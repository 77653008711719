import PageHeader from '@app/components/Layout/PageHeader/PageHeader';
import * as actions from '@app/redux/foodkid/foodkid.action';
import FoodkidSelector from '@app/redux/foodkid/foodkid.selector';
import SchoolSelector from '@app/redux/school/school.selector';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FoodkidFilter from './FoodkidFilter';
import FoodkidList from './FoodkidList';
import './styles.scss';
import _othersSelector from '@app/redux/others/selector';
import moment from 'moment';
import ModalChangeQuantity from './ModalChangeQuantity';
import ModalUpdateService from './ModalUpdateService';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import alertUtils, { getCustomContent, notiContent } from '@app/utils/alert';
import { SWEET_ALERT_TYPES } from '@app/constants/global.const';
import _ from 'lodash';
import FoodkidItem from './FoodkidItem';
import NoData from '@app/components/Layout/Shares/NoData';

const MySwal = withReactContent(Swal);

const Foodkids = () => {
    const [dateFilter, setDateFilter] = useState(moment());
    const [kidGroupSample, setKidGroupSample] = useState(0);
    const [kidGroup, setKidGroup] = useState(0);

    // -- SELECTOR THỰC ĐƠN
    const menuList = useSelector(FoodkidSelector.menuList);
    const listServiceSchool = useSelector(FoodkidSelector.listServiceSchool);
    const menuSchoolFoodList = useSelector(FoodkidSelector.menuSchoolFoodList);
    const rowSelected = useSelector(FoodkidSelector.rowSelected);
    const reload = useSelector(FoodkidSelector.reload);

    const isShowModalQuantity = useSelector(
        FoodkidSelector.isShowModalQuantity
    );
    const isShowModalService = useSelector(FoodkidSelector.isShowModalService);

    // -- SELECTOR SCHOOL
    const currentSchool = useSelector(SchoolSelector.currentSchool);

    // -- SELECTOR OTHER
    const schoolInfo = useSelector(_othersSelector.schoolInfo);
    const schoolFood = useSelector(_othersSelector.schoolFood);
    const units = useSelector(_othersSelector.units);
    const suppliers = useSelector(_othersSelector.supplier);

    const dispatch = useDispatch();

    useEffect(
        () => () => {
            dispatch(actions.doReset());
        },
        [dispatch]
    );

    useEffect(() => {
        if (reload > 0) {
            dispatch(
                actions.doGetMenuSample({
                    oId: currentSchool,
                    kidGroupId: kidGroupSample,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, reload]);

    useEffect(() => {
        if (kidGroupSample) {
            dispatch(
                actions.doGetMenuSample({
                    oId: currentSchool,
                    kidGroupId: kidGroupSample,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, kidGroupSample]);

    // const updateMenuResult = (data) => {
    //     dispatch(actions.doUpdateMenuResult([...data]));
    // };

    const onSaveNewMenu = (data) => {
        if (!data || !data.length) {
            alertUtils.showWarning('Không có thực đơn nào được chọn!');
            return;
        }
        const listOrgId = _.chain(data)
            .map((o) => o.o_id)
            .uniq()
            .value();

        if (listOrgId.length >= 2) {
            alertUtils.showWarning(
                'Danh sách thực đơn không thuộc về cùng 1 trường, vui lòng kiểm tra lại!'
            );
            return;
        }
        const dataPost = {
            orgId: listOrgId[0],
            listModel: data.map((o) => ({
                ...o,
                kg_id: kidGroup,
                date_import: moment.unix(o.m_datefounded).format('DD-MM-YYYY'),
            })),
            schoolInfo,
            suppliers,
        };

        dispatch(actions.saveMenu(dataPost));
    };

    const onCheckData = (value, data) => {
        const { checked } = value.target;
        const newList = menuList.map((o) => {
            if (o.m_id === data.m_id) {
                o = {
                    ...o,
                    is_update: checked,
                };
            }

            return o;
        });

        dispatch(actions.doUpdateMenuResult(newList));
    };

    const onChangeDateFilter = (value) => {
        setDateFilter(value);

        const newList = menuList.map((o) => ({
            ...o,
            m_datefounded: null,
        }));

        dispatch(actions.doUpdateMenuResult(newList));
    };

    /** ========= TĂNG GIẢM SỈ SỐ ========= */
    const onOpenChangeQuantity = () => {
        dispatch(actions.doOpenModalChangeQuantity());
    };

    const onCloseChangeQuantity = () => {
        dispatch(actions.doCloseModalChangeQuantity());
    };

    const onSubmitChangeQuantity = (data) => {
        dispatch(
            actions.doUpdateQuantityMenu({
                orgId: schoolInfo.o_id,
                listMenu: menuList.filter((o) => o.is_update),
                quantity: +data.quantity,
            })
        );
    };
    /** ========= END ========= */

    /** ========= CẬP NHẬT TIỀN DỊCH VỤ ========= */
    const onOpenUpdateService = () => {
        dispatch(actions.doOpenModalUpdateService());
    };

    const onCloseUpdateService = () => {
        dispatch(actions.doCloseModalUpdateService());
    };

    const onSubmitUpdateService = (data) => {
        const listIdService = data.map((o) => o.ms_id);

        const menuSelected = menuList.filter((o) => o.is_update);

        dispatch(
            actions.doUpdatePriceServiceMenu({
                orgId: schoolInfo.o_id,
                listMenu: menuSelected,
                listIdService,
            })
        );
    };
    /** ========= END ========= */

    /** ========= CẬP NHẬT TIỀN THỰC PHẨM MỚI ========= */
    const onUpdateNewPrice = () => {
        MySwal.fire(
            getCustomContent(
                'Bạn có muốn cập nhật lại giá tiền mới nhất của thực phẩm. Nhấn <b>đồng ý</b> để cập nhật!',
                SWEET_ALERT_TYPES.QUESTION
            )
        ).then((res) => {
            if (res.value) {
                const dataPost = menuList
                    .filter((o) => o.is_update)
                    .map((menu) => {
                        const listSchoolFood = menuSchoolFoodList.filter(
                            (o) => o.m_id === menu.m_id
                        );
                        return {
                            menu,
                            listSchoolFood,
                        };
                    });

                dispatch(
                    actions.doUpdateNewPriceMenu({
                        orgId: schoolInfo.o_id,
                        dataPost,
                    })
                );
            }
        });
    };

    /** ========= LÀM TRÒN SỐ THỰC MUA ========= */
    const onRoundingRealBuy = () => {
        MySwal.fire(
            getCustomContent(
                'Nhấn đồng ý nếu bạn muốn làm tròn số <b> thực mua </b>!',
                SWEET_ALERT_TYPES.QUESTION
            )
        ).then((res) => {
            if (res.value) {
                const menuChecked = menuList.filter(
                    (o) => o.is_update && o.m_quantityafter
                );

                if (_.isEmpty(menuChecked)) {
                    MySwal.fire(
                        notiContent(
                            'warning',
                            'Vui lòng cung cấp sỉ số thực tế cho thực đơn đã chọn !'
                        )
                    );
                    return;
                }

                const dataPost = menuChecked.map((menu) => {
                    const listSchoolFood = menuSchoolFoodList
                        .filter((o) => o.m_id === menu.m_id)
                        ?.map((schFood) => {
                            const food = schoolFood.find(
                                (o) => o.sf_id === schFood.sf_id
                            );

                            if (food) {
                                const unit =
                                    food.cu_id !== 0
                                        ? units.find(
                                              (o) => o.cu_id === food.cu_id
                                          )
                                        : units.find(
                                              (o) => o.cu_weight === 1000
                                          );

                                schFood.cu_id = unit.cu_id;
                                schFood.cu_weight = unit.cu_weight;
                            }

                            const fixed =
                                schFood.cu_weight !== 1000 &&
                                schFood.cu_weight !== 1
                                    ? 0
                                    : 1;

                            schFood.msf_totalquantityafter = parseFloat(
                                schFood.msf_totalquantityafter
                            ).toFixed(fixed);

                            schFood.msf_quantityafter = parseFloat(
                                (
                                    ((schFood.msf_totalquantityafter -
                                        (schFood.msf_totalquantityafter / 100) *
                                            schFood.msf_disposalrate) *
                                        schFood.cu_weight) /
                                    menu.m_quantityafter
                                ).toFixed(2)
                            );
                            schFood.msf_pricefoodafter = parseFloat(
                                (
                                    (schFood.msf_totalquantityafter *
                                        schFood.msf_priceafter) /
                                    menu.m_quantityafter
                                ).toFixed(2)
                            );

                            return schFood;
                        });
                    menu.m_priceafter = (
                        _.sumBy(listSchoolFood, 'msf_pricefoodafter') +
                        _.sumBy(
                            listServiceSchool.filter(
                                (_service) => _service.m_id === menu.m_id
                            ),
                            'mms_price'
                        )
                    ).toFixed(2);

                    return { menu, listSchoolFood };
                });

                dispatch(
                    actions.doRoundingRealBuy({
                        orgId: schoolInfo.o_id,
                        dataPost,
                    })
                );
            }
        });
    };

    const onChangeCheckAll = (event) => {
        const { checked } = event.target;

        const newList = menuList.map((o) => ({ ...o, is_update: checked }));

        dispatch(actions.doUpdateMenuResult(newList));
    };

    const onEditData = (row, valueChange, type) => {
        const newData = [...menuList].map((o) => {
            if (o.m_id === row.m_id) {
                o = {
                    ...o,
                    [type]:
                        type === 'm_datefounded'
                            ? valueChange.startOf('day').unix()
                            : valueChange.target.value,
                };
            }

            return o;
        });

        dispatch(actions.doUpdateMenuResult(newData));
    };

    const showData = () => {
        if (_.isEmpty(menuList)) return <NoData colspan={8} />;

        return menuList.map((dataItem, idx) => (
            <FoodkidItem
                key={idx}
                dataItem={dataItem}
                dateFilter={dateFilter}
                onEditData={onEditData}
                onCheckData={onCheckData}
            />
        ));
    };

    return (
        <Fragment>
            <PageHeader pageTitle="TẠO THỰC ĐƠN TỰ ĐỘNG" />
            <div className="content">
                {/* FILTER SECTION */}
                <FoodkidFilter
                    rowSelected={rowSelected}
                    dateFilter={dateFilter}
                    onSaveNewMenu={onSaveNewMenu}
                    onUpdateNewPrice={onUpdateNewPrice}
                    onOpenChangeQuantity={onOpenChangeQuantity}
                    onOpenUpdateService={onOpenUpdateService}
                    onChangeKidGroup={setKidGroup}
                    onRoundingRealBuy={onRoundingRealBuy}
                    onChangeKidGroupSample={setKidGroupSample}
                    onChangeDateFilter={onChangeDateFilter}
                />
                {/* LIST SECTION */}
                <FoodkidList
                    menuList={menuList}
                    onChangeCheckAll={onChangeCheckAll}
                >
                    {showData()}
                </FoodkidList>

                {isShowModalQuantity ? (
                    <ModalChangeQuantity
                        isShow={isShowModalQuantity}
                        onClose={onCloseChangeQuantity}
                        onSubmit={onSubmitChangeQuantity}
                    />
                ) : null}

                {isShowModalService ? (
                    <ModalUpdateService
                        isShow={isShowModalService}
                        onClose={onCloseUpdateService}
                        onSubmitUpdateService={onSubmitUpdateService}
                    />
                ) : null}
            </div>
        </Fragment>
    );
};

export default Foodkids;
