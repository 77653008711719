import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import { MOMENT_RANGE_TYPE } from '@app/constants/datetime.const';
import _ from 'lodash';

const propTypes = {
    /**
     * MOMENT_RANGE_TYPE
     */
    rangeType: PropTypes.string,
    onChange: PropTypes.func,
};

const RangeDate = ({
    rangeType,
    onChange,
    showType,
    defaultValue,
    minDate,
}) => {
    const [rangeDateValue, setRangeDateValue] = useState(() => {
        rangeType = rangeType || MOMENT_RANGE_TYPE.MONTH;
        let startDate = moment(new Date()).startOf(rangeType);
        let endDate = moment(new Date()).endOf(rangeType);
        if (rangeType === MOMENT_RANGE_TYPE.DAY) {
            startDate = moment(new Date()).startOf(rangeType);
            endDate = moment(new Date()).add(1, 'days').endOf(rangeType);
        }
        return {
            startDate,
            endDate,
        };
    });

    useEffect(() => {
        const _dateValue = defaultValue || rangeDateValue;
        setRangeDateValue(_dateValue);
        if (onChange) {
            onChange(_dateValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDateChange = (_type, s) => {
        let startDate = rangeDateValue.startDate;
        let endDate = rangeDateValue.endDate;
        switch (_type) {
            case 'start': {
                if (s > endDate) {
                    endDate = moment(s)
                        .add(1, 'days')
                        .endOf(MOMENT_RANGE_TYPE.DAY);
                }
                startDate = moment(s).startOf(MOMENT_RANGE_TYPE.DAY);
                break;
            }
            case 'end': {
                if (s < startDate) {
                    startDate = moment(s)
                        .add(-1, 'days')
                        .startOf(MOMENT_RANGE_TYPE.DAY);
                }
                endDate = moment(s).endOf(MOMENT_RANGE_TYPE.DAY);
                break;
            }
            default: {
                break;
            }
        }

        setRangeDateValue({ startDate, endDate });
        if (onChange) {
            onChange({ startDate, endDate });
        }
    };

    const onDisabledDate = (current) => {
        try {
            if (!minDate) return false;
            let momentCheck;
            if (_.isNumber(minDate)) {
                momentCheck = moment.unix(minDate).endOf('day');
            } else {
                momentCheck = moment(minDate).endOf('day');
            }

            return current && current <= momentCheck;
        } catch {
            return false;
        }
    };

    return (
        <>
            {showType === 'form' ? (
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>
                                <h6 className="panel-title">Từ ngày</h6>
                            </label>
                            <DatePicker
                                className="form-control daterange-single"
                                value={rangeDateValue.startDate}
                                format="DD/MM/YYYY"
                                onChange={(s) => onDateChange('start', s)}
                                allowClear={false}
                                showToday={false}
                                disabledDate={onDisabledDate}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>
                                <h6 className="panel-title">Đến ngày</h6>
                            </label>
                            <DatePicker
                                className="form-control daterange-single"
                                value={rangeDateValue.endDate}
                                format="DD/MM/YYYY"
                                onChange={(s) => onDateChange('end', s)}
                                allowClear={false}
                                showToday={false}
                                disabledDate={onDisabledDate}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    Từ ngày
                                </span>
                                <DatePicker
                                    className="form-control daterange-single"
                                    value={rangeDateValue.startDate}
                                    format="DD/MM/YYYY"
                                    onChange={(s) => onDateChange('start', s)}
                                    allowClear={false}
                                    showToday={false}
                                    disabledDate={onDisabledDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    Đến ngày
                                </span>
                                <DatePicker
                                    className="form-control daterange-single"
                                    value={rangeDateValue.endDate}
                                    format="DD/MM/YYYY"
                                    onChange={(s) => onDateChange('end', s)}
                                    allowClear={false}
                                    showToday={false}
                                    disabledDate={onDisabledDate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

RangeDate.propTypes = propTypes;

export default RangeDate;
