import ButtonModalAction from '@app/components/UI/Button/ButtonModalAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import RenderField from '@app/components/Layout/Shares/FormHelpers/TextField';
import validate from './validate';

const propTypes = {
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    isShow: PropTypes.bool,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
};

const ChangeQuantityModal = ({
    isShow,
    invalid,
    submitting,
    handleSubmit,
    onClose,
    onSubmit,
}) => (
    <Modal
        show={isShow}
        onHide={() => onClose()}
        animation
        backdrop="static"
        size="sm"
    >
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton className="bg-primary-800">
                <Modal.Title>Cập nhật sỉ số</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <Field
                                name="quantity"
                                component={RenderField}
                                type="number"
                                className="form-control"
                                label="Số lượng tăng/giảm"
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-group">
                    <ButtonModalAction
                        buttonType={BUTTON_ACTION_TYPE.SAVE}
                        disabled={invalid || submitting}
                        type="submit"
                        buttonLabel="Lưu"
                        isLeftIcon
                    />
                    <ButtonModalAction
                        buttonType={BUTTON_ACTION_TYPE.CLOSE}
                        buttonLabel="Thoát"
                        onClick={onClose}
                    />
                </div>
            </Modal.Footer>
        </form>
    </Modal>
);
const withReduxForm = reduxForm({
    form: 'MODAL_CHANGE_QUANTITY',
    enableReinitialize: true,
    validate,
});

ChangeQuantityModal.propTypes = propTypes;
export default withReduxForm(ChangeQuantityModal);
