/* eslint-disable no-return-assign */
import { Input } from 'antd';
import React, { Fragment } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

const OrganizationFilter = ({ onLinkToOr, onChangeKeyWord, onSearch }) => (
    <Fragment>
        {/* Filter */}
        <div className="row">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group filter-group">
                            <Input
                                onChange={onChangeKeyWord}
                                addonBefore="Điều kiện lọc"
                                placeholder="Tìm họ tên, email, điện thoại..."
                                prefix={<SearchOutlined />}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                            <div className="no-margin">
                                <div className="btn-group">
                                    <ButtonFilterAction
                                        labelButton="Tìm kiếm"
                                        buttonType={BUTTON_ACTION_TYPE.SEARCH}
                                        onClick={onSearch}
                                    />
                                    <ButtonFilterAction
                                        labelButton="Thêm mới"
                                        buttonType={BUTTON_ACTION_TYPE.ADD}
                                        onClick={onLinkToOr}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* /Filter */}
    </Fragment>
);

export default OrganizationFilter;
