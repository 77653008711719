import axiosService from '../../services/axiosService';
import { API_SC_GATEWAY_ENDPOINT } from '../../constants/api';

const BASE_URL = `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/ToolSC/FoodKid/InventoryReceipt`;

const LIST_URL = {
    SAVE: `${BASE_URL}/Save`,
};

const _fkInventoryReceiptApis = {
    save(dataPost) {
        return axiosService.post(LIST_URL.SAVE, dataPost);
    },
};

export default _fkInventoryReceiptApis;
