import axios from 'axios';
import { STATUS_CODES, METHODS } from '@app/constants/api';
import { TOKEN_NAME } from '@app/constants/global.const';
import { saveAs } from 'file-saver';

const config = () => {
    const token = localStorage.getItem(TOKEN_NAME);
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
    };
    return headers;
};

const formConfig = () => {
    const token = localStorage.getItem(TOKEN_NAME);
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
    };
    return headers;
};

class AxiosService {
    get = (url, type, timeOut) =>
        this.doRequest(METHODS.GET, url, '', type, timeOut);

    post = (url, data, type, timeOut) =>
        this.doRequest(METHODS.POST, url, data, type, timeOut);

    delete = (url, type, timeOut) =>
        this.doRequest(METHODS.DELETE, url, '', type, timeOut);

    exportExcel = (url, dataPost) => {
        const instance = axios.create();
        const options = {
            url,
            method: METHODS.POST,
            data: dataPost,
            headers: config(),
            responseType: 'blob',
        };
        return instance.request(options).then((response) => {
            const resHeader = response.headers['content-disposition'];
            if (resHeader) {
                const filename = resHeader
                    .split(';')
                    .find((n) => n.includes('filename='))
                    .replace('filename=', '')
                    .trim();
                const blob = new Blob([response.data]);
                // const urlFile = window.URL.createObjectURL(blob);

                saveAs(blob, filename);
            }
        });
    };

    doRequest = (method, url, data, type, timeOut) => {
        // const token = localStorage.getItem(TOKEN_NAME);
        // let dataPost = { token };
        // if (data) {
        //     dataPost = {
        //         ...data,
        //         // token,
        //     };
        // }

        let cusConfig = config();
        if (type === 'FORM') {
            cusConfig = formConfig();
        }
        if (process.env.NODE_ENV === 'production') {
            timeOut = timeOut || 0;
        } else {
            timeOut = 0;
        }

        return axios({
            method,
            url,
            data,
            headers: cusConfig,
            timeout: timeOut,
        })
            .then((res) => res)
            .catch((err) => {
                const resp = err.response;
                if (!resp) {
                    return {
                        status: 503,
                        data: {
                            msg: 'Không kết nối được đến network',
                        },
                    };
                }
                switch (resp.status) {
                    case STATUS_CODES.NOT_FOUND:
                    case STATUS_CODES.METHOD_NOT_ALLOW:
                    case STATUS_CODES.SERVICE_UNAVAIBLE:
                    case STATUS_CODES.BAD_REQUEST: {
                        return resp;
                    }
                    case STATUS_CODES.UNAUTHORIZED: {
                        localStorage.setItem(TOKEN_NAME, '');
                        document.location.href = '/login';
                        return '';
                    }
                    default:
                        return resp;
                }
            });
    };
}
export default new AxiosService();
