import React, { memo } from 'react';
import { Link, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MenuItem = ({ menu }) => {
    const { t } = useTranslation('menu');
    const showParent = (menuItem) => {
        let xhtml = null;
        if (menuItem.children) {
            xhtml = (
                <span className="ref-a">
                    <img
                        src={menuItem.icon}
                        className="w-32"
                        alt={t(menuItem.name)}
                    />
                    <span>{t(menuItem.name)}</span>
                </span>
            );
        } else if (menuItem.link) {
            xhtml = (
                <Route
                    path={menuItem.link}
                    exact={menuItem.exact}
                    children={({ match }) => {
                        const active = match ? 'active' : '';
                        return (
                            <Link to={menuItem.link} className={active}>
                                <img
                                    src={menuItem.icon}
                                    className="w-32"
                                    alt={t(menuItem.name)}
                                />
                                <span>{t(menuItem.name)}</span>
                            </Link>
                        );
                    }}
                />
            );
        }
        return xhtml;
    };

    const showChildren = (menuItem) => {
        const xhtml = [];
        if (menuItem.children) {
            menuItem.children.forEach((item, index) => {
                xhtml.push(
                    <Route
                        key={index}
                        path={item.link}
                        exact={item.exact}
                        roles={item.roles}
                        children={({ match }) => {
                            const active = match ? 'active' : '';
                            return (
                                <li className={active}>
                                    <Link to={item.link}>{t(item.name)}</Link>
                                </li>
                            );
                        }}
                    />
                );
            });
        }

        return xhtml.length === 0 ? '' : <ul>{xhtml}</ul>;
    };

    return (
        <li className="li-main">
            {showParent(menu)}
            {showChildren(menu)}
        </li>
    );
};
export default memo(MenuItem);
