import React from 'react';
import { Switch } from 'antd';
import ButtonTableAction from '@app/components/UI/Button/ButtonTableAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import PropTypes from 'prop-types';

const propTypes = {
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    accountRole: PropTypes.shape({
        sr_sortorder: PropTypes.number,
        sr_name: PropTypes.string,
        sr_status: PropTypes.number,
    }),
};
const AccountRoleItem = ({ accountRole, onEdit, onDelete, onUpdateStatus }) => (
    <tr>
        <td className="text-center">{accountRole.sr_sortorder}</td>
        <td>{accountRole.sr_name}</td>
        <td className="text-center" data-label="Đang hoạt động">
            <Switch
                checked={accountRole.sr_status === 1}
                onChange={(value) => onUpdateStatus(accountRole, value)}
            />
        </td>
        <td className="text-center">
            <div className="btn-table-group">
                <ButtonTableAction
                    buttonType={BUTTON_ACTION_TYPE.EDIT}
                    onClick={() => onEdit(accountRole)}
                />
                <ButtonTableAction
                    buttonType={BUTTON_ACTION_TYPE.DELETE}
                    onClick={() => onDelete(accountRole)}
                />
            </div>
        </td>
    </tr>
);

AccountRoleItem.propTypes = propTypes;

export default AccountRoleItem;
