import globalUtils from '@app/utils/global.utils';
import { Checkbox } from 'antd';

/* eslint-disable react/react-in-jsx-scope */
const FoodkidItem = (props) => {
    const { item, onCheck } = props;
    return (
        <tr>
            <td className="text-center">{item.stt}</td>
            <td className="text-center">
                <Checkbox checked={item.isCheck} onChange={(s) => onCheck(s)} />
            </td>
            <td data-label="Tên nhóm trẻ">{item.name}</td>
            <td data-label="Tên nhóm trẻ">Dinh dưỡng</td>

            <td className="text-center">
                {globalUtils.getProcessName(item.status)}
            </td>
        </tr>
    );
};
export default FoodkidItem;
