/* eslint-disable no-return-assign */
import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import KidGroupListSelect from '@app/components/_Share/Select/KidGroupSelect/Dynamic';
import * as actions from '@app/redux/foodkid/foodkid.action';
import FoodkidSelector from '@app/redux/foodkid/foodkid.selector';
import SchoolSelector from '@app/redux/school/school.selector';
import { notiContent, swal } from '@app/utils/alert';
import { DatePicker } from 'antd';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'moment/locale/vi';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { DownOutlined } from '@ant-design/icons';

const MySwal = withReactContent(Swal);

const dateFormatList = ['MM/YYYY', 'MM/YY'];
const FoodkidFilter = ({
    dateFilter,
    onSaveNewMenu,
    onUpdateNewPrice,
    onOpenChangeQuantity,
    onOpenUpdateService,
    onRoundingRealBuy,
    onChangeKidGroup,
    onChangeDateFilter,
    onChangeKidGroupSample,
}) => {
    // -- SELECTOR THỰC ĐƠN
    const menuList = useSelector(FoodkidSelector.menuList);

    // -- SELECTOR SCHOOL
    const currentSchool = useSelector(SchoolSelector.currentSchool);

    const dispatch = useDispatch();

    const onSaveMenu = () => {
        const filterMenu = menuList.filter(
            (mn) => mn.is_update && mn.m_datefounded && mn.m_quantityafter
        );

        if (_.isEmpty(filterMenu)) {
            MySwal.fire(
                notiContent(
                    'warning',
                    'Vui lòng cung cấp đủ thông tin cho thực đơn !'
                )
            );
        } else {
            const listIdEdit = filterMenu.map((o) => o.m_id);
            swal(
                'info',
                `Bạn đang thực hiện tạo mới ${listIdEdit.length} thực đơn ?`,
                true
            ).then((res) => {
                if (res.value) {
                    const mapMenuSave = menuList.map((o) => {
                        if (listIdEdit.includes(o.m_id)) {
                            o = { ...o, status: 1 };
                        }
                        return o;
                    });

                    dispatch(actions.doUpdateMenuResult(mapMenuSave));

                    onSaveNewMenu(filterMenu);
                }
            });
        }
    };

    return (
        <Fragment>
            {/* Filter */}
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-md-3 col-xs-6">
                            <div className="form-group">
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        Nhóm thực đơn mẫu
                                    </span>
                                    <KidGroupListSelect
                                        oId={currentSchool}
                                        onChange={(value) =>
                                            onChangeKidGroupSample(value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-xs-6">
                            <div className="form-group">
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        Nhóm tuổi cần tạo
                                    </span>
                                    <KidGroupListSelect
                                        oId={currentSchool}
                                        onChange={(value) =>
                                            onChangeKidGroup(value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 col-sm-4">
                            <div className="form-group">
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        Tháng lập
                                    </span>
                                    <DatePicker
                                        className="form-control daterange-single"
                                        value={dateFilter}
                                        format={dateFormatList}
                                        onChange={onChangeDateFilter}
                                        suffixIcon={<DownOutlined />}
                                        picker="month"
                                        locale={locale}
                                        allowClear={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-4">
                            <div className="form-group">
                                <div className="no-margin">
                                    <div className="btn-group">
                                        <ButtonFilterAction
                                            labelButton="Tạo mới"
                                            buttonType={BUTTON_ACTION_TYPE.ADD}
                                            disabled={
                                                menuList.filter(
                                                    (o) => o.is_update
                                                ).length === 0
                                            }
                                            onClick={onSaveMenu}
                                        />

                                        <button
                                            type="button"
                                            data-toggle="dropdown"
                                            disabled={
                                                menuList.filter(
                                                    (o) => o.is_update
                                                ).length === 0
                                            }
                                            className="btn btn-default btn-rounded dropdown-toggle"
                                        >
                                            <i className="icon-pencil4 position-left" />
                                            &nbsp; Điều chỉnh thực đơn
                                        </button>
                                        <ul
                                            className="dropdown-menu dropdown-menu-right"
                                            style={{ minWidth: '250px' }}
                                        >
                                            <li>
                                                <a
                                                    href="#1"
                                                    data-toggle="modal"
                                                    data-target="#modal_update_quantity"
                                                    onClick={
                                                        onOpenChangeQuantity
                                                    }
                                                >
                                                    <i className="icon-sort" />
                                                    Tăng giảm sĩ số
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#1"
                                                    data-toggle="modal"
                                                    data-target="#modal_update_service"
                                                    onClick={
                                                        onOpenUpdateService
                                                    }
                                                >
                                                    <i className="icon-droplet2" />
                                                    Cập nhật tiền dịch vụ
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#1"
                                                    onClick={onUpdateNewPrice}
                                                >
                                                    <i className="icon-coin-dollar" />
                                                    Cập nhật giá tiền mới nhất
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#1"
                                                    onClick={onRoundingRealBuy}
                                                >
                                                    <i className="icon-strategy" />
                                                    Làm tròn số thực mua
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Filter */}
        </Fragment>
    );
};

export default FoodkidFilter;
