const validate = (values) => {
    const errors = {};
    const { quantity } = values;

    if (!quantity) {
        errors.quantity = 'Số lượng không được để trống !';
    }

    // if (!/-?([1-9])+$/i.test(quantity)) {
    //     errors.quantity = 'Số lượng không đúng định dạng !';
    // }

    return errors;
};

export default validate;
