import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as types from './organization.constant';

import { ACTION_API_TYPES } from '@app/constants';
import * as apiConst from '@app/constants/api';

import { showError, showSuccess } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import {
    apiSaveLinkedSchool,
    apiUpdateConnectStatus,
    getListScale,
    getListShoolService,
    getListType,
} from '@app/apis/schools';
import {
    doFetchDataFirstSuccess,
    doGetListSuccess,
    doSaveLinkedSchoolSuccess,
    doUpdateConnectStatusSuccess,
} from './organization.action';
import { apiCopySettingSchool } from '@app/apis/schools/schoolProperty';

function* sagaGetList({ payload }) {
    function* doRQ() {
        const { data, status } = yield call(getListShoolService, payload.data);

        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(doGetListSuccess(data.data));
        } else {
            showError(status, data);
        }
    }
    yield callLoading(doRQ);
}

function* sagaFetchDataFirst() {
    yield callLoading(function* doRQ() {
        const listResp = yield all([call(getListScale), call(getListType)]);

        const checkStatus = listResp.find(
            (resp) => resp.status !== apiConst.STATUS_CODES.SUCCESS
        );

        if (!checkStatus) {
            const [dataScale, dataType] = listResp;

            yield put(
                doFetchDataFirstSuccess({
                    dataScale: dataScale.data.data,
                    dataType: dataType.data.data,
                })
            );
        } else {
            showError(checkStatus.status, checkStatus.data);
        }
    });
}

function* sagaSaveLinkedShool({ payload }) {
    yield callLoading(function* doRQ() {
        const { data, status } = yield call(apiSaveLinkedSchool, payload.data);
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(doSaveLinkedSchoolSuccess());
            showSuccess(ACTION_API_TYPES.ADD);
        } else {
            showError(status, data);
        }
    });
}

function* sagaUpdateConnectStatus({ payload }) {
    yield callLoading(function* doRQ() {
        const { data, status } = yield call(
            apiUpdateConnectStatus,
            payload.data
        );
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(doUpdateConnectStatusSuccess(data.data));
            showSuccess(ACTION_API_TYPES.EDIT);
        } else {
            showError(status, data);
        }
    });
}

function* sagaCopySetting({ payload }) {
    yield callLoading(function* doRQ() {
        const { data, status } = yield call(apiCopySettingSchool, payload.data);
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            showSuccess(ACTION_API_TYPES.EDIT);
        } else {
            showError(status, data);
        }
    });
}

function* organizationSaga() {
    yield takeLatest(types.GET_LIST, sagaGetList);
    yield takeLatest(types.FETCH_DATA_FIRST, sagaFetchDataFirst);
    yield takeLatest(types.SAVE_LINKED_SCHOOL, sagaSaveLinkedShool);
    yield takeLatest(types.UPDATE_CONNECTION_STATUS, sagaUpdateConnectStatus);
    yield takeLatest(types.COPY_SETTING, sagaCopySetting);
}

export default organizationSaga;
