import { SCHOOL_SELECT } from '@app/constants/global.const';
import * as types from './school.constant';

const value = localStorage.getItem(SCHOOL_SELECT);
const initState = {
    schoolList: [],
    currentSchool: value ?? '0',
    isShowModal: false,
};

const schoolReducer = (state = initState, action) => {
    switch (action.type) {
        case types.OPEN_SCHOOL_MODAL: {
            return {
                ...state,
                isShowModal: true,
            };
        }
        case types.CLOSE_SCHOOL_MODAL: {
            return {
                ...state,
                isShowModal: false,
            };
        }
        case types.CHANGE_SCHOOL: {
            const { id } = action.payload;
            return {
                ...state,
                currentSchool: id,
            };
        }
        case types.GET_SCHOOL_SUCCESS: {
            const { data } = action.payload;
            const select_value = localStorage.getItem(SCHOOL_SELECT);

            if (
                (select_value === null || select_value === '') &&
                data.length > 0
            ) {
                localStorage.setItem(SCHOOL_SELECT, data[0].o_id);
            }
            return {
                ...state,
                schoolList: data,
                currentSchool:
                    (select_value === null || select_value === '') &&
                    data.length > 0
                        ? data[0].o_id
                        : state.currentSchool,
            };
        }
        default:
            return { ...state };
    }
};

export default schoolReducer;
