import * as types from './constants';
import { TOKEN_NAME } from '@app/constants/global.const';

const initState = {
    token: localStorage.getItem(TOKEN_NAME),
    authenModel: {
        username: '',
        password: '',
    },
};

const loginReducer = (state = initState, action) => {
    switch (action.type) {
        case types.UPDATE_TOKEN: {
            const { token } = action.payload;
            return {
                ...state,
                token,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default loginReducer;
