import React from 'react';
import PropTypes from 'prop-types';
import RangeDate from './RangeDateV2';

const propTypes = {
    /**
     * MOMENT_RANGE_TYPE
     */
    rangeType: PropTypes.string,
    onChange: PropTypes.func,
};

const RangeDateFieldV2 = ({
    input,
    onChange,
    rangeType,
    showType,
    minDate,
}) => {
    const { value: inputValue, onChange: inputOnChange } = input;
    const handleChange = (value) => {
        inputOnChange(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <>
            <RangeDate
                onChange={handleChange}
                defaultValue={inputValue}
                rangeType={rangeType}
                showType={showType}
                minDate={minDate}
            />
        </>
    );
};

RangeDateFieldV2.propTypes = propTypes;

export default RangeDateFieldV2;
