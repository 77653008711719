export const SC_MODULES = {
    ADMIN: 1,
    SERVICE: 12,
};

const CATEGORY_FUNCS = {
    ORGANIZATION: 1,
    ACCOUNT: 2,
    FOODKID: 3,
    WAREHOUSE: 4,
    ATTENDANCE: 5,
    RECEIPTS: 6,
    GENERAL: 7,
    SETTING: 8,
    TRANSACTION_HISTORY: 9,
    AGENCY: 10,
};

/**
 * 1 Đơn vị
 */
const ORGANIZATION_FUNCS = {
    ORGANIZATIONS: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.ORGANIZATION}.1`,
};

/**
 * 2 Tài khoản
 */
const ACCOUNT_FUNCS = {
    ACCOUNTS: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.ACCOUNT}.1`,
    ACCOUNT_ROLE: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.ACCOUNT}.2`,
    ACCOUNT_PRIVILEGE: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.ACCOUNT}.3`,
};

/**
 * 3 tạo thực đơn tự động
 */
const FOODKID_FUNCS = {
    FOODKID_CREATE_MENU: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.FOODKID}.1`,
    FOODKID_COPY_MENU: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.FOODKID}.2`,
    FOODKID_LIST_MENU: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.FOODKID}.3`,
};

/**
 * 4 tạo phiếu nhập xuất kho tự động
 */
const WAREHOUSE_FUNCS = {
    WAREHOUSE_CITY: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.WAREHOUSE}.1`,
};

/**
 * 5 tạo bảng điểm danh tụ động
 */
const ATTENDANCE_FUNCS = {
    ATTENDANCE_PACKAGE: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.ATTENDANCE}.1`,
};

/**
 * 6 lập phiếu thu tự động
 */
const RECEIPTS_FUNCS = {
    /**
     * Tổ chức
     */
    RECEIPTS: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.RECEIPTS}.1`,
};

/**
 * 7
 */
const GENERAL_FUNCS = {
    /**
     * tổng hợp sổ sách bán trú
     */
    GENERAL_LIST: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.GENERAL}.1`,
};

/**
 * 8
 */
const SETTING_FUNCS = {
    /**
     * Cấu hình trường
     */
    SETTINGS: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.SETTING}.1`,
};

/**
 * 9
 */
const TRANSACTION_HISTORY_FUNCS = {
    /**
     * Lịch sử giao dịch
     */
    TRANSACTION_HISTORY: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.TRANSACTION_HISTORY}.1`,
};

/**
 * 10
 */
const AGENCY_FUNCS = {
    /**
     * Đại lý
     */
    AGENCY: `${SC_MODULES.SERVICE}.${CATEGORY_FUNCS.AGENCY}.1`,
};

/**
 *
 */
const TENANT_FUNCS = {};

export const ADMIN_FUNCTIONS = {
    ...ORGANIZATION_FUNCS,
    ...ACCOUNT_FUNCS,
    ...FOODKID_FUNCS,
    ...WAREHOUSE_FUNCS,
    ...ATTENDANCE_FUNCS,
    ...RECEIPTS_FUNCS,
    ...GENERAL_FUNCS,
    ...TENANT_FUNCS,
    ...SETTING_FUNCS,
    ...TRANSACTION_HISTORY_FUNCS,
    ...AGENCY_FUNCS,
    ADMIN: {
        // Thành phố
        CITY: `${SC_MODULES.ADMIN}.${1}`,
        // Quận huyện
        DISTRICT: `${SC_MODULES.ADMIN}.${2}`,
        // Phường xã
        WARD: `${SC_MODULES.ADMIN}.${3}`,
        // Tổ chức
        ORGANIZATION: `${SC_MODULES.ADMIN}.${4}`,
        // Loại tổ chức
        ORGANIZATION_CATEGORY: `${SC_MODULES.ADMIN}.${5}`,
        // Hình thức tổ chức
        ORGANIZATION_TYPE: `${SC_MODULES.ADMIN}.${6}`,
        // Qui mô tổ chức
        ORGANIZATION_SCALE: `${SC_MODULES.ADMIN}.${7}`,
        // Danh sách tài khoản
        ACCOUNT_LIST: `${SC_MODULES.ADMIN}.${8}`,
        // Vai trò người dùng
        ACCOUNT_ROLE: `${SC_MODULES.ADMIN}.${9}`,
        // Cấp quyền sử dụng
        ACCOUNT_PRIVILEGE: `${SC_MODULES.ADMIN}.${10}`,
        // Phân hệ
        ACCOUNT_MODULE: `${SC_MODULES.ADMIN}.${11}`,
        // Tính năng
        ACCOUNT_FUNCTION: `${SC_MODULES.ADMIN}.${12}`,
        // Sản phầm
        PRODUCT: `${SC_MODULES.ADMIN}.${13}`,
        // Danh mục sản phẩm
        PRODUCT_CATEGORY: `${SC_MODULES.ADMIN}.${14}`,
        // Nhà cung cấp sản phẩm (Thương hiệu)
        PRODUCT_SUPPLIER: `${SC_MODULES.ADMIN}.${15}`,
        // Đơn vị tính
        CALCULATION_UNIT: `${SC_MODULES.ADMIN}.${16}`,
        // Tạo đơn bán hàng
        CREATE_ORDER: `${SC_MODULES.ADMIN}.${17}`,
        // Danh sách đơn bán hàng
        ORDER_LIST: `${SC_MODULES.ADMIN}.${18}`,
        // Khuyến mãi
        PROMOTION: `${SC_MODULES.ADMIN}.${19}`,
        // Cửa hàng
        SHOP: `${SC_MODULES.ADMIN}.${20}`,
        // Phiếu thu chi
        FINANCE_TICKET: `${SC_MODULES.ADMIN}.${21}`,
        // Sổ thu chi
        FINANCE_BOOK: `${SC_MODULES.ADMIN}.${22}`,
        // Tài khoản kế toán
        ACCOUNTING_CATEGORY: `${SC_MODULES.ADMIN}.${23}`,
        // Tài khoản ngân hàng
        ACCOUNT_BANK: `${SC_MODULES.ADMIN}.${24}`,
        // Cấp bản quyền
        LICENCE_PRIVILEGE: `${SC_MODULES.ADMIN}.${25}`,
        // Danh sách bản quyền
        LICENCE_LIST: `${SC_MODULES.ADMIN}.${26}`,
        // Danh sách bản quyền sắp hết hạn
        LICENCE_EXPIRED: `${SC_MODULES.ADMIN}.${27}`,
        // Yêu cầu hỗ trợ
        SUPPORT_TICKET: `${SC_MODULES.ADMIN}.${28}`,
        // Thông báo
        BLOG_POST: `${SC_MODULES.ADMIN}.${29}`,
        // Loại thông báo
        BLOG_CATEGORY: `${SC_MODULES.ADMIN}.${30}`,
        // Danh mục sự kiện
        TRIP_EVENT: `${SC_MODULES.ADMIN}.${31}`,
        // Ưu đãi cùng scfamily
        REWARD: `${SC_MODULES.ADMIN}.${32}`,
        // Mẫu báo cáo
        REPORT_FORM: `${SC_MODULES.ADMIN}.${33}`,
        // Hợp đồng
        CONTRACT_VOICECLOUD: `${SC_MODULES.ADMIN}.${34}`,
    },
};
