import PageHeader from '@app/components/Layout/PageHeader/PageHeader';

import { SWEET_ALERT_TYPES } from '@app/constants';
import {
    HinhThucCanDoiThucDonList,
    SCHOOL_PROPERTY_GROUP,
    SCHOOL_PROPERTY_TYPE,
    SCHOOL_SETTING_FOODKID_GROUP,
    SCHOOL_SETTING_SERVICE_GROUP,
    SETTING_GLOBAL_GROUP,
    TabSchoolPropertyList,
} from '@app/constants/schoolSetting.const';
import { doGetSchoolInfo } from '@app/redux/others/sagaActions';
import OtherSelector from '@app/redux/others/selector';
import SchoolSelector from '@app/redux/school/school.selector';
import {
    settingSchoolActions,
    settingSchoolSelector,
} from '@app/redux/settingSchool/settingSchool.slice';
import _kidGroupApis from '@app/apis/foodkid/kidGroup.api';

import { swal } from '@app/utils/alert';
import _ from 'lodash';

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './custom.scss';

import TabSchoolPropertySetting from './TabProperty';
import ItemProperty from './TabProperty/Item';
// import PrivateTab from './TabProperty/PrivateTab';

const SettingSchool = () => {
    const [kidGroupList, setKidGroupList] = useState([]);
    // -- SELECTOR MAIN
    const schoolPropertyList = useSelector(
        settingSchoolSelector.schoolProperty
    );
    const schoolPropertySettingList = useSelector(
        settingSchoolSelector.schoolPropertySetting
    );
    const saveCount = useSelector(settingSchoolSelector.saveCount);

    // -- SELECTOR OTHER
    const proPackagingCache = useSelector(OtherSelector.packaging) || [];
    const invoiceForm = useSelector(OtherSelector.invoiceForm) || [];

    // -- SELECTOR SCHOOL
    const currentSchool = useSelector(SchoolSelector.currentSchool);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(settingSchoolActions.doGetSchoolProperty());
    }, [dispatch]);

    useEffect(() => {
        dispatch(settingSchoolActions.doGetPropertySetting(currentSchool));
        dispatch(doGetSchoolInfo(currentSchool));
    }, [currentSchool, saveCount, dispatch]);

    useEffect(() => {
        if (currentSchool) {
            _kidGroupApis
                .getListByOId(currentSchool)
                .then(({ status, data }) => {
                    if (status === 200) {
                        setKidGroupList(data.data);
                        return;
                    }
                    setKidGroupList([]);
                });
        }
    }, [currentSchool]);

    /**
     * Lưu setting
     */
    const onSave = () => {
        const data = schoolPropertySettingList.filter((x) => x.isEdit === true);
        swal(
            SWEET_ALERT_TYPES.QUESTION,
            '<p> Bạn có thực sự muốn chỉnh sửa dữ liệu này không?</p>',
            true
        ).then((res) => {
            if (res.value) {
                dispatch(settingSchoolActions.doSavePropertySetting(data));
            }
        });
    };

    /**
     * Change data
     * @param {string} value
     * @param {string} code
     */
    const onChangeData = (value, code) => {
        const data = { code, value };

        dispatch(settingSchoolActions.doChangeValuePropertySetting(data));
    };

    /**
     * hàm render dữ liệu setting
     * @param {Arr} list
     */
    const renderItemProperty = (list) =>
        list.map((item) => {
            let colClass = 'col-md-3';
            let dataType = 'string';
            let id = '';
            let name = '';
            let componentType = 'textBox';
            let listSelect = [];
            if (item.ps_code === 'dia_chi') {
                colClass = 'col-md-9';
            }
            if (item.ps_code === 'pgd_gan_nhat') {
                colClass = 'col-md-12';
            }
            switch (item.p_datatype) {
                case SCHOOL_PROPERTY_TYPE.NUMBER: {
                    dataType = 'number';
                    break;
                }
                case SCHOOL_PROPERTY_TYPE.STRING: {
                    dataType = 'string';
                    break;
                }
                case SCHOOL_PROPERTY_TYPE.BOOLEAN: {
                    dataType = 'boolean';
                    break;
                }
                case SCHOOL_PROPERTY_TYPE.DATE: {
                    dataType = 'date';
                    break;
                }
                case SCHOOL_PROPERTY_TYPE.EMAIL: {
                    dataType = 'email';
                    break;
                }
                default: {
                    break;
                }
            }
            if (item.ps_code === 'hinh_thuc_can_doi_thuc_don') {
                componentType = 'selectBox';
                listSelect = HinhThucCanDoiThucDonList;
                id = 'id';
                name = 'name';
            }
            if (
                item.ps_code === 'dung_cu_chia_chua_dung_thuc_an_2b' ||
                item.ps_code === 'dung_cu_chua_mau_thuc_an_luu_3'
            ) {
                componentType = 'selectBox';
                listSelect = proPackagingCache;
                id = 'p_id';
                name = 'p_name';
            }

            if (item.ps_code === 'hoa_don_nha_cung_cap') {
                componentType = 'selectBox';
                listSelect = invoiceForm;
                id = 'if_id';
                name = 'if_companyname';
            }

            if (item.p_group === SCHOOL_PROPERTY_GROUP.SERVICE) {
                componentType = 'tableBox';
            }

            if (
                item.ps_code === 'ma_nhom_mau_giao_dich_vu' ||
                item.ps_code === 'ma_nhom_nha_tre_dich_vu'
            ) {
                componentType = 'selectBox';
                listSelect = kidGroupList;
                id = 'kg_id';
                name = 'kg_name';
            }

            return (
                <ItemProperty
                    key={item.ps_id}
                    id={id}
                    name={name}
                    colClass={colClass}
                    item={item}
                    dataType={dataType}
                    componentType={componentType}
                    listSelect={listSelect}
                    value={item.ps_value}
                    onChange={(e, code) => onChangeData(e, code)}
                />
            );
        });

    /**
     * Hiễn thị input theo property group
     * @param {*} list
     * @param {*} groupId
     */
    const onShowPropertyItem = (list, groupId) => {
        let html = [];
        if (list.length > 0) {
            switch (groupId) {
                case SCHOOL_PROPERTY_GROUP.FOODKID: {
                    let listItemNotGroup = list;
                    SCHOOL_SETTING_FOODKID_GROUP.forEach((element) => {
                        const temp = list.filter((elm) =>
                            _.includes(element.children, elm.ps_code)
                        );
                        listItemNotGroup = listItemNotGroup.filter(
                            (elm) =>
                                !_.includes(
                                    temp.map((o) => o.ps_id),
                                    elm.ps_id
                                ) &&
                                elm.p_group === SCHOOL_PROPERTY_GROUP.FOODKID
                        );
                    });

                    html = renderItemProperty(listItemNotGroup);

                    SCHOOL_SETTING_FOODKID_GROUP.forEach((element) => {
                        const temp = list.filter((elm) =>
                            _.includes(element.children, elm.ps_code)
                        );

                        const htmlContainGroup = (
                            <Fragment key={element.id}>
                                <div className="col-md-12">
                                    <legend className="text-bold text-primary">
                                        {element.name}
                                    </legend>
                                </div>
                                {renderItemProperty(temp)}
                            </Fragment>
                        );
                        html = [html, htmlContainGroup];
                    });
                    break;
                }
                case SCHOOL_PROPERTY_GROUP.SERVICE: {
                    const _list = _.orderBy(
                        list.map((o) => {
                            const checkFunc = SCHOOL_SETTING_SERVICE_GROUP.find(
                                (x) => x?.listCode.includes(o.ps_code)
                            );

                            return {
                                ...o,
                                typeCate: checkFunc?.typeCate,
                                category:
                                    checkFunc?.typeCate === 1
                                        ? 'Dinh dưỡng'
                                        : 'Thu chi',
                            };
                        }),
                        ['typeCate', 'p_sortorder'],
                        ['asc', 'asc']
                    ).map((o, idx) => ({ ...o, sortOrder: idx + 1 }));

                    const htmlTableService = (
                        <Fragment>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <table className="table table-togglable table-hover table-bordered footable-loaded footable">
                                                <thead className="grey-light">
                                                    <tr>
                                                        <th
                                                            className="text-center"
                                                            data-toggle="true"
                                                            style={{
                                                                width: '5%',
                                                            }}
                                                        >
                                                            STT
                                                        </th>
                                                        <th className="text-center">
                                                            Tên báo cáo
                                                        </th>
                                                        <th className="text-center">
                                                            Phân hệ
                                                        </th>
                                                        <th
                                                            className="text-center"
                                                            style={{
                                                                width: '5%',
                                                            }}
                                                        >
                                                            Chọn in
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderItemProperty(_list)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    );

                    html = [htmlTableService];
                    break;
                }
                case SCHOOL_PROPERTY_GROUP.GLOBAL: {
                    let listItemNotGroup = list;
                    SETTING_GLOBAL_GROUP.forEach((element) => {
                        const temp = list.filter((elm) =>
                            _.includes(element.children, elm.ps_code)
                        );
                        listItemNotGroup = listItemNotGroup.filter(
                            (elm) =>
                                !_.includes(
                                    temp.map((o) => o.ps_id),
                                    elm.ps_id
                                ) &&
                                elm.p_group === SCHOOL_PROPERTY_GROUP.GLOBAL
                        );
                    });

                    html = renderItemProperty(listItemNotGroup);

                    SETTING_GLOBAL_GROUP.forEach((element) => {
                        const temp = list.filter((elm) =>
                            _.includes(element.children, elm.ps_code)
                        );
                        const htmlContainGroup = (
                            <Fragment key={element.id}>
                                <div className="col-md-12">
                                    <legend className="text-bold text-primary">
                                        {element.name}
                                    </legend>
                                </div>
                                {renderItemProperty(temp)}
                            </Fragment>
                        );
                        html = [html, htmlContainGroup];
                    });
                    break;
                }
                default: {
                    html = renderItemProperty(list);
                    break;
                }
            }
        }
        return html;
    };

    /**
     * Hiễn thị input trong từng tab theo property cache
     */
    const showTabPanel = () => {
        let xhtml = null;
        if (schoolPropertyList.length > 0) {
            const globalData = schoolPropertyList.map((item) => item.p_id);
            const setting = schoolPropertySettingList.filter((x) =>
                _.includes(globalData, x.p_id)
            );

            const final = _.sortBy(
                setting.map((item) => {
                    const schoolProperty = schoolPropertyList.find(
                        (x) => x.p_id === item.p_id
                    );
                    if (schoolProperty) {
                        item = {
                            ...item,
                            p_name: schoolProperty.p_name,
                            p_sortorder: schoolProperty.p_sortorder,
                            p_datatype: schoolProperty.p_datatype,
                            p_group: schoolProperty.p_group,
                        };
                    }
                    return item;
                }),
                ['p_sortorder'],
                ['asc']
            );

            xhtml = TabSchoolPropertyList.map((item) => {
                const list = final.filter((elm) => elm.p_group === item.id);

                return (
                    <div
                        className={`tab-pane has-padding ${
                            item.id === 1 ? 'active' : ''
                        }`}
                        id={item.id}
                        key={item.id}
                    >
                        <div className="panel panel-flat">
                            <TabSchoolPropertySetting onSave={() => onSave()}>
                                {list && list.length > 0
                                    ? onShowPropertyItem(list, item.id)
                                    : null}
                            </TabSchoolPropertySetting>
                        </div>
                    </div>
                );
            });
        }
        return (
            <>
                {xhtml}
                {/* <PrivateTab /> */}
            </>
        );
    };

    /**
     * Hiễn thị header tab
     */
    const showTabHearder = () => {
        let xhtml = null;
        // if (propertyCaches.length > 0) {
        xhtml = TabSchoolPropertyList.map((item) => (
            <li key={item.id} className={item.id === 1 ? 'active' : ''}>
                <a href={`#${item.id}`} data-toggle="tab">
                    <h5 className="text-semibold">{item.desc}</h5>
                </a>
            </li>
        ));
        // }
        return xhtml;
    };

    return (
        <Fragment>
            <PageHeader pageTitle="Thông tin trường học" />
            <div className="content">
                <div className="row">
                    <div className="tabbable tab-content-bordered school-setting">
                        <ul className="nav nav-tabs nav-tabs-highlight nav-justified">
                            {showTabHearder()}

                            {/* <li key={-1} className="">
                                <a href="#-1" data-toggle="tab">
                                    <h5 className="text-semibold">
                                        CẤU HÌNH RIÊNG
                                    </h5>
                                </a>
                            </li> */}
                        </ul>
                        {schoolPropertyList.length > 0 ? (
                            <div className="tab-content">{showTabPanel()}</div>
                        ) : null}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SettingSchool;
