import PageHeader from '@app/components/Layout/PageHeader/PageHeader';
import NoData from '@app/components/Layout/Shares/NoData';
import {
    accountListActions,
    accountListSelector,
} from '@app/redux/account/accountList/accountList.slice';
import {
    accountRoleActions,
    accountRoleSelector,
} from '@app/redux/account/accountRole/accountRole.slice';
import { alertDeletSP, swal, SWAL_CONST } from '@app/utils/alert';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountModal from './AccountModal';
import AccountFilter from './Filter';
import AccountItem from './Item';
import AccountList from './List';

const AccountPage = () => {
    /** ======== SELECTOR ACCOUNT ======== */
    const isShowModal = useSelector(accountListSelector.isShowModal);
    const accountList = useSelector(accountListSelector.accountList);
    const editItem = useSelector(accountListSelector.editItem);

    /** ======== SELECTOR ROLE ======== */
    const isEdit = useSelector(accountListSelector.isEdit);
    const roleList = useSelector(accountRoleSelector.roleList);

    const [idRole, setIdRole] = useState(0);
    const [currentImg, setCurrentImg] = useState('');

    const dispatch = useDispatch();

    /** ======== FETCH DATA ======== */
    useEffect(() => {
        dispatch(accountRoleActions.doGetListRole());

        return () => {
            dispatch(accountRoleActions.doReset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (idRole) {
            dispatch(accountListActions.doGetListAccount(idRole));
        }
    }, [dispatch, idRole]);
    /** ======== END ======== */

    /** MỞ POPUP */
    const onOpenModal = (data) => {
        if (!idRole) {
            swal(
                SWAL_CONST.TYPE.WARNING,
                'Vui lòng cung cấp dữ liệu vai trò !'
            );

            return;
        }
        dispatch(accountListActions.doOpenModal(data));
    };

    /** ĐÓNG POPUP */
    const onCloseModal = () => {
        dispatch(accountListActions.doCloseModal());
    };

    /** THAY ĐỔI VAI TRÒ */
    const onChangeRole = (value) => {
        setIdRole(value);
    };

    /**
     * XÓA TÀI KHOẢN
     * @param {Object} item : tài khoản được chọn
     */
    const onDelete = (item) => {
        const note =
            `<p>Bạn có muốn xóa tài khoản ${item.su_username} không?</p>` +
            '<p>Lưu ý: Xóa đối tượng này có thể dẫn đến việc xóa các thông tin liên quan khác' +
            ' nên hãy nhập <span style="color:red">[TÔI ĐỒNG Ý]</span> và bấm [Đồng Ý] để xác nhận xóa tài khoản</p>';
        alertDeletSP(note).then((res) => {
            if (res.value) {
                dispatch(accountListActions.doDeleteAccount(item?.su_id));
            }
        });
    };

    /**
     * RESET MẬT KHẨU
     * @param {Object} item : tài khoản được reset
     */
    const onResetPassword = (item) => {
        swal(
            SWAL_CONST.TYPE.QUESION,
            `<p>Bạn có muốn thiết lập lại mật khẩu cho tài khoản ${item.su_username} không?</p>`,
            true
        ).then((res) => {
            if (res.value) {
                dispatch(accountListActions.doResetPasswordAccount(item));
            }
        });
    };

    const onUpdateStatus = (_value, _data) => {
        const dataPost = {
            ..._data,
            su_status: _value,
        };

        dispatch(accountListActions.doSaveAccount({ dataPost }));
    };

    /**
     * LƯU TÀI KHOẢN
     * @param {Object} data: tài khoản
     */
    const onSubmit = (data) => {
        const dataPost = {
            ...data,
            su_dateofbirth: moment(data.su_dateofbirth).unix(),
            su_username: data?.su_email,
        };

        dispatch(
            accountListActions.doSaveAccount({ dataPost, file: currentImg })
        );
    };

    /**
     * THAY ĐỔI HÌNH ẢNH
     * @param {Object} data : file đang được chọn
     */
    const onChangeAvatar = (data) => {
        setCurrentImg(data);
    };

    /** HIỂN THỊ DANH SÁCH */
    const showItems = () => {
        if (_.isEmpty(accountList)) return <NoData colspan={8} />;

        return accountList.map((o, idx) => {
            const role = roleList?.find((item) => item.sr_id === o.sr_id) || {};

            return (
                <AccountItem
                    key={idx}
                    sortOrder={idx}
                    item={{ ...o, sr_name: role?.sr_name || '' }}
                    onEdit={(item) => onOpenModal(item)}
                    onDelete={(item) => onDelete(item)}
                    onUpdateStatus={onUpdateStatus}
                    onResetPassword={(item) => onResetPassword(item)}
                />
            );
        });
    };

    return (
        <Fragment>
            <PageHeader pageTitle="Danh sách tài khoản" />
            <div className="content">
                <AccountFilter
                    onAddNew={onOpenModal}
                    onChangeRole={onChangeRole}
                />

                <AccountList>{showItems()}</AccountList>
            </div>

            <AccountModal
                isEdit={isEdit}
                isShow={isShowModal}
                initialValues={editItem}
                onSubmit={onSubmit}
                onClose={onCloseModal}
                onChangeAvatar={onChangeAvatar}
            />
        </Fragment>
    );
};

export default AccountPage;
