/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import {
    AggregateDayServiceModel,
    MarketCashAgeReportModel,
    MarketCashReportModel,
} from '@app/models/foodkid/foodkid.model';
import {
    allowedToSpend,
    getDifferencePriceByDay,
    getDifferencePriceMenuByKidGroupAndDay,
    getFoodQuantityMarket,
    getFoodTotalPriceMarket,
    getPriceAfter,
    getPriceAfterMenuByKidGroup,
    // getPriceBefore,
    getPriceMenuByKidGroup,
    getQuantityAfterMenuByKidGroup,
    getQuantityBeforeMenuByKidGroup,
    getReportInwarehouseByKidGroup,
    getReportNotInwarehouseByKidGroup,
    getReportPriceAfterByKidGroup,
    getReportPriceBeforeByKidGroup,
    getReportQuantityByKidGroup,
    getReportSurplus,
    getReportSurplusByKidGroup,
    getTotalPriceBefore,
    getTotalPriceMenuByKidGroup,
    getTotalQuantityAfter,
    // getTotalQuantityBefore,
} from '@app/utils/foodkid.data';
import _globalUtils from '@app/utils/global.utils';
import { numberToPrice } from '@app/utils/utils';
import _ from 'lodash';
import moment from 'moment';
import update from 'immutability-helper';
import reportUtils from '@app/utils/reportUtils';
import { GLOBAL } from '@app/constants/global.const';

class _setupMarketExportPDFService {
    /**
     * Tạo dữ liệu cho Sổ tính tiền chợ hoặc Bảng công khai tài chính
     * @param {Array} schoolFoods : Thực phẩm trường
     * @param {Array} menuSchoolFoods : thực phẩm chợ
     * @param {Array} listwarehouse : thực phẩm kho
     * @param {Array} difference : Dư đầu ngày
     * @param {Array} report : report
     * @param {Array} service_report : report dịch vụ
     * @param {Array} services : list dịch vụ
     * @param {Array} units : list đơn vị tính
     * @param {Array} menulist : list menu
     * @param {Array} kidgroups : danh sách nhóm trẻ
     * @param {object} locationInfo : thông tin địa chỉ
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     * @param {number} date : ngày in
     * @param {String} nameFile: tên prefix file khi xuất ra
     */
    doExport = async (
        schoolFoods,
        menuSchoolFoods,
        listwarehouse,
        difference,
        difference_start_month,
        report,
        service_report,
        services,
        units,
        menulist,
        kidGroup,
        locationInfo,
        schoolInfo,
        organizationInfo,
        date,
        nameFile,
        type = REPORT_TYPE.SO_TINH_TIEN_CHO
    ) => {
        const _nameFile = nameFile || 'SoTinhTienCho';
        // console.log(menulist);
        const kidgroups = kidGroup.filter((o) =>
            menulist.find((x) => x.kg_id === o.kg_id)
        );
        const index_kg = kidgroups.length;

        const foods = listwarehouse
            .map((item, idx) => {
                item.stt = idx + 1;
                item.warehouse = 'Tiền xuất kho';
                return item;
            })
            .sort((a, b) => b.stt - a.stt)
            .concat(
                menuSchoolFoods
                    .map((item, idx) => {
                        item.stt = idx + 1;
                        item.warehouse = 'Tiền chợ';
                        return item;
                    })
                    .sort((a, b) => b.stt - a.stt)
            );

        const info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            school: schoolInfo.ten_truong,
            administrators: schoolInfo?.ho_ten_hieu_pho_ban_tru || '', // HPBT
            administrators_title:
                schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_ASSISTANT_TITLE, // HPBT tiêu đề
            creator_title: 'Người lập phiếu', // Tiêu đề người lập bảng
            creator: schoolInfo?.ten_nguoi_lap_bang || '', // Tên người lập bảng
            accountant: schoolInfo?.ho_ten_ke_toan || '', // Kế toán
            chef: schoolInfo?.ho_ten_bep_truong || '', // Tên bếp chính
            supplies: schoolInfo?.ten_ban_tiep_pham || '', // Tên ban tiếp phẩm
            nhomtre1: index_kg >= 1 ? kidgroups[0].kg_name : '',
            nhomtre2: index_kg >= 2 ? kidgroups[1].kg_name : '',
            nhomtre3: index_kg >= 3 ? kidgroups[2].kg_name : '',
            nhomtre4: index_kg >= 4 ? kidgroups[3].kg_name : '',
            date: moment.unix(date).format('DD-MM-YYYY'),
            a_nhomtre1_price:
                index_kg >= 1
                    ? getDifferencePriceMenuByKidGroupAndDay(
                          difference,
                          kidgroups[0].kg_id
                      )
                    : '',
            b_nhomtre1_price:
                index_kg >= 1
                    ? getPriceMenuByKidGroup(menulist, kidgroups[0].kg_id)
                    : '',
            c_nhomtre1_price:
                index_kg >= 1
                    ? getPriceAfterMenuByKidGroup(
                          menulist,
                          kidgroups[0].kg_id,
                          difference
                      )
                    : '',
            d_nhomtre1_price:
                index_kg >= 1
                    ? getTotalPriceMenuByKidGroup(menulist, kidgroups[0].kg_id)
                    : '',
            e_nhomtre1_price:
                index_kg >= 1
                    ? getReportPriceBeforeByKidGroup(
                          report,
                          difference,
                          kidgroups[0].kg_id
                      )
                    : '',

            a_nhomtre2_price:
                index_kg >= 2
                    ? getDifferencePriceMenuByKidGroupAndDay(
                          difference,
                          kidgroups[1].kg_id
                      )
                    : '',
            b_nhomtre2_price:
                index_kg >= 2
                    ? getPriceMenuByKidGroup(menulist, kidgroups[1].kg_id)
                    : '',
            c_nhomtre2_price:
                index_kg >= 2
                    ? getPriceAfterMenuByKidGroup(
                          menulist,
                          kidgroups[1].kg_id,
                          difference
                      )
                    : '',
            d_nhomtre2_price:
                index_kg >= 2
                    ? getTotalPriceMenuByKidGroup(menulist, kidgroups[1].kg_id)
                    : '',
            e_nhomtre2_price:
                index_kg >= 2
                    ? getReportPriceBeforeByKidGroup(
                          report,
                          difference,
                          kidgroups[1].kg_id
                      )
                    : '',

            a_nhomtre3_price:
                index_kg >= 3
                    ? getDifferencePriceMenuByKidGroupAndDay(
                          difference,
                          kidgroups[2].kg_id
                      )
                    : '',
            b_nhomtre3_price:
                index_kg >= 3
                    ? getPriceMenuByKidGroup(menulist, kidgroups[2].kg_id)
                    : '',
            c_nhomtre3_price:
                index_kg >= 3
                    ? getPriceAfterMenuByKidGroup(
                          menulist,
                          kidgroups[2].kg_id,
                          difference
                      )
                    : '',
            d_nhomtre3_price:
                index_kg >= 3
                    ? getTotalPriceMenuByKidGroup(menulist, kidgroups[2].kg_id)
                    : '',
            e_nhomtre3_price:
                index_kg >= 3
                    ? getReportPriceBeforeByKidGroup(
                          report,
                          difference,
                          kidgroups[2].kg_id
                      )
                    : '',

            a_nhomtre4_price:
                index_kg >= 4
                    ? getDifferencePriceMenuByKidGroupAndDay(
                          difference,
                          kidgroups[3].kg_id
                      )
                    : '',
            b_nhomtre4_price:
                index_kg >= 4
                    ? getPriceMenuByKidGroup(menulist, kidgroups[3].kg_id)
                    : '',
            c_nhomtre4_price:
                index_kg >= 4
                    ? getPriceAfterMenuByKidGroup(
                          menulist,
                          kidgroups[3].kg_id,
                          difference
                      )
                    : '',
            d_nhomtre4_price:
                index_kg >= 4
                    ? getTotalPriceMenuByKidGroup(menulist, kidgroups[3].kg_id)
                    : '',
            e_nhomtre4_price:
                index_kg >= 4
                    ? getReportPriceBeforeByKidGroup(
                          report,
                          difference,
                          kidgroups[3].kg_id
                      )
                    : '',

            a_nhomtre1_quantiry: '',
            b_nhomtre1_quantiry:
                index_kg >= 1
                    ? getQuantityBeforeMenuByKidGroup(
                          menulist,
                          kidgroups[0].kg_id
                      )
                    : '',
            c_nhomtre1_quantiry:
                index_kg >= 1
                    ? getQuantityAfterMenuByKidGroup(
                          menulist,
                          kidgroups[0].kg_id
                      )
                    : '',
            d_nhomtre1_quantiry: '',
            e_nhomtre1_quantiry:
                index_kg >= 1
                    ? getReportQuantityByKidGroup(report, kidgroups[0].kg_id)
                    : '',
            f_nhomtre1_price:
                index_kg >= 1
                    ? getReportPriceAfterByKidGroup(
                          report,
                          service_report,
                          kidgroups[0].kg_id
                      )
                    : '',
            g_nhomtre1_price:
                index_kg >= 1
                    ? numberToPrice(
                          _.sumBy(
                              service_report.filter(
                                  (i) => i.kg_id === kidgroups[0].kg_id
                              ),
                              (x) => x.mms_price * x.quantity
                          )
                      )
                    : '',
            y_nhomtre1_price:
                index_kg >= 1
                    ? getReportInwarehouseByKidGroup(report, kidgroups[0].kg_id)
                    : '',
            k_nhomtre1_price:
                index_kg >= 1
                    ? getReportNotInwarehouseByKidGroup(
                          report,
                          kidgroups[0].kg_id
                      )
                    : '',
            l_nhomtre1_price:
                index_kg >= 1
                    ? getReportSurplusByKidGroup(
                          report,
                          difference_start_month,
                          kidgroups[0].kg_id
                      )
                    : '',

            a_nhomtre2_quantiry: '',
            b_nhomtre2_quantiry:
                index_kg >= 2
                    ? getQuantityBeforeMenuByKidGroup(
                          menulist,
                          kidgroups[1].kg_id
                      )
                    : '',
            c_nhomtre2_quantiry:
                index_kg >= 2
                    ? getQuantityAfterMenuByKidGroup(
                          menulist,
                          kidgroups[1].kg_id
                      )
                    : '',
            d_nhomtre2_quantiry: '',
            e_nhomtre2_quantiry:
                index_kg >= 2
                    ? getReportQuantityByKidGroup(report, kidgroups[1].kg_id)
                    : '',
            f_nhomtre2_price:
                index_kg >= 2
                    ? getReportPriceAfterByKidGroup(
                          report,
                          service_report,
                          kidgroups[1].kg_id
                      )
                    : '',
            g_nhomtre2_price:
                index_kg >= 2
                    ? numberToPrice(
                          _.sumBy(
                              service_report.filter(
                                  (i) => i.kg_id === kidgroups[1].kg_id
                              ),
                              (x) => x.mms_price * x.quantity
                          )
                      )
                    : '',
            y_nhomtre2_price:
                index_kg >= 2
                    ? getReportInwarehouseByKidGroup(report, kidgroups[1].kg_id)
                    : '',
            k_nhomtre2_price:
                index_kg >= 2
                    ? getReportNotInwarehouseByKidGroup(
                          report,
                          kidgroups[1].kg_id
                      )
                    : '',
            l_nhomtre2_price:
                index_kg >= 2
                    ? getReportSurplusByKidGroup(
                          report,
                          difference_start_month,
                          kidgroups[1].kg_id
                      )
                    : '',

            a_nhomtre3_quantiry: '',
            b_nhomtre3_quantiry:
                index_kg >= 3
                    ? getQuantityBeforeMenuByKidGroup(
                          menulist,
                          kidgroups[2].kg_id
                      )
                    : '',
            c_nhomtre3_quantiry:
                index_kg >= 3
                    ? getQuantityAfterMenuByKidGroup(
                          menulist,
                          kidgroups[2].kg_id
                      )
                    : '',
            d_nhomtre3_quantiry: '',
            e_nhomtre3_quantiry:
                index_kg >= 3
                    ? getReportQuantityByKidGroup(report, kidgroups[2].kg_id)
                    : '',
            f_nhomtre3_price:
                index_kg >= 3
                    ? getReportPriceAfterByKidGroup(
                          report,
                          service_report,
                          kidgroups[2].kg_id
                      )
                    : '',
            g_nhomtre3_price:
                index_kg >= 3
                    ? numberToPrice(
                          _.sumBy(
                              service_report.filter(
                                  (i) => i.kg_id === kidgroups[2].kg_id
                              ),
                              (x) => x.mms_price * x.quantity
                          )
                      )
                    : '',
            y_nhomtre3_price:
                index_kg >= 3
                    ? getReportInwarehouseByKidGroup(report, kidgroups[2].kg_id)
                    : '',
            k_nhomtre3_price:
                index_kg >= 3
                    ? getReportNotInwarehouseByKidGroup(
                          report,
                          kidgroups[2].kg_id
                      )
                    : '',
            l_nhomtre3_price:
                index_kg >= 3
                    ? getReportSurplusByKidGroup(
                          report,
                          difference_start_month,
                          kidgroups[2].kg_id
                      )
                    : '',

            a_nhomtre4_quantiry: '',
            b_nhomtre4_quantiry:
                index_kg >= 4
                    ? getQuantityBeforeMenuByKidGroup(
                          menulist,
                          kidgroups[3].kg_id
                      )
                    : '',
            c_nhomtre4_quantiry:
                index_kg >= 4
                    ? getQuantityAfterMenuByKidGroup(
                          menulist,
                          kidgroups[3].kg_id
                      )
                    : '',
            d_nhomtre4_quantiry: '',
            e_nhomtre4_quantiry:
                index_kg >= 4
                    ? getReportQuantityByKidGroup(report, kidgroups[3].kg_id)
                    : '',
            f_nhomtre4_price:
                index_kg >= 4
                    ? getReportPriceAfterByKidGroup(
                          report,
                          service_report,
                          kidgroups[3].kg_id
                      )
                    : '',
            g_nhomtre4_price:
                index_kg >= 4
                    ? numberToPrice(
                          _.sumBy(
                              service_report.filter(
                                  (i) => i.kg_id === kidgroups[3].kg_id
                              ),
                              (x) => x.mms_price * x.quantity
                          )
                      )
                    : '',
            y_nhomtre4_price:
                index_kg >= 4
                    ? getReportInwarehouseByKidGroup(report, kidgroups[3].kg_id)
                    : '',
            k_nhomtre4_price:
                index_kg >= 4
                    ? getReportNotInwarehouseByKidGroup(
                          report,
                          kidgroups[3].kg_id
                      )
                    : '',
            l_nhomtre4_price:
                index_kg >= 4
                    ? getReportSurplusByKidGroup(
                          report,
                          difference_start_month,
                          kidgroups[3].kg_id
                      )
                    : '',

            a_total_quantity: '',
            a_total_unit_price: '',
            a_total_price: getDifferencePriceByDay(difference),
            a_total_unit: '',

            b_total_quantity: getTotalQuantityAfter(menulist),
            b_total_unit_price: '',
            b_total_price: getPriceAfter(menulist),
            b_total_unit: '',

            c_total_quantity: getTotalQuantityAfter(menulist),
            c_total_unit_price: '',
            c_total_price: allowedToSpend(menulist, difference),
            c_total_unit: '',

            d_total_quantity: '',
            d_total_unit_price: '',
            d_total_price: getTotalPriceBefore(menulist),
            d_total_unit: '',

            e_total_quantity: numberToPrice(
                report.reduce((sum, x) => sum + x.quantity, 0)
            ),
            e_total_unit_price: '',
            e_total_price: numberToPrice(
                report.reduce((sum, x) => sum + x.pricebefore, 0)
            ),
            e_total_unit: '',

            f_total_quantity: '',
            f_total_unit_price: '',
            f_total_price: numberToPrice(
                report.reduce((sum, x) => sum + x.priceafter, 0)
            ),
            f_total_unit: '',

            g_total_quantity: '',
            g_total_unit_price: '',
            g_total_price: numberToPrice(
                service_report.reduce(
                    (sum, x) => sum + x.mms_price * x.quantity,
                    0
                )
            ),
            g_total_unit: '',

            y_total_quantity: '',
            y_total_unit_price: '',
            y_total_price: numberToPrice(
                report.reduce((sum, x) => sum + x.inwarehouse, 0)
            ),
            y_total_unit: '',

            k_total_quantity: '',
            k_total_unit_price: '',
            k_total_price: numberToPrice(
                report.reduce((sum, x) => sum + x.notinwarehouse, 0)
            ),
            k_total_unit: '',

            l_total_quantity: '',
            l_total_unit_price: '',
            l_total_price: getReportSurplus(report, difference_start_month),
            l_total_unit: '',
        };
        const menufoods = foods.map((item) => {
            const food = new MarketCashReportModel();
            const sf = schoolFoods.find((o) => o.sf_id === item.items[0].sf_id);
            const unit = units.find((o) => o.cu_id === sf.cu_id);

            food.stt = item.stt;
            food.f_name = item.name;
            food.warehouse = item.warehouse;
            item.items
                .sort((a, b) => a.kg_id - b.kg_id)
                .forEach((element) => {
                    kidgroups.forEach((kg, index) => {
                        if (element.kg_id === kg.kg_id) {
                            food[`price_${index + 1}`] = numberToPrice(
                                element.msf_totalquantityafter *
                                    element.msf_priceafter
                            );
                            food[`sf_quantiry_${index + 1}`] =
                                element.msf_totalquantityafter.toFixed(2);
                        }
                    });
                });
            food.total_quantity = getFoodQuantityMarket(item.items);
            food.total_unit_price = numberToPrice(item.price);
            // food.total_unit_price = getFoodPriceMarket(item.items);
            food.total_price = getFoodTotalPriceMarket(item.items, kidgroups);
            food.total_unit = unit.cu_name;

            return food;
        });

        const menukidgroups = kidgroups.map((item) => {
            const age = new MarketCashAgeReportModel();
            age.m_quantity = menulist.filter(
                (o) => o.kg_id === item.kg_id
            )[0]?.m_quantityafter;
            age.m_id = menulist.filter(
                (o) => o.kg_id === item.kg_id
            )[0]?.m_code;
            age.kg_name = item?.kg_name;
            return age;
        });

        const menuservices = services
            .reduce((r, a) => {
                if (r.length === 0) {
                    r = update(r, {
                        $push: [
                            {
                                id: a.ms_id,
                                ms_name: a.ms_name,
                                kg_id: a.kg_id,
                                price: a.mms_price,
                                items: [].concat(a),
                            },
                        ],
                    });
                } else {
                    const item = r.find((o) => o.id === a.ms_id);
                    if (item !== undefined) {
                        r.map((f) => {
                            if (f.id === a.ms_id) {
                                f.items.push(a);
                            }
                            return f;
                        });
                    } else {
                        r = update(r, {
                            $push: [
                                {
                                    id: a.ms_id,
                                    ms_name: a.ms_name,
                                    kg_id: a.kg_id,
                                    price: a.mms_price,
                                    items: [].concat(a),
                                },
                            ],
                        });
                    }
                }

                return r;
            }, [])
            .sort((a, b) => a.id - b.id)
            .map((element, i) => {
                const s = new AggregateDayServiceModel();
                s.stt = i + 1;
                s.s_name = element.ms_name;
                s.service_price = numberToPrice(element.price);
                kidgroups.forEach((kg, kgIndex) => {
                    let _sPrice = '';
                    let _sQuantity = '';

                    const _itemCheck = element.items.find(
                        (ms) => ms.kg_id === kg.kg_id
                    );

                    if (_itemCheck) {
                        _sPrice = numberToPrice(
                            +_itemCheck.mms_price * +_itemCheck.quantity
                        );
                        _sQuantity = _itemCheck.quantity.toFixed(0);
                    }

                    s[`service_price_${kgIndex + 1}`] = _sPrice;
                    s[`service_quantity_${kgIndex + 1}`] = _sQuantity;
                });
                s.service_price_total = numberToPrice(
                    element.items.reduce(
                        (a, v) => a + v.mms_price * v.quantity,
                        0
                    )
                );
                s.service_quantity_total = element.items.reduce(
                    (a, v) => a + v.quantity,
                    0
                );
                return s;
            });

        const res = await exportReportToPDF({
            data: JSON.stringify({
                info,
                foods: menufoods,
                kidgroups: menukidgroups,
                kidgroupmeals: [],
                services: menuservices,
            }),
            report: type,
        });
        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `${_nameFile}_${info.date}_${organizationInfo.o_name}`
            );
        }
        return true;
    };
}

export default new _setupMarketExportPDFService();
