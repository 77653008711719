const updateListIntoList = (datas, dataList, fieldId) => {
    // Kiểm tra đối tượng có nằm trong danh sách không
    datas.forEach((dataItem) => {
        const itemCheck = dataList.find(
            (o) => o[fieldId] === dataItem[fieldId]
        );

        if (itemCheck) {
            dataList = dataList.map((o) => {
                if (o[fieldId] === itemCheck[fieldId]) {
                    o = {
                        ...o,
                        ...dataItem,
                    };
                }
                return o;
            });
        } else {
            dataList = [dataItem, ...dataList];
        }
    });
    return dataList;
};

export { updateListIntoList };
