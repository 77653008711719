import moment from 'moment';
import { IMAGE_FOLDER_UPLOAD, IMAGE_SERVER_SERVICE_URL } from '@app/constants';

export class AccountUserModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.su_id = data.su_id || 0;
        this.o_id = data.o_id || '';
        this.sr_id = data.sr_id || 0;
        this.su_lastlogin = data.su_lastlogin || moment(new Date()).unix();
        this.su_status = data.su_status || true;
        this.su_username = data.su_username || '';
        this.su_password = data.su_password || 'abc123';
        this.su_firstname = data.su_firstname || '';
        this.su_lastname = data.su_lastname || '';
        this.su_dateofbirth = data.su_dateofbirth
            ? moment.unix(data.su_dateofbirth)
            : moment(new Date()) || moment(new Date());
        this.su_gender = data.su_gender || 0;
        this.su_homephone = data.su_homephone || '';
        this.su_cellphone = data.su_cellphone || '';
        this.su_email = data.su_email || '';
        this.su_avatar = data.su_avatar || '';
        this.su_note = data.su_note || '';

        this.currentImg = data.su_avatar
            ? `${IMAGE_SERVER_SERVICE_URL}/${IMAGE_FOLDER_UPLOAD.PARENT}/${data.o_id}/${data.su_avatar}`
            : '';
        this.img = data.img || {};
        this.isUpload = !!data.isUpload;
    }
}
