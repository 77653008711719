import { apiGetFunctionByCate } from '@app/apis/accounts/accountFunction';
import { apiGetFuncCategoryByModule } from '@app/apis/accounts/accountFunctionCategory';
import PageHeader from '@app/components/Layout/PageHeader/PageHeader';
import NoData from '@app/components/Layout/Shares/NoData';
import { STATUS_CODES } from '@app/constants/api';
import {
    accountPrivilegeActions,
    accountPrivilegeSelector,
} from '@app/redux/account/accountPrivilege/accountPrivilege.slice';
import co from 'co';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountPrivilegeFilter from './Filter';
import AccountPrivilegeItem from './Item';
import ListPrivilege from './List';

const MODULE_SERVICE = 12;

const AccountPrivilege = () => {
    /** ========= SELECTOR ========= */
    const privilegeList = useSelector(accountPrivilegeSelector.privilegeList);

    /** ========= STATE ========= */
    const [roleId, setRoleId] = useState(0);
    const [funcCateId, setFuncCateId] = useState(-1);

    const [listFuncionCate, setListFunctionCate] = useState([]);
    const [listFuncion, setListFunction] = useState([]);

    /** ========= DEFINE DISPATCH ========= */
    const dispatch = useDispatch();

    /** ========= CALL DATA FIRST ========= */
    useEffect(() => {
        co(function* () {
            const { status, data } = yield apiGetFuncCategoryByModule(
                MODULE_SERVICE
            );

            setListFunctionCate(
                status === STATUS_CODES.SUCCESS ? data.data : []
            );
        });

        return () => {
            dispatch(accountPrivilegeActions.doReset());
        };
    }, [dispatch]);

    useEffect(() => {
        co(function* () {
            const cateCode =
                funcCateId === -1
                    ? -1
                    : listFuncionCate.find((o) => o.sfc_id === funcCateId)
                          ?.sfc_code || 0;

            const { status, data } = yield apiGetFunctionByCate(
                MODULE_SERVICE,
                cateCode
            );

            setListFunction(status === STATUS_CODES.SUCCESS ? data.data : []);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, funcCateId]);

    useEffect(() => {
        if (roleId && funcCateId) {
            const model = {
                sfc_id: funcCateId,
                sr_id: roleId,
            };

            dispatch(accountPrivilegeActions.doGetAccountPrivilege(model));
        }
    }, [dispatch, funcCateId, roleId]);

    /** ========= HIỂN THỊ DANH SÁCH PHÂN QUYỀN THEO VAI TRÒ & NHÓM TINH NĂNG ========= */
    const showData = () => {
        let xhtml = null;

        if (privilegeList.length === 0) return <NoData colspan={12} />;

        const mapPrivilege = privilegeList.reduce((rsList, item) => {
            rsList[item.sf_id] = item;

            return rsList;
        }, {});

        const listFuncCateFilter =
            funcCateId === -1
                ? listFuncionCate
                : listFuncionCate.filter((o) => o.sfc_id === funcCateId);

        const mapListFunc = listFuncCateFilter.map((item) => ({
            ...item,
            isParent: true,
            sortOrder: item.sfc_sortorder,
            listChildren: listFuncion
                .filter((x) => x.sfc_id === item.sfc_id)
                .map((x) => ({
                    ...mapPrivilege[x.sf_code],
                    sf_name: x.sf_name,
                    sf_sortorder: x.sf_sortorder,
                })),
        }));

        if (mapListFunc.length > 0) {
            xhtml = mapListFunc.map((item, index) => (
                <AccountPrivilegeItem
                    stt={index}
                    key={index}
                    accountPrivilege={item}
                />
            ));
        }

        return xhtml;
    };

    /** ========= THAY ĐỔI VAI TRÒ ========= */
    const onChangeRole = (id) => {
        setRoleId(id);
    };

    /** ========= THAY ĐỔI NHÓM TÍNH NĂNG ========= */
    const onChangeFc = (id) => {
        setFuncCateId(id);
    };

    return (
        <Fragment>
            <PageHeader pageTitle="Cấp quyền sử dụng" />

            <div className="content">
                <AccountPrivilegeFilter
                    onChangeRole={onChangeRole}
                    onChangeFc={onChangeFc}
                />
                <ListPrivilege>{showData()}</ListPrivilege>
            </div>
        </Fragment>
    );
};

export default AccountPrivilege;
