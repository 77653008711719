/**
 * Lấy thông tin học sinh lớp học theo năm học
 * @param {number} sy_id: Mã năm học
 */
export const getStudentClass = (studentItem, sy_id) => {
    let studentClass = {};
    if (studentItem) {
        const { list_student_class } = studentItem;
        if (list_student_class && list_student_class.length > 0) {
            studentClass = list_student_class.find(
                (o) => o.sy_id === sy_id && o.s_id === studentItem.s_id
            );
        }
    }

    return studentClass;
};

/**
 * Lấy thông tin học sinh lớp học theo năm học và lớp học
 * @param {number} sy_id: Mã năm học
 * @param {number} c_id: Mã lớp học
 */
export const getStudentClassByYearClass = (studentItem, sy_id, c_id) => {
    let studentClass = {};
    if (studentItem) {
        const { list_student_class } = studentItem;
        if (list_student_class && list_student_class.length > 0) {
            studentClass = list_student_class.find(
                (o) =>
                    o.sy_id === sy_id &&
                    o.s_id === studentItem.s_id &&
                    o.c_id === c_id
            );
        }
    }

    return studentClass;
};

/**
 * Lấy STT học sinh theo năm học
 * @param {number} sy_id: Mã năm học
 */
export const getSortOrder = (studentItem, sy_id) => {
    let _sortOrder = 0;
    if (studentItem) {
        const stuClass = getStudentClass(studentItem, sy_id);
        _sortOrder = !_.isEmpty(stuClass) ? stuClass.sc_sortorder : 1;
    }
    return _sortOrder;
};

/**
 * Lấy STT học sinh theo năm học
 * @param {number} sy_id: Mã năm học
 * @param {number} c_id: Mã lớp học
 */
export const getSortOrderByYearClass = (studentItem, sy_id, c_id) => {
    let _sortOrder = 0;
    if (studentItem) {
        const stuClass = getStudentClassByYearClass(studentItem, sy_id, c_id);
        _sortOrder = !_.isEmpty(stuClass) ? stuClass.sc_sortorder : 1;
    }
    return _sortOrder;
};
