/* eslint-disable no-return-assign */
import { FoodReceiptModel } from '@app/models/inventory/foodReceipt.model';
import { FoodReceiptOutDetailModel } from '@app/models/inventory/foodReceiptOutDetail.model';
import globalUtils from '@app/utils/global.utils';
import { createCodeInventory } from '@app/utils/utils';
import _ from 'lodash';
import moment from 'moment';
import * as types from './inventory.constant';

const initState = {
    inventoryList: [],
    inventoryByMenuList: [],
    isShowModalIn: false,
    isShowModalOut: false,
};

const inventoryReducer = (state = initState, action) => {
    switch (action.type) {
        case types.OPEN_INVENTORY_MODAL: {
            return {
                ...state,
                isShowModal: true,
            };
        }
        case types.CLOSE_INVENTORY_MODAL: {
            return {
                ...state,
                isShowModal: false,
            };
        }
        case types.GET_INVENTORY_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                inventoryList: data,
            };
        }

        case types.GET_MENU_SUCCESS: {
            const { data, type, warehouse, creatorName } = action.payload;

            const menus = _.chain(data.list)
                .groupBy('m_datefounded')
                .map((value, key) => ({
                    m_datefounded: key,
                    ids: value.map((o) => o.m_id),
                    datas: value,
                }))
                .value();
            const mds = [];

            if (warehouse.w_breakfast === 1) {
                mds.push(1);
            }
            if (warehouse.w_lunch === 1) {
                mds.push(2);
            }
            if (warehouse.w_dinner === 3) {
                mds.push(1);
            }
            if (warehouse.w_snack === 1) {
                mds.push(4);
            }

            const receipts = menus
                .map((o) => {
                    const foods = data.menuSchoolFoods
                        .filter((x) => {
                            if (type === 1) {
                                return (
                                    o.ids.find((j) => j === x.m_id) &&
                                    x.msf_useinwarehouse
                                );
                            }

                            return (
                                o.ids.find((j) => j === x.m_id) &&
                                mds.find((f) => f === x.msf_meal) &&
                                x.msf_useinwarehouse
                            );
                        })
                        .map((f) => {
                            const receiptDetail =
                                new FoodReceiptOutDetailModel();
                            receiptDetail.frd_foodname = f.f_name;
                            receiptDetail.f_id = f.f_id;
                            receiptDetail.frd_priceexport = f.msf_priceafter;
                            receiptDetail.frd_totalexport = parseFloat(
                                (
                                    f.msf_totalquantityafter * f.msf_priceafter
                                ).toFixed(2)
                            );
                            receiptDetail.frd_quantityexport =
                                f.msf_totalquantityafter;
                            receiptDetail.sf_id = f.sf_id;
                            return receiptDetail;
                        });
                    // const groups = _.groupBy(foods, 'sf_id');
                    const results = [];
                    // console.log(
                    foods.reduce((res, value) => {
                        if (!res[value.sf_id]) {
                            // value.frd_totalexport = 0;

                            res[value.sf_id] = {
                                ...value,
                                msf_totalquantityafter: 0,
                                frd_quantityexport: 0,
                                frd_totalexport: 0,
                            };
                            results.push(res[value.sf_id]);
                        }
                        res[value.sf_id].msf_totalquantityafter +=
                            value.frd_quantityexport;
                        res[value.sf_id].frd_quantityexport +=
                            value.frd_quantityexport;
                        res[value.sf_id].frd_totalexport +=
                            value.frd_totalexport;
                        return res;
                    }, {});
                    const receipt = new FoodReceiptModel();
                    receipt.w_id = warehouse.w_id;
                    receipt.fr_id = 0;
                    receipt.fr_code = createCodeInventory(2, o.m_datefounded);

                    const check =
                        moment.unix(o.m_datefounded).month() ===
                        moment
                            .unix(o.m_datefounded)
                            .startOf('isoWeek')
                            .isoWeekday(1)
                            .month();
                    receipt.dateweek = !check
                        ? moment.unix(o.m_datefounded).startOf('month').unix()
                        : moment
                              .unix(o.m_datefounded)
                              .startOf('isoWeek')
                              .isoWeekday(1)
                              .unix();
                    receipt.fr_type = 2;
                    receipt.fr_datefounded = o.m_datefounded;
                    receipt.fr_creator = creatorName;
                    receipt.fr_deliverorreceiver = '';
                    receipt.fr_address = '';
                    receipt.fr_description = '';
                    receipt.fr_totalimport = 0;
                    receipt.fr_totalexport = results.reduce(
                        (a, v) => (a += v.frd_totalexport),
                        0
                    );
                    receipt.foodList = results;
                    return receipt;
                })
                .filter((o) => o.foodList.length > 0);

            return {
                ...state,
                inventoryByMenuList: receipts,
            };
        }

        case types.UPDATE: {
            const { data, status } = action.payload;
            const list = _.cloneDeep(state.inventoryByMenuList).map((o) => {
                const item = data.find(
                    (x) => x.fr_datefounded === o.fr_datefounded
                );
                if (item !== undefined) {
                    item.status = status;
                    return item;
                }
                return o;
            });
            return {
                ...state,
                inventoryByMenuList: list,
            };
        }

        case types.SAVE_INVENTORY_SUCCESS: {
            const { data } = action.payload;
            const list = _.orderBy(
                globalUtils.updateListIntoList(
                    data,
                    state.inventoryList,
                    'fr_id'
                ),
                ['fr_datefounded'],
                ['asc']
            );
            return {
                ...state,
                inventoryList: list,
                isShowModalIn: false,
                isShowModalOut: false,
            };
        }

        case types.DELETE_INVENTORY_SUCCESS: {
            const { data } = action.payload;
            const list = state.inventoryList.filter((o) =>
                data.some((x) => x !== o.fr_id)
            );
            return {
                ...state,
                inventoryList: list,
            };
        }

        case types.OPEN_MODAL_IN: {
            return {
                ...state,
                isShowModalIn: true,
                // menuSelect: new MenuListModel(data),
            };
        }
        case types.CLOSE_MODAL_IN: {
            return {
                ...state,
                isShowModalIn: false,
                // menuSelect: new MenuListModel(),
            };
        }

        case types.OPEN_MODAL_OUT: {
            return {
                ...state,
                isShowModalOut: true,
                // menuSelect: new MenuListModel(data),
            };
        }
        case types.CLOSE_MODAL_OUT: {
            return {
                ...state,
                isShowModalOut: false,
                // menuSelect: new MenuListModel(),
            };
        }

        case types.CHECK_FOODRECEIPT_OUT: {
            const { id } = action.payload;
            // console.info(state.inventoryByMenuList, id);
            const list = _.cloneDeep(state.inventoryByMenuList).map((item) => {
                if (item.fr_datefounded === id) {
                    item = new FoodReceiptModel({
                        ...item,
                        isCheck: !item.isCheck,
                    });
                }
                return item;
            });
            const listCheck = list.filter((item) => item.isCheck === true);
            const countList = state.inventoryByMenuList.length;
            const countListCheck = listCheck.length;
            const checkAll = !(countList > countListCheck);
            return {
                ...state,
                inventoryByMenuList: list,
                isCheckAll: checkAll,
            };
        }
        case types.CHECK_ALL: {
            const list = state.inventoryByMenuList.map(
                (item) =>
                    new FoodReceiptModel({
                        ...item,
                        isCheck: !state.isCheckAll,
                    })
            );
            return {
                ...state,
                isCheckAll:
                    state.inventoryByMenuList.length > 0
                        ? !state.isCheckAll
                        : false,
                inventoryByMenuList: list,
            };
        }

        default:
            return { ...state };
    }
};

export default inventoryReducer;
