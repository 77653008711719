import axiosService from '@app/services/axiosService';
import { API_SC_GATEWAY_ENDPOINT } from '@app/constants/api';
import { ApiGatewayDataPost } from '@app/models/common/apiGatewayDataPost.model';

const LIST_URL = {
    TRANSIT_STATION: `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/TransitStation`,
};

const _icInvoiceApis = {
    /**
     * Lấy danh sách biên lai thu tiền
     * @param {object} dataPost : dữ liệu gửi lên
     * @param {string} dataPost.oId : Id tổ chức
     * @param {Array} dataPost.listInvoiceId: Danh sách Id hóa đơn/biên lai
     * @returns
     */
    getByListId(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'AdminInvoice',
            action: 'GetByListId',
            dataPost: {
                ...dataPost,
                orgId: dataPost.oId,
            },
        });
        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    },
};

export default _icInvoiceApis;
