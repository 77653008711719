import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as _mealSeviceApis from '@app/apis/foodkid/mealService';
import { Select } from 'antd';

const { Option } = Select;

const propTypes = {
    onChange: PropTypes.func,
    isNull: PropTypes.bool,
};

const nullItem = {
    ms_id: -1,
    ms_name: '----',
};

function FKMealServiceSelect({ onChange, label, oId, isNull }) {
    const [selectId, setSelectId] = useState();
    const [list, setList] = useState([]);
    const isFirst = useRef(true);

    const onReset = () => {
        setSelectId('');
        setList([]);
    };

    const getData = async (_oId) => {
        const { status, data } = await _mealSeviceApis.apiGetListByOId(_oId);
        if (status === 200) {
            let _listTemp = data.data;
            let _selectIdTemp = '';
            if (isNull) {
                _listTemp = [nullItem, ..._listTemp];
            }
            if (_listTemp.length > 0) {
                _selectIdTemp = _listTemp[0].ms_id;
            }
            setSelectId(_selectIdTemp);
            setList(_listTemp);
            if (onChange) {
                onChange(_selectIdTemp);
            }
        } else {
            onReset();
        }
        isFirst.current = false;
    };

    useEffect(() => {
        if (oId) {
            getData(oId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oId]);

    useEffect(() => {
        if (isFirst.current) return;
        let _listTemp = list;
        let _selectId = -1;
        if (isNull) {
            _listTemp = [nullItem, ..._listTemp];
        } else {
            _listTemp = _listTemp.filter((o) => o.ms_id !== -1);
        }
        setList(_listTemp);

        if (_listTemp.length > 0) {
            _selectId = _listTemp[0].ms_id;
        }
        setSelectId(_selectId);
        if (onChange) {
            onChange(_selectId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNull]);

    const onSearch = (input, option) => {
        if (option) {
            return (
                nonAccentVietnamese(option.children.replace(/\s/g, ''))
                    .toLowerCase()
                    .indexOf(
                        nonAccentVietnamese(
                            input.replace(/\s/g, '')
                        ).toLowerCase()
                    ) >= 0
            );
        }
        return false;
    };

    const handleChange = (value) => {
        setSelectId(value);
        if (onChange) {
            onChange(value);
        }
    };

    const showOptionItem = () => {
        let xhtml = null;
        if (list.length > 0) {
            xhtml = list.map((item) => (
                <Option value={item.ms_id} key={item.ms_id}>
                    {item.ms_name}
                </Option>
            ));
        }
        return xhtml;
    };

    return (
        <>
            {label ? (
                <>
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                    <div className="clearfix mb20" />
                </>
            ) : null}
            <Select
                value={selectId}
                onChange={(value) => handleChange(value)}
                optionFilterProp="children"
                filterOption={(input, option) => onSearch(input, option)}
                showSearch
                disabled={isNull}
                className="disable-modified"
            >
                {showOptionItem()}
            </Select>
        </>
    );
}

FKMealServiceSelect.propTypes = propTypes;

export default FKMealServiceSelect;
