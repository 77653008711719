import RangeDatePicker from '@app/components/_Share/Date/RangeDate';
import { DATE_RANGE_LIMIT_TYPE } from '@app/components/_Share/Date/RangeDate/_service';
import CommonSelect from '@app/components/_Share/Select/CommonSelect';
import React, { Fragment } from 'react';

const StatusData = [
    { id: -1, name: 'Tất cả' },
    { id: 1, name: 'Chờ thanh toán' },
    { id: 2, name: 'Hoàn thành' },
    { id: 3, name: 'Hủy bỏ' },
];

const ModalTransHistoryFilter = ({
    onChangeDate,
    onChangeStatus,
    statusSelected,
}) => (
    <Fragment>
        <div className="col-md-8">
            <div className="form-group">
                <RangeDatePicker
                    rangeType={DATE_RANGE_LIMIT_TYPE.MONTH}
                    defaultRange={1}
                    limitRange={36}
                    onChange={onChangeDate}
                />
            </div>
        </div>
        <div className="col-md-4">
            <div className="form-group">
                <div className="input-group">
                    <span className="input-group-addon">Tình trạng</span>
                    <CommonSelect
                        dataSource={StatusData}
                        fieldId="id"
                        fieldName="name"
                        defaultValue={statusSelected}
                        onChange={onChangeStatus}
                    />
                </div>
            </div>
        </div>
    </Fragment>
);

export default ModalTransHistoryFilter;
