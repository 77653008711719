import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from './constants';
import * as _paymentVoucherApis from '@app/apis/income/paymentVoucher.api';
import { callLoading } from '@app/utils/saga.utils';
import { showError, showSuccess } from '@app/utils/alert';
import { ACTION_API_TYPES } from '@app/constants/global.const';
import { _paymentVoucherActs } from './slice';

function* sgGetListByDate({ payload }) {
    const { dataPost } = payload;

    yield callLoading(function* doRQ() {
        const { status, data } = yield call(
            _paymentVoucherApis.apiGetListByDate,
            dataPost
        );
        if (status === 200) {
            yield put(_paymentVoucherActs.doGetListSuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sgGetTotalOfListByDate({ payload }) {
    const { dataPost } = payload;

    yield callLoading(function* doRQ() {
        const { status, data } = yield call(
            _paymentVoucherApis.apiGetTotalOfListByDate,
            dataPost
        );
        if (status === 200) {
            yield put(_paymentVoucherActs.doGetTotalListSuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sgSave({ payload }) {
    const { dataPost } = payload;

    yield callLoading(function* doRQ() {
        const { status, data } = yield call(
            _paymentVoucherApis.apiSave,
            dataPost
        );
        if (status === 200) {
            showSuccess(ACTION_API_TYPES.ADD);
            yield put(_paymentVoucherActs.doSaveSuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sgDelete({ payload }) {
    const { dataPost } = payload;

    yield callLoading(function* doRQ() {
        const { status, data } = yield call(
            _paymentVoucherApis.apiDeleteByListId,
            dataPost
        );
        if (status === 200) {
            showSuccess(ACTION_API_TYPES.DELETE);
            yield put(_paymentVoucherActs.doDeleteSuccess(dataPost.listId));
        } else {
            showError(status, data);
        }
    });
}

function* paymentVoucherSaga() {
    yield takeLatest(types.SAVE, sgSave);
    yield takeLatest(types.GET_LIST_BY_DATE, sgGetListByDate);
    yield takeLatest(types.GET_TOTAL_OF_LIST_BY_DATE, sgGetTotalOfListByDate);
    yield takeLatest(types.DELETE, sgDelete);
}

export default paymentVoucherSaga;
