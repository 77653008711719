import React from 'react';
import AccountRoleSelect from './Dynamic';
import PropTypes from 'prop-types';

const AccountRoleSelectField = ({ input, onChange, label, disabled }) => {
    const { value: inputValue, onChange: inputOnChange } = input;

    const handleChange = (value) => {
        inputOnChange(value);
        if (onChange) {
            onChange(value);
        }
    };
    return (
        <AccountRoleSelect
            label={label}
            defaultValue={inputValue}
            onChange={(value) => handleChange(value)}
            disabled={disabled}
        />
    );
};

AccountRoleSelectField.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default AccountRoleSelectField;
