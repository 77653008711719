/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/no-redundant-roles */

import KidGroupMultiSelect from '../_Share/Select/KidGroupMultiSelect/Dynamic';
import SchoolSelector from '@app/redux/school/school.selector';
import { useSelector } from 'react-redux';
import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import { DatePicker } from 'antd';
import moment from 'moment';

/* eslint-disable react/react-in-jsx-scope */
const GeneralFilter = ({
    onChangeDate,
    setKidGroup,
    onExportPDF,
    kidGroup,
    dateTo,
    dateFrom,
}) => {
    const currentSchool = useSelector(SchoolSelector.currentSchool);

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    Từ ngày
                                </span>
                                <DatePicker
                                    onChange={(date, dateString) =>
                                        onChangeDate(
                                            date,
                                            dateString,
                                            'dateFrom'
                                        )
                                    }
                                    allowClear={false}
                                    format="DD/MM/YYYY"
                                    value={moment.unix(dateFrom)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    Đến ngày
                                </span>
                                <DatePicker
                                    onChange={(date, dateString) =>
                                        onChangeDate(date, dateString, 'dateTo')
                                    }
                                    allowClear={false}
                                    format="DD/MM/YYYY"
                                    value={moment.unix(dateTo)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    Nhóm trẻ
                                </span>
                                <KidGroupMultiSelect
                                    oId={currentSchool}
                                    defaultValue={kidGroup}
                                    onChange={(value) =>
                                        setKidGroup(
                                            Array.isArray(value)
                                                ? value
                                                : [value]
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-md-2 col-sm-4">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    Năm học
                                </span>
                                <SchoolYearSelect
                                    onChange={(_id) => onYearChange(_id)}
                                />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-md-2 col-sm-2">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    Tháng học
                                </span>
                                <MonthDynamicSelect
                                    onChange={(_id) => onMonthChange(_id)}
                                    oId={currentSchool}
                                />
                            </div>
                        </div>
                    </div> */}

                    <div className="col-md-2 col-sm-6">
                        <div className="form-group">
                            <div className="no-margin">
                                <div className="btn-group">
                                    <ButtonFilterAction
                                        labelButton="Tạo báo cáo"
                                        buttonType={
                                            BUTTON_ACTION_TYPE.EXPORT_FILE
                                        }
                                        onClick={onExportPDF}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneralFilter;
