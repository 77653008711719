import { API_ORDER_ENDPOINT } from '@app/constants/api';
import axiosService from '@app/services/axiosService';

const BASE_URL = `${API_ORDER_ENDPOINT}/v1/SaleOrderDetail`;

const LIST_URL = {
    GET_LIST_TRANSACTION: `${BASE_URL}/TransactionHistory`,
};

export const apiGetListTransaction = (data) =>
    axiosService.post(LIST_URL.GET_LIST_TRANSACTION, data);
