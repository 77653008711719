import React from 'react';
import { Modal } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import SchoolListSelect from '@app/components/Layout/Shares/SchoolSelect/SchoolListSelect';
import validate from './validate';

const SchoolModal = (props) => {
    const { handleSubmit, invalid, submitting } = props;

    const handleSubmitForm = () => {
        // if (data.p_status === true) {
        //     data.p_status = 1;
        // } else if (data.p_status === false) {
        //     data.p_status = 0;
        // }
        // data.p_namesearch = nonAccentVietnamese(data.p_name);
        // data.img = img;
        // dispatch(productActions.saveProduct(data));
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onClose}
            animation
            backdrop="static"
            size="sm"
            className="PL0"
        >
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Modal.Header closeButton className="bg-primary-800 text-white">
                    <Modal.Title>Chọn trường làm dữ liệu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-6">
                                    <div className="panel-body">
                                        <SchoolListSelect />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="submit"
                        className="btn btn-xs btn-primary bg-primary-800 btn-labeled"
                        disabled={invalid || submitting}
                    >
                        <b>
                            <i className="icon-floppy-disk" />
                        </b>
                        Lưu
                    </button>
                    <button
                        type="button"
                        className="btn btn-xs btn-labeled"
                        onClick={props.onClose}
                    >
                        <b>
                            <i className="glyphicon glyphicon-off" />
                        </b>
                        Thoát
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

const FORM_NAME = 'PRODUCT_FORM';

const SchoolModalForm = reduxForm({
    form: FORM_NAME,
    validate,
    enableReinitialize: true,
})(SchoolModal);

export default SchoolModalForm;
