import React, { Fragment } from 'react';

const AccountPrivilegeList = (props) => (
    <Fragment>
        <div className="row">
            <div className="col-lg-12">
                <div className="panel panel-white">
                    <table className="table table-togglable table-hover table-bordered">
                        <thead className="grey-light">
                            <tr>
                                <th
                                    className="text-center"
                                    style={{ width: '35px' }}
                                >
                                    STT
                                </th>
                                <th className="text-center" data-toggle="true">
                                    Tên chức năng
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hide="phone"
                                >
                                    Toàn quyền
                                </th>

                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hide="phone"
                                >
                                    Thêm
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hide="phone,tablet"
                                >
                                    Xóa
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hide="phone,tablet"
                                >
                                    Sửa
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hide="phone,tablet"
                                >
                                    Xem
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hide="phone,tablet"
                                >
                                    In
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hide="phone,tablet"
                                >
                                    Dữ liệu cá nhân
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hide="phone,tablet"
                                >
                                    Dữ liệu phòng ban
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hide="phone,tablet"
                                >
                                    Dữ liệu tổ chức
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hide="phone"
                                >
                                    Không cấp quyền
                                </th>
                            </tr>
                        </thead>
                        <tbody>{props.children}</tbody>
                    </table>
                </div>
            </div>
        </div>
    </Fragment>
);

export default AccountPrivilegeList;
