/* eslint-disable no-await-in-loop */
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import PageHeader from '@app/components/Layout/PageHeader/PageHeader';
import Filter from './Filter';
import List from './List';
import { useDispatch, useSelector } from 'react-redux';
import * as _mainActions from '@app/redux/attendance/sagaActions';
import _attendanceSelector from '@app/redux/attendance/selector';
import _schoolSelector from '@app/redux/school/school.selector';
import alertUtils, { swal } from '@app/utils/alert';
import { doChangeProcess, doResetList } from '@app/redux/attendance/slice';
import _icReceiptApis from '@app/apis/income/receipt.api';
import globalUtils from '@app/utils/global.utils';

const Receipt = () => {
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const classList = useSelector(_attendanceSelector.classList);
    const oId = useSelector(_schoolSelector.currentSchool);

    const listCheck =
        useMemo(() => classList.filter((o) => o.isCheck), [classList]) || [];

    const dispatch = useDispatch();

    useEffect(() => {
        if (year) {
            const dataPost = {
                o_id: oId,
                sy_id: year,
            };
            dispatch(_mainActions.doGetClassList(dataPost));
        }
    }, [dispatch, oId, year]);

    useEffect(
        () => () => {
            dispatch(doResetList());
        },
        [dispatch]
    );

    useEffect(() => {
        if (month) {
            dispatch(doResetList());
        }
    }, [dispatch, month]);

    const onChangeYear = (_id) => {
        setYear(_id);
    };
    const onMonthChange = (_id) => {
        setMonth(_id);
    };

    // Thêm bảng điểm danh
    const onAdd = async () => {
        if (listCheck?.length === 0) {
            swal('Thông báo', 'Bạn chưa chọn lớp cần lập phiếu thu');
            return;
            // eslint-disable-next-line no-empty
        }
        alertUtils.showLoading('Đang thực hiện tạo phiếu thu...');
        dispatch(
            doChangeProcess({
                list: listCheck,
                process: 'processing',
            })
        );
        const baseData = {
            yearId: year,
            month,
            oId,
            orgId: oId,
        };

        //const listFunc = [];

        let listRes = [];
        // Gọi hàm tạo phiếu thu
        // eslint-disable-next-line no-restricted-syntax
        for (const elem of listCheck) {
            const dataPost = {
                ...baseData,
                classId: elem.c_id,
            };

            // const _res = await apiCreateReceipt(dataPost);
            const _res = await _icReceiptApis.create(dataPost);
            listRes = [...listRes, { ..._res, dataPost }];

            // listFunc = [...listFunc, apiCreateReceipt(dataPost)];
        }

        const listFail = listRes
            .filter((o) => o.status !== 200)
            .map((o) => ({
                c_id: o.dataPost.classId,
                msg: o.data.msg,
            }));

        if (listFail.length > 0) {
            dispatch(
                doChangeProcess({
                    list: listFail,
                    process: 'error',
                })
            );
        }

        const listSuccess = listRes
            .filter((o) => o.status === 200)
            .map((o) => ({
                c_id: o.dataPost.classId,
                ...o.dataPost,
            }));
        if (listSuccess.length === 0) {
            alertUtils.closeLoading();
            return;
        }

        // Kiểm tra và gọi api thanh toán phiếu thu
        const listClassId = listSuccess.map((o) => o.classId);
        const dataPost = {
            ...baseData,
            listClassId,
        };
        alertUtils.showLoading('Đang thực hiện thu tiền...');

        const totalRes = await _icReceiptApis.getTotal(dataPost);
        if (totalRes.status !== 200) {
            dispatch(
                doChangeProcess({
                    list: listSuccess.map((o) => ({
                        ...o,
                        msg: totalRes.data.msg,
                    })),
                    process: 'error',
                })
            );
            alertUtils.closeLoading();
            return;
        }
        const total = totalRes.data.data;

        const pageCount = globalUtils.calcPagesCount(10, total);

        let listResPayment = [];
        for (let i = 1; i <= pageCount; i++) {
            const _dataPost = {
                ...dataPost,
                currentPage: i,
                pageSize: 10,
            };

            const _res = await _icReceiptApis.doPayment(_dataPost);
            listResPayment = [...listResPayment, { ..._res }];
        }

        alertUtils.closeLoading();

        const resPaymentErr = listResPayment.find((o) => o.status !== 200);
        if (resPaymentErr) {
            dispatch(
                doChangeProcess({
                    list: listSuccess.map((o) => ({
                        ...o,
                        msg: 'Thu tiền phiếu thu bị lỗi',
                    })),
                    process: 'error',
                })
            );
            return;
        }
        dispatch(
            doChangeProcess({
                list: listSuccess,
                process: 'done',
            })
        );
    };

    // Xóa
    const onDelete = async () => {
        if (listCheck?.length === 0) {
            swal('Thông báo', 'Bạn chưa chọn lớp cần xóa phiếu thu');
            // eslint-disable-next-line no-empty
        } else {
            dispatch(
                doChangeProcess({
                    list: listCheck,
                    process: 'delete_processing',
                })
            );
            const baseData = {
                yearId: year,
                month,
                oId,
                orgId: oId,
            };
            //eslint-disable-next-line no-restricted-syntax
            for (let elem of listCheck) {
                const dataPost = {
                    ...baseData,
                    classId: elem.c_id,
                };
                //eslint-disable-next-line no-await-in-loop
                const { data, status } = await _icReceiptApis.delete(dataPost);

                let process = 'delete_done';
                if (status !== 200) {
                    process = 'delete_error';
                    elem = {
                        ...elem,
                        msg: data.msg,
                    };
                }
                dispatch(
                    doChangeProcess({
                        list: [elem],
                        process,
                    })
                );
            }
        }
    };

    return (
        <Fragment>
            <PageHeader pageTitle="LẬP PHIẾU THU TỰ ĐỘNG" />
            <div className="content">
                <Filter
                    onYearChange={(_id) => onChangeYear(_id)}
                    onMonthChange={(_id) => onMonthChange(_id)}
                    isDisable={listCheck?.length === 0}
                    onAdd={() => onAdd()}
                    onDelete={() => onDelete()}
                    oId={oId}
                />

                <div className="row">
                    <div className="col-lg-12">
                        <List dataSource={classList} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Receipt;
