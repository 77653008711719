import { change, untouch } from 'redux-form';

// reset the respective fields's value with the error if any after

export const resetFields = (formName, fieldsObj, props, isNotDestroy) => {
    if (!isNotDestroy) {
        props.destroy();
        props.initialize();
    }

    Object.keys(fieldsObj).forEach((fieldKey) => {
        // reset the field's value
        props.dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));

        // reset the field's error
        props.dispatch(untouch(formName, fieldKey));
    });
};

export const setField = (formName, fieldName, value, props) => {
    if (value === 0) {
        value = '0';
    }
    props.dispatch(change(formName, fieldName, value));
};
