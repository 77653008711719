import * as types from './constants';

export const getYearCache = (data) => ({
    type: types.GET_YEAR_CACHE,
    payload: {
        data,
    },
});
export const getYearCacheSuccess = (data) => ({
    type: types.GET_YEAR_CACHE_SUCCESS,
    payload: {
        data,
    },
});
export const changeYearCacheSelect = (data) => ({
    type: types.CHANGE_YEAR_CACHE_SELECT,
    payload: {
        data,
    },
});

export const updateSchoolInvetoryCache = (data) => ({
    type: types.UPDATE_SCHOOL_INVENTORY_CACHE,
    payload: {
        data,
    },
});
