import React from 'react';
import { Modal } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import renderTextField from '@app/components/Layout/Shares/FormHelpers/TextField';
import renderSwitchField from '@app/components/Layout/Shares/FormHelpers/SwitchField';
import validate from './validate';
import PropTypes from 'prop-types';
import { REDUX_FORM_NAME } from '@app/constants/redux.form';
import ButtonModalAction from '@app/components/UI/Button/ButtonModalAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

const AccountRoleModal = ({
    isShow,
    invalid,
    submitting,
    handleSubmit,
    onClose,
    onSubmit,
}) => (
    <Modal show={isShow} onHide={onClose} animation backdrop="static" size="sm">
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton className="bg-primary-800">
                <Modal.Title>Vai trò</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                name="sr_sortorder"
                                component={renderTextField}
                                label="Số thứ tự"
                                className="form-control"
                                type="number"
                                min="1"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                name="sr_status"
                                component={renderSwitchField}
                                label="Đang sử dụng"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <Field
                                name="sr_name"
                                component={renderTextField}
                                label="Tên vai trò"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-group">
                    <ButtonModalAction
                        buttonType={BUTTON_ACTION_TYPE.SAVE}
                        disabled={invalid || submitting}
                        type="submit"
                        buttonLabel="Lưu"
                        isLeftIcon
                    />
                    <ButtonModalAction
                        buttonType={BUTTON_ACTION_TYPE.CLOSE}
                        buttonLabel="Thoát"
                        onClick={onClose}
                    />
                </div>
            </Modal.Footer>
        </form>
    </Modal>
);

AccountRoleModal.propTypes = {
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
};

const withReduxForm = reduxForm({
    form: REDUX_FORM_NAME.ACCOUNT_ROLE_FORM,
    validate,
    enableReinitialize: true,
});

export default withReduxForm(AccountRoleModal);
