import axiosService from '../../services/axiosService';
import { API_SCHOOL_ENDPOINT } from '../../constants/api';

const BASE_URL = `${API_SCHOOL_ENDPOINT}/v1/schoolConnectionHistory`;

const LIST_URL = {
    GET_LIST: `${BASE_URL}/GetList`,
};

export const apiGetList = (data) => axiosService.post(LIST_URL.GET_LIST, data);
