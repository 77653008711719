/* eslint-disable react/button-has-type */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getModalButtonType } from '../ButtonFilterAction/_service';
import '../_style.scss';

const propTypes = {
    /**
     * Tên button
     */
    buttonLabel: PropTypes.string,

    /**
     * Sự kiện click
     */
    onClick: PropTypes.func,

    /**
     * Loại button
     */
    buttonType: PropTypes.string,

    /**
     * Disable hay không
     */
    disabled: PropTypes.bool,

    /**
     * Kiểu button submit || button
     */
    type: PropTypes.string,
};

const ButtonModalAction = ({
    buttonLabel,
    onClick,
    buttonType,
    disabled,
    type,
    isLeftIcon,
}) => {
    const objButton = getModalButtonType(buttonType);
    type = type || 'button';
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (
        <Fragment>
            <button
                type={type}
                className={`${objButton.buttonClass}`}
                onClick={() => handleClick()}
                disabled={disabled}
            >
                {isLeftIcon && (
                    <b>
                        <i className={`${objButton.iconClass} position-left`} />
                    </b>
                )}

                {buttonLabel}

                {!isLeftIcon && (
                    <b>
                        <i
                            className={`${objButton.iconClass} position-right`}
                        />
                    </b>
                )}
            </button>
        </Fragment>
    );
};

ButtonModalAction.propTypes = propTypes;

export default ButtonModalAction;
