import * as copyMenuActions from '@app/redux/copymenu/copymenu.action';
import CopyMenuSelector from '@app/redux/copymenu/copymenu.selector';
import _othersSelector from '@app/redux/others/selector';
import SchoolSelector from '@app/redux/school/school.selector';
import { swal } from '@app/utils/alert';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../Layout/PageHeader/PageHeader';
import CopyMenuFilter from './CopyMenuFilter';
import CopyMenuList from './CopyMenuList';
import CopyMenuModalImport from './CopyMenuModalImport';
import _copyMenuHelpers from './_helpers';

const CopyMenu = () => {
    const [kidGroupSample, setKidGroupSample] = useState(0);
    const [kidGroup, setKidGroup] = useState(0);

    const dispatch = useDispatch();

    // -- SELECTOR OTHER
    const schoolInfo = useSelector(_othersSelector.schoolInfo);
    //const mealService = useSelector(_othersSelector.mealService);
    const proFood = useSelector(_othersSelector.proFood);

    // -- SELECTOR SCHOOL
    const currentSchool = useSelector(SchoolSelector.currentSchool);

    // -- SELECTOR COPY MENU
    const listMenu = useSelector(CopyMenuSelector.menuList);
    // const kidGroupList = useSelector(CopyMenuSelector.kidGroupList);
    const toSchoolId = useSelector(CopyMenuSelector.idToSchool);
    const rowSelected = useSelector(CopyMenuSelector.rowSelected);
    const isImport = useSelector(CopyMenuSelector.isImport);

    useEffect(
        () => () => {
            dispatch(copyMenuActions.doReset());
        },
        [dispatch]
    );

    useEffect(() => {
        if (kidGroupSample) {
            dispatch(
                copyMenuActions.doGetListMenuBySchool({
                    oId: currentSchool,
                    kidGroupId: kidGroupSample,
                })
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, kidGroupSample]);

    const onChangeSchool = (value) => {
        dispatch(copyMenuActions.doChangeSchoolCopy(value));
    };

    const onSelectedChange = (newSelectedRowKeys) => {
        const newList = listMenu.map((o) => ({
            ...o,
            is_update: newSelectedRowKeys.includes(o.m_id),
        }));

        dispatch(copyMenuActions.doUpdateMenuResult(newList));
        dispatch(copyMenuActions.doChangeSelectedRow(newSelectedRowKeys));
    };

    // const onUpdateMenuResult = (data) => {
    //     dispatch(copyMenuActions.doUpdateMenuResult([...data]));
    // };

    const onSaveMenu = async () => {
        if (currentSchool === toSchoolId) {
            swal('warning', 'Vui lòng chọn đơn vị khác để sao chép !');
            return;
        }

        const filterMenu = listMenu
            .filter((mn) => mn.is_update)
            .map((o) => ({ ...o, kg_id: kidGroup }));

        if (_.isEmpty(filterMenu)) {
            swal('warning', 'Chưa có thực đơn nào bổ sung đủ thông tin !');
        } else {
            const listIdEdit = filterMenu.map((o) => o.m_id);

            const swalRes = await swal(
                'info',
                `Bạn đang thực hiện sao chép ${listIdEdit.length} thực đơn mẫu ?`,
                true
            );

            if (swalRes.value) {
                const { isSuccess, data: schoolTargetInfo } =
                    await _copyMenuHelpers.getTargetSchoolSetting(toSchoolId);
                if (!isSuccess) {
                    return;
                }

                const mapMenuSave = listMenu.map((o) => {
                    if (listIdEdit.includes(o.m_id)) {
                        o = { ...o, status: 1 };
                    }

                    return o;
                });

                dispatch(copyMenuActions.doUpdateMenuResult(mapMenuSave));

                dispatch(
                    copyMenuActions.doSaveMenuSample({
                        orgId: schoolInfo.o_id,
                        listMenu: filterMenu,
                        toSchoolId,
                        schoolInfo: schoolTargetInfo,
                        kidGroupId: kidGroup,
                    })
                );
            }
        }
    };

    const onOpenImportCsvMenu = () => {
        dispatch(copyMenuActions.doOpenImport());
    };

    const onCloseImportCsvMenu = () => {
        dispatch(copyMenuActions.doCloseImport());
    };

    // const onCheckServiceExistSchool = (_listService) => {
    //     if (_listService?.length === 0) return { isExist: true };

    //     let isExist = true;
    //     let service_menu = '';

    //     const formatCompare = (str) =>
    //         str.toLowerCase().trim().replaceAll(' ', '');
    //     _listService.forEach((elm) => {
    //         const checkExist = mealService?.find(
    //             (o) => formatCompare(o.ms_name) === formatCompare(elm.ms_name)
    //         );

    //         if (!checkExist) {
    //             isExist = false;
    //             service_menu = elm?.ms_name;
    //         }
    //     });

    //     return { isExist, service_menu };
    // };

    const onImportMenu = async (data) => {
        const { dataPost, file } = data;

        if (_.isEmpty(file)) {
            swal('warning', 'Vui lòng chọn tập tin !');
            return;
        }

        // const { isExist, service_menu = '' } = onCheckServiceExistSchool(
        //     file?.listService
        // );

        // if (!isExist) {
        //     let renderListMeal = '';

        //     mealService.map((o, idx) => {
        //         if (idx === 0) {
        //             renderListMeal += `<tr>
        //             <td rowspan="0">${service_menu}</td>
        //             <td>${o.ms_name}</td>
        //             </tr>`;
        //         } else {
        //             renderListMeal += `<tr><td>${o.ms_name}</td></tr>`;
        //         }

        //         return o;
        //     });

        //     swal(
        //         'warning',
        //         `<p>Vui lòng cung cấp đúng thông tin dịch vụ của trường để tránh việc dư thừa dữ liệu trên hệ thống !</p>
        //         <div class="panel panel-white">
        //             <table class="table table-togglable table-hover table-bordered">
        //                 <thead>
        //                     <tr>
        //                         <th rowspan=${mealService.length} class="text-center">Dịch vụ thực đơn</th>
        //                         <th class="text-center">Dịch vụ trường</th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>${renderListMeal}</tbody>
        //             </table>
        //         </div>
        //         `
        //     );
        //     return;
        // }

        const resSwal = await swal(
            'question',
            'Bạn có chắc chắn muốn thêm thực đơn từ tập tin này ?',
            true
        );

        if (resSwal.value) {
            dispatch(
                copyMenuActions.doImportMenuFromFile({
                    ...dataPost,
                    ...file,
                    o_id: currentSchool,
                    kg_id: kidGroupSample,
                    schoolInfo,
                })
            );
        }
    };

    return (
        <Fragment>
            <PageHeader pageTitle="SAO CHÉP THỰC ĐƠN" />
            <div className="content">
                {/* FILTER SECTIONS */}
                <CopyMenuFilter
                    menuList={listMenu}
                    idToSchool={toSchoolId}
                    rowSelected={rowSelected}
                    onChangeSchool={onChangeSchool}
                    onSaveMenu={onSaveMenu}
                    onOpenImportCsvMenu={onOpenImportCsvMenu}
                    onChangeKidGroup={setKidGroup}
                    onChangeKidGroupSample={setKidGroupSample}
                />

                <CopyMenuList
                    dataSource={listMenu}
                    rowSelected={rowSelected}
                    onSelectedChange={onSelectedChange}
                />
            </div>

            {isImport ? (
                <CopyMenuModalImport
                    isShow={isImport}
                    onClose={onCloseImportCsvMenu}
                    onImportMenu={onImportMenu}
                    proFood={proFood}
                    initialValues={{ m_datefounded: moment(), m_code: '' }}
                />
            ) : null}
        </Fragment>
    );
};

export default CopyMenu;
