import { takeEvery, call, put, takeLatest } from 'redux-saga/effects';
import * as types from './foodkid.constant';
// import {
//     apiGetListKidGroup,
//     //apiGetListMenuSample,
//     //apiSaveMenu,
//     //apiUpdateNewPrice,
//     // apiGetListMealService,
//     // apiUpdatePriceService,
//     // apiUpdateQuantity,
//     //apiRoudingRealBuy,
// } from '@app/apis/foodkid/foodkid';
import { ACTION_API_TYPES } from '@app/constants';
import * as apiConst from '@app/constants/api';
import {
    // getKidGroup,
    getKidGroupSuccess,
    saveMenuSuccess,
    doGetMenuSampleSuccess,
    doUpdateNewPriceMenuSuccess,
    doGetListServiceSchoolSuccess,
    doUpdatePriceServiceMenuSuccess,
    doUpdateQuantityMenuSuccess,
    doRoundingRealBuySuccess,
} from './foodkid.action';

import { showError, showSuccess } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import _fkMenuApis from '@app/apis/foodkid/menu.api';
import _fkMealServiceApis from '@app/apis/foodkid/mealService.api';
import _kidGroupApis from '@app/apis/foodkid/kidGroup.api';

function* sagaGetListKidGroup({ payload }) {
    function* doRQ() {
        // const res = yield call(apiGetListKidGroup, payload.id);
        const res = yield call(_kidGroupApis.getListByOId, payload.id);
        const { data, status } = res;
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(getKidGroupSuccess(data.data));
        } else {
            showError(status, data);
        }
    }
    yield callLoading(doRQ);
}

function* sagaGetListMenuSample({ payload }) {
    yield callLoading(function* doRQ() {
        const { data, status } = yield call(
            _fkMenuApis.getListSample,
            //apiGetListMenuSample,
            payload.data
        );

        const dataRes = data.data;

        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(
                doGetMenuSampleSuccess({
                    menu: dataRes.listMenuView,
                    menudishs: dataRes.menuDishs,
                    menuServices: dataRes.menuServices,
                    menuSchoolFoods: dataRes.menuSchoolFoods,
                })
            );
        } else {
            showError(status, data);
        }
    });
}

function* sagaSaveListMenu({ payload }) {
    yield callLoading(function* doRQ() {
        //const res = yield call(apiSaveMenu, payload.data);
        // => Chuyển sang API Gateway
        const res = yield call(_fkMenuApis.save, payload.data);
        const { data, status } = res;
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(saveMenuSuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sagaUpdateNewPriceMenu({ payload }) {
    yield callLoading(function* doRQ() {
        // const { data, status } = yield call(apiUpdateNewPrice, payload.data);
        const { data, status } = yield call(
            _fkMenuApis.updateNewFoodPrice,
            payload.data
        );

        if (status === apiConst.STATUS_CODES.SUCCESS) {
            showSuccess(ACTION_API_TYPES.EDIT);
            yield put(doUpdateNewPriceMenuSuccess());
        } else {
            showError(status, data);
        }
    });
}

function* sagaGetListServiceSchool({ payload }) {
    yield callLoading(function* doRQ() {
        // const { data, status } = yield call(
        //     apiGetListMealService,
        //     payload.data
        // );

        const { data, status } = yield call(
            _fkMealServiceApis.getList,
            payload.data
        );

        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(doGetListServiceSchoolSuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sagaUpdatePriceServiceMenu({ payload }) {
    yield callLoading(function* doRQ() {
        // const { status, data } = yield call(
        //     apiUpdatePriceService,
        //     payload.data
        // );

        const { status, data } = yield call(
            _fkMenuApis.updateServicePrice,
            payload.data
        );

        if (status === apiConst.STATUS_CODES.SUCCESS) {
            showSuccess(ACTION_API_TYPES.EDIT);
            yield put(doUpdatePriceServiceMenuSuccess());
        } else {
            showError(status, data);
        }
    });
}

function* sagaUpdateQuantityMenu({ payload }) {
    yield callLoading(function* doRQ() {
        // const { status, data } = yield call(apiUpdateQuantity, payload.data);
        const { status, data } = yield call(
            _fkMenuApis.updateNumberOfStudent,
            payload.data
        );

        if (status === apiConst.STATUS_CODES.SUCCESS) {
            showSuccess(ACTION_API_TYPES.EDIT);
            yield put(doUpdateQuantityMenuSuccess());
        } else {
            showError(status, data);
        }
    });
}

function* sagaRoudingRealBuy({ payload }) {
    yield callLoading(function* doRQ() {
        //const { data, status } = yield call(apiRoudingRealBuy, payload.data);
        const { data, status } = yield call(
            _fkMenuApis.roundingRealBuy,
            payload.data
        );

        if (status === apiConst.STATUS_CODES.SUCCESS) {
            showSuccess(ACTION_API_TYPES.EDIT);
            yield put(doRoundingRealBuySuccess());
        } else {
            showError(status, data);
        }
    });
}

function* foodkidSaga() {
    yield takeEvery(types.GET_KIDGROUP, sagaGetListKidGroup);
    yield takeEvery(types.GET_MENU_SAMPLE, sagaGetListMenuSample);
    yield takeEvery(types.SAVE_MENU, sagaSaveListMenu);

    yield takeLatest(types.UPDATE_NEW_PRICE_MENU, sagaUpdateNewPriceMenu);
    yield takeLatest(types.GET_LIST_SERVICE_SCHOOL, sagaGetListServiceSchool);
    yield takeLatest(
        types.UPDATE_PRICE_SERVICE_MENU,
        sagaUpdatePriceServiceMenu
    );
    yield takeLatest(types.UPDATE_QUANTITY_MENU, sagaUpdateQuantityMenu);
    yield takeLatest(types.ROUNDING_REAL_BUY, sagaRoudingRealBuy);
}

export default foodkidSaga;
