import React from 'react';
import PropTypes from 'prop-types';
import { getTypeButton } from './_service';
import '../_style.scss';
import { useAuthButton } from '@app/hooks/useAuthButton';

const ButtonFilterAction = (props) => {
    const { labelButton, onClick, buttonType, className, disabled } = props;
    const classButtonIcon = getTypeButton(buttonType);
    const classButton = className || 'btn-default btn-rounded';
    const isAuth = useAuthButton(buttonType);

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return isAuth ? (
        <button
            type="button"
            className={`btn ${classButton}`}
            onClick={() => handleClick()}
            disabled={disabled}
        >
            <i className={classButtonIcon} />
            &nbsp;
            {labelButton}
        </button>
    ) : null;
};

ButtonFilterAction.propTypes = {
    labelButton: PropTypes.string,
    onClick: PropTypes.func,
    buttonType: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

export default ButtonFilterAction;
