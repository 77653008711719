import React, { useEffect } from 'react';
import { Select } from 'antd';
import * as _warehouseActions from '@app/redux/warehouse/warehouse.action';
import _warehouseSltor from '@app/redux/warehouse/warehouse.selector';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentSchoolId } from '@app/hooks/useCurrentSchool';

const { Option } = Select;
const WarehouseSelect = ({ label }) => {
    const listWarehouse = useSelector(_warehouseSltor.warehouseList);
    const currentWarehouse = useSelector(_warehouseSltor.currentWarehouse);
    const currentSchoolId = useCurrentSchoolId();

    const dispatch = useDispatch();

    const doReset = () => {
        dispatch(_warehouseActions.getWarehouseSuccess([]));
    };

    useEffect(
        () => () => {
            doReset();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (!currentSchoolId || currentSchoolId === '0') {
            doReset();
            return;
        }
        dispatch(_warehouseActions.getWarehouse(currentSchoolId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSchoolId, dispatch]);

    const showOptionItem = () => {
        if (!listWarehouse?.length) return null;
        return listWarehouse.map((o) => (
            <Option value={o.w_id} key={o.w_id}>
                {o.w_name}
            </Option>
        ));
    };

    const onChange = (value) => {
        dispatch(_warehouseActions.changeWarehouse(value));
    };

    return (
        <>
            {label ? (
                <label>
                    <h6 className="panel-title">{label}</h6>
                </label>
            ) : null}
            <Select value={currentWarehouse} onChange={onChange}>
                {showOptionItem()}
            </Select>
        </>
    );
};

export default WarehouseSelect;
