import {
    apiGetListSchoolProperty,
    apiGetListSchoolPropertySetting,
    apiSaveSchoolPropertySetting,
} from '@app/apis/settingSchool/settingSchool.api';
import { ACTION_API_TYPES } from '@app/constants/';
import { STATUS_CODES } from '@app/constants/api';
import { showError, showSuccess } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import { call, put, takeLatest } from 'redux-saga/effects';
import { settingSchoolActions } from './settingSchool.slice';

function* sgGetPropertySetting({ payload }) {
    function* callData() {
        const { data, status } = yield call(
            apiGetListSchoolPropertySetting,
            payload
        );
        if (status === STATUS_CODES.SUCCESS) {
            yield put(
                settingSchoolActions.doGetPropertySettingSuccess(data.data)
            );
        } else {
            showError(status, data);
        }
    }
    yield callLoading(callData);
}

function* sgSavePropertySetting({ payload }) {
    yield callLoading(function* callData() {
        const { data, status } = yield call(
            apiSaveSchoolPropertySetting,
            payload
        );

        if (status === STATUS_CODES.SUCCESS) {
            yield put(settingSchoolActions.doSavePropertySettingSuccess());
            showSuccess(ACTION_API_TYPES.EDIT);
        } else {
            showError(status, data);
        }
    });
}

function* sgGetSchoolProperty() {
    const { data, status } = yield call(apiGetListSchoolProperty);
    if (status === STATUS_CODES.SUCCESS) {
        yield put(settingSchoolActions.doGetSchoolPropertySucess(data));
    } else {
        yield put(settingSchoolActions.doGetSchoolPropertySucess([]));
    }
}

function* SettingSchoolSaga() {
    yield takeLatest(
        settingSchoolActions.doGetPropertySetting,
        sgGetPropertySetting
    );
    yield takeLatest(
        settingSchoolActions.doSavePropertySetting.type,
        sgSavePropertySetting
    );
    yield takeLatest(
        settingSchoolActions.doGetSchoolProperty,
        sgGetSchoolProperty
    );
}

export default SettingSchoolSaga;
