/* eslint-disable eqeqeq */
import {
    COST_CALCULATED_TYPE,
    RECEIPT_VOUCHER_TYPE,
} from '@app/constants/receiptCost.CONST';
import _ from 'lodash';
import { _receiptVoucherUtils } from './receiptVoucher.utils';
/**
 * Sổ thu thanh toán tổng hợp utils
 */
export const _costTotalReportUtils = {
    /**
     * Lấy tất cả danh sách chi tiết phiếu thu
     * @param {Array} list : Danh sách phiếu thu có kèm chi tiết
     */
    getAllDetail(list) {
        let listDetail = [];
        list.forEach((o) => {
            listDetail = [...listDetail, ...o.detailArr];
        });
        return listDetail;
    },

    /**
     * Lấy tổng ngày nghỉ tháng trước
     * @param {Array} list: Danh sách phiếu thu
     */
    getAllAbsentPrevMonth(list) {
        return _.sum(
            list.map((item) =>
                _receiptVoucherUtils.getTotalQuantityRefund(item, month)
            )
        );
    },

    // Lấy tổng ngày nghỉ tháng trước
    getAllAbsentPrevMonthByMonth(list, month) {
        return _.sum(
            list.map((item) =>
                _receiptVoucherUtils.getTotalQuantityRefundByMonth(item, month)
            )
        );
    },

    /**
     * Lấy tổng tiền hoàn trả của 1 danh sách phiếu thu có kèm chi tiết
     * @param {Array} list:danh sách phiếu thu
     */
    getAllMoneyRefundPrevMonth(list) {
        return _.sum(
            list.map((item) => _receiptVoucherUtils.getTotalMoneyRefund(item))
        );
    },

    getAllMoneyRefundPrevMonthByMonth(list, month) {
        return _.sum(
            list.map((item) =>
                _receiptVoucherUtils.getTotalMoneyRefundByMonth(item, month)
            )
        );
    },

    /**
     * Lấy danh sách detail là tiền ăn tháng trước
     * @param {Object} receiptVoucher : Phiếu thu có kèm chi tiết
     */
    getListDetailPrevMonth(receiptVoucher) {
        let _listDetailPrevMonth = [];
        if (receiptVoucher.prevReceiptJson) {
            const prevReceiptItem = JSON.parse(receiptVoucher.prevReceiptJson);
            _listDetailPrevMonth = prevReceiptItem[0].prevDataJson ?? [];
        }
        return _listDetailPrevMonth;
    },

    /**
     * Lấy danh sách detail là tiền ăn tháng trước là PHIEU_THU
     * @param {Object} receiptVoucher : Phiếu thu có kèm chi tiết
     */
    getReceiptListDetailPrevMonth(receiptVoucher) {
        let _listDetailPrevMonth = [];
        if (receiptVoucher.prevReceiptJson) {
            const prevReceiptItem = JSON.parse(receiptVoucher.prevReceiptJson);
            _listDetailPrevMonth = (
                prevReceiptItem[0].prevDataJson || []
            ).filter((o) => o.rd_type == RECEIPT_VOUCHER_TYPE.PHIEU_THU);
        }
        return _listDetailPrevMonth;
    },

    /**
     * Lấy danh sách detail là tiền ăn tháng trước là PHIEU_CHI
     * @param {Object} receiptVoucher : Phiếu thu có kèm chi tiết
     */
    getExpenseListDetailPrevMonth(receiptVoucher) {
        let _listDetailPrevMonth = [];
        if (receiptVoucher.prevReceiptJson) {
            const prevReceiptItem = JSON.parse(receiptVoucher.prevReceiptJson);
            _listDetailPrevMonth = (
                prevReceiptItem[0].prevDataJson || []
            ).filter((o) => o.rd_type == RECEIPT_VOUCHER_TYPE.PHIEU_CHI);
        }
        return _listDetailPrevMonth;
    },

    /**
     * Lấy số ngày đã được lập phiếu hoàn trả tiền ăn tháng trước
     * @param {*} receiptVoucher
     * @returns
     */
    getQuantityRefundForMeal(receiptVoucher) {
        const _refundForMealList = (
            this.getListDetailPrevMonth(receiptVoucher) ?? []
        ).filter((o) => o.rd_type == RECEIPT_VOUCHER_TYPE.PHIEU_CHI);

        const refundForMealTatCaCacNgay = _refundForMealList.find(
            (o) =>
                o.cl_calculatedtype ==
                COST_CALCULATED_TYPE.TIEN_AN_TAT_CA_CAC_NGAY
        );

        const quantityTatCa = refundForMealTatCaCacNgay
            ? refundForMealTatCaCacNgay.rd_quantity
            : 0;

        const refundForMealT2T6 = _refundForMealList.find(
            (o) => o.cl_calculatedtype == COST_CALCULATED_TYPE.TIEN_AN_T2_T6
        );
        const quantityT2T6 = refundForMealT2T6
            ? refundForMealT2T6.rd_quantity
            : 0;

        const refundForMealT7CN = _refundForMealList.find(
            (o) => o.cl_calculatedtype == COST_CALCULATED_TYPE.TIEN_AN_T7_CN
        );

        const quantityT7CN = refundForMealT7CN
            ? refundForMealT7CN.rd_quantity
            : 0;

        return quantityTatCa > 0 ? quantityTatCa : quantityT2T6 + quantityT7CN;
    },

    /**
     * Lấy tiền đã lập phiếu hoàn trả
     * @param {*} receiptVoucher
     * @returns
     */
    getMoneyRefundForMealPrevMonth(receiptVoucher) {
        const _refundForMealList = this.getListDetailPrevMonth(
            receiptVoucher
        ).filter((o) => o.rd_type == RECEIPT_VOUCHER_TYPE.PHIEU_CHI);

        const tongTienHoanTraTienAn = _.sum(
            _refundForMealList.map((o) => o.rd_quantity * o.rd_price)
        );

        return tongTienHoanTraTienAn;
    },
    /**
     * Lấy ngày nghỉ tháng trước
     * @param {Object} receiptVoucher : Phiếu thu có kèm chi tiết
     * @returns
     */
    getAbsentPrevMonth(receiptVoucher) {
        // Lấy các chi tiết phiếu thu có số ngày hoàn trả
        const _refundList = _receiptVoucherUtils.getRefundList(receiptVoucher);

        // Lấy các chi tiết phiếu thu của phiếu hoàn trả tiền ăn

        // Tất cả các ngày
        const refundTatCaCacNgay =
            _refundList.find(
                (o) =>
                    o.cl_calculatedtype ==
                    COST_CALCULATED_TYPE.TIEN_AN_TAT_CA_CAC_NGAY
            ) ?? {};

        const quantityTatCaCacNgay = refundTatCaCacNgay
            ? refundTatCaCacNgay.rd_quantityrefunded
            : 0;

        // Từ thứ 2 đến thứ 6
        const refundT2T6 =
            _refundList.find(
                (o) => o.cl_calculatedtype == COST_CALCULATED_TYPE.TIEN_AN_T2_T6
            ) ?? {};

        const quantityT2T6 = refundT2T6 ? refundT2T6.rd_quantityrefunded : 0;

        // Thứ 7 + CN
        const refundT7CN =
            _refundList.find(
                (o) => o.cl_calculatedtype == COST_CALCULATED_TYPE.TIEN_AN_T7_CN
            ) ?? {};

        const quantityT7CN = refundT7CN ? refundT7CN.rd_quantityrefunded : 0;

        return quantityTatCaCacNgay > 0
            ? quantityTatCaCacNgay
            : quantityT2T6 + quantityT7CN;
    },

    getAbsentPrevMonthByMonth(receiptVoucher, month) {
        if (receiptVoucher.r_month !== month) {
            return 0;
        }
        return this.getAbsentPrevMonth(receiptVoucher);
    },

    /**
     * Lấy tiền hoàn trả tháng trước
     * @param {*} receiptVoucher
     */
    getMoneyAbsentPrevMonth(receiptVoucher) {
        // Lấy các chi tiết phiếu thu có số ngày hoàn trả
        const _refundList = _receiptVoucherUtils.getRefundList(receiptVoucher);

        const tongTienHoanTra = _.sum(
            _refundList.map((o) => o.rd_quantityrefunded * o.rd_pricerefunded)
        );

        return tongTienHoanTra;
    },

    getMoneyAbsentPrevMonthByMonth(receiptVoucher, month) {
        if (receiptVoucher.r_month !== month) {
            return 0;
        }
        return this.getMoneyAbsentPrevMonth(receiptVoucher);
    },

    /**
     * Lấy ngày đi học tháng trước
     * @param {Object} receiptVoucher : Phiếu thu có kèm chi tiết
     * @returns
     */
    getAttancePrevMonth(receiptVoucher) {
        const ngayNghiThangTruoc = this.getAbsentPrevMonth(receiptVoucher);

        const ngayLapPhieuHoanTra =
            this.getQuantityRefundForMeal(receiptVoucher);

        let ngayHocThangTruoc = 0;
        const _listDetailPrevMonth =
            this.getListDetailPrevMonth(receiptVoucher);

        if (_listDetailPrevMonth.length > 0) {
            ngayHocThangTruoc =
                _receiptVoucherUtils.getTotalQuantityMealByList(
                    _listDetailPrevMonth
                ) -
                ngayNghiThangTruoc -
                ngayLapPhieuHoanTra;
        }

        return ngayHocThangTruoc;
    },

    getAttancePrevMonthByMonth(receiptVoucher, month) {
        if (receiptVoucher.r_month !== month) {
            return 0;
        }

        return this.getAttancePrevMonth(receiptVoucher);
    },

    /**
     * Lấy tổng ngày đo học trong tháng của danh sách phiếu thu
     * @param {Array} list: Danh sách phiếu thu
     */

    getAllAttancePrevMonth(list) {
        return _.sum(list.map((item) => this.getAttancePrevMonth(item)));
    },

    getAllAttancePrevMonthByMonth(list, month) {
        return _.sum(
            list.map((item) => this.getAttancePrevMonthByMonth(item, month))
        );
    },

    /**
     * Lấy số tiền chi tháng trước của 1 phiếu thu
     * @param {Object} receiptVoucher: Phiếu thu có kèm theo chi tiết
     * @returns
     */
    getMoneyPrevMonth(receiptVoucher) {
        // Tiền trừ tháng trước
        const refundMoney = this.getMoneyAbsentPrevMonth(receiptVoucher);

        // Tiền hoàn trả tiền ăn tháng trước
        const refundForMealMoney =
            this.getMoneyRefundForMealPrevMonth(receiptVoucher);

        const _listDetailPrevMonth =
            this.getReceiptListDetailPrevMonth(receiptVoucher);

        const _money = _.sumBy(_listDetailPrevMonth, 'rd_subtotal');

        return _money - refundMoney - refundForMealMoney;
    },

    getMoneyPrevMonthByMonth(receiptVoucher, month) {
        if (receiptVoucher.r_month !== month) {
            return 0;
        }

        return this.getMoneyPrevMonth(receiptVoucher);
    },

    /**
     * Lấy tổng tiền chi tháng trước của danh sách phiếu thu
     * @param {Array} list: Danh sách phiếu thu
     * @returns
     */
    getAllMoneyPrevMonth(list) {
        return _.sum(list.map((item) => this.getMoneyPrevMonth(item)));
    },

    getAllMoneyPrevMonthByMonth(list, month) {
        return _.sum(
            list.map((item) => this.getMoneyPrevMonthByMonth(item, month))
        );
    },

    getAllTotalByCostList(_list, cl_id) {
        const _listDetail = this.getAllDetail(_list);
        const _listByCostList = _listDetail.filter((o) => o.cl_id === cl_id);
        return _.sumBy(_listByCostList, 'rd_total');
    },
};
