import React from 'react';
import { ADMIN_FUNCTIONS, SC_MODULES } from '@app/constants/sc.modules';
import settingIcon from '@app/assets/images/icons/cfg/settings.svg';
import SettingSchool from '@app/components/SettingSchool';

/**
 * Cấu hình
 */
const settingRoutes = [
    {
        name: 'THIẾT LẬP HỆ THỐNG',
        icon: settingIcon,
        isMenu: true,
        isRoute: true,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        functionId: ADMIN_FUNCTIONS.SETTINGS,
        link: '/setting',
        path: '/setting',
        main: () => <SettingSchool />,
    },
];

export default settingRoutes;
