/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { getPopupButtonType } from '../_service';

const propTypes = {
    onClick: PropTypes.func,
    buttonType: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    type: PropTypes.string,
    isLeftIcon: PropTypes.bool,
};
const ButtonPopupAction = ({
    onClick,
    buttonType,
    disabled,
    label,
    type,
    isLeftIcon,
}) => {
    const classButton = getPopupButtonType(buttonType);
    type = type || 'button';
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (
        <>
            {/* <button
                type={type}
                className={`btn btn-xs btn-labeled ${classButton.btnClass}`}
                disabled={disabled}
                onClick={() => handleClick()}
            >
                <b>
                    <i className={classButton.icon} />
                </b>
                {label}
            </button> */}

            <button
                type={type}
                className={`${classButton.btnClass}`}
                disabled={disabled}
                onClick={() => handleClick()}
            >
                {isLeftIcon && (
                    <b>
                        <i className={`${classButton.icon} position-left`} />
                    </b>
                )}

                {label}

                {!isLeftIcon && (
                    <b>
                        <i className={`${classButton.icon} position-right`} />
                    </b>
                )}
            </button>
        </>
    );
};

ButtonPopupAction.propTypes = propTypes;

export default ButtonPopupAction;
