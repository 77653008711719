/* eslint-disable consistent-return */
import React from 'react';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import ButtonTableAction from '@app/components/UI/Button/ButtonTableAction';
import { formatDateUnix } from '@app/utils/datetime.utils';
import { numberToPrice } from '@app/utils/utils';

const HistoryItem = ({ transactionHistory, onOpenDetail }) => {
    const onShowStatus = (paymentStatus, orderStatus) => {
        let html = null;

        if (+orderStatus === 4) {
            return (
                <td>
                    <span className="label label-danger">
                        <i className="icon-cancel-circle2" /> Hủy bỏ
                    </span>
                </td>
            );
        }

        switch (paymentStatus) {
            case 1:
            case 2:
                html = (
                    <span className="label label-primary bg-purple">
                        <i className="icon-cash" /> Chờ thanh toán
                    </span>
                );
                break;

            case 3:
                html = (
                    <span className="label label-success">
                        <i className="icon-checkmark4" /> Hoàn thành
                    </span>
                );
                break;

            default:
                break;
        }
        return html;
    };

    return (
        <tr>
            <td className="text-center">
                {formatDateUnix(
                    transactionHistory?.so_dateordered,
                    'DD/MM/YYYY HH:mm:ss'
                )}
            </td>
            <td className="text-center">{transactionHistory?.so_code}</td>
            <td>{transactionHistory?.so_description}</td>
            <td className="text-right">
                {numberToPrice(transactionHistory?.so_subtotal)}
            </td>
            <td>
                {onShowStatus(
                    transactionHistory?.so_paymentstatus,
                    transactionHistory?.so_orderstatus
                )}
            </td>
            <td className="text-center">
                <div className="btn-table-group">
                    <ButtonTableAction
                        tootTipTitle="Chi tiết đơn hàng"
                        buttonType={BUTTON_ACTION_TYPE.DETAIL_ORDER}
                        onClick={() => onOpenDetail(transactionHistory)}
                    />
                </div>
            </td>
        </tr>
    );
};

export default HistoryItem;
