import { takeLatest, put, call } from 'redux-saga/effects';
import * as types from './constants';
import { SAGA_CONST } from './sagaActions';
import { getYearCacheSuccess } from './actions';
import _schoolYearRepository from '@app/services/localDBService/schoolYearRepository';
import { apiGetOrganizationInfo } from '@app/apis/organization/organization.api';
import { apiGetLocationInfo } from '@app/apis/locations/location';
import {
    getOrganizationInfoSuccess,
    getLocationInfoSuccess,
    getSchoolInfoSuccess,
    getUnitsSuccess,
    getAgeGroupSuccess,
    getKidGroupSuccess,
    getMealServiceSuccess,
    getSupplierSuccess,
    getDishSuccess,
    getInventorySuccess,
    getPackagingSuccess,
    doGetSchoolFoodSuccess,
    doGetSchoolPropertySuccess,
    doGetSchoolInvoiceFormSuccess,
    doGetProFoodSuccess,
} from './slice';
import {
    apiGetSchoolInfoByOId,
    apiGetSchoolInvoiceForm,
    apiGetSchoolProperty,
} from '@app/apis/schools/schoolProperty';
import {
    //apiGetListAgeGroup,
    // apiGetListDish,
    // apiGetListKidGroup,
    //apiGetListMealService,
    apiGetSupplier,
    apiGetUnits,
    apiGetPackaging,
    // apiGetSchoolFood,
    apiGetProFood,
} from '@app/apis/foodkid/foodkid';
// import { GetByUser } from '@app/apis/inventory';
import _fkMealServiceApis from '@app/apis/foodkid/mealService.api';
import _kidGroupApis from '@app/apis/foodkid/kidGroup.api';
import _fkWarehouseApis from '@app/apis/inventory/warehouse.api';
import _fkSchoolFoodApis from '@app/apis/foodkid/schoolFood.api';
import _ageGroupApis from '@app/apis/foodkid/ageGroup.api';
import _fkDishApis from '@app/apis/foodkid/dish.api';

function* sgGetShoolYearCache() {
    const data = yield _schoolYearRepository.getAll();
    yield put(getYearCacheSuccess(data));
}

function* sgGetOrganizationInfo({ payload }) {
    const { status, data } = yield call(apiGetOrganizationInfo, payload.oId);
    let dataRes = {};
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getOrganizationInfoSuccess(dataRes));
}

function* sgGetLocationInfo({ payload }) {
    const { status, data } = yield call(apiGetLocationInfo, payload.l_code);
    let dataRes = {};
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getLocationInfoSuccess(dataRes));
}

function* sgGetSchoolInfo({ payload }) {
    const { status, data } = yield call(apiGetSchoolInfoByOId, payload.oId);
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getSchoolInfoSuccess(dataRes));
}

function* sgGetUnit() {
    const { status, data } = yield call(apiGetUnits);
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getUnitsSuccess(dataRes));
}

function* sgGetAgeGroup() {
    //const { status, data } = yield call(apiGetListAgeGroup);
    const { status, data } = yield call(_ageGroupApis.getList);
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getAgeGroupSuccess(dataRes));
}

function* sgGetKidGroup({ payload }) {
    // const { status, data } = yield call(apiGetListKidGroup, payload.oId);
    const { status, data } = yield call(
        _kidGroupApis.getListByOId,
        payload.oId
    );
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getKidGroupSuccess(dataRes));
}

function* sgGetMealService({ payload }) {
    const { status, data } = yield call(
        _fkMealServiceApis.getList,
        payload.oId
    );
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getMealServiceSuccess(dataRes));
}

function* sgGetSupplier({ payload }) {
    const { status, data } = yield call(apiGetSupplier, payload.oId);
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getSupplierSuccess(dataRes));
}

function* sgGetDish({ payload }) {
    // const { status, data } = yield call(apiGetListDish, payload.oId);
    const { status, data } = yield call(_fkDishApis.getList, payload.oId);
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getDishSuccess(dataRes));
}

function* sgGetInventory({ payload }) {
    //const { status, data } = yield call(GetByUser, payload.oId);
    const { status, data } = yield call(
        _fkWarehouseApis.getBySchool,
        payload.oId
    );
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getInventorySuccess(dataRes));
}

function* sgGetPackaging() {
    const { status, data } = yield call(apiGetPackaging);
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(getPackagingSuccess(dataRes));
}

function* sgGetSchoolFood({ payload }) {
    // const { status, data } = yield call(apiGetSchoolFood, payload.oId);
    const { status, data } = yield call(_fkSchoolFoodApis.getList, payload.oId);
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(doGetSchoolFoodSuccess(dataRes));
}

function* sgGetSchoolProperty() {
    const { status, data } = yield call(apiGetSchoolProperty);
    let dataRes = [];
    if (status === 200) {
        dataRes = data;
    }
    yield put(doGetSchoolPropertySuccess(dataRes));
}

function* sgGetSchoolInvoiceForm() {
    const { status, data } = yield call(apiGetSchoolInvoiceForm);
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(doGetSchoolInvoiceFormSuccess(dataRes));
}

function* sgGetProFood() {
    const { status, data } = yield call(apiGetProFood);
    let dataRes = [];
    if (status === 200) {
        dataRes = data.data;
    }
    yield put(doGetProFoodSuccess(dataRes));
}

function* otherSaga() {
    yield takeLatest(types.GET_YEAR_CACHE, sgGetShoolYearCache);
    yield takeLatest(SAGA_CONST.GET_ORGANIZATION_INFO, sgGetOrganizationInfo);
    yield takeLatest(SAGA_CONST.GET_LOCATION_INFO, sgGetLocationInfo);
    yield takeLatest(SAGA_CONST.GET_SCHOOL_INFO, sgGetSchoolInfo);
    yield takeLatest(SAGA_CONST.GET_UNIT, sgGetUnit);
    yield takeLatest(SAGA_CONST.GET_AGE_GROUP, sgGetAgeGroup);
    yield takeLatest(SAGA_CONST.GET_KID_GROUP, sgGetKidGroup);
    yield takeLatest(SAGA_CONST.GET_MEAL_SERVICE, sgGetMealService);
    yield takeLatest(SAGA_CONST.GET_SUPPLIER, sgGetSupplier);
    yield takeLatest(SAGA_CONST.GET_DISH, sgGetDish);
    yield takeLatest(SAGA_CONST.GET_INVENTORY, sgGetInventory);
    yield takeLatest(SAGA_CONST.GET_PACKAGING, sgGetPackaging);
    yield takeLatest(SAGA_CONST.GET_SCHOOL_FOOD, sgGetSchoolFood);
    yield takeLatest(SAGA_CONST.GET_SCHOOL_PROPERTY, sgGetSchoolProperty);
    yield takeLatest(
        SAGA_CONST.GET_SCHOOL_INVOICE_FORM,
        sgGetSchoolInvoiceForm
    );
    yield takeLatest(SAGA_CONST.GET_PRODUCT_FOOD, sgGetProFood);
}

export default otherSaga;
