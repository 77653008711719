export class SchoolInfoModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.o_id = data.o_id || '';
        this.bieu_do_suc_khoe = data.bieu_do_suc_khoe || '';

        this.dan_thuoc = data.dan_thuoc || '';

        this.dia_chi = data.dia_chi || '';

        this.dien_thoai = data.dien_thoai || '';

        this.dien_thoai_hieu_pho_ban_tru =
            data.dien_thoai_hieu_pho_ban_tru || '';

        this.dien_thoai_hieu_pho_chuyen_mon =
            data.dien_thoai_hieu_pho_chuyen_mon || '';

        this.dien_thoai_hieu_truong = data.dien_thoai_hieu_truong || '';

        this.dung_cu_chia_chua_dung_thuc_an_2b =
            data.dung_cu_chia_chua_dung_thuc_an_2b || '';

        this.dung_cu_chua_mau_thuc_an_luu_3 =
            data.dung_cu_chua_mau_thuc_an_luu_3 || '';

        this.gio_bat_dau_an_bua_chinh_chieu_2b =
            data.gio_bat_dau_an_bua_chinh_chieu_2b || '';

        this.gio_bat_dau_an_bua_phu_chieu_2b =
            data.gio_bat_dau_an_bua_phu_chieu_2b || '';

        this.gio_bat_dau_an_bua_sang_2b = data.gio_bat_dau_an_bua_sang_2b || '';

        this.gio_bat_dau_an_bua_trua_2b = data.gio_bat_dau_an_bua_trua_2b || '';

        this.gio_che_bien_bua_chinh_chieu_2a =
            data.gio_che_bien_bua_chinh_chieu_2a || '';

        this.gio_che_bien_bua_phu_chieu_2a =
            data.gio_che_bien_bua_phu_chieu_2a || '';

        this.gio_che_bien_bua_sang_2a = data.gio_che_bien_bua_sang_2a || '';

        this.gio_che_bien_bua_trua_2a = data.gio_che_bien_bua_trua_2a || '';

        this.gio_chia_mon_an_xong_bua_chinh_chieu_2b =
            data.gio_chia_mon_an_xong_bua_chinh_chieu_2b || '';

        this.gio_chia_mon_an_xong_bua_phu_chieu_2b =
            data.gio_chia_mon_an_xong_bua_phu_chieu_2b || '';

        this.gio_chia_mon_an_xong_bua_sang_2b =
            data.gio_chia_mon_an_xong_bua_sang_2b || '';

        this.gio_chia_mon_an_xong_bua_trua_2b =
            data.gio_chia_mon_an_xong_bua_trua_2b || '';

        this.gio_huy_mau_bua_chinh_chieu_3 =
            data.gio_huy_mau_bua_chinh_chieu_3 || '';

        this.gio_huy_mau_bua_sang_3 = data.gio_huy_mau_bua_sang_3 || '';

        this.gio_huy_mau_bua_trua_3 = data.gio_huy_mau_bua_trua_3 || '';

        this.gio_huy_mau_phu_chieu_3 = data.gio_huy_mau_phu_chieu_3 || '';

        this.gio_lay_mau_bua_chinh_chieu_3 =
            data.gio_lay_mau_bua_chinh_chieu_3 || '';

        this.gio_lay_mau_bua_phu_chieu_3 =
            data.gio_lay_mau_bua_phu_chieu_3 || '';

        this.gio_lay_mau_buoi_sang_3 = data.gio_lay_mau_buoi_sang_3 || '';

        this.gio_lay_mau_buoi_trua_3 = data.gio_lay_mau_buoi_trua_3 || '';

        this.gio_so_che_bua_chinh_chieu_2a =
            data.gio_so_che_bua_chinh_chieu_2a || '';

        this.gio_so_che_bua_phu_chieu_2a =
            data.gio_so_che_bua_phu_chieu_2a || '';

        this.gio_so_che_bua_sang_2a = data.gio_so_che_bua_sang_2a || '';

        this.gio_so_che_bua_trua_2a = data.gio_so_che_bua_trua_2a || '';

        this.gop_y = data.gop_y || '';

        this.hinh_anh = data.hinh_anh || '';

        this.hinh_thuc_can_doi_thuc_don = data.hinh_thuc_can_doi_thuc_don || '';

        this.ho_ten_bep_truong = data.ho_ten_bep_truong || '';

        this.ho_ten_cap_duong = data.ho_ten_cap_duong || '';

        this.ho_ten_hieu_pho_ban_tru = data.ho_ten_hieu_pho_ban_tru || '';
        this.tieu_de_hieu_pho_ban_tru = data.tieu_de_hieu_pho_ban_tru || '';

        this.ho_ten_hieu_pho_chuyen_mon = data.ho_ten_hieu_pho_chuyen_mon || '';

        this.ho_ten_hieu_truong = data.ho_ten_hieu_truong || '';

        this.ho_ten_ke_toan = data.ho_ten_ke_toan || '';

        this.ho_ten_thu_kho = data.ho_ten_thu_kho || '';

        this.ho_ten_thu_quy = data.ho_ten_thu_quy || '';

        this.ho_ten_van_thu = data.ho_ten_van_thu || '';

        this.ho_ten_y_te = data.ho_ten_y_te || '';

        this.hoc_phi = data.hoc_phi || '';

        this.ke_hoach_giao_duc = data.ke_hoach_giao_duc || '';

        this.khao_sat_y_kien = data.khao_sat_y_kien || '';

        this.khoi_luong_the_tich_mau_3 = data.khoi_luong_the_tich_mau_3 || '';

        this.khu_vuc_che_bien_phu_tro_2a =
            data.khu_vuc_che_bien_phu_tro_2a || '';

        this.kiem_tra_cam_quan_mon_an_2b =
            data.kiem_tra_cam_quan_mon_an_2b || '';

        this.kiem_tra_cam_quan_thuc_an_2a =
            data.kiem_tra_cam_quan_thuc_an_2a || '';

        this.ky_hieu_hoa_don = data.ky_hieu_hoa_don || '';

        this.loi_nhan_dau_ngay = data.loi_nhan_dau_ngay || '';

        this.ma_qhns = data.ma_qhns || '';

        this.nguoi_huy_mau = data.nguoi_huy_mau || '';

        this.nguoi_luu_mau = data.nguoi_luu_mau || '';

        this.nguoi_tham_gia_che_bien_2a = data.nguoi_tham_gia_che_bien_2a || '';

        this.nhat_ky_hang_ngay = data.nhat_ky_hang_ngay || '';

        this.nhiet_do_bao_quan_mau_3 = data.nhiet_do_bao_quan_mau_3 || '';

        this.noi_dung_thong_bao_nop_tien =
            data.noi_dung_thong_bao_nop_tien || '';

        this.quyen_phieu_thu_hien_tai = data.quyen_phieu_thu_hien_tai || '';

        this.so_be_ngoan = data.so_be_ngoan || '';

        this.so_bien_lai_hien_tai = data.so_bien_lai_hien_tai || '';

        this.so_bien_lai_hoc_phi_mau_giao =
            data.so_bien_lai_hoc_phi_mau_giao || '';

        this.so_bien_lai_hoc_phi_nha_tre =
            data.so_bien_lai_hoc_phi_nha_tre || '';

        this.so_ky_tu_bien_lai = data.so_ky_tu_bien_lai || '';

        this.ten_truong = data.ten_truong || '';

        this.thang_chuyen_lop = data.thang_chuyen_lop || '';

        this.tin_tuc = data.tin_tuc || '';

        this.trang_thiet_bi_dung_cu_2a = data.trang_thiet_bi_dung_cu_2a || '';

        this.xin_phep_nghi_hoc = data.xin_phep_nghi_hoc || '';

        this.ngay_nop_tien = data.ngay_nop_tien || '';

        this.ngay_gui_phieu_thu = data.ngay_gui_phieu_thu || '';

        this.pgd_gan_nhat = data.pgd_gan_nhat || '';
        this.ten_tai_khoan = data.ten_tai_khoan || '';
        this.so_tai_khoan = data.so_tai_khoan || '';
        this.ten_ngan_hang = data.ten_ngan_hang || '';

        this.nguoi_kiem_tra = data.nguoi_kiem_tra || '';
        this.thoi_gian_kiem_tra = data.thoi_gian_kiem_tra || '';
        this.dia_diem_kiem_tra = data.dia_diem_kiem_tra || '';
        this.ban_tiep_nhan = data.ban_tiep_nhan || '';
        this.hoa_don_nha_cung_cap = data.hoa_don_nha_cung_cap || '';
        this.ten_nguoi_lap_bang = data.ten_nguoi_lap_bang || '';
        this.ten_ban_tiep_pham = data.ten_ban_tiep_pham || '';

        this.thuc_don_trong_ngay = data.thuc_don_trong_ngay || false;
        this.phieu_ke_cho = data.phieu_ke_cho || false;
        this.phieu_tiep_pham = data.phieu_tiep_pham || false;
        this.so_kiem_thuc = data.so_kiem_thuc || false;
        this.so_kiem_thuc_trong = data.so_kiem_thuc_trong || false;
        this.bang_tong_hop_khau_phan_dinh_duong_tuan =
            data.bang_tong_hop_khau_phan_dinh_duong_tuan || false;
        this.so_tinh_tien_cho = data.so_tinh_tien_cho || false;
        this.phieu_nhap_kho = data.phieu_nhap_kho || false;
        this.phieu_xuat_kho = data.phieu_xuat_kho || false;
        this.the_kho = data.the_kho || false;
        this.tong_hop_the_kho = data.tong_hop_the_kho || false;
        this.so_quyet_toan_trong_ngay = data.so_quyet_toan_trong_ngay || false;
        this.hoa_don_ban_hang = data.hoa_don_ban_hang || false;
        this.bang_cong_khai_tai_chinh = data.bang_cong_khai_tai_chinh || false;

        this.bang_diem_danh = data.bang_diem_danh || false;
        this.bang_diem_danh_trong = data.bang_diem_danh_trong || false;
        this.tong_hop_diem_danh = data.tong_hop_diem_danh || false;
        this.phieu_bao_dong_tien = data.phieu_bao_dong_tien || false;
        this.bien_lai_thu_tien = data.bien_lai_thu_tien || false;
        this.so_thu_thanh_toan_tong_hop =
            data.so_thu_thanh_toan_tong_hop || false;
        this.tong_hop_thu_chi_tien_an = data.tong_hop_thu_chi_tien_an || false;
        this.so_quy_tien_an = data.so_quy_tien_an || false;
        this.thuc_don_tuan = data.thuc_don_tuan || false;
        this.so_thu_thanh_toan_tong_hop_phhs =
            data.so_thu_thanh_toan_tong_hop_phhs || false;
        this.an_ky_ten_so_ba_buoc = data.an_ky_ten_so_ba_buoc || false;

        this.thanh_pho = data.thanh_pho || '';
        this.quan_huyen = data.quan_huyen || '';
        this.phuong_xa = data.phuong_xa || '';

        /** KHO */
        this.bo_phan_quan_ly_kho = data.bo_phan_quan_ly_kho || '';
        this.ly_do_nhap_kho = data.ly_do_nhap_kho || '';
        this.nguoi_lap_phieu_nxk = data.nguoi_lap_phieu_nxk || '';
        this.nguoi_nhan_hang_nxk = data.nguoi_nhan_hang_nxk || '';
        this.dia_diem_nxk = data.dia_diem_nxk || '';
        this.ly_do_nhap_kho = data.ly_do_nhap_kho || '';
        this.nhap_tai_kho = data.nhap_tai_kho || '';
        this.ly_do_xuat_kho = data.ly_do_xuat_kho || '';
        this.xuat_tai_kho = data.xuat_tai_kho || '';
    }
}
