/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import { Checkbox } from 'antd';

/* eslint-disable jsx-a11y/anchor-is-valid */
const GeneralList = ({ list, onCheckAll, ...props }) => (
    <div className="row">
        <div className="col-lg-12">
            {/* Basic panel controls */}
            <div className="panel panel-white">
                <table className="table table-togglable table-hover table-bordered table-striped footable-loaded footable">
                    <thead className="grey-light">
                        <tr>
                            <th
                                className="text-center"
                                data-toggle="true"
                                style={{ width: '55px' }}
                            >
                                STT
                            </th>
                            <th className="text-center" style={{ width: '5%' }}>
                                {/* Chọn */}
                                <Checkbox
                                    checked={
                                        list.filter((o) => o.isCheck).length ===
                                        list.length
                                    }
                                    onChange={(s) => onCheckAll(s)}
                                />
                            </th>
                            <th className="text-center">Tên báo cáo</th>
                            <th className="text-center">Phân hệ</th>

                            <th
                                className="text-center"
                                style={{ width: '15%' }}
                            >
                                Tình trạng
                            </th>
                        </tr>
                    </thead>
                    <tbody>{props.children}</tbody>
                </table>
            </div>
            {/* /basic panel controls */}
        </div>
    </div>
);

export default GeneralList;
