/**
 * Lấy yearId theo tháng chuyển lớp
 * @param {number} year : Năm được chọn
 * @param {number} month : Tháng được chọn
 * @param {number} monthUpClass : Tháng chuyển lớp
 * @returns
 */
const getYearId = (year, month, monthUpClass) => {
    let yearId = year;
    if (month >= 1 && month < monthUpClass) {
        yearId -= 1;
    }
    return yearId;
};

export { getYearId };
