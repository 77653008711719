import * as types from './school.constant';

export const openSchoolModal = () => ({
    type: types.OPEN_SCHOOL_MODAL,
});

export const closeSchoolModal = () => ({
    type: types.CLOSE_SCHOOL_MODAL,
});

export const changeSchool = (id) => ({
    type: types.CHANGE_SCHOOL,
    payload: {
        id,
    },
});

export const getSchool = () => ({
    type: types.GET_SCHOOL,
});

export const getSchoolSuccess = (data) => ({
    type: types.GET_SCHOOL_SUCCESS,
    payload: {
        data,
    },
});
