import {
    uploadImageV1,
    deleteImageV1,
    uploadMultiImage,
} from '../apis/uploads/imageV1';
import { v4 as uuidv4 } from 'uuid';

export const buildFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return formData;
};

/**
 * Hàm upload hình ảnh
 * @param {Object}  newImg: file hình ảnh được chọn
 * @param {String} oldImg: Tên hình ảnh cũ
 * @param {String} directory: Thư mục hình ảnh
 * @returns {String} imageName: Tên hình ảnh
 */
export const globalUploadImage = async (newImg, oldImg, directory) => {
    const oldImgData = {
        fileName: oldImg,
        directory,
    };

    const newImgData = {
        files: newImg,
        directory,
    };

    const form = buildFormData(newImgData);
    const res = await uploadImageV1(form, 'FORM');
    const { data, status } = res;
    let imageName = '';
    if (status === 200) {
        if (oldImg) {
            const formDel = buildFormData(oldImgData);
            await deleteImageV1(formDel, 'FORM');
        }
        imageName = data;
    }

    return imageName;
};

export const globalMultiUploadImage = async (listImg, directory) => {
    const form = new FormData();
    let listName = [];
    if (listImg.length > 0) {
        for (let i = 0; i < listImg.length; i++) {
            const file = listImg[i];
            form.append('files', file);
        }
        form.append('directory', directory);

        const res = await uploadMultiImage(form, 'FORM');
        const { data, status } = res;

        if (status === 200) {
            listName = data;
        }
    }

    return listName;
};

export const globalMultiUploadFile = async (listImg, directory) => {
    const form = new FormData();
    let listName = [];
    if (listImg.length > 0) {
        for (let i = 0; i < listImg.length; i++) {
            const file = listImg[i];
            form.append('files', file);
        }
        form.append('directory', directory);

        const res = await uploadMultiImage(form, 'FORM');
        const { data, status } = res;

        if (status === 200) {
            listName = data;
        }
    }

    return listName;
};

export const globalDeleteImage = async (imgName, directory) => {
    const ImgData = {
        fileName: imgName,
        directory,
    };
    let isSuccess = false;
    if (imgName) {
        const formDel = buildFormData(ImgData);
        const { status } = await deleteImageV1(formDel, 'FORM');
        if (status === 200) {
            isSuccess = true;
        }
    }
    return isSuccess;
};

export const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
        return '0 Byte';
    }
    // eslint-disable-next-line radix
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    // eslint-disable-next-line no-restricted-properties
    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
};

export const globalUploadImageToS3 = async (listImg, directory) => {
    const form = new FormData();
    const imageName = [];

    if (listImg.length > 0) {
        for (let i = 0; i < listImg.length; i++) {
            const blob = listImg[i].slice(0, listImg[i].size, listImg[i].type);

            const randomNameImg = `${uuidv4()}.jpg`;
            imageName.push(randomNameImg);

            const newImg = new File([blob], randomNameImg, {
                type: 'image/jpg',
            });

            form.append('files', newImg);
        }
        form.append('directory', directory);

        const res = await uploadImageV1(form, 'FORM');
        const { data } = res;

        if (data) {
            return imageName;
        }
    }

    return imageName;
};
