const getApiLinkByEnv = (localUrl, deployUrl) => {
    if (process.env.NODE_ENV === 'production') {
        return deployUrl;
    }
    return localUrl;
};

export const API_SC_GATEWAY_ENDPOINT = getApiLinkByEnv(
    //'http://localhost:9683/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_INCOME_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:9101/api',
    // 'https://api-ic-v2.sc.edu.vn/api',
    // 'https://api-ic-v2.sc.edu.vn/api'
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_SC_FOODKID_ENPOINT = getApiLinkByEnv(
    //'http://localhost:9088/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_GLOBALDATA_ENDPOINT = getApiLinkByEnv(
    //"http://localhost:5000/api",
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_ACCOUNT_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:9401/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_SCHOOL_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:5000/api',
    //'http://localhost:9010/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_STUDENT_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:9092/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_WEB_IMAGE_UPLOAD_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:9005/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_INVENTORY_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:9103/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_REPORT_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:5050/api',
    'https://report.sc.edu.vn/api',
    'https://report.sc.edu.vn/api'
);

export const API_ORGANIZATION_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:5000/api',
    // 'http://localhost:9003/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_PRODUCT_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:5000/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_BCN_PRODUCT_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:5000/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const API_ORDER_ENDPOINT = getApiLinkByEnv(
    // 'http://localhost:9008/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);

export const STATUS_CODES = {
    SUCCESS: 200,
    CREATED: 201,
    UPDATED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    UNAUTHORIZED: 401,
    METHOD_NOT_ALLOW: 405,
    SERVICE_UNAVAIBLE: 503,
};

export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};
