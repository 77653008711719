import axiosService from '@app/services/axiosService';
import {
    // API_SC_FOODKID_ENPOINT,
    // API_INVENTORY_ENDPOINT,
    API_SC_GATEWAY_ENDPOINT,
} from '@app/constants/api';
import { ApiGatewayDataPost } from '@app/models/common/apiGatewayDataPost.model';

// const FK_BASE_URL = `${API_SC_FOODKID_ENPOINT}/v1/sc/foodkids/service`;
// const INVENTORY_BASE_URL = `${API_INVENTORY_ENDPOINT}/v1/sc/inventory/service/foodreceipt`;

const LIST_URL = {
    // FK_GET_TOTAL_MENU_MONEY_BY_DATE: `${FK_BASE_URL}/GetTotalMenuMoneyByDate`,
    // IV_GET_TOTAL_MONEY_BY_DATE: `${INVENTORY_BASE_URL}/GetTotalMoneyByDate`,
    TRANSIT: `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/TransitStation`,
};

/*=================================================*
* FOODKID
=================================================*/
/**
 * Lấy tổng tiền chợ theo từ ngày đến ngày
 * @param {Object} dataPost : param gửi lên
 * @param {string} dataPost.oId : Id tổ chức
 * @param {Number} dataPost.startDate : từ ngày
 * @param {Number} dataPost.endDate : từ ngày
 */
export const apiGetTotalMenuMoneyByDate = (dataPost) => {
    const _dataPost = new ApiGatewayDataPost({
        service: 'FoodKid',
        controller: 'ToolScMenu',
        action: 'GetTotalFoodMenuMoney',
        dataPost,
    });
    return axiosService.post(LIST_URL.TRANSIT, _dataPost);
    // return axiosService.post(
    //     LIST_URL.FK_GET_TOTAL_MENU_MONEY_BY_DATE,
    //     dataPost
    // );
};

/*=================================================*
* KHO
=================================================*/
/**
 * Lấy tổng tiền nhập kho theo từ ngày đến ngày
 * @param {Object} dataPost : param gửi lên
 * @param {string} dataPost.oId : Id tổ chức
 * @param {Number} dataPost.startDate : từ ngày
 * @param {Number} dataPost.endDate : từ ngày
 */
export const apiGetTotalFoodReceiptMoneyByDate = (dataPost) => {
    const _dataPost = new ApiGatewayDataPost({
        service: 'Inventory',
        controller: 'ToolScImportVoucher',
        action: 'GetTotalMoneyByDate',
        dataPost,
    });

    return axiosService.post(LIST_URL.TRANSIT, _dataPost);
    //return axiosService.post(LIST_URL.IV_GET_TOTAL_MONEY_BY_DATE, dataPost);
};
