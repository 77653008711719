// import * as apActions from '@app/redux/accounts/accountPrivilege/actions';
import { Switch } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { accountPrivilegeActions } from '@app/redux/account/accountPrivilege/accountPrivilege.slice';

const AccountPrivilegeItem = ({ accountPrivilege }) => {
    const dispatch = useDispatch();

    /**
     * type = 1 : Toàn quyền
     * type = 0 : Không cấp quyền
     */

    const checkPermission = (data, type) => {
        let check = false;
        const { sp_view, sp_report, sp_edit, sp_delete, sp_add } = data;

        if (sp_view && sp_report && sp_delete && sp_edit && sp_add) {
            check = true;
        }
        if (type === 0) {
            if (!sp_view && !sp_report && !sp_delete && !sp_edit && !sp_add) {
                return true;
            }

            return false;
        }

        return check;
    };

    const onChange = (value, type, data) => {
        switch (type) {
            case 'fullright': {
                if (value) {
                    data.sp_add = true;
                    data.sp_delete = true;
                    data.sp_edit = true;
                    data.sp_view = true;
                    data.sp_report = true;
                    data.sp_fullright = true;
                    data.sp_prevent = false;
                } else {
                    data.sp_fullright = false;
                }
                break;
            }
            case 'prevent': {
                if (value) {
                    data.sp_add = false;
                    data.sp_delete = false;
                    data.sp_edit = false;
                    data.sp_view = false;
                    data.sp_report = false;
                    data.sp_prevent = true;
                    data.sp_fullright = false;
                } else {
                    data.sp_view = true;
                    data.sp_prevent = false;
                }
                break;
            }
            case 'add': {
                data.sp_add = value;
                data.sp_fullright = checkPermission(data, 1);
                data.sp_prevent = checkPermission(data, 0);
                break;
            }
            case 'delete': {
                data.sp_delete = value;
                data.sp_fullright = checkPermission(data, 1);
                data.sp_prevent = checkPermission(data, 0);
                break;
            }
            case 'edit': {
                data.sp_edit = value;
                data.sp_fullright = checkPermission(data, 1);
                data.sp_prevent = checkPermission(data, 0);
                break;
            }
            case 'view': {
                data.sp_view = value;
                data.sp_fullright = checkPermission(data, 1);
                data.sp_prevent = checkPermission(data, 0);
                break;
            }
            case 'report': {
                data.sp_report = value;
                data.sp_fullright = checkPermission(data, 1);
                data.sp_prevent = checkPermission(data, 0);
                break;
            }
            case 'personal': {
                if (
                    value === false &&
                    data.sp_department === false &&
                    data.sp_organization === false
                ) {
                    data.sp_personal = true;
                } else {
                    data.sp_personal = value;
                    if (value) {
                        data.sp_department = false;
                        data.sp_organization = false;
                    }
                }
                break;
            }
            case 'department': {
                data.sp_department = value;
                if (value) {
                    data.sp_personal = false;
                    data.sp_organization = false;
                } else {
                    data.sp_personal = true;
                }
                break;
            }
            case 'organization': {
                data.sp_organization = value;
                if (value) {
                    data.sp_personal = false;
                    data.sp_department = false;
                } else {
                    data.sp_personal = true;
                }
                break;
            }
            default:
                return '';
        }

        return data;
    };

    const onCheckBeforeChange = (value, type, data) => {
        let listChange = [];

        if (data.isParent) {
            listChange = data.listChildren.map((item) =>
                onChange(value, type, item)
            );
        } else {
            listChange = [onChange(value, type, data)];
        }

        dispatch(accountPrivilegeActions.doUpdateAccountPrivilege(listChange));
    };

    /** Hàm check status parent */
    const checkStatus = (listCheck, fieldCheck) => {
        const check = listCheck.find((_check) => !_check[fieldCheck]);

        if (check) {
            return false;
        }

        return true;
    };

    /** Hàm render status parent */
    const renderStatusParent = (dataChilren) => {
        let objStatus = {
            sp_fullright: false,
            sp_add: false,
            sp_delete: false,
            sp_edit: false,
            sp_view: false,
            sp_report: false,
            sp_personal: false,
            sp_department: false,
            sp_organization: false,
            sp_prevent: false,
        };

        /** Check Full Right */
        const checkFull = dataChilren.find(
            (child) => !child.sp_fullright || child.sp_prevent
        );

        if (!checkFull) {
            objStatus = { ...objStatus, sp_fullright: true };
        }

        /** Check từng quyền */

        const sp_add = checkStatus(dataChilren, 'sp_add');
        const sp_delete = checkStatus(dataChilren, 'sp_delete');
        const sp_edit = checkStatus(dataChilren, 'sp_edit');
        const sp_view = checkStatus(dataChilren, 'sp_view');
        const sp_report = checkStatus(dataChilren, 'sp_report');
        const sp_personal = checkStatus(dataChilren, 'sp_personal');
        const sp_department = checkStatus(dataChilren, 'sp_department');
        const sp_organization = checkStatus(dataChilren, 'sp_organization');
        const sp_prevent = checkStatus(dataChilren, 'sp_prevent');

        return {
            ...objStatus,
            sp_add,
            sp_delete,
            sp_edit,
            sp_view,
            sp_report,
            sp_personal,
            sp_department,
            sp_organization,
            sp_prevent,
        };
    };

    /** Hàm hiển thị dữ liệu chung */
    const showData = (data, fieldName) => (
        <tr key={data.sortOrder} className={`${data.isParent ? 'info' : ''}`}>
            <td className="text-center">{data.sortOrder}</td>

            <td>
                {data.isParent
                    ? data[fieldName].toUpperCase()
                    : data[fieldName]}
            </td>

            <td className="text-center" data-label="Toàn quyền">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={data.sp_fullright}
                        onChange={(s) =>
                            onCheckBeforeChange(s, 'fullright', data)
                        }
                    />
                </div>
            </td>

            <td className="text-center" data-label="Thêm">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={data.sp_add}
                        onChange={(s) => onCheckBeforeChange(s, 'add', data)}
                    />
                </div>
            </td>
            <td className="text-center" data-label="Xóa">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={data.sp_delete}
                        onChange={(s) => onCheckBeforeChange(s, 'delete', data)}
                    />
                </div>
            </td>
            <td className="text-center" data-label="Sửa">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={data.sp_edit}
                        onChange={(s) => onCheckBeforeChange(s, 'edit', data)}
                    />
                </div>
            </td>
            <td className="text-center" data-label="Xem">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={data.sp_view}
                        onChange={(s) => onCheckBeforeChange(s, 'view', data)}
                    />
                </div>
            </td>
            <td className="text-center" data-label="In">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={data.sp_report}
                        onChange={(s) => onCheckBeforeChange(s, 'report', data)}
                    />
                </div>
            </td>
            <td className="text-center" data-label="Xem cá nhân">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={data.sp_personal}
                        onChange={(s) =>
                            onCheckBeforeChange(s, 'personal', data)
                        }
                    />
                </div>
            </td>
            <td className="text-center" data-label="Xem phòng ban">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={data.sp_department}
                        onChange={(s) =>
                            onCheckBeforeChange(s, 'department', data)
                        }
                    />
                </div>
            </td>
            <td className="text-center" data-label="Xem tổ chức">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={data.sp_organization}
                        onChange={(s) =>
                            onCheckBeforeChange(s, 'organization', data)
                        }
                    />
                </div>
            </td>
            <td className="text-center" data-label="Không cấp quyền">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={data.sp_prevent}
                        onChange={(s) =>
                            onCheckBeforeChange(s, 'prevent', data)
                        }
                    />
                </div>
            </td>
        </tr>
    );

    const showPrivilege = (data) => {
        const xhtml = [];
        xhtml.push(
            showData(
                { ...data, ...renderStatusParent(data.listChildren) },
                'sfc_name'
            )
        );
        if (data?.listChildren && data?.listChildren.length > 0) {
            const _xhtml = data.listChildren.map((child, idx) =>
                showData(
                    {
                        ...child,
                        sortOrder: `${child.sf_sortorder}.${idx + 1}`,
                    },
                    'sf_name'
                )
            );

            xhtml.push(_xhtml);
        }

        return xhtml;
    };

    return showPrivilege(accountPrivilege);
};

export default AccountPrivilegeItem;
