import { menuRoutes } from './routes';
import _ from 'lodash';
import { getAuth, getRoles } from '@app/utils/auth.utils';
import { SC_MODULES } from './constants/sc.modules';

class RouteAuthentication {
    constructor() {
        this.MenuRoutes = menuRoutes;
    }

    getMenu() {
        const listClone = _.cloneDeep(this.MenuRoutes);
        let listDes = [];
        listClone.forEach((item) => {
            if (item.isMenu) {
                // Nếu menu có children thì children phải có ít nhất 1 là menu
                if (item.children && item.children.length > 0) {
                    let checkChild = item.children.filter(
                        (o) =>
                            o.isMenu &&
                            getAuth(SC_MODULES.SERVICE, o.functionId)
                    );
                    // if (checkChild.length > 0) {
                    checkChild = checkChild.map((o) => ({
                        ...o,
                        roles: getRoles(SC_MODULES.SERVICE, o.functionId),
                    }));
                    listDes = [
                        ...listDes,
                        {
                            ...item,
                            children: checkChild,
                        },
                    ];
                    // }
                } else if (item.isRoute) {
                    // const isAuth = getAuth(SC_MODULES.ADMIN, item.functionId);
                    // if (isAuth) {
                    listDes = [
                        ...listDes,
                        {
                            ...item,
                            roles: getRoles(
                                SC_MODULES.SERVICE,
                                item.functionId
                            ),
                        },
                    ];
                    // }
                }
            }
        });
        return listDes;
    }

    getRoutes() {
        const listClone = _.cloneDeep(this.MenuRoutes);
        let listDes = [];
        listClone.forEach((item) => {
            if (item.isRoute) {
                listDes = [
                    ...listDes,
                    {
                        ...item,
                        roles: getRoles(SC_MODULES.SERVICE, item.functionId),
                    },
                ];
                if (item.children) {
                    item.children.forEach((o) => {
                        if (o.isRoute) {
                            listDes = [
                                ...listDes,
                                {
                                    ...o,
                                    roles: getRoles(
                                        SC_MODULES.SERVICE,
                                        o.functionId
                                    ),
                                },
                            ];
                        }
                    });
                }
            } else if (item.children) {
                item.children.forEach((o) => {
                    if (o.isRoute) {
                        listDes = [
                            ...listDes,
                            {
                                ...o,
                                roles: getRoles(
                                    SC_MODULES.SERVICE,
                                    o.functionId
                                ),
                            },
                        ];
                    } else if (o.children) {
                        o.children.forEach((_elm) => {
                            if (_elm.isRoute) {
                                listDes = [
                                    ...listDes,
                                    {
                                        ..._elm,
                                        roles: getRoles(
                                            SC_MODULES.SERVICE,
                                            _elm.functionId
                                        ),
                                    },
                                ];
                            }
                        });
                    }
                });
            }
        });

        return listDes;
    }
}

export default new RouteAuthentication();
