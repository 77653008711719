import userIcon from '@app/assets/images/icons/sys/users.svg';
import React from 'react';
import { ADMIN_FUNCTIONS, SC_MODULES } from '@app/constants/sc.modules';
import AccountList from '@app/components/Account/AccountList';
import AccountRole from '@app/components/Account/AccountRole';
import AccountPrivilege from '@app/components/Account/AccountPrivilege';

/**
 * Người dùng
 */
const accountRoutes = [
    {
        name: 'NHÂN SỰ',
        icon: userIcon,
        isMenu: true,
        isRoute: false,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        functionId: ADMIN_FUNCTIONS.FOODKID_MAIN,
        children: [
            {
                name: 'Danh sách tài khoản',
                link: '/accounts',
                path: '/accounts',
                isMenu: true,
                isRoute: true,
                exact: false,
                functionId: ADMIN_FUNCTIONS.ACCOUNTS,
                main: () => <AccountList />,
            },
            {
                name: 'Cấp quyền sử dụng',
                link: '/accountprivilege',
                path: '/accountprivilege',
                isMenu: true,
                isRoute: true,
                exact: false,
                functionId: ADMIN_FUNCTIONS.ACCOUNT_PRIVILEGE,
                main: () => <AccountPrivilege />,
            },
            {
                name: 'Vai trò',
                link: '/accountrole',
                path: '/accountrole',
                isMenu: true,
                isRoute: true,
                exact: false,
                functionId: ADMIN_FUNCTIONS.ACCOUNT_ROLE,
                main: () => <AccountRole />,
            },
        ],
    },
];

export default accountRoutes;
