/* eslint-disable eqeqeq */
import {
    COST_CALCULATED_TYPE,
    RECEIPT_TYPE_PAYMENT,
    RECEIPT_COST_SETTING,
} from '@app/constants/receiptCost.CONST';
import _ from 'lodash';
/**
 * Ghi chú:
 * Tổng số suất = Số suất tháng trước + số suất thu tháng này + số suất thất thu = số suất chi + số suất hoàn trả + số suất chuyển sang tháng sau
 * Số suất tháng trước: ngày nghỉ tháng trước (lấy từ phiếu thu)
 * Số suất thu: số suất đã thu tiền
 * Số suất thất thu: số suất chưa thu tiền
 * Số suất chi: số ngày đi học trong tháng = Tổng số suất lập trong tháng - số suất hoàn trả - số suất chuyển sang tháng sau
 * Số suất hoàn trả:
 * Số suất chuyển sang tháng sau = số ngày bé nghỉ trong tháng
 */

/**
 * Tổng hợp thu chi tiền ăn
 */
export const _incomeReportByMoneyForMealUtils = {
    /**
     * Lấy thông tin setting của khoản thu
     * @param {Array} listCostDetail : Cấu hình của khoản thu
     */
    getRefundSetting(listCostDetail) {
        if (listCostDetail) {
            const isRefundLegal =
                listCostDetail.find(
                    (o) => o.cs_id == RECEIPT_COST_SETTING.IS_REFUNDED_LEGAL
                ) || {};
            const numberRefundLegal =
                listCostDetail.find(
                    (o) =>
                        o.cs_id ==
                        RECEIPT_COST_SETTING.NUMBER_OF_DAYS_IS_REFUNDED_LEGAL
                ) || {};
            const legal = {
                isRefundLegal: isRefundLegal
                    ? isRefundLegal.cd_value == 1
                    : false,
                allowNumberDay: numberRefundLegal
                    ? +numberRefundLegal.cd_value
                    : 0,
            };
            const isRefundIlLegal =
                listCostDetail.find(
                    (o) => o.cs_id == RECEIPT_COST_SETTING.IS_REFUNDED_ILLEGAL
                ) || {};
            const numberRefundIlLegal =
                listCostDetail.find(
                    (o) =>
                        o.cs_id ==
                        RECEIPT_COST_SETTING.NUMBER_OF_DAYS_IS_REFUNDED_ILLEGAL
                ) || {};
            const ilLegal = {
                isRefundIlLegal: isRefundIlLegal
                    ? isRefundIlLegal.cd_value == 1
                    : false,
                allowNumberDay: numberRefundIlLegal
                    ? +numberRefundIlLegal.cd_value
                    : 0,
            };
            return {
                legal,
                ilLegal,
            };
        }
        return 0;
    },
    /**
     * Lấy số suất ăn chuyển sang tháng sau từ bảng điểm danh
     * @param {Object} attandance: Bảng điểm danh
     * @param {Array} listCostDetail: Cấu hình của khoản thu
     * @param {number} cl_calculatedtype: Cách tính của khoản thu
     */
    getCateringTransferNextMonth(
        attandance,
        cl_calculatedtype,
        listCostDetail
    ) {
        let numberTransfer = 0;
        if (cl_calculatedtype && attandance && listCostDetail) {
            switch (cl_calculatedtype) {
                case COST_CALCULATED_TYPE.TIEN_AN_T2_T6: {
                    numberTransfer =
                        attandance.as_absencedayslegal -
                        attandance.as_absencedayslegalonsaturday -
                        attandance.as_absencedayslegalonsunday +
                        (attandance.as_absencedaysillegal -
                            attandance.as_absencedaysillegalsaturday -
                            attandance.as_absencedaysillegalonsunday);
                    break;
                }
                case COST_CALCULATED_TYPE.TIEN_AN_T7_CN: {
                    numberTransfer =
                        attandance.as_absencedayslegalonsaturday +
                        attandance.as_absencedayslegalonsunday +
                        attandance.as_absencedaysillegalsaturday +
                        attandance.as_absencedaysillegalonsunday;
                    break;
                }
                case COST_CALCULATED_TYPE.TIEN_AN_TAT_CA_CAC_NGAY: {
                    numberTransfer =
                        attandance.as_absencedayslegal +
                        attandance.as_absencedaysillegal;
                    break;
                }
                default: {
                    break;
                }
            }
        }
        return numberTransfer;
    },
    /**
     * Lấy danh sách merge để hiển thị lên antd table
     * @param {Object} dataPost : Danh sách phiếu thu có kèm theo chi tiết phiếu thu
     * @param {Array} dataPost.listReceiptDetail
     * @param {Array} dataPost.listAttandance
     * @param {Array} dataPost.listRefundDetail
     * @param {Array} dataPost.listClassId : Danh sách Id lớp học có thu tiền trong tháng
     * @param {Array} dataPost.listClass : Danh sách lớp học
     * @param {number} dataPost.month : Tháng đang chọn
     * @param {Array} dataPost.listReceipt : Danh sách phiếu thu có kèm theo chi tiết phiếu thu
     * @param {Array} dataPost.listRefund
     * @param {Array} dataPost.listCostDetail
     */
    getTableData(dataPost) {
        const {
            listReceiptDetail,
            listAttandance,
            listRefundDetail,
            listClassId,
            listClass,
            month,
            listReceipt,
            listRefund,
            listCostDetail,
        } = dataPost;

        let tableData = listClassId.map((classId) => {
            const itemClass = listClass.find((o) => o.c_id == classId) || {};

            // Danh sách phiếu thu theo từng lớp
            const listReceiptByClass =
                listReceipt.filter((o) => o.r_linkcid == classId) || [];

            const listReceiptIdByClass = listReceiptByClass.map(
                (elm) => elm.r_id
            );

            // Danh sách chi tiết phiếu thu theo từng lớp
            const listReceiptDetailByClass =
                listReceiptDetail.filter((o) =>
                    _.includes(listReceiptIdByClass, o.r_id)
                ) || [];

            const price =
                listReceiptDetailByClass.length > 0
                    ? listReceiptDetailByClass[0].rd_price
                    : 0;

            // Danh sách phiếu thu thu trong tháng
            const listReceiptByClassPayInMonth =
                listReceiptByClass.filter(
                    (o) =>
                        o.r_monthpayment === +month &&
                        (o.r_paymentstatus === RECEIPT_TYPE_PAYMENT.DONE ||
                            o.r_paymentstatus === RECEIPT_TYPE_PAYMENT.DEPOSIT)
                ) || [];

            // Danh sách chi tiết phiếu thu thu trong tháng
            const listReceiptDetailByClassPayInMonth =
                listReceiptDetailByClass.filter(
                    (o) =>
                        _.includes(
                            listReceiptByClassPayInMonth.map((elm) => elm.r_id),
                            o.r_id
                        ) && o.rd_status === RECEIPT_TYPE_PAYMENT.DONE
                ) || [];

            // Tổng số suất thu trong tháng
            const countReceiptInMonth =
                _.sumBy(listReceiptDetailByClassPayInMonth, 'rd_quantity') -
                _.sumBy(
                    listReceiptDetailByClassPayInMonth,
                    'rd_quantityrefunded'
                );

            // Tổng tiền thu trong tháng
            const moneyReceiptInMonth = countReceiptInMonth * price;

            // Tổng số suất tháng trước
            const countRefundPrevMonth = _.sumBy(
                listReceiptDetailByClassPayInMonth,
                'rd_quantityrefunded'
            );

            // Tổng tiền thừa tháng trước
            const moneyRefundPrevMonth = countRefundPrevMonth * price;

            // Danh sách phiếu hoàn trả theo lớp
            const listRefundForMealByClass =
                listRefund.filter((o) =>
                    _.includes(listReceiptIdByClass, o.r_id)
                ) || [];

            // Danh sách chi tiết phiếu hoàn trả theo lớp
            const listRefundDetailForMealByClass =
                listRefundDetail.filter((o) =>
                    _.includes(
                        listRefundForMealByClass.map((elm) => elm.rfm_id),
                        o.rfm_id
                    )
                ) || [];

            // Số suất hoàn trả
            const countRefundForMeal =
                _.sumBy(listRefundDetailForMealByClass, 'rfmd_quantity') || 0;

            // Tổng tiền hoàn trả
            const moneyRefundForMeal = countRefundForMeal * price;

            // Danh sách chi tiết phiếu thu chưa thanh toán
            const listReceiptNotPaid = listReceiptByClass.filter(
                (o) =>
                    o.r_monthpayment != month ||
                    o.r_paymentstatus == RECEIPT_TYPE_PAYMENT.NOT_PAID
            );

            const listReceiptDetailNotPaid =
                listReceiptDetailByClass.filter(
                    (o) =>
                        _.includes(
                            listReceiptNotPaid.map((elm) => elm.r_id),
                            o.r_id
                        ) || o.rd_status == RECEIPT_TYPE_PAYMENT.NOT_PAID
                ) || [];

            // Số suất chưa thanh toán/thất thu
            const countNotPaid = _.sumBy(
                listReceiptDetailNotPaid,
                'rd_quantity'
            );

            // Tổng tiền chưa thanh toán/thất thu
            const moneyNotPaid = _.sumBy(
                listReceiptDetailNotPaid,
                'rd_subtotal'
            );

            // Danh sách mã phiếu thu đã hoàn trả tiền ăn
            const listReceiptIdRefundMeal =
                listRefundForMealByClass.map((o) => o.r_id) || [];

            // Danh sách phiếu thu chưa hoàn trả tiền ăn
            const listReceiptNotRefund =
                listReceiptByClass.filter(
                    (o) => !_.includes(listReceiptIdRefundMeal, o.r_id)
                ) || [];

            // Danh sách Id student class chưa hoàn trả
            const listSCId = listReceiptNotRefund.map((o) => +o.r_linkid);

            const cl_calculatedtype =
                listReceiptDetailByClass.length > 0
                    ? listReceiptDetailByClass[0].cl_calculatedtype
                    : '';

            // Danh sách bảng điểm danh của những bé chưa hoàn trả trong lớp
            const listAttandanceByClass =
                listAttandance.filter(
                    (o) => o.c_id == classId && _.includes(listSCId, o.sc_id)
                ) || [];

            const countNextMonth =
                _.sum(
                    listAttandanceByClass.map((o) =>
                        this.getCateringTransferNextMonth(
                            o,
                            cl_calculatedtype,
                            listCostDetail
                        )
                    )
                ) || 0;

            const moneyNextMonth = countNextMonth * price;

            // Tổng số suất chi

            const countExpense =
                _.sumBy(listReceiptDetailByClass, 'rd_quantity') -
                countNextMonth -
                countRefundForMeal;

            // Tổng tiền chi
            const moneyExpense = countExpense * price;

            return {
                key: classId,
                c_id: classId,
                c_name: itemClass.c_name,
                c_sortorder: itemClass.c_sortorder,
                gl_id: itemClass.gl_id,
                totalCountReceiptInMonth:
                    countReceiptInMonth + countRefundPrevMonth + countNotPaid,
                totalMoneyReceiptInMonth:
                    moneyReceiptInMonth + moneyRefundPrevMonth,
                countReceiptInMonth,
                moneyReceiptInMonth,
                countRefundPrevMonth,
                moneyRefundPrevMonth,
                countExpense,
                moneyExpense,
                countRefundForMeal,
                moneyRefundForMeal,
                totalExpenseMoney: moneyExpense + moneyRefundForMeal,
                countNotPaid,
                moneyNotPaid,
                countNextMonth,
                moneyNextMonth,
            };
        });

        tableData = _.orderBy(
            tableData,
            ['gl_id', 'c_sortorder'],
            ['asc', 'asc']
        ).map((item, index) => ({
            ...item,
            sortOrder: index + 1,
        }));

        return tableData;
    },
};
