import * as types from './inventory.constant';

export const openInventoryModal = () => ({
    type: types.OPEN_INVENTORY_MODAL,
});

export const closeInventoryModal = () => ({
    type: types.CLOSE_INVENTORY_MODAL,
});

export const changeInventory = (id) => ({
    type: types.CHANGE_INVENTORY,
    payload: {
        id,
    },
});

export const getInventory = (data) => ({
    type: types.GET_INVENTORY,
    payload: {
        data,
    },
});

export const getInventorySuccess = (data) => ({
    type: types.GET_INVENTORY_SUCCESS,
    payload: {
        data,
    },
});

export const getMenu = (data, type, warehouse, creatorName) => ({
    type: types.GET_MENU,
    payload: {
        data,
        type,
        warehouse,
        creatorName,
    },
});

export const getMenuSuccess = (data, type, warehouse, creatorName) => ({
    type: types.GET_MENU_SUCCESS,
    payload: {
        data,
        type,
        warehouse,
        creatorName,
    },
});

export const saveInventory = (data) => ({
    type: types.SAVE_INVENTORY,
    payload: {
        data,
    },
});

export const saveInventorySuccess = (data) => ({
    type: types.SAVE_INVENTORY_SUCCESS,
    payload: {
        data,
    },
});

export const deleteInventory = (data) => ({
    type: types.DELETE_INVENTORY,
    payload: {
        data,
    },
});

export const deleteInventorySuccess = (data) => ({
    type: types.DELETE_INVENTORY_SUCCESS,
    payload: {
        data,
    },
});

export const openModalIn = (data) => ({
    type: types.OPEN_MODAL_IN,
    payload: {
        data,
    },
});

export const closeModalIn = () => ({
    type: types.CLOSE_MODAL_IN,
    payload: {},
});

export const openModalOut = (data) => ({
    type: types.OPEN_MODAL_OUT,
    payload: {
        data,
    },
});

export const closeModalOut = () => ({
    type: types.CLOSE_MODAL_OUT,
    payload: {},
});

export const checkFoodReceiptIn = (id) => ({
    type: types.CHECK_FOODRECEIPT_OUT,
    payload: {
        id,
    },
});

export const checkAll = () => ({
    type: types.CHECK_ALL,
});

export const update = (data, status) => ({
    type: types.UPDATE,
    payload: {
        data,
        status,
    },
});
