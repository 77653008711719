import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './school.constant';
import * as apiConst from '@app/constants/api';
import { getSchoolSuccess } from './school.action';
import { showError } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import { getList } from '@app/apis/schools';

function* sagaGetListSchool() {
    function* doRQ() {
        const res = yield call(getList);
        const { data, status } = res;
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(getSchoolSuccess(data.data));
        } else {
            showError(status, data);
        }
    }
    yield callLoading(doRQ);
}

function* schoolSaga() {
    yield takeEvery(types.GET_SCHOOL, sagaGetListSchool);
}

export default schoolSaga;
