/**
 * Chèn kí tự
 * @param {*} value : Giá trị cần xử lý
 * @param {*} num : Số kí tự cần chèn
 * @param {*} char : kí tự chèn
 * @returns (2,2,0) => 02
 */
const padLeft = (value, num, char) => String(value).padStart(num, char);

export { padLeft };
