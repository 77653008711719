/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';

const propTypes = {
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
    currentCount: PropTypes.number,
};

const CrmPagination = ({
    currentPage,
    pageSize,
    total,
    onChange,
    currentCount,
    showSizeChanger,
}) => (
    <div>
        {total <= pageSize ? (
            <div />
        ) : (
            <div className="text-center">
                <div className="text-center" style={{ marginBottom: '10px' }}>
                    <p style={{ marginBottom: '0px' }} className="text-muted">
                        {'Hiển thị bản ghi '}
                        <span className="text-black">
                            {(currentPage - 1) * pageSize + 1}
                            {' - '}
                            {(currentPage - 1) * pageSize +
                                (currentCount > pageSize
                                    ? pageSize
                                    : currentCount)}
                        </span>
                        {' của '}
                        <span className="text-black">{total}</span>
                    </p>
                </div>
                <Pagination
                    current={currentPage}
                    defaultPageSize={pageSize}
                    total={total}
                    onChange={(page) => onChange(page)}
                    showSizeChanger={showSizeChanger}
                />
            </div>
        )}
    </div>
);

CrmPagination.propTypes = propTypes;

export default CrmPagination;
