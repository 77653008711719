import React from 'react';
import { Field } from 'redux-form';

import RenderTextField from '@app/components/Layout/Shares/FormHelpers/TextField';
import AccountRoleSelectField from '@app/components/_Share/Select/AccountRoleSelect/DynamicField';
import RenderTextAreaField from '@app/components/Layout/Shares/FormHelpers/TextAreaField';
import GenderSelect from '@app/components/Layout/Shares/FormHelpers/GenderSelect';

import ImageAvatar from '@app/components/Layout/Shares/ImageAvatar';
import DatePickerField from '@app/components/Layout/Shares/FormHelpers/DatimePickerField';

const AccountUserInfo = ({ onChangeAvatar, initialValues }) => (
    <div className="panel panel-white  border-top-lg border-top-primary-800">
        <div className="panel-heading">
            <h6 className="panel-title text-semibold">
                THÔNG TIN TÀI KHOẢN NGƯỜI DÙNG
            </h6>
        </div>
        <div className="panel-body">
            <div className="row">
                <div className="col-md-3">
                    <ImageAvatar
                        onChange={onChangeAvatar}
                        currentImage={initialValues.su_avatar}
                    />
                </div>

                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <Field
                                    name="su_lastname"
                                    component={RenderTextField}
                                    type="text"
                                    className="form-control"
                                    label="Họ"
                                    requiredChar="*"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Field
                                    name="su_firstname"
                                    component={RenderTextField}
                                    type="text"
                                    className="form-control"
                                    label="Tên"
                                    requiredChar="*"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Field
                                    name="su_dateofbirth"
                                    component={DatePickerField}
                                    type="text"
                                    className="form-control"
                                    label="Ngày sinh"
                                    maxDate={new Date()}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>
                                    <h6 className="panel-title">Giới tính</h6>
                                </label>
                                <Field
                                    name="su_gender"
                                    component={GenderSelect}
                                    type="text"
                                    label="Giới tính"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Field
                                    name="su_homephone"
                                    component={RenderTextField}
                                    type="text"
                                    className="form-control"
                                    label="Điện thoại nhà"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <Field
                                    name="su_cellphone"
                                    component={RenderTextField}
                                    type="text"
                                    className="form-control"
                                    label="Điện thoại di động"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <Field
                            name="su_email"
                            component={RenderTextField}
                            type="email"
                            className="form-control"
                            label="Email"
                            requiredChar="*"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <Field
                            name="sr_id"
                            component={AccountRoleSelectField}
                            className="form-control"
                            label="Vai trò"
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <Field
                            name="su_note"
                            component={RenderTextAreaField}
                            className="form-control"
                            label="Ghi chú"
                            rows={4}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default AccountUserInfo;
