import * as types from './warehouse.constant';

const initState = {
    warehouseList: [],
    currentWarehouse: -1,
    currentItem: {},
    isShowModal: false,
};

const warehouseReducer = (state = initState, action) => {
    switch (action.type) {
        case types.OPEN_WAREHOUSE_MODAL: {
            return {
                ...state,
                isShowModal: true,
            };
        }
        case types.CLOSE_WAREHOUSE_MODAL: {
            return {
                ...state,
                isShowModal: false,
            };
        }
        case types.CHANGE_WAREHOUSE: {
            const { id } = action.payload;
            const currentItem =
                state.warehouseList.find((o) => o.w_id === id) ?? {};
            return {
                ...state,
                currentWarehouse: id,
                currentItem,
            };
        }
        case types.GET_WAREHOUSE_SUCCESS: {
            const { data } = action.payload;
            const _id = data.length > 0 ? data[0].w_id : -1;
            const currentItem = data.find((o) => o.w_id === _id) ?? {};

            return {
                ...state,
                warehouseList: data,
                currentWarehouse: _id,
                currentItem,
            };
        }
        default:
            return { ...state };
    }
};

export default warehouseReducer;
