import * as types from './constants';

const initialState = {
    yearCache: [],
    yearCacheSelect: new Date().getFullYear() - 1,
    schoolInventoryCache: [],
};

export const othersReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.GET_YEAR_CACHE_SUCCESS: {
            const { data } = payload;
            return {
                ...state,
                yearCache: data,
            };
        }

        case types.CHANGE_YEAR_CACHE_SELECT: {
            const { data } = payload;
            return {
                ...state,
                yearCacheSelect: data,
            };
        }

        case types.UPDATE_SCHOOL_INVENTORY_CACHE: {
            const { data } = payload;
            return {
                ...state,
                schoolInventoryCache: data,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default othersReducer;
