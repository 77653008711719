import axiosService from '@app/services/axiosService';
import { API_REPORT_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_REPORT_ENDPOINT}/v1/Report`;
const LIST_URL = {
    EXPORT_PDF: `${BASE_URL}/ExportPdf`,
};

/**
 * Xuất báo cáo sang file PDF
 * @param {*} dataPost: dữ liệu gửi lên
 * @param {string} dataPost.data: dữ liệu cần xuất báo cáo (dùng JSON.stringify)
 * @param {number} dataPost.report: kiểu báo cáo (constants > index > REPORT_TYPE)
 * @returns => Chuỗi Base64
 */
export const exportReportToPDF = (dataPost) =>
    axiosService.post(LIST_URL.EXPORT_PDF, dataPost);
