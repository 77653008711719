export const MOMENT_RANGE_TYPE = {
    /**
     * Ngày
     */
    DAY: 'D',
    /**
     * Tuần
     */
    WEEK: 'W',

    /**
     * Tháng
     */
    MONTH: 'M',

    /**
     * Quý
     */
    QUARTER: 'Q',

    YEAR: 'year',
};
