import axiosService from '@app/services/axiosService';
import { API_SC_GATEWAY_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/ToolSC/Income/Attendance`;

const LIST_URL = {
    CREATE: `${BASE_URL}/Create`,
    DELETE: `${BASE_URL}/Delete`,
    GET_LIST: `${BASE_URL}/GetList`,
    GET_SUMMARY: `${BASE_URL}/Summary`,
};

/**
 * CÁC API LIÊN QUAN ĐẾN BẢNG ĐIỂM DANH
 */
const _icAttandanceApis = {
    /**
     * Lấy danh sách bảng điểm danh theo lớp
     * @param {Object} dataPost: dữ liệu đẩy lên API
     * @param {number} dataPost.yearId: Id năm học
     * @param {number} dataPost.classId: Id lớp học
     * @param {number} dataPost.month: tháng học
     * @param {string} dataPost.oId: mã tổ chức
     * @returns
     */
    getList(dataPost) {
        return axiosService.post(LIST_URL.GET_LIST, dataPost);
    },

    /**
     * Lấy danh sách bảng điểm danh theo lớp
     * @param {Object} dataPost: dữ liệu đẩy lên API
     * @param {number} dataPost.yearId: Id năm học
     * @param {number} dataPost.month: tháng học
     * @param {string} dataPost.oId: mã tổ chức
     * @param {Array} dataPost.listClassId: danh sách Id lớp học
     * @returns
     */
    getSummary(dataPost) {
        return axiosService.post(LIST_URL.GET_SUMMARY, dataPost);
    },

    /**
     * Tạo bảng điểm danh
     * @param {Object} dataPost: dữ liệu đẩy lên API
     * @param {number} dataPost.yearId: Id năm học
     * @param {number} dataPost.classId: Id lớp học
     * @param {number} dataPost.month: tháng học
     * @param {string} dataPost.oId: mã tổ chức
     * @returns Thành công || Thất bại
     */
    create(dataPost) {
        return axiosService.post(LIST_URL.CREATE, dataPost);
    },
    /**
     * Xóa bảng điểm danh
     * @param {Object} dataPost: dữ liệu đẩy lên API
     * @param {number} dataPost.yearId: Id năm học
     * @param {number} dataPost.classId: Id lớp học
     * @param {number} dataPost.month: tháng học
     * @param {string} dataPost.oId: mã tổ chức
     * @returns Thành công || Thất bại
     */
    delete(dataPost) {
        return axiosService.post(LIST_URL.DELETE, dataPost);
    },
};

export default _icAttandanceApis;
