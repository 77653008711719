import React from 'react';
import logoQuocHung from '../../../assets/images/logo.png';

import './index.scss';
// import LanguageHeader from './LanguageHeader';
// import MessageHeader from './MessageHeader';
import UserHeader from './UserHeader';
import SchoolModal from './SchoolModal';
import SchoolSelector from '@app/redux/school/school.selector';
import * as schoolActions from '@app/redux/school/school.action';
import { useSelector, useDispatch } from 'react-redux';
import SchoolListSelect from '../Shares/SchoolSelect/SchoolListSelect';
import { getCurrentUser } from '@app/utils/utils';
import ChangePasswordPopup from '@app/components/_Share/ChangePasswordPopup';

const MainHeader = () => {
    const dispatch = useDispatch();
    const isShowModal = useSelector(SchoolSelector.isShowModal);

    const user = getCurrentUser();

    const closeModal = () => {
        dispatch(schoolActions.closeSchoolModal());
    };

    const getNameOrg = () => user?.o_name || '';

    return (
        <>
            <div className="navbar navbar-fixed-top">
                <div className="navbar-header">
                    <a
                        className="navbar-brand"
                        id="link-module-sc"
                        href="/"
                        // data-popup="popover"
                        // title={getNameOrg()}
                        // data-trigger="hover"
                        data-html="true"
                        data-placement="bottom"
                    >
                        <img src={logoQuocHung} alt={getNameOrg()} />
                    </a>
                    {/* <ul className="nav navbar-nav visible-xs-block">
            <li>
              <a data-toggle="collapse" data-target="#navbar-mobile">
                <i className="icon-tree5" />
              </a>
            </li>
            <li>
              <a className="sidebar-mobile-main-toggle">
                <i className="icon-paragraph-justify3" />
              </a>
            </li>
          </ul> */}
                </div>
                <div className="navbar-collapse collapse" id="navbar-mobile">
                    {/* <ul className="nav navbar-nav">
            <li>
              <a className="sidebar-control sidebar-main-toggle hidden-xs">
                <i className="icon-paragraph-justify3" />
              </a>
            </li>
          </ul> */}
                    <p className="navbar-text">
                        {/* <span className="text-bold">{getNameOrg()}</span> */}
                        <span className="text-bold">
                            DỊCH VỤ SỔ SÁCH BÁN TRÚ
                        </span>
                    </p>

                    <UserHeader />
                    <div
                        className="nav navbar-nav navbar-right"
                        style={{ marginTop: '5px', minWidth: '400px' }}
                    >
                        <SchoolListSelect />
                    </div>
                    <SchoolModal
                        show={isShowModal}
                        onClose={closeModal}
                        // editItem={editItem}
                        // initialValues={editItem}
                        // handleChangeFromDate={handleChangeFromDate}
                        // handleChangeToDate={handleChangeToDate}
                    />
                </div>
            </div>
            <ChangePasswordPopup />
        </>
    );
};
export default MainHeader;
