const prefix = 'WARD_V2_';

export const GET_WARDS = `${prefix}GET_WARDS`;
export const GET_WARDS_SUCCESS = `${prefix}GET_WARDS_SUCCESS`;

export const ADD_NEW = `${prefix}ADD_NEW`;

export const CLOSE_WARD_MODAL = `${prefix}CLOSE_WARD_MODAL`;

export const RESET_WARD_EDIT_ITEM = `${prefix}RESET_WARD_EDIT_ITEM`;

export const SAVE_WARD = `${prefix}SAVE_WARD`;
export const SAVE_WARD_SUCCESS = `${prefix}SAVE_WARD_SUCCESS`;

export const EDIT_WARD = `${prefix}EDIT_WARD`;

export const DELETE_WARD = `${prefix}DELETE_WARD`;
export const DELETE_WARD_SUCCESS = `${prefix}DELETE_WARD_SUCCESS`;

export const GET_WARD_SELECT_LIST = `${prefix}GET_WARD_SELECT_LIST`;
export const GET_WARD_SELECT_LIST_SUCCESS = `${prefix}GET_WARD_SELECT_LIST_SUCCESS`;
export const WARD_SELECT_CHANGE = `${prefix}WARD_SELECT_CHANGE`;

// My
export const CLEAN_SELECT_LIST = `${prefix}CLEAN_SELECT_LIST`;
