/* eslint-disable react/no-unused-state */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import InventorySelector from '@app/redux/inventory/inventory.selector';
import * as actions from '@app/redux/inventory/inventory.action';
import { reduxForm } from 'redux-form';
import OnlySelect from '../_Share/Select/onlySelect';
import { SCHOOL_SELECT } from '@app/constants/global.const';
import monthReducer from '@app/redux/others/month/month.selector';
import WarehouseSelector from '@app/redux/warehouse/warehouse.selector';
import WarehouseSelect from '../Layout/Shares/WarehouseSelect';
import SchoolYearSelect from '@app/components/_Share/Select/SchoolYearSelect/Dynamic';
import MonthSelect from '../Layout/Shares/MonthSelect';

const types = [
    { type: 1, name: 'Nhập kho 1 lần/tháng' },
    { type: 2, name: 'Nhập kho 2 lần/tháng' },
    { type: 3, name: 'Nhập kho theo tuần' },
];

const InventoryModal = (props) => {
    const { closeModal, handleSubmit } = props;
    const [type, setType] = useState(1);
    const [year, setYear] = useState(0);
    const [userCreate, setUserCreate] = useState('');
    const [note, setNote] = useState('');
    const currentMonth = useSelector(monthReducer.currentMonth);
    const currentWarehouse = useSelector(WarehouseSelector.currentWarehouse);
    const isShowModalIn = useSelector(InventorySelector.isShowModalIn);

    const dispatch = useDispatch();

    const handleSubmitForm = () => {
        const scholl_select = localStorage.getItem(SCHOOL_SELECT);
        dispatch(
            actions.saveInventory({
                w_id: currentWarehouse,
                w_type: 1,
                type,
                o_id: scholl_select,
                year,
                month: currentMonth,
                userCreate,
                note,
            })
        );
    };

    return (
        <Modal show={isShowModalIn} animation backdrop="static" size="lg">
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Modal.Header closeButton className="bg-primary-800 text-white">
                    <Modal.Title>Nhập kho</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            <h6 className="panel-title">
                                                Phương thức nhập
                                            </h6>
                                        </label>
                                        <OnlySelect
                                            select_id="type"
                                            select_name="name"
                                            selectList={types}
                                            selectDefault={type}
                                            onChange={(value) => setType(value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <WarehouseSelect />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <SchoolYearSelect
                                            label="Năm học"
                                            onChange={(value) => setYear(value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <MonthSelect />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            <h6 className="panel-title">
                                                Người lập
                                            </h6>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={userCreate}
                                            onChange={(e) =>
                                                setUserCreate(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            <h6 className="panel-title">
                                                Lý do nhập
                                            </h6>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={note}
                                            onChange={(e) =>
                                                setNote(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="submit"
                        className="btn btn-xs btn-primary bg-primary-800 btn-labeled"
                        // disabled={
                        //     menuSelect.m_code === null ||
                        //     menuSelect.m_code === '' ||
                        //     menuSelect.m_quantitybefore <= 0 ||
                        //     menuSelect.m_price <= 0
                        // }
                    >
                        <b>
                            <i className="icon-floppy-disk" />
                        </b>
                        Lưu
                    </button>

                    <button
                        type="button"
                        className="btn btn-xs btn-labeled bg-slate"
                        data-dismiss="modal"
                        onClick={closeModal}
                    >
                        <b>
                            <i className="glyphicon glyphicon-off" />
                        </b>
                        Thoát
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
    // }
};

// const withReduxForm = reduxForm({
//     form: REDUX_FORM_NAME.MENU_LIST_FORM,
//     validate,
//     enableReinitialize: true,
// });

// const mapStateToProps = (state) => ({
//     isShow: state.InventoryMenuList.isShowModal,
//     InventoryDishCache: state.others.foodDishCache,
//     kidGroupList: state.others.kidGroupCache,
//     currentSelect: state.mealType.currentSelect,
//     dishSelect: state.InventoryMenuList.dishSelect,
//     dateFrom: state.InventoryMenuList.dateFrom,
//     dateTo: state.InventoryMenuList.dateTo,
//     modalDate: state.InventoryMenuList.modalDate,
//     kidGroupSelect: state.kidGroup.currentkidGroup,
// });

// const mapDispathToProps = (dispath) => ({
//     menuListCreator: bindActionCreators(menuListActions, dispath),
// });

const withReduxForm = reduxForm({
    form: 'INVENTORY_FORM',
    // validate,
    enableReinitialize: true,
});

export default withReduxForm(InventoryModal);
