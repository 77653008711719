/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import { GLOBAL } from '@app/constants/global.const';
import _globalUtils from '@app/utils/global.utils';
import reportUtils from '@app/utils/reportUtils';
import { priceType } from '@app/utils/utils';

const { roundNumber } = _globalUtils;

/** TỔNG HỢP THẺ KHO */
class _receiptTotalExportPDFService {
    /**
     * Merge dữ liệu cho bảng điểm danh
     * @param {Array} foodReceiptTotalList : danh sách thực phẩm kho
     * @param {number} year : id năm học
     * @param {number} month : id tháng học
     * @param {Array} foodReceiptBeforeList : thực phẩm kho
     * @param {object} locationInfo : thông tin địa chỉ của trường
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     * @param {object} inventory : kho
     */
    doExport = async (
        foodReceiptTotalList,
        year,
        month,
        foodReceiptBeforeList,
        locationInfo,
        schoolInfo,
        organizationInfo,
        inventory,
        listSchoolFood
    ) => {
        const list = foodReceiptTotalList.map((item) => {
            const objBefore = foodReceiptBeforeList.find(
                (elm) => item.sf_id === elm.sf_id
            );
            return {
                ...item,
                soLuongTon: objBefore
                    ? roundNumber(objBefore.soLuongNhap - objBefore.soLuongXuat)
                    : 0,
                tongTienTon: objBefore
                    ? roundNumber(
                          objBefore.tongTienNhap - objBefore.tongTienXuat,
                          0
                      )
                    : 0,
            };
        });

        const info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            assistantTitle:
                schoolInfo.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_ASSISTANT_TITLE,
            school: schoolInfo.ten_truong,
            date: `${month}/${year}`,
            warehouseKeeper: schoolInfo.ho_ten_thu_kho,
            stocker: schoolInfo.ho_ten_thu_kho,
            creator: schoolInfo?.ten_nguoi_lap_bang,

            assistant: schoolInfo.ho_ten_hieu_pho_ban_tru,
        };
        let datas = list.map((item, i) => {
            const objSchoolFood = listSchoolFood.find(
                (o) => o.sf_id === item.sf_id
            );
            const soluongton = roundNumber(
                item.soLuongTon + item.soLuongNhap - item.soLuongXuat
            );
            const tongtienton = roundNumber(
                item.tongTienTon + item.tongTienNhap - item.tongTienXuat,
                0
            );
            const d = {};
            d.stt = i + 1;
            d.f_name = objSchoolFood?.sf_name;
            d.inventory_first_quantity =
                item.soLuongTon === 0
                    ? ''
                    : priceType(roundNumber(item.soLuongTon));
            d.inventory_first_price =
                item.tongTienTon === 0
                    ? ''
                    : priceType(roundNumber(item.tongTienTon, 0));
            d.inventory_last_quantity =
                soluongton === 0 ? '' : priceType(soluongton);
            d.inventory_last_price =
                tongtienton === 0 ? '' : priceType(tongtienton);
            d.import_warehouse_quantity =
                item.soLuongNhap === 0
                    ? ''
                    : priceType(roundNumber(item.soLuongNhap));
            d.import_warehouse_price =
                item.tongTienNhap === 0
                    ? ''
                    : priceType(roundNumber(item.tongTienNhap, 0));
            d.export_warehouse_quantity =
                item.soLuongXuat === 0
                    ? ''
                    : priceType(roundNumber(item.soLuongXuat));
            d.export_warehouse_price =
                item.tongTienXuat === 0
                    ? ''
                    : priceType(roundNumber(item.tongTienXuat, 0));
            return d;
        });

        datas = _globalUtils.sortByVietnamName(datas, 'f_name').map((o) => ({
            ...o,
            stt: o.sortOrder,
        }));

        const res = await exportReportToPDF({
            data: JSON.stringify({ info, datas }),
            report: REPORT_TYPE.TONG_HOP_THE_KHO,
        });
        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `TongHopTheKho_Thang_${month}_${year}_${inventory?.w_name}_${organizationInfo.o_name}`
            );
        }
        return true;
    };
}

export default new _receiptTotalExportPDFService();
