import React from 'react';
import './Menu.scss';
// import menuIcon from '@app/assets/images/magic-wand_1514313.svg';

const MenuList = (props) => (
    <div className="sidebar sidebar-main">
        <div className="sidebar-content">
            {/* <div className="sidebar-user">
                <div className="category-content">
                    <div className="media">
                        <span
                            className="media-left"
                            data-popup="tooltip"
                            data-placement="right"
                            data-original-title="PHÂN HỆ DỊCH VỤ SỔ SÁCH"
                        >
                            <img
                                src={menuIcon}
                                title="Dịch vụ sổ sách"
                                alt="EP"
                            />
                        </span>
                        <div className="media-body">
                            <span className="media-heading text-size-mini text-muted">
                                Phân hệ
                            </span>
                            <div className="text-semibold ">
                                Dịch vụ sổ sách
                            </div>
                        </div>

                        <div className="media-right media-middle">
                            <ul className="icons-list">
                                <li>
                                    <span>
                                        <i className="icon-cog3" />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="sidebar-category sidebar-category-visible">
                <div className="category-content no-padding">
                    <ul className="navigation navigation-main navigation-accordion">
                        {/* <li className="navigation-header">
                            <span>Danh sách các tính năng</span>
                            <i
                                className="icon-menu"
                                title=""
                                data-original-title="Cách tính năng"
                            />
                        </li> */}
                        {props.children}
                    </ul>
                </div>
            </div>
        </div>
    </div>
);

export default MenuList;
