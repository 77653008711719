/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import { GLOBAL } from '@app/constants/global.const';
import {
    Step1AReportModel,
    Step1BReportModel,
    Step2AReportModel,
    Step2BReportModel,
} from '@app/models/foodkid/foodkid.model';
import { foodName, mealName, prepare } from '@app/utils/foodkid.data';
import _globalUtils from '@app/utils/global.utils';
import reportUtils from '@app/utils/reportUtils';

import moment from 'moment';

const sample = (md_meal) => {
    switch (md_meal) {
        case 1:
            return 'gio_lay_mau_buoi_sang_3';
        case 2:
            return 'gio_lay_mau_buoi_trua_3';
        case 3:
            return 'gio_lay_mau_bua_chinh_chieu_3';
        default:
            return 'gio_lay_mau_bua_phu_chieu_3';
    }
};

const sample_cancel = (md_meal) => {
    switch (md_meal) {
        case 1:
            return 'gio_huy_mau_buoi_sang_3';
        case 2:
            return 'gio_huy_mau_buoi_trua_3';
        case 3:
            return 'gio_huy_mau_bua_chinh_chieu_3';
        default:
            return 'gio_huy_mau_bua_phu_chieu_3';
    }
};

class _stepExportPDFService {
    /**
     * Merge dữ kiểm thực
     * @param {Array} menudishlist : danh sách món ăn
     * @param {Array} schoolFoods : danh sách thực phẩm trường
     * @param {Array} menuSchoolFoods : danh sách thực phẩm của thực đơn
     * @param {Array} units : danh sách đơn vị tính
     * @param {Array} menulist : danh sách thực đơn
     * @param {object} locationInfo : thông tin địa chỉ của trường
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     * @param {Array} suppliers : danh sách nhà cung cấp
     * @param {Array} foodDish : thông tin món ăn
     * @param {Array} packaging: danh sách dụng cụ chứa đựng
     * @param {String} nameFile: tên prefix file khi xuất ra
     */
    doExport = async (
        menudishlist,
        schoolFoods,
        menuSchoolFoods,
        units,
        menulist,
        locationInfo,
        schoolInfo,
        organizationInfo,
        suppliers,
        foodDish,
        packaging,
        nameFile,
        isExportEmpty
    ) => {
        const _nameFile = nameFile || 'SoKiemThuc';

        const datas = [];
        menuSchoolFoods.forEach((entry) => {
            const sf = schoolFoods.find((o) => o.sf_id === entry.sf_id);
            entry.sf_group = sf?.sf_group;
            const data = datas.find(
                (o) =>
                    o.sf_id === entry.sf_id &&
                    o.msf_priceafter === entry.msf_priceafter
            );
            if (data === undefined) {
                datas.push(entry);
            } else {
                const index = datas.indexOf(data);
                datas[index].msf_totalquantitybefore = parseFloat(
                    (
                        Math.round(
                            (datas[index].msf_totalquantitybefore +
                                entry.msf_totalquantitybefore) *
                                100
                        ) / 100
                    ).toFixed(2)
                );
                datas[index].msf_totalquantityafter = parseFloat(
                    (
                        Math.round(
                            (datas[index].msf_totalquantityafter +
                                entry.msf_totalquantityafter) *
                                100
                        ) / 100
                    ).toFixed(2)
                );
            }
        });

        const info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            // province: locationInfo.cityName,
            // district: locationInfo.districtName,
            // wards: locationInfo.wardName,
            school: schoolInfo.ten_truong,
            date: `Ngày: ${moment
                .unix(menulist[0].m_datefounded)
                .format('DD/MM/YYYY')}`,
            administrators: schoolInfo?.ho_ten_hieu_pho_ban_tru,
            administrators_title:
                schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_ASSISTANT_TITLE,

            // principal: schoolInfo.ho_ten_hieu_truong,
            // assistant: schoolInfo.ho_ten_hieu_pho_chuyen_mon,
            provision: schoolInfo.ho_ten_cap_duong,
            accountant: schoolInfo.ho_ten_ke_toan,
            reception: schoolInfo.ban_tiep_nhan,
            medical: schoolInfo.ho_ten_y_te,
            user_save: schoolInfo.nguoi_luu_mau,
            user_remove: schoolInfo.nguoi_huy_mau,
            user_charge: '',
            user_check: schoolInfo.nguoi_kiem_tra,
            time_check: schoolInfo.thoi_gian_kiem_tra,
            localtion_check: schoolInfo.dia_diem_kiem_tra,
            visibleFooter: !schoolInfo?.an_ky_ten_so_ba_buoc,
        };
        const foods = datas
            .filter((o) => o.sf_group === 2)
            .map((item, i) => {
                const food = new Step1AReportModel(item);
                const sf = schoolFoods.find((o) => o.sf_id === item.sf_id);
                // const supplier = suppliers.find((o) => o.s_id === item.s_id);

                food.stt = i + 1;
                food.f_name = sf?.sf_name;
                food.unit = item.msf_totalquantitybefore;
                food.date = moment
                    .unix(menulist[0].m_datefounded)
                    .format('DD-MM-YYYY');
                // item.msf_step1dateimported;
                food.s_name = item.msf_step1suppliername;
                food.s_deliver = item.msf_step1supplierrepresentative;
                food.s_address = item.msf_step1supplieraddress;

                food.msf_step1dateexpired =
                    // eslint-disable-next-line eqeqeq
                    item.msf_step1dateexpired != 0
                        ? item.msf_step1dateexpired
                        : '';

                if (isExportEmpty) {
                    food.getEmptyReport();
                }
                return food;
            });

        const foods1B = datas
            .filter((o) => o.sf_group === 1)
            .map((item, i) => {
                const food = new Step1BReportModel();
                const sf = schoolFoods.find((o) => o.sf_id === item.sf_id);
                const supplier = suppliers.find((o) => o.s_id === item.s_id);

                food.stt = i + 1;
                food.f_name = sf?.sf_name;
                // food.unit = unit.cu_name;
                food.unit = item.msf_totalquantitybefore;
                food.date = moment
                    .unix(menulist[0].m_datefounded)
                    .format('DD-MM-YYYY');
                // item.msf_step1dateimported || '';
                food.s_name = supplier != null ? supplier.s_name : '';
                food.s_deliver = supplier != null ? supplier.s_deliver : '';
                food.s_address = supplier != null ? supplier.s_address : '';
                food.msf_step1invoicenumber = item.msf_step1invoicenumber || '';
                food.msf_step1quarantinecertificate =
                    item.msf_step1quarantinecertificate || '';
                food.msf_step1foodcertificate = item?.msf_step1foodcertificate
                    ? sf?.sf_foodcertificate || ''
                    : '';
                food.msf_step1qualitytestsucceed =
                    item.msf_step1qualitytestsucceed || '';
                food.msf_step1qualitytestfailed =
                    item.msf_step1qualitytestfailed || '';
                food.msf_step1quicktestsucceed =
                    item.msf_step1quicktestsucceed || '';
                food.msf_step1quicktestfailed =
                    item.msf_step1quicktestfailed || '';
                food.msf_step1note = item.msf_step1note || '';

                if (isExportEmpty) {
                    food.getEmptyReport();
                }
                return food;
            });

        const foods2A = _.chain(menudishlist)
            .cloneDeep()
            .orderBy(['md_meal', 'md_datecreated'], ['asc', 'asc'])
            .reduce((array, item) => {
                const check = array.find(
                    (o) => o.d_id === item.d_id && o.md_meal === item.md_meal
                );
                if (check === undefined) {
                    array.push(item);
                }
                return array;
            }, [])
            .map((item, i) => {
                const food = new Step2AReportModel();
                food.stt = i + 1;
                food.meal = mealName(item);
                food.d_name = foodName(item, foodDish);
                food.prepare =
                    item.md_step2amainingredients || prepare(item, foodDish);

                food.md_step2aquantity = menulist
                    .filter((o) =>
                        menudishlist.find(
                            (j) =>
                                j.m_id === o.m_id &&
                                j.d_id === item.d_id &&
                                j.md_meal === item.md_meal
                        )
                    )
                    .reduce(
                        (prev, current) => prev + +current.m_quantitybefore,
                        0
                    );
                food.md_step2atimeprepare = item.md_step2atimeprepare || '';
                food.md_step2atimecooking = item.md_step2atimecooking || '';
                food.md_step2acookingchef = item.md_step2acookingchef || '';
                food.md_step2acookingequipment =
                    item.md_step2acookingequipment || '';
                food.md_step2acookingarea = item.md_step2acookingarea || '';
                food.md_step2aqualitytestsucceed =
                    item.md_step2aqualitytestsucceed || '';
                food.md_step2aqualitytestfailed =
                    item.md_step2aqualitytestfailed || '';
                food.md_step2anote = item.md_step2anote || '';
                food.md_meal = item.md_meal;
                food.md_datecreated = item.md_datecreated;

                if (isExportEmpty) {
                    food.getEmptyReport();
                }

                return food;
            })
            .orderBy(['md_meal', 'md_datecreated'], ['asc', 'asc'])
            .map((item, index) => ({
                ...item,
                stt: index + 1,
            }))
            .value();
        const foods2B = _.chain(menudishlist)
            .cloneDeep()
            .orderBy(['md_meal', 'md_datecreated'], ['asc', 'asc'])
            .reduce((array, item) => {
                const check = array.find(
                    (o) => o.d_id === item.d_id && o.md_meal === item.md_meal
                );
                if (check === undefined) {
                    array.push(item);
                }
                return array;
            }, [])
            .map((item, i) => {
                const food = new Step2BReportModel();
                food.stt = i + 1;
                food.meal = mealName(item);
                food.d_name = foodName(item, foodDish);
                food.prepare =
                    item.md_step2amainingredients || prepare(item, foodDish);

                food.md_step2aquantity = menulist
                    .filter((o) =>
                        menudishlist.find(
                            (j) =>
                                j.m_id === o.m_id &&
                                j.d_id === item.d_id &&
                                j.md_meal === item.md_meal
                        )
                    )
                    .reduce(
                        (prev, current) => prev + +current.m_quantitybefore,
                        0
                    );
                food.md_step2btimedevidefood =
                    item.md_step2btimedevidefood || '';
                food.md_step2btimeeat = item.md_step2btimeeat || '';
                food.md_step2bequipmentdevidefood = packaging.find(
                    (o) => o.p_id === +item.md_step2bequipmentdevidefood
                )?.p_name;
                food.md_step2bqualitytestsucceed =
                    item.md_step2bqualitytestsucceed || '';
                food.md_step2bqualitytestfailed =
                    item.md_step2bqualitytestfailed || '';
                food.md_step2bnote = item.md_step2bnote || '';

                food.md_meal = item.md_meal;
                food.md_datecreated = item.md_datecreated;

                if (isExportEmpty) {
                    food.getEmptyReport();
                }
                return food;
            })
            .orderBy(['md_meal', 'md_datecreated'], ['asc', 'asc'])
            .map((item, index) => ({
                ...item,
                stt: index + 1,
            }))
            .value();
        const foods3 = _.chain(menudishlist)
            .orderBy(['md_meal', 'md_datecreated'], ['asc', 'asc'])
            .cloneDeep()
            .reduce((array, item) => {
                const check = array.find(
                    (o) => o.d_id === item.d_id && o.md_meal === item.md_meal
                );
                if (check === undefined) {
                    array.push(item);
                }
                return array;
            }, [])
            .map((item, index) => {
                const persongetsample = schoolInfo.nguoi_luu_mau;
                const persondestroysample = schoolInfo.nguoi_huy_mau;
                const md_step3timegetsample = schoolInfo[sample(item.md_meal)];
                const md_step3timedestroysample =
                    schoolInfo[sample_cancel(item.md_meal)];
                return {
                    meal: mealName(item),
                    d_name: foodName(item, foodDish),
                    prepare: prepare(item, foodDish),

                    md_step2aquantity: menulist
                        .filter((o) =>
                            menudishlist.find(
                                (j) =>
                                    j.m_id === o.m_id &&
                                    j.d_id === item.d_id &&
                                    j.md_meal === item.md_meal
                            )
                        )
                        .reduce(
                            (prev, current) => prev + +current.m_quantitybefore,
                            0
                        ),
                    user_save: item.md_step3persongetsample || persongetsample,
                    user_remove:
                        item.md_step3persondestroysample || persondestroysample,
                    msf_step1dateimported:
                        item.msf_step1dateimported || ''
                            ? moment(new Date()).format('DD-MM-YYYY')
                            : item.msf_step1dateimported,
                    msf_step1dateexpired: item.msf_step1dateexpired || '',
                    // ? moment(new Date()).format('DD-MM-YYYY')
                    // : item.msf_step1dateexpired,
                    md_step3timegetsample:
                        item.md_step3timegetsample || md_step3timegetsample,
                    md_step3timedestroysample:
                        item.md_step3timedestroysample ||
                        md_step3timedestroysample,
                    md_step3storagetemperature:
                        item.md_step3storagetemperature || '',
                    md_step3sampleweight: item.md_step3sampleweight,
                    md_step3foodcontainer: packaging.find(
                        (o) => o.p_id === +item.md_step3foodcontainer
                    )?.p_name,
                    md_step3note: item.md_step3note || '',
                    // user_save: '',
                    // user_remove: '',
                    stt: index + 1,

                    md_meal: item.md_meal,
                    md_datecreated: item.md_datecreated,
                };
            })
            .orderBy(['md_meal', 'md_datecreated'], ['asc', 'asc'])
            .map((item, index) => ({
                ...item,
                stt: index + 1,
            }))
            .value();

        const res = await exportReportToPDF({
            data: JSON.stringify({
                info,
                foods,
                foods1B,
                foods2A,
                foods2B,
                foods3,
            }),
            report: REPORT_TYPE.SO_KIEM_THUC,
        });
        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `${isExportEmpty ? `${_nameFile}T` : _nameFile}_${moment
                    .unix(menulist[0].m_datefounded)
                    .format('DD/MM/YYYY')}_${organizationInfo.o_name}`
            );
        }
        return true;
    };
}

export default new _stepExportPDFService();
