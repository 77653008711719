import React from 'react';
import { Modal } from 'react-bootstrap';
import ButtonModalAction from '@app/components/UI/Button/ButtonModalAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import _ from 'lodash';
import NoData from '../Layout/Shares/NoData';
import { numberToPrice } from '@app/utils/utils';

const TransactionHistoryModal = ({
    isShow,
    onCloseModal,
    currentInfoDetail,
    listProduct,
}) => {
    const showDetail = () => {
        if (_.isEmpty(currentInfoDetail?.listDetail)) {
            return <NoData colspan={6} />;
        }

        return (currentInfoDetail?.listDetail ?? [])?.map((item, idx) => (
            <tr>
                <td className="text-center">{idx + 1}</td>
                <td>{listProduct[item.p_id]?.p_name}</td>
                <td className="text-center">
                    {listProduct[item.p_id]?.cu_name}
                </td>
                <td className="text-right">{item?.sod_quantity}</td>
                <td className="text-right">{numberToPrice(item?.sod_price)}</td>
                <td className="text-right">
                    {numberToPrice(
                        +(item?.sod_quantity ?? 0) * +(item?.sod_price ?? 0)
                    )}
                </td>
            </tr>
        ));
    };

    return (
        <Modal
            show={isShow}
            onHide={onCloseModal}
            animation
            backdrop="static"
            size="lg"
        >
            <form>
                <Modal.Header closeButton className="bg-primary-800">
                    <Modal.Title>
                        Thông tin đơn hàng ({currentInfoDetail?.so_code})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="panel panel-white">
                        <table className="table table-togglable table-hover table-bordered table-striped">
                            <thead className="grey-light">
                                <tr>
                                    <th
                                        className="text-center"
                                        style={{ width: '55px' }}
                                    >
                                        STT
                                    </th>
                                    <th
                                        className="text-center"
                                        data-toggle="true"
                                    >
                                        Sản phẩm - Dịch vụ
                                    </th>
                                    <th
                                        className="text-center"
                                        data-hide="phone"
                                        style={{ width: '15%' }}
                                    >
                                        ĐVT
                                    </th>
                                    <th
                                        className="text-center"
                                        data-hide="phone"
                                        style={{ width: '10%' }}
                                    >
                                        Số lượng
                                    </th>
                                    <th
                                        className="text-center"
                                        data-hide="phone"
                                        style={{ width: '10%' }}
                                    >
                                        Đơn giá
                                    </th>
                                    <th
                                        className="text-center"
                                        style={{ width: '15%' }}
                                    >
                                        Thành tiền
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{showDetail()}</tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-group">
                        <ButtonModalAction
                            buttonType={BUTTON_ACTION_TYPE.CLOSE}
                            buttonLabel="Thoát"
                            onClick={onCloseModal}
                        />
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
    // }
};

export default TransactionHistoryModal;
