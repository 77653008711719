import {
    apiGetListAgency,
    apiGetListSchOfAgency,
} from '@app/apis/agency/agency.api';
import { getListScale, getListType } from '@app/apis/schools';
import { apiGetList } from '@app/apis/schools/connectionHistory';
import { apiGetListTransaction } from '@app/apis/transactionHistory/transactionHistory.api';
import { STATUS_CODES } from '@app/constants/api';
import { showError } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { agencyActions } from './agency.slice';

function* sagaFetchDataFirst() {
    const listResp = yield all([call(getListScale), call(getListType)]);

    const checkStatus = listResp.find(
        (resp) => resp.status !== STATUS_CODES.SUCCESS
    );

    if (!checkStatus) {
        const [dataScale, dataType] = listResp;

        yield put(
            agencyActions.doFetchDataFirstSuccess({
                dataScale: dataScale.data.data,
                dataType: dataType.data.data,
            })
        );
    } else {
        showError(checkStatus.status, checkStatus.data);
    }
}

function* sgGetListAgency({ payload }) {
    yield callLoading(function* doRQ() {
        const { status, data } = yield call(apiGetListAgency, payload);

        if (status === STATUS_CODES.SUCCESS) {
            yield put(agencyActions.doGetListAgencySuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sgGetListSchOfAgency({ payload }) {
    yield callLoading(function* doRQ() {
        const { status, data } = yield call(apiGetListSchOfAgency, payload);

        if (status === STATUS_CODES.SUCCESS) {
            yield put(agencyActions.doGetListSchOfAgencySuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sgGetListTransHistory({ payload }) {
    yield callLoading(function* doRQ() {
        const { status, data } = yield call(apiGetListTransaction, payload);

        if (status === STATUS_CODES.SUCCESS) {
            yield put(agencyActions.doGetListTransHistorySuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sgGetListConnHistory({ payload }) {
    yield callLoading(function* doRQ() {
        const { status, data } = yield call(apiGetList, payload);

        if (status === STATUS_CODES.SUCCESS) {
            yield put(agencyActions.doGetListConnHistorySuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* agencySaga() {
    yield takeLatest(agencyActions.doFetchDataFirst.type, sagaFetchDataFirst);
    yield takeLatest(agencyActions.doGetListAgency.type, sgGetListAgency);
    yield takeLatest(
        agencyActions.doGetListSchOfAgency.type,
        sgGetListSchOfAgency
    );
    yield takeLatest(
        agencyActions.doGetListTransHistory.type,
        sgGetListTransHistory
    );
    yield takeLatest(
        agencyActions.doGetListConnHistory.type,
        sgGetListConnHistory
    );
}

export default agencySaga;
