import axiosService from '../../services/axiosService';
import { API_SCHOOL_ENDPOINT } from '../../constants/api';

const url = 'v1/schoolYear';

const BASE_URL = `${API_SCHOOL_ENDPOINT}/v1/schoolYear`;
const LIST_URL = {
    GET_ALL: `${BASE_URL}/GetAll`
};

export const getList = () => axiosService.get(`${API_SCHOOL_ENDPOINT}/${url}`);

export const getAll = () => axiosService.get(`${LIST_URL.GET_ALL}`);
