import React from 'react';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

const AgencyFilter = ({ onSearch, onChangeKeyword }) => (
    <div className="row">
        <div className="col-md-6 col-xs-8">
            <div className="form-group filter-group">
                <Input
                    onChange={onChangeKeyword}
                    addonBefore="Tìm kiếm"
                    placeholder="Tìm đại lý, người đại diện, email, điện thoại..."
                    prefix={<SearchOutlined />}
                />
            </div>
        </div>

        <div className="col-md-3 col-xs-4">
            <div className="form-group">
                <div className="no-margin">
                    <div className="btn-group">
                        <ButtonFilterAction
                            labelButton="Tìm kiếm"
                            buttonType={BUTTON_ACTION_TYPE.SEARCH}
                            onClick={onSearch}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default AgencyFilter;
