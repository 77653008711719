import axiosService from '../../services/axiosService';
import { API_SC_GATEWAY_ENDPOINT } from '../../constants/api';

const BASE_GATE_WAY = `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/ToolSC/FoodKid/Menu`;

const LIST_URL = {
    GET_LIST_SAMPLE: `${BASE_GATE_WAY}/GetListSample`,
    GET_LIST_BY_DATE_KID_GROUP: `${BASE_GATE_WAY}/GetByDateKidGroup`,
    SAVE: `${BASE_GATE_WAY}/Save`,
    COPY: `${BASE_GATE_WAY}/Copy`,
    IMPORT_FROM_CSV: `${BASE_GATE_WAY}/ImportFromCSV`,
    UPDATE_NUMBER_OF_STUDENT: `${BASE_GATE_WAY}/UpdateNumberOfStudent`,
    UPDATE_SERVICE_PRICE: `${BASE_GATE_WAY}/UpdateServicePrice`,
    UPDATE_NEW_FOOD_PRICE: `${BASE_GATE_WAY}/UpdateNewFoodPrice`,
    ROUNDING_REAL_BUY: `${BASE_GATE_WAY}/RoundingRealBuy`,
};

const _fkMenuApis = {
    /**
     * Lấy thực đơn mẫu
     * @returns
     */
    getListSample({ oId, kidGroupId }) {
        const linkApi = `${LIST_URL.GET_LIST_SAMPLE}/${oId}/${kidGroupId}`;
        return axiosService.get(linkApi);
    },

    /** Lấy thông tin danh sách thực đơn theo ngày và nhóm trẻ */
    getMenuByDateAndKidGroup(dataPost) {
        return axiosService.post(LIST_URL.GET_LIST_BY_DATE_KID_GROUP, dataPost);
    },

    /**
     * Lưu thực đơn
     */
    save(dataPost) {
        return axiosService.post(LIST_URL.SAVE, dataPost);
    },

    /** Sao chép thực đơn */
    copy(dataPost) {
        return axiosService.post(LIST_URL.COPY, dataPost);
    },

    /** Nhập thực đơn từ file CSV */
    importFromCSV(dataPost) {
        return axiosService.post(LIST_URL.IMPORT_FROM_CSV, dataPost);
    },

    /**
     * Cập nhật sĩ số cho thực đơn
     */
    updateNumberOfStudent(dataPost) {
        return axiosService.post(LIST_URL.UPDATE_NUMBER_OF_STUDENT, dataPost);
    },

    /**
     * Cập nhật giá tiền dịch vụ
     */
    updateServicePrice(dataPost) {
        return axiosService.post(LIST_URL.UPDATE_SERVICE_PRICE, dataPost);
    },

    /**
     * Cập nhật giá tiền thực phẩm mới nhất
     */
    updateNewFoodPrice(dataPost) {
        return axiosService.post(LIST_URL.UPDATE_NEW_FOOD_PRICE, dataPost);
    },

    /**
     * Làm tròn thực mua
     */
    roundingRealBuy(dataPost) {
        return axiosService.post(LIST_URL.ROUNDING_REAL_BUY, dataPost);
    },
};

export default _fkMenuApis;
