import axiosService from '../../services/axiosService';
import { API_GLOBALDATA_ENDPOINT } from '../../constants/api';

const BASE_URL = `${API_GLOBALDATA_ENDPOINT}/v1/ScFoodkidGlobalData/AgeGroup`;

const LIST_URL = {
    GET_LIST: `${BASE_URL}/GetList`,
};

const _ageGroupApis = {
    getList() {
        return axiosService.get(LIST_URL.GET_LIST);
    },
};

export default _ageGroupApis;
