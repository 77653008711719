import React, { useState, Fragment, useEffect } from 'react';
import './index.scss';
import { numberToPrice } from '../../../../../utils/utils';

const RenderPriceFieldV2 = ({
    input,
    label,
    type,
    value,
    readOnly,
    isFocus,
    max,
    hasLabel = true,
    meta: { touched, error, warning },
    ...custome
}) => {
    const [valueDisplay, setValueDispay] = useState(0);

    const [checkEdit, setCheckEdit] = useState(false);

    useEffect(() => {
        if (input.value) {
            const number = input.value.toString().replace(/,/g, '');
            if (max) {
                if (max !== '0') {
                    if (parseFloat(number) >= parseFloat(max)) {
                        setValueDispay(numberToPrice(max));
                        input.onChange(parseFloat(max));
                        setCheckEdit(true);
                    }
                }
                if (max === '0') {
                    if (
                        checkEdit &&
                        parseFloat(number) > 0 &&
                        parseFloat(max) === 0
                    ) {
                        setValueDispay(numberToPrice(max));
                        input.onChange(parseFloat(max));
                    }
                }
            } else if (!max) {
                setValueDispay(numberToPrice(input.value));
                input.onChange(parseFloat(number));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input.value, max]);
    const handleFocus = (e) => {
        if (isFocus) {
            e.target.select();
        }
    };
    const onChange = (s) => {
        const data = s.target.value;
        const number = data.replace(/,/g, '');
        if (max && number >= max) {
            setValueDispay(numberToPrice(max));
        } else if (max && max === 0) {
            setValueDispay(numberToPrice(0));
        } else {
            setValueDispay(numberToPrice(data));
        }
    };
    return (
        <Fragment>
            {hasLabel ? (
                <label>
                    <h6 className="panel-title">{label}</h6>
                </label>
            ) : null}

            <div className="contain-error">
                <input
                    {...input}
                    {...custome}
                    value={valueDisplay}
                    onFocus={handleFocus}
                    type={type}
                    readOnly={readOnly}
                    onChange={(e) => onChange(e)}
                    style={{ textAlign: 'right' }}
                />
                {touched &&
                    ((error && <small>{error}</small>) ||
                        (warning && <small>{warning}</small>))}
            </div>
        </Fragment>
    );
};

export default RenderPriceFieldV2;
