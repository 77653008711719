/* eslint-disable eqeqeq */
import { RECEIPT_VOUCHER_TYPE } from '@app/constants/receiptCost.CONST';
import _ from 'lodash';
import { formatDateUnix } from '@app/utils/datetime.utils';
import { numberToPrice } from '@app/utils/utils';

/**
 * Sổ thu chi tiền ăn
 */
export const _incomeMoneyForMealCashBookReportUtils = {
    getTableData(dataSource) {
        const { reportTotal, listData, startDate } = dataSource;
        const { totalRevenue, totalCost } = reportTotal;
        let { openingBalance } = reportTotal;

        const openingData = {
            ngayLap: startDate,
            maPhieuThu: '',
            maPhieuChi: '',
            dienGiai: 'Tồn đầu kỳ',
            tienThu: numberToPrice(totalRevenue),
            tienChi: numberToPrice(totalCost),
            tienTon: numberToPrice(openingBalance),
            key: -1,
        };
        let tableData = _.orderBy(listData, ['i_datefounded'], ['asc']).map(
            (item) => {
                let dataItem = {
                    key: `${item.i_id}`,
                    ngayLap: formatDateUnix(item.i_datefounded, 'DD/MM/YYYY'),
                    dienGiai: item.i_description,
                };
                if (item.i_type === RECEIPT_VOUCHER_TYPE.PHIEU_THU) {
                    openingBalance += item.rd_total;
                    dataItem = {
                        ...dataItem,
                        maPhieuThu: item.i_referencecode,
                        tienThu: numberToPrice(item.rd_total),
                        tienTon: numberToPrice(openingBalance),
                    };
                } else {
                    openingBalance -= item.rd_total;
                    dataItem = {
                        ...dataItem,
                        maPhieuChi: item.i_code,
                        tienChi: numberToPrice(item.rd_total),
                        tienTon: numberToPrice(openingBalance),
                    };
                }
                return dataItem;
            }
        );
        tableData = [openingData, ...tableData];

        return tableData;
    },
};
