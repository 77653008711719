import myIcon from '@app/assets/images/icons/stats.svg';
import General from '@app/components/General';
import React from 'react';
import { ADMIN_FUNCTIONS, SC_MODULES } from '@app/constants/sc.modules';

/**
 * Thư viện
 */
const generalRoutes = [
    {
        name: 'IN BÁO CÁO',
        icon: myIcon,
        isMenu: true,
        isRoute: true,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        link: '/general',
        path: '/general',
        main: () => <General />,
        functionId: ADMIN_FUNCTIONS.GENERAL_LIST,
        // children: [
        //   {
        //     name: "menu.general",
        //     link: "/general",
        //     path: "/general",
        //     isMenu: true,
        //     isRoute: true,
        //     exact: false,
        //     functionId: ADMIN_FUNCTIONS.DASHBOAD_MAIN,
        //     main: () => <DashBoard />,
        //   },
        // ],
    },
];

export default generalRoutes;
