import axiosService from '@app/services/axiosService';
import {
    API_STUDENT_ENDPOINT,
    API_SC_GATEWAY_ENDPOINT,
} from '@app/constants/api';
import { ApiGatewayDataPost } from '@app/models/common/apiGatewayDataPost.model';

const BASE_URL = `${API_STUDENT_ENDPOINT}/v1/BcnStudent/StudentClass`;

const LIST_URL = {
    GET_BY_YEAR: `${BASE_URL}/GetByYear`,
    TRANSIT_STATION: `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/TransitStation`,
};

/**
 * Lấy danh sách học sinh lớp học kèm theo tên theo mã năm học
 * @param {object} dataPost: dữ liệu truyền lên
 * @param {number} dataPost.sy_id: Id năm học
 * @param {string} dataPost.o_id: Id mã tổ chức
 * @returns Danh sách học sinh lớp học
 */
export const apiGetByYear = (dataPost) => {
    const _dataPost = new ApiGatewayDataPost({
        service: 'ScStudent',
        controller: 'StudentClass',
        action: 'GetByYear',
        dataPost: {
            ...dataPost,
            orgId: dataPost.o_id,
        },
    });
    return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    //return axiosService.post(LIST_URL.GET_BY_YEAR, dataPost);
};
