import { PropertySettingModel } from '@app/models/settingSchool/propertySetting.model';
import { createSlice } from '@reduxjs/toolkit';

const MODULE_NAME = 'settingSchool';

const initialState = {
    schoolProperty: [],
    schoolPropertySetting: [],

    saveCount: 0,
};

const SettingSchoolSlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        doGetPropertySetting() {},
        doGetPropertySettingSuccess(state, { payload }) {
            state.schoolPropertySetting = payload;
        },

        doGetSchoolProperty() {},
        doGetSchoolPropertySucess(state, { payload }) {
            state.schoolProperty = payload;
        },

        doChangeValuePropertySetting(state, { payload }) {
            const finalData = state.schoolPropertySetting.map((item) => {
                if (item.ps_code === payload.code) {
                    item = new PropertySettingModel({
                        ...item,
                        ps_value: payload.value,
                        isEdit: true,
                    });
                }
                return item;
            });

            state.schoolPropertySetting = finalData;
        },

        doSavePropertySetting() {},
        doSavePropertySettingSuccess(state) {
            state.saveCount += 1;
        },
    },
});

/** ===== ACTION ==== **/
export const settingSchoolActions = SettingSchoolSlice.actions;

/** ===== SELECTOR ==== **/
export const settingSchoolSelector = {
    schoolProperty: (state) => state[MODULE_NAME].schoolProperty,
    schoolPropertySetting: (state) => state[MODULE_NAME].schoolPropertySetting,

    saveCount: (state) => state[MODULE_NAME].saveCount,
};

/** ===== REDUCER ==== **/
const settingSchoolReducer = SettingSchoolSlice.reducer;
export default settingSchoolReducer;
