import React, { Fragment } from 'react';

const OrganizationList = (props) => (
    <Fragment>
        <div className="row">
            <div className="col-lg-12">
                {/* Basic panel controls */}
                <div className="panel panel-white">
                    <table className="table table-togglable table-hover table-bordered table-striped datatable-pagination default footable-loaded footable">
                        <thead className="grey-light">
                            <tr>
                                <th
                                    className="text-center"
                                    style={{ width: '35px' }}
                                >
                                    STT
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '40%' }}
                                >
                                    Tên đơn vị
                                    <span className="display-block text-muted">
                                        Địa chỉ
                                    </span>
                                </th>

                                <th className="text-center">
                                    Người đại diện
                                    <span className="display-block text-muted">
                                        Chức vụ
                                    </span>
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: 150 }}
                                >
                                    Email
                                    <span className="display-block text-muted">
                                        Điện thoại
                                    </span>
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '6%' }}
                                    data-hidden="phone"
                                >
                                    Tình trạng
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: 180 }}
                                >
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>{props.children}</tbody>
                    </table>
                </div>
                {/* /basic panel controls */}
            </div>
        </div>
    </Fragment>
);

export default OrganizationList;
