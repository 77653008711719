/* eslint-disable no-confusing-arrow */
/* eslint-disable no-use-before-define */
import numeral from 'numeral';
import { decimalToPrice, numberToPrice } from './utils';

export const dishMealData = [
    { id: 1, name: 'Bữa sáng' },
    { id: 2, name: 'Bữa trưa' },
    { id: 3, name: 'Bữa xế' },
    { id: 4, name: 'Bữa phụ chiều' },
];

export const wareHouseData = [
    { id: 0, name: 'Đi chợ' },
    { id: 1, name: 'Xuất kho' },
];

export const dishRegionData = [
    { id: '1', name: 'Miền Bắc' },
    { id: '2', name: 'Miền Trung' },
    { id: '3', name: 'Miền Nam' },
    { id: '4', name: 'Tây Nguyên' },
];

export const dishTypeData = [
    { id: '1', name: 'Bình thường' },
    { id: '2', name: 'Suy dinh dưỡng' },
    { id: '3', name: 'Dư cân béo phì' },
    { id: '4', name: 'Dị ứng thực phẩm' },
    { id: '5', name: 'Chăm sóc đặc biệt' },
];

export const checkColor = (checkMenu) => {
    if (checkMenu === 1) {
        return 'label label-default';
    }
    if (checkMenu === 2) {
        return 'label label-warning';
    }
    if (checkMenu === 3) {
        return 'label label-info';
    }
    if (checkMenu === 4) {
        return 'label label-success';
    }
    if (checkMenu === 5) {
        return 'label label-primary';
    }
    return '';
};

export const checkText = (checkMenu) => {
    if (checkMenu === 1) {
        return 'Tạo thực đơn';
    }
    if (checkMenu === 2) {
        return 'Lập phiếu kê chợ';
    }
    if (checkMenu === 3) {
        return 'Lập phiếu tiếp phẩm';
    }
    if (checkMenu === 4) {
        return 'Tạo sổ thực ăn';
    }
    if (checkMenu === 5) {
        return 'Sổ tính tiền chợ';
    }
    return '';
};

export const calori = (item, type) =>
    type === 0 ? item.msf_quantitybefore / 100 : item.msf_quantityafter / 100;

export const priceBuyFood = (productFoodList, order) =>
    productFoodList.length > 0
        ? productFoodList
              .reduce(
                  (a, b) =>
                      a + order === 0
                          ? b.msf_pricefoodbefore
                          : b.msf_pricefoodafter,
                  0
              )
              .toFixed(2)
        : 0;

export const extraMoneyByChild = (productFoodList, serviceList, editItem) =>
    editItem.m_price -
    (productFoodList.reduce(
        (a, b) => a + parseFloat(b.msf_pricefoodbefore),
        0
    ) +
        serviceList.reduce((a, b) => a + b.mms_price, 0));

export const extraMoneyByChildAfter = (
    productFoodList,
    serviceList,
    editItem
) =>
    (productFoodList.length > 0
        ? editItem.m_price -
          (productFoodList.reduce(
              (a, b) => a + parseFloat(b.msf_pricefoodafter),
              0
          ) +
              serviceList.reduce((a, b) => a + b.mms_price, 0))
        : editItem.m_price
    ).toFixed(2);

export const extraMoney = (productFoodList, serviceList, editItem, order) => {
    const quantity =
        order !== 0 && editItem.m_quantityafter !== 0
            ? editItem.m_quantityafter
            : editItem.m_quantitybefore;
    return (
        editItem.m_price * quantity -
        (productFoodList.reduce(
            (a, b) =>
                a +
                parseFloat(
                    b[
                        `msf_pricefood${
                            order !== 0 && editItem.m_quantityafter !== 0
                                ? 'after'
                                : 'before'
                        }`
                    ]
                ),
            0
        ) *
            quantity +
            serviceList.reduce((a, b) => a + b.mms_price, 0) * quantity)
    );
};

export const getUnitId = (units, sf) => {
    if (sf !== undefined) {
        if (units.find((i) => i.cu_id === sf.cu_id) !== undefined) {
            return units.find((i) => i.cu_id === sf.cu_id).cu_id;
        }
    }
    return 1;
};

export const getUnitWeight = (units, sf) => {
    if (sf !== undefined) {
        if (units.find((i) => i.cu_id === sf.cu_id) !== undefined) {
            return units.find((i) => i.cu_id === sf.cu_id).cu_weight;
        }
    }
    return 1000;
};

export const priceOfChild = (productFoodList) => {
    if (productFoodList.length > 0) {
        return numberToPrice(
            productFoodList.reduce((a, b) => a + b.msf_pricefoodbefore, 0)
        );
    }
    return 0;
};

// sổ tính tiền nợ

export const getPriceService = (items, id) => {
    const ms = items.find((o) => o.kg_id === id);
    if (ms !== undefined) {
        return numberToPrice(ms.mms_price);
    }
    return '';
};

export const getQuantityService = (items, id) => {
    const ms = items.filter((o) => o.kg_id === id);
    if (ms.length > 0) {
        return ms.reduce((sum, x) => sum + x.quantity, 0);
    }
    return '';
};

export const getTotalPriceService = (items, id) => {
    const ms = items.find((o) => o.kg_id === id);
    if (ms !== undefined) {
        return numberToPrice(ms.mms_price * ms.quantity);
    }
    return '';
};

export const getPriceMarket = (items, id) => {
    const sf = items.find((o) => o.kg_id === id);
    if (sf !== undefined) {
        return numberToPrice(sf.msf_priceafter);
    }
    return '';
};

export const getQuantityMarket = (items, id) => {
    const sf = items.filter((o) => o.kg_id === id);
    if (sf.length > 0) {
        return sf.reduce((sum, x) => sum + x.msf_totalquantityafter, 0);
    }
    return '';
};

export const getTotalPriceMarket = (items, id) => {
    const sf = items.find((o) => o.kg_id === id);
    if (sf !== undefined) {
        return numberToPrice(sf.msf_priceafter * sf.msf_totalquantityafter);
    }
    return '';
};

export const getFoodPriceMarket = (items) =>
    numberToPrice(items.reduce((sum, x) => sum + x.msf_priceafter, 0));

export const getFoodQuantityMarket = (items) =>
    items.reduce((sum, x) => sum + x.msf_totalquantityafter, 0).toFixed(2);

export const getFoodTotalPriceMarket = (items, kgs) =>
    numberToPrice(
        kgs.reduce(
            (total, kg) =>
                total +
                parseFloat(
                    items
                        .filter((o) => o.kg_id === kg.kg_id)
                        .reduce(
                            (sum, x) =>
                                sum +
                                x.msf_priceafter * x.msf_totalquantityafter,
                            0
                        )
                        .toFixed(0)
                ),
            0
        )
    );

export const getServicePriceMarket = (items) =>
    numberToPrice(items.reduce((sum, x) => sum + x.quantity * x.mms_price, 0));

export const getServiceQuantityMarket = (items) =>
    items.reduce((sum, x) => sum + x.quantity, 0).toFixed(2);

export const getServiceTotalPriceMarket = (items) =>
    numberToPrice(items.reduce((sum, x) => sum + x.mms_price * x.quantity, 0));

export const getTotalByKidGroup = (items, kg) =>
    numberToPrice(
        items.reduce(
            (sum, x) =>
                sum +
                x.items
                    .filter((o) => o.kg_id === kg.kg_id)
                    .reduce(
                        (child, y) =>
                            child +
                            parseFloat(
                                (
                                    y.msf_priceafter * y.msf_totalquantityafter
                                ).toFixed(0)
                            ),
                        0
                    ),
            0
        )
    );

export const getTotalServiceByKidGroup = (items, id) =>
    numberToPrice(
        items.reduce(
            (sum, x) =>
                sum +
                x.items
                    .filter((o) => o.kg_id === id)
                    .reduce((child, y) => child + y.mms_price * y.quantity, 0),
            0
        )
    );

export const getTotalMarketCashTotal = (items, kgs) =>
    numberToPrice(
        kgs.reduce(
            (total, kg) =>
                total +
                parseFloat(
                    items
                        .reduce(
                            (sum, x) =>
                                sum +
                                x.items
                                    .filter((o) => o.kg_id === kg.kg_id)
                                    .reduce(
                                        (child, y) =>
                                            child +
                                            parseFloat(
                                                (
                                                    y.msf_priceafter *
                                                    y.msf_totalquantityafter
                                                ).toFixed(2)
                                            ),
                                        0
                                    ),
                            0
                        )
                        .toFixed(0)
                ),
            0
        )
    );

export const getTotalMarketCash = (items, kgs) =>
    numberToPrice(
        kgs.reduce(
            (total, kg) =>
                total +
                parseFloat(
                    items
                        .filter((o) => o.kg_id === kg.kg_id)
                        .reduce(
                            (sum, x) =>
                                sum +
                                x.items.reduce(
                                    (child, y) =>
                                        child +
                                        y.msf_priceafter *
                                            y.msf_totalquantityafter,
                                    0
                                ),
                            0
                        )
                        .toFixed(2)
                ),
            0
        )
    );

export const getTotalMarketCashService = (item) =>
    numberToPrice(
        item.reduce(
            (sum, x) =>
                sum +
                x.items.reduce(
                    (child, y) => child + y.mms_price * y.quantity,
                    0
                ),
            0
        )
    );

// Sổ tính tiền chợ: dự chi

export const getPriceMenuByKidGroup = (menus, id) => {
    const m = menus.find((o) => o.kg_id === id);
    if (m !== undefined) {
        return numberToPrice(m.m_price);
    }
    return '';
};

export const getQuantityBeforeMenuByKidGroup = (menus, id) => {
    const m = menus.find((o) => o.kg_id === id);
    if (m !== undefined) {
        return m.m_quantityafter;
    }
    return '';
};

export const getPriceBeforeMenuByKidGroup = (menus, id) => {
    const m = menus.find((o) => o.kg_id === id);
    if (m !== undefined) {
        return numberToPrice(m.m_price * m.m_quantityafter);
    }
    return '';
};

// Sổ tính tiền chợ: đã chi

export const getPriceSpentMenuByKidGroup = (menus, id) => {
    const m = menus.find((o) => o.kg_id === id);
    if (m !== undefined) {
        return numberToPrice(m.m_priceafter);
    }
    return '';
};

export const getQuantityAfterMenuByKidGroup = (menus, id) => {
    const m = menus.find((o) => o.kg_id === id);
    if (m !== undefined) {
        return m.m_quantityafter;
    }
    return '';
};

export const getPriceAfterMenuByKidGroup = (menus, id, difference) => {
    const m = menus.find((o) => o.kg_id === id);
    const d =
        difference !== undefined ? difference.find((o) => o.kg_id === id) : 0;
    const price = d !== undefined ? d.price : 0;
    if (m !== undefined) {
        return numberToPrice(m.m_price * m.m_quantityafter + price);
    }
    return '';
};

export const getTotalQuantityBefore = (menus) =>
    menus.reduce((sum, x) => sum + x.m_quantitybefore, 0);

export const getTotalQuantityAfter = (menus) =>
    numberToPrice(menus.reduce((sum, x) => sum + x.m_quantityafter, 0));

export const getPriceBefore = (menus) =>
    numberToPrice(
        menus.reduce((sum, x) => sum + x.m_price * x.m_quantitybefore, 0)
    );

export const getPriceAfter = (menus) =>
    numberToPrice(
        menus.reduce((sum, x) => sum + x.m_price * x.m_quantityafter, 0)
    );

export const allowedToSpend = (menus, difference) =>
    numberToPrice(
        menus.reduce(
            (sum, x) =>
                sum + parseFloat((x.m_price * x.m_quantityafter).toFixed(0)),
            0
        ) + difference.reduce((sum, x) => sum + x.price, 0)
    );

export const getTotalPriceBefore = (menus) =>
    numberToPrice(
        menus.reduce(
            (sum, x) => sum + (x.m_totalfoodprice + x.m_totalserviceprice),
            0
        )
    );

export const getTotalPriceAfter = (menus) =>
    numberToPrice(
        menus.reduce((sum, x) => sum + x.m_priceafter * x.m_quantityafter, 0)
    );

export const getTotalPriceMenuByKidGroup = (menus, id) => {
    const m = menus.find((o) => o.kg_id === id);
    if (m !== undefined) {
        return numberToPrice(m.m_totalfoodprice + m.m_totalserviceprice);
    }
    return '';
};

export const getDifferencePriceByDay = (difference) =>
    numberToPrice(difference.reduce((sum, x) => sum + x.price, 0));

export const getDifferencePriceMenuByKidGroupAndDay = (difference, id) => {
    const m = difference.find((o) => o.kg_id === id);
    if (m !== undefined) {
        return numberToPrice(m.price);
    }
    return '0';
};

export const getReportQuantityByKidGroup = (report, id) => {
    const r = report.find((o) => o.kg_id === id);
    if (r !== undefined) {
        return numberToPrice(r.quantity);
    }
    return '';
};

export const getReportPriceBeforeByKidGroup = (report, difference, id) => {
    const r = report.find((o) => o.kg_id === id);
    if (r !== undefined) {
        const m = difference.find((o) => o.kg_id === id);
        if (m !== undefined) {
            return numberToPrice(r.pricebefore);
        }
        return numberToPrice(r.pricebefore);
    }
    return '';
};

export const getReportPriceAfterByKidGroup = (report, services, id) => {
    const r = report.find((o) => o.kg_id === id);
    if (r !== undefined) {
        return numberToPrice(
            Math.floor(
                Math.floor(
                    _.sumBy(
                        services.filter((i) => i.kg_id === id),
                        (x) => Math.floor(x.mms_price * x.quantity)
                    )
                ) +
                    Math.floor(r.inwarehouse) +
                    Math.floor(r.notinwarehouse)
            )
        );
    }
    return '';
};

export const getReportPriceServiceByKidGroup = (report, id) => {
    const r = report.find((o) => o.kg_id === id);
    if (r !== undefined) {
        return numberToPrice(Math.floor(r.mms_price));
    }
    return '';
};

export const getReportInwarehouseByKidGroup = (report, id) => {
    const r = report.find((o) => o.kg_id === id);
    if (r !== undefined) {
        return numberToPrice(Math.floor(r.inwarehouse));
    }
    return '';
};

export const getReportNotInwarehouseByKidGroup = (report, id) => {
    const r = report.find((o) => o.kg_id === id);
    if (r !== undefined) {
        return numberToPrice(Math.floor(r.notinwarehouse));
    }
    return '';
};

export const getReportSurplusByKidGroup = (report, difference, id) => {
    const r = report.find((o) => o.kg_id === id);
    if (r !== undefined) {
        const m = difference.find((o) => o.kg_id === id);
        if (m !== undefined) {
            return numberToPrice(
                r.pricebefore -
                    (r.notinwarehouse + r.inwarehouse + r.mms_price) +
                    m.price
            );
        }
        return numberToPrice(
            r.pricebefore - (r.notinwarehouse + r.inwarehouse + r.mms_price)
        );
    }
    return '';
};

export const getReportSurplus = (report, difference) => {
    let total = 0;
    total =
        report.reduce(
            (sum, x) =>
                sum +
                x.pricebefore -
                (x.notinwarehouse + x.inwarehouse + x.mms_price),
            0
        ) + difference.reduce((sum, x) => sum + x.price, 0);
    return numberToPrice(total);
};

// tổng hợp sổ tính tiền chợ

export const generalStartDay = (list, index, difference) => {
    const price_start = difference ? difference.price : 0;
    let i;
    let total = 0;
    if (index === 0) {
        total = price_start;
    } else {
        for (i = 0; i <= index; i++) {
            if (i > 0) {
                total =
                    total +
                    list[i - 1].price -
                    (list[i - 1].notinwarehouse +
                        list[i - 1].inwarehouse +
                        list[i - 1].serviceprice);
            }
        }
        total += price_start;
    }

    return numberToPrice(total);
};

export const generalEndDay = (list, index, difference) => {
    const price_start = difference ? difference.price : 0;
    let i;
    let total = 0;
    for (i = 0; i <= index; i++) {
        // const beforeDay =
        //     i > 0 ? list[i - 1].price - list[i - 1].priceafter : 0;

        total +=
            list[i].price -
            (list[i].notinwarehouse +
                list[i].inwarehouse +
                list[i].serviceprice);
    }
    total += price_start;
    return numberToPrice(total);
};

export const getAccumulatedQuantity = (list, index) => {
    let i;
    let total = 0;
    for (i = 0; i <= index; i++) {
        total += list[i].quantity;
    }
    return numberToPrice(total);
};

export const getAccumulatedPrice = (list, index) => {
    let i;
    let total = 0;
    for (i = 0; i <= index; i++) {
        total += list[i].price;
    }
    return numberToPrice(total);
};

export const getAccumulatedService = (list, index) => {
    let i;
    let total = 0;
    for (i = 0; i <= index; i++) {
        total += list[i].serviceprice;
    }
    return numberToPrice(total);
};

export const getAccumulatedInwarehouse = (list, index) => {
    let i;
    let total = 0;
    for (i = 0; i <= index; i++) {
        total += list[i].inwarehouse;
    }
    return numberToPrice(total);
};

export const getAccumulatedNotInwarehouse = (list, index) => {
    let i;
    let total = 0;
    for (i = 0; i <= index; i++) {
        total += list[i].notinwarehouse;
    }
    return numberToPrice(total);
};

export const getAccumulatedPriceAfter = (list, index) => {
    let i;
    let total = 0;
    for (i = 0; i <= index; i++) {
        total +=
            list[i].notinwarehouse + list[i].inwarehouse + list[i].serviceprice;
    }
    return numberToPrice(total);
};

// Tỉ lệ đạt

export const totalProtid = (
    productFoodList,
    kidGroupItem,
    ageGroupItem,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const protidv = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_protidv * calori(b, after)),
                0
            )
        );
        const protida = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_protida * calori(b, after)),
                0
            )
        );
        const ratio =
            ((protidv + protida) /
                (kidGroupItem.kg_protidamax + kidGroupItem.kg_protidvmax)) *
            100;
        return decimalToPrice(ratio);
    }
    return 0;
};

export const getTotalProtid = (productFoodList, after = 1) => {
    if (productFoodList.length > 0) {
        const protidv = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_protidv * calori(b, after)),
                0
            )
        );
        const protida = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_protida * calori(b, after)),
                0
            )
        );
        return ((protidv + protida) * 4 * 100).toFixed(2);
    }
    return 0;
};

export const totalLipid = (
    productFoodList,
    kidGroupItem,
    ageGroupItem,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const lipidv = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_lipidv * calori(b, after)),
                0
            )
        );
        const lipida = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_lipida * calori(b, after)),
                0
            )
        );
        const ratio =
            ((lipidv + lipida) /
                (kidGroupItem.kg_lipidamax + kidGroupItem.kg_lipidvmax)) *
            100;
        return decimalToPrice(ratio);
    }
    return 0;
};

export const getTotalLipid = (productFoodList, after = 1) => {
    if (productFoodList.length > 0) {
        const lipidv = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_lipidv * calori(b, after)),
                0
            )
        );
        const lipida = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_lipida * calori(b, after)),
                0
            )
        );
        return ((lipidv + lipida) * 9 * 100).toFixed(2);
    }
    return 0;
};

export const totalGlucid = (
    productFoodList,
    kidGroupItem,
    ageGroupItem,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const glucid = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_glucid * calori(b, after)),
                0
            )
        );
        const ratio = (glucid / kidGroupItem.kg_glucidmax) * 100;
        return decimalToPrice(ratio);
    }
    return 0;
};

export const getTotalGlucid = (productFoodList, after = 1) => {
    if (productFoodList.length > 0) {
        const glucid = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_glucid * calori(b, after)),
                0
            )
        );
        return glucid;
    }
    return 0;
};

export const totalCalo = (
    productFoodList,
    kidGroupItem,
    ageGroupItem,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const calo = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_calo494 * calori(b, after)),
                0
            )
        );
        const ratio = (calo / kidGroupItem.kg_calomax) * 100;
        return decimalToPrice(ratio);
    }
    return 0;
};
export const getCaloTotal = (productFoodList, after = 1) => {
    if (productFoodList.length > 0) {
        const calo = decimalRounding(
            productFoodList
                .reduce(
                    (a, b) =>
                        a + decimalRounding(b.f_calo494 * calori(b, after)),
                    0
                )
                .toFixed(2)
        );
        return calo;
    }
    return 0;
};

// Đánh giá thực đơn

export const ratioProtid = (productFoodList, type, after = 1, rear = '%') => {
    if (productFoodList.length > 0) {
        const protidv = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_protidv * calori(b, after)),
                0
            )
        );
        const protida = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_protida * calori(b, after)),
                0
            )
        );
        const ratio = (
            ((protidv + protida) * 4 * 100) /
            getCaloTotal(productFoodList, after)
        ).toFixed(2);
        if (type === 0) {
            return `${ratio}${rear}`;
        }
        switch (true) {
            case ratio > 20:
                if (type === 1) {
                    return 'Vượt mức';
                }
                return 'text-success';

            case ratio < 13:
                if (type === 1) {
                    return 'Chưa đạt';
                }
                return 'text-orange';

            default:
                if (type === 1) {
                    return 'Đạt';
                }
                return 'text-primary';
        }
    }
    if (type === 0) {
        return `0${rear}`;
    }
    return '';
};

export const ratioLipid = (productFoodList, type, after = 1, rear = '%') => {
    if (productFoodList.length > 0) {
        const lipida = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_lipida * calori(b, after)),
                0
            )
        );
        const lipidv = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_lipidv * calori(b, after)),
                0
            )
        );
        const ratio = (
            ((lipidv + lipida) * 9 * 100) /
            getCaloTotal(productFoodList, after)
        ).toFixed(2);
        if (type === 0) {
            return `${ratio}${rear}`;
        }
        switch (true) {
            case ratio > 35:
                if (type === 1) {
                    return 'Vượt mức';
                }
                return 'text-success';

            case ratio < 25:
                if (type === 1) {
                    return 'Chưa đạt';
                }
                return 'text-orange';

            default:
                if (type === 1) {
                    return 'Đạt';
                }
                return 'text-primary';
        }
    }
    if (type === 0) {
        return `0${rear}`;
    }
    return '';
};

export const ratioGlucid = (productFoodList, type, after = 1, rear = '%') => {
    if (productFoodList.length > 0) {
        const glucid = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_glucid * calori(b, after)),
                0
            )
        );
        const ratio = (
            (glucid * 4 * 100) /
            getCaloTotal(productFoodList, after)
        ).toFixed(2);
        if (type === 0) {
            return `${ratio}${rear}`;
        }
        switch (true) {
            case ratio > 60:
                if (type === 1) {
                    return 'Vượt mức';
                }
                return 'text-success';

            case ratio < 52:
                if (type === 1) {
                    return 'Chưa đạt';
                }
                return 'text-orange';

            default:
                if (type === 1) {
                    return 'Đạt';
                }
                return 'text-primary';
        }
    }
    if (type === 0) {
        return `0${rear}`;
    }
    return '';
};

export const ratioByMeal = (
    productFoodList,
    kidGroupItem,
    meal,
    type,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const calo = decimalRounding(
            productFoodList
                .filter((o) => o.msf_meal === meal)
                .reduce(
                    (a, b) =>
                        a + decimalRounding(b.f_calo494 * calori(b, after)),
                    0
                )
        );
        const ratio = ((calo * 100) / kidGroupItem.kg_calomax).toFixed(2);
        if (type === 0) {
            return calo;
        }
        if (type === 1) {
            return `${ratio}%`;
        }
        switch (true) {
            case ratio > numberMinMax(meal, 0):
                if (type === 2) {
                    return 'Vượt mức';
                }
                return 'text-success';
            case ratio < numberMinMax(meal, 1):
                if (type === 2) {
                    return 'Chưa đạt';
                }
                return 'text-orange';
            default:
                if (type === 2) {
                    return 'Đạt';
                }
                return 'text-primary';
        }
    }
    if (type === 0) {
        return '0%';
    }
    return '';
};

const numberMinMax = (meal, type) => {
    switch (meal) {
        case 1:
            if (type === 0) {
                return 15;
            }
            return 20;

        case 2:
            if (type === 0) {
                return 30;
            }
            return 35;

        case 4:
            if (type === 0) {
                return 15;
            }
            return 25;

        default:
            return 0;
    }
};

// THÀNH PHẦN DINH DƯỠNG

export const caloProtidA = (
    productFoodList,
    kidGroupItem,
    ageGroupItem,
    type,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const calo = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_protida * calori(b, after)),
                0
            )
        );
        if (type === 0) {
            return calo;
        }
        switch (true) {
            case calo >
                (kidGroupItem.kg_protidamax * ageGroupItem.ag_ratiomax) / 100:
                if (type === 2) {
                    return 'icon-arrow-up13 position-left';
                }
                return 'text-success';

            case calo <
                (kidGroupItem.kg_protidamin * ageGroupItem.ag_ratiomin) / 100:
                if (type === 2) {
                    return 'icon-arrow-down132 position-left';
                }
                return 'text-orange';

            default:
                if (type === 2) {
                    return '';
                }
                return 'text-primary';
        }
    }

    return 0;
};

export const caloProtidV = (
    productFoodList,
    kidGroupItem,
    ageGroupItem,
    type,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const calo = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_protidv * calori(b, after)),
                0
            )
        );
        if (type === 0) {
            return calo;
        }
        switch (true) {
            case calo >
                (kidGroupItem.kg_protidvmax * ageGroupItem.ag_ratiomax) / 100:
                if (type === 2) {
                    return 'icon-arrow-up13 position-left';
                }
                return 'text-success';

            case calo <
                (kidGroupItem.kg_protidvmin * ageGroupItem.ag_ratiomin) / 100:
                if (type === 2) {
                    return 'icon-arrow-down132 position-left';
                }
                return 'text-orange';

            default:
                if (type === 2) {
                    return '';
                }
                return 'text-primary';
        }
    }

    return 0;
};

export const caloLipidA = (
    productFoodList,
    kidGroupItem,
    ageGroupItem,
    type,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const calo = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_lipida * calori(b, after)),
                0
            )
        );
        if (type === 0) {
            return calo;
        }
        switch (true) {
            case calo >
                (kidGroupItem.kg_lipidamax * ageGroupItem.ag_ratiomax) / 100:
                if (type === 2) {
                    return 'icon-arrow-up13 position-left';
                }
                return 'text-success';

            case calo <
                (kidGroupItem.kg_lipidamin * ageGroupItem.ag_ratiomin) / 100:
                if (type === 2) {
                    return 'icon-arrow-down132 position-left';
                }
                return 'text-orange';

            default:
                if (type === 2) {
                    return '';
                }
                return 'text-primary';
        }
    }

    return 0;
};

export const caloLipidV = (
    productFoodList,
    kidGroupItem,
    ageGroupItem,
    type,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const calo = productFoodList
            .reduce(
                (a, b) => a + decimalRounding(b.f_lipidv * calori(b, after)),
                0
            )
            .toFixed(2);
        if (type === 0) {
            return calo;
        }
        switch (true) {
            case calo >
                (kidGroupItem.kg_lipidvmax * ageGroupItem.ag_ratiomax) / 100:
                if (type === 2) {
                    return 'icon-arrow-up13 position-left';
                }
                return 'text-success';

            case calo <
                (kidGroupItem.kg_lipidvmin * ageGroupItem.ag_ratiomin) / 100:
                if (type === 2) {
                    return 'icon-arrow-down132 position-left';
                }
                return 'text-orange';

            default:
                if (type === 2) {
                    return '';
                }
                return 'text-primary';
        }
    }

    return 0;
};

export const caloGlucid = (
    productFoodList,
    kidGroupItem,
    ageGroupItem,
    type,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const calo = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_glucid * calori(b, after)),
                0
            )
        );
        if (type === 0) {
            return calo;
        }
        switch (true) {
            case calo >
                (kidGroupItem.kg_glucidmax * ageGroupItem.ag_ratiomax) / 100:
                if (type === 2) {
                    return 'icon-arrow-up13 position-left';
                }
                return 'text-success';

            case calo <
                (kidGroupItem.kg_glucidmin * ageGroupItem.ag_ratiomin) / 100:
                if (type === 2) {
                    return 'icon-arrow-down132 position-left';
                }
                return 'text-orange';

            default:
                if (type === 2) {
                    return '';
                }
                return 'text-primary';
        }
    }

    return 0;
};

export const caloTotal = (
    productFoodList,
    kidGroupItem,
    ageGroupItem,
    type,
    after = 1
) => {
    if (productFoodList.length > 0) {
        const calo = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_calo494 * calori(b, after)),
                0
            )
        );
        if (type === 0) {
            return calo;
        }
        switch (true) {
            case calo >
                (kidGroupItem.kg_calomax * ageGroupItem.ag_ratiomax) / 100:
                if (type === 2) {
                    return 'icon-arrow-up13 position-left';
                }
                return 'text-success';

            case calo <
                (kidGroupItem.kg_calomin * ageGroupItem.ag_ratiomin) / 100:
                if (type === 2) {
                    return 'icon-arrow-down132 position-left';
                }
                return 'text-orange';

            default:
                if (type === 2) {
                    return '';
                }
                return 'text-primary';
        }
    }

    return 0;
};

export const valueReport = (value) => {
    const num = value.toFixed(2);
    if (num >= 0.005) {
        const check = num % 1;
        if (num.charAt(num.length - 1) === '.') {
            return numeral(num).format('0,0.0');
        }
        if (check % 1 === 0) {
            return numeral(num).format('0,0');
        }
        if ((check.toFixed(2) * 10) % 1 === 0) {
            return numeral(num).format('0,0.0');
        }
        return numeral(num).format('0,0.00');
    }
    return '';
};

export const decimalRounding = (number) => {
    const interger = Math.floor(number * 100);
    const n = Math.abs(number * 100);
    const decimal = n - interger;
    if (decimal > 0.5) {
        return (interger + 1) / 100;
    }
    return interger / 100;
};

// 2A

export const mealName = (dishItem) => {
    const meal = dishMealData.find((o) => o.id === dishItem.md_meal);
    if (meal !== undefined) {
        return meal.name;
    }
    return '';
};

export const foodName = (dishItem, foodDishCache) => {
    if (foodDishCache.length > 0) {
        const food = foodDishCache.find((o) => o.d_id === dishItem.d_id);
        if (food !== undefined) {
            return food.d_name;
        }
        return '';
    }
    return '';
};

export const numberOfServing = (dishItem, foodDishCache) => {
    if (foodDishCache.length > 0) {
        const food = foodDishCache.find((o) => o.d_id === dishItem.d_id);
        if (food !== undefined) {
            return food.d_numberofserving;
        }
        return 0;
    }
    return 0;
};

export const prepare = (dishItem, foodDishCache) => {
    if (foodDishCache.length > 0) {
        const food = foodDishCache.find((o) => o.d_id === dishItem.d_id);
        if (food !== undefined) {
            return food.d_prepare;
        }
        return '';
    }
    return '';
};

export const schoolPropertyValueDish = (value, meal) => {
    switch (value) {
        case 'md_step2atimeprepare':
            switch (meal) {
                case 1:
                    return 'gio_so_che_bua_sang_2a';
                case 2:
                    return 'gio_so_che_bua_trua_2a';
                case 3:
                    return 'gio_so_che_bua_chinh_chieu_2a';
                default:
                    return 'gio_so_che_bua_phu_chieu_2a';
            }
        case 'md_step2atimecooking':
            switch (meal) {
                case 1:
                    return 'gio_che_bien_bua_sang_2a';
                case 2:
                    return 'gio_che_bien_bua_trua_2a';
                case 3:
                    return 'gio_che_bien_bua_chinh_chieu_2a';
                default:
                    return 'gio_che_bien_bua_phu_chieu_2a';
            }
        case 'md_step2acookingchef':
            return 'nguoi_tham_gia_che_bien_2a';
        case 'md_step2acookingequipment':
            return 'trang_thiet_bi_dung_cu_2a';
        case 'md_step2acookingarea':
            return 'khu_vuc_che_bien_phu_tro_2a';
        case 'md_step2aqualitytestfailed':
            return 'value_null';
        case 'md_step2aqualitytestsucceed':
            return 'kiem_tra_cam_quan_thuc_an_2a';
        case 'md_step2anote':
            return 'value_null';
        case 'md_step2btimedevidefood':
            switch (meal) {
                case 1:
                    return 'gio_chia_mon_an_xong_bua_sang_2b';
                case 2:
                    return 'gio_chia_mon_an_xong_bua_trua_2b';
                case 3:
                    return 'gio_chia_mon_an_xong_bua_chinh_chieu_2b';
                default:
                    return 'gio_chia_mon_an_xong_bua_phu_chieu_2b';
            }
        case 'md_step2btimeeat':
            switch (meal) {
                case 1:
                    return 'gio_bat_dau_an_bua_sang_2b';
                case 2:
                    return 'gio_bat_dau_an_bua_trua_2b';
                case 3:
                    return 'gio_bat_dau_an_bua_chinh_chieu_2b';
                default:
                    return 'gio_bat_dau_an_bua_phu_chieu_2b';
            }
        case 'md_step2bequipmentdevidefood':
            return 'dung_cu_chia_chua_dung_thuc_an_2b';
        case 'md_step2bqualitytestfailed':
            return 'value_null';
        case 'md_step2bqualitytestsucceed':
            return 'kiem_tra_cam_quan_mon_an_2b';
        case 'md_step2bnote':
            return 'value_null';
        case 'md_step3sampleweight':
            return 'khoi_luong_the_tich_mau_3';
        case 'md_step3foodcontainer':
            return 'dung_cu_chua_mau_thuc_an_luu_3';
        case 'md_step3storagetemperature':
            return 'nhiet_do_bao_quan_mau_3';
        case 'md_step3timegetsample':
            switch (meal) {
                case 1:
                    return 'gio_lay_mau_buoi_sang_3';
                case 2:
                    return 'gio_lay_mau_buoi_trua_3';
                case 3:
                    return 'gio_lay_mau_bua_chinh_chieu_3';
                default:
                    return 'gio_lay_mau_bua_phu_chieu_3';
            }
        case 'md_step3timedestroysample':
            switch (meal) {
                case 1:
                    return 'gio_huy_mau_bua_sang_3';
                case 2:
                    return 'gio_huy_mau_bua_trua_3';
                case 3:
                    return 'gio_huy_mau_bua_chinh_chieu_3';
                default:
                    return 'gio_huy_mau_phu_chieu_3';
            }
        case 'md_step3persongetsample':
            return 'nguoi_luu_mau';
        case 'md_step3persondestroysample':
            return 'nguoi_luu_mau';
        case 'md_step3note':
            return 'value_null';
        default:
            return 'foo';
    }
};

export const textColorMeal = (value) => {
    switch (value) {
        case 1:
            return 'text-primary';
        case 2:
            return 'text-orange';
        case 3:
            return 'text-pink';
        default:
            return 'text-info';
    }
};
