import React, { Fragment, useEffect, useState } from 'react';
import TransactionHistoryFilter from './TransactionHistoryFilter';
import PageHeader from '@app/components/Layout/PageHeader/PageHeader';

import TransactionHistoryList from './TransactionHistoryList';
import TransactionHistoryItem from './TransactionHistoryItem';
import { useDispatch, useSelector } from 'react-redux';
import {
    transactionHistoryActions,
    transactionHistorySelector,
} from '@app/redux/transactionHistory/transactionHistory.slice';
import moment from 'moment';
import NoData from '@app/components/Layout/Shares/NoData';
import _ from 'lodash';
import { getCurrentUser } from '@app/utils/utils';
import Pagination from '@app/components/_Share/Pagination';
import TransactionHistoryModal from './TransactionHistoryModal';
import { apiGetListProduct } from '@app/apis/product/product.api';
import { co } from 'co';
import { apiGetListCalUnit } from '@app/apis/product/calculationUnit.api';

const TransactionHistory = () => {
    /** ========== SELECTOR ========== */
    const listTransactionHistory = useSelector(
        transactionHistorySelector.listTransactionHistory
    );
    const currentInfoDetail = useSelector(
        transactionHistorySelector.currentInfoDetail
    );
    const totalList = useSelector(transactionHistorySelector.totalList);
    const currentPage = useSelector(transactionHistorySelector.currentPage);
    const isShow = useSelector(transactionHistorySelector.isShow);

    /** ========== CURRENT USER ========== */
    const currentUser = getCurrentUser();

    /** ========== STATE ========== */
    const [objDate, setObjDate] = useState({});
    const [idStatus, setIdStatus] = useState(-1);
    const [listProduct, setListProduct] = useState([]);

    /** ========== DISPATCH ========== */
    const dispatch = useDispatch();

    /** ========== SIDE EFFECT ========== */
    useEffect(() => {
        co(function* () {
            const [respProduct, respCalUnit] = yield [
                apiGetListProduct(),
                apiGetListCalUnit(),
            ];

            const { status: statusProduct, data: dataProduct } = respProduct;
            const { status: statusCalUnit, data: dataCalUnit } = respCalUnit;

            if (statusProduct === 200 && statusCalUnit === 200) {
                const mapProduct =
                    dataProduct?.data.length > 0
                        ? dataProduct.data.reduce((map, item) => {
                              const itemCalUnit = dataCalUnit.data.find(
                                  (o) => o.cu_id === item.cu_id
                              );

                              map[item.p_id] = {
                                  ...item,
                                  cu_name: itemCalUnit?.cu_name,
                              };
                              return map;
                          }, [])
                        : [];

                setListProduct(mapProduct);
            }
        });

        return () => {
            dispatch(transactionHistoryActions.doReset());
        };
    }, [dispatch]);

    /** ========== THAY ĐỔI NGÀY ========== */
    const onchangeDate = (data) => {
        const { startDate, endDate } = data;

        const unixSD = moment(startDate).startOf('day').unix();
        const unixED = moment(endDate).endOf('day').unix();

        setObjDate({ fromDate: unixSD, toDate: unixED });
    };

    /** ========== THAY ĐỔI TRẠNG THÁI ========== */
    const onChangeStatus = (data) => {
        setIdStatus(data);
    };

    /** ========== MỞ POPUP CHI TIẾT ĐƠN HÀNG ========== */
    const onOpenDetail = (data) => {
        dispatch(transactionHistoryActions.doOpenHistoryDetail(data));
    };

    /** ========== ĐÓNG POPUP CHI TIẾT ĐƠN HÀNG ========== */
    const onCloseDetail = () => {
        dispatch(transactionHistoryActions.doCloseHistoryDetail());
    };

    /** ========== HIỂN THỊ DỮ LIỆU ========== */
    const showData = () => {
        if (_.isEmpty(listTransactionHistory)) return <NoData colspan={6} />;

        return listTransactionHistory.map((o) => (
            <TransactionHistoryItem
                transactionHistory={o}
                onOpenDetail={onOpenDetail}
            />
        ));
    };

    /** ========== TÌM KIẾM ========== */
    const onSearch = () => {
        const model = {
            o_id: currentUser?.o_id,
            fromDate: objDate.fromDate,
            toDate: objDate.toDate,
            so_paymentstatus: idStatus,
            currentPage: 1,
        };

        dispatch(transactionHistoryActions.doGetList(model));
    };

    /** ========== THAY ĐỔI SỐ TRANG ========== */
    const onChangePage = (page) => {
        dispatch(transactionHistoryActions.doChangePage(page));

        const model = {
            o_id: currentUser?.o_id,
            fromDate: objDate.fromDate,
            toDate: objDate.toDate,
            so_paymentstatus: idStatus,
            currentPage: page,
        };
        dispatch(transactionHistoryActions.doGetList(model));
    };

    return (
        <Fragment>
            <PageHeader pageTitle="Lịch sử giao dịch" />
            <div className="content">
                <TransactionHistoryFilter
                    onSearch={onSearch}
                    onChangeDate={onchangeDate}
                    onChangeStatus={onChangeStatus}
                    statusSelected={idStatus}
                />
                <TransactionHistoryList>{showData()}</TransactionHistoryList>
            </div>

            {isShow ? (
                <TransactionHistoryModal
                    isShow={isShow}
                    onCloseModal={onCloseDetail}
                    currentInfoDetail={currentInfoDetail}
                    listProduct={listProduct}
                />
            ) : null}

            <Pagination
                currentPage={currentPage}
                pageSize={10}
                total={totalList}
                currentCount={listTransactionHistory.length}
                onChange={onChangePage}
            />
        </Fragment>
    );
};

export default TransactionHistory;
