import React from 'react';
import { ADMIN_FUNCTIONS, SC_MODULES } from '@app/constants/sc.modules';
import agencyIcon from '@app/assets/images/icons/sys/business-and-trade_2891415.svg';
import Agency from '@app/components/Agency';

/**
 * Đại lý
 */
const agencyRoutes = [
    {
        name: 'ĐẠI LÝ',
        icon: agencyIcon,
        isMenu: true,
        isRoute: true,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        functionId: ADMIN_FUNCTIONS.AGENCY,
        link: '/agency',
        path: '/agency',
        main: () => <Agency />,
    },
];

export default agencyRoutes;
