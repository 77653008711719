import React from 'react';
import PaymentTypeSelect from './Dynamic';

const PaymentTypeSelectField = ({
    input,
    onChange,
    label,
    disabled,
    hasAllOption,
}) => {
    const { value: inputValue, onChange: inputOnChange } = input;
    const handleChange = (value) => {
        inputOnChange(value);
        if (onChange) {
            onChange(value);
        }
    };
    return (
        <PaymentTypeSelect
            defaultValue={inputValue}
            label={label}
            onChange={(value) => handleChange(value)}
            disabled={disabled}
            hasAllOption={hasAllOption}
        />
    );
};

PaymentTypeSelectField.propTypes = {};

export default PaymentTypeSelectField;
