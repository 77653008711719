import { all } from 'redux-saga/effects';

import otherSaga from '@app/redux/others/saga';

import loginSaga from '@app/redux/login/saga';

import attendanceSaga from '@app/redux/attendance/saga';

import schoolSaga from '@app/redux/school/school.saga';
import warehouseSaga from '@app/redux/warehouse/warehouse.saga';
import inventorySaga from '@app/redux/inventory/inventory.saga';

/** ======== ĐƠN VỊ ======== */
import organizationSaga from '@app/redux/organization/organization.saga';

/** ======== THỰC ĐƠN ======== */
import foodkidSaga from '@app/redux/foodkid/foodkid.saga';
import copyMenuSaga from '@app/redux/copymenu/copymenu.saga';
import menusSaga from '@app/redux/menus/menus.saga';

/** ======== VAI TRÒ ======== */
import accountRoleSaga from '@app/redux/account/accountRole/accountRole.saga';

/** ======== TÀI KHOẢN NGƯỜI DÙNG ======== */
import accountListSaga from '@app/redux/account/accountList/accountList.saga';

/** ======== CẤP QUYỀN SỬ DỤNG ======== */
import accountPrivilegeSaga from '@app/redux/account/accountPrivilege/accountPrivilege.saga';

/** ======== LỊCH SỬ GIAO DỊCH ======== */
import transactionHistorySaga from '@app/redux/transactionHistory/transactionHistory.saga';

/** ======== CẤU HÌNH TRƯỜNG ======== */
import settingSchoolSaga from '@app/redux/settingSchool/settingSchool.saga';

/** ======== ĐẠI LÝ ======== */
import agencySaga from '@app/redux/agency/agency.saga';

/** ======== Phiếu chi ======== */
import paymentVoucherSaga from '@app/redux/income/paymentVoucher/saga';

function* rootSaga() {
    try {
        yield all([
            loginSaga(),
            schoolSaga(),
            foodkidSaga(),
            otherSaga(),
            warehouseSaga(),
            inventorySaga(),
            attendanceSaga(),

            organizationSaga(),

            menusSaga(),
            copyMenuSaga(),

            accountRoleSaga(),
            accountListSaga(),
            accountPrivilegeSaga(),

            transactionHistorySaga(),

            settingSchoolSaga(),
            agencySaga(),

            /**==============
             * Phiếu chi
             ==============*/
            paymentVoucherSaga(),
        ]);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.trace(err);
    }
}

export default rootSaga;
