import { apiGetFuncCategoryByModule } from '@app/apis/accounts/accountFunctionCategory';
import { STATUS_CODES } from '@app/constants/api';
import { nonAccentVietnamese } from '@app/utils/utils';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

const { Option } = Select;

const AccountRoleSelect = ({
    moduleCode,
    isAll,
    label,
    defaultValue,
    onChange,
    disabled,
}) => {
    const IS_ALL = isAll || false;

    const [list, setList] = useState([]);
    const [selectId, setSelectId] = useState('');

    useEffect(() => {
        apiGetFuncCategoryByModule(moduleCode).then(({ status, data }) => {
            if (status === STATUS_CODES.SUCCESS) {
                let dataRes = data.data || [];
                let id = '';

                if (dataRes.length > 0) {
                    dataRes = IS_ALL
                        ? [{ sfc_id: -1, sfc_name: 'Tất cả' }, ...dataRes]
                        : dataRes;

                    if (defaultValue) {
                        const check = dataRes.find(
                            (item) => item.sr_id === defaultValue
                        );
                        if (check) {
                            id = defaultValue;
                        } else {
                            id = dataRes[0].sfc_id;
                        }
                    } else {
                        id = dataRes[0].sfc_id;
                    }
                }

                setList(dataRes);
                setSelectId(id);
                onChange?.(id);
            } else {
                setList([]);
                setSelectId('');
            }
        });

        return () => {
            setList([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleCode]);

    useEffect(() => {
        if (defaultValue) {
            setSelectId(defaultValue);
        } else if (list && list.length > 0) {
            setSelectId(list[0].sfc_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const onSearch = (input, option) => {
        if (option) {
            return (
                nonAccentVietnamese(option.children.replace(/\s/g, ''))
                    .toLowerCase()
                    .indexOf(
                        nonAccentVietnamese(
                            input.replace(/\s/g, '')
                        ).toLowerCase()
                    ) >= 0
            );
        }
        return false;
    };

    const showOptionItem = (_list) => {
        const xhtml = [];
        if (_list.length > 0) {
            for (let i = 0; i < _list.length; i++) {
                xhtml.push(
                    <Option value={_list[i].sfc_id} key={_list[i].ssfc_idr_id}>
                        {_list[i].sfc_name}
                    </Option>
                );
            }
        }
        return xhtml;
    };

    const handleChange = (value) => {
        setSelectId(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Fragment>
            {label ? (
                <Fragment>
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                    <div className="clearfix mb20" />
                </Fragment>
            ) : null}
            <Select
                value={selectId}
                optionFilterProp="children"
                filterOption={(input, option) => onSearch(input, option)}
                disabled={disabled}
                className="disable-modified"
                onChange={handleChange}
                placeholder="Chưa có dữ liệu"
            >
                {showOptionItem(list)}
            </Select>
        </Fragment>
    );
};

AccountRoleSelect.propTypes = {
    label: PropTypes.string,
    defaultValue: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default AccountRoleSelect;
