export const DB_CACHE_CONST = {
    DB_NAME: 'BCN_ACCOUNTANT_LOCAL_DATA',
    DB_VERSION: 2,
    STORES: {
        RESOURCES: 'BCNAccountantCompanyResources',
    },
    TABlES: {
        GLOBAL_LOCATION: 'global_location',
        SCHOOL_YEAR: 'sch_school_year',
        SC_INVENTORY: 'sc_inventory',
        GRADE_LEVEL: 'sch_grade_level',
        CALCULATION_UNIT: 'p_calculation_unit',
        PRODUCT: 'p_product',
        PRODUCT_CATEGORY: 'p_product_category',
        GLOBAL_ETHNIC: 'global_ethnic',
        ACCOUNT_FUNCTION: 'acc_function',
        ORGANIZATION: 'organization',
        ORGANIZATION_CATEGORY: 'organization_category',
        ORGANIZATION_TYPE: 'organization_type',
        ORGANIZATION_SCALE: 'organization_scale',
    },
};
