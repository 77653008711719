import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const MODULE_NAME = 'menus';

const initialState = {
    menuList: [],
    serviceList: [],
    dishList: [],
    menuSchoolFoodList: [],
    schoolFoodList: [],
    proFoodList: [],

    isCheckAll: false,
    isShowExport: false,
};

const MenusSlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        /** FETCH DATA */
        doGetListMenu() {},
        doGetListMenuSuccess(state, { payload }) {
            const {
                data,
                menudishs,
                menuServices,
                menuSchoolFoods,
                schoolFoods,
                proFoods,
            } = payload;

            const renderMenuDish = (list, type) => {
                let result = '';

                if (_.isEmpty(list)) return result;

                result = [
                    ...list
                        .filter((o) => o.md_meal === type)
                        .map((o) => o.d_name),
                ].join(', ');

                return result;
            };

            const newList = data.map((mn) => {
                const _dish = menudishs.filter((o) => o.m_id === mn.m_id);

                const m_meal = [];

                if (renderMenuDish(_dish, 1)) {
                    m_meal.push(renderMenuDish(_dish, 1));
                }
                if (renderMenuDish(_dish, 2)) {
                    m_meal.push(renderMenuDish(_dish, 2));
                }
                if (renderMenuDish(_dish, 3)) {
                    m_meal.push(renderMenuDish(_dish, 3));
                }
                if (renderMenuDish(_dish, 4)) {
                    m_meal.push(renderMenuDish(_dish, 4));
                }

                return { ...mn, listMeal: m_meal.join(' | '), check: false };
            });

            state.menuList = newList;
            state.dishList = menudishs;
            state.serviceList = menuServices;
            state.menuSchoolFoodList = menuSchoolFoods;
            state.schoolFoodList = schoolFoods;
            state.proFoodList = proFoods;

            state.isCheckAll = false;
        },

        doChangeListMenu(state, { payload }) {
            const newList = state.menuList.map((o) => {
                if (o.m_id === payload.m_id) {
                    o = {
                        ...o,
                        ...payload,
                    };
                }

                return o;
            });

            state.isCheckAll = _.isEmpty(newList.filter((o) => !o.check));
            state.menuList = newList;
        },

        doCheckAll(state, { payload }) {
            const newList = state.menuList.map((o) => ({
                ...o,
                check: payload,
            }));

            state.menuList = newList;
            state.isCheckAll = payload;
        },

        doOpenModalExport(state) {
            state.isShowExport = true;
        },

        doCloseModalExport(state) {
            state.isShowExport = false;
        },

        doDeleteMenu() {},
        doDeleteMenuSuccess(state, { payload }) {
            const newList = state.menuList.map((mn) => {
                if (payload.includes(mn.m_id)) {
                    mn = {
                        ...mn,
                        m_step: 1,
                        check: false,
                    };
                }

                return mn;
            });

            state.menuList = newList;
        },

        doUpdateStatus() {},
        doUpdateStatusSuccess(state, { payload }) {
            const newList = state.menuList.map((mn) => {
                if (payload?.listId?.includes(mn.m_id)) {
                    mn = {
                        ...mn,
                        m_step: 5,
                        check: false,
                    };
                }

                return mn;
            });

            state.menuList = newList;
            state.isCheckAll = false;
        },

        doClearCheckMenu(state) {
            const newList = state.menuList.map((o) => ({ ...o, check: false }));

            state.menuList = newList;
            state.isCheckAll = false;
        },

        doReset(state) {
            state.menuList = [];
            state.dishList = [];
            state.serviceList = [];
            state.menuSchoolFoodList = [];
            state.schoolFoodList = [];
            state.proFoodList = [];
            state.isCheckAll = false;
        },
    },
});

/** ===== ACTION ==== **/
export const menusActions = MenusSlice.actions;

/** ===== SELECTOR ==== **/
export const menusSelector = {
    menuList: (state) => state[MODULE_NAME].menuList,
    dishList: (state) => state[MODULE_NAME].dishList,
    serviceList: (state) => state[MODULE_NAME].serviceList,
    menuSchoolFoodList: (state) => state[MODULE_NAME].menuSchoolFoodList,
    schoolFoodList: (state) => state[MODULE_NAME].schoolFoodList,
    proFoodList: (state) => state[MODULE_NAME].proFoodList,

    isCheckAll: (state) => state[MODULE_NAME].isCheckAll,
    isShowExport: (state) => state[MODULE_NAME].isShowExport,
};

/** ===== REDUCER ==== **/
const menusReducer = MenusSlice.reducer;
export default menusReducer;
