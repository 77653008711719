import { Base64 } from 'js-base64';
import { getCurrentUser } from './utils';

export const getAuth = (moduleId, functionId) => {
    let isAuth = true;

    try {
        if (moduleId && functionId) {
            const userInfo = getCurrentUser();
            if (userInfo.auth) {
                const rightStr = Base64.decode(userInfo.auth);

                const rightList = JSON.parse(rightStr);

                const right = rightList.find((item) => item.m_id === moduleId);

                if (right) {
                    const tempRole = right.rights.find(
                        (item) => item.id === functionId
                    );
                    if (tempRole) {
                        if (
                            tempRole.roles[0] === 1 ||
                            tempRole.roles[2] === 1
                        ) {
                            isAuth = true;
                        }
                    }
                }
            }
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
    return isAuth;
};

export const getRoles = (moduleId, functionId) => {
    let mRole = [];
    try {
        const rightStr = Base64.decode(getCurrentUser().auth);

        const rightList = JSON.parse(rightStr);

        const right = rightList.find((item) => item.m_id === moduleId);

        if (right) {
            const tempRole = right.rights.find(
                (item) => item.id === functionId
            );
            if (tempRole) {
                mRole = tempRole.roles;
            }
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
    return mRole;
};
