const MODULE_NAME = 'organizationReducer';

const OrganizationSelector = {
    organizationList: (state) => state[MODULE_NAME].organizationList,
    organizationScale: (state) => state[MODULE_NAME].organizationScale,
    organizationType: (state) => state[MODULE_NAME].organizationType,

    isShow: (state) => state[MODULE_NAME].isShow,
    currentPage: (state) => state[MODULE_NAME].currentPage,
    total: (state) => state[MODULE_NAME].total,
    keyword: (state) => state[MODULE_NAME].keyword,
    isLoad: (state) => state[MODULE_NAME].isLoad,
    editItem: (state) => state[MODULE_NAME].editItem,
};

export default OrganizationSelector;
