import { priceType } from '@app/utils/utils';
import React, { Fragment } from 'react';

const InventoryList = (props) => (
    <Fragment>
        <div className="row">
            <div className="col-lg-12">
                {/* Basic panel controls */}
                <div className="panel panel-white">
                    <table className="table table-togglable table-hover table-bordered table-striped datatable-pagination default footable-loaded footable">
                        <thead className="grey-light">
                            <tr>
                                <th
                                    className="text-center"
                                    style={{ width: '35px' }}
                                >
                                    <div className="checker">
                                        <span
                                            className={
                                                props.isCheckAll === true
                                                    ? 'checked'
                                                    : ''
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                className="styled"
                                                onChange={() =>
                                                    props.onCheckAll()
                                                }
                                            />
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '8%' }}
                                >
                                    Ngày lập
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '12%' }}
                                >
                                    Số phiếu
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '10%' }}
                                >
                                    Kho hàng
                                </th>
                                {/* <th
                                    className="text-center"
                                    style={{ width: '8%' }}
                                >
                                    Tổng tiền nhập
                                </th> */}
                                <th
                                    className="text-center"
                                    style={{ width: '8%' }}
                                >
                                    Tổng tiền xuất
                                </th>
                                <th className="text-center">Lý do nhập</th>
                                <th
                                    className="text-center"
                                    style={{ width: '10%' }}
                                >
                                    Người lập
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '12%' }}
                                >
                                    Trạng thái
                                </th>
                                {/* <th
                                    className="text-center"
                                    style={{ width: '5%' }}
                                >
                                    Hành động
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {props.children}
                            <tr className="info text-semibold">
                                <td
                                    colSpan="3"
                                    className="text-right text-semibold"
                                >
                                    Tổng cộng
                                </td>
                                <td className="text-right">
                                    {priceType(props.showIndexExportTotal())}
                                </td>
                                {/* <td className="text-right">
                                    {priceType(props.showIndexExportTotal())}
                                </td> */}
                                <td colSpan="5" />
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* /basic panel controls */}
            </div>
        </div>
    </Fragment>
);

export default InventoryList;
