import { apiGetListTransaction } from '@app/apis/transactionHistory/transactionHistory.api';
import { STATUS_CODES } from '@app/constants/api';
import { showError } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import { call, put, takeLatest } from 'redux-saga/effects';
import { transactionHistoryActions } from './transactionHistory.slice';

function* sgGetList({ payload }) {
    yield callLoading(function* doRQ() {
        const { status, data } = yield call(apiGetListTransaction, payload);

        if (status === STATUS_CODES.SUCCESS) {
            yield put(transactionHistoryActions.doGetListSuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* transactionHistorySaga() {
    yield takeLatest(transactionHistoryActions.doGetList.type, sgGetList);
}

export default transactionHistorySaga;
