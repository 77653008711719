import React, { useEffect, useState } from 'react';
import { deleteDbCache } from '@app/services/localDBService/initDB';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from '@app/utils/utils';
import { SCHOOL_SELECT, TOKEN_NAME } from '@app/constants/global.const';
import * as loginActions from '@app/redux/login/actions';
import * as schoolActions from '@app/redux/school/school.action';
import { useDispatch } from 'react-redux';
import avatarDefault from '@app/assets/images/avatar_default.png';
import { _myAccountActs } from '@app/redux/account/myAccount/slice';

const UserHeader = () => {
    const [user, setUser] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        setUser(getCurrentUser());
    }, []);

    const logOut = (e) => {
        e.preventDefault();
        localStorage.setItem(TOKEN_NAME, '');
        localStorage.setItem(SCHOOL_SELECT, '');

        deleteDbCache();
        dispatch(loginActions.updateToken(''));
        history.push('/login');
    };

    const changeSchool = () => {
        dispatch(schoolActions.openSchoolModal());
    };

    const openChangePassPopup = () => {
        dispatch(_myAccountActs.doOpenChangePassPopup(true));
    };

    return (
        <ul className="nav navbar-nav navbar-right">
            <li className="dropdown dropdown-user">
                <a className="dropdown-toggle" data-toggle="dropdown" href="/">
                    <img src={avatarDefault} alt="" />
                    <span>{user?.name}</span>
                    <i className="caret" />
                </a>
                <ul className="dropdown-menu dropdown-menu-right">
                    <li onClick={changeSchool}>
                        <span className="ref-a">
                            <i className="icon-home" /> Chuyển trường
                        </span>
                    </li>
                    {/* <li>
                        <span className="ref-a">
                            <i className="icon-user" /> Thông tin tài khoản
                        </span>
                    </li> */}
                    <li className="divider" />
                    <li onClick={openChangePassPopup}>
                        <span className="ref-a">
                            <i className="icon-key" /> Thay đổi mật khẩu
                        </span>
                    </li>
                    {/* <li>
                        <span className="ref-a">
                            <i className="icon-fan" /> Xóa cache dữ liệu
                        </span>
                    </li> */}
                    <li onClick={logOut}>
                        <span className="ref-a">
                            <i className="icon-switch2" /> Đăng xuất
                        </span>
                    </li>
                </ul>
            </li>
        </ul>
    );
};

UserHeader.propTypes = {};

export default UserHeader;
