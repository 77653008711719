const prefix = 'OTHERS_V2_';
export const SAGA_CONST = {
    GET_ORGANIZATION_INFO: `${prefix}GET_ORGANIZATION_INFO`,
    GET_SCHOOL_INFO: `${prefix}GET_SCHOOL_INFO`,
    GET_LOCATION_INFO: `${prefix}GET_LOCATION_INFO`,
    GET_UNIT: `${prefix}GET_UNIT`,
    GET_AGE_GROUP: `${prefix}GET_AGE_GROUP`,
    GET_KID_GROUP: `${prefix}GET_KID_GROUP`,
    GET_MEAL_SERVICE: `${prefix}GET_MEAL_SERVICE`,
    GET_SUPPLIER: `${prefix}GET_SUPPLIER`,
    GET_DISH: `${prefix}GET_DISH`,
    GET_INVENTORY: `${prefix}GET_INVENTORY`,
    GET_PACKAGING: `${prefix}GET_PACKAGING`,
    GET_ROUTES: `${prefix}GET_ROUTES`,
    GET_SCHOOL_FOOD: `${prefix}GET_SCHOOL_FOOD`,
    GET_SCHOOL_PROPERTY: `${prefix}GET_SCHOOL_PROPERTY`,
    GET_SCHOOL_INVOICE_FORM: `${prefix}GET_SCHOOL_INVOICE_FORM`,
    GET_PRODUCT_FOOD: `${prefix}GET_PRODUCT_FOOD`,
};

/**
 * Lấy thông tin tổ chức
 * @param {string} oId : Id tổ chức
 * @returns => Saga
 */
export const doGetOrganizationInfo = (oId) => ({
    type: SAGA_CONST.GET_ORGANIZATION_INFO,
    payload: {
        oId,
    },
});

/**
 * Lấy thông tin địa điểm (địa chỉ/Thành phố, phường, xã)
 * @param {string} l_code: Mã phường xã
 * @returns => Saga
 */
export const doGetLocationInfo = (l_code) => ({
    type: SAGA_CONST.GET_LOCATION_INFO,
    payload: {
        l_code,
    },
});

export const doGetSchoolInfo = (oId) => ({
    type: SAGA_CONST.GET_SCHOOL_INFO,
    payload: {
        oId,
    },
});

export const doGetUnits = () => ({
    type: SAGA_CONST.GET_UNIT,
});

export const doGetAgeGroup = () => ({
    type: SAGA_CONST.GET_AGE_GROUP,
});

export const doGetKidGroup = (oId) => ({
    type: SAGA_CONST.GET_KID_GROUP,
    payload: {
        oId,
    },
});

export const doGetMealService = (oId) => ({
    type: SAGA_CONST.GET_MEAL_SERVICE,
    payload: {
        oId,
    },
});

export const doGetSupplier = (oId) => ({
    type: SAGA_CONST.GET_SUPPLIER,
    payload: {
        oId,
    },
});

export const doGetFoodkidDish = (oId) => ({
    type: SAGA_CONST.GET_DISH,
    payload: {
        oId,
    },
});

export const doGetInventory = (oId) => ({
    type: SAGA_CONST.GET_INVENTORY,
    payload: {
        oId,
    },
});

export const doGetPackaging = () => ({
    type: SAGA_CONST.GET_PACKAGING,
});

/**
 * Lấy danh sách thực phẩm trường
 * @param {string} oId : Id tổ chức
 * @returns => Saga
 */
export const doGetSchoolFood = (oId) => ({
    type: SAGA_CONST.GET_SCHOOL_FOOD,
    payload: { oId },
});

/**
 * Lấy danh sách thuộc tính thiết lập
 * @returns => Saga
 */
export const doGetSchoolProperty = () => ({
    type: SAGA_CONST.GET_SCHOOL_PROPERTY,
});

/**
 * Lấy danh sách hóa đơn nhà cung cấp
 * @returns => Saga
 */
export const doGetSchoolInvoiceForm = () => ({
    type: SAGA_CONST.GET_SCHOOL_INVOICE_FORM,
});

/**
 * Lấy danh sách thực phẩm
 * @returns => Saga
 */
export const doGetProFood = () => ({
    type: SAGA_CONST.GET_PRODUCT_FOOD,
});
