import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _icCostApis from '@app/apis/income/costList.api';
import BaseSelect from '../../BaseSelect';
import globalUtils from '@app/utils/global.utils';

const propTypes = {
    onChange: PropTypes.func,
};

function DynamicCostSelect({
    onChange,
    label,
    oId,
    defaultValue,
    disabled,
    type,
}) {
    const [selectId, setSelectId] = useState();
    const [list, setList] = useState([]);
    const isFirst = useRef(true);

    const onReset = () => {
        setSelectId('');
        setList([]);
    };

    const getData = async (_oId) => {
        const { status, data } = await _icCostApis.getList(_oId, type);
        if (status === 200) {
            const _listTemp = data.data;

            let id = '';
            if (
                defaultValue &&
                globalUtils.checkValueInList('cl_id', defaultValue, _listTemp)
            ) {
                id = defaultValue;
            } else if (_listTemp.length > 0) {
                id = _listTemp[0].cl_id;
            }
            setList(_listTemp);
            setSelectId(id);
            if (onChange) {
                onChange(id);
            }
        } else {
            onReset();
        }
        isFirst.current = false;
    };

    useEffect(() => {
        if (oId) {
            getData(oId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oId]);

    const handleChange = (value) => {
        setSelectId(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <BaseSelect
            list={list}
            selectId={selectId}
            idField="cl_id"
            nameField="cl_name"
            onChange={handleChange}
            label={label}
            disabled={disabled}
        />
    );
}

DynamicCostSelect.propTypes = propTypes;

export default DynamicCostSelect;
