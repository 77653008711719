import React from 'react';

const ModalListSchoolItem = ({ sortOrder, schOfAgency }) => (
    <tr>
        <td className="text-center">{sortOrder}</td>
        <td>
            <span className="badge badge-info pull-right margin-left-5">
                {schOfAgency?.scaleName || ''}
            </span>
            <span className="badge bg-slate pull-right ">
                {schOfAgency?.typeName || ''}
            </span>
            {schOfAgency?.o_name || ''}
            <div className="display-block text-muted">
                {schOfAgency?.oa_addressline1 || ''}
            </div>
        </td>
        <td>
            <span className={`label ${schOfAgency?.objStatus?.classLabel}`}>
                <i className={schOfAgency?.objStatus?.classIcon} />
                {` ${schOfAgency?.objStatus?.label}`}
            </span>
        </td>
    </tr>
);

export default ModalListSchoolItem;
