import axiosService from '@app/services/axiosService';
import {
    API_INCOME_ENDPOINT,
    API_SC_GATEWAY_ENDPOINT,
} from '@app/constants/api';
import { ApiGatewayDataPost } from '@app/models/common/apiGatewayDataPost.model';

const BASE_URL = `${API_INCOME_ENDPOINT}/v1/Sc/Income/AccountBank`;

const LIST_URL = {
    GET_BY_OID: `${BASE_URL}/GetByOId`,
    TRANSIT_STATION: `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/TransitStation`,
};

/**
 * Lấy danh sách tài khoản ngân hàng theo Id tổ chức
 * @param {string} oId: Id tổ chức
 * @returns
 */
export const apiGetByOId = (oId) => {
    const _dataPost = new ApiGatewayDataPost({
        service: 'Income',
        controller: 'AccountBank',
        action: 'GetListByOId',
        dataPost: {
            orgId: oId,
        },
    });

    return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);

    //return axiosService.get(`${LIST_URL.GET_BY_OID}/${oId}`);
};
