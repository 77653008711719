import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from './copymenu.constant';
// import {
//     //apiGetListMenuSample,
//     //apiGetListKidGroup,
//     //apiSaveMenuSample,
//     apiImportMenuFromFile,
// } from '@app/apis/foodkid/foodkid';

import * as apiConst from '@app/constants/api';
import {
    doGetKidGroupSuccess,
    doGetListMenuBySchoolSuccess,
    doImportMenuFromFileSuccess,
    doSaveMenuSampleSuccess,
} from './copymenu.action';
import { showError, showSuccess } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import { ACTION_API_TYPES } from '@app/constants/global.const';
import _fkMenuApis from '@app/apis/foodkid/menu.api';
import _kidGroupApis from '@app/apis/foodkid/kidGroup.api';

function* sagaGetListMenu({ payload }) {
    function* doRQ() {
        const { data, status } = yield call(
            _fkMenuApis.getListSample,
            //apiGetListMenuSample,
            payload.data
        );

        const dataRes = data.data;

        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(
                doGetListMenuBySchoolSuccess({
                    menu: dataRes.listMenuView,
                    menudishs: dataRes.menuDishs,
                    menuServices: dataRes.menuServices,
                    menuSchoolFoods: dataRes.menuSchoolFoods,
                })
            );
        } else {
            showError(status, data);
        }
    }
    yield callLoading(doRQ);
}

function* sagaGetListKidGroup({ payload }) {
    // const { data, status } = yield call(apiGetListKidGroup, payload.id);
    const { data, status } = yield call(_kidGroupApis.getListByOId, payload.id);

    if (status === apiConst.STATUS_CODES.SUCCESS) {
        yield put(doGetKidGroupSuccess(data.data));
    } else {
        showError(status, data);
    }
}

function* sagaSaveListMenuSample({ payload }) {
    yield callLoading(function* doRQ() {
        const { data, status } = yield call(_fkMenuApis.copy, payload.data);
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(doSaveMenuSampleSuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sagaImportMenuFromFile({ payload }) {
    yield callLoading(function* doRQ() {
        // const { data, status } = yield call(
        //     apiImportMenuFromFile,
        //     payload.data
        // );
        const { data, status } = yield call(
            _fkMenuApis.importFromCSV,
            payload.data
        );
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(doImportMenuFromFileSuccess());
            showSuccess(ACTION_API_TYPES.ADD);
        } else {
            showError(status, data);
        }
    });
}

function* foodkidSaga() {
    yield takeLatest(types.GET_LIST_MENU_BY_SCHOOL, sagaGetListMenu);
    yield takeLatest(types.GET_KIDGROUP, sagaGetListKidGroup);
    yield takeLatest(types.SAVE_MENU_SAMPLE, sagaSaveListMenuSample);
    yield takeLatest(types.IMPORT_MENU_FROM_FILE, sagaImportMenuFromFile);
}

export default foodkidSaga;
