import numeral from 'numeral';

const mangso = [
    'không',
    'một',
    'hai',
    'ba',
    'bốn',
    'năm',
    'sáu',
    'bảy',
    'tám',
    'chín',
];

const dochangchuc = (so, daydu) => {
    let chuoi = '';
    const chuc = Math.floor(so / 10);
    const donvi = so % 10;
    if (chuc > 1) {
        chuoi = ` ${mangso[chuc]} mươi`;
        if (donvi === 1) {
            chuoi += ' mốt';
        }
    } else if (chuc === 1) {
        chuoi = ' mười';
        if (donvi === 1) {
            chuoi += ' một';
        }
    } else if (daydu && donvi > 0) {
        chuoi = ' lẻ';
    }
    if (donvi === 5 && chuc > 1) {
        chuoi += ' lăm ';
    } else if (donvi > 1 || (donvi === 1 && chuc === 0)) {
        chuoi += ` ${mangso[donvi]}`;
    }
    return chuoi;
};

const docblock = (so, daydu) => {
    let chuoi = '';
    const tram = Math.floor(so / 100);
    so %= 100;
    if (daydu || tram > 0) {
        chuoi = ` ${mangso[tram]} trăm`;
        chuoi += dochangchuc(so, true);
    } else {
        chuoi = dochangchuc(so, false);
    }
    return chuoi;
};

const dochangtrieu = (so, daydu) => {
    let chuoi = '';
    const trieu = Math.floor(so / 1000000);
    so %= 1000000;
    if (trieu > 0) {
        chuoi = `${docblock(trieu, daydu)} triệu`;
        daydu = true;
    }
    const nghin = Math.floor(so / 1000);
    so %= 1000;
    if (nghin > 0) {
        chuoi += `${docblock(nghin, daydu)} nghìn`;
        daydu = true;
    }
    if (so > 0) {
        chuoi += docblock(so, daydu);
    }
    return chuoi;
};

class NumberUtils {
    /**
     * Chuyển số sang dạng chữ
     * @param {number} num
     * @returns
     */
    convertNumberToWord = (num) => {
        let chuoi = '';
        let hauto = '';
        let ty = 0;
        if (num === 0) return mangso[0];
        do {
            ty = num % 1000000000;
            num = Math.floor(num / 1000000000);
            if (num > 0) {
                chuoi = dochangtrieu(ty, true) + hauto + chuoi;
            } else {
                chuoi = dochangtrieu(ty, false) + hauto + chuoi;
            }
            hauto = ' tỷ';
        } while (num > 0);
        return chuoi.trim();
    };

    /**
     * Chuyển số sang hiển thị dạng tiền tệ
     * @param {number} num : số cần chuyển
     * @returns
     */
    numberToPrice = (num) => numeral(num).format('0,0');

    roundNumber = (number, decimalPlaces = 2) => {
        if (!number) return 0;
        if (!_.isNumber(number)) return 0;

        /**
         *  Tương đương với cách tính:
         */
        // const p = Math.pow(10, decimalPlaces);
        // return Math.round(number * p) / p;

        return +`${Math.round(
            `${number}e+${decimalPlaces}`
        )}e-${decimalPlaces}`;
    };
}
export default new NumberUtils();
