import axiosService from '@app/services/axiosService';
import { API_ORGANIZATION_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_ORGANIZATION_ENDPOINT}/v1/CustomerOrganization`;

const LIST_URL = {
    GET_ORGANIZATION_INFO: `${BASE_URL}/GetById`,
};

export const apiGetOrganizationInfo = (oId) =>
    axiosService.get(`${LIST_URL.GET_ORGANIZATION_INFO}/${oId}`);
