/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import { GLOBAL } from '@app/constants/global.const';
import { MarketReportModel } from '@app/models/foodkid/foodkid.model';
import _globalUtils from '@app/utils/global.utils';
import reportUtils from '@app/utils/reportUtils';
import { numberToPrice, priceToNumber } from '@app/utils/utils';
// import _ from 'lodash';
import moment from 'moment';

class _supplierExportPDFService {
    /**
     * Merge dữ nhà cung cấp
     * @param {Array} schoolFoods : danh sách thực phẩm trường
     * @param {Array} menuSchoolFoods : danh sách thực phẩm của thực đơn
     * @param {Array} units : danh sách đơn vị tính
     * @param {Array} menulist : danh sách thực đơn
     * @param {object} locationInfo : thông tin địa chỉ của trường
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     * @param {number} dataValue : loại báo cáo dự trù hoặc thực tế
     * @param {String} nameFile: tên prefix file khi xuất ra
     */
    doExport = async (
        schoolFoods,
        menuSchoolFoods,
        units,
        menulist,
        locationInfo,
        schoolInfo,
        organizationInfo,
        dataValue,
        nameFile
    ) => {
        const _nameFile = nameFile || 'PhieuTiepPham';

        const value = dataValue === 1 ? 'before' : 'after';

        const info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            // province: locationInfo.cityName,
            // district: locationInfo.districtName,
            // wards: locationInfo.wardName,
            school: schoolInfo.ten_truong,
            principal: schoolInfo.ho_ten_hieu_truong,
            // assistant: schoolInfo.ho_ten_hieu_pho_chuyen_mon,
            provision: schoolInfo.ho_ten_cap_duong,
            accountant: schoolInfo.ho_ten_ke_toan,
            assistant: schoolInfo?.ho_ten_hieu_pho_ban_tru,
            assistantTitle:
                schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_ASSISTANT_TITLE,
            date: moment.unix(menulist[0].m_datefounded).format('DD-MM-YYYY'),
        };
        const datas = menuSchoolFoods
            .map((item) => {
                const food = new MarketReportModel();
                const sf = schoolFoods.find((o) => o.sf_id === item.sf_id);
                const unit = units.find((o) => o.cu_id === sf.cu_id);
                food.f_name = sf.sf_name;
                food.sf_id = sf.sf_id;
                food.cu_name = unit.cu_name;
                food.quantity = item[`msf_totalquantity${value}`];
                return food;
            })
            .reduce((acc, rec) => {
                const itemsInAcc = acc.find((a) => a.sf_id === rec.sf_id);
                if (itemsInAcc != null) {
                    itemsInAcc.quantity += rec.quantity;
                    itemsInAcc.price_1 = numberToPrice(
                        priceToNumber(itemsInAcc.price_1) +
                            priceToNumber(rec.price_1)
                    );
                    itemsInAcc.price_2 = numberToPrice(
                        priceToNumber(itemsInAcc.price_2) +
                            priceToNumber(rec.price_2)
                    );
                    itemsInAcc.price_3 = numberToPrice(
                        priceToNumber(itemsInAcc.price_3) +
                            priceToNumber(rec.price_3)
                    );
                    itemsInAcc.price_4 = numberToPrice(
                        priceToNumber(itemsInAcc.price_4) +
                            priceToNumber(rec.price_4)
                    );
                    itemsInAcc.sf_quantiry_1 += rec.sf_quantiry_1;
                    itemsInAcc.sf_quantiry_2 += rec.sf_quantiry_2;
                    itemsInAcc.sf_quantiry_3 += rec.sf_quantiry_3;
                    itemsInAcc.sf_quantiry_4 += rec.sf_quantiry_4;
                    itemsInAcc.total_quantity = parseFloat(
                        (
                            Math.round(
                                (itemsInAcc.total_quantity +
                                    rec.total_quantity) *
                                    100
                            ) / 100
                        ).toFixed(2)
                    );
                    itemsInAcc.total_unit_price = numberToPrice(
                        priceToNumber(itemsInAcc.total_unit_price) +
                            priceToNumber(rec.total_unit_price)
                    );
                    itemsInAcc.total_price = numberToPrice(
                        priceToNumber(itemsInAcc.total_price) +
                            priceToNumber(rec.total_price)
                    );
                } else {
                    acc = [...acc, { ...rec }];
                }
                return acc;
            }, [])
            .map((item, i) => {
                item.stt = i + 1;
                item.quantity = item.quantity.toFixed(2);
                return item;
            });
        const res = await exportReportToPDF({
            data: JSON.stringify({ info, datas }),
            report: REPORT_TYPE.PHIEU_TIEP_PHAM,
        });
        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `${_nameFile}_${info.date}_${organizationInfo.o_name}`
            );
        }
        return true;
    };
}

export default new _supplierExportPDFService();
