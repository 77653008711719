import PageHeader from '@app/components/Layout/PageHeader/PageHeader';
import NoData from '@app/components/Layout/Shares/NoData';
import { agencyActions, agencySelector } from '@app/redux/agency/agency.slice';
import React, { Fragment, useEffect, useRef } from 'react';
import AgencyFilter from './AgencyFilter';
import { useDispatch, useSelector } from 'react-redux';
import AgencyItem from './AgencyItem';
import AgencyList from './AgencyList';
import CustomePagination from '@app/components/_Share/Pagination';
import ModalListSchool from './ModalListSchool';
import ModalTransHistory from './ModalTransHistory';
import ModalConnHistory from './ModalConnHistory';

const Agency = () => {
    const dispatch = useDispatch();
    const typingTimeoutRef = useRef(null);

    /** ==========  SELECTOR ==========  */
    const listAgency = useSelector(agencySelector.listAgency);

    const isShowListSchool = useSelector(agencySelector.isShowListSchool);
    const isShowTransHistory = useSelector(agencySelector.isShowTransHistory);
    const isShowConnHistory = useSelector(agencySelector.isShowConnHistory);

    const currentPage = useSelector(agencySelector.currentPage);
    const totalList = useSelector(agencySelector.totalList);
    const keyword = useSelector(agencySelector.keyword);
    /** ==========  END SELECTOR ==========  */

    const modelSearchAgency = (_currentPage) => {
        const model = {
            currentPage: _currentPage,
            keyword,
        };

        return model;
    };

    useEffect(() => {
        dispatch(agencyActions.doFetchDataFirst());
    }, [dispatch]);

    const onChangeSwitch = () => {
        // const status = value ? 1 : 0;
        // dispatch(
        //     agencyActions.doChangeStatusAgency({ ...data, o_status: status })
        // );
    };

    /** ========== START TOGGLE POPUP ==========  */
    const onOpenListSchool = (data) => {
        dispatch(agencyActions.doToggleListSchool({ data, action: 'open' }));
    };

    const onCloseListSchool = (data) => {
        dispatch(agencyActions.doToggleListSchool({ data, action: 'close' }));
    };

    const onOpenTransHistory = (data) => {
        dispatch(agencyActions.doToggleTransHistory({ data, action: 'open' }));
    };

    const onCloseTransHistory = (data) => {
        dispatch(agencyActions.doToggleTransHistory({ data, action: 'close' }));
    };

    const onOpenConnHistory = (data) => {
        dispatch(agencyActions.doToggleConnHistory({ data, action: 'open' }));
    };

    const onCloseConnHistory = (data) => {
        dispatch(agencyActions.doToggleConnHistory({ data, action: 'close' }));
    };
    /** ==========  END TOGGLE POPUP ==========  */

    /** ==========  THAY ĐỔI TỪ KHÓA TÌM KIẾM ==========  */
    const onChangeKeyword = (e) => {
        const { value = '' } = e.target;

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            dispatch(agencyActions.doChangeKeyword(value));
        }, 300);
    };

    /** ==========  TÌM KIẾM ==========  */
    const onSearch = () => {
        const model = modelSearchAgency(1);

        dispatch(agencyActions.doChangePage(1));
        dispatch(agencyActions.doGetListAgency(model));
    };

    /** ==========  THAY ĐỔI TRANG ==========  */
    const onChangePage = (page) => {
        const model = modelSearchAgency(page);

        dispatch(agencyActions.doChangePage(page));
        dispatch(agencyActions.doGetListAgency(model));
    };

    /** ==========  HIỂN THỊ DỮ LIỆU ==========  */
    const showData = (_list) => {
        if (_.isEmpty(_list)) return <NoData colspan="7" />;

        return _list.map((agency, idx) => (
            <AgencyItem
                sortOrder={(currentPage - 1) * 10 + idx + 1}
                key={idx}
                agency={agency}
                onListSchool={onOpenListSchool}
                onTransHistory={onOpenTransHistory}
                onConnectionHistory={onOpenConnHistory}
                onChangeSwitch={onChangeSwitch}
            />
        ));
    };

    return (
        <Fragment>
            <PageHeader pageTitle="Danh sách đại lý" />

            <div className="content">
                <AgencyFilter
                    onSearch={onSearch}
                    onChangeKeyword={onChangeKeyword}
                />

                <AgencyList>{showData(listAgency)}</AgencyList>
            </div>

            <CustomePagination
                total={totalList}
                pageSize={10}
                currentPage={currentPage}
                currentCount={listAgency.length}
                onChange={onChangePage}
            />

            {isShowListSchool ? (
                <ModalListSchool
                    isShow={isShowListSchool}
                    onCloseModal={onCloseListSchool}
                />
            ) : null}

            {isShowTransHistory ? (
                <ModalTransHistory
                    isShow={isShowTransHistory}
                    onCloseModal={onCloseTransHistory}
                />
            ) : null}

            {isShowConnHistory ? (
                <ModalConnHistory
                    isShow={isShowConnHistory}
                    onCloseModal={onCloseConnHistory}
                />
            ) : null}
        </Fragment>
    );
};

export default Agency;
