/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import { GLOBAL } from '@app/constants/global.const';
import { getDayOfWeekByDate } from '@app/utils/datetime.utils';

import _globalUtils from '@app/utils/global.utils';
import reportUtils from '@app/utils/reportUtils';
// import _ from 'lodash';
import moment from 'moment';

class _weeklyMenuExportPDFService {
    /**
     * Merge dữ liệu thực đơn tuần
     * @param {Array} menulist : danh sách thực đơn
     * @param {Array} menuDishs : danh sách món ăn
     * @param {Array} foodDishCache : danh sach dish cache
     * @param {object} kg : nhóm trẻ
     * @param {object} locationInfo : thông tin địa chỉ của trường
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     * @param {number} type : loại báo cáo
     * @param {String} nameFile: tên prefix file khi xuất ra
     */
    doExport = async (
        menulist,
        menuDishs,
        foodDishCache,
        kg,
        locationInfo,
        schoolInfo,
        organizationInfo,
        type,
        dateFrom,
        dateTo,
        nameFile
    ) => {
        const _nameFile = nameFile || 'ThucDonTuan';

        const showMenuDish = (list, mealId) => {
            let xhtml = '';
            list = list.filter((item) => item.md_meal === mealId);
            if (list.length > 0) {
                list.forEach((item, i) => {
                    const dish = foodDishCache.find(
                        (elm) => elm.d_id === item.d_id
                    );
                    if (i > 0) {
                        xhtml += dish ? `\n${dish.d_name}` : '';
                    } else {
                        xhtml += dish ? dish.d_name : '';
                    }
                });
            }
            return xhtml;
        };

        const info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            // province: locationInfo.cityName,
            // district: locationInfo.districtName,
            // wards: locationInfo.wardName,
            school: schoolInfo.ten_truong,
            date: `Tuần lễ từ ngày ${moment
                .unix(dateFrom)
                .format('DD-MM-YYYY')} đến ngày ${moment
                .unix(dateTo)
                .format('DD-MM-YYYY')}`,
            type: type === 1 ? '' : '1',
            assistant: schoolInfo?.ho_ten_hieu_pho_ban_tru,
            assistantTitle:
                schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_ASSISTANT_TITLE,
        };

        const datas = menulist.map((item) => {
            const week = {};

            const listMenuDish = menuDishs.filter((o) => o.m_id === item.m_id);

            week.day = getDayOfWeekByDate(item.m_datefounded, true);
            week.breakfast = showMenuDish(listMenuDish, 1);
            week.lunch = showMenuDish(listMenuDish, 2);
            week.dinner = showMenuDish(listMenuDish, 3);
            week.snacks = showMenuDish(listMenuDish, 4);
            week.sdd = '';
            week.dcbp = '';
            week.dutp = '';
            week.csdb = '';
            return week;
        });

        const res = await exportReportToPDF({
            data: JSON.stringify({ info, datas }),
            report: REPORT_TYPE.THUC_DON_TUAN,
        });

        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `${_nameFile}_${kg.kg_name}_${organizationInfo.o_name}_${moment
                    .unix(dateFrom)
                    .format('DD')}_${moment.unix(dateTo).format('DD')}`
            );
        }
        return true;
    };
}

export default new _weeklyMenuExportPDFService();
