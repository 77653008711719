export class FoodReceiptOutDetailModel {
    constructor(data) {
        if (!data) {
            data = {};
        }

        this.fr_id = data.fr_id || 0;
        this.sf_id = data.sf_id || 0;
        this.f_id = data.f_id || 0;
        this.fr_code = data.fr_code || '';
        this.cu_id = data.cu_id || 0;
        this.cu_name = data.cu_name || '';
        this.frd_id = data.frd_id || 0;
        this.frd_foodname = data.frd_foodname || '';
        this.frd_quantityexport = data.frd_quantityexport || 0;
        this.frd_priceexport = data.frd_priceexport || 0;
        this.fr_description = data.fr_description || '';
        this.frd_totalexport =
            data.frd_quantityexport * data.frd_priceexport || 0;
        this.frd_status = 0;
        this.frd_importreceiptid = data.frd_importreceiptid || 0;
        this.frd_referencecode = data.frd_referencecode || '';
        this.checkTotalQuantity = data.checkTotalQuantity || 0;
        this.ware_imports = data.ware_imports || [];
        // this.isCheck = data.isCheck || false;
        // this.isEdit = data.isEdit || false;
    }
}
