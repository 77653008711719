import axiosService from '@app/services/axiosService';
import { API_ACCOUNT_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_ACCOUNT_ENDPOINT}/v1/ScAccount/ScPrivilege`;

const LIST_URL = {
    GET_LIST: `${BASE_URL}/Get`,
    UPDATE: `${BASE_URL}/Update`,
};

/* ======= Lấy danh sách phân quyền ======== */
export const apiGetListPrivilege = (data) =>
    axiosService.post(LIST_URL.GET_LIST, data);

/* ======= Cập nhật phân quyền người dùng ======== */
export const apiUpdatePrivilege = (data) =>
    axiosService.post(LIST_URL.UPDATE, data);
