const prefix = 'COUNTRY_';

export const GET_LIST_COUNTRY = `${prefix}GET_LIST_COUNTRY`;
export const GET_LIST_COUNTRY_SUCCCESS = `${prefix}GET_LIST_COUNTRY_SUCCESS`;

export const ADD_NEW = `${prefix}ADD_NEW`;
export const TOGGLE_MODAL = `${prefix}TOGGLE_MODAL`;

export const SAVE_COUNTRY = `${prefix}SAVE_COUNTRY`;
export const SAVE_COUNTRY_SUCCESS = `${prefix}SAVE_COUNTRY_SUCCESS`;

export const EDIT_COUNTRY = `${prefix}EDIT_COUNTRY`;

export const DELETE_COUNTRY = `${prefix}DELETE_COUNTRY`;
export const DELETE_COUNTRY_SUCCESS = `${prefix}DELETE_COUNTRY_SUCCESS`;

export const GET_LIST_SELECT_COUNTRY = `${prefix}GET_LIST_SELECT_COUNTRY`;
export const GET_LIST_SELECT_COUNTRY_SUCCESS = `${prefix}GET_LIST_SELECT_COUNTRY_SUCCESS`;

export const COUNTRY_SELECT_CHANGE = `${prefix}COUNTRY_SELECT_CHANGE`;
export const RESET_LIST_SELECT_COUNTRY = `${prefix}RESET_LIST_SELECT_COUNTRY`;

export const PAGE_CHANGE = `${prefix}PAGE_CHANGE`;
