/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-assign */
import React, { Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@app/redux/inventory/inventory.action';
import InventorySelector from '@app/redux/inventory/inventory.selector';
import WarehouseSelector from '@app/redux/warehouse/warehouse.selector';
import WarehouseSelect from '../Layout/Shares/WarehouseSelect';
import _othersSelector from '@app/redux/others/selector';
import { STATUS_PROCESS } from '@app/constants/';
// import { saveReceipt } from '@app/apis/inventory';
import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import { useCurrentSchoolId } from '@app/hooks/useCurrentSchool';
import OnlyMonthPicker from '../_Share/Date/OnlyMonthPicker';
import _fkInventoryReceipt from '@app/apis/inventory/inventoryReceipt.api';

const InventoryFilter = () => {
    const inventoryByMenuList = useSelector(
        InventorySelector.inventoryByMenuList
    );
    const currentWarehouse = useSelector(WarehouseSelector.currentItem);
    const kidGroup = useSelector(_othersSelector.kidGroup);
    const currentSchoolId = useCurrentSchoolId();
    const schoolInfo = useSelector(_othersSelector.schoolInfo);
    const dateInfo = useRef();

    const dispatch = useDispatch();

    const onSearch = () => {
        const type = parseInt(schoolInfo?.hinh_thuc_can_doi_thuc_don);

        dispatch(
            actions.getMenu(
                {
                    ...dateInfo.current,
                    ids: kidGroup.map((o) => o.kg_id),
                    o_id: currentSchoolId,
                },
                type,
                currentWarehouse,
                schoolInfo?.nguoi_lap_phieu_nxk
            )
        );
    };

    const saveInventory = async () => {
        const list = inventoryByMenuList.filter((o) => o.isCheck);
        dispatch(actions.update(list, STATUS_PROCESS.AWAITING));
        for (const item of list) {
            dispatch(actions.update([item], STATUS_PROCESS.PROCESSING));
            item.o_id = currentSchoolId;
            // await saveReceipt(item).then(({ status }) => {
            //     if (status === 200) {
            //         dispatch(actions.update([item], STATUS_PROCESS.DONE));
            //     } else {
            //         dispatch(actions.update([item], STATUS_PROCESS.ERROR));
            //     }
            //     dispatch(actions.checkFoodReceiptIn(item.fr_datefounded));
            // });
            await _fkInventoryReceipt.save(item).then(({ status }) => {
                if (status === 200) {
                    dispatch(actions.update([item], STATUS_PROCESS.DONE));
                } else {
                    dispatch(actions.update([item], STATUS_PROCESS.ERROR));
                }
                dispatch(actions.checkFoodReceiptIn(item.fr_datefounded));
            });
        }
    };

    const onMonthChange = (s) => {
        dateInfo.current = s;
    };

    return (
        <Fragment>
            {/* Filter */}
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        Kho hàng
                                    </span>
                                    <WarehouseSelect />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-4">
                            <div className="form-group">
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        Tháng
                                    </span>
                                    <OnlyMonthPicker onChange={onMonthChange} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6">
                            <div className="form-group">
                                <div className="no-margin">
                                    <div className="btn-group">
                                        <div className="btn-group">
                                            <ButtonFilterAction
                                                labelButton="Tìm kiếm"
                                                buttonType={
                                                    BUTTON_ACTION_TYPE.SEARCH
                                                }
                                                onClick={onSearch}
                                            />
                                            <ButtonFilterAction
                                                labelButton="Lưu nhập / xuất kho"
                                                buttonType={
                                                    BUTTON_ACTION_TYPE.SAVE
                                                }
                                                onClick={saveInventory}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default InventoryFilter;
