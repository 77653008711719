const prefix = 'INVENTORY_';

export const OPEN_INVENTORY_MODAL = `${prefix}'OPEN_INVENTORY_MODAL`;
export const CLOSE_INVENTORY_MODAL = `${prefix}CLOSE_INVENTORY_MODAL`;

export const CHANGE_INVENTORY = `${prefix}CHANGE_INVENTORY`;

export const GET_INVENTORY = `${prefix}GET_INVENTORY`;
export const GET_INVENTORY_SUCCESS = `${prefix}GET_INVENTORY_SUCCESS`;

export const GET_MENU = `${prefix}GET_MENU`;
export const GET_MENU_SUCCESS = `${prefix}GET_MENU_SUCCESS`;

export const SAVE_INVENTORY = `${prefix}SAVE_INVENTORY`;
export const SAVE_INVENTORY_SUCCESS = `${prefix}SAVE_INVENTORY_SUCCESS`;

export const DELETE_INVENTORY = `${prefix}DELETE_INVENTORY`;
export const DELETE_INVENTORY_SUCCESS = `${prefix}DELETE_INVENTORY_SUCCESS`;

export const CLOSE_MODAL_IN = `${prefix}CLOSE_MODAL_IN`;
export const OPEN_MODAL_IN = `${prefix}OPEN_MODAL_IN`;

export const CLOSE_MODAL_OUT = `${prefix}CLOSE_MODAL_OUT`;
export const OPEN_MODAL_OUT = `${prefix}OPEN_MODAL_OUT`;
export const UPDATE = `${prefix}UPDATE`;

export const CHECK_FOODRECEIPT_OUT = `${prefix}CHECK_FOODRECEIPT_OUT`;
export const CHECK_ALL = `${prefix}CHECK_ALL_FOODRECEIPT_OUT`;
