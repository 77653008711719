const prefix = 'PAYMENT_VOUCHER_';

export const PAYMENT_VOUCHER_MODULE_NAME = 'paymentVoucher';

export const GET_LIST_BY_DATE = `${prefix}GET_LIST_BY_DATE`;
export const GET_TOTAL_OF_LIST_BY_DATE = `${prefix}GET_TOTAL_OF_LIST_BY_DATE`;

export const SAVE = `${prefix}SAVE`;

export const DELETE = `${prefix}DELETE`;
