/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import {
    MenuFoodReportModel,
    ProductFoodModel,
} from '@app/models/foodkid/foodkid.model';
import {
    calori,
    decimalRounding,
    getCaloTotal,
    getTotalGlucid,
    getTotalLipid,
    getTotalProtid,
    totalCalo,
    totalGlucid,
    totalLipid,
    totalProtid,
    valueReport,
} from '@app/utils/foodkid.data';
import _globalUtils from '@app/utils/global.utils';
import reportUtils from '@app/utils/reportUtils';
import { decimalToPrice, numberToPrice } from '@app/utils/utils';
// import _ from 'lodash';
import moment from 'moment';

class _menuExportPDFService {
    /**
     * Merge dữ liệu thực đơn
     * @param {Array} proFoods : danh sách thực phẩm
     * @param {Array} schoolFoods : danh sách thực phẩm trường
     * @param {Array} menuSchoolFoods : danh sách thực phẩm của thực đơn
     * @param {Array} units : danh sách đơn vị tính
     * @param {object} menu : thực đơn
     * @param {Array} kidGroup : danh sách nhóm trẻ
     * @param {Array} dishList : danh sách món ăn
     * @param {Array} ageGroup : danh sách độ tuổi
     * @param {Array} serviceList : danh sách dịch vụ
     * @param {object} locationInfo : thông tin địa chỉ của trường
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     * @param {Array} mealService : danh sách bữa ăn
     * @param {String} nameFile: tên prefix file khi xuất ra
     */
    doExportMenu = async (
        proFoods,
        schoolFoods,
        menuSchoolFoods,
        units,
        menuItem,
        kidGroup,
        dishList,
        ageGroup,
        serviceList,
        locationInfo,
        schoolInfo,
        organizationInfo,
        mealService,
        nameFile
    ) => {
        const _nameFile = nameFile || 'ThucDonTrongNgay';

        const productFoodList = [];
        menuSchoolFoods.map((l) => {
            const food = schoolFoods.find((o) => o.sf_id === l?.sf_id);
            if (food !== undefined) {
                const unit =
                    food.cu_id !== 0
                        ? units.find((o) => o.cu_id === food?.cu_id)
                        : units.find((o) => o.cu_weight === 1000);
                const proFood = proFoods.find((o) => o.f_id === food?.f_id);
                l.f_name = proFood?.f_name;
                l.sf_name = food.sf_name ? food?.sf_name : proFood?.f_name;
                l.f_id = food?.f_id;
                l.f_nameabbreviation = proFood?.f_nameabbreviation;
                l.sf_price = food.sf_price;
                l.cu_id = unit.cu_id;
                l.cu_weight = unit.cu_weight;
                l.f_protida = proFood?.f_protida;
                l.f_protidv = proFood?.f_protidv;
                l.f_lipida = proFood?.f_lipida;
                l.f_lipidv = proFood?.f_lipidv;
                l.f_glucid = proFood?.f_glucid;
                l.f_calo = proFood?.f_calo;
                l.f_calo494 = proFood?.f_calo494;
                l.f_salt = proFood?.f_salt;
                l.f_canxi = proFood?.f_canxi;
                l.f_iron = proFood?.f_iron;
                l.f_photpho = proFood?.f_photpho;
                l.f_vitamina = proFood?.f_vitamina;
                l.f_vitaminb1 = proFood?.f_vitaminb1;
                l.f_vitaminb2 = proFood?.f_vitaminb2;
                l.msf_quantityafter =
                    menuItem.m_step >= 2
                        ? l.msf_quantitybefore
                        : l.msf_quantitybefore;
                l.msf_priceafter =
                    menuItem.m_step >= 2
                        ? l.msf_pricebefore
                        : l.msf_pricebefore;
                l.msf_pricefoodbefore =
                    menuItem.m_step >= 2
                        ? l.msf_pricefoodbefore
                        : l.msf_pricefoodbefore;
                l.f_vitaminpp = proFood?.f_vitaminpp;
                l.f_vitaminc = proFood?.f_vitaminc;
                l.sf_quantity_g =
                    menuItem.m_step === 3
                        ? (l.msf_quantitybefore * menuItem.m_quantitybefore) /
                          units.find((o) => o.cu_id === l.cu_id).cu_weight
                        : (l.msf_quantitybefore * menuItem.m_quantitybefore) /
                          units.find((o) => o.cu_id === l.cu_id).cu_weight;
                productFoodList.push(new ProductFoodModel(l));
            }
            return l;
        });
        const kg = kidGroup?.find((o) => o.kg_id === menuItem.kg_id);
        const ageGroupItem = ageGroup?.find((o) => o.ag_id === kg.ag_id);

        const protida = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_protida * calori(b, 0)),
                0
            )
        );
        const protidv = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_protidv * calori(b, 0)),
                0
            )
        );
        const lipida = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_lipida * calori(b, 0)),
                0
            )
        );
        const lipidv = decimalRounding(
            productFoodList.reduce(
                (a, b) => a + decimalRounding(b.f_lipidv * calori(b, 0)),
                0
            )
        );
        const o_breakfast = dishList.filter((o) => o.md_meal === 1);
        const o_lunch = dishList.filter((o) => o.md_meal === 2);
        const o_dinner = dishList.filter((o) => o.md_meal === 3);
        const o_afternoon_supper = dishList.filter((o) => o.md_meal === 4);

        const info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            m_code: menuItem.m_code,
            m_footer: `Thực đơn: ${menuItem.m_code}`,
            m_quantitybefore: menuItem.m_quantitybefore,
            m_pricebefore: menuItem.m_price,
            kg_name: kg !== undefined ? kg.kg_name : '',
            // province: locationInfo.cityName,
            // district: locationInfo.districtName,
            // wards: locationInfo.wardName,
            school: schoolInfo.ten_truong,
            date: moment.unix(menuItem.m_datefounded).format('DD-MM-YYYY'),
            title: 'KẾT QUẢ KHẨU PHẦN DINH DƯỠNG',
            breakfast: o_breakfast.reduce(
                (a, b, i) => (i === 0 ? a + b.d_name : `${a}\n${b.d_name}`),
                ''
            ),
            lunch: o_lunch.reduce(
                (a, b, i) => (i === 0 ? a + b.d_name : `${a}\n${b.d_name}`),
                ''
            ),
            dinner: o_dinner.reduce(
                (a, b, i) => (i === 0 ? a + b.d_name : `${a}\n${b.d_name}`),
                ''
            ),
            afternoon_supper: o_afternoon_supper.reduce(
                (a, b, i) => (i === 0 ? a + b.d_name : `${a}\n${b.d_name}`),
                ''
            ),
            kg_ratioprotid: valueReport(kg.kg_ratioprotid),
            kg_protidamax: valueReport(kg.kg_protidamax),
            kg_protidvmax: valueReport(kg.kg_protidvmax),
            kg_ratiolipid: valueReport(kg.kg_ratiolipid),
            kg_lipidamax: valueReport(kg.kg_lipidamax),
            kg_lipidvmax: valueReport(kg.kg_lipidvmax),
            kg_ratioglucid: valueReport(kg.kg_ratioglucid),
            kg_glucidmax: valueReport(kg.kg_glucidmax),
            kg_calomax: valueReport(kg.kg_calomax),
            ratioprotid: totalProtid(productFoodList, kg, ageGroupItem, 0),
            ratioprotida: decimalToPrice(
                ((protida / kg.kg_protidamax) * 100).toFixed(2)
            ),
            rprotida: ((protida / (protida + protidv)) * 100).toFixed(2),
            ratioprotidv: ((protidv / kg.kg_protidvmax) * 100).toFixed(2),
            rprotidv: ((protidv / (protida + protidv)) * 100).toFixed(2),
            ratiolipid: totalLipid(productFoodList, kg, ageGroupItem, 0),
            ratiolipida: ((lipida / kg.kg_lipidamax) * 100).toFixed(2),
            rlipida: ((lipida / (lipida + lipidv)) * 100).toFixed(2),
            ratiolipidv: ((lipidv / kg.kg_lipidvmax) * 100).toFixed(2),
            rlipidv: ((lipidv / (lipidv + lipida)) * 100).toFixed(2),
            ratioglucid: totalGlucid(productFoodList, kg, ageGroupItem, 0),
            ratiocalo: totalCalo(productFoodList, kg, ageGroupItem, 0),
            caloservice: `Tiền 1 calo: ${(
                productFoodList.reduce((a, b) => a + b.msf_pricefoodbefore, 0) /
                productFoodList.reduce(
                    (a, b) => a + b.f_calo494 * calori(b, 0),
                    0
                )
            ).toFixed(2)}`,
            nameservice: `${numberToPrice(
                serviceList.reduce((a, b) => a + b.mms_price, 0)
            )} (${serviceList.reduce(
                (a, b, i) =>
                    i === 0
                        ? a +
                          mealService.find((o) => o.ms_id === b.ms_id)?.ms_name
                        : `${a}, ${
                              mealService.find((o) => o.ms_id === b.ms_id)
                                  ?.ms_name
                          }`,
                ''
            )})`,
            kg_ratioprotid_real: (
                parseFloat(getTotalProtid(productFoodList, 0)) /
                getCaloTotal(productFoodList, 0)
            ).toFixed(2),
            kg_ratiolipid_real: (
                getTotalLipid(productFoodList, 0) /
                getCaloTotal(productFoodList, 0)
            ).toFixed(2),
            kg_ratioglucid_real: (
                (getTotalGlucid(productFoodList, 0) * 4 * 100) /
                getCaloTotal(productFoodList, 0)
            ).toFixed(2),
        };
        const datafoods = productFoodList
            .sort((a, b) => (a.msf_sortorder > b.msf_sortorder ? 1 : -1))
            .map((item, i) => {
                const food = new MenuFoodReportModel();
                const unit = units.find((o) => o.cu_id === item.cu_id);
                const vprotid_dv = item.f_protida * calori(item, 0);
                const vprotid_av = item.f_protidv * calori(item, 0);
                const vacid_dv = item.f_lipida * calori(item, 0);
                const vacid_av = item.f_lipidv * calori(item, 0);
                const vglucid = item.f_glucid * calori(item, 0);
                const vcalo = item.f_calo494 * calori(item, 0);
                food.stt = i + 1;
                food.f_name = item.sf_name;
                food.sf_quantiry = valueReport(item.msf_quantityafter);
                food.unit = unit !== undefined ? unit.cu_name : '';
                food.price =
                    item.msf_priceafter > 0
                        ? numberToPrice(item.msf_priceafter)
                        : '';
                food.protid_dv = valueReport(vprotid_dv);
                food.protid_av = valueReport(vprotid_av);
                food.acid_dv = valueReport(vacid_dv);
                food.acid_av = valueReport(vacid_av);
                food.glucid = valueReport(vglucid);
                food.calo = valueReport(vcalo);
                food.totalprice =
                    item.msf_pricefoodafter > 0
                        ? decimalToPrice(item.msf_pricefoodafter)
                        : '';
                food.sf_quantitytotal = valueReport(
                    (item.msf_quantityafter * menuItem.m_quantityafter) /
                        (unit !== undefined ? unit.cu_weight : 0)
                );
                food.disposal = valueReport(item.msf_disposalrate);
                food.real = valueReport(
                    parseFloat(item.msf_totalquantityafter)
                );
                return food;
            });
        const res = await exportReportToPDF({
            data: JSON.stringify({ info, food: datafoods }),
            report: REPORT_TYPE.THUC_DON_TRONG_NGAY,
        });
        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `${_nameFile}_${menuItem.m_code}_${kg?.kg_name}_${organizationInfo.o_name}`
            );
        }
        return true;
    };
}

export default new _menuExportPDFService();
