/* eslint-disable react/jsx-one-expression-per-line */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const propTypes = {
    pageTitle: PropTypes.string,
};

const PageHeader = ({ pageTitle }) => {
    const { t } = useTranslation('pageHeader');
    return (
        <div className="page-header page-header-default">
            <div className="page-header" />
            <div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li>
                        <a href="sys_dashboard.html">
                            <i className="icon-home2 position-left" /> Trang chủ
                        </a>
                    </li>

                    <li className="active">{t(pageTitle)}</li>
                </ul>
            </div>
        </div>
    );
};

PageHeader.propTypes = propTypes;

export default memo(PageHeader);
