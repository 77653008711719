import {
    RECEIPT_VOUCHER_PAYMENT_TYPE,
    RECEIPT_VOUCHER_TYPE,
} from '@app/constants/receiptCost.CONST';
import { createCode } from '@app/utils/utils';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export class InvoiceModel {
    constructor(data) {
        data = data || {};

        /**
         * Id người tạo phiếu
         */
        this.u_id = data.u_id || 0;

        /**
         * Id tổ chức
         */
        this.o_id = data.o_id || '';

        /**
         * Key
         */
        this.i_id = data.i_id || 0;

        /**
         * Mã học sinh lớp học
         */
        this.sc_id = data.sc_id || 0;

        /**
         * Mã phiếu thu
         */
        this.r_id = data.r_id || 0;

        /**
         * Loại phiếu
         * 1: Thu, 2: Chi, 3: Lương
         */
        this.i_type = data.i_type || 0;

        /**
         * Mã code hóa đơn
         */
        this.i_code = data.i_code
            ? data.i_code
            : this.createRandomCode(this.i_type);

        /**
         * Ngày tạo phiếu
         */
        this.i_datefounded = data.i_datefounded || 0;

        /**
         * Năm tạo phiếu
         */
        this.i_year = data.i_year || 0;

        /**
         * Năm tạo phiếu
         */
        this.i_month = data.i_month || 0;

        /**
         * Quý tạo phiếu
         */
        this.i_quarter = data.i_quarter || 0;

        /**
         * Tuần tạo phiếu
         */
        this.i_week = data.i_week || 0;

        /**
         * Mã hóa đơn điện tử
         */
        this.i_einvoicecode = data.i_einvoicecode || '';

        /**
         * Mã tham khảo hóa đơn điện tử
         */
        this.i_einvoicesearch = data.i_einvoicesearch || '';

        /**
         * Thông tin hóa đơn điện tử
         */
        this.i_einvoiceinfo = data.i_einvoiceinfo || '';

        /**
         * Tổng tiền
         */
        this.i_total = data.i_total || 0;

        /**
         * Trạng thái
         */
        this.i_status = data.i_status || 0;

        /**
         * Mã học sinh
         */
        this.i_linksid = data.i_linksid || '';

        /**
         * Mã lớp học
         */
        this.i_linkcid = data.i_linkcid || '';

        /**
         *
         */
        this.i_linkrid = data.i_linkrid || '';

        /**
         * Đối tượng chi
         */
        this.i_linktype = data.i_linktype || 0;

        /**
         * Người thu tiền
         */
        this.i_cashiername = data.i_cashiername || '';

        /**
         * Người nhận tiền
         */
        this.i_receivername = data.i_receivername || '';

        /**
         * Mã năm học
         */
        this.sy_id = data.sy_id || 0;

        /**
         * Mã ngân hàng
         */
        this.ab_id = data.ab_id || 0;

        /**
         * Hình thức thu tiền
         */
        this.i_paymenttype =
            data.i_paymenttype || RECEIPT_VOUCHER_PAYMENT_TYPE.TIEN_MAT;
        /**
         * Số chứng từ
         */
        this.i_referencecode = data.i_referencecode || '';

        /**
         * Địa chỉ
         */
        this.i_address = data.i_address || '';

        /**
         * Miêu tả
         */
        this.i_description = data.i_description || '';

        /**
         * Kiểu phiếu
         * 1: Thu, 2: Chi, 3: Lương, 4:Hoàn trả
         */
        this.i_linkrtype = data.i_linkrtype || 0;

        /**
         * Tháng lập phiếu
         */
        this.r_month = data.r_month || 0;

        /**
         * Ngày bắt đầu - Dùng cho lập phiếu chi phần dịch vụ
         */
        this.i_datefrom = data.i_datefrom || 0;

        /**
         * Ngày kết thúc - Dùng cho lập phiếu chi phần dịch vụ
         */
        this.i_dateto = data.i_dateto || 0;

        this.i_paymentvouchertype = data.i_paymentvouchertype || 0;

        this.i_daterange_dt = this.getDateRange(this.i_datefrom, this.i_dateto);

        this.randomStr = uuidv4();
    }

    createRandomCode = (type) => {
        let _prefix = 'PT';
        switch (type) {
            case RECEIPT_VOUCHER_TYPE.PHIEU_CHI: {
                _prefix = 'PC';
                break;
            }
            case RECEIPT_VOUCHER_TYPE.PHIEU_LUONG: {
                _prefix = 'PL';
                break;
            }
            default: {
                break;
            }
        }
        return createCode(_prefix);
    };

    getDateRange = (dateFrom, dateTo) => {
        if (dateFrom && dateTo) {
            return {
                startDate: moment.unix(dateFrom),
                endDate: moment.unix(dateTo),
            };
        }
        return {
            startDate: moment(new Date()).startOf('D'),
            endDate: moment(new Date()).add(1, 'days').endOf('D'),
        };
    };
}
