import { BaseRepository } from './baseRepository';
import { DB_CACHE_CONST } from '../../constants/dbCacheConst';

class SchoolYearRepository extends BaseRepository {
    constructor() {
        super();
        this.db = DB_CACHE_CONST.STORES.RESOURCES;
        this.table = DB_CACHE_CONST.TABlES.SCHOOL_YEAR;
    }

    getAll = async () => {
        const data = await this.initData();
        const db = this.taffydb.taffy(data);
        return db().get();
    };

    getById = async (id) => {
        const data = await this.initData();
        const db = this.taffydb.taffy(data);
        return db({ id }).first();
    };
}

export default new SchoolYearRepository();
