import axiosService from '../../services/axiosService';
import { API_SC_GATEWAY_ENDPOINT } from '../../constants/api';

const BASE_URL = `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/ToolSC/FoodKid/Warehouse`;

const LIST_URL = {
    GET_BY_SCHOOL: `${BASE_URL}/GetBySchool`,
};

const _fkWarehouseApis = {
    getBySchool(orgId) {
        return axiosService.get(`${LIST_URL.GET_BY_SCHOOL}/${orgId}`);
    },
};

export default _fkWarehouseApis;
