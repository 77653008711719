import axiosService from '../../services/axiosService';
import { API_ACCOUNT_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_ACCOUNT_ENDPOINT}/v1`;
const LIST_URL = {
    LOGIN: `${BASE_URL}/ScServiceLogin`,
    PING: `${BASE_URL}/PingPing`,
};
export const login = (model) => axiosService.post(LIST_URL.LOGIN, model);

export const doPingPong = () => axiosService.get(LIST_URL.PING);
