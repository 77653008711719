export class UserInfoModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.u_id = data.u_id || 0;
        this.username = data.username || '';
        this.o_id = data.o_id || '';
        this.auth = data.auth || '';
        this.oc_id = data.oc_id || undefined;
        this.r_id = data.r_id || undefined;
        this.o_name = data.o_name || '';
        this.avatar = data.avatar || '';
        this.name = data.name || '';
    }
}
