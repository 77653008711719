import axiosService from '../../services/axiosService';
import { API_ORGANIZATION_ENDPOINT } from '../../constants/api';

const url = 'v1/CustomerOrganization';

export const getList = () =>
    axiosService.get(`${API_ORGANIZATION_ENDPOINT}/${url}/GetSchoolByUser`);

export const getListShoolService = (data) =>
    axiosService.post(
        `${API_ORGANIZATION_ENDPOINT}/${url}/GetSchoolService`,
        data
    );

export const apiSaveLinkedSchool = (data) =>
    axiosService.post(
        `${API_ORGANIZATION_ENDPOINT}/${url}/SaveLinkedSchool`,
        data
    );

export const apiUpdateConnectStatus = (data) =>
    axiosService.post(
        `${API_ORGANIZATION_ENDPOINT}/${url}/UpdateConnectStatus`,
        data
    );

const urlScale = 'v1/CustomerScaleOrganization';

export const getListScale = () =>
    axiosService.get(
        `${API_ORGANIZATION_ENDPOINT}/${urlScale}/GetWithoutCategory`
    );

const urlType = 'v1/CustomerTypeOrganization';

export const getListType = () =>
    axiosService.get(
        `${API_ORGANIZATION_ENDPOINT}/${urlType}/GetWithoutCategory`
    );
