// import React, { useState, useEffect } from 'react';
// import { Switch } from 'antd';
// import { convertToBoolean } from './_service';

// const RenderSwitchField = ({ label, input }) => {
//     const [isChecked, setIsChecked] = useState(true);
//     const { value, onChange } = input;

//     useEffect(() => {
//         if (value !== undefined) {
//             const cValue = convertToBoolean(value);
//             setIsChecked(cValue);
//             onChange(cValue);
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     const handleOnchange = (s) => {
//         setIsChecked(s);
//         onChange(s);
//     };

//     return (
//         <div>
//             <label>
//                 <h6 className="panel-title sc-title">{label}</h6>
//             </label>
//             <div className="clearfix" />
//             <Switch
//                 {...input}
//                 checked={isChecked}
//                 onChange={(s) => handleOnchange(s)}
//             />
//         </div>
//     );
// };

// export default RenderSwitchField;
import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';

const RenderSwitchField = ({ label, input, ...custome }) => {
    const { value, name, onChange } = input;

    const [mapValue, setMapValue] = useState();

    useEffect(() => {
        const _valueMap = value === 1 || value === true ? 1 : 0;
        setMapValue(_valueMap);
    }, [value]);

    const handleChange = (valueChange) => {
        const _valueMap = valueChange === 1 || valueChange === true ? 1 : 0;
        setMapValue(_valueMap);
        if (onChange) {
            onChange(_valueMap);
        }
    };

    return (
        <div>
            <label>
                <h6 className="panel-title">{label}</h6>
            </label>
            <div className="clear-fix" />
            <Switch
                onChange={(s) => handleChange(s)}
                name={name}
                {...custome}
                checked={mapValue === 1}
            />
        </div>
    );
};

export default RenderSwitchField;
