import React from 'react';
import PropTypes from 'prop-types';
import { getTableButtonType } from '../ButtonFilterAction/_service';
import '../_style.scss';
import ReactTooltip from 'react-tooltip';
import { useAuthButton } from '@app/hooks/useAuthButton';

const propTypes = {
    tootTipTitle: PropTypes.string,
    onClick: PropTypes.func,
    buttonType: PropTypes.string,
    disabled: PropTypes.bool,
};

const ButtonTableAction = (props) => {
    const { tootTipTitle, onClick, buttonType, disabled } = props;
    const classButton = getTableButtonType(buttonType);
    const isAuth = useAuthButton(buttonType);

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return isAuth ? (
        <div className="wrap-contain-tooltip">
            <span data-tip={tootTipTitle}>
                <button
                    type="button"
                    className={`btn btn-xs btn-icon btn-rounded ${classButton.btnClass}`}
                    onClick={() => handleClick()}
                    disabled={disabled}
                >
                    <i className={classButton.icon} />
                </button>
            </span>
            <ReactTooltip
                place="top"
                type="dark"
                effect="solid"
                wrapper="span"
            />
        </div>
    ) : null;
};

ButtonTableAction.propTypes = propTypes;

export default ButtonTableAction;
