import React, { Fragment } from 'react';
// import routes from '../../../routes';
import { Switch, withRouter } from 'react-router-dom';
import CustomeRoute from './CustomeRoute';
import RouteAuthentication from '../../../routeAuth';

const Body = () => {
    const routeList = RouteAuthentication.getRoutes();
    const showConfigRouter = () => {
        let xhtml = null;
        if (routeList.length > 0) {
            xhtml = routeList.map((item, index) => (
                <CustomeRoute {...item} key={index} />
            ));
        }

        return <Switch>{xhtml}</Switch>;
    };

    return <Fragment>{showConfigRouter()}</Fragment>;
};

export default withRouter(Body);
