import { createSlice } from '@reduxjs/toolkit';

const MODULE_NAME = 'transactionHistory';

const initialState = {
    listTransactionHistory: [],
    totalList: 0,
    currentInfoDetail: {},

    isShow: false,

    currentPage: 1,
};

const TransactionHistorySlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        doGetList() {},
        doGetListSuccess(state, { payload }) {
            const { data, total } = payload;

            state.listTransactionHistory = data;
            state.totalList = total;
        },

        doOpenHistoryDetail(state, { payload }) {
            state.isShow = true;
            state.currentInfoDetail = payload;
        },
        doCloseHistoryDetail(state) {
            state.isShow = false;
        },

        doChangePage(state, { payload }) {
            state.currentPage = payload;
        },

        doReset(state) {
            state.listTransactionHistory = [];
            state.totalList = 0;
            state.currentPage = 1;
        },
    },
});

/** ========== ACTIONS ========== */
export const transactionHistoryActions = TransactionHistorySlice.actions;

/** ========== SELECTOR ========== */
export const transactionHistorySelector = {
    listTransactionHistory: (state) =>
        state[MODULE_NAME].listTransactionHistory,
    totalList: (state) => state[MODULE_NAME].totalList,
    currentInfoDetail: (state) => state[MODULE_NAME].currentInfoDetail,
    isShow: (state) => state[MODULE_NAME].isShow,

    currentPage: (state) => state[MODULE_NAME].currentPage,
};

/** ========== REDUCER ========== */
const transactionHistoryReducer = TransactionHistorySlice.reducer;
export default transactionHistoryReducer;
