const MODULE_NAME = 'copyMenuReducer';

const CopyMenuSelector = {
    menuList: (state) => state[MODULE_NAME].menuList,
    kidGroupList: (state) => state[MODULE_NAME].kidGroupList,
    idToSchool: (state) => state[MODULE_NAME].idToSchool,
    rowSelected: (state) => state[MODULE_NAME].rowSelected,
    isImport: (state) => state[MODULE_NAME].isImport,
};

export default CopyMenuSelector;
