import axiosService from '@app/services/axiosService';
import { API_SCHOOL_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_SCHOOL_ENDPOINT}/v1/schoolProperty`;
const BASE_URL_V1 = `${API_SCHOOL_ENDPOINT}/v1/schoolPropertySetting`;

const LIST_URL = {
    GET_LIST_PROPERTY: `${BASE_URL}/GetList`,
    GET_LIST_PROPERTY_SETTING: `${BASE_URL_V1}/GetAllSettingByOId`,
};

/* ======= Lấy danh sách thuộc tính ======== */
export const apiGetListSchoolProperty = () =>
    axiosService.post(LIST_URL.GET_LIST_PROPERTY);

/* ======= Lấy danh sách cấu hình trường ======== */
export const apiGetListSchoolPropertySetting = (oId) =>
    axiosService.get(`${LIST_URL.GET_LIST_PROPERTY_SETTING}/${oId}`);

/* ======= Lưu cấu hình trường ======== */
export const apiSaveSchoolPropertySetting = (data) =>
    axiosService.post(BASE_URL_V1, data);

const _schoolPropertyApis = {
    getSetting(oId) {
        return axiosService.get(`${LIST_URL.GET_LIST_PROPERTY_SETTING}/${oId}`);
    },
};

export default _schoolPropertyApis;
