import axiosService from '@app/services/axiosService';
import { API_ACCOUNT_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_ACCOUNT_ENDPOINT}/v1/ScAccount/ScRole`;

const LIST_URL = {
    GET_LIST: `${BASE_URL}/Get`,
    SAVE: `${BASE_URL}/Save`,
    DELETE: `${BASE_URL}/Delete`,
};

/* ======= Lấy danh sách vai trò ======== */
export const apiGetListRole = () => axiosService.get(LIST_URL.GET_LIST);

/* ======= Lưu vai trò ======== */
export const apiSaveRole = (data) => axiosService.post(LIST_URL.SAVE, data);

/* ======= Xóa vai trò ======== */
export const apiDeleteRole = (id) =>
    axiosService.delete(`${LIST_URL.DELETE}/${id}`);
