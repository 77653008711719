/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import { GLOBAL } from '@app/constants/global.const';
import _globalUtils from '@app/utils/global.utils';
import reportUtils from '@app/utils/reportUtils';
import {
    numberToPrice,
    precisionRound,
    priceToNumber,
    priceType,
} from '@app/utils/utils';
import moment from 'moment';

// Thẻ kho
class _receiptExportPDFService {
    /**
     * Merge dữ liệu kho
     * @param {Array} foodReceiptList : danh sách thực phẩm kho
     * @param {number} beforeQuantity : số lượng nhập
     * @param {number} beforeTotal : tổng tiền nhập
     * @param {Array} schoolFoods : danh sách thực phẩm trường
     * @param {Array} units : danh sách đơn vị tính
     * @param {number} sf_id : id thực phẩm
     * @param {number} year : id năm học
     * @param {number} month : id tháng học
     * @param {Array} inventory : kho
     * @param {object} locationInfo : thông tin địa chỉ của trường
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     */
    doExport = async (
        foodReceiptList,
        beforeQuantity,
        beforeTotal,
        schoolFoods,
        units,
        sf_id,
        year,
        month,
        inventory,
        locationInfo,
        schoolInfo,
        organizationInfo
    ) => {
        let remainQuantity = beforeQuantity;

        let remainTotal = beforeTotal;

        const firstRow = {
            content: 'Tồn đầu kì',
            inventory_quantity: beforeQuantity.toFixed(2),
            inventory_price: numberToPrice(beforeTotal),
        };

        const sf = schoolFoods.find((o) => o.sf_id === parseInt(sf_id));
        const unit = units.find((o) => o.cu_id === sf?.cu_id);
        let datas = foodReceiptList
            .reduce((acc, rec) => {
                const itemsInAcc = acc.filter(
                    (a) =>
                        a.sf_id === rec.sf_id &&
                        a.fr_datefounded === rec.fr_datefounded &&
                        a.fr_type === rec.fr_type
                );

                if (itemsInAcc.length > 0) {
                    itemsInAcc[0].frd_totalimport += +rec.frd_totalimport;
                    itemsInAcc[0].frd_quantityimport += +rec.frd_quantityimport;
                    itemsInAcc[0].frd_totalexport += +rec.frd_totalexport;
                    itemsInAcc[0].frd_quantityexport += +rec.frd_quantityexport;
                } else {
                    acc = [
                        ...acc,
                        {
                            ...rec,
                            frd_totalimport: +rec.frd_totalimport,
                            frd_quantityimport: +rec.frd_quantityimport,
                            frd_totalexport: +rec.frd_totalexport,
                            frd_quantityexport: +rec.frd_quantityexport,
                        },
                    ];
                }
                return acc;
            }, [])
            .map((item) => {
                if (item.fr_type === 1) {
                    remainTotal += parseFloat(item.frd_totalimport.toFixed(2));
                    remainQuantity += parseFloat(
                        item.frd_quantityimport.toFixed(2)
                    );
                } else if (item.fr_type === 2) {
                    remainTotal -= parseFloat(item.frd_totalexport.toFixed(2));
                    remainQuantity -= parseFloat(
                        item.frd_quantityexport.toFixed(2)
                    );
                }
                const d = {};
                d.date = moment(moment.unix(item.fr_datefounded)).format('DD');
                d.import_code = item.fr_type === 1 ? item.fr_code : '';
                d.export_code = item.fr_type === 2 ? item.fr_code : '';
                d.content = item.fr_description;
                d.inventory_quantity = priceType(remainQuantity.toFixed(2));
                d.inventory_price = numberToPrice(remainTotal);
                d.import_quantity =
                    item.frd_quantityimport !== 0 && item.fr_type === 1
                        ? precisionRound(item.frd_quantityimport, 2)
                        : '';
                d.import_price =
                    item.frd_totalimport !== 0 && item.fr_type === 1
                        ? numberToPrice(item.frd_totalimport)
                        : '';
                d.export_quantity =
                    item.frd_quantityexport !== 0 && item.fr_type === 2
                        ? precisionRound(item.frd_quantityexport, 2)
                        : '';
                d.export_price =
                    item.frd_totalexport !== 0 && item.fr_type === 2
                        ? numberToPrice(item.frd_totalexport)
                        : '';
                return d;
            });

        const info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            school: schoolInfo.ten_truong,
            f_name: sf !== undefined ? sf.sf_name : '',
            f_price: sf !== undefined ? numberToPrice(sf.sf_price) : '0',
            unit: unit !== undefined ? unit.cu_name : '',
            title: `THẺ KHO THÁNG ${month >= 10 ? month : `0${month}`}/${year}`,
            warehouse:
                inventory !== undefined ? `Kho: ${inventory.w_name}` : '',
            importTotal: datas.reduce((a, v) => {
                if (v.import_quantity !== '') {
                    a += priceToNumber(v.import_quantity);
                }
                return a;
            }, 0),
            importPriceTotal: numberToPrice(
                datas.reduce((a, v) => {
                    if (v.import_price !== '') {
                        a += priceToNumber(v.import_price);
                    }
                    return a;
                }, 0)
            ),
            exportTotal: precisionRound(
                datas.reduce((a, v) => {
                    if (v.export_quantity !== '') {
                        a += priceToNumber(v.export_quantity);
                    }
                    return a;
                }, 0),
                2
            ),
            exportPriceTotal: numberToPrice(
                datas.reduce((a, v) => {
                    if (v.export_price !== '') {
                        a += priceToNumber(v.export_price);
                    }
                    return a;
                }, 0)
            ),
            inventoryTotal: datas[datas.length - 1].inventory_quantity,
            inventoryPriceTotal: datas[datas.length - 1].inventory_price,
            assistant: schoolInfo?.ho_ten_hieu_pho_ban_tru,
            assistantTitle:
                schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_ASSISTANT_TITLE,
            warehouseKeeper: schoolInfo.ho_ten_thu_kho,
        };

        datas = [firstRow, ...datas];

        const res = await exportReportToPDF({
            data: JSON.stringify({ info, datas }),
            report: REPORT_TYPE.THE_KHO,
        });
        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `TheKho_Thang_${month}_${year}_${sf?.sf_name}_${inventory.w_name}_${organizationInfo.o_name}`
            );
        }
        return true;
    };
}

export default new _receiptExportPDFService();
