import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import AccountRoleSelect from '@app/components/_Share/Select/AccountRoleSelect/Dynamic';
import React from 'react';

const AccountFilter = ({ onChangeRole, onAddNew }) => (
    <div className="row">
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-3 col-sm-12">
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-addon">Vai trò</span>
                            <AccountRoleSelect isAll onChange={onChangeRole} />
                        </div>
                    </div>
                </div>
                <div className="col-md-9 col-sm-6">
                    <div className="form-group">
                        <div className="no-margin">
                            <div className="btn-group">
                                <ButtonFilterAction
                                    labelButton="Thêm mới"
                                    buttonType={BUTTON_ACTION_TYPE.ADD}
                                    onClick={onAddNew}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default AccountFilter;
