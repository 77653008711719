const MODULE_NAME = 'warehouseReducer';

const WarehouseSelector = {
    warehouseList: (state) => state[MODULE_NAME].warehouseList,
    currentWarehouse: (state) => state[MODULE_NAME].currentWarehouse,
    isShowModal: (state) => state[MODULE_NAME].isShowModal,
    currentItem: (state) => state[MODULE_NAME].currentItem,
};

export default WarehouseSelector;
