import ButtonModalAction from '@app/components/UI/Button/ButtonModalAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import SchoolSelector from '@app/redux/school/school.selector';
import FoodkidSelector from '@app/redux/foodkid/foodkid.selector';
import _ from 'lodash';
import NoData from '@app/components/Layout/Shares/NoData';
import * as actions from '@app/redux/foodkid/foodkid.action';
import { numberToPrice } from '@app/utils/utils';

const propTypes = {
    isShow: PropTypes.bool,
    initialValues: PropTypes.shape({}),
    onSubmitUpdateService: PropTypes.func,
    onClose: PropTypes.func,
};

const UpdateServiceModal = ({ isShow, onClose, onSubmitUpdateService }) => {
    const dispatch = useDispatch();

    // -- SELECTOR SCHOOL
    const currentSchool = useSelector(SchoolSelector.currentSchool);

    // -- SELECTOR THỰC ĐƠN
    const listServiceSchool = useSelector(FoodkidSelector.listServiceSchool);

    useEffect(() => {
        dispatch(actions.doGetListServiceSchool(currentSchool));
    }, [dispatch, currentSchool]);

    const onChangeCheck = (data) => {
        const newList = listServiceSchool.map((o) => {
            if (o.ms_id === data.ms_id) {
                o = { ...o, isCheck: !o.isCheck };
            }

            return o;
        });

        dispatch(actions.doUpdateListServiceSchool(newList));
    };

    const showData = () => {
        if (_.isEmpty(listServiceSchool)) return <NoData colspan={4} />;

        return listServiceSchool.map((o, idx) => (
            <tr key={idx}>
                <td className="text-center">{o.ms_sortorder}</td>
                <td>{o.ms_name}</td>
                <td className="text-right">{numberToPrice(o.ms_price)}</td>
                <td className="text-center">
                    <label>
                        <div className="checker">
                            <span className={o.isCheck ? 'checked' : ''}>
                                <input
                                    type="checkbox"
                                    className="styled"
                                    onChange={() => onChangeCheck(o)}
                                />
                            </span>
                        </div>
                    </label>
                </td>
            </tr>
        ));
    };

    return (
        <Modal
            show={isShow}
            onHide={() => onClose()}
            animation
            backdrop="static"
            size="sm"
        >
            <Modal.Header closeButton className="bg-primary-800">
                <Modal.Title>Cập nhật tiền dịch vụ</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-togglable table-hover table-bordered table-striped datatable-pagination input-table">
                            <thead className="grey-light">
                                <tr>
                                    <th
                                        className="text-center"
                                        style={{ width: '55px' }}
                                    >
                                        STT
                                    </th>
                                    <th className="text-center">Tên dịch vụ</th>
                                    <th className="text-center">Giá tiền</th>
                                    <th
                                        className="text-center"
                                        style={{ width: '5%' }}
                                    >
                                        Chọn
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{showData()}</tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-group">
                    <ButtonModalAction
                        buttonType={BUTTON_ACTION_TYPE.SAVE}
                        disabled={
                            listServiceSchool.filter((o) => o.isCheck)
                                .length === 0
                        }
                        type="button"
                        buttonLabel="Lưu"
                        onClick={() =>
                            onSubmitUpdateService(
                                listServiceSchool.filter((o) => o.isCheck)
                            )
                        }
                        isLeftIcon
                    />
                    <ButtonModalAction
                        buttonType={BUTTON_ACTION_TYPE.CLOSE}
                        buttonLabel="Thoát"
                        onClick={onClose}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    );
};

UpdateServiceModal.propTypes = propTypes;
export default UpdateServiceModal;
