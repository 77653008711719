import axiosService from '../../services/axiosService';
import { API_WEB_IMAGE_UPLOAD_ENDPOINT } from '../../constants/api';

const URL_LIST = {
    // UPLOAD_IMAGE: 'file',
    UPLOAD_IMAGE: 'image',
    DELETE_IMAGE: 'remove',
    UPLOAD_MULTI_IMAGE: 'multiFile',
};

export const uploadImageV1 = (data, type) =>
    axiosService.post(
        `${API_WEB_IMAGE_UPLOAD_ENDPOINT}/${URL_LIST.UPLOAD_IMAGE}`,
        data,
        type
    );
export const deleteImageV1 = (data) =>
    axiosService.post(
        `${API_WEB_IMAGE_UPLOAD_ENDPOINT}/${URL_LIST.DELETE_IMAGE}`,
        data
    );

export const uploadMultiImage = (data, type) =>
    axiosService.post(
        `${API_WEB_IMAGE_UPLOAD_ENDPOINT}/${URL_LIST.UPLOAD_MULTI_IMAGE}`,
        data,
        type
    );
