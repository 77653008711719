import React, { Fragment, useEffect, useState } from 'react';
import { Select } from 'antd';
import { nonAccentVietnamese } from '@app/utils/utils';
import _kidGroupApis from '@app/apis/foodkid/kidGroup.api';

const { Option } = Select;
const KidGroupListSelect = ({
    oId,
    label,
    defaultValue,
    onChange,
    disabled,
}) => {
    const [list, setList] = useState([]);
    const [selectId, setSelectId] = useState('');

    const fetchData = async () => {
        const { status, data } = await _kidGroupApis.getListByOId(oId);
        if (status !== 200) {
            setList([]);
            setSelectId('');
            return;
        }

        const dataRes = data.data;
        let id = '';

        if (dataRes.length > 0) {
            if (defaultValue) {
                const check = dataRes.find(
                    (item) => item.kg_id === defaultValue
                );
                if (check) {
                    id = defaultValue;
                } else {
                    id = dataRes[0].kg_id;
                }
            } else {
                id = dataRes[0].kg_id;
            }
        }

        setList(dataRes);
        setSelectId(id);
        onChange?.(id);
    };

    useEffect(() => {
        // const scholl_select = localStorage.getItem(SCHOOL_SELECT);
        // eslint-disable-next-line eqeqeq
        if (oId == '0') {
            setList([]);
            setSelectId('');
            return;
        }

        fetchData();
        return () => {
            setList([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oId]);

    useEffect(() => {
        if (defaultValue) {
            setSelectId(defaultValue);
            return;
        }
        if (list && list.length > 0) {
            setSelectId(list[0].kg_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const onSearch = (input, option) => {
        if (option) {
            return (
                nonAccentVietnamese(option.children.replace(/\s/g, ''))
                    .toLowerCase()
                    .indexOf(
                        nonAccentVietnamese(
                            input.replace(/\s/g, '')
                        ).toLowerCase()
                    ) >= 0
            );
        }
        return false;
    };

    const showOptionItem = (_list) => {
        const xhtml = [];
        if (_list.length > 0) {
            for (let i = 0; i < _list.length; i++) {
                xhtml.push(
                    <Option value={_list[i].kg_id} key={_list[i].kg_id}>
                        {_list[i].kg_name}
                    </Option>
                );
            }
        }
        return xhtml;
    };

    const handleChange = (value) => {
        setSelectId(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Fragment>
            {label ? (
                <Fragment>
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                    <div className="clearfix mb20" />
                </Fragment>
            ) : null}
            <Select
                value={selectId}
                optionFilterProp="children"
                filterOption={(input, option) => onSearch(input, option)}
                disabled={disabled}
                className="disable-modified"
                onChange={handleChange}
                showSearch
            >
                {showOptionItem(list)}
            </Select>
        </Fragment>
    );
};

export default KidGroupListSelect;
