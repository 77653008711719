import React, { Fragment, useEffect, useState } from 'react';
import PageHeader from '../Layout/PageHeader/PageHeader';
import GeneralFilter from './GanaralFilter';
import GeneralList from './GeneralList';
import FookidList from './FoodkidList';
import IncomeList from './IncomeList';
import moment from 'moment';
import { SCHOOL_SETTING_SERVICE_GROUP } from '@app/constants/schoolSetting.const';
import _othersSelector from '@app/redux/others/selector';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const General = () => {
    // Dùng để kiểm tra khi bấm nút export PDF
    const [countClickExport, setCountClickExport] = useState(0);
    const [kidGroup, setKidGroup] = useState([]);
    // const [yearId, setYearId] = useState(0);
    // const [month, setMonth] = useState(0);
    const [dateto, setDateTo] = useState(moment().endOf('day').unix());
    const [datefrom, setDateFrom] = useState(moment().startOf('day').unix());
    const [list, setList] = useState([]);

    const schoolProperty = useSelector(_othersSelector.schoolProperty);
    const schoolInfo = useSelector(_othersSelector.schoolInfo);

    const onExportPDF = () => {
        setCountClickExport(countClickExport + 1);
    };

    useEffect(() => {
        if (!_.isEmpty(schoolProperty) && !_.isEmpty(schoolInfo)) {
            const listReport = SCHOOL_SETTING_SERVICE_GROUP?.reduce(
                (rsList, item) => {
                    if (!_.isEmpty(item.listCode)) {
                        rsList = [
                            ...rsList,
                            ...item.listCode
                                ?.filter((code) => schoolInfo[code])
                                ?.map((code) => ({
                                    name: schoolProperty[code].p_name,
                                    category: item.typeCate,
                                    isCheck: true,
                                    status: 0,
                                    code,
                                })),
                        ];
                    }

                    return rsList;
                },
                []
            )?.map((o, idx) => ({ ...o, id: idx + 1, stt: idx + 1 }));

            setList(listReport);
        } else {
            setList([]);
        }
    }, [schoolInfo, schoolProperty]);

    /** THAY ĐỔI TỪ NGÀY & ĐẾN NGÀY */
    const onChangeDate = (date, dateString, dateName) => {
        const monthDateChange = moment(date).month() + 1;
        const dayDateChange = moment(date).date();

        if (dateName === 'dateFrom') {
            const monthDateTo = moment.unix(dateto).month() + 1;
            const dayDateTo = moment.unix(dateto).date();

            let dateToChange = moment.unix(dateto);

            if (dayDateChange > dayDateTo) {
                dateToChange = dateToChange.set('date', dayDateChange);
            }

            if (monthDateChange !== monthDateTo) {
                dateToChange = dateToChange.set('month', monthDateChange - 1);
            }

            setDateTo(dateToChange.endOf('day').unix());
            setDateFrom(moment(date).unix());
        } else {
            const monthDateFrom = moment(datefrom).month() + 1;
            const dayDateFrom = moment.unix(datefrom).date();

            let dateFromChange = moment.unix(datefrom);

            if (dayDateChange < dayDateFrom) {
                dateFromChange = dateFromChange.set('date', dayDateChange);
            }

            if (monthDateChange !== monthDateFrom) {
                dateFromChange = dateFromChange.set(
                    'month',
                    monthDateChange - 1
                );
            }

            if (monthDateChange !== monthDateFrom) {
                dateFromChange = dateFromChange.set(
                    'month',
                    monthDateChange - 1
                );
            }
            setDateFrom(dateFromChange.startOf('day').unix());
            setDateTo(moment(date).unix());
        }
    };

    const onCheckAll = (value) => {
        const newList = list.map((o) => ({
            ...o,
            isCheck: value.target.checked,
        }));

        setList(newList);
    };

    return (
        <Fragment>
            <PageHeader pageTitle="TỔNG HỢP SỔ SÁCH BÁN TRÚ" />
            <div className="content">
                {/* Filter */}
                <GeneralFilter
                    onExportPDF={() => onExportPDF()}
                    onChangeDate={onChangeDate}
                    setKidGroup={(value) => setKidGroup(value)}
                    kidGroup={kidGroup}
                    dateTo={dateto}
                    dateFrom={datefrom}
                />
                {/* /Filter */}

                {/* Main content */}
                <GeneralList list={list} onCheckAll={onCheckAll}>
                    <FookidList
                        listReport={list}
                        dateTo={dateto}
                        dateFrom={datefrom}
                        kidGroupIds={kidGroup}
                        countClickExport={countClickExport}
                        onChangeListReport={setList}
                    />

                    <IncomeList
                        listReport={list}
                        dateFrom={datefrom}
                        countClickExport={countClickExport}
                        onChangeListReport={setList}
                    />
                </GeneralList>
                {/* /Main content */}
            </div>
        </Fragment>
    );
};

export default General;
