const prefix = 'DISTRICT_';
export const GET_DISTRICTS = `${prefix}GET_DISTRICTS`;
export const GET_DISTRICTS_SUCCESS = `${prefix}GET_DISTRICTS_SUCCESS`;

export const SHOW_DISTRICT_MODAL = `${prefix}SHOW_DISTRICT_MODAL`;
export const CLOSE_DISTRICT_MODAL = `${prefix}CLOSE_DISTRICT_MODAL`;

export const ADD_DISTRICT = `${prefix}ADD_DISTRICT`;
export const EDIT_DISTRICT = `${prefix}EDIT_DISTRICT`;

export const SAVE_DISTRICT = `${prefix}SAVE_DISTRICT`;
export const SAVE_DISTRICT_SUCCESS = `${prefix}SAVE_DISTRICT_SUCCESS`;

export const DELETE_DISTRICT = `${prefix}DELETE_DISTRICT`;
export const DELETE_DISTRICT_SUCCESS = `${prefix}DELETE_DISTRICT_SUCCESS`;

export const GET_DISTRICT_SELECT_LIST = `${prefix}GET_DISTRICT_SELECT_LIST`;
export const GET_DISTRICT_SELECT_LIST_SUCCESS = `${prefix}GET_DISTRICT_SELECT_LIST_SUCCESS`;
export const CHANGE_DISTRICT_SELECT_LIST = `${prefix}CHANGE_DISTRICT_SELECT_LIST`;
