import React from 'react';
import imgDefault from '@app/assets/images/avatar_default.png';
import './index.scss';

const ImageAvatar = ({ width, height, currentImage, onChange }) => {
    const c_width = width || 170;
    const c_height = height || 170;
    currentImage = currentImage || imgDefault;

    const handleChange = async (e) => {
        const file = e.target.files[0];
        if (onChange) {
            onChange(file);
        }

        const img = document.getElementById('imgId');
        const reader = new FileReader();
        reader.onloadend = () => {
            img.src = reader.result;
        };
        reader.readAsDataURL(file);
    };

    return (
        <div
            className="thumb thumb-rounded thumb-slide"
            style={{
                width: '90%',
            }}
        >
            <img
                id="imgId"
                src={currentImage}
                alt=""
                style={{ width: `${c_width}px`, height: `${c_height}px` }}
            />
            <div className="caption">
                <span>
                    <div className="file-input-wrapper">
                        <button
                            type="button"
                            className="btn-file-input btn bg-success-400 btn-icon btn-xs"
                        >
                            <i className="icon-plus2" />
                        </button>
                        <input
                            type="file"
                            name="file"
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </span>
            </div>
        </div>
    );
};
export default ImageAvatar;
