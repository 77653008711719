const prefix = 'CITY_';
export const GET_CITIES = `${prefix}GET_CITIES`;
export const GET_CITIES_SUCCESS = `${prefix}GET_CITIES_SUCCESS`;
export const ADD_CITY = `${prefix}ADD_CITY`;
export const EDIT_CITY = `${prefix}EDIT_CITY`;
export const SAVE_CITY = `${prefix}SAVE_CITY`;
export const SAVE_CITY_SUCCESS = `${prefix}SAVE_CITY_SUCCESS`;
export const DELETE_CITY = `${prefix}DELETE_CITY`;
export const DELETE_CITY_SUCCESS = `${prefix}DELETE_CITY_SUCCESS`;

export const CLOSE_CITY_MODAL = `${prefix}CLOSE_CITY_MODAL`;
export const OPEN_CITY_MODAL = `${prefix}OPEN_CITY_MODAL`;

export const GET_CITY_SELECT_LIST = `${prefix}GET_CITY_SELECT_LIST`;
export const GET_CITY_SELECT_LIST_SUCCESS = `${prefix}GET_CITY_SELECT_LIST_SUCCESS`;
export const CITY_SELECT_CHANGE = `${prefix}CITY_SELECT_CHANGE`;
export const PAGE_CHANGE = `${prefix}PAGE_CHANGE`;

export const CITY_RESET_SELECT_LIST = `${prefix}CITY_RESET_SELECT_LIST`;

export const RESET = `${prefix}RESET`;
