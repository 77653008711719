import axiosService from '@app/services/axiosService';
import {
    API_INCOME_ENDPOINT,
    API_SC_GATEWAY_ENDPOINT,
} from '@app/constants/api';
import { ApiGatewayDataPost } from '@app/models/common/apiGatewayDataPost.model';

const BASE_URL = `${API_INCOME_ENDPOINT}/v1/ScIncome/Service/CostList`;

const LIST_URL = {
    GET_LIST_IN_MONTH: `${BASE_URL}/GetMoneyForMealInMonth`,
    GET_LIST_BY_OID: `${BASE_URL}/GetListByOId`,
    TRANSIT_STATION: `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/TransitStation`,
};

// export const apiGetListInMonth = (dataPost) => {
//     const _dataPost = new ApiGatewayDataPost({
//         service: 'Income',
//         controller: 'CostList',
//         action: 'GetListByOId',
//         dataPost: {
//             orgId: oId,
//             type,
//         },
//     });
//     return axiosService.post(LIST_URL.GET_LIST_IN_MONTH, dataPost);
// };

/**
 * Lấy danh sách các khoản thu/chi theo Id tổ chức
 * @param {string} oId: Id tổ chức
 * @param {int} type: Loại khoản (Loại chi phí: THU/CHI)
 * @returns
 */
// export const apiGetList = (oId, type) => {
//     const _dataPost = new ApiGatewayDataPost({
//         service: 'Income',
//         controller: 'CostList',
//         action: 'GetListByOId',
//         dataPost: {
//             orgId: oId,
//             type,
//         },
//     });
//     return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
//     // return axiosService.get(`${LIST_URL.GET_LIST_BY_OID}/${oId}/${type}`);
// };

const _icCostApis = {
    getList(oId, type) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'CostList',
            action: 'GetListByOId',
            dataPost: {
                orgId: oId,
                type,
            },
        });
        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    },
    getListInMonth(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'CostList',
            action: 'GetListInMonth',
            dataPost: {
                ...dataPost,
                orgId: dataPost.oId,
            },
        });
        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
        //return axiosService.post(LIST_URL.GET_LIST_IN_MONTH, dataPost);
    },
};

export default _icCostApis;
