import * as types from './country.constant';
import { LocationModel } from '../../../models/location/location.model';
import _ from 'lodash';

const initialState = {
    listCountry: [],
    currentPage: 1,
    isShow: false,
    total: 0,
    editItem: new LocationModel(),
    selectListCountry: [],
    currentCountrySelect: -1,
    id: '',
};

const countryReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case types.GET_LIST_COUNTRY_SUCCCESS: {
            const { total, list } = payload.data.data;
            return {
                ...state,
                listCountry: list,
                total,
            };
        }
        case types.ADD_NEW: {
            const { listCountry } = state;
            const sortOrder =
                listCountry && listCountry.length > 0
                    ? listCountry[listCountry.length - 1].l_sortorder + 1
                    : 1;
            return {
                ...state,
                isShow: true,
                editItem: new LocationModel({
                    l_sortorder: sortOrder,
                }),
            };
        }
        case types.TOGGLE_MODAL: {
            const { show } = payload;
            return {
                ...state,
                isShow: show,
            };
        }
        case types.SAVE_COUNTRY_SUCCESS: {
            const { data } = payload.data;
            const { isAdd } = payload;
            let { listCountry, total } = state;

            if (isAdd) {
                total += 1;
                listCountry = [...listCountry, data];
            } else {
                listCountry = listCountry.map((item) => {
                    if (item.id === data.id) {
                        item = { item, ...data };
                    }
                    return item;
                });
            }
            listCountry = _.orderBy(listCountry, ['l_sortorder'], ['asc']);
            return {
                ...state,
                isShow: isAdd,
                total,
                listCountry,
                editItem: new LocationModel({
                    l_sortorder:
                        listCountry[listCountry.length - 1].l_sortorder + 1,
                }),
            };
        }
        case types.EDIT_COUNTRY: {
            const { data } = payload;
            return {
                ...state,
                editItem: new LocationModel(data),
                isShow: true,
            };
        }
        case types.DELETE_COUNTRY_SUCCESS: {
            const { id } = payload;
            let { listCountry, currentPage } = state;
            listCountry = _.orderBy(
                listCountry.filter((item) => item.l_id !== id),
                ['l_sortorder'],
                ['asc']
            );
            if (currentPage !== 1 && listCountry.length === 0) {
                currentPage -= 1;
            }
            return {
                ...state,
                listCountry,
                total: state.total - 1,
                currentPage,
            };
        }
        case types.PAGE_CHANGE: {
            const { page } = payload;
            return {
                ...state,
                currentPage: page,
            };
        }

        case types.GET_LIST_SELECT_COUNTRY_SUCCESS: {
            const { data, defaultValue } = payload;
            let { selectListCountry, currentCountrySelect } = state;
            selectListCountry = _.orderBy(data, ['l_sortorder'], ['asc']);
            if (defaultValue) {
                currentCountrySelect = defaultValue;
            } else {
                currentCountrySelect =
                    selectListCountry &&
                    selectListCountry.length > 0 &&
                    selectListCountry[0].l_id;
            }

            return {
                ...state,
                selectListCountry,
                currentCountrySelect,
            };
        }

        case types.COUNTRY_SELECT_CHANGE: {
            const { id } = payload;
            return {
                ...state,
                currentCountrySelect: id,
                id,
            };
        }
        case types.RESET_LIST_SELECT_COUNTRY: {
            return {
                ...state,
                selectListCountry: [],
                id: '',
                currentCountrySelect: -1,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default countryReducer;
