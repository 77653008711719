import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import RequiredIcon from '@app/components/Layout/UI/Icons/RequiredIcon';

const propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    requiredChar: PropTypes.string,
};

const RenderTextField = ({
    input,
    label,
    value,
    readOnly,
    requiredChar,
    meta: { touched, error, warning },
    ...custome
}) => (
    <div>
        {label ? (
            <label>
                {/* <h6 className="panel-title sc-title"> */}
                <h6 className="panel-title">
                    {label}
                    {requiredChar ? <RequiredIcon /> : null}
                </h6>
            </label>
        ) : null}

        <div className="contain-error">
            <input
                {...input}
                {...custome}
                readOnly={readOnly}
                onChange={(e) => input.onChange(e)}
            />
        </div>
        {touched &&
            ((error && (
                <small className="text-danger text-validate">{error}</small>
            )) ||
                (warning && (
                    <small className="text-warning text-validate">
                        {warning}
                    </small>
                )))}
    </div>
);
RenderTextField.propTypes = propTypes;

export default RenderTextField;
