import axiosService from '@app/services/axiosService';
import { API_SCHOOL_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_SCHOOL_ENDPOINT}/v1/BcnSchool/schoolPropertySetting`;
const BASE_URL_V1 = `${API_SCHOOL_ENDPOINT}/v1/schoolPropertySetting`;
const BASE_URL_V2 = `${API_SCHOOL_ENDPOINT}/v1/schoolProperty`;
const BASE_URL_V3 = `${API_SCHOOL_ENDPOINT}/v1/schoolInvoiceForm`;

const LIST_URL = {
    GET_BY_PROP: `${BASE_URL}/GetByProp`,
    GET_BY_OID: `${BASE_URL_V1}/GetSettingByOId`,
    GET_PROPERTY: `${BASE_URL_V2}/GetList`,
    COPY_SETTING: `${BASE_URL_V1}/CopySetting`,
    GET_SELECT_LIST: `${BASE_URL_V3}/GetSelectList`,
};

export const apiGetByProp = (oId, propCode) =>
    axiosService.get(`${LIST_URL.GET_BY_PROP}/${oId}/${propCode}`);

export const apiGetSchoolInfoByOId = (oId) =>
    axiosService.get(`${LIST_URL.GET_BY_OID}/${oId}`);

export const apiGetUnits = () =>
    axiosService.get(`${LIST_URL.GET_BY_OID}/${oId}`);

export const apiGetSchoolProperty = () =>
    axiosService.post(LIST_URL.GET_PROPERTY);

export const apiCopySettingSchool = (data) =>
    axiosService.post(LIST_URL.COPY_SETTING, data);

export const apiGetSchoolInvoiceForm = () =>
    axiosService.get(LIST_URL.GET_SELECT_LIST);
