import React from 'react';

const ModalTransHistoryList = (props) => (
    <table className="table table-togglable table-hover table-bordered">
        <thead className="grey-light">
            <tr>
                <th className="text-center" style={{ width: '100px' }}>
                    Ngày tạo
                </th>
                <th className="text-center" style={{ width: '15%' }}>
                    Mã đơn hàng
                </th>
                <th className="text-center" data-hide="phone">
                    Ghi chú
                </th>
                <th className="text-center" style={{ width: '8%' }}>
                    Tổng tiền
                </th>
                <th
                    className="text-center"
                    data-hide="phone"
                    style={{ width: '8%' }}
                >
                    Tình trạng
                </th>
            </tr>
        </thead>
        <tbody>{props.children}</tbody>
    </table>
);

export default ModalTransHistoryList;
