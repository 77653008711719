import * as types from './constansts';

/**
 * Lấy danh sách cần lập bảng điểm danh
 * @param {Object} dataPost : dữ liệu gửi lên
 * @param {string} dataPost.o_id : Id tổ chức
 * @param {number} dataPost.sy_id : Mã năm học
 */
export const doGetClassList = (dataPost) => ({
    type: types.GET_CLASS_LIST,
    payload: {
        dataPost,
    },
});
