import React, { Suspense } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import { Provider } from 'react-redux';
import configStore from './redux/store/storeConfig';
import Login from './pages/Login';
import AdminPage from './components/Layout/AdminPage';
import './i18n';
import './App.scss';

const store = configStore();
const App = () => (
    <Suspense fallback={null}>
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/" component={AdminPage} />
                </Switch>
            </Router>
        </Provider>
    </Suspense>
);

export default App;
