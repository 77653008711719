/* eslint-disable no-return-assign */
import React, { Fragment } from 'react';
import SchoolListSelect from '@app/components/_Share/Select/SchoolListSelect/Dynamic';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import KidGroupListSelect from '@app/components/_Share/Select/KidGroupSelect/Dynamic';
import SchoolSelector from '@app/redux/school/school.selector';
import { useSelector } from 'react-redux';

const CopyMenuFilter = ({
    onChangeKidGroup,
    onChangeKidGroupSample,
    onChangeSchool,
    onSaveMenu,
    onOpenImportCsvMenu,
    rowSelected,
    idToSchool,
}) => {
    const currentSchool = useSelector(SchoolSelector.currentSchool);

    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-md-3 col-xs-6">
                            <div className="form-group">
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        Nhóm thực đơn mẫu
                                    </span>
                                    <KidGroupListSelect
                                        oId={currentSchool}
                                        onChange={(value) =>
                                            onChangeKidGroupSample(value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5 col-sm-12">
                            <div className="form-group">
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        Đơn vị cần tạo
                                    </span>
                                    <SchoolListSelect
                                        onChange={onChangeSchool}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-xs-6">
                            <div className="form-group">
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        Nhóm tuổi cần tạo
                                    </span>
                                    <KidGroupListSelect
                                        oId={idToSchool}
                                        onChange={(value) =>
                                            onChangeKidGroup(value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="form-group">
                                <div className="btn-group">
                                    <ButtonFilterAction
                                        labelButton="Lưu thực đơn"
                                        buttonType={BUTTON_ACTION_TYPE.SAVE}
                                        onClick={onSaveMenu}
                                        disabled={rowSelected.length === 0}
                                    />
                                    <ButtonFilterAction
                                        labelButton="Thêm thực đơn từ CSV"
                                        buttonType={
                                            BUTTON_ACTION_TYPE.EXPORT_FILE
                                        }
                                        onClick={onOpenImportCsvMenu}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CopyMenuFilter;
