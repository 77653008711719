import { Switch } from 'antd';
import React from 'react';
import ButtonTableAction from '@app/components/UI/Button/ButtonTableAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

const AgencyItem = ({
    sortOrder,
    agency,
    onListSchool,
    onTransHistory,
    onConnectionHistory,
    onChangeSwitch,
}) => {
    const showPositionAndPhone = (_position, _cellPhone) => {
        if (_position && _cellPhone) return `${_position} - ${_cellPhone}`;

        if (_position) return _position;
        if (_cellPhone) return _cellPhone;
    };

    return (
        <tr>
            <td className="text-center">{sortOrder}</td>
            <td>
                {agency?.o_name}
                <span className="display-block text-muted">
                    {agency?.oa_addressline1}
                </span>
            </td>
            <td>
                {agency?.o_representative}
                <div className="display-block text-muted">
                    {showPositionAndPhone(
                        agency?.o_position,
                        agency?.o_cellphone
                    )}
                </div>
            </td>
            <td>
                {agency?.o_email}
                <div className="display-block text-muted">
                    {agency?.o_telephone}
                </div>
            </td>
            <td className="text-right">{agency?.totalSchool}</td>
            <td className="text-center" data-label="Đang hoạt động">
                <div className="checkbox checkbox-switchery no-margin-bottom">
                    <Switch
                        checked={agency.o_status === 1}
                        onChange={(s) => onChangeSwitch(s, agency)}
                    />
                </div>
            </td>
            <td className="text-center">
                <div className="btn-table-group">
                    <ButtonTableAction
                        buttonType={BUTTON_ACTION_TYPE.OFFICE}
                        onClick={() => onListSchool(agency)}
                        tootTipTitle="Danh sách đơn vị"
                    />
                    <ButtonTableAction
                        buttonType={BUTTON_ACTION_TYPE.CART}
                        onClick={() => onTransHistory(agency)}
                        tootTipTitle="Lịch sử giao dịch"
                    />
                    <ButtonTableAction
                        buttonType={BUTTON_ACTION_TYPE.HISTORY_V2}
                        onClick={() => onConnectionHistory(agency)}
                        tootTipTitle="Lịch sử kết nối"
                    />
                </div>
            </td>
        </tr>
    );
};

export default AgencyItem;
