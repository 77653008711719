// import CrmPagination from '@app/components/_Share/Pagination';
import React from 'react';

const AgencyList = ({ children }) => (
    <div className="row">
        <div className="col-lg-12">
            {/* Basic panel controls */}
            <div className="panel panel-white">
                <table className="table table-togglable table-hover table-bordered table-striped">
                    <thead className="grey-light">
                        <tr>
                            <th
                                className="text-center"
                                style={{ width: '35px' }}
                            >
                                STT
                            </th>
                            <th
                                className="text-center"
                                style={{ width: '40%' }}
                            >
                                Tên đại lý
                                <span className="display-block text-muted">
                                    Địa chỉ
                                </span>
                            </th>
                            <th
                                className="text-center"
                                style={{ width: '15%' }}
                            >
                                Người đại diện
                                <span className="display-block text-muted">
                                    Chức vụ - Di động
                                </span>
                            </th>
                            <th
                                className="text-center"
                                style={{ width: '15%' }}
                            >
                                Email
                                <span className="display-block text-muted">
                                    Điện thoại
                                </span>
                            </th>
                            <th
                                className="text-center"
                                style={{ width: '100px' }}
                            >
                                Số trường đăng ký
                            </th>
                            <th
                                className="text-center"
                                style={{ width: '6%' }}
                                data-hide="phone"
                            >
                                Đang hoạt động
                            </th>
                            <th
                                className="text-center"
                                style={{ width: '80px' }}
                            >
                                Hành động
                            </th>
                        </tr>
                    </thead>
                    <tbody>{children}</tbody>
                </table>
            </div>
        </div>
    </div>
);

export default AgencyList;
