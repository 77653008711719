/* eslint-disable no-await-in-loop */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-const-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-confusing-arrow */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import FoodkidItem from './Item';
import _schoolSelector from '@app/redux/school/school.selector';
import _foodkidApis from '@app/apis/foodkid/foodkid';
import { REPORT_TYPE, STATUS_PROCESS } from '@app/constants/index';
import _othersSelector from '@app/redux/others/selector';
import _menuExportPDFService from './_exportMenu';
import _marketExportPDFService from './_exportMarket';
import _setupMarketExportPDFService from './_exportSetupMarket';
import _supplierExportPDFService from './_exportSupplier';
import _stepExportPDFService from './_exportStep';
import _syntheticNutritionWeekExportPDFService from './_exportWeek';
import _receiptInExportPDFService from './_exportReceiptIn';
import _receiptExportPDFService from './_exportReceipt';
import _receiptTotalExportPDFService from './_exportReceiptTotal';
import _billOfSaleExportPDFService from './_exportBillOfSale';
import _weeklyMenuExportPDFService from './_exportWeeklyMenu';

import _ from 'lodash';
import moment from 'moment';
import update from 'immutability-helper';
import { numberToPrice } from '@app/utils/utils';
import _fkMenuApis from '@app/apis/foodkid/menu.api';

const getDaysArray = (year, month, dateFrom, dateTo) => {
    const date = new Date(year, month, dateFrom);
    const result = [];
    let i = date.getDate();
    while (i <= dateTo) {
        result.push(moment(date.setDate(i)).unix());
        i += 1;
    }
    return result;
};

const showWeeks = (_datefrom, _dateto) => {
    let startWeekFrom = _datefrom;
    let lastWeekFrom = moment.unix(_datefrom).weekday(5).endOf('day').unix();

    const out = [];

    if (lastWeekFrom > _dateto) {
        out.push({ start: startWeekFrom, end: _dateto });
        return out;
    }

    while (startWeekFrom <= _dateto) {
        out.push({ start: startWeekFrom, end: lastWeekFrom });

        const dayDateChange = moment.unix(lastWeekFrom).date() + 2;

        startWeekFrom = moment
            .unix(lastWeekFrom)
            .set('date', dayDateChange)
            .startOf('day')
            .unix();

        lastWeekFrom = moment
            .unix(startWeekFrom)
            .weekday(5)
            .endOf('day')
            .unix();

        if (lastWeekFrom >= _dateto) {
            lastWeekFrom = _dateto;
        }
    }

    return out;
};

const FookidList = ({
    yearId,
    month,
    dateTo,
    dateFrom,
    countClickExport,
    kidGroupIds,
    listReport,
    onChangeListReport,
}) => {
    const menulist = useRef([]);
    const menuSchoolFoods = useRef([]);
    const menuServices = useRef([]);
    const menudishs = useRef([]);
    const proFoods = useRef([]);
    const schoolFoods = useRef([]);
    // const receiptInList = useRef([]);

    const ageGroup = useSelector(_othersSelector.ageGroup);
    const kidGroup = useSelector(_othersSelector.kidGroup)?.filter((o) =>
        kidGroupIds?.find((x) => x === o.kg_id)
    );
    const units = useSelector(_othersSelector.units);
    const schoolInfo = useSelector(_othersSelector.schoolInfo);
    const locationInfo = useSelector(_othersSelector.locationInfo);
    const organizationInfo = useSelector(_othersSelector.organizationInfo);
    const mealService = useSelector(_othersSelector.mealService);
    const supplier = useSelector(_othersSelector.supplier);
    const dish = useSelector(_othersSelector.dish);
    const inventories = useSelector(_othersSelector.inventories);
    const packaging = useSelector(_othersSelector.packaging);
    const invoiceForm = useSelector(_othersSelector.invoiceForm);

    const oId = useSelector(_schoolSelector.currentSchool);

    const onSetStatus = (listItem, statusValue) => {
        const _listId = listItem.map((o) => o.id);

        onChangeListReport((data) =>
            data.map((o) => {
                if (_.includes(_listId, o.id)) {
                    o = {
                        ...o,
                        status: statusValue,
                    };
                    if (statusValue === STATUS_PROCESS.DONE) {
                        o = {
                            ...o,
                            isCheck: false,
                        };
                    }
                }
                return o;
            })
        );
    };

    const getData = async (_oId, _dateFrom, _dateTo) => {
        const listCheck = listReport.filter(
            (o) => o.isCheck && o.category === 1
        );
        if (listCheck.length === 0) return;

        onSetStatus(listCheck, STATUS_PROCESS.AWAITING);
        await _fkMenuApis
            .getMenuByDateAndKidGroup({
                ids: kidGroupIds,
                // year: yearId,
                // month,
                dateFrom: _dateFrom,
                dateTo: _dateTo,
                o_id: _oId,
                typeGetData: 1,
            })
            .then(async ({ status, data }) => {
                if (status === 200) {
                    const dataRes = data.data;
                    menulist.current = dataRes.list;
                    menuSchoolFoods.current = dataRes.menuSchoolFoods;
                    menuServices.current = dataRes.menuServices;
                    menudishs.current = dataRes.menuDishs;
                    proFoods.current = dataRes.proFoods;
                    schoolFoods.current = dataRes.schoolFoods;
                } else {
                    menulist.current = [];
                    menuSchoolFoods.current = [];
                    menuServices.current = [];
                    menudishs.current = [];
                    proFoods.current = [];
                    schoolFoods.current = [];
                }
            });
        // eslint-disable-next-line no-restricted-syntax
        for (const elem of listCheck) {
            onSetStatus([elem], STATUS_PROCESS.PROCESSING);
            let isDone = false;
            switch (elem.code) {
                case 'thuc_don_trong_ngay': {
                    if (menulist.current.length > 0) {
                        const _cloneProFood = _.cloneDeep(proFoods.current);
                        const _cloneMenuSchoolFood = _.cloneDeep(
                            menuSchoolFoods.current
                        );
                        const _cloneSchoolFood = _.cloneDeep(
                            schoolFoods.current
                        );
                        const _cloneMenuDish = _.cloneDeep(menudishs?.current);
                        const _cloneMenuService = _.cloneDeep(
                            menuServices?.current
                        );

                        for (const menu of menulist.current) {
                            // eslint-disable-next-line no-await-in-loop
                            isDone = await _menuExportPDFService.doExportMenu(
                                _cloneProFood,
                                _cloneSchoolFood,
                                _cloneMenuSchoolFood?.filter(
                                    (o) => o.m_id === menu.m_id
                                ),
                                units,
                                menu,
                                kidGroup,
                                _cloneMenuDish?.filter(
                                    (o) => o.m_id === menu.m_id
                                ),
                                ageGroup,
                                _cloneMenuService?.filter(
                                    (o) => o.m_id === menu.m_id
                                ),
                                locationInfo,
                                schoolInfo,
                                organizationInfo,
                                mealService,
                                'TDN'
                            );
                        }
                    } else {
                        isDone = true;
                    }
                    break;
                }
                case 'phieu_ke_cho': {
                    {
                        if (menulist.current.length > 0) {
                            const _cloneProFood = _.cloneDeep(proFoods.current);
                            const _cloneMenuSchoolFood = _.cloneDeep(
                                menuSchoolFoods.current
                            );
                            const _cloneSchoolFood = _.cloneDeep(
                                schoolFoods.current
                            );
                            const _cloneMenuList = _.cloneDeep(
                                menulist.current
                            );

                            const menus = _.chain(_cloneMenuList)
                                .groupBy('m_datefounded')
                                .map((value, key) => ({
                                    m_datefounded: key,
                                    ids: value.map((o) => o.m_id),
                                    datas: value,
                                }))
                                .value();
                            for (const menu of menus) {
                                // eslint-disable-next-line no-await-in-loop
                                isDone = await _marketExportPDFService.doExport(
                                    _cloneProFood,
                                    _cloneSchoolFood,
                                    _cloneMenuSchoolFood?.filter((o) =>
                                        menu.ids.find((x) => x === o.m_id)
                                    ),
                                    units,
                                    menu.datas,
                                    kidGroup,
                                    locationInfo,
                                    schoolInfo,
                                    organizationInfo,
                                    1,
                                    'PKC'
                                );
                            }
                        } else {
                            isDone = true;
                        }
                    }
                    break;
                }
                case 'phieu_tiep_pham': {
                    {
                        if (menulist.current.length > 0) {
                            const _cloneMenuSchoolFood = _.cloneDeep(
                                menuSchoolFoods.current
                            );
                            const _cloneSchoolFood = _.cloneDeep(
                                schoolFoods.current
                            );
                            const _cloneMenuList = _.cloneDeep(
                                menulist.current
                            );
                            const menus = _.chain(_cloneMenuList)
                                .groupBy('m_datefounded')
                                .map((value, key) => ({
                                    m_datefounded: key,
                                    ids: value.map((o) => o.m_id),
                                    datas: value,
                                }))
                                .value();
                            for (const menu of menus) {
                                // eslint-disable-next-line no-await-in-loop
                                isDone =
                                    await _supplierExportPDFService.doExport(
                                        _cloneSchoolFood,
                                        _cloneMenuSchoolFood?.filter((o) =>
                                            menu.ids.find((x) => x === o.m_id)
                                        ),
                                        units,
                                        menu.datas,
                                        locationInfo,
                                        schoolInfo,
                                        organizationInfo,
                                        1,
                                        'PTP'
                                    );
                            }
                        } else {
                            isDone = true;
                        }
                    }
                    break;
                }
                case 'so_kiem_thuc':
                case 'so_kiem_thuc_trong': {
                    {
                        if (menulist.current.length > 0) {
                            const _cloneMenuList = _.cloneDeep(
                                menulist.current
                            );
                            const _cloneMenuSchoolFood = _.cloneDeep(
                                menuSchoolFoods.current
                            );
                            const _cloneSchoolFood = _.cloneDeep(
                                schoolFoods.current
                            );
                            const _cloneMenuDish = _.cloneDeep(
                                menudishs?.current
                            );

                            const menus = _.chain(_cloneMenuList)
                                .groupBy('m_datefounded')
                                .map((value, key) => ({
                                    m_datefounded: key,
                                    ids: value.map((o) => o.m_id),
                                    datas: value,
                                }))
                                .value();

                            for (const menu of menus) {
                                const isEmpty =
                                    elem.code === 'so_kiem_thuc_trong';

                                // eslint-disable-next-line no-await-in-loop
                                isDone = await _stepExportPDFService.doExport(
                                    _cloneMenuDish.filter((o) =>
                                        menu.datas.find(
                                            (x) => x.m_id === o.m_id
                                        )
                                    ),
                                    _cloneSchoolFood,
                                    _cloneMenuSchoolFood?.filter((o) =>
                                        menu.ids.find((x) => x === o.m_id)
                                    ),
                                    units,
                                    menu.datas,
                                    locationInfo,
                                    schoolInfo,
                                    organizationInfo,
                                    supplier,
                                    dish,
                                    packaging,
                                    'TP3B',
                                    isEmpty
                                );
                            }
                        } else {
                            isDone = true;
                        }
                    }
                    break;
                }
                case 'bang_tong_hop_khau_phan_dinh_duong_tuan': {
                    {
                        if (menulist.current.length > 0) {
                            const _cloneProFood = _.cloneDeep(proFoods.current);
                            const _cloneMenuSchoolFood = _.cloneDeep(
                                menuSchoolFoods.current
                            );
                            const _cloneSchoolFood = _.cloneDeep(
                                schoolFoods.current
                            );
                            const _cloneMenuList = _.cloneDeep(
                                menulist.current
                            );
                            const _cloneMenuDish = _.cloneDeep(
                                menudishs.current
                            );

                            const dates = showWeeks(dateFrom, dateTo);
                            for (const date of dates) {
                                const check = menulist.current?.filter(
                                    (o) =>
                                        o.m_datefounded <= date.end &&
                                        o.m_datefounded >= date.start
                                );

                                if (check.length > 0) {
                                    for (const kg of kidGroup) {
                                        const ids = check
                                            .filter((o) => o.kg_id === kg.kg_id)
                                            .map((o) => o.m_id);
                                        isDone =
                                            await _syntheticNutritionWeekExportPDFService.doExport(
                                                _cloneProFood,
                                                _cloneSchoolFood,
                                                _cloneMenuSchoolFood?.filter(
                                                    (o) =>
                                                        ids.find(
                                                            (x) => x === o.m_id
                                                        )
                                                ),
                                                _cloneMenuList?.filter(
                                                    (o) =>
                                                        o.m_datefounded <=
                                                            date.end &&
                                                        o.m_datefounded >=
                                                            date.start &&
                                                        o.kg_id === kg.kg_id
                                                ),
                                                _cloneMenuDish?.filter((o) =>
                                                    ids.find(
                                                        (x) => x === o.m_id
                                                    )
                                                ),
                                                dish,
                                                kg,
                                                locationInfo,
                                                schoolInfo,
                                                organizationInfo,
                                                1,
                                                date.start,
                                                date.end
                                            );
                                    }
                                }
                            }
                        } else {
                            isDone = true;
                        }
                    }
                    break;
                }
                case 'thuc_don_tuan': {
                    if (menulist.current.length > 0) {
                        const dates = showWeeks(dateFrom, dateTo);

                        for (const date of dates) {
                            const check = menulist.current?.filter(
                                (o) =>
                                    o.m_datefounded <= date.end &&
                                    o.m_datefounded >= date.start
                            );

                            if (check.length > 0) {
                                for (const kg of kidGroup) {
                                    const ids = check
                                        .filter((o) => o.kg_id === kg.kg_id)
                                        .map((o) => o.m_id);
                                    isDone =
                                        await _weeklyMenuExportPDFService.doExport(
                                            menulist.current?.filter(
                                                (o) =>
                                                    o.m_datefounded <=
                                                        date.end &&
                                                    o.m_datefounded >=
                                                        date.start &&
                                                    o.kg_id === kg.kg_id
                                            ),
                                            menudishs.current?.filter((o) =>
                                                ids.find((x) => x === o.m_id)
                                            ),
                                            dish,
                                            kg,
                                            locationInfo,
                                            schoolInfo,
                                            organizationInfo,
                                            1,
                                            date.start,
                                            date.end,
                                            'TDT'
                                        );
                                }
                            }
                        }
                    } else {
                        isDone = true;
                    }
                    break;
                }
                case 'so_tinh_tien_cho': {
                    for (const date of getDaysArray(
                        moment.unix(dateFrom).year(),
                        moment.unix(dateFrom).month(),
                        moment.unix(dateFrom).date(),
                        moment.unix(dateTo).date()
                    )) {
                        await _foodkidApis
                            .getMarketCashBook({ oId, dateFound: date })
                            .then(async ({ status, data }) => {
                                if (status === 200) {
                                    if (data.data.list.length > 0) {
                                        const menus = data.data.menus.filter(
                                            (o) =>
                                                kidGroup.find(
                                                    (x) => x.kg_id === o.kg_id
                                                )
                                        );
                                        const marketMenuSchoolFoods =
                                            data.data.list?.filter((o) =>
                                                kidGroup.find(
                                                    (x) => x.kg_id === o.kg_id
                                                )
                                            );
                                        await _setupMarketExportPDFService.doExport(
                                            schoolFoods.current,
                                            marketMenuSchoolFoods
                                                ?.filter(
                                                    (o) =>
                                                        o.msf_useinwarehouse ===
                                                        false
                                                )
                                                .reduce((r, a) => {
                                                    if (r.length === 0) {
                                                        r = update(r, {
                                                            $push: [
                                                                {
                                                                    id: a.sf_id,
                                                                    name: a.sf_name,
                                                                    kg_id: a.kg_id,
                                                                    price: a.msf_priceafter,
                                                                    items: [].concat(
                                                                        a
                                                                    ),
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        const item = r.find(
                                                            (o) =>
                                                                o.id === a.sf_id
                                                        );
                                                        if (
                                                            item !== undefined
                                                        ) {
                                                            r.map((f) => {
                                                                if (
                                                                    f.id ===
                                                                    a.sf_id
                                                                ) {
                                                                    f.items.push(
                                                                        a
                                                                    );
                                                                }
                                                                return f;
                                                            });
                                                        } else {
                                                            r = update(r, {
                                                                $push: [
                                                                    {
                                                                        id: a.sf_id,
                                                                        name: a.sf_name,
                                                                        kg_id: a.kg_id,
                                                                        price: a.msf_priceafter,
                                                                        items: [].concat(
                                                                            a
                                                                        ),
                                                                    },
                                                                ],
                                                            });
                                                        }
                                                    }
                                                    return r;
                                                }, []),
                                            marketMenuSchoolFoods
                                                ?.filter(
                                                    (o) =>
                                                        o.msf_useinwarehouse ===
                                                        true
                                                )
                                                .reduce((r, a) => {
                                                    if (r.length === 0) {
                                                        r = update(r, {
                                                            $push: [
                                                                {
                                                                    id: a.sf_id,
                                                                    name: a.sf_name,
                                                                    kg_id: a.kg_id,
                                                                    price: a.msf_priceafter,
                                                                    items: [].concat(
                                                                        a
                                                                    ),
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        const item = r.find(
                                                            (o) =>
                                                                o.id === a.sf_id
                                                        );
                                                        if (
                                                            item !== undefined
                                                        ) {
                                                            r.map((f) => {
                                                                if (
                                                                    f.id ===
                                                                    a.sf_id
                                                                ) {
                                                                    f.items.push(
                                                                        a
                                                                    );
                                                                }
                                                                return f;
                                                            });
                                                        } else {
                                                            r = update(r, {
                                                                $push: [
                                                                    {
                                                                        id: a.sf_id,
                                                                        name: a.sf_name,
                                                                        kg_id: a.kg_id,
                                                                        price: a.msf_priceafter,
                                                                        items: [].concat(
                                                                            a
                                                                        ),
                                                                    },
                                                                ],
                                                            });
                                                        }
                                                    }
                                                    return r;
                                                }, []),
                                            data.data.difference?.filter((o) =>
                                                kidGroup.find(
                                                    (x) => x.kg_id === o.kg_id
                                                )
                                            ),
                                            data.data.difference_start_month?.filter(
                                                (o) =>
                                                    kidGroup.find(
                                                        (x) =>
                                                            x.kg_id === o.kg_id
                                                    )
                                            ),
                                            data.data.report?.filter((o) =>
                                                kidGroup.find(
                                                    (x) => x.kg_id === o.kg_id
                                                )
                                            ),
                                            data.data.service_report?.filter(
                                                (o) =>
                                                    kidGroup.find(
                                                        (x) =>
                                                            x.kg_id === o.kg_id
                                                    )
                                            ),
                                            data.data.services?.filter((o) =>
                                                kidGroup.find(
                                                    (x) => x.kg_id === o.kg_id
                                                )
                                            ),
                                            units,
                                            menus,
                                            kidGroup,
                                            locationInfo,
                                            schoolInfo,
                                            organizationInfo,
                                            date,
                                            'STTC'
                                        );
                                    }
                                }
                            });
                    }

                    isDone = true;
                    break;
                }
                case 'phieu_nhap_kho': {
                    _foodkidApis
                        .getListImportVoucher({
                            o_id: oId,
                            id: -1,
                            dateFrom,
                            dateTo,
                            typeGetData: 1,
                            // month,
                            // year: yearId + 1,
                        })
                        .then(async ({ status, data }) => {
                            if (status === 200) {
                                if (data.data.length > 0) {
                                    for (const item of data.data) {
                                        await _receiptInExportPDFService.doExport(
                                            schoolFoods.current,
                                            item,
                                            units,
                                            inventories,
                                            schoolInfo,
                                            organizationInfo,
                                            1,
                                            'PNK'
                                        );
                                    }
                                }
                            }
                        });
                    isDone = true;
                    break;
                }
                case 'phieu_xuat_kho': {
                    _foodkidApis
                        .getListExportVoucher({
                            o_id: oId,
                            id: -1,
                            // month,
                            // year: yearId + 1,
                            dateFrom,
                            dateTo,
                            typeGetData: 1,
                        })
                        .then(async ({ status, data }) => {
                            if (status === 200) {
                                if (data.data.length > 0) {
                                    for (const item of data.data) {
                                        await _receiptInExportPDFService.doExport(
                                            schoolFoods.current,
                                            item,
                                            units,
                                            inventories,
                                            schoolInfo,
                                            organizationInfo,
                                            2,
                                            'PXK'
                                        );
                                    }
                                }
                            }
                        });
                    isDone = true;
                    break;
                }
                case 'the_kho': {
                    const { status, data } =
                        await _foodkidApis.getListWarehouseCard({
                            o_id: oId,
                            dateFrom,
                            dateTo,
                            typeGetData: 1,
                        });
                    const dataApi = data.data;

                    if (status !== 200 || !dataApi.list?.length) {
                        onSetStatus([elem], STATUS_PROCESS.DONE);
                        // eslint-disable-next-line no-continue
                        continue;
                    }

                    for (const inventory of inventories) {
                        const datas = dataApi.list.filter(
                            (o) => o.w_id === inventory.w_id
                        );
                        if (datas.length === 0) {
                            // eslint-disable-next-line no-continue
                            continue;
                        }

                        const receipts = _.chain(datas)
                            .groupBy('sf_id')
                            .map((value, key) => ({
                                sf_id: key,
                                datas: value,
                            }))
                            .value();

                        for (const item of receipts) {
                            const beforeInfo = dataApi.listBefore.find(
                                (o) =>
                                    o.sf_id === parseInt(item.sf_id) &&
                                    o.w_id === inventory.w_id
                            );

                            await _receiptExportPDFService.doExport(
                                item.datas,
                                beforeInfo?.soLuongTon || 0,
                                beforeInfo?.tongTienTon || 0,
                                schoolFoods.current,
                                units,
                                item.sf_id,
                                moment.unix(dateFrom).year(),
                                moment.unix(dateFrom).month() + 1,
                                inventory,
                                locationInfo,
                                schoolInfo,
                                organizationInfo
                            );
                        }
                    }
                    isDone = true;
                    break;
                }
                case 'tong_hop_the_kho': {
                    _foodkidApis
                        .getSummaryWarehouseCard({
                            o_id: oId,
                            dateFrom,
                            dateTo,
                            typeGetData: 1,
                        })
                        .then(async ({ status, data }) => {
                            if (status === 200) {
                                const dataApi = data.data;
                                if (dataApi.list?.length) {
                                    for (const inventory of inventories) {
                                        const foodReceiptTotalList =
                                            dataApi.list.filter(
                                                (o) => o.w_id === inventory.w_id
                                            );
                                        const foodReceiptBeforeList =
                                            dataApi.dataBefore.filter(
                                                (o) => o.w_id === inventory.w_id
                                            );
                                        if (foodReceiptTotalList.length > 0) {
                                            await _receiptTotalExportPDFService.doExport(
                                                foodReceiptTotalList,
                                                moment.unix(dateFrom).year(),
                                                moment.unix(dateFrom).month() +
                                                    1,
                                                foodReceiptBeforeList,
                                                locationInfo,
                                                schoolInfo,
                                                organizationInfo,
                                                inventory,
                                                schoolFoods.current
                                            );
                                        }
                                    }
                                }
                            }
                        });
                    isDone = true;
                    break;
                }
                case 'hoa_don_ban_hang': {
                    if (schoolInfo?.hoa_don_nha_cung_cap) {
                        if (menulist.current.length > 0) {
                            const _cloneMenuSchoolFood = _.cloneDeep(
                                menuSchoolFoods.current
                            );
                            const _cloneSchoolFood = _.cloneDeep(
                                schoolFoods.current
                            );
                            const _cloneMenuList = _.cloneDeep(
                                menulist.current
                            );

                            /** Gộp những thực đơn trong cùng 1 ngày */
                            const menuGroupBy = _.chain(_cloneMenuList)
                                .groupBy('m_datefounded')
                                .map((value, key) => ({
                                    m_datefounded: key,
                                    ids: value.map((o) => o.m_id),
                                    datas: value,
                                }))
                                .value();

                            /** Chi thực đơn theo nhà cung cấp */
                            const _menu = menuGroupBy.reduce((rsList, item) => {
                                const listMenuShoolFood = _.chain(
                                    _cloneMenuSchoolFood
                                        ?.filter(
                                            (o) =>
                                                item.ids.includes(o.m_id) &&
                                                !o.msf_useinwarehouse &&
                                                _cloneSchoolFood?.find(
                                                    (x) => x.sf_id === o.sf_id
                                                )?.sf_isexportinvoice
                                        )
                                        ?.map((o) => ({
                                            ...o,
                                            s_id: o.s_id
                                                ? o.s_id
                                                : _cloneSchoolFood.find(
                                                      (i) => i.sf_id === o.sf_id
                                                  )?.s_id,
                                            sf_price: numberToPrice(
                                                _cloneSchoolFood.find(
                                                    (i) => i.sf_id === o.sf_id
                                                )?.sf_price
                                            ),
                                        }))
                                )
                                    .groupBy('s_id')
                                    .map((value, key) => ({
                                        s_id: key,
                                        data: value,
                                    }))
                                    .value();
                                const objItem = {
                                    m_datefounded: item.m_datefounded,
                                    dataSchoolFood: listMenuShoolFood,
                                };
                                return [...rsList, objItem];
                            }, []);

                            for (const menu of _menu) {
                                // eslint-disable-next-line no-await-in-loop
                                for (const dataSchoolFood of menu.dataSchoolFood) {
                                    const groupByQuantity = _.chain(
                                        dataSchoolFood.data
                                    )
                                        .groupBy('sf_id', 'msf_priceafter')
                                        .map((value) => ({
                                            ...value[0],
                                            quantity: value
                                                .reduce(
                                                    (a, b) =>
                                                        a +
                                                        b.msf_totalquantityafter,
                                                    0
                                                )
                                                .toFixed(2),
                                        }))
                                        .value();

                                    isDone =
                                        await _billOfSaleExportPDFService.doExportBillOfSale(
                                            dataSchoolFood.s_id,
                                            groupByQuantity,
                                            units,
                                            menu.m_datefounded,
                                            locationInfo,
                                            organizationInfo,
                                            schoolInfo,
                                            supplier,
                                            invoiceForm,
                                            2,
                                            'HDBH'
                                        );
                                }
                            }
                        }
                    }

                    isDone = true;
                    break;
                }
                case 'bang_cong_khai_tai_chinh': {
                    for (const date of getDaysArray(
                        moment.unix(dateFrom).year(),
                        moment.unix(dateFrom).month(),
                        moment.unix(dateFrom).date(),
                        moment.unix(dateTo).date()
                    )) {
                        const { status, data } =
                            await _foodkidApis.getMarketCashBook({
                                oId,
                                dateFound: date,
                            });
                        if (status !== 200 || !data.data.list?.length) {
                            onSetStatus([elem], STATUS_PROCESS.DONE);
                            // eslint-disable-next-line no-continue
                            continue;
                        }

                        const menus = data.data.menus.filter((o) =>
                            kidGroup.find((x) => x.kg_id === o.kg_id)
                        );
                        const marketMenuSchoolFoods = data.data.list?.filter(
                            (o) => kidGroup.find((x) => x.kg_id === o.kg_id)
                        );
                        await _setupMarketExportPDFService.doExport(
                            schoolFoods.current,
                            marketMenuSchoolFoods
                                ?.filter((o) => o.msf_useinwarehouse === false)
                                .reduce((r, a) => {
                                    if (r.length === 0) {
                                        r = update(r, {
                                            $push: [
                                                {
                                                    id: a.sf_id,
                                                    name: a.sf_name,
                                                    kg_id: a.kg_id,
                                                    price: a.msf_priceafter,
                                                    items: [].concat(a),
                                                },
                                            ],
                                        });
                                    } else {
                                        const item = r.find(
                                            (o) => o.id === a.sf_id
                                        );
                                        if (item !== undefined) {
                                            r.map((f) => {
                                                if (f.id === a.sf_id) {
                                                    f.items.push(a);
                                                }
                                                return f;
                                            });
                                        } else {
                                            r = update(r, {
                                                $push: [
                                                    {
                                                        id: a.sf_id,
                                                        name: a.sf_name,
                                                        kg_id: a.kg_id,
                                                        price: a.msf_priceafter,
                                                        items: [].concat(a),
                                                    },
                                                ],
                                            });
                                        }
                                    }
                                    return r;
                                }, []),
                            marketMenuSchoolFoods
                                ?.filter((o) => o.msf_useinwarehouse === true)
                                .reduce((r, a) => {
                                    if (r.length === 0) {
                                        r = update(r, {
                                            $push: [
                                                {
                                                    id: a.sf_id,
                                                    name: a.sf_name,
                                                    kg_id: a.kg_id,
                                                    price: a.msf_priceafter,
                                                    items: [].concat(a),
                                                },
                                            ],
                                        });
                                    } else {
                                        const item = r.find(
                                            (o) => o.id === a.sf_id
                                        );
                                        if (item !== undefined) {
                                            r.map((f) => {
                                                if (f.id === a.sf_id) {
                                                    f.items.push(a);
                                                }
                                                return f;
                                            });
                                        } else {
                                            r = update(r, {
                                                $push: [
                                                    {
                                                        id: a.sf_id,
                                                        name: a.sf_name,
                                                        kg_id: a.kg_id,
                                                        price: a.msf_priceafter,
                                                        items: [].concat(a),
                                                    },
                                                ],
                                            });
                                        }
                                    }
                                    return r;
                                }, []),
                            data.data.difference?.filter((o) =>
                                kidGroup.find((x) => x.kg_id === o.kg_id)
                            ),
                            data.data.difference_start_month?.filter((o) =>
                                kidGroup.find((x) => x.kg_id === o.kg_id)
                            ),
                            data.data.report?.filter((o) =>
                                kidGroup.find((x) => x.kg_id === o.kg_id)
                            ),
                            data.data.service_report?.filter((o) =>
                                kidGroup.find((x) => x.kg_id === o.kg_id)
                            ),
                            data.data.services?.filter((o) =>
                                kidGroup.find((x) => x.kg_id === o.kg_id)
                            ),
                            units,
                            menus,
                            kidGroup,
                            locationInfo,
                            schoolInfo,
                            organizationInfo,
                            date,
                            'BangCongKhaiTaiChinh',
                            REPORT_TYPE.BANG_CONG_KHAI_TAI_CHINH
                        );
                    }

                    isDone = true;
                    break;
                }
                default: {
                    isDone = true;
                    break;
                }
            }
            if (isDone) {
                onSetStatus([elem], STATUS_PROCESS.DONE);
            }
        }
    };

    useEffect(() => {
        if (countClickExport && oId) {
            getData(oId, dateFrom, dateTo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countClickExport]);

    useEffect(() => {
        // if (yearId && oId) {
        //     getListGeneral({
        //         ids: kidGroupIds,
        //         year: yearId,
        //         month,
        //         o_id: oId,
        //     }).then(({ status, data }) => {
        //         if (status === 200) {
        //             menulist.current = data.data;
        //             menuSchoolFoods.current = data.menuSchoolFoods;
        //             menuServices.current = data.menuServices;
        //             menudishs.current = data.menudishs;
        //             proFoods.current = data.proFoods;
        //             schoolFoods.current = data.schoolFoods;
        //         } else {
        //             menulist.current = [];
        //             menuSchoolFoods.current = [];
        //             menuServices.current = [];
        //             menudishs.current = [];
        //             proFoods.current = [];
        //             schoolFoods.current = [];
        //         }
        //     });
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month, oId, yearId]);

    useEffect(() => {
        // if (dateFrom && dateTo) {
        //     setList(
        //         list.map((o) => ({
        //             ...o,
        //             status: STATUS_PROCESS.NOTHING,
        //         }))
        //     );
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom, dateTo]);

    const onCheck = (s, item) => {
        const checkValue = s.target.checked;
        const listCheck = listReport.map((o) => {
            if (o.id === item.id) {
                o = {
                    ...item,
                    isCheck: checkValue,
                };
            }
            return o;
        });

        onChangeListReport(listCheck);
    };

    const showData = () => {
        let xhtml = null;
        xhtml = listReport
            .filter((o) => o.category === 1)
            .map((item) => (
                <FoodkidItem
                    key={item.id}
                    item={item}
                    onCheck={(s) => onCheck(s, item)}
                    // openModal={() => openModal(item)}
                    // showName={showName}
                    // onEdit={(s) => onEdit(s)}
                    // onDelete={(s) => onDelete(s)}
                />
            ));
        return xhtml;
    };
    return <>{showData()}</>;
};

export default FookidList;
