import _ from 'lodash';
/**
 * Hàm làm tròn số
 * @param {Number} number : Số cần làm tròn
 * @param {Number} decimalPlaces : phần số sau phần thập phân
 * @returns
 *  số .5 sẽ làm tròn lên 1, dưới .5 sẽ làm tròn xuống 0
 */

const roundNumber = (number, decimalPlaces = 2) => {
    if (!number) return 0;
    if (!_.isNumber(number)) return 0;

    /**
     *  Tương đương với cách tính:
     */
    // const p = Math.pow(10, decimalPlaces);
    // return Math.round(number * p) / p;

    return +`${Math.round(`${number}e+${decimalPlaces}`)}e-${decimalPlaces}`;
};

export { roundNumber };
