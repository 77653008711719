export const REDUX_FORM_NAME = {
    // account
    ACCOUNTFUNCTION_FORM: 'ACCOUNTFUNCTION_FORM',
    // finance
    FINANCETRANSACTION_FORM: 'FINANCETRANSACTION_FORM',
    // organization
    ORGANIZATIONCATEGORY_FORM: 'ORGANIZATIONCATEGORY_FORM',
    ORGANIZATIONSCALE_FORM: 'ORGANIZATIONSCALE_FORM',
    ORGANIZATIONTYPE_FORM: 'ORGANIZATIONTYPE_FORM',
    ORGANIZATION_FORM: 'ORGANIZATION_FORM',
    ORGANIZATIONADDRESS_FORM: 'ORGANIZATIONADDRESS_FORM',

    ACCOUNT_ROLE_FORM: 'ACCOUNT_ROLE_FORM',
    // School
    BRANCH_FORM: 'BRANCH_FORM',
    DEPARTMENT_FORM: 'DEPARTMENT_FORM',
    // WebPortal
    NEWS_CATEGORY_FORM: 'NEWS_CATEGORY_FORM',
    ABOUTUS_FORM: 'ABOUTUS_FORM',
    WEEKLY_MENU_FORM: 'WEEKLY_MENU_FORM',
    EDUCATION_PLAN_FORM: 'EDUCATION_PLAN_FORM',
    BANNER_FORM: 'BANNER_FORM',
    NEWS_FORM: 'NEWS_FORM',
    // product
    PRODUCTCATEGORY_FORM: 'PRODUCTCATEGORY_FORM',
    PRODUCTSUPPLIER_FORM: 'PRODUCTSUPPLIER_FORM',
    PRODUCTCALCULATIONUNIT_FORM: 'PRODUCTCALCULATIONUNIT_FORM',
    PRODUCT_FORM: 'PRODUCT_FORM',
    ACCOUNT_FORM: 'ACCOUNT_FORM',
    // sale
    PROMOTION_FORM: 'PROMOTION_FORM',
    SHOP_FORM: 'SHOP_FORM',
    // finance
    ACCOUNTCATEGORY_FORM: 'ACCOUNTCATEGORY_FORM',
    ACCOUNTBANK_FORM: 'ACCOUNTBANK_FORM',
    // student
    ADDMISSION_PLAN_CRITERIA_FORM: 'ADDMISSION_PLAN_CRITERIA_FORM',
    ADDMISSION_PLAN_FORM: 'ADDMISSION_PLAN_FORM',
    ADDMISSION_FORM: 'ADDMISSION_FORM',
    // licence
    LICENCELIST_FORM: 'LICENCELIST_FORM',
    // blog
    BLOGCATEGORYPOST_FORM: 'BLOGCATEGORYPOST_FORM',
    BLOG_FORM: 'BLOG_FORM',
    DISTRICT: 'DISTRICT_FORM',
    REWARD: 'REWARD_FORM',
    // report form
    REPORTFORM_FORM: 'REPORTFORM_FORM',
    // Voice Cloud
    CONTRACTVOICECLOUD_FORM: 'CONTRACTVOICECLOUD_FORM',
};
