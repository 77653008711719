import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _schoolSelector from '@app/redux/school/school.selector';
import {
    doGetOrganizationInfo,
    doGetLocationInfo,
    doGetSchoolInfo,
    doGetUnits,
    doGetAgeGroup,
    doGetKidGroup,
    doGetMealService,
    doGetSupplier,
    doGetFoodkidDish,
    doGetInventory,
    doGetPackaging,
    doGetSchoolFood,
    doGetSchoolProperty,
    doGetSchoolInvoiceForm,
    doGetProFood,
} from '@app/redux/others/sagaActions';
import _othersSelector from '@app/redux/others/selector';
import { doGetRouteList } from '@app/redux/others/slice';
// import _ from 'lodash';

const InitialData = () => {
    const dispatch = useDispatch();
    const oId = useSelector(_schoolSelector.currentSchool);
    const organizationInfo = useSelector(_othersSelector.organizationInfo);

    useEffect(() => {
        if (oId) {
            dispatch(doGetOrganizationInfo(oId));
            dispatch(doGetSchoolInfo(oId));
            dispatch(doGetSchoolInfo(oId));
            dispatch(doGetSchoolInfo(oId));
            dispatch(doGetKidGroup(oId));
            dispatch(doGetMealService(oId));
            dispatch(doGetSupplier(oId));
            dispatch(doGetFoodkidDish(oId));
            dispatch(doGetInventory(oId));
            dispatch(doGetSchoolFood(oId));
        }
    }, [dispatch, oId]);

    useEffect(() => {
        dispatch(doGetUnits());
        dispatch(doGetAgeGroup());
        dispatch(doGetPackaging());
        dispatch(doGetSchoolProperty());
        dispatch(doGetRouteList());
        dispatch(doGetSchoolInvoiceForm());
        dispatch(doGetProFood());
    }, [dispatch]);

    useEffect(() => {
        if (organizationInfo?.l_id) {
            dispatch(doGetLocationInfo(organizationInfo?.l_id));
        }
    }, [dispatch, organizationInfo]);
    return <div />;
};

export default InitialData;
