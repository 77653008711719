import axiosService from '@app/services/axiosService';
import {
    //API_INCOME_ENDPOINT,
    API_SC_GATEWAY_ENDPOINT,
} from '@app/constants/api';
import { ApiGatewayDataPost } from '@app/models/common/apiGatewayDataPost.model';

// const BASE_URL = `${API_INCOME_ENDPOINT}/v1/Sc/Income/service/PaymentVoucher`;

const LIST_URL = {
    // SAVE: `${BASE_URL}/Save`,
    // GET_LIST_BY_DATE: `${BASE_URL}/GetListByDate`,
    // GET_TOTAL_OF_LIST_BY_DATE: `${BASE_URL}/GetTotalByDate`,
    // GET_MAX_DATETO: `${BASE_URL}/GetMaxDateTo`,
    // DELETE: `${BASE_URL}/Delete`,
    TRANSIT_STATION: `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/TransitStation`,
};
/**
 * Lưu phiếu chi
 * @param {*} dataPost
 * @returns
 */
export const apiSave = (dataPost) => {
    const _dataPost = new ApiGatewayDataPost({
        service: 'Income',
        controller: 'ToolScPaymentVoucher',
        action: 'Save',
        dataPost,
    });
    return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    //return axiosService.post(LIST_URL.SAVE, dataPost)
};

/**
 * Lấy danh sách phiếu chi theo ngày
 * @param {object} dataPost : dữ liệu gửi lên
 * @param {string} dataPost.oId : Id tổ chức
 * @param {number} dataPost.yearId: Id năm học
 * @param {number} dataPost.startDate: Ngày bắt đầu
 * @param {number} dataPost.endDate: Ngày kết thúc
 * @param {number} dataPost.paymentVoucherType: Loại phiếu chi
 * @returns
 */
export const apiGetListByDate = (dataPost) => {
    const _dataPost = new ApiGatewayDataPost({
        service: 'Income',
        controller: 'ToolScPaymentVoucher',
        action: 'GetListByDate',
        dataPost,
    });

    return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    //return axiosService.post(LIST_URL.GET_LIST_BY_DATE, dataPost);
};

/**
 * Lấy danh sách phiếu chi theo ngày
 * @param {object} dataPost : dữ liệu gửi lên
 * @param {string} dataPost.oId : Id tổ chức
 * @param {number} dataPost.yearId: Id năm học
 * @param {number} dataPost.startDate: Ngày bắt đầu
 * @param {number} dataPost.endDate: Ngày kết thúc
 * @param {number} dataPost.paymentVoucherType: Loại phiếu chi
 * @returns
 */
export const apiGetTotalOfListByDate = (dataPost) => {
    const _dataPost = new ApiGatewayDataPost({
        service: 'Income',
        controller: 'ToolScPaymentVoucher',
        action: 'GetTotalOfListByDate',
        dataPost,
    });
    return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    //return axiosService.post(LIST_URL.GET_TOTAL_OF_LIST_BY_DATE, dataPost);
};

export const apiDeleteByListId = (dataPost) => {
    const _dataPost = new ApiGatewayDataPost({
        service: 'Income',
        controller: 'ToolScPaymentVoucher',
        action: 'DeleteByListId',
        dataPost,
    });

    return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    //return axiosService.post(LIST_URL.DELETE, dataPost.listId);
};

export const apiGetMaxDateTo = (dataPost) => {
    const _dataPost = new ApiGatewayDataPost({
        service: 'Income',
        controller: 'ToolScPaymentVoucher',
        action: 'GetMaxDateTo',
        dataPost,
    });

    return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    //return axiosService.post(LIST_URL.GET_MAX_DATETO, dataPost);
};
