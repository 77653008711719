import * as types from './organization.constant';

const initState = {
    organizationList: [],
    organizationScale: [],
    organizationType: [],

    isShow: false,
    keyword: '',
    total: 0,
    currentPage: 1,
    isLoad: 0,
    editItem: { o_securitycode: '' },
};

const OrganizationReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case types.GET_LIST_SUCCESS: {
            const { schoolList, total } = payload.data;
            return {
                ...state,
                organizationList: schoolList,
                total,
            };
        }

        case types.FETCH_DATA_FIRST_SUCCESS: {
            const { dataScale, dataType } = payload.data;

            return {
                ...state,
                organizationScale: dataScale,
                organizationType: dataType,
            };
        }

        case types.OPEN_MODAL: {
            return {
                ...state,
                isShow: true,
                editItem: { o_securitycode: '' },
            };
        }

        case types.CLOSE_MODAL: {
            return {
                ...state,
                isShow: false,
            };
        }

        case types.CHANGE_PAGE: {
            return {
                ...state,
                currentPage: payload.data,
            };
        }

        case types.CHANGE_KEY_WORD: {
            return {
                ...state,
                keyword: payload.data,
            };
        }

        case types.SAVE_LINKED_SCHOOL_SUCCESS: {
            return {
                ...state,
                isLoad: state.isLoad + 1,
                isShow: false,
            };
        }

        case types.UPDATE_CONNECTION_STATUS_SUCCESS: {
            return {
                ...state,
                isLoad: state.isLoad + 1,
            };
        }

        case types.RESET: {
            return {
                ...state,
                ...initState,
            };
        }

        default:
            return { ...state };
    }
};

export default OrganizationReducer;
