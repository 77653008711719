import React, { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const GenderSelect = ({ input, type, label, ...props }) => {
    const { onChange: inputOnChange, value: inputValue } = input;
    const [gender, setGender] = useState(inputValue);

    const onChange = (e) => {
        inputOnChange(e);
        setGender(e);
    };

    const showOptionItem = () => {
        const list = [
            {
                name: 'Nam',
                value: 1,
            },
            {
                name: 'Nữ',
                value: 0,
            },
        ];
        const xhtml = list.map((item) => (
            <Option value={item.value} key={item.value}>
                {item.name}
            </Option>
        ));

        return xhtml;
    };

    return (
        <Select value={gender} onChange={onChange} {...props}>
            {showOptionItem()}
        </Select>
    );
};

export default GenderSelect;
