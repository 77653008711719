import React, { memo } from 'react';
import MenuList from './MenuList';
import MenuItem from './MenuItem';
import RouteAuthentication from '../../../routeAuth';

const Menu = () => {
    const showMenu = () => {
        const menus = RouteAuthentication.getMenu();
        let xhtml = null;
        if (menus.length > 0) {
            xhtml = menus.map((item, index) => (
                <MenuItem key={index} menu={item} />
            ));
        }

        return xhtml;
    };

    return <MenuList>{showMenu()}</MenuList>;
};

export default memo(Menu);
