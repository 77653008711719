import _schoolPropertyApis from '@app/apis/settingSchool/settingSchool.api';
import { SchoolInfoModel } from '@app/models/school/schoolInfo.model';
import alertUtils from '@app/utils/alert';

const _copyMenuHelpers = {
    // Lấy thông tin setting của trường cần copy dữ liệu
    async getTargetSchoolSetting(orgId) {
        const { status, data } = await _schoolPropertyApis.getSetting(orgId);
        if (status !== 200) {
            alertUtils.showError(
                `Không tìm thấy thông tin cấu hình trường. ERR_CODE: ${data.msg}`
            );
            return {
                isSuccess: false,
            };
        }
        let schoolInfo = {};
        data.data.forEach((elm) => {
            schoolInfo = {
                ...schoolInfo,
                o_id: elm.o_id,
                [`${elm.ps_code}`]: elm.ps_value,
            };
        });
        return {
            isSuccess: true,
            data: new SchoolInfoModel(schoolInfo),
        };
    },
};

export default _copyMenuHelpers;
