import React from 'react';
import PropTypes from 'prop-types';
import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

const propTypes = {
    onAddRole: PropTypes.func,
};

const AccountRoleFilter = ({ onAddRole }) => (
    <div className="row">
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-3 col-sm-6">
                    <div className="form-group">
                        <div className="no-margin">
                            <div className="btn-group">
                                <ButtonFilterAction
                                    labelButton="Thêm mới"
                                    buttonType={BUTTON_ACTION_TYPE.ADD}
                                    onClick={onAddRole}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

AccountRoleFilter.propTypes = propTypes;
export default AccountRoleFilter;
