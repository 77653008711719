export const BUTTON_ACTION_TYPE = {
    SEARCH: 'search',
    ADD: 'add',
    CHANGE: 'change',
    EXCEL: 'excel',
    PRINT: 'print',
    SETTING: 'setting',
    SAVE: 'save',
    DELETE: 'delete',
    APPROVE: 'approve',
    SORTBYALPHA: 'sortByAlpha',
    CALCULATION: 'calculation',
    RELOAD: 'reload',
    CHECKPASS: 'checkpass',
    EDIT: 'edit',
    VIEW: 'view',
    PASSWORD: 'password',
    USER: 'user',
    DETAIL: 'detail',
    HISTORY: 'history',
    CLOSE: 'close',
    FILE: 'file',
    SYNC: 'sync',
    EXPORT_FILE: 'exportFile',
    DETAIL_ORDER: 'detailOrder',
    OFFICE: 'office',
    CART: 'cart',
    HISTORY_V2: 'historyV2',
};

export const getTypeButton = (buttonType) => {
    let classButton = '';
    switch (buttonType) {
        case BUTTON_ACTION_TYPE.SEARCH: {
            classButton = 'glyphicon glyphicon-search position-left';
            break;
        }

        case BUTTON_ACTION_TYPE.ADD: {
            classButton = 'icon-plus-circle2 position-left';
            break;
        }
        case BUTTON_ACTION_TYPE.CHANGE: {
            classButton = 'icon-loop3';
            break;
        }
        case BUTTON_ACTION_TYPE.EXCEL: {
            classButton = 'icon-file-excel';
            break;
        }
        case BUTTON_ACTION_TYPE.PRINT: {
            classButton = 'glyphicon glyphicon-print';
            break;
        }
        case BUTTON_ACTION_TYPE.SETTING: {
            classButton = 'icon-gear';
            break;
        }
        case BUTTON_ACTION_TYPE.SAVE: {
            classButton = 'icon-floppy-disk position-left';
            break;
        }
        case BUTTON_ACTION_TYPE.DELETE: {
            classButton = 'glyphicon glyphicon-trash position-left';
            break;
        }
        case BUTTON_ACTION_TYPE.APPROVE: {
            classButton = 'icon-stamp';
            break;
        }
        case BUTTON_ACTION_TYPE.SORTBYALPHA: {
            classButton = 'icon-sort-alpha-asc';
            break;
        }
        case BUTTON_ACTION_TYPE.CALCULATION: {
            classButton = 'icon-calculator2';
            break;
        }
        case BUTTON_ACTION_TYPE.RELOAD: {
            classButton = 'icon-reload-alt';
            break;
        }
        case BUTTON_ACTION_TYPE.CHECKPASS: {
            classButton = 'icon-pencil7';
            break;
        }

        case BUTTON_ACTION_TYPE.EXPORT_FILE: {
            classButton = 'icon-upload position-left';
            break;
        }

        default: {
            break;
        }
    }
    return classButton;
};

export const getTableButtonType = (buttonType) => {
    const classButton = {};
    switch (buttonType) {
        case BUTTON_ACTION_TYPE.EXCEL: {
            classButton.icon = 'icon-file-excel';
            classButton.btnClass = 'btn-success';
            break;
        }
        case BUTTON_ACTION_TYPE.PRINT: {
            classButton.icon = 'glyphicon glyphicon-print';
            classButton.btnClass = 'btn-primary';
            break;
        }
        case BUTTON_ACTION_TYPE.EDIT: {
            classButton.icon = 'icon-pencil7';
            classButton.btnClass = 'btn-primary';
            break;
        }
        case BUTTON_ACTION_TYPE.DELETE: {
            classButton.icon = 'icon-cross2';
            classButton.btnClass = 'btn-danger';
            break;
        }
        case BUTTON_ACTION_TYPE.VIEW: {
            classButton.icon = 'icon-file-eye';
            classButton.btnClass = 'btn-primary';
            break;
        }
        case BUTTON_ACTION_TYPE.PASSWORD: {
            classButton.icon = 'icon-key';
            classButton.btnClass = 'bg-green';
            break;
        }
        case BUTTON_ACTION_TYPE.USER: {
            classButton.icon = 'icon-user';
            classButton.btnClass = 'bg-yellow';
            break;
        }
        case BUTTON_ACTION_TYPE.CHANGE: {
            classButton.icon = 'glyphicon glyphicon-repeat';
            classButton.btnClass = 'bg-primary';
            break;
        }
        case BUTTON_ACTION_TYPE.SAVE: {
            classButton.icon = 'icon-floppy-disk';
            classButton.btnClass = 'bg-success';
            break;
        }
        case BUTTON_ACTION_TYPE.DETAIL: {
            classButton.icon = 'icon-list';
            classButton.btnClass = 'bg-green';
            break;
        }
        case BUTTON_ACTION_TYPE.HISTORY: {
            classButton.icon = 'icon-history';
            classButton.btnClass = 'bg-success';
            break;
        }
        case BUTTON_ACTION_TYPE.APPROVE: {
            classButton.icon = 'icon-checkmark4';
            classButton.btnClass = 'btn-success';
            break;
        }
        case BUTTON_ACTION_TYPE.FILE: {
            classButton.icon = 'icon-file-text';
            classButton.btnClass = 'btn-success';
            break;
        }
        case BUTTON_ACTION_TYPE.SYNC: {
            classButton.icon = 'icon-loop3';
            classButton.btnClass = 'btn-success';
            break;
        }
        case BUTTON_ACTION_TYPE.SETTING: {
            classButton.icon = 'icon-gear';
            classButton.btnClass = 'bg-primary';
            break;
        }
        case BUTTON_ACTION_TYPE.DETAIL_ORDER: {
            classButton.icon = 'icon-file-text';
            classButton.btnClass = 'btn-primary';
            break;
        }
        case BUTTON_ACTION_TYPE.OFFICE: {
            classButton.icon = 'icon-office';
            classButton.btnClass = 'bg-orange';
            break;
        }
        case BUTTON_ACTION_TYPE.CART: {
            classButton.icon = 'icon-cart';
            classButton.btnClass = 'btn-success';
            break;
        }
        case BUTTON_ACTION_TYPE.HISTORY_V2: {
            classButton.icon = 'icon-history';
            classButton.btnClass = 'btn-primary';
            break;
        }
        default: {
            break;
        }
    }
    return classButton;
};

export const getModalButtonType = (buttonType) => {
    const objButton = {
        buttonClass: '',
        iconClass: '',
    };

    switch (buttonType) {
        case BUTTON_ACTION_TYPE.PRINT: {
            objButton.buttonClass = 'btn-primary bg-primary-800';
            objButton.iconClass = 'glyphicon glyphicon-print';
            break;
        }

        case BUTTON_ACTION_TYPE.CLOSE: {
            // objButton.buttonClass = '';
            // objButton.iconClass = 'glyphicon glyphicon-off';
            objButton.iconClass = 'icon-switch2';
            objButton.buttonClass = 'btn btn-default btn-rounded';
            break;
        }

        case BUTTON_ACTION_TYPE.SAVE: {
            objButton.iconClass = 'icon-floppy-disk';
            objButton.buttonClass = 'btn btn-default btn-rounded';
            break;
        }

        default: {
            break;
        }
    }
    return objButton;
};
