import NoData from '@app/components/Layout/Shares/NoData';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import ButtonModalAction from '@app/components/UI/Button/ButtonModalAction';
import { agencyActions, agencySelector } from '@app/redux/agency/agency.slice';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from '@app/components/_Share/Pagination';
import ModalTransHistoryItem from './ModalTransHistoryItem';
import ModalTransHistoryList from './ModalTransHistoryList';
import ModalTransHistoryFilter from './ModalTransHistoryFilter';
import moment from 'moment';

const ModalTransHistory = ({ isShow, onCloseModal }) => {
    const dispatch = useDispatch();

    const [objDate, setObjDate] = useState({});
    const [idStatus, setIdStatus] = useState(-1);

    const total = useSelector(agencySelector.totalTransHistory);
    const currentPage = useSelector(agencySelector.currentPageModalTrans);
    const listTransHistory = useSelector(agencySelector.listTransHistory);
    const currentAgency = useSelector(agencySelector.currentAgency);

    useEffect(() => {
        if (idStatus && +objDate?.fromDate > 0 && +objDate?.toDate > 0) {
            dispatch(agencyActions.doChangePageListTrans(1));

            dispatch(
                agencyActions.doGetListTransHistory({
                    o_id: currentAgency?.o_id,
                    currentPage: 1,
                    fromDate: objDate.fromDate,
                    toDate: objDate.toDate,
                    so_paymentstatus: idStatus,
                    isGetDetail: false,
                })
            );
        }
    }, [dispatch, objDate, idStatus, currentAgency.o_id]);

    const onChangePage = (page) => {
        dispatch(agencyActions.doChangePageListTrans(page));

        dispatch(
            agencyActions.doGetListTransHistory({
                o_id: currentAgency?.o_id,
                currentPage: page,
                fromDate: objDate.fromDate,
                toDate: objDate.toDate,
                so_paymentstatus: idStatus,
                isGetDetail: false,
            })
        );
    };

    const showListTransactionHistory = () => {
        if (_.isEmpty(listTransHistory)) return <NoData colspan={5} />;

        return listTransHistory.map((item, idx) => (
            <ModalTransHistoryItem key={idx} transHistory={item} />
        ));
    };

    /** ========== THAY ĐỔI NGÀY ========== */
    const onChangeDate = (data) => {
        const { startDate, endDate } = data;

        const unixSD = moment(startDate).startOf('day').unix();
        const unixED = moment(endDate).endOf('day').unix();

        setObjDate({ fromDate: unixSD, toDate: unixED });
    };

    /** ========== THAY ĐỔI TRẠNG THÁI ========== */
    const onChangeStatus = (data) => {
        setIdStatus(data);
    };

    return (
        <Modal
            show={isShow}
            onHide={onCloseModal}
            animation
            backdrop="static"
            size="lg"
            className={listTransHistory?.length >= 10 ? 'fit-screen' : ''}
        >
            <Modal.Header closeButton className="bg-primary-800">
                <Modal.Title>Lịch sử giao dịch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <ModalTransHistoryFilter
                        onChangeDate={onChangeDate}
                        onChangeStatus={onChangeStatus}
                        statusSelected={idStatus}
                    />
                    <div className="col-md-12">
                        <ModalTransHistoryList>
                            {showListTransactionHistory()}
                        </ModalTransHistoryList>
                    </div>
                </div>

                <CustomPagination
                    total={total}
                    pageSize={10}
                    currentPage={currentPage}
                    currentCount={listTransHistory.length}
                    onChange={onChangePage}
                />
            </Modal.Body>
            <Modal.Footer>
                <ButtonModalAction
                    buttonType={BUTTON_ACTION_TYPE.CLOSE}
                    buttonLabel="Thoát"
                    onClick={onCloseModal}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ModalTransHistory;
