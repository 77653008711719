import { numberToPrice } from '@app/utils/utils';
import { Checkbox, DatePicker, Input } from 'antd';
import React from 'react';
import moment from 'moment';

const FoodkidItem = ({ dataItem, dateFilter, onEditData, onCheckData }) => {
    const disabledDate = (current) =>
        current &&
        (current < dateFilter.startOf('month') ||
            current > dateFilter.endOf('month'));

    const showStatus = (_status) => {
        let xhtml = <></>;
        switch (_status) {
            case -1:
                xhtml = (
                    <span className="label label-danger">
                        <i className="icon-x" /> Đã tồn tại
                    </span>
                );
                break;
            case 1:
                xhtml = (
                    <span className="label label-primary">
                        <i className="icon-spinner2 spinner" /> Đang xử lý...
                    </span>
                );
                break;
            case 2:
                xhtml = (
                    <span className="label label-success">
                        <i className="icon-checkmark4" /> Hoàn thành
                    </span>
                );
                break;
            default:
                break;
        }

        return xhtml;
    };

    return (
        <tr>
            <td className="text-center">
                <Checkbox
                    checked={dataItem.is_update}
                    onChange={(value) => onCheckData(value, dataItem)}
                />
            </td>
            <td className="text-center">
                {dataItem.m_code}
                <div className="display-block text-muted">
                    {dataItem.m_quantitybefore}
                </div>
            </td>
            <td className="text-right">
                {numberToPrice(dataItem.m_price)}
                <div className="display-block text-muted">
                    {numberToPrice(dataItem.m_balance)}
                </div>
            </td>
            <td className="text-right">
                {numberToPrice(dataItem.m_pricebefore)}
                <div className="display-block text-muted">
                    {numberToPrice(dataItem.m_service)}
                </div>
            </td>
            <td>
                <strong>Bữa sáng:</strong>&nbsp;
                {dataItem.m_meal_breakfast}
                <br />
                <strong>Bữa trưa:</strong>&nbsp;
                {dataItem.m_meal_lunch}
                <br />
                <strong>Bữa xế:</strong>
                &nbsp;
                {dataItem.m_meal_dinner}
                <br />
                <strong>Bữa phụ chiều:</strong>
                &nbsp;
                {dataItem.m_meal_snack}
            </td>
            <td className="text-center">
                <DatePicker
                    onChange={(value) =>
                        onEditData(dataItem, value, 'm_datefounded')
                    }
                    value={
                        dataItem.m_datefounded
                            ? moment.unix(dataItem.m_datefounded)
                            : null
                    }
                    placeholder="Chọn ngày"
                    format="DD/MM/YYYY"
                    disabledDate={disabledDate}
                    allowClear={false}
                />
            </td>
            {/* Ngày lập */}
            <td className="text-right">
                <Input
                    className="text-right"
                    value={dataItem.m_quantityafter}
                    onPressEnter={(value) =>
                        onEditData(dataItem, value, 'm_quantityafter')
                    }
                    onChange={(value) =>
                        onEditData(dataItem, value, 'm_quantityafter')
                    }
                />
            </td>
            {/* Sĩ số thực tế */}
            <td className="text-center">{showStatus(dataItem?.status)}</td>
        </tr>
    );
};

export default FoodkidItem;
