// /* eslint-disable react/no-this-in-sfc */
// import React, { Fragment, useEffect, useRef, useState } from 'react';
// import { HotTable } from '@handsontable/react';
// import { MenuListModel } from '@app/models/foodkid/foodkid.model';
// import { decimalToPrice } from '@app/utils/utils';
// import 'handsontable-key-value';
// import Handsontable from 'handsontable';

// const CopyMenuList = ({ listData, kidGroupList, onUpdateMenuResult }) => {
//     const tableRef = useRef();
//     const [settings, setSettings] = useState('');

//     const _listData = listData.map((item) => {
//         const itemMapping = new MenuListModel(item);

//         return {
//             ...itemMapping,
//             status: item.status || '',
//             // kg_id: checkKidGroup ? item.kg_id : -1,
//             m_balance: decimalToPrice(item.m_price - item.m_priceafter),
//         };
//     });

//     const onChangeCode = (value, item) => {
//         item.m_code = value;
//         item.is_update = true;
//         item.status = 0;

//         return item;
//     };

//     const onChangeKidGroup = (value, item) => {
//         item.kg_id = value;
//         item.is_update = true;
//         item.status = 0;

//         return item;
//     };

//     const staticConfig = {
//         colHeaders: [
//             'Mã thực đơn mẫu',
//             'Nhóm trẻ',
//             'Sỉ số dự trù',
//             'Tiền định mức',
//             'Tiền một trẻ',
//             'Tiền dư',
//             'Chọn nhóm tuổi',
//             'Tình trạng',
//         ],

//         columns: [
//             {
//                 data: 'm_code',
//                 type: 'text',
//                 className: 'htCenter htMiddle',
//             },
//             {
//                 data: 'kg_name',
//                 type: 'text',
//                 className: 'htCenter htMiddle',
//                 readOnly: true,
//             },
//             {
//                 data: 'm_quantitybefore',
//                 type: 'numeric',
//                 className: 'htRight htMiddle',
//             },
//             {
//                 data: 'm_price',
//                 type: 'numeric',
//                 numericFormat: {
//                     pattern: '0,0',
//                 },
//                 className: 'htRight htMiddle',
//                 readOnly: true,
//             },
//             {
//                 data: 'm_priceafter',
//                 type: 'numeric',
//                 numericFormat: {
//                     pattern: '0,0',
//                 },
//                 className: 'htRight htMiddle',
//                 readOnly: true,
//             },
//             {
//                 data: 'm_balance',
//                 type: 'numeric',
//                 numericFormat: {
//                     pattern: '0,0',
//                 },
//                 className: 'htRight htMiddle',
//                 readOnly: true,
//             },
//             {
//                 type: 'key-value',
//                 filter: false,
//                 data: 'kg_id',
//                 source: kidGroupList,
//                 keyProperty: 'kg_id',
//                 valueProperty: 'kg_name',
//                 className: 'htCenter htMiddle',
//             },
//             {
//                 data: 'status',
//                 type: 'text',
//                 readOnly: true,
//                 className: 'htCenter htMiddle',
//                 // width: 100,
//                 renderer(instance, td, row, col, prop, value, cellProperties) {
//                     if (value) {
//                         const span = document.createElement('span');
//                         const tagIcon = document.createElement('i');
//                         span.classList.add('label');

//                         switch (value) {
//                             case -1:
//                                 span.classList.add('label-danger');
//                                 tagIcon.classList.add('icon-cross2');
//                                 value = 'Thực đơn đã tồn tại';
//                                 break;
//                             case 1:
//                                 span.classList.add('label-primary');
//                                 tagIcon.classList.add('icon-hour-glass2');
//                                 value = 'Đang xử lý';
//                                 break;
//                             case 2:
//                                 span.classList.add('label-success');
//                                 tagIcon.classList.add('icon-checkmark4');
//                                 value = 'Hoàn thành';
//                                 break;
//                             default:
//                                 value = '';
//                                 break;
//                         }

//                         span.appendChild(tagIcon);
//                         span.innerHTML += ` ${value}`;

//                         td.classList.add('htCenter');
//                         td.classList.add('htMiddle');
//                         td.classList.add('lightblue-bg');

//                         td.innerText = '';
//                         td.appendChild(span);
//                     } else {
//                         Handsontable.renderers.TextRenderer.apply(
//                             this,
//                             // eslint-disable-next-line prefer-rest-params
//                             arguments
//                         );
//                     }
//                     return td;
//                 },
//             },
//         ],

//         licenseKey: 'non-commercial-and-evaluation',
//         width: '100%',
//         height: 580,
//         stretchH: 'all',
//         autoWrapRow: true,
//         colWidths: [25, 25, 25, 25, 25, 25],
//         autoColumnSize: { useHeaders: true },
//         columnHeaderHeight: 40,
//         preventOverflow: 'horizontal',
//         afterChange: (changes) => {
//             if (!changes) {
//                 return;
//             }
//             changes.forEach(([row, prop, oldValue, newValue]) => {
//                 if (tableRef.current) {
//                     const { hotInstance } = tableRef.current;
//                     switch (prop) {
//                         case 'm_code': {
//                             if (oldValue !== newValue) {
//                                 listData[row] = onChangeCode(
//                                     newValue,
//                                     listData[row]
//                                 );
//                             }
//                             break;
//                         }
//                         case 'kg_id': {
//                             listData[row] = onChangeKidGroup(
//                                 newValue,
//                                 listData[row]
//                             );

//                             break;
//                         }
//                         default: {
//                             break;
//                         }
//                     }
//                     onUpdateMenuResult(listData);
//                     hotInstance.render();
//                 }
//             });
//         },
//     };

//     useEffect(() => {
//         setSettings({
//             ...staticConfig,
//             data: _listData,
//             filtersKeyValue: true,
//         });

//         if (tableRef.current) {
//             const { hotInstance } = tableRef.current;
//             hotInstance.updateSettings({
//                 cells(row, col, prop) {
//                     const hot = this.instance;
//                     if (_listData[row] !== undefined) {
//                         if (_listData[row].is_update) {
//                             for (let i = 0; i < 8; i++) {
//                                 if (i === 6 || i === 1 || i === 0) {
//                                     hot.setCellMeta(
//                                         row,
//                                         i,
//                                         'className',
//                                         'lightblue-bg htCenter htMiddle'
//                                     );
//                                 } else {
//                                     hot.setCellMeta(
//                                         row,
//                                         i,
//                                         'className',
//                                         'lightblue-bg htRight htMiddle'
//                                     );
//                                 }
//                                 //  else {
//                                 //     hot.setCellMeta(
//                                 //         row,
//                                 //         i,
//                                 //         'className',
//                                 //         'lightblue-bg'
//                                 //     );
//                                 // }
//                             }
//                         }
//                     }
//                 },
//             });
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [listData, kidGroupList]);

//     return (
//         <Fragment>
//             <div id="example1">
//                 <HotTable
//                     id="hot"
//                     settings={settings}
//                     licenseKey="non-commercial-and-evaluation"
//                     ref={tableRef}
//                 />
//             </div>
//         </Fragment>
//     );
// };

// export default CopyMenuList;

import React, { Fragment } from 'react';
import { Table } from 'antd';
import { MenuListModel } from '@app/models/foodkid/foodkid.model';
import { decimalToPrice, numberToPrice } from '@app/utils/utils';

const CopyMenuList = ({ onSelectedChange, dataSource, rowSelected }) => {
    const _listData = dataSource.map((item) => {
        const itemMapping = new MenuListModel(item);

        return {
            ...itemMapping,
            key: item.m_id,
            status: item.status || '',
            m_balance: decimalToPrice(item.m_price - item.m_pricebefore),
        };
    });

    const rowSelection = {
        selectedRowKeys: rowSelected,
        onChange: onSelectedChange,
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const columns = [
        {
            title: () => (
                <div style={{ textAlign: 'center' }}>
                    <div>Mã thực đơn mẫu</div>
                    <div className="text-muted">Sĩ số</div>
                </div>
            ),
            key: 'm_code',
            width: '10%',
            align: 'center',
            render: (_dataItem) => (
                <Fragment>
                    {_dataItem.m_code}
                    <div className="display-block text-muted">
                        {_dataItem.m_quantitybefore}
                    </div>
                </Fragment>
            ),
        },
        {
            title: () => (
                <div style={{ textAlign: 'center' }}>
                    <div>Tiền định mức</div>
                    <div className="text-muted">Tiền dư</div>
                </div>
            ),
            key: 'm_price',
            width: '8%',
            align: 'right',
            render: (_dataItem) => (
                <Fragment>
                    {numberToPrice(_dataItem.m_price)}
                    <div className="display-block text-muted">
                        {numberToPrice(_dataItem.m_balance)}
                    </div>
                </Fragment>
            ),
        },
        {
            title: () => (
                <div style={{ textAlign: 'center' }}>
                    <div>Tiền một trẻ</div>
                    <div className="text-muted">Tiền dịch vụ</div>
                </div>
            ),
            key: 'm_priceafter',
            width: '8%',
            align: 'right',
            render: (_dataItem) => (
                <Fragment>
                    {numberToPrice(_dataItem.m_priceafter)}
                    <div className="display-block text-muted">
                        {numberToPrice(_dataItem.m_service)}
                    </div>
                </Fragment>
            ),
        },
        {
            title: () => <div style={{ textAlign: 'center' }}>Món ăn</div>,
            key: 'm_id',
            align: 'left',
            render: (_dataItem) => (
                <Fragment>
                    <strong>Bữa sáng:</strong>&nbsp;
                    {_dataItem.m_meal_breakfast}
                    <br />
                    <strong>Bữa trưa:</strong>&nbsp;
                    {_dataItem.m_meal_lunch}
                    <br />
                    <strong>Bữa xế:</strong>&nbsp;
                    {_dataItem.m_meal_dinner}
                    <br />
                    <strong>Bữa phụ chiều:</strong>&nbsp;
                    {_dataItem.m_meal_snack}
                </Fragment>
            ),
        },
        {
            title: 'Tình trạng',
            dataIndex: 'status',
            width: 100,
            align: 'center',
            render: (_dataItem) => {
                let xhtml = <></>;
                switch (_dataItem) {
                    case -1:
                        xhtml = (
                            <span className="label label-danger">
                                <i className="icon-x" /> Đã tồn tại
                            </span>
                        );
                        break;
                    case 1:
                        xhtml = (
                            <span className="label label-primary">
                                <i className="icon-spinner2 spinner" /> Đang xử
                                lý...
                            </span>
                        );
                        break;
                    case 2:
                        xhtml = (
                            <span className="label label-success">
                                <i className="icon-checkmark4" /> Hoàn thành
                            </span>
                        );
                        break;
                    default:
                        break;
                }

                return xhtml;
            },
        },
    ];

    return (
        <Table
            rowSelection={{
                type: 'checkbox',
                ...rowSelection,
            }}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={_listData}
            columns={columns}
            pagination={false}
            className="center-header"
        />
    );
};

export default CopyMenuList;
