// import { call, put, takeLatest } from 'redux-saga/effects';
// import {
//     apiBackStatusMenu,
//     apiGetListMenu,
//     apiUpdateStatusMenu,
// } from '@app/apis/foodkid/foodkid';

// import { ACTION_API_TYPES } from '@app/constants';
// import * as apiConst from '@app/constants/api';
// import { menusActions } from './menus.slice';
// import { showError, showSuccess } from '@app/utils/alert';
// import { callLoading } from '@app/utils/saga.utils';

// function* sagaGetListMenu({ payload }) {
//     function* doRQ() {
//         const { data, status } = yield call(apiGetListMenu, payload);

//         if (status === apiConst.STATUS_CODES.SUCCESS) {
//             yield put(menusActions.doGetListMenuSuccess(data));
//         } else {
//             showError(status, data);
//         }
//     }
//     yield callLoading(doRQ);
// }

// function* sagaDeleteMenu({ payload }) {
//     function* doRQ() {
//         const { data, status } = yield call(apiBackStatusMenu, payload);

//         if (status === apiConst.STATUS_CODES.SUCCESS) {
//             yield put(menusActions.doDeleteMenuSuccess(payload));
//             showSuccess(ACTION_API_TYPES.DELETE);
//         } else {
//             showError(status, data);
//         }
//     }
//     yield callLoading(doRQ);
// }

// function* sagaUpdateStatusMenu({ payload }) {
//     function* doRQ() {
//         const { data, status } = yield call(apiUpdateStatusMenu, payload);

//         if (status === apiConst.STATUS_CODES.SUCCESS) {
//             yield put(menusActions.doUpdateStatusSuccess(payload));
//             showSuccess(ACTION_API_TYPES.EDIT);
//         } else {
//             showError(status, data);
//         }
//     }
//     yield callLoading(doRQ);
// }

function* menusSaga() {
    // yield takeLatest(menusActions.doGetListMenu.type, sagaGetListMenu);
    // yield takeLatest(menusActions.doDeleteMenu.type, sagaDeleteMenu);
    // yield takeLatest(menusActions.doUpdateStatus.type, sagaUpdateStatusMenu);
}

export default menusSaga;
