import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

const propTypes = {
    onChange: PropTypes.func,
};

function BaseSelect({
    onChange,
    label,
    list,
    selectId,
    idField,
    nameField,
    disabled,
}) {
    list = list || [];
    selectId = selectId || '';
    const onSearch = (input, option) => {
        if (option) {
            return (
                nonAccentVietnamese(option.children.replace(/\s/g, ''))
                    .toLowerCase()
                    .indexOf(
                        nonAccentVietnamese(
                            input.replace(/\s/g, '')
                        ).toLowerCase()
                    ) >= 0
            );
        }
        return false;
    };

    const handleChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    const showOptionItem = () => {
        let xhtml = null;
        if (list.length > 0) {
            xhtml = list.map((item) => (
                <Option value={item[idField]} key={item[idField]}>
                    {item[nameField]}
                </Option>
            ));
        }
        return xhtml;
    };

    return (
        <>
            {label ? (
                <>
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                    <div className="clearfix mb20" />
                </>
            ) : null}
            <Select
                value={selectId}
                onChange={(value) => handleChange(value)}
                optionFilterProp="children"
                filterOption={(input, option) => onSearch(input, option)}
                showSearch
                disabled={disabled}
                className="disable-modified"
            >
                {showOptionItem()}
            </Select>
        </>
    );
}

BaseSelect.propTypes = propTypes;

export default BaseSelect;
