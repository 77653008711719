import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './inventory.constant';
import * as apiConst from '@app/constants/api';
import {
    deleteInventorySuccess,
    getInventorySuccess,
    getMenuSuccess,
    saveInventorySuccess,
} from './inventory.action';
import { showError, showSuccess } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import {
    deleteReceipt,
    get,
    saveFoodReceiptMenu,
    saveFoodReceiptMenuOut,
} from '@app/apis/inventory';
//import { apiGetListGeneral } from '@app/apis/foodkid/foodkid';
import { ACTION_API_TYPES } from '@app/constants/';
import _fkMenuApis from '@app/apis/foodkid/menu.api';

function* sagaGetList({ payload }) {
    function* doRQ() {
        const res = yield call(get, payload.data);
        const { data, status } = res;
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(getInventorySuccess(data.data));
        } else {
            showError(status, data);
        }
    }
    yield callLoading(doRQ);
}

function* sagaGetListMenu({ payload }) {
    function* doRQ() {
        //const res = yield call(apiGetListGeneral, payload.data);
        const res = yield call(
            _fkMenuApis.getMenuByDateAndKidGroup,
            payload.data
        );
        const { data, status } = res;
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(
                getMenuSuccess(
                    data.data,
                    payload.type,
                    payload.warehouse,
                    payload.creatorName
                )
            );
        } else {
            showError(status, data);
        }
    }
    yield callLoading(doRQ);
}

function* sagaSaveReceipt({ payload }) {
    function* doRQ() {
        const res = yield call(
            payload.data.w_type === 1
                ? saveFoodReceiptMenu
                : saveFoodReceiptMenuOut,
            payload.data
        );
        const { data, status } = res;
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(saveInventorySuccess(data.datas));
        } else {
            showError(status, data);
        }
    }
    yield callLoading(doRQ);
}

function* sagaDeleteMany({ payload }) {
    const res = yield call(deleteReceipt, payload.data);
    const { data, status } = res;
    if (status === apiConst.STATUS_CODES.SUCCESS) {
        showSuccess(ACTION_API_TYPES.DELETE);
        yield put(deleteInventorySuccess(payload.data));
    } else {
        showError(status, data);
    }
}

function* inventorySaga() {
    yield takeEvery(types.GET_INVENTORY, sagaGetList);
    yield takeEvery(types.GET_MENU, sagaGetListMenu);
    yield takeEvery(types.SAVE_INVENTORY, sagaSaveReceipt);
    yield takeEvery(types.DELETE_INVENTORY, sagaDeleteMany);
}

export default inventorySaga;
