/* eslint-disable eqeqeq */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { nonAccentVietnamese } from '../../../../utils/utils';

const propTypes = {
    /**
     * Danh sách item truyền vào
     */
    dataSource: PropTypes.arrayOf(PropTypes.shape()),
    /**
     * Field giá trị
     */
    fieldId: PropTypes.string,
    /**
     * Field tên dùng để hiển thị
     */
    fieldName: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.node,
};
const { Option } = Select;

/**
 * Select dùng để dùng chung
 */
const CommonSelect = ({
    dataSource,
    fieldId,
    fieldName,
    defaultValue,
    onChange,
    disabled,
    label,
}) => {
    const [selectId, setSelectId] = useState('');
    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        if (dataSource && dataSource.length > 0) {
            setIsMount(true);
            let _id = '';
            if (defaultValue) {
                const objCheck = dataSource.find(
                    (o) => o[fieldId] == defaultValue
                );

                if (objCheck) {
                    _id = defaultValue;
                }
            } else if (dataSource.length > 0) {
                _id = dataSource[0][fieldId];
                // Trường hợp không truyền giá trị mặc định thì gửi giá trị ra bên ngoài thông qua onChange
                if (onChange) {
                    onChange(_id);
                }
            }
            setSelectId(_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource]);

    /**
     * Dùng để kiểm tra khi giá trị mặc định (default) thay đổi
     */
    useEffect(() => {
        if (defaultValue && dataSource && isMount) {
            const objCheck = dataSource.find((o) => o[fieldId] == defaultValue);
            if (objCheck) {
                setSelectId(defaultValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const showOptionItem = () => {
        let xhtml = null;
        if (dataSource && dataSource.length > 0) {
            xhtml = dataSource.map((item) => (
                <Option value={item[fieldId]} key={item[fieldId]}>
                    {item[fieldName]}
                </Option>
            ));
        }

        return xhtml;
    };

    const handleChange = (value) => {
        setSelectId(value);
        if (onChange) {
            onChange(value);
        }
    };

    const onSearch = (input, option) => {
        if (option) {
            return (
                nonAccentVietnamese(option.children.replace(/\s/g, ''))
                    .toLowerCase()
                    .indexOf(
                        nonAccentVietnamese(
                            input.replace(/\s/g, '')
                        ).toLowerCase()
                    ) >= 0
            );
        }
        return false;
    };

    return (
        <Fragment>
            {label ? (
                <Fragment>
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                    <div className="clearfix mb20" />
                </Fragment>
            ) : null}
            <Select
                value={selectId}
                onChange={(value) => handleChange(value)}
                optionFilterProp="children"
                filterOption={(input, option) => onSearch(input, option)}
                showSearch
                disabled={disabled}
                className="disable-modified"
            >
                {showOptionItem()}
            </Select>
        </Fragment>
    );
};

CommonSelect.propTypes = propTypes;

export default CommonSelect;
