import React from 'react';
import RenderField from '@app/components/Layout/Shares/FormHelpers/TextField';
import { Field } from 'redux-form';

const AccountLoginInfo = () => (
    <div className="panel panel-white border-top-lg border-top-success">
        <div className="panel-heading">
            <h6 className="panel-title text-semibold">THÔNG TIN TÀI KHOẢN</h6>
        </div>
        <div className="panel-body">
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <Field
                            name="su_email"
                            component={RenderField}
                            type="text"
                            className="form-control"
                            label="Tên đăng nhập"
                            disabled="disabled"
                            requiredChar="*"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <Field
                            name="su_password"
                            component={RenderField}
                            type="password"
                            className="form-control"
                            label="Mật khẩu"
                            requiredChar="*"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default AccountLoginInfo;
