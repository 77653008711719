import * as types from './constants';
import * as _ from 'lodash';
import { LocationModel } from '../../../models/location/location.model';
import { LOCATION_TYPES } from '../../../constants';

const initState = {
    cityList: [],
    editItem: new LocationModel(),
    currentPage: 1,
    total: 0,
    isShowModal: false,
    citySelectList: [],
    currentCitySelect: -1,
    id: '',
    currentItem: new LocationModel(),
};

const cityReducerV2 = (state = initState, action) => {
    const { payload } = action;
    switch (action.type) {
        case types.GET_CITIES_SUCCESS: {
            const { list, total } = payload.data;

            return {
                ...state,
                cityList: list,
                total,
            };
        }
        case types.ADD_CITY: {
            const { countryId } = payload;
            const { cityList } = state;
            return {
                ...state,
                isShowModal: true,
                editItem: new LocationModel({
                    l_sortorder:
                        cityList && cityList.length > 0
                            ? cityList[cityList.length - 1].l_sortorder + 1
                            : 1,
                    l_parentid: countryId,
                    l_type: LOCATION_TYPES.CITY,
                }),
            };
        }

        case types.EDIT_CITY: {
            const { data } = action.payload;

            return {
                ...state,
                isShowModal: true,
                editItem: new LocationModel(data),
            };
        }

        case types.OPEN_CITY_MODAL: {
            return {
                ...state,
                isShowModal: true,
            };
        }

        case types.CLOSE_CITY_MODAL: {
            return {
                ...state,
                isShowModal: false,
            };
        }
        case types.SAVE_CITY_SUCCESS: {
            const { data } = payload.data;
            const { saveType } = action.payload;
            let { cityList, total } = state;

            if (saveType) {
                cityList = [...cityList, data];
                total += 1;
                cityList = _.take(
                    _.orderBy(cityList, ['l_sortorder'], ['asc']),
                    10
                );
            } else {
                cityList = cityList.map((item) => {
                    if (item.id === data.id) {
                        item = {
                            item,
                            ...data,
                        };
                    }
                    return item;
                });
            }
            return {
                ...state,
                cityList,
                total,
                editItem: new LocationModel({ l_sortorder: total + 1 }),
                isShowModal: saveType,
            };
        }

        case types.DELETE_CITY_SUCCESS: {
            const { id } = action.payload;
            let { currentPage } = state;
            const cityList = _.take(
                _.orderBy(
                    state.cityList.filter((item) => item.l_id !== id),
                    ['l_sortorder'],
                    ['asc']
                ),
                10
            );
            if (currentPage !== 1 && cityList.length === 0) {
                currentPage -= 1;
            }

            return {
                ...state,
                cityList,
                total: state.total - 1,
                currentPage,
            };
        }

        case types.GET_CITY_SELECT_LIST_SUCCESS: {
            const { data, defaultValue } = action.payload;
            let { citySelectList } = state;
            citySelectList = _.orderBy(data, ['l_sortorder'], ['asc']);
            let { currentCitySelect } = state;
            if (defaultValue) {
                currentCitySelect = defaultValue;
            } else {
                currentCitySelect =
                    citySelectList.length === 0 ? -1 : citySelectList[0].l_id;
            }
            return {
                ...state,
                citySelectList,
                currentCitySelect,
            };
        }

        case types.CITY_SELECT_CHANGE: {
            const { id } = action.payload;
            return {
                ...state,
                currentCitySelect: id,
                id,
            };
        }

        case types.CITY_RESET_SELECT_LIST: {
            return {
                ...state,
                citySelectList: [],
                cityList: [],
                id: '',
                total: 0,
                currentCitySelect: '',
            };
        }

        case types.PAGE_CHANGE: {
            const { page } = payload;
            return {
                ...state,
                currentPage: page,
            };
        }

        case types.RESET: {
            return {
                ...state,
                currentPage: 1,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default cityReducerV2;
