import * as types from './copymenu.constant';

export const doGetListMenuBySchool = (data) => ({
    type: types.GET_LIST_MENU_BY_SCHOOL,
    payload: { data },
});

export const doGetListMenuBySchoolSuccess = (data) => ({
    type: types.GET_LIST_MENU_BY_SCHOOL_SUCCESS,
    payload: { data },
});

export const doGetKidGroup = (id) => ({
    type: types.GET_KIDGROUP,
    payload: { id },
});

export const doGetKidGroupSuccess = (data) => ({
    type: types.GET_KIDGROUP_SUCCESS,
    payload: { data },
});

export const doUpdateMenuResult = (data) => ({
    type: types.UPDATE_MENU_NEW,
    payload: { data },
});

export const doSaveMenuSample = (data) => ({
    type: types.SAVE_MENU_SAMPLE,
    payload: { data },
});

export const doSaveMenuSampleSuccess = (data) => ({
    type: types.SAVE_MENU_SAMPLE_SUCCESS,
    payload: { data },
});

export const doChangeSchoolCopy = (data) => ({
    type: types.CHANGE_SCHOOL_COPY,
    payload: { data },
});

/** ======== TÍCH CHỌN THỰC ĐƠN ======== */
export const doChangeSelectedRow = (data) => ({
    type: types.CHANGE_SELECTED_ROW,
    payload: { data },
});

export const doOpenImport = () => ({
    type: types.OPEN_MODAL_IMPORT,
});
export const doCloseImport = () => ({
    type: types.CLOSE_MODAL_IMPORT,
});

export const doImportMenuFromFile = (data) => ({
    type: types.IMPORT_MENU_FROM_FILE,
    payload: { data },
});
export const doImportMenuFromFileSuccess = () => ({
    type: types.IMPORT_MENU_FROM_FILE_SUCCESS,
});

export const doReset = () => ({
    type: types.RESET,
});
