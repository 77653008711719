import * as types from './constants';
import { LOCATION_TYPES } from '../../../constants';
import * as _ from 'lodash';
import { LocationModel } from '../../../models/location/location.model';

const initState = {
    list: [],
    wardSelectList: [],
    isShowModal: false,
    editItem: {},
    currentSelectId: '',
    id: '',
};

const wardReducerV2 = (state = initState, action) => {
    const { payload, type } = action;
    switch (type) {
        case types.GET_WARDS_SUCCESS: {
            const { list } = action.payload;
            return {
                ...state,
                list,
            };
        }

        case types.EDIT_WARD: {
            const { data } = action.payload;
            return {
                ...state,
                editItem: new LocationModel(data),
                isShowModal: true,
            };
        }

        case types.SAVE_WARD_SUCCESS: {
            const { data, isAdd } = action.payload;

            let { list } = state;
            if (isAdd) {
                list = [...list, data];
            } else {
                list = list.map((item) => {
                    if (item.id === data.id) {
                        item = {
                            ...item,
                            ...data,
                        };
                    }
                    return item;
                });
            }
            list = _.orderBy(list, ['l_sortorder'], ['asc']);
            const editItem = new LocationModel({
                l_type: LOCATION_TYPES.WARD,
                l_parentid: data.l_parentid,
                l_sortorder: list.length + 1,
            });
            return {
                ...state,
                list,
                isShowModal: isAdd,
                editItem,
            };
        }

        case types.DELETE_WARD_SUCCESS: {
            const { id } = action.payload;
            const list = _.remove(state.list, (item) => item.l_id !== id);
            return {
                ...state,
                list,
            };
        }

        case types.ADD_NEW: {
            const { districtId } = payload;
            const { list } = state;
            const editItem = new LocationModel({
                l_sortorder: list.length + 1,
                l_parentid: districtId,
                l_type: LOCATION_TYPES.WARD,
            });
            return {
                ...state,
                isShowModal: true,
                editItem,
            };
        }
        case types.CLOSE_WARD_MODAL: {
            return {
                ...state,
                isShowModal: false,
                editItem: new LocationModel(),
            };
        }
        case types.GET_WARD_SELECT_LIST_SUCCESS: {
            const { data, defaultValue } = action.payload;
            let currentSelectId = '';
            if (defaultValue) {
                currentSelectId = defaultValue;
            } else {
                currentSelectId = data.length === 0 ? -1 : data[0].l_id;
            }
            return {
                ...state,
                wardSelectList: data,
                currentSelectId,
            };
        }
        case types.WARD_SELECT_CHANGE: {
            const { id } = action.payload;
            return {
                ...state,
                currentSelectId: id,
            };
        }
        case types.CLEAN_SELECT_LIST: {
            return {
                ...state,
                wardSelectList: [],
                currentWardSelect: {},
                id: '',
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default wardReducerV2;
