import NoData from '@app/components/Layout/Shares/NoData';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import ButtonModalAction from '@app/components/UI/Button/ButtonModalAction';
import { agencyActions, agencySelector } from '@app/redux/agency/agency.slice';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from '@app/components/_Share/Pagination';
import ModalListSchoolItem from './ModalListSchoolItem';

const ModalListSchool = ({ isShow, onCloseModal }) => {
    const dispatch = useDispatch();

    const total = useSelector(agencySelector.totalSchOfAgency);
    const currentPage = useSelector(agencySelector.currentPageModalSchool);
    const currentAgency = useSelector(agencySelector.currentAgency);
    const listSchOfAgency = useSelector(agencySelector.listSchOfAgency);
    const listOrgScale = useSelector(agencySelector.listOrgScale);
    const listOrgType = useSelector(agencySelector.listOrgType);

    useEffect(() => {
        dispatch(
            agencyActions.doGetListSchOfAgency({
                o_id: currentAgency?.o_id,
                currentPage,
                keyword: '',
                isConnected: true,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, dispatch]);

    const onChangePage = (page) => {
        dispatch(agencyActions.doChangePageListSch(page));
    };

    const renderStatus = (status) => {
        const objStatus = {
            classLabel: '',
            classIcon: '',
            label: '',
        };
        switch (status) {
            case 1: {
                objStatus.classLabel = 'label-primary';
                objStatus.classIcon = 'icon-hour-glass2';
                objStatus.label = 'Chờ đơn vị xác nhận';

                break;
            }
            case 2: {
                objStatus.classLabel = 'label-success';
                objStatus.classIcon = 'icon-checkmark4';
                objStatus.label = 'Kết nối thành công';

                break;
            }
            case 3: {
                objStatus.classLabel = 'label-danger';
                objStatus.classIcon = 'icon-x';
                objStatus.label = 'Đơn vị yêu cầu hủy kết nối';

                break;
            }
            default:
        }

        return objStatus;
    };

    const showListSchool = () => {
        if (_.isEmpty(listSchOfAgency)) return <NoData colspan={3} />;

        return listSchOfAgency.map((item, idx) => {
            const scaleName = listOrgScale.find(
                (sCale) => sCale.so_id === item.so_id
            )?.so_name;

            const typeName = listOrgType.find(
                (sCale) => sCale.to_id === item.to_id
            )?.to_name;

            const objStatus = renderStatus(item.o_connectionstatus);

            return (
                <ModalListSchoolItem
                    sortOrder={(currentPage - 1) * 10 + idx + 1}
                    schOfAgency={{ ...item, scaleName, typeName, objStatus }}
                />
            );
        });
    };

    return (
        <Modal
            show={isShow}
            onHide={onCloseModal}
            animation
            backdrop="static"
            size="lg"
            className={listSchOfAgency?.length >= 10 ? 'fit-screen' : ''}
        >
            <Modal.Header closeButton className="bg-primary-800">
                <Modal.Title>Danh sách đơn vị</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-togglable table-hover table-bordered">
                    <thead className="grey-light">
                        <tr>
                            <th
                                className="text-center"
                                style={{ width: '35px' }}
                            >
                                STT
                            </th>
                            <th className="text-center">
                                Tên đơn vị
                                <span className="display-block text-muted">
                                    Địa chỉ
                                </span>
                            </th>
                            <th
                                className="text-center"
                                style={{ width: '6%' }}
                                data-hide="phone"
                            >
                                Tình trạng
                            </th>
                        </tr>
                    </thead>
                    <tbody>{showListSchool()}</tbody>
                </table>
                <CustomPagination
                    total={total}
                    pageSize={10}
                    currentPage={currentPage}
                    currentCount={listSchOfAgency.length}
                    onChange={onChangePage}
                />
            </Modal.Body>
            <Modal.Footer>
                <ButtonModalAction
                    buttonType={BUTTON_ACTION_TYPE.CLOSE}
                    buttonLabel="Thoát"
                    onClick={onCloseModal}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ModalListSchool;
