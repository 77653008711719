/**
 * Dữ liệu gửi lên dùng để sử dụng trung chuyển của service API Gateway
 */
export class ApiGatewayDataPost {
    constructor(data) {
        if (!data) {
            data = {};
        }
        /** Api Service */
        this.service = data.service || '';

        /** Controller */
        this.controller = data.controller || '';

        /** Action */
        this.action = data.action || '';

        /** Dữ liệu gửi lên */
        this.dataPost = data.dataPost || '';
    }
}
