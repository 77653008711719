import { PAYMENT_VOUCHER_MODULE_NAME } from './constants';

const MODULE_NAME = PAYMENT_VOUCHER_MODULE_NAME;

const PaymentVoucherSelector = {
    isShowPopup: (state) => state[MODULE_NAME].isShowPopup,
    editItem: (state) => state[MODULE_NAME].editItem,
    listData: (state) => state[MODULE_NAME].listData,
    total: (state) => state[MODULE_NAME].total,
    currentPage: (state) => state[MODULE_NAME].currentPage,
    pageSize: (state) => state[MODULE_NAME].pageSize,
    isCheckAll: (state) => state[MODULE_NAME].isCheckAll,
};

export default PaymentVoucherSelector;
