import { takeLatest, call, put } from 'redux-saga/effects';

// import {
//     saveAccountRoleSuccess,
//     deleteAccountRoleSuccess,
//     getAccountRoleByIdSuccess,
//     getAccountRoleSelectListSuccess,
// } from './actions';
import { accountPrivilegeActions } from './accountPrivilege.slice';
import { showError } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import { STATUS_CODES } from '@app/constants/api';
import {
    apiGetListPrivilege,
    apiUpdatePrivilege,
} from '@app/apis/accounts/accountPrivilege';

function* sgGetListAccountPrivilege({ payload }) {
    yield callLoading(function* doRequest() {
        const { data, status } = yield call(apiGetListPrivilege, payload);

        if (status === STATUS_CODES.SUCCESS) {
            yield put(
                accountPrivilegeActions.doGetAccountPrivilegeSuccess(data.data)
            );
        } else {
            showError(status, data);
        }
    });
}

function* sgUpdateAccountPrivilege({ payload }) {
    const { data, status } = yield call(apiUpdatePrivilege, payload);

    if (status === STATUS_CODES.SUCCESS) {
        yield put(
            accountPrivilegeActions.doUpdateAccountPrivilegeSuccess(data.data)
        );
    } else {
        showError(status, data);
    }
}

function* AccountPrivilegeSaga() {
    yield takeLatest(
        accountPrivilegeActions.doGetAccountPrivilege.type,
        sgGetListAccountPrivilege
    );
    yield takeLatest(
        accountPrivilegeActions.doUpdateAccountPrivilege.type,
        sgUpdateAccountPrivilege
    );
}

export default AccountPrivilegeSaga;
