import React from 'react';
import PropTypes from 'prop-types';
import SchoolYearSelect from '@app/components/_Share/Select/SchoolYearSelect/Dynamic';
import MonthDynamicSelect from '@app/components/_Share/Select/MonthSelect/Dynamic';
import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

const propTypes = {
    onYearChange: PropTypes.func,
    onMonthChange: PropTypes.func,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    isDisable: PropTypes.bool,
};

const Filter = ({
    onYearChange,
    onMonthChange,
    isDisable,
    onAdd,
    onDelete,
    oId,
}) => (
    <div className="row">
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-2 col-sm-4">
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-addon">Năm học</span>
                            <SchoolYearSelect
                                onChange={(_id) => onYearChange(_id)}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-2 col-sm-2">
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-addon">Tháng học</span>
                            <MonthDynamicSelect
                                onChange={(_id) => onMonthChange(_id)}
                                oId={oId}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-sm-6">
                    <div className="form-group">
                        <div className="no-margin">
                            <div className="btn-group">
                                <ButtonFilterAction
                                    labelButton="Thêm"
                                    buttonType={BUTTON_ACTION_TYPE.ADD}
                                    disabled={isDisable}
                                    onClick={() => onAdd()}
                                />

                                <ButtonFilterAction
                                    labelButton="Xóa"
                                    buttonType={BUTTON_ACTION_TYPE.DELETE}
                                    disabled={isDisable}
                                    onClick={() => onDelete()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

Filter.propTypes = propTypes;

export default Filter;
