import { createAction } from '@reduxjs/toolkit';
import * as types from './constants';

/**
 * Lấy danh sách phiếu chi theo ngày
 */
export const doGetListByDate = createAction(
    types.GET_LIST_BY_DATE,
    (dataPost) => ({
        payload: {
            dataPost,
        },
    })
);

/**
 * Lấy tổng số phiếu chi
 */
export const doGetTotalOfListByDate = createAction(
    types.GET_TOTAL_OF_LIST_BY_DATE,
    (dataPost) => ({
        payload: {
            dataPost,
        },
    })
);

/**
 * Lưu phiếu chi
 */
export const doSave = createAction(types.SAVE, (dataPost) => ({
    payload: {
        dataPost,
    },
}));

/**
 * Xóa danh sách phiếu chi
 */
export const doDelete = createAction(types.DELETE, (dataPost) => ({
    payload: {
        dataPost,
    },
}));
