import React, { memo } from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';

const propTypes = {
    colspan: PropTypes.number,
};
const NoData = ({ colspan }) => (
    <tr colSpan={colspan}>
        <td
            colSpan={colspan}
            className="text-center"
            style={{ padding: '20px' }}
        >
            <Empty description="Chưa có dữ liệu" />
        </td>
    </tr>
);

NoData.propTypes = propTypes;

export default memo(NoData);
