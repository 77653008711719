import axiosService from '@app/services/axiosService';
import { API_ACCOUNT_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_ACCOUNT_ENDPOINT}/v1/ScAccount/ScUser`;

const LIST_URL = {
    GET_LIST: `${BASE_URL}/GetAccountUserServiceByRole`,
    SAVE: `${BASE_URL}/SaveAccountUserService`,
    DELETE: `${BASE_URL}/DeleteAccountUser`,
    RESET_PASSWORD: `${BASE_URL}/ResetPassAccountUser`,
};

/* ======= Lấy danh sách tài khoản người dùng ======== */
export const apiGetListAccount = (roleId) =>
    axiosService.get(`${LIST_URL.GET_LIST}/${roleId}`);

/* ======= Lưu tài khoản người dùng ======== */
export const apiSaveAccount = (data) => axiosService.post(LIST_URL.SAVE, data);

/* ======= Xóa tài khoản người dùng ======== */
export const apiDeleteAccount = (id) =>
    axiosService.delete(`${LIST_URL.DELETE}/${id}`);

/* ======= Xóa tài khoản người dùng ======== */
export const apiResetPasswordAccount = (data) =>
    axiosService.post(LIST_URL.RESET_PASSWORD, data);
