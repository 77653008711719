import axiosService from '@app/services/axiosService';
import { API_ACCOUNT_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_ACCOUNT_ENDPOINT}/v1/sc/ScFunctionCategory`;

const LIST_URL = {
    GET_LIST_BY_MODULE: `${BASE_URL}/GetByModule`,
};

/* ======= Lấy danh sách nhóm tính năng theo module code ======== */
export const apiGetFuncCategoryByModule = (moduleCode) =>
    axiosService.get(`${LIST_URL.GET_LIST_BY_MODULE}/${moduleCode}`);
