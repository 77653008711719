import * as types from './organization.constant';

export const doFetchDataFirst = () => ({
    type: types.FETCH_DATA_FIRST,
});

export const doFetchDataFirstSuccess = (data) => ({
    type: types.FETCH_DATA_FIRST_SUCCESS,
    payload: { data },
});

export const doGetList = (data) => ({
    type: types.GET_LIST,
    payload: { data },
});

export const doGetListSuccess = (data) => ({
    type: types.GET_LIST_SUCCESS,
    payload: { data },
});

export const doSaveLinkedSchool = (data) => ({
    type: types.SAVE_LINKED_SCHOOL,
    payload: { data },
});

export const doSaveLinkedSchoolSuccess = (data) => ({
    type: types.SAVE_LINKED_SCHOOL_SUCCESS,
    payload: { data },
});

export const doUpdateConnectStatus = (data) => ({
    type: types.UPDATE_CONNECTION_STATUS,
    payload: { data },
});

export const doUpdateConnectStatusSuccess = (data) => ({
    type: types.UPDATE_CONNECTION_STATUS_SUCCESS,
    payload: { data },
});

export const doOpenModal = () => ({
    type: types.OPEN_MODAL,
});

export const doCloseModal = () => ({
    type: types.CLOSE_MODAL,
});

export const doChangePage = (data) => ({
    type: types.CHANGE_PAGE,
    payload: { data },
});

export const doChangeKeyWord = (data) => ({
    type: types.CHANGE_KEY_WORD,
    payload: { data },
});

export const doCopySetting = (data) => ({
    type: types.COPY_SETTING,
    payload: { data },
});

export const doReset = () => ({
    type: types.RESET,
});
