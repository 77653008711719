import myIcon from '@app/assets/images/icons/ic/calculator.svg';
import paymentVoucherIcon from '@app/assets/images/icons/ic/finance.svg';
import Receipt from '@app/components/Income/Receipt';
import React from 'react';
import { ADMIN_FUNCTIONS, SC_MODULES } from '@app/constants/sc.modules';
import PaymentVoucherPage from '@app/components/Income/PaymentVoucherPage';

/**
 * Chương trình
 */
const receiptsRoutes = [
    {
        name: 'LẬP PHIẾU THU',
        icon: myIcon,
        isMenu: true,
        isRoute: true,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        link: '/receipt',
        path: '/receipt',
        main: () => <Receipt />,
        functionId: ADMIN_FUNCTIONS.RECEIPTS,
    },
    {
        name: 'LẬP PHIẾU CHI',
        icon: paymentVoucherIcon,
        isMenu: true,
        isRoute: true,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        link: '/paymentVoucher',
        path: '/paymentVoucher',
        main: () => <PaymentVoucherPage />,
        functionId: ADMIN_FUNCTIONS.RECEIPTS,
    },
];

export default receiptsRoutes;
