import axiosService from '@app/services/axiosService';
import { API_ACCOUNT_ENDPOINT } from '@app/constants/api';

const BASE_URL = `${API_ACCOUNT_ENDPOINT}/v1/sc/ScFunction`;

const LIST_URL = {
    GET_LIST_BY_CATE: `${BASE_URL}/GetListByCate`,
};

/* ======= Lấy danh sách tính năng ======== */
export const apiGetFunctionByCate = (moduleCode, cateCode) =>
    axiosService.get(`${LIST_URL.GET_LIST_BY_CATE}/${moduleCode}/${cateCode}`);
