import { callLoading } from '@app/utils/saga.utils';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from './constansts';
import { apiDoGetListByOId } from '@app/apis/schools/class';
import { doGetClassListSuccess } from '@app/redux/attendance/slice';
import { showError } from '@app/utils/alert';

/**
 * Gọi API lấy danh sách lớp học
 * @param {Object} payload
 * @param {Object} payload.dataPost
 * @param {string} payload.dataPost.o_id: Id tổ chức/trường học
 * @param {number} payload.dataPost.sy_id: Id năm học
 */
function* sgGetClassList({ payload }) {
    yield callLoading(function* doRQ() {
        const { dataPost } = payload;
        const { data, status } = yield call(apiDoGetListByOId, dataPost);
        if (status === 200) {
            yield put(doGetClassListSuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* attendanceSaga() {
    yield takeLatest(types.GET_CLASS_LIST, sgGetClassList);
}

export default attendanceSaga;
