import React, { Fragment, useState, useEffect, useMemo } from 'react';
import PageHeader from '@app/components/Layout/PageHeader/PageHeader';
import Filter from './Filter';
import List from './List';
import { useDispatch, useSelector } from 'react-redux';
import * as _mainActions from '@app/redux/attendance/sagaActions';
import _attendanceSelector from '@app/redux/attendance/selector';
import _schoolSelector from '@app/redux/school/school.selector';
import { swal, swalClose, swalLoading, SWAL_CONST } from '@app/utils/alert';
import { doChangeProcess, doResetList } from '@app/redux/attendance/slice';
import _icAttandanceApis from '@app/apis/income/attendance.api';

const Attendance = () => {
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const classList = useSelector(_attendanceSelector.classList);
    const oId = useSelector(_schoolSelector.currentSchool);

    const listCheck =
        useMemo(() => classList.filter((o) => o.isCheck), [classList]) || [];

    const dispatch = useDispatch();

    useEffect(() => {
        if (year) {
            const dataPost = {
                o_id: oId,
                sy_id: year,
            };
            dispatch(_mainActions.doGetClassList(dataPost));
        }
    }, [dispatch, oId, year]);

    useEffect(() => {
        if (month) {
            dispatch(doResetList());
        }
    }, [dispatch, month]);

    useEffect(
        () => () => {
            dispatch(doResetList());
        },
        [dispatch]
    );

    const onChangeYear = (_id) => {
        setYear(_id);
    };
    const onMonthChange = (_id) => {
        setMonth(_id);
    };

    // Thêm bảng điểm danh
    const onAdd = async () => {
        if (listCheck?.length === 0) {
            swal('Thông báo', 'Bạn chưa chọn lớp cần lập bảng điểm danh');
            // eslint-disable-next-line no-empty
        } else {
            dispatch(
                doChangeProcess({
                    list: listCheck,
                    process: 'processing',
                })
            );
            const baseData = {
                yearId: year,
                month,
                oId,
            };

            const dataPost = {
                ...baseData,
                classList: listCheck,
            };

            swalLoading('Đang thực hiện tạo bảng điểm danh');

            const { data, status } = await _icAttandanceApis.create(dataPost);
            let process = 'done';
            if (status !== 200) {
                swal(SWAL_CONST.TYPE.ERROR, data.msg, false);
                process = 'error';
            } else {
                swalClose();
            }

            dispatch(
                doChangeProcess({
                    list: listCheck,
                    process,
                })
            );

            // eslint-disable-next-line no-restricted-syntax
            // for (const elem of listCheck) {
            //     console.log(elem);

            //     // eslint-disable-next-line no-await-in-loop
            //     // const { data, status } = await apiCreateAttendance(dataPost);
            //     // let process = 'done';
            //     // if (status !== 200) {
            //     //     process = 'error';
            //     //     elem = {
            //     //         ...elem,
            //     //         msg: data.msg,
            //     //     };
            //     // }

            //     // dispatch(
            //     //     doChangeProcess({
            //     //         list: [elem],
            //     //         process,
            //     //     })
            //     // );
            // }
        }
    };

    // Xóa bảng điểm danh
    const onDelete = async () => {
        if (listCheck?.length === 0) {
            swal('Thông báo', 'Bạn chưa chọn lớp cần xóa bảng điểm danh');
            // eslint-disable-next-line no-empty
        } else {
            dispatch(
                doChangeProcess({
                    list: listCheck,
                    process: 'delete_processing',
                })
            );
            const baseData = {
                yearId: year,
                month,
                oId,
            };
            // eslint-disable-next-line no-restricted-syntax
            for (let elem of listCheck) {
                const dataPost = {
                    ...baseData,
                    classId: elem.c_id,
                };
                // eslint-disable-next-line no-await-in-loop
                const { data, status } = await _icAttandanceApis.delete(
                    dataPost
                );
                let process = 'delete_done';
                if (status !== 200) {
                    process = 'delete_error';
                    elem = {
                        ...elem,
                        msg: data.msg,
                    };
                }

                dispatch(
                    doChangeProcess({
                        list: [elem],
                        process,
                    })
                );
            }
        }
    };

    return (
        <Fragment>
            <PageHeader pageTitle="TẠO BẢNG ĐIỂM DANH TỰ ĐỘNG" />
            <div className="content">
                <Filter
                    onYearChange={(_id) => onChangeYear(_id)}
                    onMonthChange={(_id) => onMonthChange(_id)}
                    isDisable={listCheck?.length === 0}
                    onAdd={() => onAdd()}
                    onDelete={() => onDelete()}
                    oId={oId}
                />

                <div className="row">
                    <div className="col-lg-12">
                        <List dataSource={classList} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Attendance;
