import stringUtils from './string.utils';

const locationUtils = {
    /** Lấy đầy đủ thông tin địa phương */
    getFullNameById(locationId, listLocation) {
        if (!locationId || !listLocation?.length) return '';

        const listSplitId = this.splitLocationId(locationId);
        if (listSplitId.length === 0) return '';

        let fullName = '';

        listSplitId.forEach((elm) => {
            const objLocation = listLocation.find((o) => o.l_id === elm);
            if (objLocation) {
                fullName += `${objLocation.l_name}, `;
            }
        });

        if (fullName) {
            fullName = stringUtils.removeLastChar(fullName);
        }

        return fullName;
    },
    splitLocationId(locationId) {
        if (!locationId) return [];
        const arrLocation = locationId.split('.');
        const _length = arrLocation.length;
        /** Không đúng định dạng mã địa phương */
        switch (_length) {
            /** Mã phường xã */
            case 3: {
                return [
                    locationId,
                    `${arrLocation[0]}.${arrLocation[1]}`,
                    arrLocation[0],
                ];
            }
            case 2: {
                return [locationId, arrLocation[0]];
            }
            case 1: {
                return [locationId];
            }
            default: {
                return [];
            }
        }
    },
};

export default locationUtils;
