import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../Layout/PageHeader/PageHeader';
import OrganizationFilter from './OrganizationFilter';
import OrganizationItem from './OrganizationItem';
import OrganizationList from './OrganizationList';
import * as organizationActions from '@app/redux/organization/organization.action';
import OrganizationSelector from '@app/redux/organization/organization.selector';
import OrganizationModal from './OrganizationModal';
import _, { debounce } from 'lodash';
import NoData from '@app/components/Layout/Shares/NoData';
import CrmPagination from '../_Share/Pagination';
import { swal, alertDeletSP } from '@app/utils/alert';
import _othersSelector from '@app/redux/others/selector';
import SchoolSelector from '@app/redux/school/school.selector';
import { getCurrentUser } from '@app/utils/utils';

const Organization = () => {
    const dispatch = useDispatch();

    const isShow = useSelector(OrganizationSelector.isShow);
    const total = useSelector(OrganizationSelector.total);
    const currentPage = useSelector(OrganizationSelector.currentPage);
    const keyword = useSelector(OrganizationSelector.keyword);
    const isLoad = useSelector(OrganizationSelector.isLoad);
    const editItem = useSelector(OrganizationSelector.editItem);

    const organizationList = useSelector(OrganizationSelector.organizationList);
    const organizationScale = useSelector(
        OrganizationSelector.organizationScale
    );
    const organizationType = useSelector(OrganizationSelector.organizationType);

    const organizationInfo = useSelector(_othersSelector.organizationInfo);
    const currentSchool = useSelector(SchoolSelector.currentSchool);

    const user = getCurrentUser();

    useEffect(() => {
        dispatch(organizationActions.doFetchDataFirst());
    }, [dispatch]);

    useEffect(() => {
        if (isLoad > 0) {
            dispatch(
                organizationActions.doGetList({
                    o_id: user?.o_id,
                    keyword: '',
                    currentPage: 1,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isLoad]);

    useEffect(() => {
        dispatch(
            organizationActions.doGetList({
                o_id: user?.o_id,
                keyword,
                currentPage,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentPage]);

    /** MỞ POPUP LIÊN KẾT ĐƠN VỊ */
    const onLinkToOr = () => {
        dispatch(organizationActions.doOpenModal());
    };

    /** ĐÓNG POPUP LIÊN KẾT ĐƠN VỊ */
    const onCloseModal = () => {
        dispatch(organizationActions.doCloseModal());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const keywordDebounce = useCallback(
        debounce(
            (nextValue) =>
                dispatch(organizationActions.doChangeKeyWord(nextValue)),
            250
        ),
        []
    );

    /** THAY ĐỔI TỪ KHÓA TÌM KIẾM */
    const onChangeKeyWord = (e) => {
        const key = e.target.value;
        keywordDebounce(key);
    };

    /** TÌM KIẾM */
    const onSearch = () => {
        dispatch(
            organizationActions.doGetList({
                o_id: user?.o_id,
                keyword,
                currentPage,
            })
        );
    };

    /** THAY ĐỔI TRANG */
    const onChangePage = (page) => {
        dispatch(organizationActions.doChangePage(page));
    };

    /** TẠO LIÊN KẾT TRƯỜNG VỚI DỊCH VỤ */
    const onSave = (data) => {
        dispatch(organizationActions.doSaveLinkedSchool(data));
    };

    const onCancelLinked = (data) => {
        swal(
            'question',
            `Bạn có chắc chắn muốn hủy liên kết với trường <span style="color:red;font-weight:550">${data.o_name.toUpperCase()}</span> không ?`,
            true
        ).then((res) => {
            if (res.value) {
                dispatch(organizationActions.doUpdateConnectStatus(data));
            }
        });
    };

    const onApproveCancel = (data) => {
        swal(
            'question',
            `Bạn có đồng ý với yêu cầu hủy kết nối của trường <span style="color:red;font-weight:550">${data.o_name.toUpperCase()}</span> không ?`,
            true
        ).then((res) => {
            if (res.value) {
                dispatch(organizationActions.doUpdateConnectStatus(data));
            }
        });
    };

    const onCopySetting = (data) => {
        alertDeletSP(
            `<p>Bạn đang thực hiện sao chép cấu hình  
            <span style="font-weight:550">${data.o_name}</span> 
            đến <span style="font-weight:550">${organizationInfo.o_name}</span></p>
            <p>Xin vui lòng nhập <b style="color:#FF7043">TÔI ĐỒNG Ý</b> để chắc chắn bạn muốn sao chép cấu hình từ đối tượng này<p>`
        ).then((res) => {
            if (res.value) {
                if (data.o_id === currentSchool) {
                    swal(
                        'warning',
                        'Xin vui lòng chọn đơn vị khác để sao chép !'
                    );
                } else {
                    const dataPost = {
                        fromSchool: data.o_id,
                        toSchool: currentSchool,
                    };

                    dispatch(organizationActions.doCopySetting(dataPost));
                }
            }
        });
    };

    /** HIỂN THỊ DỮ LIỆU */
    const showData = () => {
        if (_.isEmpty(organizationList)) return <NoData colspan={6} />;

        return organizationList.map((item, idx) => {
            const scaleName = organizationScale.find(
                (sCale) => sCale.so_id === item.so_id
            )?.so_name;

            const typeName = organizationType.find(
                (sCale) => sCale.to_id === item.to_id
            )?.to_name;

            return (
                <OrganizationItem
                    key={idx}
                    sortOrder={(currentPage - 1) * 10 + idx + 1}
                    organization={{ ...item, scaleName, typeName }}
                    onCopySetting={onCopySetting}
                    onCancelLinked={onCancelLinked}
                    onApproveCancel={onApproveCancel}
                />
            );
        });
    };

    return (
        <Fragment>
            <PageHeader pageTitle="Danh sách đơn vị" />
            <div className="content">
                <OrganizationFilter
                    onSearch={onSearch}
                    onLinkToOr={onLinkToOr}
                    onChangeKeyWord={onChangeKeyWord}
                />

                <OrganizationList>{showData()}</OrganizationList>
            </div>

            {isShow ? (
                <OrganizationModal
                    isShow={isShow}
                    initialValues={editItem}
                    onSave={onSave}
                    onCloseModal={onCloseModal}
                />
            ) : null}

            <CrmPagination
                total={total}
                pageSize={10}
                currentPage={currentPage}
                onChange={onChangePage}
                currentCount={organizationList.length}
            />
        </Fragment>
    );
};

export default Organization;
