import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;
export default class OnlySelect extends Component {
    showOptionItem = (list) => {
        const { select_id, select_name } = this.props;
        const xhtml = [];
        // xhtml.push(<Option value={0} key={0}>{hintText}</Option>)
        if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                xhtml.push(
                    <Option value={list[i][select_id]} key={list[i][select_id]}>
                        {list[i][select_name]}
                    </Option>
                );
            }
        }
        return xhtml;
    };

    render() {
        const {
            selectList,
            select_id,
            selectDefault,
            selectName,
            onChange,
            hintText,
            disabled,
            isBoolean,
            bordered,
        } = this.props;
        const check = isBoolean ? -1 : 0;
        const isExist =
            selectList.find((o) => o[select_id] === selectDefault) !==
            undefined;
        return (
            <Fragment>
                <label>
                    <h6 className="panel-title">{selectName}</h6>
                </label>
                {selectDefault > check && isExist ? (
                    <Select
                        bordered={!bordered}
                        defaultValue={selectDefault}
                        onChange={onChange}
                        value={selectDefault}
                        placeholder={hintText}
                        disabled={disabled}
                    >
                        {this.showOptionItem(selectList)}
                    </Select>
                ) : (
                    <Select
                        bordered={!bordered}
                        disabled={disabled}
                        onChange={onChange}
                        placeholder={hintText}
                    >
                        {this.showOptionItem(selectList)}
                    </Select>
                )}
            </Fragment>
        );
    }
}

OnlySelect.propTypes = {
    isBoolean: PropTypes.bool,
    disabled: PropTypes.bool,
};

OnlySelect.defaultProps = {
    isBoolean: false,
    disabled: false,
};
