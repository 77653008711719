import React, { Component } from 'react';
import PageHeader from '@app/components/Layout/PageHeader/PageHeader';

class NotAuthorizePage extends Component {
    render() {
        return <PageHeader pageTitle="Bạn không có quyền truy cập vào trang" />;
    }
}

export default NotAuthorizePage;
