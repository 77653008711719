const prefix = 'ORGANIZATION_';

export const FETCH_DATA_FIRST = `${prefix}FETCH_DATA_FIRST`;
export const FETCH_DATA_FIRST_SUCCESS = `${prefix}FETCH_DATA_FIRST_SUCCESS`;

export const GET_LIST = `${prefix}GET_LIST`;
export const GET_LIST_SUCCESS = `${prefix}GET_LIST_SUCCESS`;

export const SAVE_LINKED_SCHOOL = `${prefix}SAVE_LINKED_SCHOOL`;
export const SAVE_LINKED_SCHOOL_SUCCESS = `${prefix}SAVE_LINKED_SCHOOL_SUCCESS`;

export const OPEN_MODAL = `${prefix}OPEN_MODAL`;
export const CLOSE_MODAL = `${prefix}CLOSE_MODAL`;

export const CHANGE_PAGE = `${prefix}CHANGE_PAGE`;
export const CHANGE_KEY_WORD = `${prefix}CHANGE_KEY_WORD`;

export const UPDATE_CONNECTION_STATUS = `${prefix}UPDATE_CONNECTION_STATUS`;
export const UPDATE_CONNECTION_STATUS_SUCCESS = `${prefix}UPDATE_CONNECTION_STATUS_SUCCESS`;

export const COPY_SETTING = `${prefix}COPY_SETTING`;

export const RESET = `${prefix}RESET`;
