import React, { Fragment } from 'react';

const AccountRoleList = (props) => (
    <Fragment>
        <div className="row">
            <div className="col-lg-12">
                <div className="panel panel-white">
                    <table className="table table-togglable table-hover table-bordered table-striped">
                        <thead className="grey-light">
                            <tr>
                                <th
                                    className="text-center"
                                    data-toggle="true"
                                    style={{ width: '10%' }}
                                >
                                    STT
                                </th>
                                <th className="text-center">Tên vai trò</th>
                                <th
                                    className="text-center"
                                    style={{ width: '8%' }}
                                    data-hide="phone"
                                >
                                    Đang hoạt động
                                </th>
                                <th
                                    className="text-center"
                                    style={{ width: '5%' }}
                                >
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>{props.children}</tbody>
                    </table>
                </div>
            </div>
        </div>
    </Fragment>
);

export default AccountRoleList;
