import axiosService from '@app/services/axiosService';
import {
    // API_SC_FOODKID_ENPOINT,
    API_SC_GATEWAY_ENDPOINT,
} from '@app/constants/api';
import { ApiGatewayDataPost } from '@app/models/common/apiGatewayDataPost.model';

// const BASE_URL = `${API_SC_FOODKID_ENPOINT}/v1/sc/foodkids/mealService`;

const LIST_URL = {
    //GET_LIST_BY_OID: `${BASE_URL}/GetListByOId`,
    TRANSIT_STATION: `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/TransitStation`,
};

/**
 * Lấy danh sách nhóm trẻ theo id tổ chức
 * @param {string} oId: Id tổ chức
 * @returns => Danh sách nhóm trẻ
 */
export const apiGetListByOId = (oId) => {
    const _dataPost = new ApiGatewayDataPost({
        service: 'FoodKid',
        controller: 'MealService',
        action: 'GetListByOId',
        dataPost: {
            orgId: oId,
        },
    });

    return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);

    //return axiosService.get(`${LIST_URL.GET_LIST_BY_OID}/${oId}`);
};
