import axiosService from '../../services/axiosService';
import {
    API_INVENTORY_ENDPOINT,
    API_SC_FOODKID_ENPOINT,
} from '../../constants/api';

const url = 'v1/Accountant';

const urlMenuIn = 'v1/FoodkidMenuReceiptInAccountentByDate';
const urlMenuOut = 'v1/FoodkidMenuReceiptOutAccountentByDate';
const urlDelete = 'v1/AccountentFoodReceiptOut/DeleteMany';
// const urlSave = 'FoodReceiptOut';

export const get = (model) =>
    axiosService.post(
        `${API_INVENTORY_ENDPOINT}/${url}/FoodReceipt/Search`,
        model
    );

// export const GetByUser = (id) =>
//     axiosService.get(
//         `${API_INVENTORY_ENDPOINT}/${url}/InventoryWareHouseBySchool/${id}`
//     );
export const GetDetailByUser = () =>
    axiosService.post(`${API_INVENTORY_ENDPOINT}/${url}/GetDetailByUser`);

export const saveFoodReceiptMenu = (model) =>
    axiosService.post(`${API_SC_FOODKID_ENPOINT}/${urlMenuIn}`, model);

export const saveFoodReceiptMenuOut = (model) =>
    axiosService.post(`${API_SC_FOODKID_ENPOINT}/${urlMenuOut}`, model);

export const deleteReceipt = (data) =>
    axiosService.post(`${API_INVENTORY_ENDPOINT}/${urlDelete}`, data);

// export const saveReceipt = (data) =>
//     axiosService.post(`${API_INVENTORY_ENDPOINT}/${url}/${urlSave}`, data);
