const _stringUtils = {
    /** Xóa kí tự cuối cùng */
    removeLastChar(str) {
        if (!str) return '';
        return str.trim().slice(0, -1);
    },
    /**
     * Xóa khoảng trảng
     * @param {*} str: chuỗi cần xóa khoảng trắng
     * @returns
     *  Trả về chuỗi không còn khoảng trắng
     */
    removeSpace(str) {
        if (!str) return '';
        return str.replace(/\s+/g, '');
    },
    /**
     * Kiểm tra chuỗi rỗng
     * @param {string} str :chuỗi cần kiểm tra
     * @returns
     *  true: chuỗi rỗng
     */
    isEmpty(str) {
        if (!str) {
            return true;
        }
        str += '';
        if (_.isEmpty(this.removeSpace(str))) {
            return true;
        }
        return false;
    },
};

export default _stringUtils;
