export const GET_KIDGROUP = 'GET_KIDGROUP';
export const GET_KIDGROUP_SUCCESS = 'GET_KIDGROUP_SUCCESS';

export const CHANG_KIDGROUP = 'CHANG_KIDGROUP';
export const CHANGE_SELECTED_ROW = 'CHANGE_SELECTED_ROW';

export const GET_MENU_SAMPLE = 'GET_MENU_SAMPLE';
export const GET_MENU_SAMPLE_SUCCESS = 'GET_MENU_SAMPLE_SUCCESS';

export const SAVE_MENU = 'SAVE_MENU';
export const SAVE_MENU_SUCCESS = 'SAVE_MENU_SUCCESS';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

export const CHANGE_MENU_SELECT = 'CHANGE_MENU_SELECT';

export const SAVE_BALANCE_TO_SERVICE = 'SAVE_BALANCE_TO_SERVICE';
export const SAVE_BALANCE_TO_SERVICE_SUCCESS =
    'SAVE_BALANCE_TO_SERVICE_SUCCESS';

export const CHECK = 'CHECK';
export const CHECK_ALL = 'CHECK_ALL_FOODRECEIPT_OUT';

export const DELETE_MENU = 'DELETE_MENU';
export const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS';

export const UPDATE_MENU_COMPLETE = 'UPDATE_MENU_COMPLETE';
export const UPDATE_MENU_COMPLETE_SUCCESS = 'UPDATE_MENU_COMPLETE_SUCCESS';

export const CLOSE_MODAL_COPY_MENU = 'CLOSE_MODAL_COPY_MENU';
export const OPEN_MODAL_COPY_MENU = 'OPEN_MODAL_COPY_MENU';

export const UPDATE_MENU_NEW = 'UPDATE_MENU_NEW';
export const SET_MENU_LIST = 'SET_MENU_LIST';

export const UPDATE_NEW_PRICE_MENU = 'UPDATE_NEW_PRICE_MENU';
export const UPDATE_NEW_PRICE_MENU_SUCCESS = 'UPDATE_NEW_PRICE_MENU_SUCCESS';

export const RESET_AUTO_CREATE_MENU = 'RESET_AUTO_CREATE_MENU';

export const OPEN_MODAL_CHANGE_QUANTITY = 'OPEN_MODAL_CHANGE_QUANTITY';
export const CLOSE_MODAL_CHANGE_QUANTITY = 'CLOSE_MODAL_CHANGE_QUANTITY';

export const OPEN_MODAL_UPDATE_SERVICE = 'OPEN_MODAL_UPDATE_SERVICE';
export const CLOSE_MODAL_UPDATE_SERVICE = 'CLOSE_MODAL_UPDATE_SERVICE';

export const GET_LIST_SERVICE_SCHOOL = 'GET_LIST_SERVICE_SCHOOL';
export const GET_LIST_SERVICE_SCHOOL_SUCCESS =
    'GET_LIST_SERVICE_SCHOOL_SUCCESS';

export const UPDATE_LIST_SERVICE_SCHOOL = 'UPDATE_LIST_SERVICE_SCHOOL';

export const UPDATE_PRICE_SERVICE_MENU = 'UPDATE_PRICE_SERVICE_MENU';
export const UPDATE_PRICE_SERVICE_MENU_SUCCESS =
    'UPDATE_PRICE_SERVICE_MENU_SUCCESS';

export const UPDATE_QUANTITY_MENU = 'UPDATE_QUANTITY_MENU';
export const UPDATE_QUANTITY_MENU_SUCCESS = 'UPDATE_QUANTITY_MENU_SUCCESS';

export const ROUNDING_REAL_BUY = 'ROUNDING_REAL_BUY';
export const ROUNDING_REAL_BUY_SUCCESS = 'ROUNDING_REAL_BUY_SUCCESS';
