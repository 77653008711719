// Setting
export const LIST_OBJ_SCHOOL_SETTING = {
    GLOBAL: [
        {
            name: 'Tên trường',
            code: 'ten_truong',
        },
        {
            name: 'Địa chỉ',
            code: 'dia_chi',
        },
        {
            name: 'Điện thoại',
            code: 'dien_thoai',
        },
        {
            name: 'Email',
            code: 'email',
        },
        {
            name: 'Họ tên hiệu trưởng',
            code: 'ho_ten_hieu_truong',
        },
        {
            name: 'Điện thoại hiệu trưởng',
            code: 'dt_hieu_truong',
        },
        {
            name: 'Họ tên hiệu phó bán trú',
            code: 'ho_ten_hieu_pho_bt',
        },
        {
            name: 'Điện thoại hiệu phó bán trú',
            code: 'dien_thoai_hieu_pho_bt',
        },
        {
            name: 'Họ tên hiệu phó chuyên môn',
            code: 'ho_ten_hieu_pho_cm',
        },
        {
            name: 'Điện thoại hiệu phó chuyên môn',
            code: 'dien_thoai_hieu_pho_cm',
        },
        {
            name: 'Họ tên kế toán',
            code: 'ho_ten_ke_toan',
        },
        {
            name: 'Họ tên thủ quỷ',
            code: 'ho_ten_thu_quy',
        },
        {
            name: 'Họ tên văn thư',
            code: 'ho_ten_van_thu',
        },
        {
            name: 'Họ tên y tế',
            code: 'ho_ten_y_te',
        },
        {
            name: 'Họ tên cấp dưỡng',
            code: 'ho_ten_cap_duong',
        },
        {
            name: 'Họ tên thủ kho',
            code: 'ho_ten_thu_kho',
        },
    ],
    FOODKID: [],
    INCOME: [],
};

export const SCHOOL_SETTING_PROPERTY = {
    GLOBAL: {},
    FOODKID: {
        HINH_THUC_CAN_DOI_THUC_DON: 'hinh_thuc_can_doi_thuc_don',
        NGUOI_KIEM_TRA: 'nguoi_kiem_tra',
        NGUOI_LUU_MAU: 'nguoi_luu_mau',
        NGUOI_HUY_MAU: 'nguoi_huy_mau',
        HO_TEN_BEP_TRUONG: 'ho_ten_bep_truong',
    },
    INCOME: {
        THANG_CHUYEN_LOP: 'thang_chuyen_lop',
        MA_QHNS: 'ma_qhns',
        KY_HIEU_HOA_DON: 'ky_hieu_hoa_don',
        QUYEN_PHIEU_THU_HIEN_TAI: 'quyen_phieu_thu_hien_tai',
        SO_BIEN_LAI_HIEN_TAI: 'so_bien_lai_hien_tai',
        SO_BIEN_LAI_HOC_PHI_NHA_TRE: 'so_bien_lai_hoc_phi_nha_tre',
        SO_BIEN_LAI_HOC_PHI_MAU_GIAO: 'so_bien_lai_hoc_phi_mau_giao',
        SO_KY_TU_BIEN_LAI: 'so_ky_tu_bien_lai',
        NOI_DUNG_THONG_BAO_NOP_TIEN: 'noi_dung_thong_bao_nop_tien',
        NGAY_NOP_TIEN: 'ngay_nop_tien',
        NGAY_GUI_PHIEU_THU: 'ngay_gui_phieu_thu',
    },
};

export const SETTING_GLOBAL_GROUP = [
    {
        id: 1,
        name: 'THÔNG TIN TRƯỜNG',
        children: [
            'ten_truong',
            'dia_chi',
            'dien_thoai',
            'email',
            'ho_ten_hieu_truong',
            'dien_thoai_hieu_truong',
            'ho_ten_hieu_pho_ban_tru',
            'dien_thoai_hieu_pho_ban_tru',
            'ho_ten_hieu_pho_chuyen_mon',
            'dien_thoai_hieu_pho_chuyen_mon',
            'ho_ten_ke_toan',
            'ho_ten_thu_quy',
            'ho_ten_van_thu',
            'ho_ten_y_te',
            'ho_ten_cap_duong',
            'ho_ten_thu_kho',
        ],
    },
    {
        id: 1,
        name: 'THÔNG TIN TÀI KHOẢN NGÂN HÀNG',
        children: [
            'pgd_gan_nhat',
            'ten_ngan_hang',
            'ten_tai_khoan',
            'so_tai_khoan',
        ],
    },
];

export const SCHOOL_SETTING_FOODKID_GROUP = [
    {
        id: 1,
        name: 'THÔNG TIN TIẾP PHẨM',
        children: [
            'nguoi_kiem_tra',
            'thoi_gian_kiem_tra',
            'dia_diem_kiem_tra',
            'ban_tiep_nhan',
            'ho_ten_bep_truong',
            'nguoi_luu_mau',
            'nguoi_huy_mau',
            'ten_nguoi_lap_bang',
            'ten_ban_tiep_pham',
        ],
    },
    {
        id: 2,
        name: 'TIẾP PHẨM BƯỚC 2A',
        children: [
            'gio_so_che_bua_sang_2a',
            'gio_so_che_bua_trua_2a',
            'gio_so_che_bua_chinh_chieu_2a',
            'gio_so_che_bua_phu_chieu_2a',
            'gio_so_che_bua_phu_chieu_2a',
            'gio_che_bien_bua_sang_2a',
            'gio_che_bien_bua_trua_2a',
            'gio_che_bien_bua_chinh_chieu_2a',
            'gio_che_bien_bua_phu_chieu_2a',
            'nguoi_tham_gia_che_bien_2a',
            'trang_thiet_bi_dung_cu_2a',
            'khu_vuc_che_bien_phu_tro_2a',
            'kiem_tra_cam_quan_thuc_an_2a',
        ],
    },
    {
        id: 3,
        name: 'TIẾP PHẨM BƯỚC 2B',
        children: [
            'gio_chia_mon_an_xong_bua_sang_2b',
            'gio_chia_mon_an_xong_bua_trua_2b',
            'gio_chia_mon_an_xong_bua_chinh_chieu_2b',
            'gio_chia_mon_an_xong_bua_phu_chieu_2b',
            'gio_bat_dau_an_bua_sang_2b',
            'gio_bat_dau_an_bua_trua_2b',
            'gio_bat_dau_an_bua_chinh_chieu_2b',
            'gio_bat_dau_an_bua_phu_chieu_2b',
            'kiem_tra_cam_quan_mon_an_2b',
            'dung_cu_chia_chua_dung_thuc_an_2b',
        ],
    },
    {
        id: 4,
        name: 'TIẾP PHẨM BƯỚC 3',
        children: [
            'khoi_luong_the_tich_mau_3',
            'dung_cu_chua_mau_thuc_an_luu_3',
            'nhiet_do_bao_quan_mau_3',
            'gio_lay_mau_buoi_sang_3',
            'gio_lay_mau_buoi_trua_3',
            'gio_lay_mau_bua_chinh_chieu_3',
            'gio_lay_mau_bua_phu_chieu_3',
            'gio_huy_mau_bua_sang_3',
            'gio_huy_mau_bua_trua_3',
            'gio_huy_mau_bua_chinh_chieu_3',
            'gio_huy_mau_phu_chieu_3',
        ],
    },
];

export const SCHOOL_SETTING_SERVICE_GROUP = [
    {
        typeCate: 1,
        listCode: [
            'thuc_don_trong_ngay',
            'phieu_ke_cho',
            'phieu_tiep_pham',
            'so_kiem_thuc',
            'so_kiem_thuc_trong',
            'bang_tong_hop_khau_phan_dinh_duong_tuan',
            'so_tinh_tien_cho',
            'phieu_nhap_kho',
            'phieu_xuat_kho',
            'the_kho',
            'tong_hop_the_kho',
            'so_quyet_toan_trong_ngay',
            'hoa_don_ban_hang',
            'thuc_don_tuan',
            'bang_cong_khai_tai_chinh',
        ],
    },

    {
        typeCate: 2,
        listCode: [
            'bang_diem_danh',
            'bang_diem_danh_trong',
            'tong_hop_diem_danh',
            'phieu_bao_dong_tien',
            'bien_lai_thu_tien',
            'so_thu_thanh_toan_tong_hop',
            'so_thu_thanh_toan_tong_hop_phhs',
            'tong_hop_thu_chi_tien_an',
            'so_quy_tien_an',
        ],
    },
];

export const SCHOOL_PROPERTY_TYPE = {
    NUMBER: 1,
    STRING: 2,
    BOOLEAN: 3,
    DATE: 4,
    EMAIL: 5,
};

export const SCHOOL_PROPERTY_GROUP = {
    GLOBAL: 1,
    FOODKID: 2,
    INCOME: 3,
    SERVICE: 13,
    PRIVATE: -1,
};

export const TabSchoolPropertyList = [
    {
        id: 1,
        code: 'global',
        desc: 'THÔNG TIN CHUNG',
    },
    {
        id: 2,
        code: 'foodkid',
        desc: 'KHẨU PHẦN DINH DƯỠNG',
    },
    {
        id: 3,
        code: 'income',
        desc: 'QUẢN LÝ THU CHI',
    },
    {
        id: 13,
        code: 'service',
        desc: 'SỔ SÁCH',
    },
];

export const HinhThucCanDoiThucDonList = [
    {
        id: 1,
        name: 'Gộp chung',
    },
    {
        id: 2,
        name: 'Chia theo từng bữa ăn',
    },
];
