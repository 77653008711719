import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PageHeader from '@app/components/Layout/PageHeader/PageHeader';
import Filter from './Filter';
import List from './List';
import AddOrEditPopup from './AddOrEditPopup';
import { _paymentVoucherActs } from '@app/redux/income/paymentVoucher/slice';
import * as _paymentVouSagaActs from '@app/redux/income/paymentVoucher/sagaActions';
import _paymentVoucherStor from '@app/redux/income/paymentVoucher/selector';
import _schoolSelector from '@app/redux/school/school.selector';
import { useDispatch, useSelector } from 'react-redux';
import CrmPagination from '@app/components/_Share/Pagination';
import { swal, SWAL_CONST } from '@app/utils/alert';

const PaymentVoucherPage = () => {
    const [rangeDateValue, setRangeDateValue] = useState({});
    const [paymentVoucherType, setPaymentVoucherType] = useState();
    const [yearId, setYearId] = useState();
    const isShowPopup = useSelector(_paymentVoucherStor.isShowPopup);
    const editItem = useSelector(_paymentVoucherStor.editItem);
    const oId = useSelector(_schoolSelector.currentSchool);
    const listData = useSelector(_paymentVoucherStor.listData);
    const currentPage = useSelector(_paymentVoucherStor.currentPage);
    const total = useSelector(_paymentVoucherStor.total);
    const pageSize = useSelector(_paymentVoucherStor.pageSize);
    const isCheckAll = useSelector(_paymentVoucherStor.isCheckAll);

    const dispatch = useDispatch();

    const listCheck = listData.filter((o) => o.isCheck);

    useEffect(() => {
        if (!_.isEmpty(rangeDateValue) && paymentVoucherType && yearId) {
            const dataPost = {
                startDate: rangeDateValue.startDate.unix(),
                endDate: rangeDateValue.endDate.unix(),
                paymentVoucherType,
                yearId,
                oId,
                orgId: oId,
                currentPage,
                pageSize,
            };
            dispatch(_paymentVouSagaActs.doGetListByDate(dataPost));
        }
    }, [
        currentPage,
        dispatch,
        oId,
        pageSize,
        paymentVoucherType,
        rangeDateValue,
        yearId,
    ]);

    const onDateChange = (s) => {
        setRangeDateValue(s);
    };

    const onTypeChange = (s) => {
        setPaymentVoucherType(s);
    };

    const onYearChange = (s) => {
        setYearId(s);
    };

    const onCreate = () => {
        dispatch(_paymentVoucherActs.doCreate());
    };

    const onSave = (dataSave) => {
        const dataPost = {
            ...dataSave,
            i_datefounded: dataSave.i_datefound_dt.unix(),
            i_datefrom: dataSave.i_daterange_dt.startDate.unix(),
            i_dateto: dataSave.i_daterange_dt.endDate.unix(),
            o_id: oId,
            orgId: oId,
            sy_id: yearId,
        };
        dispatch(_paymentVouSagaActs.doSave(dataPost));
    };

    const onDelete = async (_dataItem) => {
        const swalRes = await swal(
            SWAL_CONST.TYPE.QUESION,
            `Bạn có muốn xóa phiếu chi [${_dataItem.i_code}] không`,
            true
        );
        if (swalRes.value) {
            dispatch(
                _paymentVouSagaActs.doDelete({
                    orgId: oId,
                    listId: [_dataItem.i_id],
                })
            );
        }
    };

    const onDeleteChooseItem = async () => {
        if (listCheck.length === 0) return;
        const swalRes = await swal(
            SWAL_CONST.TYPE.QUESION,
            'Bạn có muốn xóa những phiếu chi đã chọn không',
            true
        );
        if (swalRes.value) {
            const listDeleteId = listCheck.map((o) => o.i_id);
            dispatch(
                _paymentVouSagaActs.doDelete({
                    orgId: oId,
                    listId: listDeleteId,
                })
            );
        }
    };

    const onClosePopup = () => {
        dispatch(_paymentVoucherActs.doTogglePopup(false));
    };

    const onCurrentPageChange = (_currentPage) => {
        if (_currentPage !== currentPage) {
            dispatch(_paymentVoucherActs.doChangeCurrentPage(_currentPage));
        }
    };

    const onCheckAllList = (s) => {
        const checkValue = s.target.checked;
        dispatch(_paymentVoucherActs.doCheckAll(checkValue));
    };

    const onCheckItem = (dataItem, s) => {
        const checkValue = s.target.checked;
        dispatch(
            _paymentVoucherActs.doCheckItem({
                checkValue,
                dataItem,
            })
        );
    };

    return (
        <>
            <PageHeader pageTitle="Lập phiếu chi" />
            <div className="content">
                <Filter
                    onDateChange={onDateChange}
                    onAdd={onCreate}
                    onTypeChange={onTypeChange}
                    onDelete={onDeleteChooseItem}
                    onYearChange={onYearChange}
                    disableDeleteBtn={listCheck.length === 0}
                />
                <div className="row">
                    <div className="col-lg-12">
                        <List
                            dataSource={listData}
                            onCheckAll={onCheckAllList}
                            isCheckAll={isCheckAll}
                            onCheckItem={onCheckItem}
                            onDelete={onDelete}
                        />
                    </div>
                </div>

                <CrmPagination
                    currentCount={listData?.length}
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                    onChange={onCurrentPageChange}
                />
            </div>
            {isShowPopup ? (
                <AddOrEditPopup
                    isShow={isShowPopup}
                    onSubmit={onSave}
                    initialValues={editItem}
                    onClose={onClosePopup}
                    oId={oId}
                />
            ) : null}
        </>
    );
};

export default PaymentVoucherPage;
