// eslint-disable-next-line no-unused-vars
import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    classList: [],
    isCheckAll: false,
};

const attendanceSlice = createSlice({
    name: 'attendance',
    initialState,
    reducers: {
        // Lấy danh sách lớp học thành công
        doGetClassListSuccess: (state, { payload }) => {
            state.classList = payload;
            state.isCheckAll = false;
        },
        // Chọn tất cả
        doCheckAll: (state, { payload }) => {
            state.isCheckAll = payload;
            state.classList = state.classList.map((item) => ({
                ...item,
                isCheck: payload,
            }));
        },
        // Chọn 1 lớp
        doCheckItem: (state, { payload }) => {
            const { dataItem, checkValue } = payload;
            state.classList = state.classList.map((o) => {
                if (o.c_id === dataItem.c_id) {
                    o = {
                        ...o,
                        isCheck: checkValue,
                    };
                }
                return o;
            });

            state.isCheckAll =
                state.classList.length ===
                state.classList.filter((o) => o.isCheck).length;
        },
        // Đổi quá trình xử lý
        doChangeProcess: (state, { payload }) => {
            const { list, process } = payload;
            state.classList = state.classList.map((obj) => {
                const objCheck = list.find((item) => item.c_id === obj.c_id);
                if (objCheck) {
                    obj = {
                        ...obj,
                        ...objCheck,
                        process,
                        isCheck: !(
                            process !== 'processing' &&
                            process !== 'delete_processing'
                        ),
                    };
                }
                return obj;
            });
            const listChecked = state.classList.filter((o) => o.isCheck);
            state.isCheckAll = listChecked.length === state.classList.length;
        },
        doResetList: (state) => {
            state.classList = state.classList.map((obj) => ({
                ...obj,
                isCheck: false,
                process: '',
            }));
            state.isCheckAll = false;
        },
        doReset: (state) => {
            // eslint-disable-next-line no-unused-vars
            state = {
                ...initialState,
            };
        },
    },
});

export const {
    doGetClassListSuccess,
    doCheckAll,
    doCheckItem,
    doChangeProcess,
    doResetList,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
