import axiosService from '../../services/axiosService';
import { API_SC_GATEWAY_ENDPOINT } from '../../constants/api';

const BASE_GATE_WAY = `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/ToolSC/FoodKid/SchoolFood`;

const LIST_URL = {
    GET_LIST: `${BASE_GATE_WAY}/GetList`,
};

const _fkSchoolFoodApis = {
    /** Lấy danh sách */
    getList(orgId) {
        return axiosService.get(`${LIST_URL.GET_LIST}/${orgId}`);
    },
};

export default _fkSchoolFoodApis;
