import axiosService from '@app/services/axiosService';
import {
    API_INCOME_ENDPOINT,
    API_SC_GATEWAY_ENDPOINT,
} from '@app/constants/api';
import _urlUtils from '@app/utils/url.utils';
import { ApiGatewayDataPost } from '@app/models/common/apiGatewayDataPost.model';

const BASE_URL = `${API_INCOME_ENDPOINT}/v1/ScIncome/ReceiptVoucher`;
const BASE_URL_V2 = `${API_INCOME_ENDPOINT}/v1/ScIncome/Service/ReceiptVoucher`;

const LIST_URL = {
    //CREATE: `${BASE_URL}/ServiceCreate`,
    CREATE: `${BASE_URL_V2}/Create`,
    DELETE: `${BASE_URL}/ServiceDelete`,
    GET_LIST: `${BASE_URL_V2}/GetList`,
    GET_TOTAL: `${BASE_URL_V2}/GetTotal`,
    GET_TOTAL_BY_LIST_CLASS: `${BASE_URL_V2}/GetTotalByListClass`,
    PAYMENT: `${BASE_URL_V2}/Payment`,
    TRANSIT_STATION: `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/TransitStation`,
};

// const BASE_URL = `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/ToolSC/Income/Receipt`;

// const LIST_URL = {
//     //CREATE: `${BASE_URL}/ServiceCreate`,
//     CREATE: `${BASE_URL}/Create`,
//     DELETE: `${BASE_URL}/ServiceDelete`,
//     GET_LIST: `${BASE_URL}/GetList`,
//     GET_TOTAL: `${BASE_URL}/GetTotal`,
//     GET_TOTAL_BY_LIST_CLASS: `${BASE_URL}/GetTotalByListClass`,
//     PAYMENT: `${BASE_URL}/Payment`,
// };

const _icReceiptApis = {
    /**
     * Lấy danh sách phiếu thu
     * @param {Object} dataPost: dữ liệu đẩy lên API
     * @param {number} dataPost.yearId: Id năm học
     * @param {number} dataPost.classId: Id lớp học
     * @param {number} dataPost.month: tháng học
     * @param {string} dataPost.oId: mã tổ chức
     * @returns Thành công || Thất bại
     */
    getList(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'ToolScReceipt',
            action: 'GetList',
            dataPost: {
                ...dataPost,
                orgId: dataPost.oId,
            },
        });
        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
        // console.log(dataPost);
        // return axiosService.post(LIST_URL.GET_LIST, dataPost);
    },

    /** Lấy tổng số phiếu thu */
    getTotal(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'ToolScReceipt',
            action: 'GetTotal',
            dataPost: {
                orgId: dataPost.orgId,
                queryParam: _urlUtils.createSearchParams(dataPost),
            },
        });

        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    },
    /** Thanh toán phiêu thu */
    doPayment(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'ToolScReceipt',
            action: 'DoPayment',
            dataPost,
        });

        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);

        //return axiosService.post(LIST_URL.PAYMENT, dataPost);
    },
    /**
     * Tạo phiếu thu
     * @param {Object} dataPost: dữ liệu đẩy lên API
     * @param {number} dataPost.yearId: Id năm học
     * @param {number} dataPost.classId: Id lớp học
     * @param {number} dataPost.month: tháng học
     * @param {string} dataPost.oId: mã tổ chức
     * @returns Thành công || Thất bại
     */
    create(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'ToolScReceipt',
            action: 'Create',
            dataPost,
        });

        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);

        //return axiosService.post(LIST_URL.CREATE, dataPost);
    },
    /**
     * Xóa phiếu thu
     * @param {Object} dataPost: dữ liệu đẩy lên API
     * @param {number} dataPost.yearId: Id năm học
     * @param {number} dataPost.classId: Id lớp học
     * @param {number} dataPost.month: tháng học
     * @param {string} dataPost.oId: mã tổ chức
     * @returns Thành công || Thất bại
     */
    delete(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'ToolScReceipt',
            action: 'Delete',
            dataPost,
        });

        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);

        //return axiosService.post(LIST_URL.DELETE, dataPost);
    },
};

export default _icReceiptApis;
