import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { nonAccentVietnamese } from '@app/utils/utils';
import { getList } from '@app/apis/schools';
import _locationApis from '@app/apis/locations/location';
import _ from 'lodash';
import { useDeepEffect } from '@app/hooks/useDeepEffect';
import locationUtils from '@app/utils/location.utils';

const { Option } = Select;
const SchoolListSelect = ({ label, defaultValue, onChange, disabled }) => {
    const [list, setList] = useState([]);
    const [selectId, setSelectId] = useState('');
    const [listLocation, setListLocation] = useState([]);

    useEffect(() => {
        getList().then(({ status, data }) => {
            if (status === 200) {
                const dataRes = data.data;
                let id = '';

                if (dataRes.length > 0) {
                    if (defaultValue) {
                        const check = dataRes.find(
                            (item) => item.o_id === defaultValue
                        );
                        if (check) {
                            id = defaultValue;
                        } else {
                            id = dataRes[0].o_id;
                        }
                    } else {
                        id = dataRes[0].o_id;
                    }
                }

                setList(dataRes);
                setSelectId(id);
                onChange?.(id);
            } else {
                setList([]);
                setSelectId('');
            }
        });

        return () => {
            setList([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getListLocation = async () => {
        const listLocationId = list.map((o) => o.l_id);

        let listAllLocationId = [];
        listLocationId.forEach((elm) => {
            const arr = elm.split('.');
            listAllLocationId = [
                ...listAllLocationId,
                elm,
                `${arr[0]}.${arr[1]}`,
                `${arr[0]}`,
            ];
        });

        listAllLocationId = _.uniq(listAllLocationId);

        const { status, data } = await _locationApis.getByListId(
            listAllLocationId
        );

        if (status !== 200) {
            setListLocation([]);
            return;
        }
        setListLocation(data.data);
    };

    useDeepEffect(() => {
        if (!list?.length) return;
        getListLocation();
    }, [list]);

    const listView = useMemo(() => {
        if (!list?.length || !listLocation?.length) return [];
        return list.map((elm) => {
            const locationFullName = locationUtils.getFullNameById(
                elm.l_id,
                listLocation
            );

            return {
                ...elm,
                locationFullName,
            };
        });
    }, [list, listLocation]);

    useEffect(() => {
        if (defaultValue) {
            setSelectId(defaultValue);
        } else if (list && list.length > 0) {
            setSelectId(list[0].o_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const onSearch = (input, option) => {
        if (option) {
            return (
                nonAccentVietnamese(option.children[0].replace(/\s/g, ''))
                    .toLowerCase()
                    .indexOf(
                        nonAccentVietnamese(
                            input.replace(/\s/g, '')
                        ).toLowerCase()
                    ) >= 0
            );
        }
        return false;
    };

    const showOptionItem = () => {
        if (_.isEmpty(listView)) return null;
        return listView.map((elm) => (
            <Option value={elm.o_id} key={elm.o_id}>
                {elm.o_name}
                <p style={{ fontSize: '10px', lineHeight: '1' }}>
                    {elm.locationFullName}
                </p>
            </Option>
        ));
    };

    const handleChange = (value) => {
        setSelectId(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Fragment>
            {label ? (
                <Fragment>
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                    <div className="clearfix mb20" />
                </Fragment>
            ) : null}
            <Select
                value={selectId}
                optionFilterProp="children"
                filterOption={(input, option) => onSearch(input, option)}
                disabled={disabled}
                className="disable-modified"
                onChange={handleChange}
                showSearch
            >
                {showOptionItem(list)}
            </Select>
        </Fragment>
    );
};

export default SchoolListSelect;
