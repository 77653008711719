import { AccountRoleModel } from '@app/models/account/accountRole.model';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const MODULE_NAME = 'accountRole';

const initialState = {
    roleList: [],

    editItem: new AccountRoleModel(),
    isShowModal: false,
};

const AccountRoleSlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        /** FETCH DATA */
        doGetListRole() {},
        doGetListRoleSuccess(state, { payload }) {
            state.roleList = payload;
        },

        doOpenModal(state, { payload }) {
            state.isShowModal = true;

            state.editItem = payload
                ? new AccountRoleModel(payload)
                : new AccountRoleModel({
                      sr_sortorder: !_.isEmpty(state.roleList)
                          ? _.max(state.roleList.map((o) => o.sr_sortorder)) + 1
                          : 1,
                  });
        },
        doCloseModal(state) {
            state.isShowModal = false;
        },

        doDeleteRole() {},
        doDeleteRoleSuccess(state, { payload }) {
            const newList = state.roleList.filter((o) => o.sr_id !== payload);

            state.roleList = newList;
        },

        doUpdateStatus() {},
        doUpdateStatusSuccess(state, { payload }) {
            const newList = state.roleList.map((o) => {
                if (o.sr_id === payload.sr_id) {
                    o = {
                        ...o,
                        ...payload,
                    };
                }

                return o;
            });

            state.roleList = newList;
        },

        doSaveRole() {},
        doSaveRoleSuccess(state, { payload }) {
            const { data, action } = payload;

            const newList =
                action === 'add'
                    ? [...state.roleList, data]
                    : state.roleList.map((o) => {
                          if (o.sr_id === data.sr_id) {
                              o = {
                                  ...o,
                                  ...data,
                              };
                          }

                          return o;
                      });

            state.roleList = _.orderBy(newList, ['sr_sortorder'], ['asc']);
            state.isShowModal = action === 'add';
            state.editItem = new AccountRoleModel({
                sr_sortorder:
                    _.max(state.roleList.map((o) => o.sr_sortorder)) + 1,
            });
        },

        doReset(state) {
            state.roleList = [];
        },
    },
});

/** ===== ACTION ==== **/
export const accountRoleActions = AccountRoleSlice.actions;

/** ===== SELECTOR ==== **/
export const accountRoleSelector = {
    roleList: (state) => state[MODULE_NAME].roleList,
    isShowModal: (state) => state[MODULE_NAME].isShowModal,
    editItem: (state) => state[MODULE_NAME].editItem,
    // menuSchoolFoodList: (state) => state[MODULE_NAME].menuSchoolFoodList,
    // schoolFoodList: (state) => state[MODULE_NAME].schoolFoodList,
    // proFoodList: (state) => state[MODULE_NAME].proFoodList,
    // isCheckAll: (state) => state[MODULE_NAME].isCheckAll,
    // isShowExport: (state) => state[MODULE_NAME].isShowExport,
};

/** ===== REDUCER ==== **/
const accountRoleReducer = AccountRoleSlice.reducer;
export default accountRoleReducer;
