import axiosService from '@app/services/axiosService';
import { API_SCHOOL_ENDPOINT } from '@app/constants/api';

const url = 'v1/schoolClass';

export const getList = (data) =>
    axiosService.post(`${API_SCHOOL_ENDPOINT}/${url}/Search`, data);

export const getSelectList = (data) =>
    axiosService.post(`${API_SCHOOL_ENDPOINT}/${url}/SelectList/`, data);

export const save = (data) =>
    axiosService.post(`${API_SCHOOL_ENDPOINT}/${url}`, data);

export const deleteClass = (id) =>
    axiosService.delete(`${API_SCHOOL_ENDPOINT}/${url}/${id}`);

/**============================================================
 * Ver mới
 ============================================================*/

const BASE_URL_V2 = `${API_SCHOOL_ENDPOINT}/v1/BcnSchool/schoolClass`;

const LIST_URL_V2 = {
    GET_CLASS_LIST_BY_OID: `${BASE_URL_V2}/GetListByOId`,
};

/**
 * Lấy danh sách lớp học theo Id tổ chức + năm học
 * @param {*} dataPost: dữ liệu gửi lên
 * @param {string} dataPost.o_id: Id tổ chức
 * @param {number} dataPost.sy_id: Id năm học
 * @returns
 */
export const apiDoGetListByOId = (dataPost) =>
    axiosService.post(LIST_URL_V2.GET_CLASS_LIST_BY_OID, dataPost);
