import { takeLatest, call, put } from 'redux-saga/effects';
// import * as types from './constants';
import {
    apiGetListRole,
    apiSaveRole,
    apiDeleteRole,
} from '@app/apis/accounts/accountRole';
import { ACTION_API_TYPES } from '@app/constants';
// import {
//     saveAccountRoleSuccess,
//     deleteAccountRoleSuccess,
//     getAccountRoleByIdSuccess,
//     getAccountRoleSelectListSuccess,
// } from './actions';
import { showError, showSuccess } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import { accountRoleActions } from './accountRole.slice';
import { STATUS_CODES } from '@app/constants/api';

function* sgSaveRole({ payload }) {
    yield callLoading(function* doRequest() {
        const { data, status } = yield call(apiSaveRole, payload);

        if (status === STATUS_CODES.SUCCESS) {
            yield put(
                accountRoleActions.doSaveRoleSuccess({
                    data: data.data,
                    action: data.action,
                })
            );

            showSuccess(
                ACTION_API_TYPES[data.action === 'add' ? 'ADD' : 'EDIT']
            );
        } else {
            showError(status, data);
        }
    });
}

function* sgDeleteRole({ payload }) {
    yield callLoading(function* doRequest() {
        const { data, status } = yield call(apiDeleteRole, payload);

        if (status === STATUS_CODES.NO_CONTENT) {
            yield put(accountRoleActions.doDeleteRoleSuccess(payload));
            showSuccess(ACTION_API_TYPES.DELETE);
        } else {
            showError(status, data);
        }
    });
}

function* sgGetListRole() {
    yield callLoading(function* doRequest() {
        const { data, status } = yield call(apiGetListRole);

        if (status === STATUS_CODES.SUCCESS) {
            yield put(accountRoleActions.doGetListRoleSuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sgUpdateStatusRole({ payload }) {
    const { data, status } = yield call(apiSaveRole, payload);

    if (status === STATUS_CODES.SUCCESS) {
        yield put(accountRoleActions.doUpdateStatusSuccess(data.data));
    } else {
        showError(status, data);
    }
}

// function* sagaGetSelectListAccountRole({ payload }) {
//     const { id, defaultId } = payload;
//     const res = yield call(getListById, id);
//     const { data, status } = res;
//     if (status === apiConst.STATUS_CODES.SUCCESS) {
//         yield put(getAccountRoleSelectListSuccess(data.data, defaultId));
//     } else {
//         showError(status, data);
//     }
// }

function* AccountRoleSaga() {
    yield takeLatest(accountRoleActions.doSaveRole.type, sgSaveRole);
    yield takeLatest(accountRoleActions.doDeleteRole.type, sgDeleteRole);
    yield takeLatest(accountRoleActions.doGetListRole.type, sgGetListRole);
    yield takeLatest(
        accountRoleActions.doUpdateStatus.type,
        sgUpdateStatusRole
    );
}

export default AccountRoleSaga;
