import React from 'react';
import { formatDateUnix } from '@app/utils/datetime.utils';

const ModalTransHistoryItem = ({ connHistory }) => {
    const onShowStatus = (connStatus) => {
        let html = null;

        switch (connStatus) {
            case 1:
                html = (
                    <span className="label label-success">
                        <i className="icon-checkmark4" /> Kết nối
                    </span>
                );
                break;
            case 2:
                html = (
                    <span className="label label-danger">
                        <i className="icon-x" /> Hủy kết nối
                    </span>
                );
                break;

            default:
                break;
        }
        return html;
    };

    return (
        <tr>
            <td className="text-center">
                {formatDateUnix(
                    connHistory?.ch_connectiondate,
                    'DD/MM/YYYY HH:mm:ss'
                )}
            </td>
            <td>
                <span className="badge badge-info pull-right margin-left-5">
                    {connHistory?.scaleName || ''}
                </span>
                <span className="badge bg-slate pull-right ">
                    {connHistory?.typeName || ''}
                </span>
                {connHistory?.o_name || ''}
                <div className="display-block text-muted">
                    {connHistory?.oa_addressline1}
                </div>
            </td>
            <td>{onShowStatus(connHistory?.ch_connectionstatus)}</td>
        </tr>
    );
};

export default ModalTransHistoryItem;
