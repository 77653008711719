import React from 'react';
import PaymentVoucherTypeSelect from './Dynamic';

const PaymentVoucherTypeSelectField = ({
    input,
    onChange,
    hasAllOption,
    label,
}) => {
    const { value: inputValue, onChange: inputOnChange } = input;
    const handleChange = (value) => {
        inputOnChange(value);
        if (onChange) {
            onChange(value);
        }
    };
    return (
        <PaymentVoucherTypeSelect
            defaultValue={inputValue}
            label={label}
            onChange={handleChange}
            hasAllOption={hasAllOption}
        />
    );
};

PaymentVoucherTypeSelectField.propTypes = {};

export default PaymentVoucherTypeSelectField;
