import { TOKEN_NAME } from '@app/constants/global.const';
import jwt from 'jwt-decode';
import { UserInfoModel } from '../models/account/userInfo.model';
import moment from 'moment';
import { deleteImage } from '../apis/uploads/image';
import numeral from 'numeral';

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

export const createCodeSale = () => {
    let dtcode = '';
    let tcode = '';
    let random4 = '';
    const today = new Date();
    const month = `0${today.getMonth() + 1}`.slice(-2);
    const year = today.getFullYear().toString().substr(-2);
    const day = `0${today.getDate()}`.slice(-2);
    dtcode = year + month + day;
    const hour = `0${today.getHours()}`.slice(-2);
    const min = `0${today.getMinutes()}`.slice(-2);
    tcode = hour + min;
    random4 = makeid(4);

    return `SO-BCN-${dtcode}-${tcode}-${random4}`;
};

export const convertDate = (date, number) => {
    if (date !== null && date !== undefined) {
        let day = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        if (number !== undefined) {
            day = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate() + number
            );
        }
        return day;
    }
};

export const convertDateWithNoTime = (date) => {
    const day = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0
    );
    return day;
};

export const convertDateToEndDate = (date) => {
    const day = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0
    );
    day.setHours(23, 59, 59, 999);
    return day;
};

export const checkExpriedToken = (token) => {
    try {
        if (token) {
            const jwtDecode = jwt(token);
            const currentTime = Math.floor(new Date().getTime() / 1000);
            if (jwtDecode.exp > currentTime) {
                return true;
            }
        }
        return false;
    } catch {
        return false;
    }
};

export const getCurrentUser = () => {
    let userInfo = new UserInfoModel();
    try {
        const token = localStorage.getItem(TOKEN_NAME);
        // console.log({ token });
        if (token) {
            userInfo = new UserInfoModel(jwt(token));
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }

    return userInfo;
};

export const onDeleteImage = (nameImage, link) => {
    const form = new FormData();
    form.append('currentImage', nameImage);
    form.append('directory', link);
    deleteImage(form, 'FORM');
};

export const nonAccentVietnamese = (str) => {
    str = str.toLowerCase();
    str = str.replace(
        /\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g,
        'a'
    );
    str = str.replace(
        /\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g,
        'e'
    );
    str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, 'i');
    str = str.replace(
        /\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g,
        'o'
    );
    str = str.replace(
        /\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g,
        'u'
    );
    str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, 'y');
    str = str.replace(/\u0111/g, 'd');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyen sac hoi nga nang
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // A, O, U
    return str;
};

export const buildFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    return formData;
};

export const priceType = (num) => {
    if (num > 0) {
        const number = num
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        return number;
    }
    return num;
};

export const inputNumber = (num) => {
    if (/^[1-9][0-9]*$/.test(num)) {
        return num;
    }
    return undefined;
};

export const displayDateByIntV1 = (day) =>
    moment(moment.unix(day)).format('DD/MM/YYYY');

export const displayDateByIntV2 = (day) =>
    moment(moment.unix(day)).format('DD/MM/YYYY hh:mm A');

export const deleteSpaceInString = (num) => {
    if (num) {
        const number = num.split(' ').join('');
        return number;
    }
};

export const bankNumberType = (num) => {
    if (num) {
        const number = num
            .replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim();
        return number;
    }
};

export const convertDateToInt = (date) => {
    const res = Date.parse(date) / 1000;
    return res;
};

export const fromDateToInt = (date) => {
    const day = convertDate(date);
    const res = Date.parse(day) / 1000;
    return res;
};

export const toDateToInt = (date) => {
    const day = convertDate(date, 1);
    const res = (Date.parse(day) - 1000) / 1000;
    return res;
};

export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

/**
 * Tạo mã code theo ngày hiện tại
 * @param {string} _prefix : Tiền tố của mã code
 * @returns => PREFIX-XXXXXX-XXXX-XXXX
 */
export const createCode = (_prefix) => {
    const today = moment(new Date());

    return `${_prefix}-${today.format('YYMMDD')}-${today.format(
        'HHmm'
    )}-${makeid(4)}`;
};

// Chỉ dùng cho danh mục, Dùng để kiểm tra khi lưu có bị trùng tên hay không.
// list: là danh sách của module
// idType: là tên của id
// nameType: là tên của phần muốn lưu
// id: là dữ liệu của id
// name: là dữ liệu của tên
export const checkNameToSave = (list, idType, nameType, id, name) => {
    let check;
    if (id === '' || id === 0) {
        check = list.find(
            (item) =>
                item[nameType].replace(/\s/g, '').toUpperCase() ===
                name.replace(/\s/g, '').toUpperCase()
        );
    } else {
        const checkList = list.filter((item) => item[idType] !== id);
        check = checkList.find(
            (item) =>
                item[nameType].replace(/\s/g, '').toUpperCase() ===
                name.replace(/\s/g, '').toUpperCase()
        );
    }
    return check;
};

export const checkNumberToSave = (list, idType, numberType, id, number) => {
    let check;
    if (id === '' || id === 0) {
        check = list.find(
            (item) => item[numberType].toString() === number.toString()
        );
    } else {
        const checkList = list.filter((item) => item[idType] !== id);
        check = checkList.find(
            (item) => item[numberType].toString() === number.toString()
        );
    }
    return check;
};

export const numberToPrice = (num) => numeral(num).format('0,0');

export const decimalToPrice = (num) => {
    if (!Number.isInteger(num)) {
        return numeral(num).format('0,0.[00]');
    }
    return numeral(num).format('0,0');
};

export const priceToNumber = (value) => numeral(value).value();

export const minmax = (vl, minvl, maxvl) => {
    // eslint-disable-next-line no-restricted-globals
    if (parseInt(vl, 0) < minvl || isNaN(parseInt(vl, 0))) {
        return minvl;
    }
    if (parseInt(vl, 0) > maxvl) {
        return maxvl;
    }
    return vl;
};

export const createCodeInventory = (type, date) => {
    let prefix = '';
    let dtcode = '';
    let random4 = '';
    if (type === 1) {
        prefix = 'PNK';
    } else if (type === 2) {
        prefix = 'PXK';
    }
    const today = moment.unix(date).toDate();
    const month = `0${today.getMonth() + 1}`.slice(-2);
    const year = today.getFullYear().toString().substr(-2);
    const day = `0${today.getDate()}`.slice(-2);
    dtcode = year + month + day;
    random4 = makeid(4);

    return `${prefix}-${dtcode}-${random4}-SC`;
};

export const onlyNumber = (str) =>
    parseFloat(str.toString().replace(/[^\d.-]/g, ''));
const mangso = [
    'không',
    'một',
    'hai',
    'ba',
    'bốn',
    'năm',
    'sáu',
    'bảy',
    'tám',
    'chín',
];
const dochangchuc = (so, daydu) => {
    let chuoi = '';
    const chuc = Math.floor(so / 10);
    const donvi = so % 10;
    if (chuc > 1) {
        chuoi = ` ${mangso[chuc]} mươi`;
        if (donvi === 1) {
            chuoi += ' mốt';
        }
    } else if (chuc === 1) {
        chuoi = ' mười';
        if (donvi === 1) {
            chuoi += ' một';
        }
    } else if (daydu && donvi > 0) {
        chuoi = ' lẻ';
    }
    if (donvi === 5 && chuc > 1) {
        chuoi += ' lăm ';
    } else if (donvi > 1 || (donvi === 1 && chuc === 0)) {
        chuoi += ` ${mangso[donvi]}`;
    }
    return chuoi;
};
const docblock = (so, daydu) => {
    let chuoi = '';
    const tram = Math.floor(so / 100);
    so %= 100;
    if (daydu || tram > 0) {
        chuoi = ` ${mangso[tram]} trăm`;
        chuoi += dochangchuc(so, true);
    } else {
        chuoi = dochangchuc(so, false);
    }
    return chuoi;
};
const dochangtrieu = (so, daydu) => {
    let chuoi = '';
    const trieu = Math.floor(so / 1000000);
    so %= 1000000;
    if (trieu > 0) {
        chuoi = `${docblock(trieu, daydu)} triệu`;
        daydu = true;
    }
    const nghin = Math.floor(so / 1000);
    so %= 1000;
    if (nghin > 0) {
        chuoi += `${docblock(nghin, daydu)} nghìn`;
        daydu = true;
    }
    if (so > 0) {
        chuoi += docblock(so, daydu);
    }
    return chuoi;
};
export const docso = (so) => {
    let chuoi = '';
    let hauto = '';
    let ty = 0;
    if (so === 0) return mangso[0];
    do {
        ty = so % 1000000000;
        so = Math.floor(so / 1000000000);
        if (so > 0) {
            chuoi = dochangtrieu(ty, true) + hauto + chuoi;
        } else {
            chuoi = dochangtrieu(ty, false) + hauto + chuoi;
        }
        hauto = ' tỷ';
    } while (so > 0);
    return chuoi;
};

export const precisionRound = (number, precision) => {
    const factor = 10 ** precision; // Math.pow(10, precision);
    return Math.round(number * factor) / factor;
};

/**
 * Render text break line từ CSDLCSD
 * @param {String} str : số phần tử trong mỗi sub arr
 */
export const textBreakLine = (str) => {
    const arrString = str.split('\\n');
    let value = str;
    if (arrString.length > 1) {
        value = '';
        arrString.forEach((o, i) => {
            value += o;
            if (i < arrString.length - 1) {
                value += '\n';
            }
        });
    }
    return value;
};
