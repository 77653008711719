import { AccountUserModel } from '@app/models/account/accountUser.model';
import { createSlice } from '@reduxjs/toolkit';
import imgDefault from '@app/assets/images/avatar_default_2.png';

const MODULE_NAME = 'accountList';

const initialState = {
    isShowModal: false,
    isEdit: false,

    accountList: [],

    editItem: new AccountUserModel(),
};

const AccountListSlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        doOpenModal(state, { payload }) {
            state.isShowModal = true;

            state.editItem = payload
                ? new AccountUserModel(payload)
                : new AccountUserModel();
            state.isEdit = !!payload;
        },
        doCloseModal(state) {
            state.isShowModal = false;
        },

        doGetListAccount() {},
        doGetListAccountSuccess(state, { payload }) {
            state.accountList = payload;
        },

        doDeleteAccount() {},
        doDeleteAccountSuccess(state, { payload }) {
            const newList = state.accountList.filter(
                (o) => o.su_id !== payload
            );

            state.accountList = newList;
        },

        doResetPasswordAccount() {},

        doSaveAccount() {},
        doSaveAccountSuccess(state, { payload }) {
            const { data, isAdd } = payload;

            const newList = isAdd
                ? [...state.accountList, data]
                : state.accountList.map((o) => {
                      if (o.su_id === data.su_id) {
                          o = {
                              ...o,
                              ...data,
                          };
                      }

                      return o;
                  });

            state.isShowModal = isAdd;
            state.isEdit = false;
            state.accountList = newList;
            state.editItem = new AccountUserModel({ su_avatar: imgDefault });
        },
    },
});

/** ===== ACTION ==== **/
export const accountListActions = AccountListSlice.actions;

/** ===== SELECTOR ==== **/
export const accountListSelector = {
    isShowModal: (state) => state[MODULE_NAME].isShowModal,
    isEdit: (state) => state[MODULE_NAME].isEdit,
    editItem: (state) => state[MODULE_NAME].editItem,
    accountList: (state) => state[MODULE_NAME].accountList,
};

/** ===== REDUCER ==== **/
const accountListReducer = AccountListSlice.reducer;
export default accountListReducer;
