// /* eslint-disable react/no-this-in-sfc */
// import { MenuListModel } from '@app/models/foodkid/foodkid.model';
// import { formatDateUnix } from '@app/utils/datetime.utils';
// import { decimalToPrice } from '@app/utils/utils';
// import { HotTable } from '@handsontable/react';
// import Handsontable from 'handsontable';
// import moment from 'moment';
// import React, { Fragment, useEffect, useRef, useState } from 'react';

// const FoodkidList = ({ listData, updateMenuResult }) => {
//     const tableRef = useRef();
//     const [settings, setSettings] = useState('');

//     const _listData = listData.map((item) => {
//         const itemMapping = new MenuListModel(item);

//         return {
//             ...itemMapping,
//             status: item.status || '',
//             m_datefounded:
//                 item.m_datefounded === 0
//                     ? ''
//                     : formatDateUnix(item.m_datefounded),
//             m_balance: decimalToPrice(item.m_price - item.m_priceafter),
//         };
//     });

//     const onChangeCode = (value, item) => {
//         item.m_code = value;
//         item.status = 0;
//         item.is_update = true;
//         return item;
//     };

//     const onChangeQuantityBefore = (value, item) => {
//         item.m_quantitybefore = parseInt(value);
//         item.status = 0;
//         item.is_update = true;
//         return item;
//     };

//     const onChangeDateFound = (value, item) => {
//         item.m_datefounded = value;
//         item.status = 0;
//         item.is_update = true;

//         return item;
//     };

//     const staticConfig = {
//         colHeaders: [
//             'Mã',
//             'Nhóm trẻ',
//             'Sỉ số',
//             'Tiền ĐM',
//             'Món ăn',
//             'Ngày lập',
//             'Tiền dư',
//             'Dịch vụ',
//             'Tiền MT',
//             'Tình trạng',
//         ],
//         // colHeaders: (col) => {
//         //     switch (col) {
//         //         case 0:
//         //             return '<div style="font-weight:550">Mã</div>';
//         //         case 1:
//         //             return '<div style="font-weight:550">Nhóm trẻ</div>';
//         //         case 2:
//         //             return '<div style="font-weight:550">Sỉ<br />số</div>';
//         //         case 3:
//         //             return '<div style="font-weight:550">Tiền<br>ĐM</div>';
//         //         case 4:
//         //             return '<div style="font-weight:550">Món ăn</div>';
//         //         case 5:
//         //             return '<div style="font-weight:550">Ngày lập</div>';
//         //         case 6:
//         //             return '<div style="font-weight:550">Tiền<br>dư</div>';
//         //         case 7:
//         //             return '<div style="font-weight:550">Dịch<br>vụ</div>';
//         //         case 8:
//         //             return '<div style="font-weight:550">Tiền<br>MT</div>';
//         //         case 9:
//         //             return '<div style="font-weight:550">Tình trạng</div>';
//         //         default:
//         //     }
//         // },

//         columns: [
//             {
//                 data: 'm_code',
//                 type: 'text',
//                 className: 'htCenter htMiddle',
//                 readOnly: true,
//             },
//             {
//                 data: 'kg_name',
//                 type: 'text',
//                 readOnly: true,
//                 className: 'htCenter htMiddle',
//             },
//             {
//                 data: 'm_quantitybefore',
//                 type: 'numeric',
//                 readOnly: true,
//                 className: 'htRight htMiddle',
//                 columnSorting: {
//                     indicator: false,
//                     headerAction: false,
//                     compareFunctionFactory: function compareFunctionFactory() {
//                         return function comparator() {
//                             return 0; // Don't sort the first visual column.
//                         };
//                     },
//                 },
//             },
//             {
//                 data: 'm_price',
//                 type: 'numeric',
//                 numericFormat: {
//                     pattern: '0,0',
//                 },
//                 readOnly: true,
//                 // width: 40,
//                 className: 'htRight htMiddle',
//                 columnSorting: {
//                     indicator: false,
//                     headerAction: false,
//                     compareFunctionFactory: function compareFunctionFactory() {
//                         return function comparator() {
//                             return 0; // Don't sort the first visual column.
//                         };
//                     },
//                 },
//             },
//             {
//                 data: 'm_meal',
//                 type: 'text',
//                 readOnly: true,
//                 columnSorting: {
//                     indicator: false,
//                     headerAction: false,
//                     compareFunctionFactory: function compareFunctionFactory() {
//                         return function comparator() {
//                             return 0; // Don't sort the first visual column.
//                         };
//                     },
//                 },
//                 // width: 250,
//                 // renderer(instance, td, row, col, prop, value) {
//                 //     td.style.whiteSpace = 'nowrap';
//                 //     td.style.overflow = 'hidden';
//                 //     td.style.textOverflow = 'ellipsis';

//                 //     td.title = value;

//                 //     td.innerText = value;
//                 //     Handsontable.renderers.TextRenderer.apply(
//                 //         this,
//                 //         // eslint-disable-next-line prefer-rest-params
//                 //         arguments
//                 //     );

//                 //     return td;
//                 // },
//             },
//             {
//                 data: 'm_datefounded',
//                 type: 'date',
//                 dateFormat: 'DD/MM/YYYY',
//                 correctFormat: true,
//                 className: 'htCenter htMiddle',
//                 columnSorting: {
//                     indicator: false,
//                     headerAction: false,
//                     compareFunctionFactory: function compareFunctionFactory() {
//                         return function comparator() {
//                             return 0; // Don't sort the first visual column.
//                         };
//                     },
//                 },
//                 // width: 100,
//             },
//             {
//                 data: 'm_balance',
//                 type: 'numeric',
//                 numericFormat: {
//                     pattern: '0,0',
//                 },
//                 // width: 50,
//                 readOnly: true,
//                 className: 'htRight htMiddle',
//                 columnSorting: {
//                     indicator: false,
//                     headerAction: false,
//                     compareFunctionFactory: function compareFunctionFactory() {
//                         return function comparator() {
//                             return 0; // Don't sort the first visual column.
//                         };
//                     },
//                 },
//             },
//             {
//                 data: 'm_service',
//                 type: 'numeric',
//                 numericFormat: {
//                     pattern: '0,0',
//                 },
//                 // width: 50,
//                 readOnly: true,
//                 className: 'htRight htMiddle',
//                 columnSorting: {
//                     indicator: false,
//                     headerAction: false,
//                     compareFunctionFactory: function compareFunctionFactory() {
//                         return function comparator() {
//                             return 0; // Don't sort the first visual column.
//                         };
//                     },
//                 },
//             },
//             {
//                 data: 'm_priceafter',
//                 type: 'numeric',
//                 numericFormat: {
//                     pattern: '0,0',
//                 },
//                 // width: 50,
//                 readOnly: true,
//                 className: 'htRight htMiddle',
//                 columnSorting: {
//                     indicator: false,
//                     headerAction: false,
//                     compareFunctionFactory: function compareFunctionFactory() {
//                         return function comparator() {
//                             return 0; // Don't sort the first visual column.
//                         };
//                     },
//                 },
//             },
//             {
//                 data: 'status',
//                 type: 'text',
//                 readOnly: true,
//                 className: 'htCenter htMiddle',
//                 columnSorting: {
//                     indicator: false,
//                     headerAction: false,
//                     compareFunctionFactory: function compareFunctionFactory() {
//                         return function comparator() {
//                             return 0; // Don't sort the first visual column.
//                         };
//                     },
//                 },
//                 // width: 100,
//                 renderer(instance, td, row, col, prop, value) {
//                     if (value) {
//                         const span = document.createElement('span');
//                         const tagIcon = document.createElement('i');
//                         span.classList.add('label');

//                         switch (value) {
//                             case -1:
//                                 span.classList.add('label-danger');
//                                 tagIcon.classList.add('icon-cross2');
//                                 value = 'Thực đơn đã tồn tại';
//                                 break;
//                             case 1:
//                                 span.classList.add('label-primary');
//                                 tagIcon.classList.add('icon-hour-glass2');
//                                 value = 'Đang xử lý';
//                                 break;
//                             case 2:
//                                 span.classList.add('label-success');
//                                 tagIcon.classList.add('icon-checkmark4');
//                                 value = 'Hoàn thành';
//                                 break;
//                             default:
//                                 value = '';
//                                 break;
//                         }

//                         span.appendChild(tagIcon);
//                         span.innerHTML += ` ${value}`;

//                         td.classList.add('htCenter');
//                         td.classList.add('htMiddle');
//                         td.classList.add('lightblue-bg');

//                         td.innerText = '';
//                         td.appendChild(span);
//                     } else {
//                         Handsontable.renderers.TextRenderer.apply(
//                             this,
//                             // eslint-disable-next-line prefer-rest-params
//                             arguments
//                         );
//                     }
//                     return td;
//                 },
//             },
//         ],

//         licenseKey: 'non-commercial-and-evaluation',
//         width: '100%',
//         height: 580,
//         colWidth: 100,
//         stretchH: 'all',
//         autoWrapRow: true,
//         colWidths: [40, 40, 25, 30, 150, 50, 30, 30, 30, 80],
//         autoColumnSize: { useHeaders: true },
//         columnHeaderHeight: 40,
//         preventOverflow: 'horizontal',
//         afterChange: (changes) => {
//             if (!changes) {
//                 return;
//             }
//             changes.forEach(([row, prop, oldValue, newValue]) => {
//                 if (tableRef.current) {
//                     const { hotInstance } = tableRef.current;
//                     switch (prop) {
//                         case 'm_code': {
//                             if (oldValue !== newValue) {
//                                 listData[row] = onChangeCode(
//                                     newValue,
//                                     listData[row]
//                                 );
//                             }
//                             break;
//                         }
//                         case 'm_quantitybefore': {
//                             if (parseFloat(oldValue) !== newValue) {
//                                 listData[row] = onChangeQuantityBefore(
//                                     newValue,
//                                     listData[row]
//                                 );
//                             }
//                             break;
//                         }
//                         case 'm_datefounded': {
//                             let _newValue = 0;

//                             const [day = 0, month = 0, year = 0] =
//                                 newValue?.split('/') || [];

//                             if (
//                                 newValue?.trim() !== '' &&
//                                 day !== 0 &&
//                                 month !== 0 &&
//                                 year !== 0
//                             ) {
//                                 _newValue = moment(
//                                     new Date(+year, +month - 1, +day)
//                                 ).unix();
//                             }

//                             listData[row] = onChangeDateFound(
//                                 _newValue,
//                                 listData[row]
//                             );

//                             break;
//                         }
//                         default: {
//                             break;
//                         }
//                     }
//                     updateMenuResult(listData);
//                     hotInstance.render();
//                 }
//             });
//         },
//     };

//     useEffect(() => {
//         setSettings({
//             ...staticConfig,
//             data: _listData,
//             filtersKeyValue: true,
//         });

//         if (tableRef.current) {
//             const { hotInstance } = tableRef.current;
//             hotInstance.updateSettings({
//                 cells(row, col, prop) {
//                     const hot = this.instance;
//                     if (_listData[row] !== undefined) {
//                         if (_listData[row].is_update) {
//                             for (let i = 0; i < 10; i++) {
//                                 if (i === 5 || i === 9 || i === 1 || i === 0) {
//                                     hot.setCellMeta(
//                                         row,
//                                         i,
//                                         'className',
//                                         'lightblue-bg htCenter htMiddle'
//                                     );
//                                 } else if (i === 4) {
//                                     hot.setCellMeta(
//                                         row,
//                                         i,
//                                         'className',
//                                         'lightblue-bg htLeft htMiddle'
//                                     );
//                                 } else {
//                                     hot.setCellMeta(
//                                         row,
//                                         i,
//                                         'className',
//                                         'lightblue-bg htRight htMiddle'
//                                     );
//                                 }
//                             }
//                         }
//                     }
//                 },
//             });
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [listData]);

//     return (
//         <Fragment>
//             <div id="example1">
//                 <HotTable
//                     id="hot"
//                     settings={settings}
//                     licenseKey="non-commercial-and-evaluation"
//                     ref={tableRef}
//                 />
//             </div>
//         </Fragment>
//     );
// };

// export default FoodkidList;
import React from 'react';
import { Checkbox } from 'antd';

const FoodkidList = ({ menuList, onChangeCheckAll, ...props }) => (
    <div className="row">
        <div className="col-lg-12">
            <div className="panel panel-white ">
                <table className="table table-togglable table-hover table-bordered">
                    <thead>
                        <tr>
                            <th
                                className="text-center footable-visible footable-last-column"
                                style={{ width: '35px' }}
                            >
                                <Checkbox
                                    checked={
                                        menuList.length > 0 &&
                                        menuList.filter((o) => o.is_update)
                                            .length === menuList.length
                                    }
                                    onChange={onChangeCheckAll}
                                />
                            </th>
                            <th
                                className="text-center"
                                data-toggle="true"
                                style={{ width: '10%' }}
                            >
                                Mã thực đơn mẫu
                                <span className="display-block text-muted">
                                    Sĩ số dự trù
                                </span>
                            </th>
                            <th className="text-center" style={{ width: '8%' }}>
                                Tiền định mức
                                <span className="display-block text-muted">
                                    Tiền dư
                                </span>
                            </th>
                            <th
                                className="text-center"
                                data-hide="phone"
                                style={{ width: '8%' }}
                            >
                                Tiền một trẻ
                                <span className="display-block text-muted">
                                    Tiền dịch vụ
                                </span>
                            </th>
                            <th className="text-center" data-hide="phone">
                                Món ăn
                            </th>
                            <th
                                className="text-center"
                                style={{ width: '12%' }}
                            >
                                Ngày lập
                            </th>
                            <th
                                className="text-center"
                                data-hide="phone"
                                style={{ width: '8%' }}
                            >
                                Sĩ số thực tế
                            </th>
                            <th
                                className="text-center"
                                style={{ width: '80px' }}
                            >
                                Tình trạng
                            </th>
                        </tr>
                    </thead>
                    <tbody>{props.children}</tbody>
                </table>
            </div>
        </div>
    </div>
);

export default FoodkidList;
