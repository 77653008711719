import React from 'react';
import RangeDatePicker from '@app/components/_Share/Date/RangeDate';
import CommonSelect from '@app/components/_Share/Select/CommonSelect';
import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

import { DATE_RANGE_LIMIT_TYPE } from '@app/components/_Share/Date/RangeDate/_service';

const TransactionHistoryFilter = (props) => {
    const { onSearch, onChangeStatus, onChangeDate, statusSelected } = props;

    const StatusData = [
        { id: -1, name: 'Tất cả' },
        { id: 1, name: 'Chờ thanh toán' },
        { id: 2, name: 'Hoàn thành' },
        { id: 3, name: 'Hủy bỏ' },
    ];

    return (
        <div className="row">
            <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="form-group">
                    <RangeDatePicker
                        rangeType={DATE_RANGE_LIMIT_TYPE.MONTH}
                        defaultRange={1}
                        limitRange={36}
                        onChange={onChangeDate}
                    />
                </div>
            </div>
            <div className="col-md-3 col-xs-6">
                <div className="form-group">
                    <div className="input-group">
                        <span className="input-group-addon">Tình trạng</span>
                        <CommonSelect
                            dataSource={StatusData}
                            fieldId="id"
                            fieldName="name"
                            defaultValue={statusSelected}
                            onChange={onChangeStatus}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-3 col-xs-6">
                <div className="form-group">
                    <div className="no-margin">
                        <div className="btn-group">
                            <ButtonFilterAction
                                labelButton="Tìm kiếm"
                                buttonType={BUTTON_ACTION_TYPE.SEARCH}
                                onClick={onSearch}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionHistoryFilter;
