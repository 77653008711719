import { RECEIPT_VOUCHER_TYPE } from '@app/constants/receiptCost.CONST';
import { InvoiceModel } from '@app/models/income/invoice.model';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { PAYMENT_VOUCHER_MODULE_NAME } from './constants';

const initialState = {
    listData: [],
    isShowPopup: false,
    editItem: new InvoiceModel({
        i_type: RECEIPT_VOUCHER_TYPE.PHIEU_CHI,
    }),
    total: 0,
    currentPage: 1,
    pageSize: 30,
    isCheckAll: false,
};

const paymentVoucherSlice = createSlice({
    name: PAYMENT_VOUCHER_MODULE_NAME,
    initialState,
    reducers: {
        doGetListSuccess: (state, { payload }) => {
            state.listData = payload;
        },

        doGetTotalListSuccess: (state, { payload }) => {
            state.total = payload;
        },

        doChangeCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },

        doTogglePopup: (state, { payload }) => {
            state.isShowPopup = payload;
        },

        doCreate: (state) => {
            state.isShowPopup = true;
            state.editItem = new InvoiceModel({
                i_type: RECEIPT_VOUCHER_TYPE.PHIEU_CHI,
            });
        },

        doSaveSuccess: (state, { payload }) => {
            state.listData = _.orderBy(
                [...state.listData, payload],
                ['i_datefrom'],
                ['asc']
            );
            state.isShowPopup = false;
        },

        doCheckItem: (state, { payload }) => {
            const { checkValue, dataItem } = payload;
            let { listData } = state;
            listData = listData.map((o) => {
                if (o.i_id === dataItem.i_id) {
                    o = {
                        ...o,
                        isCheck: checkValue,
                    };
                }
                return o;
            });

            const listCheck = listData.filter((o) => o.isCheck);

            state.isCheckAll =
                listData.length !== 0 && listData.length === listCheck.length;
            state.listData = listData;
        },

        doCheckAll: (state, { payload }) => {
            state.isCheckAll = payload;
            state.listData = state.listData.map((o) => ({
                ...o,
                isCheck: payload,
            }));
        },

        doDeleteSuccess: (state, { payload }) => {
            state.listData = state.listData.filter(
                (o) => !_.includes(payload, o.i_id)
            );
            const listCheck = state.listData.filter((o) => o.isCheck);
            state.isCheckAll =
                state.listData.length === listCheck.length &&
                state.listData.length !== 0;
        },
    },
});

export const _paymentVoucherActs = paymentVoucherSlice.actions;
export default paymentVoucherSlice.reducer;
