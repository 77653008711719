const validate = (values) => {
    const errors = {};
    const { o_securitycode } = values;
    if (!o_securitycode) {
        errors.o_securitycode = 'Mã bảo mật không được để trống!';
    }
    return errors;
};

export default validate;
