import axiosService from '../../services/axiosService';
import {
    API_SC_FOODKID_ENPOINT,
    API_SC_GATEWAY_ENDPOINT,
} from '../../constants/api';

const BASE_URL = `${API_SC_FOODKID_ENPOINT}/v1/sc/foodkids/kidgroup`;

const BASE_GATE_WAY = `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/Foodkid/KidGroup`;

const LIST_URL = {
    GET_LIST_BY_OID: `${BASE_URL}/GetListByOId`,
    GET_LIST_BY_OID_V2: `${BASE_GATE_WAY}/GetListByOId`,
};

/**
 * Lấy danh sách nhóm trẻ theo id tổ chức
 * @param {string} oId: Id tổ chức
 * @returns => Danh sách nhóm trẻ
 */
export const apiGetListByOId = (oId) =>
    axiosService.get(`${LIST_URL.GET_LIST_BY_OID}/${oId}`);

const _kidGroupApis = {
    getListByOId(oId) {
        return axiosService.get(`${LIST_URL.GET_LIST_BY_OID_V2}/${oId}`);
    },
};

export default _kidGroupApis;
