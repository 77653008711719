import React from 'react';
import { numberToPrice } from '@app/utils/utils';
import { formatDateUnix } from '@app/utils/datetime.utils';

const ModalTransHistoryItem = ({ transHistory }) => {
    const onShowStatus = (paymentStatus, orderStatus) => {
        let html = null;

        if (+orderStatus === 4) {
            return (
                <td>
                    <span className="label label-danger">
                        <i className="icon-cancel-circle2" /> Hủy bỏ
                    </span>
                </td>
            );
        }

        switch (paymentStatus) {
            case 1:
            case 2:
                html = (
                    <span className="label label-primary bg-purple">
                        <i className="icon-cash" /> Chờ thanh toán
                    </span>
                );
                break;

            case 3:
                html = (
                    <span className="label label-success">
                        <i className="icon-checkmark4" /> Hoàn thành
                    </span>
                );
                break;

            default:
                break;
        }
        return html;
    };

    return (
        <tr>
            <td className="text-center">
                {formatDateUnix(
                    transHistory?.so_dateordered,
                    'DD/MM/YYYY HH:mm:ss'
                )}
            </td>
            <td className="text-center">{transHistory?.so_code}</td>
            <td>{transHistory?.so_description}</td>
            <td className="text-right">
                {numberToPrice(transHistory?.so_subtotal)}
            </td>
            <td>
                {onShowStatus(
                    transHistory?.so_paymentstatus,
                    transHistory?.so_orderstatus
                )}
            </td>
        </tr>
    );
};

export default ModalTransHistoryItem;
