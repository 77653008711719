import PageHeader from '@app/components/Layout/PageHeader/PageHeader';
import NoData from '@app/components/Layout/Shares/NoData';
import { SWEET_ALERT_TYPES } from '@app/constants';
import {
    accountRoleActions,
    accountRoleSelector,
} from '@app/redux/account/accountRole/accountRole.slice';
import { swal } from '@app/utils/alert';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountRoleFilter from './AccountRoleFilter';
import AccountRoleItem from './AccountRoleItem';
import AccountRoleList from './AccountRoleList';
import AccountRoleModal from './AccountRoleModal';

const AccountRole = () => {
    const roleList = useSelector(accountRoleSelector.roleList);
    const isShowModal = useSelector(accountRoleSelector.isShowModal);
    const editItem = useSelector(accountRoleSelector.editItem);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(accountRoleActions.doGetListRole());
    }, [dispatch]);

    /** Thêm mới */
    const onOpenModal = (data) => {
        dispatch(accountRoleActions.doOpenModal(data));
    };

    /** Đóng popup */
    const onCloseModal = () => {
        dispatch(accountRoleActions.doCloseModal());
    };

    const onDelete = (data) => {
        swal(
            SWEET_ALERT_TYPES.QUESTION,
            `<p>Bạn có muốn xóa vai trò <b>${data.sr_name}</b> không?</p>`,
            true
        ).then((res) => {
            if (res.value) {
                dispatch(accountRoleActions.doDeleteRole(data.sr_id));
            }
        });
    };

    const onUpdateStatus = (data, valueChange) => {
        const status = valueChange ? 1 : 0;

        dispatch(
            accountRoleActions.doUpdateStatus({ ...data, sr_status: status })
        );
    };

    const onSubmitData = (data) => {
        dispatch(accountRoleActions.doSaveRole(data));
    };

    const onShowData = (_list) => {
        let xhtml = null;

        if (_list.length > 0) {
            xhtml = _list.map((item, idx) => (
                <AccountRoleItem
                    key={idx}
                    accountRole={item}
                    onDelete={(data) => onDelete(data)}
                    onEdit={(data) => onOpenModal(data)}
                    onUpdateStatus={(data, valueChange) =>
                        onUpdateStatus(data, valueChange)
                    }
                />
            ));
        } else {
            xhtml = <NoData colspan={5} />;
        }
        return xhtml;
    };

    return (
        <Fragment>
            <PageHeader pageTitle="Vai trò người dùng" />
            <div className="content">
                <AccountRoleFilter onAddRole={onOpenModal} />

                <AccountRoleList>{onShowData(roleList)}</AccountRoleList>
            </div>

            <AccountRoleModal
                isShow={isShowModal}
                onClose={onCloseModal}
                initialValues={editItem}
                onSubmit={onSubmitData}
            />
        </Fragment>
    );
};

export default AccountRole;
