/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import RangeDateService from './_service';

const propTypes = {
    // Giá trị giới hạn mặc định
    defaultRange: PropTypes.number,

    // Giá trị giới hạn
    limitRange: PropTypes.number,

    // limit
    minDate: PropTypes.instanceOf(moment),
    maxDate: PropTypes.instanceOf(moment),
    // Loại giới hạn (days, week, month)
    rangeType: PropTypes.number,

    // Sự kiện thay đổi
    onChange: PropTypes.func,
    defaultStartDate: PropTypes.instanceOf(moment),
    defaultEndDate: PropTypes.instanceOf(moment),
};

const SCRangeDate = ({
    defaultRange,
    limitRange,
    minDate, maxDate,
    rangeType,
    onChange,
    defaultStartDate,
    defaultEndDate,
}) => {
    // Giá trị giới hạn mặc định
    defaultRange = defaultRange || 1;

    // Giá trị giới hạn
    limitRange = limitRange || 1;

    // Kiểu giới hạn (day, week, month)
    const rangeTypeStr = RangeDateService.getTypeLimit(rangeType);

    const [objRangDate, setObjRangeDate] = useState({
        startDate: moment(new Date()).startOf(rangeTypeStr),
        endDate: moment(new Date())
            .startOf(rangeTypeStr)
            .add(defaultRange, rangeTypeStr),
    });

    useEffect(() => {
        if (defaultStartDate && defaultEndDate) {
            setObjRangeDate({
                ...objRangDate,
                startDate: defaultStartDate,
                endDate: defaultEndDate,
            });
            return;
        }
        if (defaultStartDate) {
            setObjRangeDate({
                ...objRangDate,
                startDate: defaultStartDate,
            });
            return;
        }
        if (defaultEndDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: defaultEndDate,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onChange) {
            onChange({
                startDate: objRangDate.startDate.toDate(),
                endDate: objRangDate.endDate.toDate(),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objRangDate]);

    const disabledDate = useCallback((d) => {
        if (d == null) {
            return null;
        }
        return (
            (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
            (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day'))
        );
    }, [minDate, maxDate]);

    const handleEndDateChange = (s) => {
        // Ngày được chọn nhỏ hơn ngày bắt đầu
        if (s <= objRangDate.startDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: s,
                startDate: moment(s.startOf('day')).subtract(1, 'days'),
            });
            return;
        }

        // Ngày chọn lớn hơn số giới hạn
        if (limitRange) {
            const _limitRangeDate = moment(
                objRangDate.startDate.startOf('day')
            ).add(limitRange, rangeTypeStr);

            if (_limitRangeDate < s) {
                setObjRangeDate({
                    ...objRangDate,
                    endDate: s,
                    startDate: moment(s.startOf('day')).subtract(
                        limitRange,
                        rangeTypeStr
                    ),
                });
                return;
            }
        }
        setObjRangeDate({
            ...objRangDate,
            endDate: s,
        });
    };

    const handleStartDateChange = (s) => {
        // Ngày được chọn lớn hơn ngày kết thúc
        if (s >= objRangDate.endDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: moment(s.startOf('day')).add(1, 'days'),
                startDate: s,
            });
            return;
        }

        if (limitRange) {
            const _limitRangeDate = moment(
                objRangDate.endDate.startOf('day')
            ).subtract(limitRange, rangeTypeStr);

            if (_limitRangeDate > s) {
                setObjRangeDate({
                    ...objRangDate,
                    startDate: s,
                    endDate: moment(s.startOf('day')).add(
                        limitRange,
                        rangeTypeStr
                    ),
                });
                return;
            }
        }

        setObjRangeDate({
            ...objRangDate,
            startDate: s,
        });
    };

    return (
        <div className="row">
            <div className="col-sm-6">
                <div className="form-group">
                    <label>
                        <h6 className="panel-title">Từ ngày</h6>
                    </label>
                    <DatePicker
                        value={objRangDate.startDate}
                        format="DD/MM/YYYY"
                        onChange={(s) => handleStartDateChange(s)}
                        allowClear={false}
                        disabledDate={disabledDate}
                    />
                </div>
            </div>
            <div className="col-sm-6">
                <div className="form-group">
                    <label>
                        <h6 className="panel-title">Đến ngày</h6>
                    </label>
                    <DatePicker
                        value={objRangDate.endDate}
                        format="DD/MM/YYYY"
                        onChange={(s) => handleEndDateChange(s)}
                        allowClear={false}
                        disabledDate={disabledDate}
                    />
                </div>
            </div>
        </div>
    );
};

SCRangeDate.propTypes = propTypes;

export default SCRangeDate;
