import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { notification } from 'antd';
import { ACTION_API_TYPES } from '../constants';

const MySwal = withReactContent(Swal);

export const getDeleteContent = (name, type) => ({
    title: 'Thông báo',
    html: `<p> Bạn có muốn xóa <b> ${name} </b>không ?</p>`,
    icon: type,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Xóa',
    cancelButtonText: 'Hủy',
});

export const getRecoverContent = (name, type) => ({
    title: 'Thông báo',
    html: `<p> Bạn có muốn khôi phục <b> ${name} </b>không ?</p>`,
    icon: type,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Khôi phục',
    cancelButtonText: 'Hủy',
});

export const getCancelContent = (name, type) => ({
    title: 'Thông báo',
    html: `<p> Bạn có muốn hủy <b> ${name} </b>không ?</p>`,
    icon: type,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Có',
    cancelButtonText: 'Không',
});

export const getCustomContent = (name, type) => ({
    title: 'Thông báo',
    html: `${name}`,
    icon: type,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Có',
    cancelButtonText: 'Không',
});

export const getEditSaleOrderContent = (name, type) => ({
    title: 'Thông báo',
    html: `<p> Bạn có muốn chỉnh sửa đơn hàng <b> ${name} </b>không ?</p>`,
    icon: type,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Có',
    cancelButtonText: 'Không',
});

export const getEditContent = (name, type) => ({
    title: 'Thông báo',
    html: '<p> Bạn có thực sự muốn chỉnh sửa dữ liệu này không ?</p>',
    icon: type,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ok',
    cancelButtonText: 'Hủy',
});

export const notiContent = (type, content) => ({
    title: 'Thông báo',
    html: `<p>${content}</p>`,
    icon: type,
    showCancelButton: false,
    confirmButtonColor: '#3085d6',
    // cancelButtonColor: "#d33",
    confirmButtonText: 'Ok',
    // cancelButtonText: "Hủy"
});

/**
 * Hiển thị thông báo
 * @param {string} type : kiểu thông báo
 * @param {string} content : nội dung thông báo
 * @param {boolean} isQuestion : Thông báo có phải kiểu câu hỏi không
 */
export const swal = (type, content, isQuestion) => {
    let config = {
        title: 'Thông báo',
        html: content,
        icon: type,
    };
    if (isQuestion) {
        config = {
            title: 'Thông báo',
            html: content,
            icon: type,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy',
        };
    }
    return MySwal.fire(config);
};

export const swalLoading = (msg) => {
    Swal.fire({
        html: msg,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
        },
    });
};

export const swalClose = () => {
    Swal.close();
};

export const alertDeleteReason = (content, valueConfirm) => {
    const config = {
        title: 'Thông báo',
        html: `${content} `,
        input: 'text',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        allowOutsideClick: false,
        preConfirm: (res) => {
            if (!res) {
                Swal.showValidationMessage(
                    'Bạn chưa nhập! Xin vui lòng kiểm tra lại'
                );
            } else if (valueConfirm) {
                if (res.replace(/\s+/g, '').toLowerCase() !== valueConfirm) {
                    Swal.showValidationMessage(
                        'Bạn nhập chưa chính xác! Xin vui lòng kiểm tra lại'
                    );
                }
            }
            Swal.enableButtons();
        },
    };
    return MySwal.fire(config);
};

export const alertDeletSP = (content, valueConfirm) => {
    if (!valueConfirm) {
        valueConfirm = 'tôiđồngý';
    }

    const config = {
        title: 'Thông báo',
        html: `${content} `,
        input: 'text',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        allowOutsideClick: false,
        preConfirm: (res) => {
            if (!res) {
                Swal.showValidationMessage(
                    'Bạn chưa nhập! Xin vui lòng kiểm tra lại'
                );
            } else if (res.replace(/\s+/g, '').toLowerCase() !== valueConfirm) {
                Swal.showValidationMessage(
                    'Bạn nhập chưa chính xác! Xin vui lòng kiểm tra lại'
                );
            }
            Swal.enableButtons();
        },
    };
    return MySwal.fire(config);
};

export const createDefaultContentSP = (strConfirm) =>
    '<p>Bạn có muốn xóa các đối tượng được chọn không ?<p>' +
    '<p><b style="color:#FF7043">Lưu ý:</b> Xóa các đối tượng được chọn có thể dẫn đến việc xóa các dữ liệu liên quan. ' +
    `Vì vậy xin vui lòng nhập <b  style="color:#FF7043">${strConfirm.toUpperCase()}</b> để chắc chắn bạn muốn xóa những đối tượng này<p>`;

export const SWAL_CONST = {
    TYPE: {
        SUCCESS: 'success',
        QUESION: 'question',
        WARNING: 'warning',
        INFO: 'info',
        ERROR: 'error',
    },
};

/**
 * Hiển thị thông báo lỗi
 * @param {Number} statusCode: mã lỗi
 * @param {Object} data: Dữ liệu API trả về
 * @param {String} data.msg: Thông báo từ API trả về
 */
export const showError = (statusCode, data) => {
    let messageStr = 'Có lỗi xảy ra trong quá trình thực hiện';
    if (process.env.NODE_ENV === 'development') {
        messageStr = `Có lỗi xảy ra ${statusCode}`;
    }
    notification.error({
        message: messageStr,
        description: data.msg,
        className: 'notif-err',
        duration: 5,
    });
};

/**
 * Hiển thị thông báo thành công
 * @param {number} type : loại thông báo ACTION_API_TYPES
 */
export const showSuccess = (type) => {
    let msg = '';
    switch (type) {
        case ACTION_API_TYPES.GET: {
            msg = 'Lấy dữ liệu thành công';
            break;
        }
        case ACTION_API_TYPES.ADD: {
            msg = 'Thêm dữ liệu thành công';
            break;
        }
        case ACTION_API_TYPES.EDIT: {
            msg = 'Chỉnh sửa dữ liệu thành công';
            break;
        }
        case ACTION_API_TYPES.DELETE: {
            msg = 'Xóa dữ liệu thành công';
            break;
        }
        default: {
            break;
        }
    }

    notification.success({
        message: 'Thành công',
        description: msg,
        className: 'notif-success',
        duration: 5,
    });
};

const alertUtils = {
    /** ==========================================
    * Hiển thị sweet alert
    * @param {string} type: loại cảnh báo
    * @param {string} msg: message
    * @param {boolean} isQuesion: có phải loại câu hỏi không
    ========================================== */
    show(type, msg, isQuestion) {
        if (isQuestion) {
            return MySwal.fire({
                icon: type,
                title: 'Thông báo',
                html: msg,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
        }
        return MySwal.fire({
            icon: type,
            title: 'Thông báo',
            html: msg,
        });
    },
    /**
     * Hiển thị câu hỏi
     * @param {object} opts
     * @param {string} opts.msg: nội dung hiển thị
     * @returns
     */
    showQuestion(opts) {
        const { msg } = opts;
        return MySwal.fire({
            icon: SWAL_CONST.TYPE.QUESION,
            title: 'Thông báo',
            html: msg,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy',
            showCancelButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
    },

    showWarning(msg) {
        return MySwal.fire({
            icon: SWAL_CONST.TYPE.WARNING,
            title: 'Cảnh báo',
            html: msg,
        });
    },

    showError(msg) {
        return MySwal.fire({
            icon: SWAL_CONST.TYPE.ERROR,
            title: 'Có lỗi xảy ra',
            html: msg,
        });
    },

    showTimer(type, msg, timer = 1000) {
        return MySwal.fire({
            icon: type,
            title: msg,
            timer,
            timerProgressBar: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                MySwal.hideLoading();
            },
        });
    },
    showTimerFunc({ type, title, msg, func, timer = 1000 }) {
        type ??= SWAL_CONST.TYPE.SUCCESS;
        title ??= 'Thông báo';

        let timerInterval;
        MySwal.fire({
            icon: type,
            title,
            html: msg,
            timer,
            didOpen: () => {
                timerInterval = setInterval(() => {
                    MySwal.getHtmlContainer().querySelector(
                        'strong'
                    ).textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
                func();
            },
        });
    },
    showLoading(msg) {
        return MySwal.fire({
            html: msg,
            timerProgressBar: true,
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading();
            },
        });
    },
    closeLoading() {
        MySwal.close();
    },
};

export default alertUtils;
