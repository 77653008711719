import { STATUS_PROCESS } from '@app/constants/index';

/* eslint-disable react/react-in-jsx-scope */
const getProcessName = (status, message = '') => {
    let xhtml = null;
    switch (status) {
        case STATUS_PROCESS.AWAITING: {
            xhtml = (
                <span className="label label-info">
                    <i className="icon-hour-glass2" /> Đang đợi xử lý...
                </span>
            );
            break;
        }
        case STATUS_PROCESS.PROCESSING: {
            xhtml = (
                <span className="label label-primary">
                    <i className="icon-spinner2 spinner" /> Đang xử lý...
                </span>
            );
            break;
        }
        case STATUS_PROCESS.DONE: {
            xhtml = (
                <span className="label label-success">
                    <i className="icon-checkmark4" /> Hoàn thành
                </span>
            );
            break;
        }
        case STATUS_PROCESS.ERROR: {
            xhtml = (
                <span className="label label-danger">
                    <i className="icon-bug2" /> Lỗi
                </span>
            );
            break;
        }

        case STATUS_PROCESS.WARNING: {
            xhtml = (
                <span className="label label-warning">
                    <i className="icon-notification2" /> {message}
                </span>
            );
            break;
        }

        default: {
            return null;
        }
    }
    return xhtml;
};

export { getProcessName };
