import AccountFunctionCategorySelect from '@app/components/_Share/Select/AccountFunctionCategorySelect/Dynamic';
import AccountRoleSelect from '@app/components/_Share/Select/AccountRoleSelect/Dynamic';
import React from 'react';

const AccountPrivilegeFilter = ({ onChangeRole, onChangeFc }) => (
    <div className="row">
        <div className="col-md-3 col-sm-8">
            <div className="form-group">
                <div className="input-group">
                    <span className="input-group-addon">Vai trò</span>
                    <AccountRoleSelect onChange={onChangeRole} />
                </div>
            </div>
        </div>
        <div className="col-md-3 col-sm-8">
            <div className="form-group">
                <div className="input-group">
                    <span className="input-group-addon">Nhóm tính năng</span>
                    <AccountFunctionCategorySelect
                        isAll
                        moduleCode={12}
                        onChange={onChangeFc}
                    />
                </div>
            </div>
        </div>
    </div>
);

export default AccountPrivilegeFilter;
