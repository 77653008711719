import {
    apiDeleteAccount,
    apiGetListAccount,
    apiResetPasswordAccount,
    apiSaveAccount,
} from '@app/apis/accounts/accountList';
import {
    ACTION_API_TYPES,
    CDN_IMAGE_URL,
    IMAGE_FOLDER_UPLOAD,
} from '@app/constants/';
import { STATUS_CODES } from '@app/constants/api';
import { showError, showSuccess } from '@app/utils/alert';
import { globalUploadImageToS3 } from '@app/utils/img.ultils';
import { callLoading } from '@app/utils/saga.utils';
import { call, put, takeLatest } from 'redux-saga/effects';
import { accountListActions } from './accountList.slice';

function* sgGetListAccount({ payload }) {
    yield callLoading(function* doRequest() {
        const { data, status } = yield call(apiGetListAccount, payload);

        if (status === STATUS_CODES.SUCCESS) {
            yield put(accountListActions.doGetListAccountSuccess(data.data));
        } else {
            showError(status, data);
        }
    });
}

function* sgSaveAccount({ payload }) {
    yield callLoading(function* () {
        const { dataPost, file } = payload;

        if (file) {
            const imageName = yield globalUploadImageToS3(
                [file],
                IMAGE_FOLDER_UPLOAD.USER
            );

            if (imageName) {
                dataPost.su_avatar = `${CDN_IMAGE_URL}/${IMAGE_FOLDER_UPLOAD.USER}/${imageName[0]}`;
            }
        }

        const { status, data } = yield call(apiSaveAccount, dataPost);

        if (status === STATUS_CODES.SUCCESS) {
            const isAdd = !dataPost.su_id;

            yield put(
                accountListActions.doSaveAccountSuccess({
                    data: data.data,
                    isAdd,
                })
            );

            showSuccess(ACTION_API_TYPES[isAdd ? 'ADD' : 'EDIT']);
        } else {
            showError(status, data);
        }
    });
}

function* sgDeleteAccount({ payload }) {
    yield callLoading(function* () {
        const { status } = yield call(apiDeleteAccount, payload);
        if (status === STATUS_CODES.NO_CONTENT) {
            yield put(accountListActions.doDeleteAccountSuccess(payload));
            showSuccess(ACTION_API_TYPES.DELETE);
        } else {
            showError(status, { msg: 'Xóa thất bại' });
        }
    });
}

function* sgResetPasswordAccount({ payload }) {
    yield callLoading(function* doRQ() {
        const { status } = yield call(apiResetPasswordAccount, payload);

        if (status === STATUS_CODES.SUCCESS) {
            showSuccess(ACTION_API_TYPES.EDIT);
        } else {
            showError(status, { msg: 'Không thiết lại lại được mật khẩu' });
        }
    });
}

function* AccountListSaga() {
    yield takeLatest(
        accountListActions.doGetListAccount.type,
        sgGetListAccount
    );
    yield takeLatest(accountListActions.doSaveAccount.type, sgSaveAccount);
    yield takeLatest(accountListActions.doDeleteAccount.type, sgDeleteAccount);
    yield takeLatest(
        accountListActions.doResetPasswordAccount.type,
        sgResetPasswordAccount
    );
}

export default AccountListSaga;
