import { createSlice } from '@reduxjs/toolkit';

const MODULE_NAME = 'agency';
const initialState = {
    listAgency: [],
    listSchOfAgency: [],
    listTransHistory: [],
    listConnHistory: [],

    listOrgScale: [],
    listOrgType: [],

    isShowListSchool: false,
    isShowTransHistory: false,
    isShowConnHistory: false,

    totalList: 0,
    totalSchOfAgency: 0,
    totalTransHistory: 0,
    totalConnHistory: 0,

    currentPage: 1,
    currentPageModalSchool: 1,
    currentPageModalTrans: 1,
    currentPageModalConn: 1,

    currentAgency: {},
    keyword: '',
};

const AgencySlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        doFetchDataFirst() {},
        doFetchDataFirstSuccess(state, { payload }) {
            const { dataScale, dataType } = payload;

            state.listOrgScale = dataScale;
            state.listOrgType = dataType;
        },

        doGetListAgency() {},
        doGetListAgencySuccess(state, { payload }) {
            const { listView, total } = payload;

            state.listAgency = listView;
            state.totalList = total;
        },

        doToggleListSchool(state, { payload }) {
            state.currentAgency = payload.data || {};
            state.isShowListSchool = payload.action === 'open';
        },
        doToggleTransHistory(state, { payload }) {
            state.currentAgency = payload.data || {};
            state.isShowTransHistory = payload.action === 'open';
        },
        doToggleConnHistory(state, { payload }) {
            state.currentAgency = payload.data || {};
            state.isShowConnHistory = payload.action === 'open';
        },

        doGetListSchOfAgency() {},
        doGetListSchOfAgencySuccess(state, { payload }) {
            const { schoolList, total } = payload;

            state.listSchOfAgency = schoolList;
            state.totalSchOfAgency = total;
        },

        doGetListTransHistory() {},
        doGetListTransHistorySuccess(state, { payload }) {
            const { data, total } = payload;

            state.listTransHistory = data;
            state.totalTransHistory = total;
        },

        doGetListConnHistory() {},
        doGetListConnHistorySuccess(state, { payload }) {
            const { data, listInfoOrg, total } = payload;

            const newList = data.map((item) => {
                const info = listInfoOrg.find(
                    (o) => o.o_id === item.ch_schoolid
                );

                return {
                    ...item,
                    ...info,
                };
            });

            state.listConnHistory = newList;
            state.totalConnHistory = total;
        },

        doChangePage(state, { payload }) {
            state.currentPage = payload;
        },
        doChangePageListSch(state, { payload }) {
            state.currentPageModalSchool = payload;
        },
        doChangePageListTrans(state, { payload }) {
            state.currentPageModalTrans = payload;
        },
        doChangePageListConn(state, { payload }) {
            state.currentPageModalConn = payload;
        },

        doChangeKeyword(state, { payload }) {
            state.keyword = payload;
        },
    },
});

/** ========== ACTIONS ========== */
export const agencyActions = AgencySlice.actions;

/** ========== SELECTOR ========== */
export const agencySelector = {
    listAgency: (state) => state[MODULE_NAME].listAgency,
    listSchOfAgency: (state) => state[MODULE_NAME].listSchOfAgency,
    listTransHistory: (state) => state[MODULE_NAME].listTransHistory,
    listConnHistory: (state) => state[MODULE_NAME].listConnHistory,

    listOrgScale: (state) => state[MODULE_NAME].listOrgScale,
    listOrgType: (state) => state[MODULE_NAME].listOrgType,

    isShowListSchool: (state) => state[MODULE_NAME].isShowListSchool,
    isShowTransHistory: (state) => state[MODULE_NAME].isShowTransHistory,
    isShowConnHistory: (state) => state[MODULE_NAME].isShowConnHistory,

    totalList: (state) => state[MODULE_NAME].totalList,
    totalSchOfAgency: (state) => state[MODULE_NAME].totalSchOfAgency,
    totalTransHistory: (state) => state[MODULE_NAME].totalTransHistory,
    totalConnHistory: (state) => state[MODULE_NAME].totalConnHistory,

    currentPage: (state) => state[MODULE_NAME].currentPage,
    currentPageModalSchool: (state) =>
        state[MODULE_NAME].currentPageModalSchool,
    currentPageModalTrans: (state) => state[MODULE_NAME].currentPageModalTrans,
    currentPageModalConn: (state) => state[MODULE_NAME].currentPageModalConn,

    currentAgency: (state) => state[MODULE_NAME].currentAgency,
    keyword: (state) => state[MODULE_NAME].keyword,
};

/** ========== REDUCER ========== */
const agencyReducer = AgencySlice.reducer;
export default agencyReducer;
