import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import othersSelector from '@app/redux/others/selector';
import { useHistory } from 'react-router-dom';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

export const useAuthButton = (buttonType) => {
    const history = useHistory();

    const [isAuth, setIsAuth] = useState(false);
    const listRoute = useSelector(othersSelector.routeList);

    useEffect(() => {
        if (buttonType) {
            if (history.location.pathname === '/') {
                setIsAuth(true);
            } else {
                const routeCheck = listRoute.find(
                    (item) =>
                        item.path.split('/')[1].toLowerCase() ===
                        history.location.pathname.split('/')[1].toLowerCase()
                );

                if (routeCheck) {
                    const { roles } = routeCheck;
                    if (roles && roles.length > 0) {
                        let isCheck = false;
                        if (roles[0] === 1) {
                            isCheck = true;
                        } else {
                            switch (buttonType) {
                                case BUTTON_ACTION_TYPE.SEARCH: {
                                    isCheck = roles[2] === 1;
                                    break;
                                }
                                case BUTTON_ACTION_TYPE.ADD: {
                                    isCheck = roles[3] === 1;
                                    break;
                                }
                                case BUTTON_ACTION_TYPE.PASSWORD:
                                case BUTTON_ACTION_TYPE.SAVE:
                                case BUTTON_ACTION_TYPE.EDIT: {
                                    isCheck = roles[4] === 1;
                                    break;
                                }

                                case BUTTON_ACTION_TYPE.DELETE: {
                                    isCheck = roles[5] === 1;
                                    break;
                                }
                                case BUTTON_ACTION_TYPE.PRINT:
                                case BUTTON_ACTION_TYPE.EXPORT_FILE: {
                                    isCheck = roles[6] === 1;
                                    break;
                                }

                                default: {
                                    break;
                                }
                            }
                        }
                        setIsAuth(isCheck);
                    }
                }
            }
        }
    }, [history.location.pathname, buttonType, listRoute]);

    return isAuth;
};
