/** =====================================
 * Danh sách hình thức thu tiền dạng CONST
 ====================================== */
export const COST_CALCULATED_TYPE = {
    THU_TIEN_TRON_GOI: 1,
    TIEN_THU_DAU_NAM: 2,
    TIEN_THU_THEO_THANG: 3,
    TIEN_THU_HANG_THANG: 4,
    TIEN_AN_TAT_CA_CAC_NGAY: 5,
    TIEN_AN_T2_T6: 6,
    TIEN_AN_T7_CN: 7,
    TIEN_HOAN_TRA_TAT_CA_CAC_NGAY: 8,
    TIEN_HOAN_TRA_T2_T6: 9,
    TIEN_HOAN_TRA_T7_CN: 10,
};

/** =====================================
 * Danh sách hình thức thu tiền
 ====================================== */
export const CostCalculatedTypeList = [
    {
        id: 1,
        name: 'Tiền thu trọn gói',
        note: 'Chỉ thu 1 lần trong năm',
    },
    {
        id: 2,
        name: 'Tiền thu đầu năm',
        note: 'Dựa vào ngày vào lớp',
    },
    {
        id: 3,
        name: 'Tiền thu theo tháng',
        note: 'Dựa vào tháng lập phiếu thu',
    },
    {
        id: 4,
        name: 'Tiền thu hằng tháng',
        note: 'Tháng nào cũng thu',
    },
    {
        id: 5,
        name: 'Tiền ăn tất cả các ngày',
        note: '',
    },
    {
        id: 6,
        name: 'Tiền ăn T2, T3, T4, T5, T6',
        note: '',
    },
    {
        id: 7,
        name: 'Tiền ăn T7, CN',
        note: '',
    },
    {
        id: 8,
        name: 'Tiền hoàn trả tất cả các ngày',
        note: '',
    },
    {
        id: 9,
        name: 'Tiền hoàn trả T2, T3, T4, T5, T6',
        note: '',
    },
    {
        id: 10,
        name: 'Tiền hoàn trả T7, CN',
        note: '',
    },
];

/** =====================================
 * Danh sách loại hình thu tiền
 ====================================== */
export const CostListRequireList = [
    {
        id: 1,
        name: 'Bắt buộc',
    },
    {
        id: 2,
        name: 'Thỏa thuận',
    },
];

/** =====================================
 * Danh sách cấu hình chi phí dạng CONST
 ====================================== */
export const RECEIPT_COST_SETTING = {
    /**
     * Tính tiền trong biên lai
     */
    SHOW_ON_INVOICES: 1,
    IS_REFUNDED_LEGAL: 2,
    SHOW_ON_REFUND: 3,
    NUMBER_OF_DAYS_IS_REFUNDED_LEGAL: 4,
    PAYMENT_FOR_MONTHS: 5,
    IS_REFUNDED_ILLEGAL: 6,
    NUMBER_OF_DAYS_IS_REFUNDED_ILLEGAL: 7,

    /**
     * Tính tiền trong hóa đơn
     */
    SHOW_ON_BILL: 8,
};

/** =====================================
 * Danh sách cấu hình chi phí
 ====================================== */
export const ReceiptCostSetting = [
    {
        id: 1,
        code: 'SHOW_ON_INVOICES',
        desc: 'Tính tiền trong biên lai',
    },
    {
        id: 2,
        code: 'IS_REFUNDED_LEGAL',
        desc: 'Trừ tiền khi nghỉ có phép',
    },
    {
        id: 3,
        code: 'SHOW_ON_REFUND',
        desc: 'Tính vào tiền thừa tháng trước',
    },
    {
        id: 4,
        code: 'NUMBER_OF_DAYS_IS_REFUNDED_LEGAL',
        desc: 'Số ngày nghỉ có phép được trừ tiền',
    },
    {
        id: 5,
        code: 'PAYMENT_FOR_MONTHS',
        desc: 'Tiền thu theo tháng',
    },
    {
        id: 6,
        code: 'IS_REFUNDED_ILLEGAL',
        desc: 'Trừ tiền khi nghỉ không phép',
    },
    {
        id: 7,
        code: 'NUMBER_OF_DAYS_IS_REFUNDED_ILLEGAL',
        desc: 'Số ngày nghỉ không phép được trừ tiền',
    },
    {
        id: 8,
        code: 'SHOW_ON_BILL',
        desc: 'Tính tiền trong hóa đơn',
    },
];

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const MonthList = [
    {
        id: 1,
        name: 1,
    },
    {
        id: 2,
        name: 2,
    },
    {
        id: 3,
        name: 3,
    },
    {
        id: 4,
        name: 4,
    },
    {
        id: 5,
        name: 5,
    },
    {
        id: 6,
        name: 6,
    },
    {
        id: 7,
        name: 7,
    },
    {
        id: 8,
        name: 8,
    },
    {
        id: 9,
        name: 9,
    },
    {
        id: 10,
        name: 10,
    },
    {
        id: 11,
        name: 11,
    },
    {
        id: 12,
        name: 12,
    },
];

/** =====================================
 * Danh sách lỗi
 ====================================== */

export const RECEIPT_ERR = {
    DA_LAP_PHIEU_THU_THANG_SAU: 'RECEIPT_ERR_1',
    CHUA_LAP_BANG_DIEM_DANH: 'RECEIPT_ERR_2',
    DA_DONG_THU_TIEN_TRON_GOI: 'RECEIPT_ERR_3',
    NGHI_CA_THANG: 'RECEIPT_ERR_4',
};

export const RECEIPT_TYPE_PAYMENT = {
    /**
     * Chưa thanh toán
     */
    NOT_PAID: 0,
    /**
     * Đã thanh toán, tạm ứng
     */
    DEPOSIT: 1,
    /**
     * Đã thanh toán, hoàn thành
     */
    DONE: 2,
    /**
     * Hủy
     */
    CANCEL: 3,
};

export const RECEIPT_VOUCHER_TYPE = {
    PHIEU_THU: 1,
    PHIEU_CHI: 2,
    PHIEU_LUONG: 3,
};

/*====================================
 * HÌNH THỨC THANH TOÁN CỦA PHIẾU THU
 ====================================*/

export const RECEIPT_VOUCHER_PAYMENT_TYPE = {
    ALL: -1,
    TIEN_MAT: 1,
    CHUYEN_KHOAN: 2,
};

export const ReceiptVoucherPaymentTypeList = [
    {
        id: RECEIPT_VOUCHER_PAYMENT_TYPE.TIEN_MAT,
        name: 'Tiền mặt',
    },
    {
        id: RECEIPT_VOUCHER_PAYMENT_TYPE.CHUYEN_KHOAN,
        name: 'Chuyển khoản',
    },
];

/*====================================
 * Đối tượng thanh toán
 ====================================*/

export const PAYMENT_OBJECT_CONST = {
    /**
     * Sinh viên/học sinh
     */
    STUDENT: 1,
    /**
     * Giáo viên
     */
    TEACHER: 2,
    /**
     * Phụ huynh
     */
    PARENT: 3,
    /**
     * Nhà cung cấp
     */
    SUPPLIER: 4,
    /**
     * Khác
     */
    OTHERS: -1,
};

export const PaymentObjectList = [
    {
        id: PAYMENT_OBJECT_CONST.STUDENT,
        name: 'Học sinh',
    },
    {
        id: PAYMENT_OBJECT_CONST.TEACHER,
        name: 'Giáo viên',
    },
    {
        id: PAYMENT_OBJECT_CONST.PARENT,
        name: 'Phụ huynh học sinh',
    },
    {
        id: PAYMENT_OBJECT_CONST.SUPPLIER,
        name: 'Nhà cung cấp',
    },
    {
        id: PAYMENT_OBJECT_CONST.OTHERS,
        name: 'Khác',
    },
];

/**
 * Các loại phiếu chi
 */
export const PAYMENT_VOUCHER_TYPE = {
    /**
     * Chi chợ
     */
    MARKET: 1,

    /**
     * Chi kho
     */
    STORAGE: 2,

    /**
     * Chi dịch vụ
     */
    SERVICE: 3,
};

export const PaymentVoucherTypeListConst = [
    {
        id: PAYMENT_VOUCHER_TYPE.MARKET,
        name: 'Chi chợ',
    },
    {
        id: PAYMENT_VOUCHER_TYPE.STORAGE,
        name: 'Chi kho',
    },
    {
        id: PAYMENT_VOUCHER_TYPE.SERVICE,
        name: 'Chi dịch vụ',
    },
];
