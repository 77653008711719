const sortByVietnamName = (list, fieldSort) => {
    if (list && list.length > 0) {
        list = list.sort((item1, item2) => {
            const name1 = (item1[fieldSort] || '').toUpperCase();
            const name2 = (item2[fieldSort] || '').toUpperCase();
            return name1.localeCompare(name2);
        });
        list = list.map((item, index) => ({
            ...item,
            sortOrder: index + 1,
        }));
    }
    return list;
};

export { sortByVietnamName };
