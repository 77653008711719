import * as types from './copymenu.constant';
import { MenuListModel } from '@app/models/foodkid/foodkid.model';

const initState = {
    menuList: [],
    kidGroupList: [],
    rowSelected: [],

    isImport: false,

    idToSchool: 0,
};

const CopyMenuReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case types.GET_LIST_MENU_BY_SCHOOL_SUCCESS: {
            const { menu, menudishs, menuServices } = payload.data;

            const renderMenuDish = (list, _type) => {
                let result = '';

                if (_.isEmpty(list)) return result;

                result = [
                    ...list
                        .filter((o) => o.md_meal === _type)
                        .map((o) => o.d_name.trim()),
                ].join(' | ');

                return result;
            };

            const newList = menu.map((mn) => {
                const _dish = menudishs.filter((o) => o.m_id === mn.m_id);

                const _service = menuServices.find((o) => o.m_id === mn.m_id);

                return new MenuListModel({
                    ...mn,
                    m_meal_breakfast: renderMenuDish(_dish, 1),
                    m_meal_lunch: renderMenuDish(_dish, 2),
                    m_meal_snack: renderMenuDish(_dish, 3),
                    m_meal_dinner: renderMenuDish(_dish, 4),
                    m_quantityafter: '',
                    m_service: _service ? _service.mms_price : 0,
                    is_update: false,
                });
            });
            return {
                ...state,
                menuList: newList,
                // data.map(
                //     (o) => new MenuListModel({ ...o, kg_id: -1 })
                // ),
            };
        }

        case types.GET_KIDGROUP_SUCCESS: {
            const { data } = payload;
            return {
                ...state,
                kidGroupList: data,
            };
        }

        case types.UPDATE_MENU_NEW: {
            return {
                ...state,
                menuList: payload.data,
            };
        }
        case types.SAVE_MENU_SAMPLE_SUCCESS: {
            const { data } = payload;

            const newList = state.menuList.map((o) => {
                const _check = data.find((x) => x.m_id === o.m_id);

                if (_check) {
                    o = {
                        ...o,
                        status: _check.status,
                    };
                }

                return o;
            });

            return {
                ...state,
                menuList: newList,
            };
        }
        case types.RESET: {
            return {
                ...state,
                ...initState,
            };
        }
        case types.CHANGE_SCHOOL_COPY: {
            return {
                ...state,
                idToSchool: payload.data,
            };
        }
        case types.CHANGE_SELECTED_ROW: {
            return {
                ...state,
                rowSelected: payload.data,
            };
        }

        case types.OPEN_MODAL_IMPORT: {
            return { ...state, isImport: true };
        }
        case types.CLOSE_MODAL_IMPORT: {
            return { ...state, isImport: false };
        }

        case types.IMPORT_MENU_FROM_FILE_SUCCESS: {
            return { ...state, isImport: false };
        }

        default:
            return { ...state };
    }
};

export default CopyMenuReducer;
