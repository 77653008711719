import React from 'react';
import DynamicCostSelect from './Dynamic';

const DynamicCostSelectField = ({
    input,
    onChange,
    label,
    disabled,
    oId,
    type,
}) => {
    const { value: inputValue, onChange: inputOnChange } = input;
    const handleChange = (value) => {
        inputOnChange(value);
        if (onChange) {
            onChange(value);
        }
    };
    return (
        <DynamicCostSelect
            defaultValue={inputValue}
            label={label}
            onChange={(value) => handleChange(value)}
            disabled={disabled}
            oId={oId}
            type={type}
        />
    );
};

DynamicCostSelectField.propTypes = {};

export default DynamicCostSelectField;
