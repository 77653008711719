import {
    PaymentVoucherTypeListConst,
    PAYMENT_VOUCHER_TYPE,
} from '@app/constants/receiptCost.CONST';
import * as _serviceFKModuleApis from '@app/apis/foodkid/serviceModule';
import * as _paymentVoucherApis from '@app/apis/income/paymentVoucher.api';
import { swalClose, swalLoading } from '@app/utils/alert';

const _paymentVoucherHelpers = {
    /**
     * Lấy tổng tiền phần lập phiếu thu theo ngày + loại phiếu chi
     * @param {string} oId: id tổ chức
     * @param {Object} dateRange: Từ ngày đến ngày tính tổng tiền
     * @param {Moment} dateRange.startDate: Từ ngày
     * @param {Moment} dateRange.endDate: Đến ngày
     * @param {number} paymentVoucherType: Loại phiếu chi
     * @param {number} menuServiceId: Id dịch vụ dành cho loại phiếu chi dịch vụ
     * @returns Tổng tiền theo ngày
     */
    getTotalByDate: async (
        oId,
        dateRange,
        paymentVoucherType,
        menuServiceId
    ) => {
        swalLoading('Đang tính tổng tiền ...');
        let total = 0;
        try {
            const intStartDate = dateRange.startDate.unix();
            const intEndDate = dateRange.endDate.unix();

            switch (paymentVoucherType) {
                case PAYMENT_VOUCHER_TYPE.SERVICE:
                case PAYMENT_VOUCHER_TYPE.MARKET: {
                    if (
                        paymentVoucherType === PAYMENT_VOUCHER_TYPE.SERVICE &&
                        menuServiceId === -1
                    ) {
                        return;
                    }
                    const { status, data } =
                        await _serviceFKModuleApis.apiGetTotalMenuMoneyByDate({
                            oId,
                            startDate: intStartDate,
                            endDate: intEndDate,
                            paymentVoucherType,
                            menuServiceId,
                            orgId: oId,
                        });

                    if (status !== 200) return;

                    total = data.data;
                    break;
                }

                case PAYMENT_VOUCHER_TYPE.STORAGE: {
                    const { status, data } =
                        await _serviceFKModuleApis.apiGetTotalFoodReceiptMoneyByDate(
                            {
                                oId,
                                startDate: intStartDate,
                                endDate: intEndDate,
                                orgId: oId,
                            }
                        );
                    if (status !== 200) return;
                    total = data.data;
                    break;
                }

                default: {
                    break;
                }
            }
        } finally {
            swalClose();
        }

        return total;
    },

    getPaymentVoucherTypeName: (paymentVoucherTypeId) => {
        const paymentVoucher = PaymentVoucherTypeListConst.find(
            (o) => o.id === paymentVoucherTypeId
        );

        return paymentVoucher?.name;
    },

    getMaxDateTo: async (oId, paymentVouCherType) => {
        if (paymentVouCherType <= 0) return 0;
        const dataPost = {
            orgId: oId,
            oId,
            paymentVouCherType,
        };
        const { status, data } = await _paymentVoucherApis.apiGetMaxDateTo(
            dataPost
        );
        if (status !== 200) return 0;
        return data.data;
    },
};

export default _paymentVoucherHelpers;
