/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Fragment } from 'react';
import PageHeader from '../Layout/PageHeader/PageHeader';
import InventoryFilter from './InventoryFilter';
import InventoryList from './InventoryList';
import InventoryModal from './InventoryModal';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@app/redux/inventory/inventory.action';
import InventorySelector from '@app/redux/inventory/inventory.selector';
import WarehouseSelector from '@app/redux/warehouse/warehouse.selector';
import InventoryItem from './InventoryItem';
import NoData from '@app/components/Layout/Shares/NoData';
import InventoryModalOut from './InventoryModalOut';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { getDeleteContent } from '@app/utils/alert';
import { SWEET_ALERT_TYPES } from '@app/constants/';

const MySwal = withReactContent(Swal);

const Warehouse = () => {
    const inventoryByMenuList = useSelector(
        InventorySelector.inventoryByMenuList
    );
    const warehouseList = useSelector(WarehouseSelector.warehouseList);
    const isCheckAll = useSelector(InventorySelector.isCheckAll);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(actions.closeModalIn());
    };

    const closeModalOut = () => {
        dispatch(actions.closeModalOut());
    };

    const deleteInventory = (item) => {
        MySwal.fire(
            getDeleteContent(
                item.map((o) => o.fr_code).join(','),
                SWEET_ALERT_TYPES.QUESTION
            )
        ).then((res) => {
            if (res.value) {
                dispatch(actions.deleteInventory(item.map((o) => o.fr_id)));
            }
        });
    };
    const onCheck = (s) => {
        dispatch(actions.checkFoodReceiptIn(s));
    };
    const onCheckAll = () => {
        dispatch(actions.checkAll());
    };
    const showData = (list) => {
        let xhtml = null;
        if (list.length > 0) {
            xhtml = list.map((item) => (
                <InventoryItem
                    key={item.fr_datefounded}
                    item={item}
                    warehouseList={warehouseList}
                    deleteItem={() => {
                        deleteInventory([item]);
                    }}
                    onCheck={() => onCheck(item.fr_datefounded)}
                />
            ));
        } else {
            xhtml = <NoData colspan={10} />;
        }
        return xhtml;
    };

    const showIndexTotal = () => {
        const listIndexTotal = inventoryByMenuList.map(
            (item) => item.fr_totalimport
        );
        let total = 0;
        if (listIndexTotal.length > 0) {
            total = listIndexTotal.reduce((a, b) => a + b);
        }
        return total;
    };

    const showIndexExportTotal = () => {
        const listIndexTotal = inventoryByMenuList.map(
            (item) => item.fr_totalexport
        );
        let total = 0;
        if (listIndexTotal.length > 0) {
            total = listIndexTotal.reduce((a, b) => a + b);
        }
        return total;
    };

    const onSetStatus = (listItem, statusValue) => {
        const _listId = listItem.map((o) => o.m_datefounded);
        setList((data) =>
            data.map((o) => {
                if (_.includes(_listId, o.m_datefounded)) {
                    o = {
                        ...o,
                        status: statusValue,
                    };
                    if (statusValue === STATUS_PROCESS.DONE) {
                        o = {
                            ...o,
                            // isCheck: false,
                        };
                    }
                }
                return o;
            })
        );
    };

    return (
        <Fragment>
            <PageHeader pageTitle="TẠO PHIẾU NHẬP XUẤT KHO TỰ ĐỘNG" />
            <div className="content">
                {/* Filter */}
                <InventoryFilter
                    onSetStatus={(listItem, statusValue) =>
                        onSetStatus(listItem, statusValue)
                    }
                />
                {/* /Filter */}

                <InventoryList
                    onCheckAll={() => onCheckAll()}
                    showIndexTotal={() => showIndexTotal()}
                    showIndexExportTotal={() => showIndexExportTotal()}
                    isCheckAll={isCheckAll}
                >
                    {showData(inventoryByMenuList)}
                </InventoryList>

                <InventoryModal closeModal={() => closeModal()} />
                <InventoryModalOut closeModal={() => closeModalOut()} />
            </div>
        </Fragment>
    );
};

export default Warehouse;
