import { getCurrentUser } from '../../utils/utils';
import { LOCATION_TYPES } from '../../constants';
import { v4 as uuidv4 } from 'uuid';

const getCodeOnly = (code) => {
    let strRes = '';
    if (code) {
        const arrCode = code.split('.');
        if (arrCode.length > 0) {
            strRes = arrCode[arrCode.length - 1];
        }
    }

    return strRes;
};

export class LocationModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.id = data.id || 0;
        this.l_id = data.l_id || '';
        this.u_id = data.u_id || getCurrentUser().u_id;
        this.l_parentid = data.l_parentid || '';
        this.l_code = data.l_code || '';
        this.l_name = data.l_name || '';
        this.l_english_name = data.l_english_name || '';
        this.l_sortorder = data.l_sortorder || 1;
        this.l_type = data.l_type || LOCATION_TYPES.COUNTRY;
        this.l_status = data.l_status !== undefined ? data.l_status : true;
        this.l_parentCode = data.l_parentCode || '';
        this.l_notparentcode = getCodeOnly(data.l_id);
        this.isChange = uuidv4();
    }
}
