import React, { Fragment } from 'react';
import { displayDateByIntV1, priceType } from '@app/utils/utils';
import globalUtils from '@app/utils/global.utils';

const InventoryItem = (props) => {
    const { item, warehouseList, onCheck } = props;
    const warehouse = warehouseList.find((o) => o.w_id === item.w_id);
    return (
        <Fragment>
            <tr>
                <td className="text-center">
                    <div className="checker">
                        <span
                            className={item.isCheck === true ? 'checked' : ''}
                        >
                            <input
                                type="checkbox"
                                className="styled"
                                onChange={() => onCheck()}
                            />
                        </span>
                    </div>
                </td>
                <td className="text-center">
                    {displayDateByIntV1(item.fr_datefounded)}
                </td>
                <td className="text-center">{item.fr_code}</td>
                <td className="text-center">
                    {warehouse !== undefined ? warehouse.w_name : ''}
                </td>
                {/* <td className="text-right">
                    {item.fr_totalimport > 0
                        ? priceType(item.fr_totalimport)
                        : ''}
                </td> */}
                <td className="text-right">
                    {item.fr_totalexport > 0
                        ? priceType(item.fr_totalexport)
                        : ''}
                </td>
                <td>
                    <input
                        value={item.fr_description}
                        className="input-grid"
                        style={{ width: '100%' }}
                        // onChange={(s) =>
                        // this.props.onChangeDescription(
                        //     s,
                        //     item.fr_id
                        // )
                        // }
                        disabled={item.fr_status === 2}
                    />
                </td>
                <td>
                    <input
                        value={item.fr_creator}
                        className="input-grid"
                        style={{ width: '100%' }}
                        // onChange={(s) =>
                        // this.props.onChangeCreator(s, item.fr_id)
                        // }
                        disabled={item.fr_status === 2}
                    />
                </td>
                <td className="text-center">
                    {/* <span
                        className={
                            item.fr_status === 2 ? 'label label-danger' : null
                        }
                    >
                        {item.fr_status === 2 ? 'ĐÃ XUẤT KHO' : ''}
                    </span> */}
                    {globalUtils.getProcessName(item.status)}
                </td>
                {/* <td className="text-center">
                    <div className="btn-table-group">
                        {item.fr_status !== 2 ? (
                            <ButtonTableAction
                                buttonType={BUTTON_ACTION_TYPE.DELETE}
                                tootTipTitle="Xóa"
                                onClick={() => deleteItem()}
                            />
                        ) : null}
                    </div>
                </td> */}
            </tr>
        </Fragment>
    );
};

export default InventoryItem;
