import axiosService from '../../services/axiosService';
import {
    API_PRODUCT_ENDPOINT,
    API_SC_FOODKID_ENPOINT,
    API_INVENTORY_ENDPOINT,
    API_SC_GATEWAY_ENDPOINT,
} from '../../constants/api';
import { ApiGatewayDataPost } from '@app/models/common/apiGatewayDataPost.model';

const BASE_URL = `${API_SC_FOODKID_ENPOINT}/v1`;
const BASE_URL_INVENTORY = `${API_INVENTORY_ENDPOINT}/v1`;
const BASE_URL_PRODUCT = `${API_PRODUCT_ENDPOINT}/v1/ScProduct`;

const LIST_URL = {
    UPDATE_NEW_PRICE: `${BASE_URL}/FoodkidMenuAccountent/UpdateNewPrice`,
    UPDATE_PRICE_SERVICE: `${BASE_URL}/FoodkidMenuAccountent/UpdatePriceService`,
    UPDATE_QUANTITY: `${BASE_URL}/FoodkidMenuAccountent/UpdateQuantity`,
    ROUDING_REAL_BUY: `${BASE_URL}/FoodkidMenuAccountent/RoudingRealBuy`,

    FOODKID_MENU_SAMPLE: `${BASE_URL}/FoodkidMenuAccountentBySchool`,
    FOODKID_SAVE_MENU_SAMPLE: `${BASE_URL}/SaveFoodkidMenu/CopyMenuAccountent`,
    FOODKID_SAVE_MENU: `${BASE_URL}/SaveFoodkidMenu/SaveMenuAccountent`,
    FOODKID_IMPORT_MENU: `${BASE_URL}/ScFoodkid/Service/ImportMenuFromFile`,

    /** ======== GET LIST ======== */
    PRODUCT_CALCULATION_UNIT: `${BASE_URL_PRODUCT}/ProductCalculationUnit`,
    PRODUCT_SUPPLIER: `${BASE_URL_PRODUCT}/ProductSupplierAccountent`,
    PRODUCT_PACKAGE: `${BASE_URL_PRODUCT}/ProductPackaging`,
    PRODUCT_FOOD: `${BASE_URL_PRODUCT}/ProductFood`,

    FOODKID_DISH: `${BASE_URL}/FoodkidKidDishAccountent`,
    FOODKID_AGE_GROUP: `${BASE_URL}/FoodkidAgeGroup`,
    FOODKID_KID_GROUP: `${BASE_URL}/FoodkidKidGroupAccountent`,
    FOODKID_SCHOOL_FOOD: `${BASE_URL}/FoodkidSchoolFoodBySchoolByUser`,
    FOODKID_MEAL_SERVICE: `${BASE_URL}/GetAccountentFoodkidMealService`,

    GENERAL_FOODKIDS: `${BASE_URL}/FoodkidMenuGeneralByDateAndKidGroup`,
    GENERAL_SETUP_MARKET: `${BASE_URL}/FoodkidSchoolFood/SetupMarketCashReport`,
    GENERAL_RECEIPT: `${BASE_URL_INVENTORY}/FoodReceipt/ReportSearch`,
    GENERAL_RECEIPT_TOTAL: `${BASE_URL_INVENTORY}/FoodReceiptTotal/ReportSearch`,
    GENERAL_RECEIPT_OUT: `${BASE_URL_INVENTORY}/FoodReceiptOut/AccountentSearch`,
    GENERAL_RECEIPT_IN: `${BASE_URL_INVENTORY}/FoodReceiptIn/AccountentSearch`,

    TRANSIT_STATION: `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/TransitStation`,
};

/** ========== API MENU  ========== */
export const apiSaveMenu = (data) =>
    axiosService.post(LIST_URL.FOODKID_SAVE_MENU, data);

// export const apiGetListTotalGeneralReceipt = (data) =>
//     axiosService.post(LIST_URL.GENERAL_RECEIPT_TOTAL, data);

// export const apiGetListGeneralReceipt = (data) =>
//     axiosService.post(LIST_URL.GENERAL_RECEIPT, data);

// export const apiGetSetupMarketReport = (o_id, datefounded) =>
//     axiosService.get(`${LIST_URL.GENERAL_SETUP_MARKET}/${datefounded}/${o_id}`);

export const apiGetSupplier = (oId) =>
    axiosService.get(`${LIST_URL.PRODUCT_SUPPLIER}/${oId}`);

export const apiGetUnits = () =>
    axiosService.get(LIST_URL.PRODUCT_CALCULATION_UNIT);

export const apiGetPackaging = () => axiosService.get(LIST_URL.PRODUCT_PACKAGE);

export const apiGetProFood = () =>
    axiosService.get(`${LIST_URL.PRODUCT_FOOD}/GetAll`);

/** Danh sách các API liên quan đến foodkid */
const _foodkidApis = {
    /**
     * Lấy thông tin sổ tính tiền chợ
     * @param {Object} dataPost : Thông tin filter
     * @param {number} dataPost.dateFound : Ngày lập sổ tính tiền chợ ;
     * @param {string} dataPost.oId : Mã trường ;
     */
    getMarketCashBook(dataPost) {
        const { oId, dateFound } = dataPost;

        const _dataPost = new ApiGatewayDataPost({
            service: 'FoodKid',
            controller: 'MarketCashBook',
            action: 'GetInfo',
            dataPost: {
                orgId: oId,
                dateFound,
            },
        });

        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    },

    /**
     * Lấy danh sách phiếu nhập kho
     */
    getListImportVoucher(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Inventory',
            controller: 'ToolScImportVoucher',
            action: 'GetList',
            dataPost: {
                orgId: dataPost.o_id,
                ...dataPost,
            },
        });

        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    },

    /**
     * Lấy danh sách phiếu xuất kho
     */
    getListExportVoucher(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Inventory',
            controller: 'ToolScExportVoucher',
            action: 'GetList',
            dataPost: {
                orgId: dataPost.o_id,
                ...dataPost,
            },
        });
        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    },

    /** Lấy danh sách thẻ kho */
    getListWarehouseCard(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Inventory',
            controller: 'ToolScWarehouseCard',
            action: 'GetList',
            dataPost: {
                orgId: dataPost.o_id,
                ...dataPost,
            },
        });

        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
    },

    /** Lấy tổng hợp thẻ kho */
    getSummaryWarehouseCard(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Inventory',
            controller: 'ToolScWarehouseCard',
            action: 'GetSummary',
            dataPost: {
                orgId: dataPost.o_id,
                ...dataPost,
            },
        });

        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
        //return axiosService.post(LIST_URL.GENERAL_RECEIPT_TOTAL, dataPost);
    },
};

export default _foodkidApis;
