import { openDB } from 'idb';
import { DB_CACHE_CONST } from '../../constants/dbCacheConst';
import * as taffydb from 'taffydb';

export class BaseRepository {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.db = data.db || DB_CACHE_CONST.STORES.RESOURCES;
        this.table = data.table || DB_CACHE_CONST.TABlES.GLOBAL_LOCATION;
        this.taffydb = taffydb;
    }

    _baseGetData = async () => {
        let data = [];
        try {
            const dbPromise = await openDB(
                DB_CACHE_CONST.DB_NAME,
                DB_CACHE_CONST.DB_VERSION
            );
            data = await dbPromise.get(this.db, this.table);
            // eslint-disable-next-line no-empty
        } catch {}
        return data;
    };

    initData = async () => {
        let data = null;
        const dataIndexDB = await this._baseGetData();
        if (dataIndexDB) {
            data = dataIndexDB.data;
        }
        return data;
    };

    get = async (objData) => {
        const data = await this.initData();
        const db = taffydb.taffy(data);
        return db(objData).first();
    };

    getAll = async () => {
        const data = await this.initData();
        const db = taffydb.taffy(data);
        return db().get();
    };

    getMany = async (objData) => {
        const data = await this.initData();
        const db = taffydb.taffy(data);
        return db(objData).get();
    };

    /**
     * update đối tượng vào IndexDB
     * @param {Object} itemUpdate : đối tượng cần cập nhật
     * @param {string} idField: tên id đối tượng cần cập nhật. vd: l_id ...
     */
    updateDB = async (itemUpdate, idField) => {
        try {
            const dbPromise = await openDB(
                DB_CACHE_CONST.DB_NAME,
                DB_CACHE_CONST.DB_VERSION
            );
            const dataIndexDB = await dbPromise.get(this.db, this.table);
            if (dataIndexDB) {
                let data = dataIndexDB.data;
                const check = data.find(
                    (item) => item[idField] === itemUpdate[idField]
                );

                if (check) {
                    data = data.map((item) => {
                        if (item[idField] === itemUpdate[idField]) {
                            item = { ...item, ...itemUpdate };
                        }
                        return item;
                    });
                } else {
                    data = [...data, itemUpdate];
                }

                if (data.length > 0) {
                    const dataUpdate = {
                        ...dataIndexDB,
                        data,
                    };
                    await dbPromise.put(
                        DB_CACHE_CONST.STORES.RESOURCES,
                        dataUpdate
                    );
                }
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    };

    /**
     * Xóa 1 đối tượng trong IndexDB
     * @param {} id: giá trị của id
     * @param {string} idField: tên id của đối tượng. vd: l_id ...
     */
    removeDB = async (id, idField) => {
        try {
            const dbPromise = await openDB(
                DB_CACHE_CONST.DB_NAME,
                DB_CACHE_CONST.DB_VERSION
            );
            const dataIndexDB = await dbPromise.get(this.db, this.table);
            if (dataIndexDB) {
                let data = dataIndexDB.data;
                data = data.filter((item) => item[idField] !== id);

                const dataUpdate = {
                    ...dataIndexDB,
                    data,
                };
                await dbPromise.put(
                    DB_CACHE_CONST.STORES.RESOURCES,
                    dataUpdate
                );
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    };
}
