import React, { Fragment } from 'react';
import './_index.scss';
import { useSelector } from 'react-redux';
import OthersSelector from '../../../redux/others/selector';

const SCLoading = () => {
    const isLoading = useSelector(OthersSelector.isLoading);

    return (
        <Fragment>
            {isLoading ? (
                <div className="bg-spinner">
                    <div className="loadingio-spinner-double-ring-j8ipsq9bil">
                        <div className="ldio-hpnteioc1h">
                            <div />
                            <div />
                            <div>
                                <div />
                            </div>
                            <div>
                                <div />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

export default SCLoading;
