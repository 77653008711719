import { SchoolInfoModel } from '@app/models/school/schoolInfo.model';
import { createSlice } from '@reduxjs/toolkit';
import routeAuth from '../../routeAuth';

const initialState = {
    isLoading: false,
    organizationInfo: {},
    schoolInfo: new SchoolInfoModel(),
    schoolProperty: [],
    locationInfo: {},
    units: [],
    ageGroup: [],
    kidGroup: [],
    mealService: [],
    supplier: [],
    dish: [],
    inventories: [],
    packaging: [],
    routeList: [],
    schoolFood: [],
    invoiceForm: [],
    proFood: [],
};

const othersSlice = createSlice({
    name: 'others',
    initialState,
    reducers: {
        changePageLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        getOrganizationInfoSuccess: (state, { payload }) => {
            state.organizationInfo = payload;
        },
        getLocationInfoSuccess: (state, { payload }) => {
            state.locationInfo = payload;
        },
        getSchoolInfoSuccess: (state, { payload }) => {
            let schoolInfo = {};
            if (payload.length > 0) {
                payload.forEach((elm) => {
                    schoolInfo = {
                        ...schoolInfo,
                        o_id: elm.o_id,
                        [`${elm.ps_code}`]: elm.ps_value,
                    };
                });
            }
            state.schoolInfo = new SchoolInfoModel(schoolInfo);
        },
        getUnitsSuccess: (state, { payload }) => {
            state.units = payload;
        },
        getAgeGroupSuccess: (state, { payload }) => {
            state.ageGroup = payload;
        },
        getKidGroupSuccess: (state, { payload }) => {
            state.kidGroup = payload;
        },
        getMealServiceSuccess: (state, { payload }) => {
            state.mealService = payload;
        },
        getSupplierSuccess: (state, { payload }) => {
            state.supplier = payload;
        },
        getDishSuccess: (state, { payload }) => {
            state.dish = payload;
        },
        getInventorySuccess: (state, { payload }) => {
            state.inventories = payload;
        },
        getPackagingSuccess: (state, { payload }) => {
            state.packaging = payload;
        },
        doGetRouteList: (state) => {
            state.routeList = routeAuth.getRoutes();
        },
        doGetSchoolFoodSuccess: (state, { payload }) => {
            state.schoolFood = payload;
        },
        doGetSchoolPropertySuccess: (state, { payload }) => {
            state.schoolProperty = payload.reduce((rsList, item) => {
                rsList = { ...rsList, [item.p_code]: item };

                return rsList;
            }, {});
        },
        doGetSchoolInvoiceFormSuccess: (state, { payload }) => {
            state.invoiceForm = payload;
        },
        doGetProFoodSuccess: (state, { payload }) => {
            state.proFood = payload;
        },
    },
});

export const {
    changePageLoading,
    getOrganizationInfoSuccess,
    getSchoolInfoSuccess,
    getLocationInfoSuccess,
    getUnitsSuccess,
    getAgeGroupSuccess,
    getKidGroupSuccess,
    getMealServiceSuccess,
    getSupplierSuccess,
    getDishSuccess,
    getInventorySuccess,
    getPackagingSuccess,
    doGetRouteList,
    doGetSchoolFoodSuccess,
    doGetSchoolPropertySuccess,
    doGetSchoolInvoiceFormSuccess,
    doGetProFoodSuccess,
} = othersSlice.actions;

export default othersSlice.reducer;
