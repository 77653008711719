import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { doCheckAll, doCheckItem } from '@app/redux/attendance/slice';
import AttendanceSelector from '@app/redux/attendance/selector';

const propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({})),
};

const List = ({ dataSource }) => {
    const isCheckAll = useSelector(AttendanceSelector.isCheckAll);

    const dispatch = useDispatch();

    const onCheckAll = (s) => {
        dispatch(doCheckAll(s.target.checked));
    };
    const onCheckItem = (dataItem, s) => {
        const checkValue = s.target.checked;
        dispatch(doCheckItem({ dataItem, checkValue }));
    };

    const getProcessName = (dataItem) => {
        let xhtml = <></>;
        switch (dataItem.process) {
            case 'processing': {
                xhtml = (
                    <span className="label label-primary">
                        <i className="icon-hour-glass2" /> Đang tạo...
                    </span>
                );
                break;
            }
            case 'done': {
                xhtml = (
                    <span className="label label-success">
                        <i className="icon-checkmark4" /> Hoàn thành
                    </span>
                );
                break;
            }
            case 'error': {
                xhtml = (
                    <>
                        <span className="label label-danger">
                            <i className="icon-bug2" /> Không tạo được
                        </span>
                        <br />
                        <small>{dataItem.msg}</small>
                    </>
                );
                break;
            }
            case 'delete_processing': {
                xhtml = (
                    <span className="label label-primary">
                        <i className="icon-hour-glass2" /> Đang xóa...
                    </span>
                );
                break;
            }
            case 'delete_done': {
                xhtml = (
                    <span className="label label-success">
                        <i className="icon-checkmark4" /> Xóa xong
                    </span>
                );
                break;
            }
            case 'delete_error': {
                xhtml = (
                    <>
                        <span className="label label-danger">
                            <i className="icon-bug2" /> Không xóa được
                        </span>
                        <br />
                        <small>{dataItem.msg}</small>
                    </>
                );
                break;
            }
            default: {
                return <></>;
            }
        }
        return xhtml;
    };

    const columns = [
        {
            title: 'STT',
            width: 80,
            align: 'center',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Tên lớp',
            dataIndex: 'c_name',
            key: 'c_name',
        },
        {
            title: 'Độ tuổi',
            dataIndex: 'gl_name',
            key: 'gl_name',
        },
        {
            title: () => (
                <Checkbox
                    checked={isCheckAll}
                    onChange={(s) => onCheckAll(s)}
                />
            ),
            width: 120,
            align: 'center',
            render: (dataItem) => (
                <Checkbox
                    checked={dataItem.isCheck}
                    onChange={(s) => onCheckItem(dataItem, s)}
                    disabled
                />
            ),
        },
        {
            title: 'Tình trạng',
            width: 210,
            align: 'center',
            render: (dataItem) => getProcessName(dataItem),
        },
    ];
    return (
        <div className="panel panel-white">
            <Table
                columns={columns}
                dataSource={dataSource}
                rowKey="c_id"
                bordered
                pagination={false}
            />
        </div>
    );
};

List.propTypes = propTypes;

export default memo(List);
