import myIcon from '@app/assets/images/icons/sys/school.svg';
import React from 'react';
import { ADMIN_FUNCTIONS, SC_MODULES } from '@app/constants/sc.modules';
import Organization from '@app/components/Organizations';

const organizationRoutes = [
    {
        name: 'DANH SÁCH ĐƠN VỊ',
        icon: myIcon,
        isMenu: true,
        isRoute: true,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        link: '/organization',
        path: '/organization',
        main: () => <Organization />,
        functionId: ADMIN_FUNCTIONS.ORGANIZATIONS,
    },
];

export default organizationRoutes;
