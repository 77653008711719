export class FoodReceiptModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.w_id = data.w_id || 0;
        this.fr_id = data.fr_id || 0;
        this.fr_code = data.fr_code || '';
        this.fr_type = data.fr_type || 1;
        this.fr_datefounded = data.fr_datefounded || 0;
        this.fr_creator = data.fr_creator || '';
        this.fr_deliverorreceiver = data.fr_deliverorreceiver || '';
        this.fr_address = data.fr_address || '';
        this.fr_description = data.fr_description || '';
        this.fr_totalimport = data.fr_totalimport || 0;
        this.fr_totalexport = data.fr_totalexport || 0;
        this.fr_status = data.fr_status || 1;
        this.status = data.status || 0;
        this.isCheck = data.isCheck || false;
        this.dateweek = data.dateweek || 0;
        this.schoolFoodList = data.schoolFoodList || [];
        this.foodList = data.foodList || [];
    }
}
