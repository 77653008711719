import { getCurrentUser } from '@app/utils/utils';

export class AccountRoleModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.sr_id = data.sr_id || 0;
        this.su_id = data.su_id || 0;
        this.o_id = data.o_id || getCurrentUser().o_id;
        this.sr_name = data.sr_name || '';
        this.sr_sortorder = data.sr_sortorder || 1;
        this.sr_status =
            data.sr_status || data.sr_status === 0 ? data.sr_status : 1;
    }
}
