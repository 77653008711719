import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import RangeDateService from './_service';

const propTypes = {
    // Giá trị giới hạn mặc định
    defaultRange: PropTypes.number,

    // Giá trị giới hạn
    limitRange: PropTypes.number,

    // Loại giới hạn (days, week, month)
    rangeType: PropTypes.number,

    // Sự kiện thay đổi
    onChange: PropTypes.func,
    defaultStartDate: PropTypes.instanceOf(moment),
    defaultEndDate: PropTypes.instanceOf(moment),
    renderCount: PropTypes.number,
};

const SCRangeDate = ({
    defaultRange,
    limitRange,
    rangeType,
    onChange,
    defaultStartDate,
    defaultEndDate,
    renderCount,
}) => {
    // Giá trị giới hạn mặc định
    defaultRange = defaultRange || 1;

    // Giá trị giới hạn
    limitRange = limitRange || 1;

    // Kiểu giới hạn (day, week, month)
    const rangeTypeStr = RangeDateService.getTypeLimit(rangeType);

    const [objRangDate, setObjRangeDate] = useState({
        startDate: moment(new Date()).startOf(rangeTypeStr),
        endDate: moment(new Date())
            .startOf(rangeTypeStr)
            .add(defaultRange, rangeTypeStr),
    });

    useEffect(() => {
        if (defaultEndDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: defaultEndDate,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderCount]);

    useEffect(() => {
        if (defaultStartDate && defaultEndDate) {
            setObjRangeDate({
                ...objRangDate,
                startDate: defaultStartDate,
                endDate: defaultEndDate,
            });
            return;
        }
        if (defaultStartDate) {
            setObjRangeDate({
                ...objRangDate,
                startDate: defaultStartDate,
            });
            return;
        }
        if (defaultEndDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: defaultEndDate,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderCount]);

    useEffect(() => {
        if (onChange) {
            onChange({
                startDate: objRangDate.startDate,
                endDate: objRangDate.endDate,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objRangDate]);

    const handleEndDateChange = (s) => {
        // Ngày được chọn nhỏ hơn ngày bắt đầu
        if (s <= objRangDate.startDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: s,
                startDate: moment(s.startOf('day')).subtract(1, 'days'),
            });
        } else if (limitRange) {
            // Ngày chọn lớn hơn số giới hạn
            const _limitRangeDate = moment(
                objRangDate.startDate.startOf('day')
            ).add(limitRange, rangeTypeStr);

            if (_limitRangeDate < s) {
                setObjRangeDate({
                    ...objRangDate,
                    endDate: s,
                    startDate: moment(s.startOf('day')).subtract(
                        limitRange,
                        rangeTypeStr
                    ),
                });
            } else {
                setObjRangeDate({
                    ...objRangDate,
                    endDate: s,
                });
            }
        }

        if (onChange) {
            onChange({
                startDate: objRangDate.startDate.toDate(),
                endDate: objRangDate.endDate.toDate(),
            });
        }
    };

    const handleStartDateChange = (s) => {
        // Ngày được chọn lớn hơn ngày kết thúc
        if (s >= objRangDate.endDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: moment(s.startOf('day')).add(1, 'days'),
                startDate: s,
            });
        } else if (limitRange) {
            const _limitRangeDate = moment(
                objRangDate.endDate.startOf('day')
            ).subtract(limitRange, rangeTypeStr);

            if (_limitRangeDate > s) {
                setObjRangeDate({
                    ...objRangDate,
                    startDate: s,
                    endDate: moment(s.startOf('day')).add(
                        limitRange,
                        rangeTypeStr
                    ),
                });
            } else {
                setObjRangeDate({
                    ...objRangDate,
                    startDate: s,
                });
            }
        }

        if (onChange) {
            onChange({
                startDate: objRangDate.startDate,
                endDate: objRangDate.endDate,
            });
        }

        // setObjRangeDate({
        //     ...objRangDate,
        //     startDate: s,
        // });
    };

    return (
        <div className="row">
            <div className="col-sm-6">
                <div className="form-group">
                    <div className="input-group">
                        <span className="input-group-addon">Từ ngày</span>
                        <DatePicker
                            value={objRangDate.startDate}
                            format="DD/MM/YYYY"
                            onChange={(s) => handleStartDateChange(s)}
                            allowClear={false}
                        />
                    </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="form-group">
                    <div className="input-group">
                        <span className="input-group-addon">Đến ngày</span>
                        <DatePicker
                            value={objRangDate.endDate}
                            format="DD/MM/YYYY"
                            onChange={(s) => handleEndDateChange(s)}
                            allowClear={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

SCRangeDate.propTypes = propTypes;

export default SCRangeDate;
