import { MenuListModel } from '@app/models/foodkid/foodkid.model';
import { getUnixStartOfDay } from '../../utils/datetime.utils';
import * as types from './foodkid.constant';
import _ from 'lodash';
import { decimalToPrice } from '@app/utils/utils';

const initState = {
    kidGroupSelect: '',

    rowSelected: [],
    kidGroupList: [],

    menuList: [],
    menuSchoolFoodList: [],
    menuServiceList: [],

    menuSelect: new MenuListModel(),
    itemMenuCopy: {},
    listMenuCopy: [],

    listServiceSchool: [],

    isShowModel: false,
    isShowModalCopy: false,
    isShowModalQuantity: false,
    isShowModalService: false,

    reload: 0,
};

const foodkidReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_KIDGROUP_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                kidGroupList: data,
                kidGroupSelect: data[0].kg_id,
            };
        }

        case types.CHANG_KIDGROUP: {
            const { id } = action.payload;
            return {
                ...state,
                kidGroupSelect: id,
            };
        }
        case types.GET_MENU_SAMPLE_SUCCESS: {
            const { menu, menudishs, menuServices, menuSchoolFoods } =
                action.payload.data;

            const renderMenuDish = (list, type) => {
                let result = '';

                if (_.isEmpty(list)) return result;

                result = [
                    ...list
                        .filter((o) => o.md_meal === type)
                        .map((o) => o.d_name.trim()),
                ].join(' | ');

                return result;
            };

            const newList = menu.map((mn) => {
                const _dish = menudishs.filter((o) => o.m_id === mn.m_id);

                const _service = menuServices.find((o) => o.m_id === mn.m_id);

                return new MenuListModel({
                    ...mn,
                    m_meal_breakfast: renderMenuDish(_dish, 1),
                    m_meal_lunch: renderMenuDish(_dish, 2),
                    m_meal_snack: renderMenuDish(_dish, 3),
                    m_meal_dinner: renderMenuDish(_dish, 4),
                    m_datefounded: 0,
                    m_balance: decimalToPrice(mn.m_price - mn.m_pricebefore),
                    m_quantityafter: mn.m_quantitybefore,
                    m_service: _service ? _service.mms_price : 0,
                    status: mn.status || '',
                    is_update: false,
                });
            });

            return {
                ...state,
                menuList: newList,
                menuSchoolFoodList: menuSchoolFoods,
                menuServiceList: menuServices,
            };
        }
        case types.SAVE_MENU_SUCCESS: {
            const { data } = action.payload;

            const newList = state.menuList.map((o) => {
                const _check = data.find((x) => x.m_id === o.m_id);

                if (_check) {
                    o = {
                        ...o,
                        status: _check.status,
                        is_update: false,
                    };
                }

                return o;
            });

            return {
                ...state,
                menuSelect: new MenuListModel(),
                isShowModel: false,
                menuList: newList,
                rowSelected: [],
            };
        }
        case types.OPEN_MODAL: {
            const { data } = action.payload;

            const newData = {
                ...data,
                m_code: '',
                m_datefounded: getUnixStartOfDay(new Date()),
            };

            return {
                ...state,
                isShowModel: true,
                menuSelect: new MenuListModel(newData),
            };
        }
        case types.CLOSE_MODAL: {
            return {
                ...state,
                isShowModel: false,
                menuSelect: new MenuListModel(),
            };
        }

        case types.OPEN_MODAL_CHANGE_QUANTITY: {
            return {
                ...state,
                isShowModalQuantity: true,
            };
        }
        case types.CLOSE_MODAL_CHANGE_QUANTITY: {
            return {
                ...state,
                isShowModalQuantity: false,
            };
        }

        case types.OPEN_MODAL_UPDATE_SERVICE: {
            return {
                ...state,
                isShowModalService: true,
            };
        }
        case types.CLOSE_MODAL_UPDATE_SERVICE: {
            return {
                ...state,
                isShowModalService: false,
            };
        }

        case types.CHANGE_MENU_SELECT: {
            const { data } = action.payload;
            return {
                ...state,
                menuSelect: new MenuListModel(data),
            };
        }
        case types.SAVE_BALANCE_TO_SERVICE_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                menuList: data,
            };
        }

        case types.CHECK: {
            const { id } = action.payload;

            const list = state.menuList.map((item) => {
                if (item.m_id === id) {
                    item = {
                        ...item,
                        isCheck: !item.isCheck,
                    };
                }
                return item;
            });
            const listCheck = list.filter((item) => item.isCheck === true);
            const countList = state.menuList.length;
            const countListCheck = listCheck.length;
            const checkAll = !(countList > countListCheck);
            return {
                ...state,
                menuList: list,
                isCheckAll: checkAll,
            };
        }
        case types.CHECK_ALL: {
            const list = state.menuList.map((item) => ({
                ...item,
                isCheck: !state.isCheckAll,
            }));
            return {
                ...state,
                isCheckAll:
                    state.inventoryList.length > 0 ? !state.isCheckAll : false,
                inventoryList: list,
            };
        }

        case types.DELETE_MENU_SUCCESS: {
            const { data } = action.payload;

            const list = state.menuList.filter((o) =>
                data.some((x) => x !== o.m_id)
            );
            return {
                ...state,
                menuList: list,
            };
        }

        case types.UPDATE_MENU_COMPLETE_SUCCESS: {
            const { data } = action.payload;

            const newList = state.menuList.filter(
                (o) => !data.includes(o.m_id)
            );

            return {
                ...state,
                menuList: newList,
            };
        }
        case types.OPEN_MODAL_COPY_MENU: {
            const { data, listMenu } = action.payload;

            return {
                ...state,
                isShowModalCopy: true,
                itemMenuCopy: data,
                listMenuCopy: listMenu,
            };
        }
        case types.CLOSE_MODAL_COPY_MENU: {
            return {
                ...state,
                isShowModalCopy: false,
            };
        }
        case types.UPDATE_MENU_NEW: {
            const { data } = action.payload;

            return {
                ...state,
                menuList: data,
            };
        }
        case types.RESET_AUTO_CREATE_MENU: {
            return {
                ...state,
                ...initState,
            };
        }
        case types.CHANGE_SELECTED_ROW: {
            return {
                ...state,
                rowSelected: action.payload.data,
            };
        }
        case types.UPDATE_NEW_PRICE_MENU_SUCCESS: {
            return {
                ...state,
                rowSelected: [],
            };
        }
        case types.GET_LIST_SERVICE_SCHOOL_SUCCESS: {
            return {
                ...state,
                listServiceSchool: action.payload.data.map((o) => ({
                    ...o,
                    isCheck: false,
                })),
            };
        }
        case types.UPDATE_LIST_SERVICE_SCHOOL: {
            return {
                ...state,
                listServiceSchool: action.payload.data,
            };
        }
        case types.UPDATE_PRICE_SERVICE_MENU_SUCCESS: {
            return {
                ...state,
                reload: state.reload + 1,
                listServiceSchool: state.listServiceSchool.map((o) => ({
                    ...o,
                    isCheck: false,
                })),
                isShowModalService: false,
                rowSelected: [],
            };
        }
        case types.UPDATE_QUANTITY_MENU_SUCCESS: {
            return {
                ...state,
                reload: state.reload + 1,
                listServiceSchool: state.listServiceSchool.map((o) => ({
                    ...o,
                    isCheck: false,
                })),
                isShowModalQuantity: false,
                rowSelected: [],
            };
        }
        case types.ROUNDING_REAL_BUY_SUCCESS: {
            return {
                ...state,
                rowSelected: [],
                reload: state.reload + 1,
            };
        }
        default:
            return { ...state };
    }
};

export default foodkidReducer;
