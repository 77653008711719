import _ from 'lodash';
import { useEffect, useRef } from 'react';

function deepCompareEquals(a, b) {
    return _.isEqual(a, b);
}

function useDeepCompareMemoize(value) {
    const ref = useRef();
    // it can be done by using useMemo as well
    // but useRef is rather cleaner and easier

    if (!deepCompareEquals(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

export const useDeepEffect = (callback, dependencies) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
};
