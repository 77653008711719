import React from 'react';
import { ADMIN_FUNCTIONS, SC_MODULES } from '@app/constants/sc.modules';
import transactionHistoryIcon from '@app/assets/images/icons/sys/invoice.svg';
import TransactionHistory from '@app/components/TransactionHistory';

/**
 * Lịch sử giao dịch
 */
const transactionHistoryRoutes = [
    {
        name: 'LỊCH SỬ GIAO DỊCH',
        icon: transactionHistoryIcon,
        isMenu: true,
        isRoute: true,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        functionId: ADMIN_FUNCTIONS.TRANSACTION_HISTORY,
        link: '/transactionHistory',
        path: '/transactionHistory',
        main: () => <TransactionHistory />,
    },
];

export default transactionHistoryRoutes;
