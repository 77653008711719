/**
 * Lấy phần thập phân cần lấy
 * @param {*} num: số cần xử lý
 * @param {*} n: số chữ số sau dấu phẩy cần lấy
 * @returns
 */
const takeDecimalNumber = (num, n) => {
    const base = 10 ** n;
    const result = Math.round(num * base) / base;
    return result;
};

export { takeDecimalNumber };
