import warehouseRoutes from './WarehouseRoutes';
import attendanceRoutes from './AttendanceRoutes';
import receiptsRoutes from './ReceiptsRoutes';
import generalRoutes from './GeneralRoutes';
import foodkidRoutes from './FoodkidRoutes';
import organizationRoutes from './OrganizationRoutes';
import accountRoutes from './AccountRoutes';
import transactionHistoryRoutes from './TransactionHistoryRoutes';
import settingRoutes from './SettingRoutes';
import agencyRoutes from './AgencyRoutes';

/**
 * Dùng cho hiển thị menu + route
 *  {
        name: Tên menu,
        link: Đường link - Dùng cho trường hợp sử dụng cố định 1 param nào đó,
        path: Route - Setting cấu trúc cho route,
        isMenu: Có phải là menu không,
        isRoute: Có phải là route không,
        exact: false,
        functionId: Id tính năng được truy cập vào,
    },
 */
export const menuRoutes = [
    ...agencyRoutes,
    ...organizationRoutes,
    ...accountRoutes,
    ...foodkidRoutes,
    ...warehouseRoutes,
    ...attendanceRoutes,
    ...receiptsRoutes,
    ...generalRoutes,
    ...transactionHistoryRoutes,
    ...settingRoutes,
];
