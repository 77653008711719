/* eslint-disable max-classes-per-file */
// import { getUnixStartOfDay } from '@app/utils/datetime.utils';

export class MenuListModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.key = data.key || 0;
        this.kg_id = data.kg_id || 0;
        this.kg_name = data.kg_name || '';

        this.m_breakfast = data.m_breakfast || '';
        this.m_chooseprint = data.m_chooseprint || false;
        this.m_code = data.m_code || '';
        this.m_datefounded = data.m_datefounded || 0;
        this.m_dinner = data.m_dinner || '';
        this.m_id = data.m_id || 0;
        this.m_ip = data.m_ip || 0;
        this.m_lunch = data.m_lunch || '';
        this.m_price = data.m_price || 0;
        this.m_priceafter = data.m_priceafter || 0;
        this.m_pricebefore = data.m_pricebefore || 0;
        this.m_quantityafter = data.m_quantityafter || 0;
        this.m_quantitybefore = data.m_quantitybefore || 0;
        this.m_snack = data.m_snack || '';
        this.m_status = data.m_status || 0;
        this.m_step = data.m_step || 0;
        this.o_id = data.o_id || '';
        this.u_id = data.u_id || 0;

        this.m_meal = data.m_meal || '';
        this.m_service = data.m_service || 0;

        this.m_meal_breakfast = data.m_meal_breakfast || 0;
        this.m_meal_lunch = data.m_meal_lunch || 0;
        this.m_meal_snack = data.m_meal_snack || 0;
        this.m_meal_dinner = data.m_meal_dinner || 0;

        this.is_update = data.is_update || false;
    }
}

export class MenuFoodReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.stt = data.stt || 0;
        this.f_name = data.f_name || '';
        this.sf_quantiry = data.sf_quantiry || 0;
        this.unit = data.unit || '';
        this.price = data.price || 0;
        this.protid_dv = data.protid_dv || 0;
        this.protid_av = data.protid_av || 0;
        this.acid_dv = data.acid_dv || 0;

        this.acid_av = data.acid_av || 0;
        this.glucid = data.glucid || 0;
        this.calo = data.calo494 || 0;
        this.totalprice = data.totalprice || 0;
        this.sf_quantitytotal = data.sf_quantitytotal || 0;
        this.disposal = data.disposal || 0;
        this.real = data.real || 0;
    }
}

export class ProductFoodModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.u_id = data.u_id || 0;
        this.fc_id = data.fc_id || 0;
        this.m_id = data.m_id || 0;
        this.msf_id = data.msf_id || 0;
        this.md_id = data.md_id || -1;
        this.md_ids = data.md_ids || [];
        this.d_id = data.d_id || 0;
        this.s_id = data.s_id || 0;
        this.fc_id = data.fc_id || 0;
        this.f_id = data.f_id || 0;
        this.sf_id = data.sf_id || 0;
        this.cu_id = data.cu_id || 0;
        this.msf_sortorder = data.msf_sortorder || 0;
        this.f_code = data.f_code || '';
        this.f_name = data.f_name || '';
        this.sf_name = data.sf_name || '';
        this.f_nameabbreviation = data.f_nameabbreviation || '';
        this.f_imageid = data.f_imageid || '';
        this.f_group = data.f_group || 0;
        this.f_source = data.f_source || 0;
        this.f_disposalrate = data.f_disposalrate || 0;

        this.f_protida = data.f_protida || 0;
        this.f_protidv = data.f_protidv || 0;
        this.f_lipida = data.f_lipida || 0;
        this.f_lipidv = data.f_lipidv || 0;
        this.f_glucid = data.f_glucid || 0;
        this.f_calo = data.f_calo494 || 0;
        this.f_calo494 = data.f_calo494 || 0;
        this.f_salt = data.f_salt || 0;
        this.f_canxi = data.f_canxi || 0;
        this.f_iron = data.f_iron || 0;
        this.f_photpho = data.f_photpho || 0;
        this.f_vitamina = data.f_vitamina || 0;
        this.f_vitaminb1 = data.f_vitaminb1 || 0;
        this.f_vitaminb2 = data.f_vitaminb2 || 0;

        this.f_vitaminpp = data.f_vitaminpp || 0;
        this.f_vitaminc = data.f_vitaminc || 0;
        this.msf_useinwarehouse = data.msf_useinwarehouse || false;
        this.msf_pricebefore = data.msf_pricebefore || 0;
        this.msf_pricefoodbefore = parseFloat(data.msf_pricefoodbefore) || 0;
        this.msf_priceafter = data.msf_priceafter || 0;
        this.msf_pricefoodafter = parseFloat(data.msf_pricefoodafter) || 0;
        this.sf_id = data.sf_id || 0;
        this.sf_group = data.sf_group || 0;
        this.m_quantitybefore = data.m_quantitybefore || 0;
        this.m_quantityafter = data.m_quantityafter || 0;
        this.sf_quantity_g = data.sf_quantity_g || 0;
        this.msf_totalquantityafter = data.msf_totalquantityafter || 0;
        this.msf_totalquantitybefore = data.msf_totalquantitybefore || 0;
        this.msf_quantityafter = data.msf_quantityafter || 0;
        this.msf_quantitybefore = data.msf_quantitybefore || 0;
        this.msf_disposalrate = data.msf_disposalrate || 0;
        this.cu_weight = data.cu_weight || 0;
        this.msf_meal = data.msf_meal || 0;
        this.msf_step1dateimported = data.msf_step1dateimported || 0;
        this.msf_step1manufacturer = data.msf_step1manufacturer || '';
        this.msf_step1manufactureraddress =
            data.msf_step1manufactureraddress || '';
        this.msf_step1suppliercode = data.msf_step1suppliercode || '';
        this.msf_step1suppliername = data.msf_step1suppliername || '';
        this.msf_step1supplieraddress = data.msf_step1supplieraddress || '';
        this.msf_step1deliver = data.msf_step1deliver || '';
        this.msf_step1dateexpired = data.msf_step1dateexpired || 0;
        this.msf_step1storagecondition = data.msf_step1storagecondition || '';
        this.msf_step1invoicenumber = data.msf_step1invoicenumber || '';
        this.msf_step1foodcertificate = data.msf_step1foodcertificate || '';
        this.msf_step1quarantinecertificate =
            data.msf_step1quarantinecertificate || '';
        this.msf_step1qualitytestfailed = data.msf_step1qualitytestfailed || '';
        this.msf_step1qualitytestsucceed =
            data.msf_step1qualitytestsucceed || '';
        this.msf_step1quicktestfailed = data.msf_step1quicktestfailed || '';
        this.msf_step1quicktestsucceed = data.msf_step1quicktestsucceed || '';
        this.msf_step1note = data.msf_step1note || '';
        this.is_update = data.is_update || false;
    }
}

export class MarketReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }

        this.stt = data.stt || '';
        this.sf_id = data.sf_id || '';
        this.f_name = data.f_name || '';
        this.warehouse = data.warehouse || '';
        this.unit = data.unit || '';
        this.price_1 = data.price_1 || '';
        this.sf_quantiry_1 = data.sf_quantiry_1 || '';
        this.price_2 = data.price_2 || '';
        this.sf_quantiry_2 = data.sf_quantiry_2 || '';
        this.price_3 = data.price_3 || '';
        this.sf_quantiry_3 = data.sf_quantiry_3 || '';
        this.price_4 = data.price_4 || '';
        this.sf_quantiry_4 = data.sf_quantiry_4 || '';
        this.total_quantity = data.total_quantity || '';
        this.total_unit_price = data.total_unit_price || '';
        this.total_price = data.total_price || '';
    }
}

export class MarketAgeReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.m_quantity = data.m_quantity || '';
        this.m_id = data.m_id || '';
        this.kg_name = data.kg_name || '';
    }
}

export class Step1AReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }

        this.stt = data.stt || '';
        this.date = data.date || '';
        this.f_name = data.f_name || '';
        this.msf_step1manufacturer = data.msf_step1manufacturer;
        this.msf_step1manufactureraddress = data.msf_step1manufactureraddress;
        this.s_name = data.s_name || '';
        this.unit = data.unit || '';
        this.s_deliver = data.s_deliver || '';
        this.s_address = data.s_address || '';
        this.msf_step1invoicenumber = data.msf_step1invoicenumber || '';
        this.msf_step1quarantinecertificate =
            data.msf_step1quarantinecertificate || '';
        this.msf_step1qualitytestsucceed =
            data.msf_step1qualitytestsucceed || '';
        this.msf_step1qualitytestfailed = data.msf_step1qualitytestfailed || '';
        this.msf_step1quicktestsucceed = data.msf_step1quicktestsucceed || '';
        this.msf_step1dateexpired = data.msf_step1dateexpired || '';
        this.is_update = data.is_update || false;
        this.msf_step1note = data.msf_step1note || '';
        this.msf_step1storagecondition = data.msf_step1storagecondition || '';
    }

    getEmptyReport() {
        this.msf_step1qualitytestfailed = '';
        this.msf_step1qualitytestsucceed = '';
        this.msf_step1invoicenumber = '';
        this.msf_step1quicktestsucceed = '';
    }
}

export class Step1BReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.stt = data.stt || '';
        this.date = data.date || '';
        this.f_name = data.f_name || '';
        this.s_name = data.s_name || '';
        this.unit = data.unit || '';
        this.s_deliver = data.s_deliver || '';
        this.s_address = data.s_address || '';
        this.msf_step1invoicenumber = data.msf_step1invoicenumber || '';
        this.msf_step1quarantinecertificate =
            data.msf_step1quarantinecertificate || '';
        // Giấy kiểm dịch
        this.msf_step1foodcertificate = data.msf_step1foodcertificate || '';
        this.msf_step1qualitytestsucceed =
            data.msf_step1qualitytestsucceed || '';
        this.msf_step1qualitytestfailed = data.msf_step1qualitytestfailed || '';
        this.msf_step1quicktestsucceed = data.msf_step1quicktestsucceed || '';
        this.sf_quantiry_4 = data.msf_step1quicktestfailed || '';
        this.is_update = data.is_update || false;
        this.msf_step1note = data.msf_step1note || '';
    }

    getEmptyReport() {
        this.msf_step1qualitytestfailed = '';
        this.msf_step1qualitytestsucceed = '';
        this.msf_step1invoicenumber = '';
        this.msf_step1quarantinecertificate = '';
    }
}

export class Step2AReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.stt = data.stt || '';
        this.md_name = data.md_name || '';
        this.md_meal = data.md_meal || '';
        this.prepare = data.prepare || '';
        this.md_step2aquantity = data.md_step2aquantity || '';
        this.md_step2atimeprepare = data.md_step2atimeprepare || '';
        this.md_step2atimecooking = data.md_step2atimecooking || '';
        this.md_step2acookingchef = data.md_step2acookingchef || '';
        this.md_step2acookingequipment = data.md_step2acookingequipment || '';
        this.md_step2acookingarea = data.md_step2acookingarea || '';
        this.md_step2aqualitytestsucceed =
            data.md_step2aqualitytestsucceed || '';
        this.md_step2aqualitytestfailed = data.md_step2aqualitytestfailed || '';
        this.md_step2anote = data.md_step2anote || '';
    }

    getEmptyReport() {
        this.md_step2aqualitytestsucceed = '';
        this.md_step2aqualitytestfailed = '';
    }
}

export class Step2BReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.stt = data.stt || '';
        this.md_name = data.md_name || '';
        this.md_meal = data.md_meal || '';
        this.prepare = data.prepare || '';
        this.md_step2aquantity = data.md_step2aquantity || '';
        this.md_step2btimedevidefood = data.md_step2btimedevidefood || '';
        this.md_step2btimeeat = data.md_step2btimeeat || '';
        this.md_step2bequipmentdevidefood =
            data.md_step2bequipmentdevidefood || '';
        this.md_step2bqualitytestsucceed =
            data.md_step2bqualitytestsucceed || '';
        this.md_step2bqualitytestfailed = data.md_step2bqualitytestfailed || '';
        this.md_step2bnote = data.md_step2bnote || '';
    }

    getEmptyReport() {
        this.md_step2bqualitytestsucceed = '';
        this.md_step2bqualitytestfailed = '';
    }
}

export class Step3ReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.stt = data.stt || '';
        this.md_step3timegetsample = data.md_step3timegetsample || '';
        this.f_name = data.f_name || '';
        this.md_step3timedestroysample = data.md_step3timedestroysample || '';
        this.d_name = data.d_name || '';
        this.d_numberofserving = data.d_numberofserving || '';
        this.md_step3note = data.md_step3note || '';
        this.is_update = data.is_update || false;
    }
}

export class StepReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }

        this.stt = data.stt || 0;
        this.f_name = data.f_name || '';
        this.f_id = data.f_id || '';
        this.msf_step1manufacturer = data.msf_step1manufacturer || '';
        this.msf_step1manufactureraddress =
            data.msf_step1manufactureraddress || '';
        this.msf_step1dateimported = data.msf_step1dateimported || '';
        this.msf_step1invoicenumber = data.msf_step1invoicenumber || '';
        this.msf_totalquantitybefore = data.msf_totalquantitybefore || 0;
        this.msf_pricebefore = data.msf_pricebefore || 0;
        this.s_id = data.s_id || 0;
        this.sup_deliver = data.sup_deliver || '';
        this.sup_address = data.sup_address || '';
        this.msf_step1dateexpired =
            data.msf_step1dateexpired === '0'
                ? ''
                : data.msf_step1dateexpired || '';
        this.msf_step1storagecondition = data.msf_step1storagecondition || '';
        this.msf_step1qualitytestsucceed =
            data.msf_step1qualitytestsucceed || '';
        this.msf_step1qualitytestfailed = data.msf_step1qualitytestfailed || '';
        this.msf_step1note = data.msf_step1note || '';
        this.is_update = data.is_update || false;
        this.sf_group = data.sf_group || 0;
        this.sf_id = data.sf_id || 0;
    }
}

export class StepReportBModel {
    constructor(data) {
        if (!data) {
            data = {};
        }

        this.stt = data.stt || 0;
        this.f_name = data.f_name || '';
        this.msf_step1manufacturer = data.msf_step1manufacturer || '';
        this.msf_step1manufactureraddress =
            data.msf_step1manufactureraddress || '';
        this.msf_step1dateimported = data.msf_step1dateimported || '';
        this.msf_step1invoicenumber = data.msf_step1invoicenumber || '';
        this.msf_step1foodcertificate = data.msf_step1foodcertificate || '';
        this.msf_step1quarantinecertificate =
            data.msf_step1quarantinecertificate || '';
        this.msf_totalquantitybefore = data.msf_totalquantitybefore || 0;
        this.msf_step1quicktestsucceed = data.msf_step1quicktestsucceed || '';
        this.msf_step1quicktestfailed = data.msf_step1quicktestfailed || '';
        this.msf_pricebefore = data.msf_pricebefore || 0;
        this.s_id = data.s_id || 0;
        this.sup_deliver = data.sup_deliver || '';
        this.sup_address = data.sup_address || '';
        this.msf_step1dateexpired = data.msf_step1dateexpired || '';
        this.msf_step1storagecondition = data.msf_step1storagecondition || '';
        this.msf_step1qualitytestsucceed =
            data.msf_step1qualitytestsucceed || '';
        this.msf_step1qualitytestfailed = data.msf_step1qualitytestfailed || '';
        this.msf_step1note = data.msf_step1note || '';
        this.is_update = data.is_update || false;
        this.sf_group = data.sf_group || 0;
        this.sf_id = data.sf_id || 0;
    }
}

export class SyntheticNutritionWeekModel {
    constructor(data) {
        if (!data) {
            data = {};
        }

        this.stt = data.stt || '';
        this.f_name = data.f_name || '';
        this.t2 = data.t2 || '';
        this.t3 = data.t3 || '';
        this.t4 = data.t4 || '';
        this.t5 = data.t5 || '';
        this.t6 = data.t6 || '';
        this.t7 = data.t7 || '';
        this.average = data.average || '';
        this.f_lipida = data.f_lipida || '';
        this.f_lipidv = data.f_lipidv || '';
        this.f_protida = data.f_protida || '';
        this.f_protidv = data.f_protidv || '';
        this.f_glucid = data.f_glucid || '';
        this.f_calo = data.f_calo || '';
        this.f_iron = data.f_iron || '';
        this.f_canxi = data.f_canxi || '';
        this.f_vitamina = data.f_vitamina || '';
        this.f_vitaminb1 = data.f_vitaminb1 || '';
        this.f_vitaminb2 = data.f_vitaminb2 || '';
        this.f_vitaminc = data.f_vitaminc || '';
        this.f_vitaminpp = data.f_vitaminpp || '';
        this.m_code = data.m_code || '';
    }
}

export class SyntheticNutritionWeekInfoModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.district = data.district || '';
        this.province = data.province || '';
        this.school = data.school || '';
        this.wards = data.wards || '';
        this.start_date = data.start_date || '';
        this.end_date = data.end_date || '';
        this.kg_name = data.kg_name || '';
        this.total_price = data.total_price || '';
        this.average = data.average || '';
        this.calo_price = data.calo_price || '';
        this.structure_p = data.structure_p || '';
        this.structure_l = data.structure_l || '';
        this.structure_g = data.structure_g || '';
        this.standard_protida = data.standard_protida || '';
        this.standard_protidv = data.standard_protidv || '';
        this.standard_lipida = data.standard_lipida || '';
        this.standard_lipidv = data.standard_lipidv || '';
        this.standard_glucid = data.standard_glucid || '';
        this.standard_calo = data.standard_calo || '';
        this.ratio_protida = data.ratio_protida || '';
        this.ratio_protidv = data.ratio_protidv || '';
        this.ratio_lipida = data.ratio_lipida || '';
        this.ratio_lipidv = data.ratio_lipidv || '';
        this.ratio_glucid = data.ratio_glucid || '';
        this.ratio_calo = data.ratio_calo || '';
        this.ratio_protid_a = data.ratio_protid_a || '';
        this.ratio_protid_v = data.ratio_protid_v || '';
        this.ratio_lipid_a = data.ratio_lipid_a || '';
        this.ratio_lipid_v = data.ratio_lipid_v || '';
        this.rate_achieved_protid = data.rate_achieved_protid || '';
        this.rate_achieved_lipid = data.rate_achieved_lipid || '';
        this.rate_achieved_glucid = data.rate_achieved_glucid || '';
        this.rate_achieved_calo = data.rate_achieved_calo || '';
        this.quantity2 = data.quantity2 || '';
        this.quantity3 = data.quantity3 || '';
        this.quantity4 = data.quantity4 || '';
        this.quantity5 = data.quantity5 || '';
        this.quantity6 = data.quantity6 || '';
        this.quantity7 = data.quantity7 || '';
        this.quantity_total = data.quantity_total || '';
        this.day2 = data.day2 || '';
        this.day3 = data.day3 || '';
        this.day4 = data.day4 || '';
        this.day5 = data.day5 || '';
        this.day6 = data.day6 || '';
        this.day7 = data.day7 || '';
        this.day_total = data.day_total || '';
    }
}
export class MarketCashReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }

        this.stt = data.stt || '';
        this.f_name = data.f_name || '';
        this.warehouse = data.warehouse || '';
        this.price_1 = data.price_1 || 0;
        this.sf_quantiry_1 = data.sf_quantiry_1 || 0;
        this.price_2 = data.price_2 || 0;
        this.sf_quantiry_2 = data.sf_quantiry_2 || 0;
        this.price_3 = data.price_3 || 0;
        this.sf_quantiry_3 = data.sf_quantiry_3 || 0;
        this.price_4 = data.price_4 || 0;
        this.sf_quantiry_4 = data.sf_quantiry_4 || 0;
        this.total_quantity = data.total_quantity || 0;
        this.total_unit_price = data.total_unit_price || 0;
        this.total_unit = data.total_unit || '';
        this.total_price = data.total_price || 0;
    }
}

export class MarketCashAgeReportModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.m_quantity = data.m_quantity || '';
        this.m_id = data.m_id || '';
        this.kg_name = data.kg_name || '';
    }
}

export class MarketCashKidGroupModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.kg_id = data.kg_id || 0;
        this.kg_name = data.kg_name || 0;
        this.m_price = data.m_price || 0;
        this.m_priceafter = data.m_priceafter || 0;
        this.m_pricebefore = data.m_pricebefore || 0;
        this.m_quantityafter = data.m_quantityafter || 0;
        this.m_quantitybefore = data.m_quantitybefore || 0;
        this.print = data.print || true;
    }
}

export class AggregateDayServiceModel {
    constructor(data) {
        if (!data) {
            data = {};
        }
        this.stt = data.stt || '';
        this.s_name = data.s_name || '';
        this.service_quantity_1 = data.service_quantity_1 || '';
        this.service_price_1 = data.service_price_1 || '';
        this.service_quantity_2 = data.service_quantity_2 || '';
        this.service_price_2 = data.service_price_2 || '';
        this.service_quantity_3 = data.service_quantity_3 || '';
        this.service_price_3 = data.service_price_3 || '';
        this.service_quantity_4 = data.service_quantity_4 || '';
        this.service_price_4 = data.service_price_4 || '';
        this.service_quantity_total = data.service_quantity_total || '';
        this.service_price = data.service_price || '';
        this.service_price_total = data.service_price_total || '';
    }
}
