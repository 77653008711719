/* eslint-disable eqeqeq */
import {
    COST_CALCULATED_TYPE,
    RECEIPT_VOUCHER_TYPE,
} from '@app/constants/receiptCost.CONST';

import { getSortOrder } from '@app/utils/studentCache.utils';
import _ from 'lodash';

/**
 * Các utils của phiếu thu kèm theo chi tiết
 */
export const _receiptVoucherUtils = {
    /**
     * Lấy danh sách chi tiết phiếu thu của 1 phiếu thu
     * @param {Object} receiptVoucher :   Phiếu thu có kèm chi tiết
     */
    getListDetail(receiptVoucher) {
        return receiptVoucher.detailArr || [];
    },

    /**
     * Lấy danh sách chi tiết phiếu thu của 1 phiếu thu với kiểu là RECEIPT_VOUCHER_TYPE.PHIEU_THU
     * @param {Object} receiptVoucher :   Phiếu thu có kèm chi tiết
     */
    getReceiptListDetail(receiptVoucher) {
        return (
            (receiptVoucher.detailArr ?? []).filter(
                (o) => o.rd_type == RECEIPT_VOUCHER_TYPE.PHIEU_THU
            ) ?? []
        );
    },

    /**
     * Lấy danh sách chi tiết phiếu thu của 1 phiếu thu với kiểu là RECEIPT_VOUCHER_TYPE.PHIEU_CHI/ Chi tiết dùng cho phiếu hoàn trả
     * @param {Object} receiptVoucher :   Phiếu thu có kèm chi tiết
     */
    getExpenseListDetail(receiptVoucher) {
        return (
            (receiptVoucher.detailArr || []).filter(
                (o) => o.rd_type == RECEIPT_VOUCHER_TYPE.PHIEU_CHI
            ) || []
        );
    },

    /**
     * Lấy danh sách chi tiết là tiền ăn
     * @param {Object} receiptVoucher : Phiếu thu có kèm theo chi tiết
     */
    getMealList(receiptVoucher) {
        const _listDetail = this.getReceiptListDetail(receiptVoucher);
        const mealList =
            _listDetail.filter(
                (_item) =>
                    _item.cl_calculatedtype ===
                        COST_CALCULATED_TYPE.TIEN_AN_TAT_CA_CAC_NGAY ||
                    _item.cl_calculatedtype ===
                        COST_CALCULATED_TYPE.TIEN_AN_T2_T6 ||
                    _item.cl_calculatedtype ===
                        COST_CALCULATED_TYPE.TIEN_AN_T7_CN
            ) || [];
        return mealList;
    },

    /**
     *  Lấy danh sách chi tiết là tiền ăn theo danh sách chi tiết phiếu thu
     * @param {Array} _listDetail : Danh sách chi tiết phiếu thu
     */
    getMealByList(_listDetail) {
        const mealList =
            _listDetail.filter(
                (_item) =>
                    (_item.cl_calculatedtype ===
                        COST_CALCULATED_TYPE.TIEN_AN_TAT_CA_CAC_NGAY ||
                        _item.cl_calculatedtype ===
                            COST_CALCULATED_TYPE.TIEN_AN_T2_T6 ||
                        _item.cl_calculatedtype ===
                            COST_CALCULATED_TYPE.TIEN_AN_T7_CN) &&
                    _item.rd_type == RECEIPT_VOUCHER_TYPE.PHIEU_THU
            ) || [];
        return mealList;
    },

    // Lấy danh sách có hoàn trả
    getRefundList(receiptVoucher) {
        const _listDetail = this.getReceiptListDetail(receiptVoucher);
        const refundList =
            _listDetail.filter((_item) => _item.rd_quantityrefunded > 0) || [];
        return refundList;
    },

    /**
     *  Lấy tổng tiền hoàn trả của 1 phiếu thu
     * @param {Object} receiptVoucher : Phiếu thu có kèm chi tiết
     */
    getTotalMoneyRefund(receiptVoucher) {
        const _refundList = this.getRefundList(receiptVoucher);
        const total =
            _.sum(
                _refundList.map(
                    (_item) =>
                        _item.rd_quantityrefunded * _item.rd_pricerefunded
                )
            ) || 0;
        return total;
    },

    getTotalMoneyRefundByMonth(receiptVoucher, month) {
        if (receiptVoucher.r_month !== month) {
            return 0;
        }

        return this.getTotalMoneyRefund(receiptVoucher);
    },

    /**
     *  Lấy tổng ngày hoàn trả/ ngày nghỉ tháng trước của 1 phiếu thu
     * @param {Object} receiptVoucher: Phiếu thu có kèm chi tiết
     */
    getTotalQuantityRefund(receiptVoucher) {
        const _refundList = this.getRefundList(receiptVoucher);

        // Tất cả các ngày
        const refundTatCaCacNgay = _refundList.find(
            (o) =>
                o.cl_calculatedtype ===
                COST_CALCULATED_TYPE.TIEN_AN_TAT_CA_CAC_NGAY
        );

        const quantityTatCaCacNgay = refundTatCaCacNgay
            ? refundTatCaCacNgay.rd_quantityrefunded
            : 0;
        // Từ thứ 2 đến thứ 6
        const refundT2T6 = _refundList.find(
            (o) => o.cl_calculatedtype === COST_CALCULATED_TYPE.TIEN_AN_T2_T6
        );

        const quantityT2T6 = refundT2T6 ? refundT2T6.rd_quantityrefunded : 0;

        // Thứ 7 + CN
        const refundT7CN = _refundList.find(
            (o) => o.cl_calculatedtype === COST_CALCULATED_TYPE.TIEN_AN_T7_CN
        );

        const quantityT7CN = refundT7CN ? refundT7CN.rd_quantityrefunded : 0;

        return quantityTatCaCacNgay > 0
            ? quantityTatCaCacNgay
            : quantityT2T6 + quantityT7CN;
    },

    /**
     * Lấy tổng ngày nghỉ tháng trước theo tháng
     * @param {*} receiptVoucher
     * @param {*} month
     * @returns
     */
    getTotalQuantityRefundByMonth(receiptVoucher, month) {
        if (receiptVoucher.r_month !== month) {
            return 0;
        }
        return this.getTotalQuantityRefund(receiptVoucher);
    },

    /**
     * Lấy tổng ngày đi học (theo chi tiết phiếu thu là tiền ăn)
     * @param {Object} receiptVoucher : Phiếu thu có kèm chi tiết
     */
    getTotalQuantityMealList(receiptVoucher) {
        const _mealList = this.getMealList(receiptVoucher);

        const mealTatCaCacNgay = _mealList.find(
            (o) =>
                o.cl_calculatedtype ===
                COST_CALCULATED_TYPE.TIEN_AN_TAT_CA_CAC_NGAY
        );
        const quantityTatCaCacNgay = mealTatCaCacNgay
            ? mealTatCaCacNgay.rd_quantity
            : 0;

        const mealT2T6 = _mealList.find(
            (o) => o.cl_calculatedtype === COST_CALCULATED_TYPE.TIEN_AN_T2_T6
        );
        const quantityT2T6 = mealT2T6 ? mealT2T6.rd_quantity : 0;

        const mealT7CN = _mealList.find(
            (o) => o.cl_calculatedtype === COST_CALCULATED_TYPE.TIEN_AN_T7_CN
        );

        const quantityT7CN = mealT7CN ? mealT7CN.rd_quantity : 0;

        return quantityTatCaCacNgay > 0
            ? quantityTatCaCacNgay
            : quantityT2T6 + quantityT7CN;
    },

    /**
     * Lấy tổng ngày đi học theo danh sách chi tiết phiếu thu
     * @param {Array} _listDetail : Danh sách chi tiết phiếu thu
     */
    getTotalQuantityMealByList(_listDetail) {
        const _mealList = this.getMealByList(_listDetail);

        const mealTatCaCacNgay = _mealList.find(
            (o) =>
                o.cl_calculatedtype ===
                COST_CALCULATED_TYPE.TIEN_AN_TAT_CA_CAC_NGAY
        );
        const quantityTatCaCacNgay = mealTatCaCacNgay
            ? mealTatCaCacNgay.rd_quantity
            : 0;

        const mealT2T6 = _mealList.find(
            (o) => o.cl_calculatedtype === COST_CALCULATED_TYPE.TIEN_AN_T2_T6
        );
        const quantityT2T6 = mealT2T6 ? mealT2T6.rd_quantity : 0;

        const mealT7CN = _mealList.find(
            (o) => o.cl_calculatedtype === COST_CALCULATED_TYPE.TIEN_AN_T7_CN
        );

        const quantityT7CN = mealT7CN ? mealT7CN.rd_quantity : 0;

        return quantityTatCaCacNgay > 0
            ? quantityTatCaCacNgay
            : quantityT2T6 + quantityT7CN;
    },

    /**
     * Lấy tổng tiền của phiếu thu
     * @param {Object} receiptVoucher: Phiếu thu có kèm danh sách chi tiết
     */
    getTotalMoney(receiptVoucher) {
        const _list = this.getReceiptListDetail(receiptVoucher);
        return _.sumBy(_list, 'rd_total');
    },

    /**
     * Lấy tổng tiền của phiếu thu theo danh sách khoản thu hiển thị
     * @param {Object} receiptVoucher: Phiếu thu có kèm danh sách chi tiết
     * @param {Array} costByClassList: Danh sách chi phí lớp học
     */
    getTotalMoneyByAvailableCostList(receiptVoucher, costByClassList) {
        const costListId = costByClassList
            .filter((o) => !o.isHide)
            .map((o) => o.cl_id);
        const _list = this.getReceiptListDetail(receiptVoucher).filter((o) =>
            _.includes(costListId, o.cl_id)
        );

        return _.sumBy(_list, 'rd_total');
    },

    /**
     * Lấy tổng tiền của danh sách phiếu thu
     * @param {Array} _list: danh sách phiếu thu có kèm danh sách chi tiết
     */
    getAllTotalMoney(_list) {
        return _.sum(_list.map((o) => this.getTotalMoney(o)));
    },

    /**
     * Lấy tổng tiền của  danh sách phiếu thu theo danh sách khoản thu hiển thị
     * @param {Array} _list: Phiếu thu có kèm danh sách chi tiết
     * @param {Array} costByClassList: Danh sách chi phí lớp học
     */
    getAllTotalMoneyByAvailableCostList(_list, costByClassList) {
        return _.sum(
            _list.map((o) =>
                this.getTotalMoneyByAvailableCostList(o, costByClassList)
            )
        );
    },
    /**
     * Lấy bảng dữ liệu phiếu báo đóng tiền(phiếu thu)
     * @param {Array} listReceipt : danh sách phiếu thu
     * @param {Array} listCost : Danh sách học sinh
     * @param {Array} listStudent : Danh sách học sinh
     */
    getDataTable(listReceipt, listCost, listStudent) {
        let listRes = [];
        if (listReceipt && listReceipt.length > 0) {
            listRes = listReceipt.map((item) => {
                // Lấy thông tin học sinh
                const itemStudent =
                    listStudent.find((o) => o.s_id == item.r_linksid) || {};

                // Lấy sanh sách chi tiết phiếu thu
                const listDetail = this.getReceiptListDetail(item);

                let itemRes = {
                    ...item,
                    key: item.r_id,
                    s_firstname: itemStudent.s_firstname,
                    s_lastname: itemStudent.s_lastname,
                    s_fullname: itemStudent.s_fullname,
                    r_prevRefundQuantity: this.getTotalQuantityRefund(item),
                    r_prevRefundMoney: this.getTotalMoneyRefund(item),
                    sortOrder: getSortOrder(itemStudent, item.sy_id),
                    student: itemStudent,
                };

                listCost.forEach((elm) => {
                    const detail =
                        listDetail.find((o) => o.cl_id == elm.cl_id) || {};
                    itemRes = {
                        ...itemRes,
                        [`cl_${elm.cl_id}`]: detail.rd_total || 0,
                    };
                });

                return itemRes;
            });
        }

        listRes = _.orderBy(listRes, ['sortOrder'], ['asc']).map(
            (item, index) => ({
                ...item,
                sortOrder: index + 1,
            })
        );

        return listRes;
    },
};
