import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RECEIPT_VOUCHER_PAYMENT_TYPE } from '@app/constants/receiptCost.CONST';
import BaseSelect from '../../BaseSelect';

const propTypes = {
    onChange: PropTypes.func,
    hasAllOption: PropTypes.bool,
};

const PaymentTypeList = [
    {
        id: RECEIPT_VOUCHER_PAYMENT_TYPE.TIEN_MAT,
        name: 'Tiền mặt',
    },
    {
        id: RECEIPT_VOUCHER_PAYMENT_TYPE.CHUYEN_KHOAN,
        name: 'Chuyển khoản',
    },
];

function PaymentTypeSelect({ onChange, hasAllOption, label }) {
    const [selectId, setSelectId] = useState(PaymentTypeList[0].id);
    const [list, setList] = useState(PaymentTypeList);

    useEffect(() => {
        let _listTemp = list;
        if (hasAllOption) {
            _listTemp = [
                {
                    id: RECEIPT_VOUCHER_PAYMENT_TYPE.ALL,
                    name: 'Tất cả',
                },
                ..._listTemp,
            ];
        }
        setList(_listTemp);
        setSelectId(_listTemp[0].id);
        if (onChange) {
            onChange(_listTemp[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasAllOption]);

    const handleChange = (value) => {
        setSelectId(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <BaseSelect
            list={PaymentTypeList}
            idField="id"
            nameField="name"
            onChange={handleChange}
            label={label}
            selectId={selectId}
        />
    );
}

PaymentTypeSelect.propTypes = propTypes;

export default PaymentTypeSelect;
