export const GET_WARDS = 'GET_WARDS';
export const GET_WARDS_SUCCESS = 'GET_WARDS_SUCCESS';

export const ADD_NEW = 'ADD_NEW';

export const CLOSE_WARD_MODAL = 'CLOSE_WARD_MODAL';

export const RESET_WARD_EDIT_ITEM = 'RESET_WARD_EDIT_ITEM';

export const SAVE_WARD = 'SAVE_WARD';
export const SAVE_WARD_SUCCESS = 'SAVE_WARD_SUCCESS';

export const EDIT_WARD = 'EDIT_WARD';

export const DELETE_WARD = 'DELETE_WARD';
export const DELETE_WARD_SUCCESS = 'DELETE_WARD_SUCCESS';

export const GET_WARD_SELECT_LIST = 'GET_WARD_SELECT_LIST';
export const GET_WARD_SELECT_LIST_SUCCESS = 'GET_WARD_SELECT_LIST_SUCCESS';
export const WARD_SELECT_CHANGE = 'WARD_SELECT_CHANGE';

// My
export const CLEAN_SELECT_LIST = 'CLEAN_SELECT_LIST';
