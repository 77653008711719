import userIcon from '@app/assets/images/icons/ic/calendar.svg';
import Attendance from '@app/components/Income/Attendance';
import React from 'react';
import { ADMIN_FUNCTIONS, SC_MODULES } from '@app/constants/sc.modules';

/**
 * Người dùng
 */
const attendanceRoutes = [
    {
        name: 'BẢNG ĐIỂM DANH',
        icon: userIcon,
        isMenu: true,
        isRoute: true,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        functionId: ADMIN_FUNCTIONS.ATTENDANCE_PACKAGE,
        link: '/attendance',
        path: '/attendance',
        main: () => <Attendance />,
        // children: [
        //   {
        //     name: "menu.attendance",
        //     link: "/attendance",
        //     path: "/attendance",
        //     isMenu: true,
        //     isRoute: true,
        //     exact: false,

        //   },
        // ],
    },
];

export default attendanceRoutes;
