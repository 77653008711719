const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isShowChangePassPopup: false,
};

const myAccountSlice = createSlice({
    name: 'myAccount',
    initialState,
    reducers: {
        doOpenChangePassPopup: (state, { payload }) => {
            state.isShowChangePassPopup = payload;
        },
    },
});

export const _myAccountActs = myAccountSlice.actions;
export default myAccountSlice.reducer;
