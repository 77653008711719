import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { nonAccentVietnamese } from '@app/utils/utils';
import { apiGetByProp } from '@app/apis/schools/schoolProperty';

const { Option } = Select;
const propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,

    // Mã tổ chức
    oId: PropTypes.string,
};

const MonthDynamicSelect = ({ label, onChange, oId }) => {
    const isFirst = useRef(true);
    const [list, setList] = useState([]);
    const [selectId, setSelectId] = useState('');
    //'8eab1345-ddcb-47c9-9051-7fc9fa3ea366'
    useEffect(() => {
        if (oId) {
            apiGetByProp(oId, 'thang_chuyen_lop').then((res) => {
                if (res.status === 200) {
                    const thangChuyenLop =
                        +(res?.data?.data ?? {}).ps_value || 1;
                    let listMonth = [];
                    for (let i = thangChuyenLop; i <= 12; i++) {
                        listMonth = [...listMonth, i];
                    }
                    for (let i = 1; i < thangChuyenLop; i++) {
                        listMonth = [...listMonth, i];
                    }
                    const thangHienTai = new Date().getMonth() + 1;

                    setSelectId(thangHienTai);
                    setList(listMonth);

                    isFirst.current = false;
                    if (onChange) {
                        onChange(thangHienTai);
                    }
                } else {
                    setList([]);
                }
            });
        } else {
            setList([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oId]);

    const handleChange = (s) => {
        setSelectId(s);
        if (onChange) {
            onChange(s);
        }
    };

    const onSearch = (input, option) => {
        if (option) {
            return (
                nonAccentVietnamese(option.children.replace(/\s/g, ''))
                    .toLowerCase()
                    .indexOf(
                        nonAccentVietnamese(
                            input.replace(/\s/g, '')
                        ).toLowerCase()
                    ) >= 0
            );
        }
        return false;
    };

    const showOptionItem = () => {
        let xhtml = null;
        if (list.length > 0) {
            xhtml = list.map((item) => (
                <Option value={item} key={`${item}`}>
                    {`${item}`}
                </Option>
            ));
        }
        return xhtml;
    };

    return (
        <Fragment>
            {label ? (
                <Fragment>
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                    <div className="clearfix mb20" />
                </Fragment>
            ) : null}
            <Select
                value={selectId}
                onChange={(value) => handleChange(value)}
                optionFilterProp="children"
                filterOption={(input, option) => onSearch(input, option)}
                showSearch
            >
                {showOptionItem()}
            </Select>
        </Fragment>
    );
};

MonthDynamicSelect.propTypes = propTypes;

export default MonthDynamicSelect;
