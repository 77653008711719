import { createSlice } from '@reduxjs/toolkit';

const MODULE_NAME = 'accountPrivilege';

const initialState = {
    privilegeList: [],
};

const AccountPrivilegeSlice = createSlice({
    name: MODULE_NAME,
    initialState,
    reducers: {
        /** ======== FETCH DATA ======== */
        doGetAccountPrivilege() {},
        doGetAccountPrivilegeSuccess(state, { payload }) {
            state.privilegeList = payload;
        },

        doUpdateAccountPrivilege() {},
        doUpdateAccountPrivilegeSuccess(state, { payload }) {
            const newList = state.privilegeList.map((item) => {
                const privilege = payload.find((x) => x.sp_id === item.sp_id);

                if (privilege) {
                    item = {
                        ...item,
                        ...privilege,
                    };
                }

                return item;
            });

            state.privilegeList = newList;
        },

        doReset(state) {
            state.privilegeList = [];
        },
    },
});

/** ===== ACTION ==== **/
export const accountPrivilegeActions = AccountPrivilegeSlice.actions;

/** ===== SELECTOR ==== **/
export const accountPrivilegeSelector = {
    privilegeList: (state) => state[MODULE_NAME].privilegeList,
};

/** ===== REDUCER ==== **/
const accountPrivilegeReducer = AccountPrivilegeSlice.reducer;
export default accountPrivilegeReducer;
