import * as types from './month.constant';
// import { ProductModel } from '../../../models/product/product.model';

const initState = {
    monthSelectList: [
        { m_id: 1, m_name: '1' },
        { m_id: 2, m_name: '2' },
        { m_id: 3, m_name: '3' },
        { m_id: 4, m_name: '4' },
        { m_id: 5, m_name: '5' },
        { m_id: 6, m_name: '6' },
        { m_id: 7, m_name: '7' },
        { m_id: 8, m_name: '8' },
        { m_id: 9, m_name: '9' },
        { m_id: 10, m_name: '10' },
        { m_id: 11, m_name: '11' },
        { m_id: 12, m_name: '12' },
    ],
    currentMonth: new Date().getMonth() + 1,
};

const monthReducer = (state = initState, action) => {
    switch (action.type) {
        case types.CHANGE_MONTH_SELECT: {
            const { data } = action.payload;
            return {
                ...state,
                currentMonth: data,
            };
        }
        default:
            return { ...state };
    }
};

export default monthReducer;
