import React from 'react';

const ModalConnHistoryList = (props) => (
    <table className="table table-togglable table-hover table-bordered">
        <thead className="grey-light">
            <tr>
                <th className="text-center" style={{ width: '100px' }}>
                    Thời gian
                </th>
                <th className="text-center">
                    Tên đơn vị
                    <span className="display-block text-muted">Địa chỉ</span>
                </th>
                <th
                    className="text-center"
                    style={{ width: '6%' }}
                    data-hide="phone"
                >
                    Tình trạng
                </th>
            </tr>
        </thead>
        <tbody>{props.children}</tbody>
    </table>
);

export default ModalConnHistoryList;
