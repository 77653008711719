/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import _globalUtils from '@app/utils/global.utils';
import { docso, numberToPrice, priceToNumber } from '@app/utils/utils';
import _ from 'lodash';
import moment from 'moment';
import { GLOBAL } from '@app/constants/global.const';
import _numberUtis from '@app/utils/number.utils';

const { roundNumber } = _numberUtis;

class _receiptInExportPDFService {
    /**
     * Merge dữ liệu kho
     * @param {Array} schoolFoods : danh sách thực phẩm trường
     * @param {object} inventoryFood : thực phẩm kho
     * @param {Array} units : danh sách đơn vị tính
     * @param {Array} inventoryHouseList : danh sách kho
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     * @param {number} type : loại xuất hoặc nhập kho
     * @param {String} nameFile : Tên file
     */
    doExport = async (
        schoolFoods,
        inventoryFood,
        units,
        inventoryHouseList,
        schoolInfo,
        organizationInfo,
        type,
        nameFile
    ) => {
        // schoolInfo ??= {};

        const _nameFile =
            nameFile || (type === 1 ? 'PhieuNhapKho' : 'PhieuXuatKho');
        const date = moment(moment.unix(inventoryFood.fr_datefounded));

        const objType = {
            1: 'import',
            2: 'export',
        };

        const initObj = (_data, _type) => ({
            code: '',
            reality_quantity: '',

            name: _data?.frd_foodname || '',
            license_quantity: parseFloat(
                _data[`frd_quantity${objType[_type]}`].toFixed(2)
            ),
            price: numberToPrice(_data[`frd_price${objType[_type]}`]),
            total_price: numberToPrice(_data[`frd_total${objType[_type]}`]),
            total_price_number: roundNumber(
                _data[`frd_total${objType[_type]}`],
                0
            ),
        });

        let list = inventoryFood.schoolFoodList.sort((a, b) =>
            a.f_name > b.f_name ? 1 : -1
        );

        if (type === 1) {
            list = list.map((item, i) => {
                const sf = schoolFoods.find((o) => o.sf_id === item?.sf_id);
                const unit = units.find((o) => o.cu_id === sf?.cu_id);

                const d = initObj(item, 1);

                d.stt = i + 1;
                d.unit = unit?.cu_name || '';

                return d;
            });
        } else {
            list = list.reduce((rsList, item) => {
                const checkExist = rsList?.find(
                    (o) =>
                        o.sf_id === item.sf_id &&
                        o.price_original === item.frd_priceexport
                );

                if (checkExist) {
                    rsList = rsList.map((o) => {
                        if (o.sf_id === checkExist.sf_id) {
                            o = {
                                ...o,
                                license_quantity: parseFloat(
                                    (
                                        o.license_quantity +
                                        item.frd_quantityexport
                                    ).toFixed(2)
                                ),
                                total_price: numberToPrice(
                                    priceToNumber(o.total_price) +
                                        item.frd_totalexport
                                ),
                                total_price_number: roundNumber(
                                    o.total_price_number + item.frd_totalexport,
                                    0
                                ),
                            };
                        }

                        return o;
                    });
                } else {
                    const d = initObj(item, 2);

                    const sf = schoolFoods.find((o) => o.sf_id === item?.sf_id);
                    const unit = units.find((o) => o.cu_id === sf?.cu_id);

                    d.stt = rsList.length === 0 ? 1 : rsList.length + 1;
                    d.unit = unit?.cu_name || '';
                    d.price_original = item.frd_priceexport;
                    d.sf_id = item.sf_id;

                    rsList = [...rsList, d];
                }

                return rsList;
            }, []);
        }

        const currentWarehouse =
            inventoryHouseList.find((o) => o.w_id === inventoryFood.w_id) ?? {};

        const deliverName = currentWarehouse.w_deliver;
        const receiverName = schoolInfo?.nguoi_nhan_hang_nxk;
        const reason =
            type === 1
                ? schoolInfo?.ly_do_nhap_kho
                : schoolInfo?.ly_do_xuat_kho;

        const lists = [
            {
                info: {
                    department: '',
                    school: schoolInfo?.ten_truong,
                    deliver: deliverName,
                    reason,
                    //reason: inventoryFood.fr_description,
                    warehouse: currentWarehouse.w_name,
                    receiver: receiverName,
                    location: inventoryFood.fr_address,
                    code: inventoryFood.fr_code,
                    creater: schoolInfo?.nguoi_lap_phieu_nxk || '',
                    accountant: schoolInfo?.ho_ten_ke_toan || '',
                    stocker: schoolInfo?.ho_ten_thu_kho || '',
                    warehouseKeeper: schoolInfo.ho_ten_thu_kho || '',
                    date: `Ngày ${date.format('DD')} Tháng ${date.format(
                        'MM'
                    )} Năm ${date.format('YYYY')}`,
                    money: `${docso(
                        _numberUtis.roundNumber(
                            _.sumBy(list, 'total_price_number'),
                            0
                        )
                    )
                        .trim()
                        .Capitalize()} đồng`,
                    assistant: schoolInfo?.ho_ten_hieu_pho_ban_tru,
                    assistantTitle:
                        schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                        GLOBAL.REPORT_ASSISTANT_TITLE,
                },
                datas: list,
            },
        ];

        const res = await exportReportToPDF({
            data: JSON.stringify({ lists, type }),
            report:
                type === 1
                    ? REPORT_TYPE.PHIEU_NHAP_KHO
                    : REPORT_TYPE.PHIEU_XUAT_KHO,
        });
        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `${_nameFile}_${date.format('DD/MM/YYYY')}_${
                    organizationInfo.o_name
                }_${
                    inventoryHouseList.find(
                        (o) => o.w_id === inventoryFood.w_id
                    )?.w_name
                }`
            );
        }
        return true;
    };
}

export default new _receiptInExportPDFService();
