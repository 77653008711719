import NoData from '@app/components/Layout/Shares/NoData';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import ButtonModalAction from '@app/components/UI/Button/ButtonModalAction';
import { agencyActions, agencySelector } from '@app/redux/agency/agency.slice';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from '@app/components/_Share/Pagination';
import ModalConnHistoryItem from './ModalConnHistoryItem';
import ModalConnHistoryList from './ModalConnHistoryList';
import ModalConnHistoryFilter from './ModalConnHistoryFilter';
import moment from 'moment';

const ModalConnHistory = ({ isShow, onCloseModal }) => {
    const dispatch = useDispatch();

    const [objDate, setObjDate] = useState({});
    const [idStatus, setIdStatus] = useState(-1);

    const listOrgScale = useSelector(agencySelector.listOrgScale);
    const listOrgType = useSelector(agencySelector.listOrgType);
    const total = useSelector(agencySelector.totalConnHistory);
    const currentPage = useSelector(agencySelector.currentPageModalConn);
    const listConnHistory = useSelector(agencySelector.listConnHistory);
    const currentAgency = useSelector(agencySelector.currentAgency);

    useEffect(() => {
        if (idStatus && +objDate?.fromDate > 0 && +objDate?.toDate > 0) {
            dispatch(agencyActions.doChangePageListConn(1));

            dispatch(
                agencyActions.doGetListConnHistory({
                    o_id: currentAgency?.o_id,
                    currentPage: 1,
                    fromDate: objDate.fromDate,
                    toDate: objDate.toDate,
                    ch_connectionstatus: idStatus,
                })
            );
        }
    }, [dispatch, objDate, idStatus, currentAgency.o_id]);

    const onChangePage = (page) => {
        dispatch(agencyActions.doChangePageListConn(page));

        dispatch(
            agencyActions.doGetListConnHistory({
                o_id: currentAgency?.o_id,
                currentPage: page,
                fromDate: objDate.fromDate,
                toDate: objDate.toDate,
                so_paymentstatus: idStatus,
                isGetDetail: false,
            })
        );
    };

    const showListConnHistory = () => {
        if (_.isEmpty(listConnHistory)) return <NoData colspan={3} />;
        return listConnHistory.map((item, idx) => {
            const scaleName = listOrgScale.find(
                (sCale) => sCale.so_id === item.so_id
            )?.so_name;

            const typeName = listOrgType.find(
                (sCale) => sCale.to_id === item.to_id
            )?.to_name;

            return (
                <ModalConnHistoryItem
                    key={idx}
                    connHistory={{ ...item, scaleName, typeName }}
                />
            );
        });
    };

    /** ========== THAY ĐỔI NGÀY ========== */
    const onChangeDate = (data) => {
        const { startDate, endDate } = data;

        const unixSD = moment(startDate).startOf('day').unix();
        const unixED = moment(endDate).endOf('day').unix();

        setObjDate({ fromDate: unixSD, toDate: unixED });
    };

    /** ========== THAY ĐỔI TRẠNG THÁI ========== */
    const onChangeStatus = (data) => {
        setIdStatus(data);
    };

    return (
        <Modal
            show={isShow}
            onHide={onCloseModal}
            animation
            backdrop="static"
            size="lg"
            className={listConnHistory?.length >= 10 ? 'fit-screen' : ''}
        >
            <Modal.Header closeButton className="bg-primary-800">
                <Modal.Title>Lịch sử kết nối</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <ModalConnHistoryFilter
                        onChangeDate={onChangeDate}
                        onChangeStatus={onChangeStatus}
                        statusSelected={idStatus}
                    />
                    <div className="col-md-12">
                        <ModalConnHistoryList>
                            {showListConnHistory()}
                        </ModalConnHistoryList>
                    </div>
                </div>

                <CustomPagination
                    total={total}
                    pageSize={10}
                    currentPage={currentPage}
                    currentCount={listConnHistory.length}
                    onChange={onChangePage}
                />
            </Modal.Body>
            <Modal.Footer>
                <ButtonModalAction
                    buttonType={BUTTON_ACTION_TYPE.CLOSE}
                    buttonLabel="Thoát"
                    onClick={onCloseModal}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConnHistory;
