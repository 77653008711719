import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

const propTypes = {
    onChange: PropTypes.func,
};

const OnlyMonthPicker = ({ label, onChange }) => {
    const [month, setCurrentMonth] = useState(moment(new Date()));

    const onChangeData = (_month) => {
        if (onChange) {
            onChange({
                month: +_month.format('M'),
                year: +_month.format('YYYY'),
            });
        }
    };

    useEffect(() => {
        onChangeData(month);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHandleChange = (s) => {
        setCurrentMonth(s);
        onChangeData(s);
    };
    return (
        <>
            {label ? (
                <label>
                    <h6 className="panel-title">{label}</h6>
                </label>
            ) : null}

            <DatePicker
                picker="month"
                //disabledDate={disabledDate}
                format="MM/YYYY"
                value={month}
                onChange={(s) => onHandleChange(s)}
                allowClear={false}
            />
        </>
    );
};

OnlyMonthPicker.propTypes = propTypes;

export default OnlyMonthPicker;
