/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Select } from 'antd';

const SchoolSettingItem = (props) => {
    const {
        colClass,
        item,
        dataType,
        componentType,
        listSelect,
        value,
        onChange,
        id,
        name,
    } = props;

    /**
     * Change input number data
     * @param {int} e
     * @param {string} code
     */
    const onChangeData = (e, code) => {
        const _value = e.target.value;
        if (dataType === 'number') {
            if (e.target.type !== dataType) {
                e.preventDefault();
                return;
            }
        }
        onChange(_value, code);
    };

    /**
     * Change data select box
     * @param {string} value
     * @param {string} code
     */
    const onChangeSelectData = (_value, code) => {
        onChange(_value, code);
    };

    /**
     * Change data in table
     * @param {string} value
     * @param {string} code
     */
    const onChangeDataInTable = (_value, code) => {
        onChange(!_value, code);
    };

    /**
     * Hàm show input theo component type
     */
    const showComponentType = () => {
        let xhtml = null;

        switch (componentType) {
            case 'textBox': {
                xhtml = (
                    <div className={`${colClass} col-sm-6`}>
                        <div className="form-group">
                            <label>
                                <h6 className="panel-title">{item.p_name}</h6>
                            </label>
                            <input
                                value={item.ps_value}
                                className="form-control"
                                onChange={(e) => onChangeData(e, item.ps_code)}
                                type={dataType}
                            />
                            <div className="contain-error">{}</div>
                        </div>
                    </div>
                );
                break;
            }
            case 'selectBox': {
                xhtml = (
                    <div className={`${colClass} col-sm-6`}>
                        <div className="form-group">
                            <label>
                                <h6 className="panel-title">{item.p_name}</h6>
                            </label>
                            <Select
                                value={+value || null}
                                onChange={(e) =>
                                    onChangeSelectData(e, item.ps_code)
                                }
                                placeholder="Vui lòng chọn"
                            >
                                {listSelect.map((elm) => (
                                    <Select.Option
                                        value={elm[id]}
                                        key={elm[id]}
                                    >
                                        {elm[name]}
                                    </Select.Option>
                                ))}
                            </Select>
                            <div className="contain-error">{}</div>
                        </div>
                    </div>
                );
                break;
            }
            case 'tableBox': {
                xhtml = (
                    <tr>
                        <td className="text-center">{item?.sortOrder}</td>
                        <td data-label="Tên báo caos">{item?.p_name}</td>
                        <td data-label="Tên phân hệ">{item?.category}</td>
                        <td className="text-center">
                            <Checkbox
                                checked={!!item?.ps_value}
                                onChange={() =>
                                    onChangeDataInTable(
                                        item.ps_value,
                                        item.ps_code
                                    )
                                }
                            />
                            {/* <label>
                                <div className="checker">
                                    <span
                                        className={
                                            item?.ps_value ? 'checked' : ''
                                        }
                                    >
                                        <input
                                            type="checkbox"
                                            className="styled"
                                            onChange={() =>
                                                onChangeDataInTable(
                                                    item.ps_value,
                                                    item.ps_code
                                                )
                                            }
                                        />
                                    </span>
                                </div>
                            </label> */}
                        </td>
                    </tr>
                );
                break;
            }
            default:
                break;
        }

        return xhtml;
    };
    // componentType === 'textBox' ? (
    //     <input
    //         value={item.ps_value}
    //         className="form-control"
    //         onChange={(e) => onChangeData(e, item.ps_code)}
    //         type={dataType}
    //     />
    // ) : (
    //     <Select
    //         value={parseInt(value, 0)}
    //         onChange={(e) => onChangeSelectData(e, item.ps_code)}
    //     >
    //         {listSelect.map((elm) => (
    //             <Select.Option value={elm[id]} key={elm[id]}>
    //                 {elm[name]}
    //             </Select.Option>
    //         ))}
    //     </Select>
    // );

    return showComponentType();
};

SchoolSettingItem.propTypes = {
    colClass: PropTypes.string,
    dataType: PropTypes.string,
    onChange: PropTypes.func,
};

export default SchoolSettingItem;
