import React from 'react';
import { formatDateUnix } from '../../../utils/datetime.utils';
import { Switch } from 'antd';
import PropTypes from 'prop-types';
import ButtonTableAction from '@app/components/UI/Button/ButtonTableAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

const propTypes = {
    item: PropTypes.shape({
        e_lastname: PropTypes.string,
        e_firstname: PropTypes.string,
        e_gender: PropTypes.number,
        e_cellphone: PropTypes.string,
        e_email: PropTypes.string,
    }),
    onEdit: PropTypes.func,
    onResetPassword: PropTypes.func,
    onDelete: PropTypes.func,
};
const AccountItem = ({
    item,
    sortOrder,
    onEdit,
    onDelete,
    onUpdateStatus,
    onResetPassword,
}) => (
    <tr>
        <td className="text-center">{sortOrder + 1}</td>
        <td>
            {`${item.su_firstname} ${item.su_lastname}`}
            <br />
            <span className="text-muted">{item.sr_name}</span>
        </td>
        <td className="text-center">
            {formatDateUnix(item.su_dateofbirth)}
            <br />
            <span className="text-muted">
                {item.su_gender === 1 ? 'Nam' : 'Nữ'}
            </span>
        </td>
        <td>
            {item.su_email}
            <br />
            <span className="text-muted">{item.su_cellphone}</span>
        </td>
        <td>{item.su_username}</td>
        <td className="text-center">
            {formatDateUnix(item.su_lastlogin, 'DD/MM/YYYY HH:mm:ss')}
        </td>
        <td className="text-center" data-label="Đang hoạt động">
            <Switch
                checked={item.su_status}
                onChange={(value) => onUpdateStatus(value, item)}
            />
        </td>
        <td className="text-center">
            <div className="btn-table-group">
                <ButtonTableAction
                    buttonType={BUTTON_ACTION_TYPE.PASSWORD}
                    onClick={() => onResetPassword(item)}
                />
                <ButtonTableAction
                    buttonType={BUTTON_ACTION_TYPE.EDIT}
                    onClick={() => onEdit(item)}
                />
                <ButtonTableAction
                    buttonType={BUTTON_ACTION_TYPE.DELETE}
                    onClick={() => onDelete(item)}
                />
            </div>
            {/* <ul className="icons-list">
                <li className="dropdown">
                    <a
                        href="/"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                    >
                        <i className="icon-menu9" />
                    </a>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                            <span
                                className="ref-a"
                                role="button"
                                tabIndex={0}
                                onClick={() => onEdit(item)}
                            >
                                <i className="icon-pencil7" />
                                Chỉnh sửa thông tin
                            </span>
                        </li>
                        <li>
                            <span
                                className="ref-a"
                                role="button"
                                tabIndex={0}
                                onClick={() => onResetPassword(item)}
                            >
                                <i className="icon-pencil7" />
                                Thiết lập lại mật khẩu
                            </span>
                        </li>
                        <li>
                            <span
                                className="ref-a"
                                role="button"
                                tabIndex={0}
                                onClick={() => onDelete(item)}
                            >
                                <i className="glyphicon glyphicon-trash" />
                                Xóa
                            </span>
                        </li>
                    </ul>
                </li>
            </ul> */}
        </td>
    </tr>
);

AccountItem.propTypes = propTypes;

export default AccountItem;
