/**
 * Loại địa phương
 */
export const LOCATION_TYPES = {
    /**
     * Đất nước
     */
    COUNTRY: 1,

    /**
     * Thành phố/tỉnh
     */
    CITY: 2,

    /**
     * Quận/huyện
     */
    DISTRICT: 3,

    /**
     * Phường xã
     */
    WARD: 4,
};

export const IMAGE_FOLDER_UPLOAD = {
    USER: 'scService/userAvatar',
    ORGANIZATION: 'scService/organization',
    PRODUCT: 'scService/product',
    SHOP: 'scService/shop',
    // FILE
    SUPPORTTICKET: 'scService/supportTicket',
    CONTRACTVOICECLOUD: 'scService/contractVoiceCloud',
    // Blog
    BLOG: 'scService/blogImage',
};

export const IMAGE_SERVER_URL = 'http://localhost:9004/images';
export const IMAGE_SERVER_SERVICE_URL = 'http://api.sc.edu.vn/images';
export const CDN_IMAGE_URL = 'https://cdn.sc.edu.vn/images';
export const FILE_SERVER_URL = 'http://localhost:9004/files';

export const ACTION_API_TYPES = {
    GET: 1,
    ADD: 2,
    EDIT: 3,
    DELETE: 4,
    CANCEL: 5,
    EXTEND: 6,
};

export const SWEET_ALERT_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    QUESTION: 'question',
};

export const STATUS_PROCESS = {
    NOTHING: 0,
    AWAITING: 1,
    PROCESSING: 2,
    DONE: 3,
    ERROR: 4,
    WARNING: 5,
};

export const REPORT_TYPE = {
    THUC_DON_TRONG_NGAY: 1,
    PHIEU_KE_CHO: 2,
    PHIEU_TIEP_PHAM: 3,
    SO_KIEM_THUC: 4,
    BANG_TONG_HOP_KHAU_PHAN_DINH_DUONG_TUAN: 5,
    SO_TINH_TIEN_CHO: 6,
    PHIEU_NHAP_KHO: 7,
    PHIEU_XUAT_KHO: 8,
    THE_KHO: 9,
    TONG_HOP_THE_KHO: 10,
    SO_QUYET_TOAN: 11,
    BANG_DIEM_DANH: 12,
    TONG_HOP_DIEM_DANH: 13,
    PHIEU_BAO_DONG_TIEN: 14,
    BIEN_LAI_THU_TIEN: 15,
    SO_THU_THANH_TOAN_TONG_HOP: 16,
    TONG_HOP_THU_CHI_TIEN_AN: 17,
    SO_QUY_TIEN_AN: 18,
    HOA_DON_BAN_HANG: 19,
    THUC_DON_TUAN: 20,
    TONG_HOP_SO_QUY_TIEN_AN: 21,
    BANG_CONG_KHAI_TAI_CHINH: 22,
};
