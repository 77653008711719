import * as types from './warehouse.constant';

export const openWarehouseModal = () => ({
    type: types.OPEN_WAREHOUSE_MODAL,
});

export const closeWarehouseModal = () => ({
    type: types.CLOSE_WAREHOUSE_MODAL,
});

export const changeWarehouse = (id) => ({
    type: types.CHANGE_WAREHOUSE,
    payload: {
        id,
    },
});

export const getWarehouse = (id) => ({
    type: types.GET_WAREHOUSE,
    payload: {
        id,
    },
});

export const getWarehouseSuccess = (data) => ({
    type: types.GET_WAREHOUSE_SUCCESS,
    payload: {
        data,
    },
});
