const validate = (values) => {
    const errors = {};
    const { su_lastname, su_firstname, su_email, su_homephone, su_cellphone } =
        values;

    if (!su_lastname) {
        errors.su_lastname = 'Họ không được để trống !';
    }

    if (!su_firstname) {
        errors.su_firstname = 'Tên không được để trống !';
    }

    if (!su_email) {
        errors.su_email = 'Email không được để trống !';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(su_email)) {
        errors.su_email = 'Email không đúng định dạng !';
    }
    if (su_homephone) {
        if (!/^[0-9]*$/i.test(su_homephone)) {
            errors.su_homephone = 'Số điện thoại không đúng định dạng !';
        }
    }

    if (su_cellphone) {
        if (!/^[0-9]*$/i.test(su_cellphone)) {
            errors.su_cellphone = 'Số điện thoại không đúng định dạng !';
        }
    }

    return errors;
};

export default validate;
