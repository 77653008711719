import { _receiptVoucherUtils } from '@app/utils/income/receiptVoucher.utils';
import _globalUtils from '@app/utils/global.utils';
import moment from 'moment';
import _ from 'lodash';
import _numberUtils from '@app/utils/number.utils';

export const _receiptPrintReportUtils = {
    /**
     * Merge dữ liệu để in báo cáo cho phiếu thu + biên lai
     * @param {Array} receiptList: Danh sách phiếu thu kèm theo chi tiết + chi tiết hóa đơn
     * @param {Array} studentList: Danh sách học sinh
     * @param {Array} costClassList : Danh sách các khoản thu
     * @param {object} info : Thông tin hiển thị khi mở popup in
     * @param {object} classItem : Lớp học hiện tại
     * @returns
     */
    doMergeDataReport: (
        receiptList,
        studentList,
        costClassList,
        info,
        classItem
    ) => {
        const { numberToPrice, convertNumberToWord } = _numberUtils;
        const listRes = receiptList.map((o) => {
            const student = studentList.find((elm) => elm.s_id === o.r_linksid);
            const quantityRefund =
                _receiptVoucherUtils.getTotalQuantityRefund(o);

            const totalRefund = _receiptVoucherUtils.getTotalMoneyRefund(o);

            const totalAttance =
                _receiptVoucherUtils.getTotalQuantityMealList(o);

            const datePayment =
                o.r_datepayment > 0
                    ? moment.unix(o.r_datepayment)._d.getDate()
                    : '...';
            const monthPayment =
                o.r_monthpayment > 0 ? o.r_monthpayment : o.r_month;

            const yearPayment =
                o.r_yearpayment > 0 ? o.r_yearpayment : o.r_year;

            const totalInvoice = _.sumBy(o?.detailArr, 'rd_total') || 0;

            // Dữ liệu hóa đơn, biên lai(các phiếu đã thu tiền)
            let receiptDetail = {
                datePayment,
                monthPayment,
                yearPayment,
                invoiceCode:
                    o.listInvoiceArr?.length > 0
                        ? o.listInvoiceArr[0].i_code
                        : '',
                totalInvoice: numberToPrice(totalInvoice),
                totalInvoiceString:
                    convertNumberToWord(totalInvoice).Capitalize(),
            };

            if (o?.detailArr?.length > 0) {
                _.orderBy(o.detailArr, ['rd_sortorder'], ['asc'])
                    .filter((elm) => elm.rd_price > 0)
                    .forEach((elm, index) => {
                        const _no = _globalUtils.padLeft(index + 1, 2, '0');
                        const _cost = costClassList.find(
                            (costElm) => costElm.cl_id === elm.cl_id
                        );
                        let _costDetail = '';

                        if (elm.rd_quantity > 1) {
                            _costDetail = `${elm.rd_quantity} x ${numberToPrice(
                                elm.rd_price
                            )}`;
                        }
                        if (elm.rd_quantityrefunded > 0) {
                            _costDetail = `(${elm.rd_quantity}-${
                                elm.rd_quantityrefunded
                            }) x ${numberToPrice(elm.rd_price)}`;
                        }
                        const _costPrice =
                            elm.rd_price -
                            elm.rd_pricediscountpolicy -
                            elm.rd_pricepromotion;

                        receiptDetail = {
                            ...receiptDetail,
                            [`costName${_no}`]: elm.rd_costname,
                            [`costDetail${_no}`]: _costDetail,
                            [`costTotal${_no}`]: numberToPrice(elm.rd_total),
                            [`costPrice${_no}`]: numberToPrice(_costPrice),
                            [`costQuantity${_no}`]: elm.rd_quantity,
                            [`costCurency${_no}`]: 'đồng',
                            [`calculatedName${_no}`]: _cost?.calculatedname,
                            [`index${_no}`]: index + 1,
                        };
                    });
            }

            return {
                ...info,
                ...receiptDetail,
                studentName: student?.s_fullname,
                quantityRefund,
                totalRefund,
                totalAttance,
                className: classItem.c_name,
                receiptCode: o.r_code,
                totalAmount: numberToPrice(o.r_total),
                totalAmountString: convertNumberToWord(o.r_total).Capitalize(),
                year: o.r_year,
                month: o.r_month,
                sortOrder: student.sc_sortorder,
                receiptId: o.r_id,
            };
        });

        return _.orderBy(listRes, ['sortOrder'], ['asc']);
    },
};
