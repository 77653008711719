/* eslint-disable no-continue */
/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import _icAttandanceApis from '@app/apis/income/attendance.api';
import _icStatisticalApis from '@app/apis/income/incomStatistical.api';
import _icReceiptApis from '@app/apis/income/receipt.api';
import _icInvoiceApis from '@app/apis/income/invoice.api';

import _icCostApis from '@app/apis/income/costList.api';
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants';
import {
    COST_CALCULATED_TYPE,
    RECEIPT_COST_SETTING,
    RECEIPT_VOUCHER_TYPE,
} from '@app/constants/receiptCost.CONST';
import _globalUtils from '@app/utils/global.utils';
import _ from 'lodash';
import { _receiptPrintReportUtils } from './_utils';
import { formatDateUnix, getTrueYearValue } from '@app/utils/datetime.utils';
import { _costTotalReportUtils } from '@app/utils/income/costTotalReport.utils';
import { _incomeReportByMoneyForMealUtils } from '@app/utils/income/reportByMoneyForMeal';
import { numberToPrice } from '@app/utils/utils';
import moment from 'moment';
import { _incomeMoneyForMealCashBookReportUtils } from '@app/utils/income/reportMoneyForMealCashBook';
import reportUtils from '@app/utils/reportUtils';
import { GLOBAL } from '@app/constants/global.const';

class _incomeExportPDFService {
    /**
     * Merge dữ liệu cho bảng điểm danh
     * @param {string} o_id : Id tổ chức/trường
     * @param {number} yearId : id năm học
     * @param {number} month : tháng điểm danh
     * @param {Array} classList : danh sách lớp học
     * @param {Array} studentList : danh sách học sinh
     * @param {object} locationInfo : thông tin địa điểm
     * @param {object} organizationInfo : thông tin tổ chức/trường chung
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {boolean} isEmpty : Có phải bảng điểm danh trống hay không
     */
    doExportBangDiemDanh = async (
        o_id,
        yearId,
        month,
        classList,
        studentList,
        locationInfo,
        organizationInfo,
        schoolInfo,
        isEmpty
    ) => {
        if (classList?.length === 0) {
            return true;
        }

        for (const classItem of classList) {
            const { status, data } = await _icAttandanceApis.getList({
                oId: o_id,
                yearId,
                classId: classItem.c_id,
                month,
            });
            if (status !== 200) {
                continue;
            }
            // eslint-disable-next-line prefer-const
            let { listAS, trueYear } = data.data;
            if (listAS?.length === 0) {
                continue;
            }
            listAS = listAS.map((asItem) => {
                const studentItem = studentList.find(
                    (o) => o.sc_id === asItem.sc_id
                );
                const percent = _globalUtils.takeDecimalNumber(
                    (asItem?.as_attendancedays /
                        (asItem?.as_absencedays + asItem?.as_attendancedays)) *
                        100,
                    2
                );

                asItem = {
                    ...asItem,
                    s_fullname: studentItem?.s_fullname,
                    sort_order: studentItem?.sc_sortorder,
                    percent,
                };
                return asItem;
            });
            listAS = _.orderBy(listAS, ['sort_order'], ['asc']).map((o) => {
                for (let i = 1; i <= 31; i++) {
                    if (
                        o[`as_day${String(i).padStart(2, '0')}`] ===
                            'day_off' ||
                        o[`as_day${String(i).padStart(2, '0')}`] === 'not_use'
                    ) {
                        o[`as_day${String(i).padStart(2, '0')}`] = '';
                    }
                }
                return o;
            });
            const dayInMonth = new Date(trueYear, +month, 0).getDate();
            let attendanceSum = {};
            for (let i = 1; i <= dayInMonth; i++) {
                attendanceSum = {
                    ...attendanceSum,
                    [`sum_attendance${_globalUtils.padLeft(i, 2, '0')}`]:
                        _.sumBy(
                            listAS,
                            (o) =>
                                o[
                                    `as_day${_globalUtils.padLeft(i, 2, '0')}`
                                ] === '+'
                        ),
                    [`sum_absent${_globalUtils.padLeft(i, 2, '0')}`]: _.sumBy(
                        listAS,
                        (o) =>
                            o[`as_day${_globalUtils.padLeft(i, 2, '0')}`] ===
                                'P' ||
                            o[`as_day${_globalUtils.padLeft(i, 2, '0')}`] ===
                                'KP'
                    ),
                };
            }

            let attendanceTotal = _.sumBy(listAS, (o) => o.as_attendancedays);
            let absentTotal = _.sumBy(listAS, (o) => o.as_absencedays);
            let percentTotal = _globalUtils.takeDecimalNumber(
                (attendanceTotal / (absentTotal + attendanceTotal)) * 100,
                2
            );

            let prefixFileName = 'BangDiemDanh';
            // Danh sách bảng điểm danh trống
            if (isEmpty) {
                listAS = listAS.map((o) => ({
                    s_fullname: o.s_fullname,
                    sort_order: o.sc_sortorder,
                }));
                attendanceSum = {};
                attendanceTotal = '';
                absentTotal = '';
                percentTotal = '';
                prefixFileName = 'BangDiemDanhTrong';
            }

            const info = {
                ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
                year: +trueYear,
                month: +month,
                class_name: classItem.c_name,
                ...attendanceSum,
                attendanceTotal,
                absentTotal,
                percentTotal,
                report_title: 'BẢNG ĐIỂM DANH',
                // city_name: locationInfo.cityName,
                // district_name: locationInfo.districtName,
                // ward_name: locationInfo.wardName,
                school_name: schoolInfo?.ten_truong,
                printcipalName: schoolInfo.ho_ten_hieu_truong,
                printcipalTitle:
                    schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                    GLOBAL.REPORT_PRINCIPAL_TITLE,
            };

            const res = await exportReportToPDF({
                data: JSON.stringify({ info, listData: listAS }),
                report: REPORT_TYPE.BANG_DIEM_DANH,
            });

            if (res.status !== 200) {
                continue;
            }

            _globalUtils.downloadPDF(
                res.data,
                `${prefixFileName}_${_globalUtils.padLeft(
                    month,
                    2,
                    '0'
                )}${trueYear}_${classItem.c_name}_${organizationInfo.o_name}`
            );
        }

        return true;
    };

    /**
     * Xuất PDF bảng điểm danh tổng hợp
     * @param {string} o_id : Id tổ chức/ trường
     * @param {number} yearId : id năm học
     * @param {number} month : tháng điểm danh
     * @param {Array} classList : danh sách lớp
     * @param {Object} locationInfo: thông tin địa điểm
     * @param {Object} organizationInfo : thông tin tổ chức
     * @param {Object} schoolInfo : thông tin chi tiết trường học
     * @returns
     */
    doExportTongHopBangDiemDanh = async (
        o_id,
        yearId,
        month,
        classList,
        locationInfo,
        organizationInfo,
        schoolInfo
    ) => {
        const listClassId = classList.map((o) => o.c_id);
        const { status, data } = await _icAttandanceApis.getSummary({
            yearId,
            month,
            listClassId,
            oId: o_id,
        });
        if (status !== 200) {
            return true;
        }

        const list = data.data.list;
        const listMap = list.map((o, index) => {
            const objClass = classList.find((item) => item.c_id === o.c_id);
            let objDayAttendance = {};
            if (o.daySummaryAttendance?.length > 0) {
                o.daySummaryAttendance.forEach((elm) => {
                    objDayAttendance = {
                        ...objDayAttendance,
                        [`attendanceDay${elm.day}`]: elm.attendanceNumber,
                        [`absentDay${elm.day}`]: elm.absentNumber,
                    };
                });

                // Tổng có mặt theo lớp
                const _sumAtByClass = _.sumBy(
                    o.daySummaryAttendance,
                    (item) => item.attendanceNumber
                );

                // Tổng vắng mặt theo lớp
                const _sumAbByClass = _.sumBy(
                    o.daySummaryAttendance,
                    (item) => item.absentNumber
                );

                // Tổng tỉ lệ theo lớp
                const percentByClass = _globalUtils.takeDecimalNumber(
                    (_sumAtByClass / (_sumAtByClass + _sumAbByClass)) * 100,
                    2
                );

                objDayAttendance = {
                    ...objDayAttendance,
                    sumAttendanceClass: _sumAtByClass,
                    sumAbsentClass: _sumAbByClass,
                    percentClass: percentByClass,
                    sortOrder: index + 1,
                };
            }
            return {
                className: objClass?.c_name,
                ...objDayAttendance,
            };
        });

        // Đối tượng lưu tổng số nghỉ + có mặt trong 1 ngày
        let itemSum = {};
        for (let i = 1; i <= 31; i++) {
            itemSum = {
                ...itemSum,
                [`sumAttendanceDay${i}`]: _.sumBy(
                    listMap,
                    (o) => o[`attendanceDay${i}`]
                ),
                [`sumAbsentDay${i}`]: _.sumBy(
                    listMap,
                    (o) => o[`absentDay${i}`]
                ),
            };
        }

        // Danh sách merge các tổng ngày vắng + có mặt theo lớp
        let _listSumAttance = [];
        list.forEach((o) => {
            _listSumAttance = [..._listSumAttance, ...o.daySummaryAttendance];
        });

        // Tổng có mặt
        const attendanceTotal = _.sumBy(
            _listSumAttance,
            (o) => o.attendanceNumber
        );

        // Tổng vắng mặt
        const absentTotal = _.sumBy(_listSumAttance, (o) => o.absentNumber);

        // Tổng tỉ lệ
        const percentTotal = _globalUtils.takeDecimalNumber(
            (attendanceTotal / (absentTotal + attendanceTotal)) * 100,
            2
        );

        itemSum = {
            ...itemSum,
            attendanceTotal,
            absentTotal,
            percentTotal,
        };

        const info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            // cityName: locationInfo?.cityName,
            // districtName: locationInfo?.districtName,
            // wardName: locationInfo?.wardName,
            schoolName: schoolInfo?.ten_truong,
            reportTitle: 'BẢNG ĐIỂM DANH TỔNG HỢP TOÀN TRƯỜNG',
            printcipalName: schoolInfo.ho_ten_hieu_truong,
            printcipalTitle:
                schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_PRINCIPAL_TITLE,
            month,
            year: data.data.trueYear,
            reportType: 1,
            ...itemSum,
        };

        const res = await exportReportToPDF({
            data: JSON.stringify({ info, listData: listMap }),
            report: REPORT_TYPE.TONG_HOP_DIEM_DANH,
        });
        const prefixFileName = 'BangDiemDanhTongHop';
        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `${prefixFileName}_${_globalUtils.padLeft(month, 2, '0')}${
                    data.data.trueYear
                }_${organizationInfo?.o_name}`
            );
        }

        return true;
    };

    doExportPhieuBaoDongTien = async (
        o_id,
        yearId,
        month,
        classList,
        studentList,
        locationInfo,
        organizationInfo,
        schoolInfo,
        isInvoice = false
    ) => {
        const info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            // cityName: locationInfo?.cityName,
            // districtName: locationInfo?.districtName,
            // wardName: locationInfo?.wardName,
            schoolName: schoolInfo?.ten_truong,
            cashierName: schoolInfo?.ho_ten_thu_quy,
            accountantName: schoolInfo?.ho_ten_ke_toan,
            datePaymentContent: schoolInfo?.ngay_nop_tien,
            dateSendReceiptContent: schoolInfo?.ngay_gui_phieu_thu,
            paymentContent: schoolInfo?.noi_dung_thong_bao_nop_tien,
            address: schoolInfo?.dia_chi,
            phone: schoolInfo?.dien_thoai,
            bankName: schoolInfo?.ten_ngan_hang,
            bankAccountName: schoolInfo?.ten_tai_khoan,
            bankAccount: schoolInfo?.so_tai_khoan,
            bankAddress: schoolInfo?.pgd_gan_nhat,
            maQHNS: schoolInfo?.ma_qhns,
        };
        for (const classItem of classList) {
            const { status, data } = await _icReceiptApis.getList({
                oId: o_id,
                yearId,
                classId: classItem.c_id,
                month,
            });
            if (status !== 200 || !data.data.listReceipt?.length) {
                continue;
            }

            let receiptList = data.data.listReceipt;
            const costClassList = data.data.costListByClass;

            /**
             * Nếu là in biên lai
             */
            if (isInvoice) {
                // Danh sách các khoản thu hiển thị trong biên lai
                const listCostInvoice = costClassList.filter((cElm) =>
                    cElm.listDetail.find(
                        (elm) =>
                            elm.cs_id ===
                                RECEIPT_COST_SETTING.SHOW_ON_INVOICES &&
                            // eslint-disable-next-line eqeqeq
                            elm.cd_value == 1
                    )
                );

                const listCostInvoiceId = listCostInvoice.map((o) => o.cl_id);

                receiptList = receiptList.map((iEml) => {
                    const _listShow = iEml?.detailArr.filter(
                        (elm) =>
                            _.includes(listCostInvoiceId, elm.cl_id) ||
                            elm.cl_calculatedtype ===
                                COST_CALCULATED_TYPE.THU_TIEN_TRON_GOI
                    );

                    return {
                        ...iEml,
                        detailArr: _.orderBy(
                            _listShow,
                            ['rd_sortorder'],
                            ['asc']
                        ),
                    };
                });
            }
            let listPrint = _receiptPrintReportUtils.doMergeDataReport(
                receiptList,
                studentList,
                costClassList,
                info,
                classItem
            );
            listPrint = listPrint.map((o, index) => ({
                ...o,
                sortOrder: index + 1,
            }));

            let res = {};
            let prefixFileName = 'PhieuBaoDongTien';
            if (isInvoice) {
                prefixFileName = 'BienLaiThuTien';
                res = await exportReportToPDF({
                    data: JSON.stringify({ listData: listPrint }),
                    report: REPORT_TYPE.BIEN_LAI_THU_TIEN,
                });
            } else {
                res = await exportReportToPDF({
                    data: JSON.stringify({ listData: listPrint }),
                    report: REPORT_TYPE.PHIEU_BAO_DONG_TIEN,
                });
            }

            if (res.status === 200) {
                _globalUtils.downloadPDF(
                    res.data,
                    `${prefixFileName}_${_globalUtils.padLeft(month, 2, '0')}${
                        listPrint[0].year
                    }_${classItem.c_name}_${info.schoolName}`
                );
            }
        }
        return true;
    };

    doExportSoThuThanhToanTongHop = async (
        oId,
        yearId,
        month,
        classList,
        studentList,
        locationInfo,
        schoolInfo,
        displayColumn
    ) => {
        let s = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            tenTruong: schoolInfo?.ten_truong,
            tenKeToan: schoolInfo?.ho_ten_ke_toan,
            tenHieuTruong: schoolInfo?.ho_ten_hieu_truong,
            tieuDeHieuTruong:
                schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_PRINCIPAL_TITLE,
            tenThuQuy: schoolInfo?.ho_ten_thu_quy,
            diaChi: schoolInfo?.dia_chi,
            soDienThoai: schoolInfo?.dien_thoai,
            tenNganHang: schoolInfo?.ten_ngan_hang,
            tenTaiKhoan: schoolInfo?.ten_tai_khoan,
            soTaiKhoan: schoolInfo?.so_tai_khoan,
            diaChiNganHang: schoolInfo?.pgd_gan_nhat,
            maQHNS: schoolInfo?.ma_qhns,
            cotHienThi: displayColumn,
            cacDongTruyThu: 1,
            cacKhoanChuaThu: 1,
            sttTangTuDong: 1,
            hienThiThuQuy: 0,
            soDongTrong: 0,
            tenBaoCao: 'SỔ THU THANH TOÁN TỔNG HỢP',
        };

        for (const classItem of classList) {
            const { status, data } =
                await _icStatisticalApis.laySoThuThanhToanTongHop({
                    oId,
                    yearId,
                    classId: classItem.c_id,
                    month,
                    paymentStatus: -1,
                });
            if (status !== 200) {
                continue;
            }
            const { costListByClass, listReceipt: list } = data.data;

            if (list.length === 0) {
                continue;
            }

            let invoiceList = [];
            let listInvoiceId = [];
            list.forEach((elm) => {
                const arrInvoice = elm.r_invoices
                    .split(',')
                    .filter((item) => item);
                listInvoiceId = [...listInvoiceId, ...arrInvoice];
            });
            const invRes = await _icInvoiceApis.getByListId({
                oId,
                listInvoiceId,
            });
            if (invRes.status === 200) {
                invoiceList = invRes.data.data;
            }

            const listDetail = _costTotalReportUtils
                .getAllDetail(list)
                .filter((o) => o.rd_type == RECEIPT_VOUCHER_TYPE.PHIEU_THU);
            let costList = costListByClass.filter((item) => {
                const sumTotal = _.sumBy(
                    listDetail.filter((o) => o.cl_id === item.cl_id),
                    'rd_total'
                );
                return sumTotal !== 0;
            });

            costList = _.orderBy(costList, ['sortOrder'], ['asc']).map(
                (o, index) => ({
                    ...o,
                    sortOrder: index + 1,
                })
            );

            for (let i = 0; i < costList.length; i++) {
                const listDetailByCost = listDetail.filter(
                    // eslint-disable-next-line no-loop-func
                    (o) => o.cl_id === costList[i].cl_id
                );
                s = {
                    ...s,
                    [`tenChiPhi${costList[i].sortOrder}`]: costList[i].costname,
                    [`tongChiPhi${costList[i].sortOrder}`]: numberToPrice(
                        _.sumBy(listDetailByCost, 'rd_total')
                    ),
                };
            }
            s = {
                ...s,
                tongThu: numberToPrice(_.sumBy(listDetail, 'rd_total')),
            };

            let listPrint = _.orderBy(
                list.map((item) => {
                    let itemDetailList = item.detailArr;
                    if (itemDetailList?.length > 0) {
                        if (s.cacKhoanChuaThu !== 1) {
                            itemDetailList = itemDetailList.filter(
                                (o) => o.rd_status === 2
                            );
                        }
                        itemDetailList.forEach((elm) => {
                            const sumTotal = _.sumBy(
                                listDetail.filter((o) => o.cl_id === elm.cl_id),
                                'rd_total'
                            );
                            const _costItem = costList.find(
                                (cItem) => cItem.cl_id === elm.cl_id
                            );

                            if (sumTotal != 0) {
                                item = {
                                    ...item,
                                    [`tienChiPhi${_costItem.sortOrder}`]:
                                        numberToPrice(elm.rd_total),
                                };
                            }
                        });
                    }
                    // Lấy thông tin mã biên lai
                    // if(item.)
                    const invoiceItem = invoiceList.find(
                        (o) => o.r_id === item.r_id
                    );
                    const student = studentList.find(
                        (o) => o.s_id == item.r_linksid
                    );

                    const maBienLai =
                        displayColumn === 'soBienLai'
                            ? invoiceItem?.i_code
                            : '';

                    const tongThu = _.sumBy(itemDetailList, 'rd_total');

                    item = {
                        ...item,
                        ngayThuTien:
                            item.r_datepayment > 0
                                ? formatDateUnix(item.r_datepayment)
                                : '',
                        ten: student?.s_firstname,
                        ho: student?.s_lastname,
                        hoTenHocSinh: `${student?.s_lastname} ${student?.s_firstname}`,
                        stt: student.sc_sortorder,
                        tongThu: tongThu > 0 ? numberToPrice(tongThu) : '',
                        ngayVangThangTruoc:
                            _costTotalReportUtils.getAbsentPrevMonthByMonth(
                                item,
                                month
                            ),
                        tienTruThangTruoc:
                            _costTotalReportUtils.getMoneyAbsentPrevMonthByMonth(
                                item,
                                month
                            ),
                        ngayHocThangTruoc:
                            _costTotalReportUtils.getAttancePrevMonthByMonth(
                                item,
                                month
                            ),
                        tienChiThangTruoc:
                            _costTotalReportUtils.getMoneyPrevMonthByMonth(
                                item,
                                month
                            ),
                        maBienLai,
                    };
                    return item;
                }),
                ['stt'],
                ['asc']
            );

            s = {
                ...s,
                thang: list[0]?.r_month,
                nam: list[0]?.r_year,
                tongTienChiThangTruoc: numberToPrice(
                    _.sumBy(listPrint, 'tienChiThangTruoc')
                ),
                tongTienTruThangTruoc: numberToPrice(
                    _.sumBy(listPrint, 'tienTruThangTruoc')
                ),
                tenLop: classItem?.c_name,
            };

            listPrint = listPrint.map((o) => ({
                ...o,
                tienTruThangTruoc: numberToPrice(o.tienTruThangTruoc),
                tienChiThangTruoc: numberToPrice(o.tienChiThangTruoc),
            }));

            listPrint = listPrint.map((o, index) => ({
                ...o,
                stt: index + 1,
            }));

            const prefixFileName = 'SoThuThanhToanTongHop';

            const res = await exportReportToPDF({
                data: JSON.stringify({
                    info: s,
                    listData: listPrint,
                }),
                report: REPORT_TYPE.SO_THU_THANH_TOAN_TONG_HOP,
            });

            if (res.status === 200) {
                _globalUtils.downloadPDF(
                    res.data,
                    `${prefixFileName}_${_globalUtils.padLeft(month, 2, '0')}${
                        s.nam
                    }_${s.tenTruong}_${
                        displayColumn === 'soBienLai' ? 'SBL' : 'PHHS'
                    }`
                );
            }
        }

        return true;
    };

    doExportThuChiTienAn = async (
        oId,
        yearId,
        month,
        classList,
        locationInfo,
        schoolInfo
    ) => {
        const infoBase = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            tenTruong: schoolInfo?.ten_truong,
            tenKeToan: schoolInfo?.ho_ten_ke_toan,
            tenHieuTruong: schoolInfo?.ho_ten_hieu_truong,
            tieuDeHieuPho:
                schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_ASSISTANT_TITLE,
            tenThuQuy: schoolInfo?.ho_ten_thu_quy,
            hienThiThuQuy: 0,
            tenBaoCao: 'TỔNG HỢP THU CHI',
        };
        const thangChuyenLop = schoolInfo.thang_chuyen_lop;
        const trueYear = getTrueYearValue(yearId, month, thangChuyenLop);
        const { status, data } = await _icCostApis.getListInMonth({
            oId,
            year: trueYear,
            sy_id: yearId,
            month,
            isAll: false,
        });

        const costList = data?.data ?? [];

        if (status !== 200 || costList?.length === 0) {
            return true;
        }

        for (const elem of costList) {
            const reportRes = await _icStatisticalApis.layTongHopThuChiTienAn({
                oId,
                sy_id: yearId,
                year: trueYear,
                month,
                cl_id: elem.cl_id,
            });
            if (reportRes.status !== 200) continue;

            const {
                listAttandance,
                listClassId,
                listCostDetail,
                listReceipt,
                listReceiptDetail,
                listRefund,
                listRefundDetail,
            } = reportRes.data.data;

            if (listReceiptDetail.length > 0) {
                let listPrint = _incomeReportByMoneyForMealUtils.getTableData({
                    listReceiptDetail,
                    listAttandance,
                    listRefundDetail,
                    listClassId,
                    listClass: classList,
                    month,
                    listReceipt,
                    listRefund,
                    listCostDetail,
                });

                listPrint = listPrint.map((o) => ({
                    stt: o.sortOrder,
                    tenLop: o.c_name,
                    tongSoSuat: o.totalCountReceiptInMonth,
                    soSuatThuaThangTruoc: o.countRefundPrevMonth,
                    tongTienThuaThangTruoc: o.moneyRefundPrevMonth,
                    soSuatThuTrongThang: o.countReceiptInMonth,
                    tongTienThuTrongThang: o.moneyReceiptInMonth,
                    tongCongThu: o.totalMoneyReceiptInMonth,
                    soSuatThatThu: o.countNotPaid,
                    tongTienThatThu: o.moneyNotPaid,
                    soSuatChi: o.countExpense,
                    tongTienChi: o.moneyExpense,
                    soSuatHoanTra: o.countRefundForMeal,
                    tongTienHoanTra: o.moneyRefundForMeal,
                    tongCongChi: o.totalExpenseMoney,
                    soSuatChuyenThangSau: o.countNextMonth,
                    tongTienChuyenThangSau: o.moneyNextMonth,
                }));

                const info = {
                    ...infoBase,
                    tenBaoCao: `${infoBase.tenBaoCao} ${elem.cl_name}`,
                    thang: month,
                    nam: trueYear,
                    sumTongSoSuat: numberToPrice(
                        _.sumBy(listPrint, 'tongSoSuat')
                    ),
                    sumSoSuatThuaThangTruoc: numberToPrice(
                        _.sumBy(listPrint, 'soSuatThuaThangTruoc')
                    ),
                    sumTongTienThuaThangTruoc: numberToPrice(
                        _.sumBy(listPrint, 'tongTienThuaThangTruoc')
                    ),
                    sumSoSuatThuTrongThang: numberToPrice(
                        _.sumBy(listPrint, 'soSuatThuTrongThang')
                    ),
                    sumTongTienThuTrongThang: numberToPrice(
                        _.sumBy(listPrint, 'tongTienThuTrongThang')
                    ),
                    sumTongCongThu: numberToPrice(
                        _.sumBy(listPrint, 'tongCongThu')
                    ),
                    sumSoSuatThatThu: numberToPrice(
                        _.sumBy(listPrint, 'soSuatThatThu')
                    ),
                    sumTongTienThatThu: numberToPrice(
                        _.sumBy(listPrint, 'tongTienThatThu')
                    ),
                    sumSoSuatChi: numberToPrice(
                        _.sumBy(listPrint, 'soSuatChi')
                    ),
                    sumTongTienChi: numberToPrice(
                        _.sumBy(listPrint, 'tongTienChi')
                    ),
                    sumSoSuatHoanTra: numberToPrice(
                        _.sumBy(listPrint, 'soSuatHoanTra')
                    ),
                    sumTongTienHoanTra: numberToPrice(
                        _.sumBy(listPrint, 'tongTienHoanTra')
                    ),
                    sumTongCongChi: numberToPrice(
                        _.sumBy(listPrint, 'tongCongChi')
                    ),
                    sumSoSuatChuyenThangSau: numberToPrice(
                        _.sumBy(listPrint, 'soSuatChuyenThangSau')
                    ),
                    sumTongTienChuyenThangSau: numberToPrice(
                        _.sumBy(listPrint, 'tongTienChuyenThangSau')
                    ),
                };

                listPrint = listPrint.map((o) => ({
                    ...o,
                    tongSoSuat: numberToPrice(o.tongSoSuat),
                    soSuatThuaThangTruoc: numberToPrice(o.soSuatThuaThangTruoc),
                    tongTienThuaThangTruoc: numberToPrice(
                        o.tongTienThuaThangTruoc
                    ),
                    soSuatThuTrongThang: numberToPrice(o.soSuatThuTrongThang),
                    tongTienThuTrongThang: numberToPrice(
                        o.tongTienThuTrongThang
                    ),
                    tongCongThu: numberToPrice(o.tongCongThu),
                    soSuatThatThu: numberToPrice(o.soSuatThatThu),
                    tongTienThatThu: numberToPrice(o.tongTienThatThu),
                    soSuatChi: numberToPrice(o.soSuatChi),
                    tongTienChi: numberToPrice(o.tongTienChi),
                    soSuatHoanTra: numberToPrice(o.soSuatHoanTra),
                    tongTienHoanTra: numberToPrice(o.tongTienHoanTra),
                    tongCongChi: numberToPrice(o.tongCongChi),
                    soSuatChuyenThangSau: numberToPrice(o.soSuatChuyenThangSau),
                    tongTienChuyenThangSau: numberToPrice(
                        o.tongTienChuyenThangSau
                    ),
                }));

                const fileName = `TongHopThuChiTienAn_${
                    elem.cl_name
                }_${_globalUtils.padLeft(month, 2, '0')}${info.nam}_${
                    info.tenTruong
                }`;

                const res = await exportReportToPDF({
                    data: JSON.stringify({
                        info,
                        listData: listPrint,
                    }),
                    report: REPORT_TYPE.TONG_HOP_THU_CHI_TIEN_AN,
                });

                if (res.status === 200) {
                    _globalUtils.downloadPDF(res.data, fileName);
                }
            }
        }

        return true;
    };

    doExportSoQuyTienAn = async (
        oId,
        yearId,
        month,
        locationInfo,
        schoolInfo
    ) => {
        let info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            tenTruong: schoolInfo?.ten_truong,
            tenKeToan: schoolInfo?.ho_ten_ke_toan,
            tenHieuTruong: schoolInfo?.ho_ten_hieu_truong,
            tieuDeHieuTruong:
                schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_PRINCIPAL_TITLE,
            tenThuQuy: schoolInfo?.ho_ten_thu_quy,
            tenBaoCao: 'SỔ QUỸ TIỀN ĂN',
        };

        const thangChuyenLop = schoolInfo.thang_chuyen_lop;
        const trueYear = getTrueYearValue(yearId, month, thangChuyenLop);
        const { status, data } = await _icCostApis.getListInMonth({
            oId,
            year: trueYear,
            sy_id: yearId,
            month,
            isAll: false,
        });

        const costList = data?.data ?? [];

        if (status !== 200 || costList?.length === 0) {
            return true;
        }

        for (const elem of costList) {
            const reportRes = await _icStatisticalApis.laySoQuyTienAn({
                oId,
                sy_id: yearId,
                year: trueYear,
                month,
                cl_id: elem.cl_id,
            });
            if (reportRes.status !== 200) {
                continue;
            }
            const { reportTotal, list } = reportRes.data.data;
            const startDate = moment(new Date(trueYear, month - 1, 1)).format(
                'DD/MM/YYYY'
            );

            const listReceipt = _.chain(list)
                .filter((o) => o.i_type === RECEIPT_VOUCHER_TYPE.PHIEU_THU)
                .map((o) => ({
                    ...o,
                    i_datefounded: moment
                        .unix(o.i_datefounded)
                        .startOf('D')
                        .unix(),
                }))
                .orderBy(['i_datefounded'], ['asc'])
                .groupBy('i_datefounded')
                .map((value, key) => ({
                    i_datefounded: key,
                    i_referencecode: '',
                    rd_total: _.sumBy(value, 'rd_total'),
                    i_type: RECEIPT_VOUCHER_TYPE.PHIEU_THU,
                    i_description: `Tổng thu tiền ăn ngày ${moment
                        .unix(key)
                        .format('DD/MM/YYYY')} - ${value.length} bé`,
                }))
                .value();

            const listPayment = list.filter(
                (o) => o.i_type === RECEIPT_VOUCHER_TYPE.PHIEU_CHI
            );

            const listMerge = _.orderBy(
                [...listReceipt, ...listPayment],
                ['i_datefounded'],
                ['asc']
            );

            const listPrint =
                _incomeMoneyForMealCashBookReportUtils.getTableData({
                    reportTotal,
                    listData: listMerge,
                    startDate,
                });

            info = {
                ...info,
                tenKhoanThu: elem?.cl_name,
                nam: trueYear,
                thang: month,
                tonCuoiKy:
                    listPrint?.length > 0
                        ? listPrint[listPrint.length - 1].tienTon
                        : 0,
            };

            const fileName = `SoQuyTienAn_${
                elem.cl_name
            }_${_globalUtils.padLeft(month, 2, '0')}${info.nam}_${
                info.tenTruong
            }`;

            const res = await exportReportToPDF({
                data: JSON.stringify({
                    info,
                    listData: listPrint,
                }),
                report: REPORT_TYPE.TONG_HOP_SO_QUY_TIEN_AN,
            });

            if (res.status === 200) {
                _globalUtils.downloadPDF(res.data, fileName);
            }
        }

        return true;
    };
}

export default new _incomeExportPDFService();
