import React from 'react';
import './index.scss';

const renderTextAreaField = ({
    input,
    label,
    type,
    value,
    rows,
    meta: { touched, error, warning },
    ...custome
}) => (
    <div>
        <label>
            <h6 className="panel-title">{label}</h6>
        </label>
        <div>
            <textarea
                {...input}
                {...custome}
                type={type}
                rows={rows}
                // value = {value} /// Có thể conflict
            />
            {touched &&
                ((error && <small className="text-danger">{error}</small>) ||
                    (warning && (
                        <small className="text-warning">{warning}</small>
                    )))}
        </div>
    </div>
);

export default renderTextAreaField;
