import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { nonAccentVietnamese } from '@app/utils/utils';
import { getAll } from '@app/apis/schools/year';
import globalUtils from '@app/utils/global.utils';
import _ from 'lodash';

const { Option } = Select;
const propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
};

const SchoolYearSelect = ({ label, defaultValue, onChange }) => {
    const isFirst = useRef(true);
    const [list, setList] = useState([]);
    const [selectId, setSelectId] = useState('');

    useEffect(() => {
        getAll().then((res) => {
            if (res.status === 200) {
                let _tempList = res.data.data || [];
                let _tempSelectId = '';
                _tempList = _.orderBy(_tempList, ['sy_id'], ['desc']);
                setList(_tempList);
                if (_tempList.length > 0) {
                    const defaultYear = _tempList.find(
                        (o) => o.sy_isdefault === 1
                    );
                    if (defaultYear) {
                        _tempSelectId = defaultYear.sy_id;
                    } else {
                        _tempSelectId = _tempList[0].sy_id;
                    }
                }

                if (defaultValue) {
                    if (
                        globalUtils.checkValueInList(
                            'sy_id',
                            defaultValue,
                            _tempList
                        )
                    ) {
                        _tempSelectId = defaultValue;
                    }
                }

                setSelectId(_tempSelectId);
                isFirst.current = false;
                if (onChange) {
                    onChange(_tempSelectId);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isFirst.current && list.length > 0 && selectId !== defaultValue) {
            let tempDefaut = list[0].sy_id;
            if (globalUtils.checkValueInList('sy_id', defaultValue, list)) {
                tempDefaut = defaultValue;
            }
            setSelectId(tempDefaut);

            if (onChange) {
                onChange(tempDefaut);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const handleChange = (s) => {
        setSelectId(s);
        if (onChange) {
            onChange(s);
        }
    };

    const onSearch = (input, option) => {
        if (option) {
            return (
                nonAccentVietnamese(option.children.replace(/\s/g, ''))
                    .toLowerCase()
                    .indexOf(
                        nonAccentVietnamese(
                            input.replace(/\s/g, '')
                        ).toLowerCase()
                    ) >= 0
            );
        }
        return false;
    };

    const showOptionItem = () => {
        let xhtml = null;
        if (list.length > 0) {
            xhtml = list.map((item) => (
                <Option value={item.sy_id} key={`${item.sy_id}`}>
                    {`${item.sy_name}`}
                </Option>
            ));
        }
        return xhtml;
    };

    return (
        <Fragment>
            {label ? (
                <Fragment>
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                    <div className="clearfix mb20" />
                </Fragment>
            ) : null}
            <Select
                value={selectId}
                onChange={(value) => handleChange(value)}
                optionFilterProp="children"
                filterOption={(input, option) => onSearch(input, option)}
                showSearch
            >
                {showOptionItem()}
            </Select>
        </Fragment>
    );
};

SchoolYearSelect.propTypes = propTypes;

export default SchoolYearSelect;
