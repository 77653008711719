/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import {
    MarketAgeReportModel,
    MarketReportModel,
} from '@app/models/foodkid/foodkid.model';
import _globalUtils from '@app/utils/global.utils';
import reportUtils from '@app/utils/reportUtils';
import { numberToPrice, priceToNumber } from '@app/utils/utils';
import _ from 'lodash';
import moment from 'moment';

const { roundNumber } = _globalUtils;

class _marketExportPDFService {
    /**
     * Merge dữ liệu phiếu kê chợ
     * @param {Array} proFoods : danh sách thực phẩm
     * @param {Array} schoolFoods : danh sách thực phẩm trường
     * @param {Array} menuSchoolFoods : danh sách thực phẩm của thực đơn
     * @param {Array} units : danh sách đơn vị tính
     * @param {Array} menulist : danh sách menu
     * @param {Array} kidGroup : danh sách nhóm trẻ
     * @param {object} locationInfo : thông tin địa chỉ của trường
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     * @param {number} dataValue : thực tế hoặc dự trù
     * @param {String} nameFile: tên prefix file khi xuất ra
     */
    doExport = async (
        proFoods,
        schoolFoods,
        menuSchoolFoods,
        units,
        menulist,
        kidGroup,
        locationInfo,
        schoolInfo,
        organizationInfo,
        dataValue,
        nameFile
    ) => {
        const _nameFile = nameFile || 'PhieuKeCho';

        const value = dataValue === 1 ? 'before' : 'after';
        const kgs = kidGroup.filter((x) =>
            menulist.some((y) => y.kg_id === x.kg_id)
        );
        const warehouse = _.orderBy(
            menuSchoolFoods.filter((o) => o.msf_useinwarehouse),
            ['msf_sortorder'],
            ['asc']
        );
        const not_warehouse = _.orderBy(
            menuSchoolFoods.filter((o) => !o.msf_useinwarehouse),
            ['msf_sortorder'],
            ['asc']
        );

        const list = warehouse.concat(not_warehouse);
        const info = {
            title: `PHIẾU KÊ CHỢ ${
                dataValue === 2 ? '\n (Điều chỉnh theo thực tế)' : ''
            }`,
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            // province: locationInfo.cityName,
            // district: locationInfo.districtName,
            // wards: locationInfo.wardName,
            school: schoolInfo?.ten_truong,
            principal: schoolInfo?.ho_ten_hieu_truong,
            assistant: schoolInfo?.ho_ten_hieu_pho_chuyen_mon,
            provision: schoolInfo?.ho_ten_cap_duong,
            accountant: schoolInfo?.ho_ten_ke_toan,
            labelPrincipal:
                schoolInfo?.tieu_de_hieu_pho_ban_tru || 'Hiệu trưởng',
            labelAssistant: 'Hiệu phó',
            labelProvision: 'Cấp dưỡng',
            labelAccountant: 'Kế toán',
            nhomtre1: kgs.length >= 1 ? kgs[0].kg_name : '',
            nhomtre2: kgs.length >= 2 ? kgs[1].kg_name : '',
            nhomtre3: kgs.length >= 3 ? kgs[2].kg_name : '',
            nhomtre4: kgs.length >= 4 ? kgs[3].kg_name : '',
            date: moment.unix(menulist[0].m_datefounded).format('DD/MM/YYYY'),
        };
        const foods = list
            .map((item) => {
                const food = new MarketReportModel();
                const sf = schoolFoods.find((o) => o.sf_id === item?.sf_id);
                const unit = units.find((o) => o.cu_id === sf?.cu_id);

                food.f_name = sf.sf_name;
                food.sf_id = sf.sf_id;
                food.unit = unit.cu_name;
                food.msf_priceafter = item[`msf_price${value}`];
                food.warehouse = item.msf_useinwarehouse ? 'Kho' : 'Chợ';
                if (kgs.length >= 1) {
                    const menu = menulist.find((o) => o.kg_id === kgs[0].kg_id);
                    food.price_1 =
                        item.m_id === menu.m_id
                            ? numberToPrice(
                                  item[`msf_price${value}`] *
                                      item[`msf_totalquantity${value}`]
                              )
                            : '';
                    food.sf_quantiry_1 =
                        item.m_id === menu.m_id
                            ? parseFloat(
                                  item[`msf_totalquantity${value}`].toFixed(2)
                              )
                            : '';
                }
                if (kgs.length >= 2) {
                    const menu = menulist.find((o) => o.kg_id === kgs[1].kg_id);
                    food.price_2 =
                        item.m_id === menu.m_id
                            ? numberToPrice(
                                  item[`msf_price${value}`] *
                                      item[`msf_totalquantity${value}`]
                              )
                            : '';
                    food.sf_quantiry_2 =
                        item.m_id === menu.m_id
                            ? parseFloat(
                                  item[`msf_totalquantity${value}`].toFixed(2)
                              )
                            : '';
                }
                if (kgs.length >= 3) {
                    const menu = menulist.find((o) => o.kg_id === kgs[2].kg_id);
                    food.price_3 =
                        item.m_id === menu.m_id
                            ? numberToPrice(
                                  item[`msf_price${value}`] *
                                      item[`msf_totalquantity${value}`]
                              )
                            : '';
                    food.sf_quantiry_3 =
                        item.m_id === menu.m_id
                            ? parseFloat(
                                  item[`msf_totalquantity${value}`].toFixed(2)
                              )
                            : '';
                }
                if (kgs.length >= 4) {
                    const menu = menulist.find((o) => o.kg_id === kgs[3].kg_id);
                    food.price_4 =
                        item.m_id === menu.m_id
                            ? numberToPrice(
                                  item[`msf_price${value}`] *
                                      item[`msf_totalquantity${value}`]
                              )
                            : '';
                    food.sf_quantiry_4 =
                        item.m_id === menu.m_id
                            ? parseFloat(
                                  item[`msf_totalquantity${value}`].toFixed(2)
                              )
                            : '';
                }

                food.total_quantity =
                    parseFloat(food.sf_quantiry_1 || 0) +
                    parseFloat(food.sf_quantiry_2 || 0) +
                    parseFloat(food.sf_quantiry_3 || 0) +
                    parseFloat(food.sf_quantiry_4 || 0);

                food.total_unit_price = numberToPrice(
                    item[`msf_price${value}`]
                );
                food.total_price = numberToPrice(
                    priceToNumber(food.price_1 || 0) +
                        priceToNumber(food.price_2 || 0) +
                        priceToNumber(food.price_3 || 0) +
                        priceToNumber(food.price_4 || 0)
                );

                return food;
            })
            .reduce((acc, rec) => {
                const itemsInAcc = acc.find(
                    (a) =>
                        a.sf_id === rec.sf_id &&
                        a.warehouse === rec.warehouse &&
                        a[`msf_price${value}`] === rec[`msf_price${value}`]
                );
                if (itemsInAcc != null) {
                    itemsInAcc.price_1 = numberToPrice(
                        priceToNumber(itemsInAcc.price_1) +
                            priceToNumber(rec.price_1)
                    );
                    itemsInAcc.price_2 = numberToPrice(
                        priceToNumber(itemsInAcc.price_2) +
                            priceToNumber(rec.price_2)
                    );
                    itemsInAcc.price_3 = numberToPrice(
                        priceToNumber(itemsInAcc.price_3) +
                            priceToNumber(rec.price_3)
                    );
                    itemsInAcc.price_4 = numberToPrice(
                        priceToNumber(itemsInAcc.price_4) +
                            priceToNumber(rec.price_4)
                    );
                    itemsInAcc.sf_quantiry_1 += rec.sf_quantiry_1;
                    itemsInAcc.sf_quantiry_2 += rec.sf_quantiry_2;
                    itemsInAcc.sf_quantiry_3 += rec.sf_quantiry_3;
                    itemsInAcc.sf_quantiry_4 += rec.sf_quantiry_4;
                    itemsInAcc.total_quantity = parseFloat(
                        (
                            Math.round(
                                (itemsInAcc.total_quantity +
                                    rec.total_quantity) *
                                    100
                            ) / 100
                        ).toFixed(2)
                    );
                    itemsInAcc.total_unit_price = numberToPrice(
                        itemsInAcc.total_unit_price
                    );
                    itemsInAcc.total_price = numberToPrice(
                        priceToNumber(itemsInAcc.total_price) +
                            priceToNumber(rec.total_price)
                    );
                } else {
                    acc = [...acc, { ...rec }];
                }
                return acc;
            }, [])
            .map((item, i) => {
                item.stt = i + 1;
                item.sf_quantiry_1 = roundNumber(+item.sf_quantiry_1);
                item.sf_quantiry_2 = roundNumber(+item.sf_quantiry_2);
                item.sf_quantiry_3 = roundNumber(+item.sf_quantiry_3);
                item.sf_quantiry_4 = roundNumber(+item.sf_quantiry_4);
                // item.total_quantity = roundNumber(item.total_quantity);
                // item.total_unit_price = roundNumber(item.total_unit_price);
                // item.total_price = roundNumber(item.total_price);
                return item;
            });

        const kidgroups = kgs.map((item) => {
            const age = new MarketAgeReportModel();
            age.m_quantity = menulist.filter((o) => o.kg_id === item.kg_id)[0][
                `m_quantity${value}`
            ];
            age.m_id = menulist.filter((o) => o.kg_id === item.kg_id)[0].m_code;
            age.kg_name = item.kg_name;
            return age;
        });
        const res = await exportReportToPDF({
            data: JSON.stringify({ info, foods, kidgroups }),
            report: REPORT_TYPE.PHIEU_KE_CHO,
        });
        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `${_nameFile}_${info.date}_${organizationInfo.o_name}`
            );
        }
        return true;
    };
}

export default new _marketExportPDFService();
