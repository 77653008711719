import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { SC_MODULES } from '@app/constants/sc.modules';
import NotAuthorizePage from '@app/pages/NotAuth';
import { getAuth } from '@app/utils/auth.utils';
import NotFountPage from '@app/pages/NotFound';

const propTypes = {
    ComponentToRender: PropTypes.elementType,
    path: PropTypes.string,
    exact: PropTypes.bool,
    functionId: PropTypes.string,
};
const CustomeRoute = ({ main: ComponentToRender, path, exact, functionId }) => {
    const showComp = () => {
        let comp = null;
        if (functionId) {
            const isAuth = getAuth(SC_MODULES.ADMIN, functionId);
            comp = isAuth ? ComponentToRender : NotAuthorizePage;
        } else {
            comp = NotFountPage;
        }
        return comp;
    };

    const showItem = () => (
        <Route path={path} exact={exact} component={showComp()} />
    );

    return showItem();
};

CustomeRoute.propTypes = propTypes;

export default CustomeRoute;
