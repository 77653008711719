import axiosService from '../../services/axiosService';
import { API_GLOBALDATA_ENDPOINT } from '../../constants/api';

const BASE_URL = `${API_GLOBALDATA_ENDPOINT}/v1/globalLocation`;
const BASE_URL_V2 = `${API_GLOBALDATA_ENDPOINT}/v1/BcnGlobalData/Location`;

const LIST_URL = {
    GET_ALL: `${BASE_URL}/GetAll`,
    GET_LIST_BY_PAGE: `${BASE_URL}/getListByPage`,
    GET_INFO: `${BASE_URL_V2}/GetInfo`,
    GET_BY_LIST_ID: `${BASE_URL}/GetByListId`,
};

// Lấy danh sách phân trang
export const getLocationByPage = (type, page, parentId) =>
    axiosService.get(
        `${LIST_URL.GET_LIST_BY_PAGE}/${type}/${page}/${parentId}`
    );

// Lấy danh sách theo location type
export const getLocationByType = (type, parentId) =>
    axiosService.get(`${BASE_URL}/${type}/${parentId}`);

// Lấy đối tượng theo id
export const getItemById = (id) => axiosService.get(`${BASE_URL}/${id}`);

// Lấy danh sách theo parentId

export const saveLocation = (location) =>
    axiosService.post(`${BASE_URL}`, location);
export const deleteLocation = (id) => axiosService.delete(`${BASE_URL}/${id}`);

// Lấy tất cả - dành cho trường hợp lưu cache
export const getAll = () => axiosService.get(`${LIST_URL.GET_ALL}`);

export const apiGetLocationInfo = (l_code) =>
    axiosService.get(`${LIST_URL.GET_INFO}/${l_code}`);

const locationApis = {
    getByListId(listId) {
        return axiosService.post(LIST_URL.GET_BY_LIST_ID, listId);
    },
};

export default locationApis;
