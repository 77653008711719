import React, { Component, Fragment } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as monthActions from '@app/redux/others/month/month.action';

const { Option } = Select;
class MonthSelect extends Component {
    showOptionItem = (list) => {
        const xhtml = [];
        if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                xhtml.push(
                    <Option value={list[i].m_id} key={list[i].m_id}>
                        {list[i].m_name}
                    </Option>
                );
            }
        }
        return xhtml;
    };

    onChange = (value) => {
        const { monthCreators } = this.props;
        monthCreators.changMonthSelect(value);
    };

    render() {
        const { monthSelectList, currentMonth, label } = this.props;
        return (
            <Fragment>
                {label ? (
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                ) : null}

                <Select value={currentMonth} onChange={this.onChange}>
                    {this.showOptionItem(monthSelectList)}
                </Select>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    monthSelectList: state.monthReducer.monthSelectList,
    currentMonth: state.monthReducer.currentMonth,
});
const mapDispathToProps = (dispatch) => ({
    monthCreators: bindActionCreators(monthActions, dispatch),
});
export default connect(mapStateToProps, mapDispathToProps)(MonthSelect);
