import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import React, { Fragment } from 'react';

const TabSchoolPropertySetting = (props) => {
    const { onSave, children } = props;
    return (
        <Fragment>
            <div className="panel-body" style={{ display: 'block' }}>
                <div className="row">{children}</div>
                <div className="text-right">
                    <ButtonFilterAction
                        buttonType={BUTTON_ACTION_TYPE.SAVE}
                        labelButton="Lưu"
                        onClick={() => onSave()}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default TabSchoolPropertySetting;
