import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Table } from 'antd';
import { formatDateUnix } from '@app/utils/datetime.utils';
import _paymentVoucherHelpers from './paymntVouPage.helpers';
import { numberToPrice } from '@app/utils/utils';
import ButtonTableAction from '@app/components/UI/Button/ButtonTableAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

const propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({})),
    onDelete: PropTypes.func,
    isCheckAll: PropTypes.bool,
    onCheckAll: PropTypes.func,
    onCheckItem: PropTypes.func,
};

const PaymentVoucherList = ({
    dataSource,
    onDelete,
    isCheckAll,
    onCheckAll,
    onCheckItem,
}) => {
    const columns = [
        {
            title: () => (
                <Checkbox checked={isCheckAll} onChange={onCheckAll} />
            ),
            width: 60,
            align: 'center',
            render: (dataItem) => (
                <Checkbox
                    checked={dataItem.isCheck}
                    onChange={(s) => onCheckItem(dataItem, s)}
                />
            ),
        },
        {
            title: 'STT',
            dataIndex: 'stt',
            render: (_value, _record, _index) => <>{_index + 1}</>,
            width: 60,
            align: 'center',
        },
        {
            title: 'Ngày chi',
            dataIndex: 'i_datefounded',
            render: (_value) => <>{formatDateUnix(_value)}</>,
            width: 120,
            align: 'center',
        },

        {
            title: 'Loại phiếu chi',
            dataIndex: 'i_paymentvouchertype',
            render: (_value) => (
                <>{_paymentVoucherHelpers.getPaymentVoucherTypeName(_value)}</>
            ),
            width: 120,
        },
        {
            title: 'Nội dung chi',
            dataIndex: 'i_description',
            index: 'i_description',
            key: 'i_description',
        },
        {
            title: 'Ngày tính tiền',
            render: (_value, _record) => (
                <>{`${formatDateUnix(_record.i_datefrom)} - ${formatDateUnix(
                    _record.i_dateto
                )}`}</>
            ),
            width: 180,
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'i_total',
            render: (_value) => <>{numberToPrice(_value)}</>,
            align: 'right',
            width: 120,
        },
        {
            title: 'Mã phiếu chi',
            dataIndex: 'i_code',
            index: 'i_code',
            key: 'i_code',
            width: 180,
            align: 'center',
        },
        {
            title: 'H.Động',
            width: 80,
            align: 'center',
            render: (_dataItem) => (
                <>
                    <ButtonTableAction
                        buttonType={BUTTON_ACTION_TYPE.DELETE}
                        onClick={() => onDelete(_dataItem)}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowKey="i_id"
                bordered
            />
        </>
    );
};

PaymentVoucherList.propTypes = propTypes;

export default PaymentVoucherList;
