import DatePickerField from '@app/components/Layout/Shares/FormHelpers/DatimePickerField';
import RenderTextField from '@app/components/Layout/Shares/FormHelpers/TextField';
import ButtonPopupAction from '@app/components/Layout/UI/Button/ButtonPopupAction';
import { BUTTON_POPUP_ACTION_TYPE } from '@app/components/Layout/UI/Button/_service';
import { swal } from '@app/utils/alert';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import validate from './validate';

const CopyMenuModalImport = ({
    isShow,
    onClose,
    proFood,
    handleSubmit,
    onImportMenu,
    invalid,
    submitting,
}) => {
    const [fileName, setFileName] = useState('');
    const [objMenu, setObjMenu] = useState({});

    const onSaveImport = (data) => {
        const dataPost = {
            ...data,
            m_datefounded: moment(data.m_datefounded).startOf('day').unix(),
        };

        onImportMenu?.({ dataPost, file: objMenu });
    };

    const csvFileToArray = (jsonCsv) => {
        try {
            const parseJson = jsonCsv ? JSON.parse(jsonCsv) : [];
            const parseObj = Object.assign({}, ...parseJson);
            const _objMenu = {
                m_quantity: parseObj?.Siso,
                m_price: parseObj?.Tiendinhmuc,
                m_pricebefore: parseObj?.Tienmottre,
                m_breakfast: parseObj?.Buasang,
                m_lunch: parseObj?.Buatrua,
                m_dinner: parseObj?.Buaxe,
                m_snack: parseObj?.Buaphu,
                listSchoolFood: parseObj?.ChiTietThucDons?.map((o) => {
                    const food = proFood?.find(
                        (x) => x.f_nameabbreviation === o.Tentat
                    );
                    return {
                        f_id: food?.f_id,
                        fc_id: food?.fc_id,
                        f_name: food?.f_name,
                        f_group: food?.f_group,
                        // f_disposalrate: food?.f_disposalrate,
                        f_disposalrate: o?.Hesothaibo,
                        sf_price: o?.Giatien,
                        msf_sortorder: o?.Thutusapxep,
                        msf_quantitybefore: o?.Soluonggam,
                        msf_totalquantitybefore: o?.Soluongthucmua,
                        msf_pricebefore: o?.Giatien,
                        msf_pricefoodbefore: o?.Tienmotthucpham,
                        msf_meal: o?.Buaan,
                        cu_code: o?.Tendonvitinh,
                    };
                }),
                listService: parseObj?.DichVuThucDons?.map((o) => ({
                    ms_name: o?.Tenloaihinhdichvu,
                    ms_price: o?.Giatienmottre,
                })),
            };

            setObjMenu(_objMenu);
        } catch {
            swal('warning', 'Tập tin đính kèm không hợp lệ !');
        }
    };

    const handleOnChange = (e) => {
        const file = e.target.files[0];

        setFileName(file?.name);

        if (file) {
            const reader = new FileReader();

            reader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            reader.readAsText(file);
        }
    };

    return (
        <Modal
            show={isShow}
            onHide={onClose}
            animation
            backdrop="static"
            size="md"
        >
            <form onSubmit={handleSubmit(onSaveImport)}>
                <Modal.Header closeButton className="bg-primary-800">
                    <Modal.Title>Thêm thực đơn từ file CSV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Field
                                            name="m_datefounded"
                                            component={DatePickerField}
                                            type="text"
                                            className="form-control"
                                            label="Ngày lập"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Field
                                            name="m_code"
                                            component={RenderTextField}
                                            type="text"
                                            className="form-control"
                                            label="Mã thực đơn"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>
                                            <h6 className="panel-title">
                                                Tập tin đính kèm
                                            </h6>
                                        </label>
                                        <div>
                                            <div className="file-input file-input-new">
                                                <div className="input-group file-caption-main">
                                                    <div
                                                        className="form-control file-caption  kv-fileinput-caption"
                                                        style={{ zIndex: 0 }}
                                                    >
                                                        <div
                                                            className="file-caption-name"
                                                            title="Chọn tập tin"
                                                        >
                                                            <i className="glyphicon glyphicon-file kv-caption-icon" />
                                                            {fileName ||
                                                                'Chọn tập tin'}
                                                        </div>
                                                    </div>

                                                    <div className="input-group-btn">
                                                        <div className="btn btn-primary btn-file">
                                                            <i className="icon-file-plus" />
                                                            <span className="hidden-xs">
                                                                Chọn tập tin
                                                            </span>
                                                            <input
                                                                type="file"
                                                                className="file-input"
                                                                id="csvFileInput"
                                                                accept=".csv"
                                                                onChange={
                                                                    handleOnChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {/* </Scrollbars> */}
                <Modal.Footer>
                    <div className="btn-group">
                        <ButtonPopupAction
                            buttonType={BUTTON_POPUP_ACTION_TYPE.SAVE}
                            label="Lưu"
                            type="submit"
                            isLeftIcon
                            disabled={invalid || submitting}
                        />
                        <ButtonPopupAction
                            buttonType={BUTTON_POPUP_ACTION_TYPE.CLOSE}
                            label="Thoát"
                            onClick={onClose}
                        />
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

const FORM_NAME = 'IMPORT_MENU_FORM';

const ReduxFormImportModal = reduxForm({
    form: FORM_NAME,
    validate,
    enableReinitialize: true,
})(CopyMenuModalImport);

export default ReduxFormImportModal;
