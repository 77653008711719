import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as _accountBankApis from '@app/apis/income/accountBank.api';
import BaseSelect from '../../BaseSelect';
import globalUtils from '@app/utils/global.utils';

const propTypes = {
    onChange: PropTypes.func,
};

function DynamicAccountBankSelect({
    onChange,
    label,
    oId,
    defaultValue,
    disabled,
}) {
    const [selectId, setSelectId] = useState();
    const [list, setList] = useState([]);

    const onReset = () => {
        setSelectId('');
        setList([]);
    };

    const getData = async (_oId) => {
        const { status, data } = await _accountBankApis.apiGetByOId(_oId);
        if (status === 200) {
            let _listTemp = data.data;
            if (_listTemp.length === 0) {
                _listTemp = [
                    {
                        ab_id: -1,
                        ab_name: '-- Chưa có tài khoản thu chi',
                    },
                    ..._listTemp,
                ];
            }
            let id = '';
            if (
                defaultValue &&
                globalUtils.checkValueInList('ab_id', defaultValue, _listTemp)
            ) {
                id = defaultValue;
            } else if (_listTemp.length > 0) {
                id = _listTemp[0].ab_id;
            }
            setList(_listTemp);
            setSelectId(id);
            if (onChange) {
                onChange(id);
            }
        } else {
            onReset();
        }
    };

    useEffect(() => {
        if (oId) {
            getData(oId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oId]);

    const handleChange = (value) => {
        setSelectId(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <BaseSelect
            list={list}
            selectId={selectId}
            idField="ab_id"
            nameField="ab_name"
            onChange={handleChange}
            label={label}
            disabled={disabled}
        />
    );
}

DynamicAccountBankSelect.propTypes = propTypes;

export default DynamicAccountBankSelect;
