const validate = (values) => {
    const errors = {};
    const { sr_sortorder, sr_name } = values;
    if (!sr_sortorder) {
        errors.sr_sortorder = 'Số thứ tự không được để trống!';
    } else if (sr_sortorder < 0) {
        errors.sr_sortorder = 'Số thứ tự không được âm!';
    }
    if (!sr_name) {
        errors.sr_name = 'Tên vai trò không được để trống!';
    }

    return errors;
};

export default validate;
