import myIcon from '@app/assets/images/icons/fk/menu.svg';
import AutoCreateMenu from '@app/components/Foodkids/AutoCreateMenu';
import CopyMenu from '@app/components/Foodkids/CopyMenu';
// import Menus from '@app/components/Foodkids/Menus';

import React from 'react';
import { ADMIN_FUNCTIONS, SC_MODULES } from '@app/constants/sc.modules';
// import CopyMenu from '@app/components/CopyMenu';

/**
 * Thông báo
 */
const foodkidRoutes = [
    {
        name: 'THỰC ĐƠN',
        icon: myIcon,
        isMenu: true,
        isRoute: false,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        // link: '/foodkid',
        // path: '/foodkid',
        // main: () => <Foodkids />,
        functionId: ADMIN_FUNCTIONS.FOODKID_MAIN,
        children: [
            {
                name: 'Tạo thực đơn',
                link: '/foodkid',
                path: '/foodkid',
                isMenu: true,
                isRoute: true,
                exact: false,
                functionId: ADMIN_FUNCTIONS.FOODKID_CREATE_MENU,
                main: () => <AutoCreateMenu />,
            },
            {
                name: 'Sao chép thực đơn',
                link: '/copymenu',
                path: '/copymenu',
                isMenu: true,
                isRoute: true,
                exact: false,
                functionId: ADMIN_FUNCTIONS.FOODKID_COPY_MENU,
                main: () => <CopyMenu />,
            },
            // {
            //     name: 'Danh sách thực đơn',
            //     link: '/listmenu',
            //     path: '/listmenu',
            //     isMenu: true,
            //     isRoute: true,
            //     exact: false,
            //     functionId: ADMIN_FUNCTIONS.FOODKID_LIST_MENU,
            //     main: () => <Menus />,
            // },
        ],
    },
];

export default foodkidRoutes;
