const validate = (values) => {
    const errors = {};
    const { m_datefounded, m_code } = values;

    if (!m_datefounded) {
        errors.m_datefounded = 'Ngày lập không được để trống !';
    }

    if (!m_code) {
        errors.m_code = 'Mã thực đơn không được để trống !';
    }

    return errors;
};

export default validate;
