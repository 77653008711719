/**
 * Kiểm tra giá trị có nằm trong danh sách cần kiểm tra không
 * @param {string} idFieldName : tên field Id
 * @param {*} idValue : giá trị cần kiểm tra
 * @param {Array} listCheck : danh sách cần kiểm tra
 * @returns
 ** true: có nằm trong danh sách
 ** false: không nằm trong danh sách
 */
const checkValueInList = (idFieldName, idValue, listCheck) => {
    let isExist = false;
    if (listCheck && listCheck.length > 0) {
        const objCheck = listCheck.find(
            (item) => item[idFieldName] === idValue
        );
        if (objCheck) {
            isExist = true;
        }
    }
    return isExist;
};

export { checkValueInList };
