import React from 'react';
import PropTypes from 'prop-types';
import ButtonFilterAction from '@app/components/UI/Button/ButtonFilterAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import RangeDate from '@app/components/_Share/Date/RangeDate/RangeDateV2';
import { MOMENT_RANGE_TYPE } from '@app/constants/datetime.const';
import PaymentVoucherTypeSelect from '@app/components/_Share/Select/Income/PaymentVoucherTypeSelect/Dynamic';
import SchoolYearSelect from '@app/components/_Share/Select/SchoolYearSelect/Dynamic';

const propTypes = {
    onDateChange: PropTypes.func,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onTypeChange: PropTypes.func,
};

const PaymentVoucherFilter = ({
    onDateChange,
    onAdd,
    onDelete,
    onTypeChange,
    onYearChange,
    disableDeleteBtn,
}) => (
    <div className="row">
        <div className="col-lg-12">
            <div className="row">
                <div className="col-md-2 col-sm-6">
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-addon">Năm học</span>
                            <SchoolYearSelect onChange={onYearChange} />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <RangeDate
                        rangeType={MOMENT_RANGE_TYPE.MONTH}
                        onChange={onDateChange}
                    />
                </div>
                <div className="col-md-2 col-sm-4">
                    <div className="form-group">
                        <div className="input-group">
                            <span className="input-group-addon">
                                Loại phiếu chi
                            </span>
                            <PaymentVoucherTypeSelect
                                hasAllOption
                                onChange={onTypeChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-sm-6">
                    <div className="form-group">
                        <div className="no-margin">
                            <div className="btn-group">
                                <ButtonFilterAction
                                    labelButton="Thêm"
                                    buttonType={BUTTON_ACTION_TYPE.ADD}
                                    onClick={onAdd}
                                />

                                <ButtonFilterAction
                                    labelButton="Xóa"
                                    buttonType={BUTTON_ACTION_TYPE.DELETE}
                                    disabled={disableDeleteBtn}
                                    onClick={onDelete}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

PaymentVoucherFilter.propTypes = propTypes;

export default PaymentVoucherFilter;
