import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from './warehouse.constant';
import * as apiConst from '@app/constants/api';
import { getWarehouseSuccess } from './warehouse.action';
import { showError } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
//import { GetByUser } from '@app/apis/inventory';
import _fkWarehouseApis from '@app/apis/inventory/warehouse.api';

function* sagaGetListSchool({ payload }) {
    function* doRQ() {
        // const res = yield call(GetByUser, payload.id);
        const res = yield call(_fkWarehouseApis.getBySchool, payload.id);
        const { data, status } = res;
        if (status === apiConst.STATUS_CODES.SUCCESS) {
            yield put(getWarehouseSuccess(data.data));
        } else {
            showError(status, data);
        }
    }
    yield callLoading(doRQ);
}

function* warehouseSaga() {
    yield takeEvery(types.GET_WAREHOUSE, sagaGetListSchool);
}

export default warehouseSaga;
