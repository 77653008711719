/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import _globalUtils from '@app/utils/global.utils';
import reportUtils from '@app/utils/reportUtils';
import { docso, numberToPrice, priceToNumber } from '@app/utils/utils';
import moment from 'moment';

class _billOfSaleExportPDFService {
    /**
     * Merge dữ liệu thực đơn
     * @param {Int} idSupplier : mã nhà cung cấp
     * @param {Array} menuSchoolFoods : danh sách thực phẩm của thực đơn
     * @param {Array} units : danh sách đơn vị tính
     * @param {Int} dateFounded : ngày xuất hóa đơn
     * @param {object} locationInfo : thông tin địa chỉ của trường
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     * @param {Array} supplier : danh sách nhà cung cấp
     * @param {Int} type : loại hóa đơn
     * @param {String} nameFile: tên prefix file khi xuất ra
     */
    doExportBillOfSale = async (
        idSupplier,
        menuSchoolFoods,
        units,
        dateFounded,
        locationInfo,
        organizationInfo,
        schoolInfo,
        supplier,
        invoiceForm,
        type,
        nameFile
    ) => {
        const _nameFile = nameFile || 'HoaDonBanHang';

        const productFoodList = menuSchoolFoods
            .sort((a, b) => (a.msf_sortorder > b.msf_sortorder ? 1 : -1))
            .map((l, idx) => {
                const unit = units.find((o) => o.cu_id === l.cu_id);

                l.stt = idx + 1;
                l.price = numberToPrice(l.msf_pricebefore);
                l.totalprice = numberToPrice(+l.quantity * +l.msf_pricebefore);
                l.unit = unit?.cu_name || '';

                return l;
            });

        const totalPriceString = docso(
            productFoodList.reduce((a, b) => a + priceToNumber(b.totalprice), 0)
        )
            .trim()
            .Capitalize();

        // const objSupplier = supplier?.find((o) => o.s_id === +idSupplier);

        const objSupplier = invoiceForm?.find(
            (o) => o.if_id === +schoolInfo.hoa_don_nha_cung_cap
        );

        const cusLocation = reportUtils.getLocationInfo(
            schoolInfo,
            locationInfo
        );

        // Địa chỉ
        let address = schoolInfo?.dia_chi;
        if (!address) {
            address = organizationInfo?.oa_addressline1;
        }

        // Tên trường
        let schoolName = schoolInfo?.ten_truong;
        if (!schoolName) {
            schoolName = organizationInfo?.o_name;
        }

        const info = {
            title: objSupplier?.if_reporttitle || 'HÓA ĐƠN BÁN HÀNG',
            logo: objSupplier?.if_logo || '',
            logoWatermask: objSupplier?.if_logowatermask,
            o_name: objSupplier?.if_companyname.toUpperCase(),
            o_address: objSupplier?.if_address,
            o_telephone: objSupplier?.if_telephone,
            o_email: objSupplier?.if_email,
            o_taxcode: objSupplier?.if_taxcode,
            o_delegatetitle: objSupplier?.if_delegatetitle,
            o_customertitle: objSupplier?.if_customertitle,
            o_website: objSupplier?.if_website,
            customer_name: schoolName || '',
            customer_telephone: schoolInfo?.dien_thoai || '',
            customer_address: `${address}, ${cusLocation.wardName}, ${cusLocation.districtName}, ${cusLocation.cityName}`,
            number_bill: `${objSupplier?.if_reportsymbol}${moment
                .unix(dateFounded)
                .format('YYMMDD')}`,
            date_bill: moment.unix(dateFounded).format('DD/MM/YYYY'),
            totalPriceString: `${totalPriceString} đồng`,
        };

        const res = await exportReportToPDF({
            data: JSON.stringify({
                info,
                datas: productFoodList,
                type: objSupplier?.if_reportname,
            }),
            report: REPORT_TYPE.HOA_DON_BAN_HANG,
        });

        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `${_nameFile}_${info.date_bill}_${
                    organizationInfo.o_name
                }_Mẫu_${type === 2 ? 'chung' : ''}`
            );
        }
        return true;
    };
}

export default new _billOfSaleExportPDFService();
