import moment from 'moment';

export const showDate = (datetimeUnix) => {
    if (typeof datetimeUnix === 'number') {
        if (datetimeUnix.toString().length === 10) {
            datetimeUnix *= 1000;
        }
    }
    const x = new Date(datetimeUnix);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(x)) {
        return '';
    }

    const dd = x.getDate() >= 10 ? x.getDate() : `0${x.getDate()}`;
    const mm =
        x.getMonth() + 1 >= 10 ? x.getMonth() + 1 : `0${x.getMonth() + 1}`;
    const yy = x.getFullYear();
    return `${dd}/${mm}/${yy}`;
};

export const formatDateUnix = (date, formatStr = 'DD/MM/YYYY') =>
    moment.unix(date).format(formatStr);

export const getUnixStartOfDay = (date) => moment(date).startOf('day').unix();

export const getDayOfWeekByDate = (dateParam, isFullName) => {
    if (typeof dateParam === 'number') {
        dateParam *= 1000;
    }
    const date = new Date(dateParam);
    const numberOfDay = date.getDay();
    let name = '';
    switch (numberOfDay) {
        case 0: {
            name = isFullName ? 'Chủ nhật' : 'CN';
            break;
        }
        case 1: {
            name = isFullName ? 'Thứ 2' : 'T2';
            break;
        }
        case 2: {
            name = isFullName ? 'Thứ 3' : 'T3';
            break;
        }
        case 3: {
            name = isFullName ? 'Thứ 4' : 'T4';
            break;
        }
        case 4: {
            name = isFullName ? 'Thứ 5' : 'T5';
            break;
        }
        case 5: {
            name = isFullName ? 'Thứ 6' : 'T6';
            break;
        }
        case 6: {
            name = isFullName ? 'Thứ 7' : 'T7';
            break;
        }
        default: {
            break;
        }
    }
    return name;
};

/*= =====================================================================================
Hàm lấy năm thực tại theo combobox(select) năm học
*** year: giá trị của combobox
*** month: tháng được chọn (bắt đầu từ 1)
*** monthUpClass: tháng chuyển lớp
 ======================================================================================= */
export const getTrueYearValue = (year, month, monthUpClass) => {
    let trueYearValue = year;
    if (month >= 1 && month < monthUpClass) {
        trueYearValue += 1;
    }
    return trueYearValue;
};
