const validate = (values) => {
    const errors = {};
    const { r_title, r_quantity, r_days } = values;
    if (!r_title) {
        errors.r_title = 'Tên tiêu đề không được để trống!';
    } else if (r_title.length > 500) {
        errors.r_title = 'Tên tiêu đề không được nhập quá 500 ký tự !';
    }
    if (r_quantity <= 0) {
        errors.r_quantity = 'Số lượng phải lớn hơn 0!';
    }
    if (r_days <= 0) {
        errors.r_days = 'Số ngày phải lớn hơn 0!';
    }
    // if (r_summary.length > 4000) {
    //     errors.r_summary = 'Giớ thiệu không được quá 4000 ký tự!!';
    // }
    // if (!n_date) {
    //     errors.n_date = 'Ngày nhập không được để trống!';
    // }
    return errors;
};

export default validate;
