import { API_ACCOUNT_ENDPOINT } from '@app/constants/api';
import axiosService from '@app/services/axiosService';

const BASE_URL = `${API_ACCOUNT_ENDPOINT}/v1/sc/AccountUser`;

const LIST_URL = {
    ADMIN_CREATE: `${BASE_URL}/BcnCreate`,
    GET_ACCOUNT_BY_TYPE: `${BASE_URL}/GetAccountByType`,
    ADMIN_RESET_PASSWORD: `${BASE_URL}/AdminResetPassword`,
    CHANGE_PASSWORD: `${API_ACCOUNT_ENDPOINT}/v1/ScAccount/ScUser/ChangePassword`,
};

/**
 * Tạo tài khoản admin cho trường/tổ chức
 * Chỉ dùng cho tài khoản Bão Công Nghệ
 * @param {Object} user: đối tượng Sc User
 * @returns
 */
export const apiAdminSave = (user) =>
    axiosService.post(LIST_URL.ADMIN_CREATE, user);

/**
 *Lấy tài khoản theo kiểu SC_ACCOUNT_TYPE
 * @param {Object} model
 * @param {number} model.type: Kiểu tài khoản
 * @param {string} model.o_id: Mã tổ chức
 */
export const apiGetAccountByType = (model) =>
    axiosService.post(LIST_URL.GET_ACCOUNT_BY_TYPE, model);

/**
 * Reset password
 * @param {Object} model
 * @param {string} model.o_id: Mã tổ chức
 * @param {number} model.su_id: Id user account
 */
export const apiAdminResetPassword = (model) =>
    axiosService.post(LIST_URL.ADMIN_RESET_PASSWORD, model);

const _scAccountApis = {
    /**
     * Thay đổi mật khẩu
     * @param {*} dataPost: dữ liệu truyền lên
     * @param {string} dataPost.curentPass: mật khẩu hiện tải
     * @param {string} dataPost.newPass: mật khẩu mới
     * @param {string} dataPost.newPassConfirm: xác nhận mật khẩu mới
     * @returns
     */
    changePassword(dataPost) {
        return axiosService.post(LIST_URL.CHANGE_PASSWORD, dataPost);
    },
};

export default _scAccountApis;
