import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from './constants';
import { login as loginApi } from '@app/apis/login';
import { showError } from '@app/utils/alert';
import { callLoading } from '@app/utils/saga.utils';
import { updateToken } from './actions';
import { TOKEN_NAME } from '@app/constants/global.const';

function* login({ payload }) {
    yield callLoading(function* doRequest() {
        const res = yield call(loginApi, payload.authModel);
        const { status, data } = res;
        if (status === 200) {
            localStorage.setItem(TOKEN_NAME, data.token);
            yield put(updateToken(data.token));
        } else {
            showError(status, data);
        }
    });
}
function* loginSaga() {
    yield takeLatest(types.LOGIN, login);
}
export default loginSaga;
