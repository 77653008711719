export class PropertySettingModel {
    constructor(data) {
        this.u_id = data.u_id || 0;
        this.o_id = data.o_id || '';
        this.p_id = data.p_id || 0;
        this.ps_id = data.ps_id || 0;
        this.ps_code = data.ps_code || '';
        this.ps_value = data.ps_value || '';
        this.ps_status = data.ps_status || 1;
        this.ps_ip = data.ps_ip || 0;
        this.isEdit = data.isEdit || false;
        this.isValid = data.isValid === 1;
    }
}
