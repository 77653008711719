const MODULE_NAME = 'inventoryReducer';

const InventorySelector = {
    inventoryList: (state) => state[MODULE_NAME].inventoryList,
    inventoryByMenuList: (state) => state[MODULE_NAME].inventoryByMenuList,
    currentInventory: (state) => state[MODULE_NAME].currentInventory,
    isShowModalIn: (state) => state[MODULE_NAME].isShowModalIn,
    isShowModalOut: (state) => state[MODULE_NAME].isShowModalOut,
    isCheckAll: (state) => state[MODULE_NAME].isCheckAll,
};

export default InventorySelector;
