/**
 * Tên token, dùng trong localStorage
 */
export const TOKEN_NAME = '___bcnToken';

/**
 * Tên code ngôn ngữ, dùng trong localStorage
 */
export const LANGUAGE_CODE = '___bcnLang';
/**
 * Trường đang chọn, dùng trong localStorage
 */
export const SCHOOL_SELECT = '___bcnSchoolSelect';

/**
 * Loại địa phương
 */
export const LOCATION_TYPES = {
    /**
     * Đất nước
     */
    COUNTRY: 1,

    /**
     * Thành phố/tỉnh
     */
    CITY: 2,

    /**
     * Quận/huyện
     */
    DISTRICT: 3,

    /**
     * Phường xã
     */
    WARD: 4,
};

export const IMAGE_FOLDER_UPLOAD = {
    USER: 'insight/userAvatar',
    ORGANIZATION: 'insight/organization',
    PRODUCT: 'insight/product',
    SHOP: 'insight/shop',
    // FILE
    SUPPORTTICKET: 'insight/supportTicket',
    CONTRACTVOICECLOUD: 'insight/contractVoiceCloud',
    // Blog
    BLOG: 'insight/blogImage',
};

export const IMAGE_SERVER_URL = 'http://localhost:9004/images';
// export const IMAGE_SERVER_SERVICE_URL = 'http://localhost:9005/images';
export const IMAGE_SERVER_SERVICE_URL = 'http://api.sc.edu.vn/images';
// export const FILE_SERVER_URL = 'http://api.sc.edu.vn/files';
export const FILE_SERVER_URL = 'http://localhost:9004/files';

export const ACTION_API_TYPES = {
    GET: 1,
    ADD: 2,
    EDIT: 3,
    DELETE: 4,
    CANCEL: 5,
    EXTEND: 6,
};

export const SWEET_ALERT_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    QUESTION: 'question',
};

export const ARR_WEB_SETTING = {
    SMTP_SERVER: 'SMTP_SERVER',
    SMTP_EMAIL: 'SMTP_EMAIL',
    SMTP_PASSWORD: 'SMTP_PASSWORD',
    SEO_TITLEPAGE: 'SEO_TITLEPAGE',
    SEO_KEYWORD: 'SEO_KEYWORD',
    SEO_DESCRIPTION: 'SEO_DESCRIPTION',
    PAGING_NEWS: 'PAGING_NEWS',
    PAGING_ALBUM: 'PAGING_ALBUM',
};

/**
 * Danh mục tổ chức
 */
export const ORGANIZATION_CATEGORY = {
    /**
     * Công ty Bão công nghệ
     */
    COMPANY: 1,

    /**
     * Nhà cung cấp
     */
    SUPPLIER: 2,

    /**
     * Công ty/Tổ chức
     */
    ORGANIZATION: 3,

    /**
     * Bộ giáo dục
     */
    MINISTRY_OF_EDUCATION_AND_TRAINING: 4,

    /**
     * Sở giáo dục
     */
    DEPARTMENT_OF_EDUCATION_AND_TRAINING: 5,

    /**
     * Phòng giáo dục
     */
    DIVISION_OF_EDUCATION_AND_TRAINING: 6,

    /**
     * Trường học
     */
    SCHOOL: 7,

    /**
     * Giáo viên
     */
    TEACHER: 8,

    /**
     * Phụ huynh
     */
    PARENT: 9,

    /**
     * Sinh viên
     */
    STUDENT: 10,
};

/**
 * Loại tài khoản SC
 */
export const SC_ACCOUNT_TYPE = {
    /**
     * Tài khoản admin của trường/tổ chức
     */
    SCHOOL_ADMIN: 1,

    /**
     * Tài khoản giáo viên
     */
    TEACHER: 2,

    /**
     * Tài khoản phụ huynh
     */
    PARENT: 3,

    /**
     * Tài khoản học sinh
     */
    STUDENT: 4,
};

/**
 * Danh sách Id các hệ thống của Bão Công Nghệ
 */
export const BCN_SYSTEM_ID = {
    /**
     * Hệ thống SC
     */
    SC: 1,

    /**
     * Hệ thống CRM
     */
    CRM: 2,
};

/**
 * Danh sách các hệ thống của Bão Công Nghệ
 */
export const BcnSystemList = [
    {
        id: BCN_SYSTEM_ID.SC,
        name: 'Hệ thống SC',
    },
    {
        id: BCN_SYSTEM_ID.CRM,
        name: 'Hệ thống CRM',
    },
];

export const AUTO_INCOME = {
    /**
     * Tạo bảng điểm danh tự động
     */
    ATTENDANCE: 1,

    /**
     * Tạo phiếu thu tự động
     */
    RECEIPT: 2,
};

export const GLOBAL = {
    REPORT_ASSISTANT_TITLE: 'KT. Hiệu trưởng\nPhó Hiệu trưởng',
    REPORT_PRINCIPAL_TITLE: 'Hiệu trưởng',
};
