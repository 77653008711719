import React, { Fragment } from 'react';
import Body from '@app/components/Layout/Body/Body';
import MainHeader from '@app/components/Layout/Header/MainHeader';
import Menu from '@app/components/Layout/Menu';
import { Redirect } from 'react-router-dom';
import { checkExpriedToken } from '@app/utils/utils';
import { SCHOOL_SELECT, TOKEN_NAME } from '@app/constants/global.const';
import InitialData from './InitialData';

import SCLoading from '@app/components/Layout/Loading';

const AdminPage = () => {
    window.addEventListener('storage', (e) => {
        const { key } = e;
        if (key === SCHOOL_SELECT) {
            window.location.reload();
        }
    });
    const checkAuthen = () => {
        let xhtml = (
            <Fragment>
                <MainHeader />
                <InitialData />
                <div className="page-container">
                    <div className="page-content">
                        <Menu />
                        <div className="content-wrapper">
                            <Body />
                        </div>
                    </div>
                </div>
                <SCLoading />
            </Fragment>
        );

        const token = localStorage.getItem(TOKEN_NAME);
        if (!token || (token && !checkExpriedToken(token))) {
            xhtml = <Redirect to="/login" />;
        }

        return xhtml;
    };

    return checkAuthen();
};

export default AdminPage;
