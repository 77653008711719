const updateItemIntoList = (dataItem, dataList, fieldId) => {
    // Kiểm tra đối tượng có nằm trong danh sách không
    const itemCheck = dataList.find((o) => o[fieldId] === dataItem[fieldId]);

    if (itemCheck) {
        dataList = dataList.map((o) => {
            if (o[fieldId] === itemCheck[fieldId]) {
                o = {
                    ...o,
                    ...dataItem,
                };
            }
            return o;
        });
    } else {
        dataList = [dataItem, ...dataList];
    }

    return dataList;
};
export { updateItemIntoList };
