export const BUTTON_FILTER_ACTION_TYPE = {
    SEARCH: 'search',
    ADD: 'add',
    CHANGE: 'change',
    EXCEL: 'excel',
    PRINT: 'print',
    SETTING: 'setting',
    SAVE: 'save',
    DELETE: 'delete',
    APPROVE: 'approve',
    CANCEL: 'cancel',
    PAYMENT: 'payment',
    LIST: 'list',
    ADD_NEW: 'addNew',
};

export const BUTTON_TABLE_ACTION_TYPE = {
    ADD_NEW: 'addNew',
    SAVE: 'save',
    DELETE: 'delete',
    EDIT: 'edit',
    VIEW: 'view',
    LOCATION: 'location',
    ACCOUNT: 'account',
    RESET: 'reset',
    PRINT: 'print',
};

export const BUTTON_POPUP_ACTION_TYPE = {
    SAVE: 'save',
    PRINT: 'print',
    CLOSE: 'close',
};

export const getTypeButton = (buttonType) => {
    let classButton = '';
    switch (buttonType) {
        case BUTTON_FILTER_ACTION_TYPE.SEARCH: {
            classButton = 'glyphicon glyphicon-search';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.ADD: {
            classButton = 'glyphicon glyphicon-plus';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.ADD_NEW: {
            classButton = 'icon-plus-circle2 position-left';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.CHANGE: {
            classButton = 'icon-loop3';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.EXCEL: {
            classButton = 'icon-file-excel';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.PRINT: {
            classButton = 'glyphicon glyphicon-print';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.SETTING: {
            classButton = 'icon-gear';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.SAVE: {
            classButton = 'icon-floppy-disk position-left';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.DELETE: {
            classButton = 'glyphicon glyphicon-trash';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.APPROVE: {
            classButton = 'icon-stamp';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.CANCEL: {
            classButton = 'glyphicon glyphicon-remove';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.PAYMENT: {
            classButton = 'icon-cash4';
            break;
        }
        case BUTTON_FILTER_ACTION_TYPE.LIST: {
            classButton = 'icon-list3';
            break;
        }
        default: {
            break;
        }
    }
    return classButton;
};

export const getTableButtonType = (buttonType) => {
    const classButton = {};
    switch (buttonType) {
        case BUTTON_TABLE_ACTION_TYPE.EDIT: {
            classButton.icon = 'icon-pencil7';
            classButton.btnClass = 'btn-primary';
            break;
        }
        case BUTTON_TABLE_ACTION_TYPE.DELETE: {
            classButton.icon = 'icon-cross2';
            classButton.btnClass = 'btn-danger';
            break;
        }
        case BUTTON_TABLE_ACTION_TYPE.VIEW: {
            classButton.icon = 'icon-file-eye';
            classButton.btnClass = 'btn-primary';
            break;
        }
        case BUTTON_TABLE_ACTION_TYPE.LOCATION: {
            classButton.icon = 'icon-location4';
            classButton.btnClass = 'btn-success';
            break;
        }
        case BUTTON_TABLE_ACTION_TYPE.ACCOUNT: {
            classButton.icon = 'icon-user';
            classButton.btnClass = 'bg-yellow';
            break;
        }
        case BUTTON_TABLE_ACTION_TYPE.PRINT: {
            classButton.icon = 'icon-printer2';
            classButton.btnClass = 'btn-success';
            break;
        }
        case BUTTON_TABLE_ACTION_TYPE.RESET: {
            classButton.icon = 'icon-sync';
            classButton.btnClass =
                'btn btn-xs btn-icon btn-rounded btn-primary';
            break;
        }
        default: {
            break;
        }
    }
    return classButton;
};

export const getPopupButtonType = (buttonType) => {
    const classButton = {};
    switch (buttonType) {
        case BUTTON_POPUP_ACTION_TYPE.SAVE: {
            classButton.icon = 'icon-floppy-disk';
            classButton.btnClass = 'btn btn-default btn-rounded';
            break;
        }
        case BUTTON_POPUP_ACTION_TYPE.CLOSE: {
            classButton.icon = 'icon-switch2 ';
            classButton.btnClass = 'btn btn-default btn-rounded';
            break;
        }
        case BUTTON_POPUP_ACTION_TYPE.PRINT: {
            classButton.icon = 'glyphicon glyphicon-print';
            classButton.btnClass = 'btn btn-default btn-rounded';
            break;
        }
        default: {
            break;
        }
    }
    return classButton;
};
