import * as types from './constants';

export const login = (authModel) => ({
    type: types.LOGIN,
    payload: {
        authModel,
    },
});

export const updateToken = (token) => ({
    type: types.UPDATE_TOKEN,
    payload: {
        token,
    },
});
