import axiosService from '../../services/axiosService';
import { API_SC_GATEWAY_ENDPOINT } from '../../constants/api';

const BASE_URL = `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/ToolSC/FoodKid/Dish`;

const LIST_URL = {
    GET_LIST: `${BASE_URL}/GetList`,
};

const _fkDishApis = {
    getList(orgId) {
        return axiosService.get(`${LIST_URL.GET_LIST}/${orgId}`);
    },
};

export default _fkDishApis;
