import React from 'react';
import ButtonTableAction from '@app/components/UI/Button/ButtonTableAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

const OrganizationItem = ({
    sortOrder,
    organization,
    onCopySetting,
    onCancelLinked,
    onApproveCancel,
}) => {
    const renderStatus = (status) => {
        const objStatus = {
            classLabel: '',
            classIcon: '',
            label: '',
        };
        switch (status) {
            case 1: {
                objStatus.classLabel = 'label-primary';
                objStatus.classIcon = 'icon-hour-glass2';
                objStatus.label = 'Chờ đơn vị xác nhận';

                break;
            }
            case 2: {
                objStatus.classLabel = 'label-success';
                objStatus.classIcon = 'icon-checkmark4';
                objStatus.label = 'Kết nối thành công';

                break;
            }
            case 3: {
                objStatus.classLabel = 'label-danger';
                objStatus.classIcon = 'icon-x';
                objStatus.label = 'Đơn vị yêu cầu hủy kết nối';

                break;
            }
            default:
        }

        return objStatus;
    };

    return (
        <tr>
            <td className="text-center">{sortOrder}</td>
            <td>
                <span className="badge badge-info pull-right margin-left-5">
                    {organization.scaleName}
                </span>
                <span className="badge bg-slate pull-right ">
                    {organization.typeName}
                </span>
                {organization?.o_name || ''}
                <div className="display-block text-muted">
                    {/* {organization?.wardName} */}
                </div>
            </td>
            <td>
                {organization?.o_representative || ''}
                <div className="display-block text-muted">
                    {organization?.o_position || ''}
                </div>
            </td>
            <td>
                {organization?.o_email || ''}
                <div className="display-block text-muted">
                    {organization?.o_cellphone || ''}
                </div>
            </td>
            <td>
                <span
                    className={`label ${
                        renderStatus(organization.o_connectionstatus)
                            ?.classLabel
                    }`}
                >
                    <i
                        className={
                            renderStatus(organization.o_connectionstatus)
                                ?.classIcon
                        }
                    />
                    {` ${renderStatus(organization.o_connectionstatus)?.label}`}
                </span>
            </td>

            {organization?.o_connectionstatus === 1 ? (
                <td className="text-center">&nbsp;</td>
            ) : (
                <td className="text-center">
                    <div className="btn-table-group">
                        {organization?.o_connectionstatus === 2 ? (
                            <ButtonTableAction
                                tootTipTitle="Sao chép cấu hình"
                                buttonType={BUTTON_ACTION_TYPE.SETTING}
                                onClick={() => onCopySetting(organization)}
                            />
                        ) : null}
                        <ButtonTableAction
                            tootTipTitle={
                                organization?.o_connectionstatus === 2
                                    ? 'Hủy liên kết'
                                    : 'Phê duyệt'
                            }
                            buttonType={
                                organization?.o_connectionstatus === 2
                                    ? BUTTON_ACTION_TYPE.DELETE
                                    : BUTTON_ACTION_TYPE.APPROVE
                            }
                            onClick={
                                organization?.o_connectionstatus === 2
                                    ? () => onCancelLinked(organization)
                                    : () => onApproveCancel(organization)
                            }
                        />
                    </div>
                </td>
            )}
        </tr>
    );
};

export default OrganizationItem;
