import React, { useEffect } from 'react';
import './index.scss';
import logoQH from '@app/assets/images/logo_QH.png';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import * as accountActions from '@app/redux/login/actions';
import validate from './validate';
import { useHistory } from 'react-router-dom';
import { deleteDbCache } from '@app/services/localDBService/initDB';
import LoginSelector from '@app/redux/login/selector';
import co from 'co';
import { doPingPong } from '@app/apis/login';
import { callLoading } from '@app/utils/saga.utils';
import { ORGANIZATION_CATEGORY } from '@app/constants/global.const';
import SCLoading from '@app/components/Layout/Loading';

const Login = ({ handleSubmit, invalid, submitting }) => {
    const history = useHistory();
    const token = useSelector(LoginSelector.token);
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            co(function* () {
                yield callLoading(function* () {
                    const { status } = yield doPingPong();
                    if (status === 200) {
                        history.push('/');
                    }
                });
            });
        }
        deleteDbCache();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const handleSubmitForm = (data) => {
        const auth = {
            username: data.username,
            password: data.password,
            type: ORGANIZATION_CATEGORY.COMPANY,
        };
        dispatch(accountActions.login(auth));
    };

    return (
        <div className="login-container" style={{ marginTop: '-50px' }}>
            <div className="navbar navbar-inverse">
                <div className="navbar-header">
                    <a className="navbar-brand" href="/">
                        <img src={logoQH} alt="" />
                    </a>
                </div>
                <div className="navbar-collapse collapse" id="navbar-mobile" />
            </div>

            <div className="page-container">
                <div className="page-content">
                    <div className="">
                        <div className="content">
                            <form onSubmit={handleSubmit(handleSubmitForm)}>
                                <div className="panel panel-body login-form">
                                    <div className="text-center">
                                        <div className="icon-object border-slate-300 text-slate-300">
                                            <i className="icon-reading" />
                                        </div>
                                        <h5 className="content-group">
                                            Đăng nhập vào hệ thống
                                            <small className="display-block">
                                                Nhập thông tin đăng nhập
                                            </small>
                                        </h5>
                                    </div>
                                    <div className="form-group has-feedback has-feedback-left">
                                        <Field
                                            name="username"
                                            component="input"
                                            type="text"
                                            className="form-control"
                                            placeholder="Tên đăng nhập"
                                        />

                                        <div className="form-control-feedback">
                                            <i className="icon-user text-muted" />
                                        </div>
                                    </div>
                                    <div className="form-group has-feedback has-feedback-left">
                                        <Field
                                            name="password"
                                            component="input"
                                            type="password"
                                            className="form-control"
                                            placeholder="Mật khẩu"
                                        />

                                        <div className="form-control-feedback">
                                            <i className="icon-lock2 text-muted" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className="btn bg-yellow btn-block"
                                            disabled={invalid || submitting}
                                        >
                                            Đăng nhập
                                            <i className="icon-circle-right2 position-right" />
                                        </button>
                                    </div>
                                    <div className="text-center">
                                        <a href="login_password_recover.html">
                                            Quên mật khẩu?
                                        </a>
                                    </div>
                                </div>
                            </form>
                            <div className="footer text-muted text-center">
                                © 2022.
                                {/* <a href="/">Quốc Hưng</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SCLoading />
        </div>
    );
};
const FORM_NAME = 'LOGIN_FORM';

const withReduxForm = reduxForm({
    form: FORM_NAME,
    validate,
});

export default withReduxForm(Login);
