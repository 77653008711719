const reportUtils = {
    /** Lấy thông tin của địa phương của trường
     * Ưu tiên lấy từ setting trường, sau đó sẽ đến địa chỉ trong tổ chức*/
    getLocationInfo(schoolInfo, locationInfo) {
        if (!schoolInfo && !locationInfo) {
            return {
                province: '',
                district: '',
                wards: '',
                tenThanhPho: '',
                tenQuan: '',
                tenPhuong: '',
                cityName: '',
                districtName: '',
                wardName: '',
                city_name: '',
                district_name: '',
                ward_name: '',
            };
        }
        if (!schoolInfo) schoolInfo = {};
        if (!locationInfo) locationInfo = {};
        const { cityName, districtName, wardName } = locationInfo;
        const { phuong_xa, quan_huyen, thanh_pho } = schoolInfo;
        const province = thanh_pho || cityName || '';
        const district = quan_huyen || districtName || '';
        const wards = phuong_xa || wardName || '';
        return {
            province,
            district,
            wards,
            tenThanhPho: province,
            tenQuan: district,
            tenPhuong: wards,
            cityName: province,
            districtName: district,
            wardName: wards,
            city_name: province,
            district_name: district,
            ward_name: wards,
        };
    },
};

export default reportUtils;
