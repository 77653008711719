import { API_BCN_PRODUCT_ENDPOINT } from '@app/constants/api';
import axiosService from '@app/services/axiosService';

const BASE_URL = `${API_BCN_PRODUCT_ENDPOINT}/v1/Product`;

const LIST_URL = {
    GET_LIST: `${BASE_URL}/GetAll`,
};

export const apiGetListProduct = () => axiosService.get(LIST_URL.GET_LIST);
