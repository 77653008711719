const prefix = 'COPY_MENU_';

export const GET_LIST_MENU_BY_SCHOOL = `${prefix}GET_LIST_MENU_BY_SCHOOL`;
export const GET_LIST_MENU_BY_SCHOOL_SUCCESS = `${prefix}GET_LIST_MENU_BY_SCHOOL_SUCCESS`;

export const GET_KIDGROUP = `${prefix}GET_KIDGROUP`;
export const GET_KIDGROUP_SUCCESS = `${prefix}GET_KIDGROUP_SUCCESS`;

export const UPDATE_MENU_NEW = `${prefix}UPDATE_MENU_NEW`;
export const CHANGE_SCHOOL_COPY = `${prefix}CHANGE_SCHOOL_COPY`;
export const CHANGE_SELECTED_ROW = `${prefix}CHANGE_SELECTED_ROW`;

export const OPEN_MODAL_IMPORT = `${prefix}OPEN_MODAL_IMPORT`;
export const CLOSE_MODAL_IMPORT = `${prefix}CLOSE_MODAL_IMPORT`;

export const SAVE_MENU_SAMPLE = `${prefix}SAVE_MENU_SAMPLE`;
export const SAVE_MENU_SAMPLE_SUCCESS = `${prefix}SAVE_MENU_SAMPLE_SUCCESS`;

export const IMPORT_MENU_FROM_FILE = `${prefix}IMPORT_MENU_FROM_FILE`;
export const IMPORT_MENU_FROM_FILE_SUCCESS = `${prefix}IMPORT_MENU_FROM_FILE_SUCCESS`;

export const RESET = `${prefix}RESET`;
