import { put, delay } from 'redux-saga/effects';
import { changePageLoading } from '../redux/others/slice';

export function* callLoading(functionIsCalled) {
    yield put(changePageLoading(true));
    yield functionIsCalled();
    yield delay(100);
    yield put(changePageLoading(false));
}

/**
 * Dùng để gọi loading trong trường hợp không dùng saga
 * @param {*} dispatch : là useDispatch của redux
 * @param {*} asyncFunction: 1 function bất đồng bộ
 */
export const callLoadingAsynFunc = async (dispatch, asyncFunction) => {
    dispatch(changePageLoading(true));
    await asyncFunction;
    dispatch(changePageLoading(false));
};
