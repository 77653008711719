export const DATE_RANGE_LIMIT_TYPE = {
    DAYS: 1,
    WEEK: 2,
    MONTH: 3,
};

class RangeDateService {
    getTypeLimit = (limitType) => {
        let strValue = 'days';
        switch (limitType) {
            case DATE_RANGE_LIMIT_TYPE.WEEK: {
                strValue = 'week';
                break;
            }
            case DATE_RANGE_LIMIT_TYPE.MONTH: {
                strValue = 'month';
                break;
            }
            default: {
                break;
            }
        }

        return strValue;
    };
}
export default new RangeDateService();
