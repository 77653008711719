import _stringUtils from '@app/utils/string.utils';

const spaceRegex = /\s/g;
const validate = (values) => {
    const errors = {};
    const { currentPass, newPass, confirmPass } = values;
    if (_stringUtils.isEmpty(currentPass)) {
        errors.currentPass = 'Mật khẩu hiện tại không được để trống';
    }
    if (_stringUtils.isEmpty(newPass)) {
        errors.newPass = 'Mật khẩu mới không được để trống';
    }
    if ((newPass ?? '').length < 6) {
        errors.newPass = 'Mật khẩu mới phải có tối thiểu 6 kí tự';
    }
    if (spaceRegex.test(newPass)) {
        errors.newPass = 'Mật khẩu mới không được có khoảng trắng';
    }

    if (_stringUtils.isEmpty(confirmPass)) {
        errors.confirmPass = 'Xác nhận mật khẩu không được để trống';
    }
    if (newPass !== confirmPass) {
        errors.confirmPass =
            'Mật khẩu mới và xác nhận mật khẩu không trùng nhau';
    }

    return errors;
};

export default validate;
