import * as types from './constants';
import { LOCATION_TYPES } from '../../../constants';
import * as _ from 'lodash';
import { LocationModel } from '../../../models/location/location.model';

const initState = {
    list: [], // danh sách dữ liệu
    isShowModal: false, // trạng thái model
    editItem: {}, // obj chỉnh sửa
    selectList: [],
    selectId: -1,
};

const districtReducer = (state = initState, action) => {
    const { payload } = action;
    switch (action.type) {
        case types.GET_DISTRICTS_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                list: data,
            };
        }

        case types.ADD_DISTRICT: {
            const { cityId } = action.payload;
            const { list } = state;
            const editItem = new LocationModel({
                l_parentid: cityId,
                l_type: LOCATION_TYPES.DISTRICT,
                l_sortorder: list.length + 1,
            });
            return {
                ...state,
                editItem,
                isShowModal: true,
            };
        }

        case types.EDIT_DISTRICT: {
            const { data } = payload;
            return {
                ...state,
                editItem: new LocationModel(data),
                isShowModal: true,
            };
        }

        case types.SAVE_DISTRICT_SUCCESS: {
            const { data, isAdd } = action.payload;
            let { list } = state;
            if (isAdd) {
                list = [...list, data];
            } else {
                list = list.map((item) => {
                    if (item.id === data.id) {
                        item = {
                            ...item,
                            ...data,
                        };
                    }
                    return item;
                });
            }
            list = _.orderBy(list, ['l_sortorder'], ['asc']);
            const editItem = new LocationModel({
                l_sortorder: list.length + 1,
                l_type: LOCATION_TYPES.DISTRICT,
                l_parentid: data.l_parentid,
            });

            return {
                ...state,
                editItem,
                list,
                isShowModal: isAdd,
            };
        }

        case types.DELETE_DISTRICT_SUCCESS: {
            const { id } = action.payload;
            const list = _.remove(state.list, (item) => item.l_id !== id);
            return {
                ...state,
                list,
            };
        }

        case types.GET_DISTRICT_SELECT_LIST_SUCCESS: {
            const { list, defaultValue } = action.payload;
            let { selectId } = state;
            if (defaultValue) {
                selectId = defaultValue;
            } else {
                selectId = list.length === 0 ? -1 : list[0].l_id;
            }
            return {
                ...state,
                selectList: list,
                selectId,
            };
        }

        case types.CHANGE_DISTRICT_SELECT_LIST: {
            const { id } = action.payload;
            return {
                ...state,
                selectId: id,
            };
        }

        case types.SHOW_DISTRICT_MODAL: {
            return {
                ...state,
                isShowModal: true,
            };
        }

        case types.CLOSE_DISTRICT_MODAL: {
            return {
                ...state,
                isShowModal: false,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default districtReducer;
