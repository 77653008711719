import _ from 'lodash';

/** Các tiện ích liên quan đến Url/Link API */
const _urlUtils = {
    /**
     * Tạo search param
     * @param {object} objParams: đối tượng tạo params
     * @returns
     */
    createSearchParams(objParams) {
        if (!objParams || _.isEmpty(objParams)) return '';
        return new URLSearchParams(objParams).toString();
    },

    /**
     * Tạo link search
     * @param {string} linkApi: Link gốc
     * @param {object} objParams: đối tượng tạo params
     * @returns
     */
    creatLinkSearch(linkApi, objParams) {
        if (!linkApi) {
            return '';
        }
        const searchParams = this.createSearchParams(objParams);

        if (!searchParams) {
            return linkApi;
        }
        return `${linkApi}?${searchParams}`;
    },
};

export default _urlUtils;
