const MODULE_NAME = 'others';

const OthersSelector = {
    isLoading: (state) => state[MODULE_NAME].isLoading,
    yearCache: (state) => state[MODULE_NAME].yearCache,
    yearCacheSelect: (state) => state[MODULE_NAME].yearCacheSelect,
    organizationInfo: (state) => state[MODULE_NAME].organizationInfo,
    schoolInfo: (state) => state[MODULE_NAME].schoolInfo,
    schoolProperty: (state) => state[MODULE_NAME].schoolProperty,
    locationInfo: (state) => state[MODULE_NAME].locationInfo,
    units: (state) => state[MODULE_NAME].units,
    ageGroup: (state) => state[MODULE_NAME].ageGroup,
    kidGroup: (state) => state[MODULE_NAME].kidGroup,
    mealService: (state) => state[MODULE_NAME].mealService,
    supplier: (state) => state[MODULE_NAME].supplier,
    dish: (state) => state[MODULE_NAME].dish,
    inventories: (state) => state[MODULE_NAME].inventories,
    packaging: (state) => state[MODULE_NAME].packaging,
    routeList: (state) => state[MODULE_NAME].routeList,
    schoolFood: (state) => state[MODULE_NAME].schoolFood,
    invoiceForm: (state) => state[MODULE_NAME].invoiceForm,
    proFood: (state) => state[MODULE_NAME].proFood,
};

export default OthersSelector;
