import React from 'react';

const AccountList = (props) => (
    <div className="row">
        <div className="col-lg-12">
            <div className="panel panel-white">
                <table className="table table-togglable table-hover table-bordered table-striped datatable-pagination input-table">
                    <thead>
                        <tr>
                            <th
                                className="text-center"
                                style={{ width: '35px' }}
                            >
                                STT
                            </th>
                            <th
                                className="text-center"
                                data-toggle="true"
                                style={{ width: '20%' }}
                            >
                                Họ tên
                                <br />
                                <span className="text-muted">Vai trò</span>
                            </th>
                            <th className="text-center">
                                Ngày sinh
                                <br />
                                <span className="text-muted">Giới tính</span>
                            </th>
                            <th className="text-center">
                                Email
                                <br />
                                <span className="text-muted">Điện thoại</span>
                            </th>
                            <th className="text-center">Tên đăng nhập</th>
                            <th className="text-center">Lần đăng nhập cuối</th>
                            <th
                                className="text-center"
                                style={{ width: '8%' }}
                                data-hide="phone"
                            >
                                Đang hoạt động
                            </th>
                            <th
                                className="text-center"
                                style={{ width: '100px' }}
                            >
                                Hành động
                            </th>
                        </tr>
                    </thead>
                    <tbody>{props.children}</tbody>
                </table>
            </div>
        </div>
    </div>
);

export default AccountList;
