import React, { Component } from 'react';
import PageHeader from '../../components/Layout/PageHeader/PageHeader';

class NotFountPage extends Component {
    render() {
        return <PageHeader pageTitle="Không tìm thấy trang" />;
    }
}

export default NotFountPage;
