/* eslint-disable no-await-in-loop */
import React, { useEffect, useRef, useState } from 'react';
import IncomeItem from './Item';
import _schoolSelector from '@app/redux/school/school.selector';
import { useSelector } from 'react-redux';
import { apiDoGetListByOId as apiGetClass } from '@app/apis/schools/class';
import _ from 'lodash';
import { STATUS_PROCESS } from '@app/constants';
import { apiGetByYear as apiGetStudent } from '@app/apis/student/studentClass.api';
import _incomeExportPDFService from './_service';
import _othersSelector from '@app/redux/others/selector';
import moment from 'moment';
import globalUtils from '@app/utils/global.utils';

const IncomeList = ({
    listReport,
    dateFrom,
    countClickExport,
    onChangeListReport,
}) => {
    const classList = useRef([]);
    const studentList = useRef([]);
    const [yearId, setYearId] = useState();
    const schoolInfo = useSelector(_othersSelector.schoolInfo);
    const locationInfo = useSelector(_othersSelector.locationInfo);
    const organizationInfo = useSelector(_othersSelector.organizationInfo);
    const oId = useSelector(_schoolSelector.currentSchool);

    const month = +moment.unix(dateFrom).format('MM');
    /**
     * Lấy Id năm học theo tháng chuyển lớp
     */
    useEffect(() => {
        if (_.isEmpty(schoolInfo)) return;

        const thangChuyenLop =
            +schoolInfo.thang_chuyen_lop > 0 ? +schoolInfo.thang_chuyen_lop : 9;

        const yearSelected = +moment.unix(dateFrom).year();
        const monthSelected = +moment.unix(dateFrom).format('MM');

        setYearId(
            globalUtils.getYearId(yearSelected, monthSelected, thangChuyenLop)
        );
    }, [dateFrom, schoolInfo]);

    const onSetStatus = (listItem, statusValue, message = '') => {
        const _listId = listItem.map((o) => o.id);
        onChangeListReport((data) =>
            data.map((o) => {
                if (_.includes(_listId, o.id)) {
                    o = {
                        ...o,
                        status: statusValue,
                        message,
                    };
                    if (
                        statusValue === STATUS_PROCESS.DONE ||
                        statusValue === STATUS_PROCESS.WARNING
                    ) {
                        o = {
                            ...o,
                            isCheck: false,
                        };
                    }
                }
                return o;
            })
        );
    };

    const getData = async (_oId, _yearId, _month) => {
        const listCheck = listReport.filter(
            (o) => o.isCheck && o.category === 2
        );

        if (listCheck.length === 0) return;

        if (classList.current.length === 0) {
            onSetStatus(
                listCheck,
                STATUS_PROCESS.WARNING,
                'Năm học chưa có lớp học'
            );
            return;
        }

        onSetStatus(listCheck, STATUS_PROCESS.AWAITING);
        // eslint-disable-next-line no-restricted-syntax
        for (const elem of listCheck) {
            onSetStatus([elem], STATUS_PROCESS.PROCESSING);
            let isDone = false;
            switch (elem.code) {
                case 'bang_diem_danh':
                case 'bang_diem_danh_trong': {
                    const isEmpty = elem.code === 'bang_diem_danh_trong';
                    isDone = await _incomeExportPDFService.doExportBangDiemDanh(
                        _oId,
                        _yearId,
                        _month,
                        classList.current,
                        studentList.current,
                        locationInfo,
                        organizationInfo,
                        schoolInfo,
                        isEmpty
                    );
                    break;
                }
                case 'tong_hop_diem_danh': {
                    isDone =
                        await _incomeExportPDFService.doExportTongHopBangDiemDanh(
                            _oId,
                            _yearId,
                            _month,
                            classList.current,
                            locationInfo,
                            organizationInfo,
                            schoolInfo
                        );
                    break;
                }
                case 'phieu_bao_dong_tien': {
                    isDone =
                        await _incomeExportPDFService.doExportPhieuBaoDongTien(
                            _oId,
                            _yearId,
                            _month,
                            classList.current,
                            studentList.current,
                            locationInfo,
                            organizationInfo,
                            schoolInfo,
                            false
                        );
                    break;
                }
                case 'bien_lai_thu_tien': {
                    isDone =
                        await _incomeExportPDFService.doExportPhieuBaoDongTien(
                            _oId,
                            _yearId,
                            _month,
                            classList.current,
                            studentList.current,
                            locationInfo,
                            organizationInfo,
                            schoolInfo,
                            true
                        );
                    break;
                }
                case 'so_thu_thanh_toan_tong_hop': {
                    isDone =
                        await _incomeExportPDFService.doExportSoThuThanhToanTongHop(
                            _oId,
                            _yearId,
                            _month,
                            classList.current,
                            studentList.current,
                            locationInfo,
                            schoolInfo,
                            'soBienLai'
                        );
                    break;
                }
                case 'so_thu_thanh_toan_tong_hop_phhs': {
                    isDone =
                        await _incomeExportPDFService.doExportSoThuThanhToanTongHop(
                            _oId,
                            _yearId,
                            _month,
                            classList.current,
                            studentList.current,
                            locationInfo,
                            schoolInfo,
                            'phhs'
                        );
                    break;
                }
                case 'tong_hop_thu_chi_tien_an': {
                    isDone = await _incomeExportPDFService.doExportThuChiTienAn(
                        _oId,
                        _yearId,
                        _month,
                        classList.current,
                        locationInfo,
                        schoolInfo
                    );
                    break;
                }
                case 'so_quy_tien_an': {
                    isDone = await _incomeExportPDFService.doExportSoQuyTienAn(
                        _oId,
                        _yearId,
                        _month,
                        locationInfo,
                        schoolInfo
                    );
                    break;
                }
                default: {
                    break;
                }
            }
            if (isDone) {
                onSetStatus([elem], STATUS_PROCESS.DONE);
            } else {
                onSetStatus([elem], STATUS_PROCESS.ERROR);
            }
        }
    };

    useEffect(() => {
        if (countClickExport && oId) {
            getData(oId, yearId, month);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countClickExport]);

    useEffect(() => {
        if (yearId && oId) {
            apiGetClass({ o_id: oId, sy_id: yearId }).then(
                ({ status, data }) => {
                    if (status === 200) {
                        classList.current = data.data;
                    } else {
                        classList.current = [];
                    }
                }
            );

            apiGetStudent({ o_id: oId, sy_id: yearId }).then(
                ({ status, data }) => {
                    if (status === 200) {
                        studentList.current = data.data;
                    } else {
                        studentList.current = [];
                    }
                }
            );
        }
    }, [oId, yearId]);

    const onCheck = (s, item) => {
        const checkValue = s.target.checked;

        const listCheck = listReport.map((o) => {
            if (o.id === item.id) {
                o = {
                    ...item,
                    isCheck: checkValue,
                };
            }
            return o;
        });

        onChangeListReport(listCheck);
    };

    const showData = () => {
        let xhtml = null;
        xhtml = listReport
            .filter((o) => o.category === 2)
            .map((item) => (
                <IncomeItem
                    key={item.id}
                    item={item}
                    onCheckItem={(s) => onCheck(s, item)}
                />
            ));
        return xhtml;
    };
    return <>{showData()}</>;
};

export default IncomeList;
