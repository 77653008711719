import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ButtonModalAction from '@app/components/UI/Button/ButtonModalAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import { Field, reduxForm } from 'redux-form';
import RenderTextField from '@app/components/Layout/Shares/FormHelpers/TextField';
import renderTextAreaField from '@app/components/Layout/Shares/FormHelpers/TextAreaField';
import DatePickerField from '@app/components/Layout/Shares/FormHelpers/DatimePickerField';
import RenderPriceFieldV2 from '@app/components/Layout/Shares/FormHelpers/PriceField_V2';
import FKMealServiceSelect from '@app/components/_Share/Select/FoodKid/MealServiceSelect/Dynamic';
import {
    PAYMENT_VOUCHER_TYPE,
    RECEIPT_VOUCHER_TYPE,
} from '@app/constants/receiptCost.CONST';
import { MOMENT_RANGE_TYPE } from '@app/constants/datetime.const';
import RangeDateFieldV2 from '@app/components/_Share/Date/RangeDate/RangeDateFieldV2';
import DynamicCostSelectField from '@app/components/_Share/Select/Income/CostList/DynamicField';
import PaymentTypeSelectField from '@app/components/_Share/Select/Income/PaymentTypeSelect/DynamicField';
import DynamicAccountBankSelectField from '@app/components/_Share/Select/Income/AccountBank/DynamicField';
import { setField } from '@app/utils/reduxForm.helpers';
import _ from 'lodash';
import _paymntVoucherHelpers from './paymntVouPage.helpers';
import PaymentVoucherTypeSelectField from '@app/components/_Share/Select/Income/PaymentVoucherTypeSelect/DynamicField';
import alertUtils from '@app/utils/alert';
import { formatDateUnix } from '@app/utils/datetime.utils';

const propTypes = {
    isShow: PropTypes.bool,
};

const FORM_NAME = 'PAYMENT_VOUCHER_FORM';

const PaymentVoucherAddOrEditPopup = ({
    isShow,
    onClose,
    handleSubmit,
    onSubmit,
    submitting,
    invalid,
    initialValues,
    oId,
    ...props
}) => {
    const [paymntVouType, setPaymntVouType] = useState(-1);
    const [dateRange, setDateRange] = useState({});
    const [serviceId, setServiceId] = useState(-1);
    const [maxDateTo, setMaxDateTo] = useState(0);

    const onSetTotal = async () => {
        if (paymntVouType && !_.isEmpty(dateRange)) {
            const iTotal = await _paymntVoucherHelpers.getTotalByDate(
                oId,
                dateRange,
                paymntVouType,
                serviceId
            );

            setField(FORM_NAME, 'i_total', iTotal, props);
        }
    };

    useEffect(() => {
        onSetTotal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, paymntVouType, serviceId]);

    useEffect(() => {
        if (!serviceId) return;
        _paymntVoucherHelpers.getMaxDateTo(oId, paymntVouType).then((res) => {
            setMaxDateTo(res);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymntVouType]);

    const onPaymentVoucherTypeChange = (s) => {
        setPaymntVouType(s);
    };

    const onDateRangeChange = (s) => {
        setDateRange(s);
    };

    const onServiceChange = (s) => {
        setServiceId(s);
    };

    const handleSubmitForm = (dataSave) => {
        if (maxDateTo > 0) {
            const { startDate, endDate } = dataSave.i_daterange_dt;
            const i_datefrom = startDate.unix();
            const i_dateto = endDate.unix();
            const strDate = formatDateUnix(maxDateTo);
            if (i_datefrom <= maxDateTo) {
                alertUtils.showWarning(
                    ` Từ ngày tính tiền không thể nhỏ hơn ngày ${strDate}`
                );
                return;
            }
            if (i_dateto <= maxDateTo) {
                alertUtils.showWarning(
                    ` Đến ngày tính tiền không thể nhỏ hơn ngày ${strDate}`
                );
                return;
            }
        }

        if (onSubmit) {
            onSubmit(dataSave);
        }
    };

    return (
        <Modal
            show={isShow}
            onHide={onClose}
            animation
            backdrop="static"
            size="lg"
            className="height-fit"
        >
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Modal.Header closeButton className="bg-primary-800">
                    <Modal.Title>
                        Thông tin phiếu chi ({initialValues.i_code})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <Field
                                    name="i_datefound_dt"
                                    className="form-control"
                                    label="Ngày lập"
                                    component={DatePickerField}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Field
                                name="i_paymentvouchertype"
                                className="form-control"
                                label="Loại phiếu chi"
                                component={PaymentVoucherTypeSelectField}
                                onChange={onPaymentVoucherTypeChange}
                            />
                        </div>
                        <div className="col-md-4">
                            <FKMealServiceSelect
                                label="Tiền dịch vụ"
                                oId={oId}
                                isNull={
                                    paymntVouType !==
                                    PAYMENT_VOUCHER_TYPE.SERVICE
                                }
                                onChange={onServiceChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <Field
                                name="i_daterange_dt"
                                className="form-control"
                                component={RangeDateFieldV2}
                                showType="form"
                                rangeType={MOMENT_RANGE_TYPE.DAY}
                                onChange={onDateRangeChange}
                                minDate={+maxDateTo}
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <Field
                                    name="i_total"
                                    className="form-control"
                                    label="Số tiền"
                                    component={RenderPriceFieldV2}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <Field
                                    name="cl_id"
                                    className="form-control"
                                    label="Loại khoản chi"
                                    component={DynamicCostSelectField}
                                    oId={oId}
                                    type={RECEIPT_VOUCHER_TYPE.PHIEU_CHI}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <Field
                                    name="cl_source"
                                    className="form-control"
                                    label="Trích từ khoản"
                                    component={DynamicCostSelectField}
                                    oId={oId}
                                    type={RECEIPT_VOUCHER_TYPE.PHIEU_THU}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <Field
                                    name="i_referencecode"
                                    className="form-control"
                                    label="Số chứng từ"
                                    component={RenderTextField}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <Field
                                    name="i_paymenttype"
                                    className="form-control"
                                    label="Hình thức thanh toán"
                                    component={PaymentTypeSelectField}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <Field
                                    name="ab_id"
                                    className="form-control"
                                    label="Chi từ tài khoản"
                                    component={DynamicAccountBankSelectField}
                                    oId={oId}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <Field
                                    name="i_receivername"
                                    className="form-control"
                                    label="Người nhận tiền"
                                    component={RenderTextField}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Field
                                    name="i_address"
                                    className="form-control"
                                    label="Địa chỉ"
                                    component={RenderTextField}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Field
                                    name="i_description"
                                    className="form-control"
                                    label="Nội dung chi tiền"
                                    rows={3}
                                    component={renderTextAreaField}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-group">
                        <ButtonModalAction
                            buttonType={BUTTON_ACTION_TYPE.SAVE}
                            buttonLabel="Lưu"
                            type="submit"
                            disabled={submitting || invalid}
                            isLeftIcon
                        />
                        <ButtonModalAction
                            buttonType={BUTTON_ACTION_TYPE.CLOSE}
                            buttonLabel="Thoát"
                            onClick={onClose}
                        />
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

PaymentVoucherAddOrEditPopup.propTypes = propTypes;

const withReduxForm = reduxForm({
    form: FORM_NAME,
    //validate,
    enableReinitialize: true,
});

export default withReduxForm(PaymentVoucherAddOrEditPopup);
