import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
// location
import cityReducer from '@app/redux/locations/city/reducer';
import districtReducer from '@app/redux/locations/district/reducer';
import wardReducer from '@app/redux/locations/ward/reducer';

// location V2
import countryReducer from '@app/redux/locationsV2/country/country.reducer';
import cityReducerV2 from '@app/redux/locationsV2/city/reducer';
import districtReducerV2 from '@app/redux/locationsV2/district/reducer';
import wardReducerV2 from '@app/redux/locationsV2/ward/reducer';

import loginReducer from '@app/redux/login/reducer';
// import locationReducer from "@app/redux/reducers/locations/location";
// import uploadFileReducer from "@app/redux/reducers/uploadFiles";

// import cacheDataReducer from "@app/redux/reducers/cacheData";

import others from '@app/redux/others/slice';

import warehouseReducer from '@app/redux/warehouse/warehouse.reducer';
import schoolReducer from '@app/redux/school/school.reducer';
import foodkidReducer from '@app/redux/foodkid/foodkid.reducer';
import inventoryReducer from '@app/redux/inventory/inventory.reducer';
import othersReducer from '@app/redux/others/reducer';
import monthReducer from '@app/redux/others/month/month.reducer';
import attendance from '@app/redux/attendance/slice';
import copyMenuReducer from '@app/redux/copymenu/copymenu.reducer';
import organizationReducer from '@app/redux/organization/organization.reducer';
import menusReducer from '@app/redux/menus/menus.slice';

import accountRoleReducer from '@app/redux/account/accountRole/accountRole.slice';
import accountListReducer from '@app/redux/account/accountList/accountList.slice';
import accountPrivilegeReducer from '@app/redux/account/accountPrivilege/accountPrivilege.slice';

import transactionHistoryReducer from '@app/redux/transactionHistory/transactionHistory.slice';

import settingSchoolReducer from '@app/redux/settingSchool/settingSchool.slice';
import paymentVoucher from '@app/redux/income/paymentVoucher/slice';

import agencyReducer from '@app/redux/agency/agency.slice';
import myAccount from '@app/redux/account/myAccount/slice';

const rootReducer = combineReducers({
    country: countryReducer,
    city: cityReducer,
    cityV2: cityReducerV2,

    ward: wardReducer,
    wardV2: wardReducerV2,
    district: districtReducer,
    districtV2: districtReducerV2,
    form: formReducer,

    login: loginReducer,
    //   location: locationReducer,

    others,

    //new
    othersReducer,
    schoolReducer,
    foodkidReducer,
    inventoryReducer,
    monthReducer,
    warehouseReducer,
    attendance,
    copyMenuReducer,
    organizationReducer,

    menus: menusReducer,
    accountRole: accountRoleReducer,
    accountList: accountListReducer,
    accountPrivilege: accountPrivilegeReducer,

    settingSchool: settingSchoolReducer,
    transactionHistory: transactionHistoryReducer,
    agency: agencyReducer,
    paymentVoucher,
    myAccount,
});

export default rootReducer;
