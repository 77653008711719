import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _myAccountSltor from '@app/redux/account/myAccount/selector';
import { _myAccountActs } from '@app/redux/account/myAccount/slice';
import ButtonModalAction from '@app/components/UI/Button/ButtonModalAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';
import { Field, Form } from 'react-final-form';
import RenderTextField from '@app/components/Layout/Shares/FormHelpers/TextField';
import validate from './validate';
import _globalUtils from '@app/utils/global.utils';
import _scAccountApis from '@app/apis/accounts/sc/scAccount';
import alertUtils from '@app/utils/alert';
import { useHistory } from 'react-router-dom';
import { deleteDbCache } from '@app/services/localDBService/initDB';
import * as loginActions from '@app/redux/login/actions';
import { SCHOOL_SELECT, TOKEN_NAME } from '@app/constants/global.const';

const ChangePasswordPopup = () => {
    const [initialValues, setInitialValues] = useState({
        currentPass: '',
        newPass: '',
        confirmPass: '',
    });
    const isShow = useSelector(_myAccountSltor.isShowChangePassPopup);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isShow) {
            setInitialValues({
                currentPass: '',
                newPass: '',
                confirmPass: '',
                randomId: _globalUtils.randomId(),
            });
        }
    }, [isShow]);

    const onClose = () => {
        dispatch(_myAccountActs.doOpenChangePassPopup(false));
    };

    const logOut = () => {
        localStorage.setItem(TOKEN_NAME, '');
        localStorage.setItem(SCHOOL_SELECT, '');

        deleteDbCache();
        dispatch(loginActions.updateToken(''));
        history.push('/login');
    };

    const onSubmitForm = async (s) => {
        const dataPost = {
            curentPass: s.currentPass,
            newPass: s.newPass,
            newPassConfirm: s.confirmPass,
        };
        const { status, data } = await _scAccountApis.changePassword(dataPost);
        if (status !== 200) {
            alertUtils.showError(data?.msg);
            return;
        }
        onClose();
        alertUtils.showTimerFunc({
            msg: 'Bạn đã thay đổi mật khẩu thành công! Hệ thống sẽ tự động đăng xuất trong <strong></strong> giây',
            func: logOut,
            timer: 5000,
        });
    };
    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, invalid, submitting }) => (
                    <Modal
                        title="Đổi mật khẩu"
                        open={isShow}
                        onCancel={onClose}
                        className="sc-modal"
                        width={600}
                        footer={[
                            <div className="btn-group" key="btn-group">
                                <ButtonModalAction
                                    buttonType={BUTTON_ACTION_TYPE.SAVE}
                                    disabled={invalid || submitting}
                                    onClick={handleSubmit}
                                    buttonLabel="Đổi mật khẩu"
                                    key="save"
                                    isLeftIcon
                                />
                                <ButtonModalAction
                                    buttonType={BUTTON_ACTION_TYPE.CLOSE}
                                    buttonLabel="Thoát"
                                    onClick={onClose}
                                    key="close"
                                />
                            </div>,
                        ]}
                    >
                        <div className="form-group">
                            <Field
                                name="currentPass"
                                component={RenderTextField}
                                className="form-control"
                                label="Mật khẩu hiện tại"
                                type="password"
                                requiredChar
                            />
                        </div>
                        <div className="form-group">
                            <Field
                                name="newPass"
                                component={RenderTextField}
                                className="form-control"
                                label="Mật khẩu mới"
                                type="password"
                                requiredChar
                            />
                        </div>
                        <div className="form-group">
                            <Field
                                name="confirmPass"
                                component={RenderTextField}
                                className="form-control"
                                label="Xác nhận lại mật khẩu"
                                type="password"
                                requiredChar
                            />
                        </div>
                    </Modal>
                )}
            />
        </>
    );
};

export default ChangePasswordPopup;
