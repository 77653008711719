import React, { Fragment } from 'react';

const RequiredIcon = () => {
    const style = {
        color: 'red',
        fontSize: '9px',
        position: 'relative',
        left: '5px',
        top: '-3px',
    };
    return (
        <Fragment>
            <span style={style}>
                <i className="fa fa-asterisk" />
            </span>
        </Fragment>
    );
};

export default RequiredIcon;
