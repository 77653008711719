import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@app/redux/school/school.action';
import SchoolSelector from '@app/redux/school/school.selector';
import { SCHOOL_SELECT } from '@app/constants/global.const';
import { nonAccentVietnamese } from '@app/utils/utils';
import _locationApis from '@app/apis/locations/location';
import { useDeepEffect } from '@app/hooks/useDeepEffect';
import _ from 'lodash';
import locationUtils from '@app/utils/location.utils';
import './_style.scss';

const { Option } = Select;

const SchoolListSelect = () => {
    const listSchool = useSelector(SchoolSelector.schoolList);
    const currentSchool = useSelector(SchoolSelector.currentSchool);
    const [listLocation, setListLocation] = useState([]);

    const dispatch = useDispatch();

    const getListLocation = async () => {
        const listLocationId = listSchool.map((o) => o.l_id);

        let listAllLocationId = [];
        listLocationId.forEach((elm) => {
            const arr = elm.split('.');
            listAllLocationId = [
                ...listAllLocationId,
                elm,
                `${arr[0]}.${arr[1]}`,
                `${arr[0]}`,
            ];
        });

        listAllLocationId = _.uniq(listAllLocationId);

        const { status, data } = await _locationApis.getByListId(
            listAllLocationId
        );

        if (status !== 200) {
            setListLocation([]);
            return;
        }
        setListLocation(data.data);
    };

    useEffect(() => {
        dispatch(actions.getSchool());
    }, [dispatch]);

    useDeepEffect(() => {
        if (listSchool.length === 0) return;
        getListLocation();
    }, [listSchool]);

    const listView = useMemo(() => {
        if (listSchool.length === 0 || listLocation.length === 0) return [];
        return listSchool.map((elm) => {
            const locationFullName = locationUtils.getFullNameById(
                elm.l_id,
                listLocation
            );

            return {
                ...elm,
                locationFullName,
            };
        });
    }, [listSchool, listLocation]);

    const onSearch = (input, option) => {
        if (!option) return false;
        const keySearch = option.children[0];
        return (
            nonAccentVietnamese(keySearch.replace(/\s/g, ''))
                .toLowerCase()
                .indexOf(
                    nonAccentVietnamese(input.replace(/\s/g, '')).toLowerCase()
                ) >= 0
        );
    };

    const showOptionItem = () => {
        if (listView.length === 0) {
            return (
                <Option value="0" key="0">
                    -- Chọn trường --
                </Option>
            );
        }
        return listView.map((elm) => (
            <Option value={elm.o_id} key={elm.o_id}>
                {elm.o_name}
                <br />
                <small className="text-muted">{elm.locationFullName}</small>
            </Option>
        ));
    };

    const onChange = (value) => {
        dispatch(actions.changeSchool(value));
        localStorage.setItem(SCHOOL_SELECT, value);
    };
    return (
        <Fragment>
            {/* <label>
                <h6 className="panel-title">Chọn trường</h6>
            </label> */}
            <Select
                value={currentSchool}
                onChange={onChange}
                optionFilterProp="children"
                filterOption={(input, option) => onSearch(input, option)}
                showSearch
                dropdownClassName="school-select"
            >
                {showOptionItem()}
            </Select>
        </Fragment>
    );
};

export default SchoolListSelect;
