/* eslint-disable react/no-unused-state */
import React from 'react';
import { Modal } from 'react-bootstrap';
import RenderTextField from '@app/components/Layout/Shares/FormHelpers/TextField';
import { Field, reduxForm } from 'redux-form';
import validate from './validate';
import ButtonModalAction from '@app/components/UI/Button/ButtonModalAction';
import { BUTTON_ACTION_TYPE } from '@app/components/UI/Button/ButtonFilterAction/_service';

const OrganizationModal = ({
    isShow,
    onSave,
    onCloseModal,
    handleSubmit,
    submitting,
    invalid,
}) => {
    const handleSubmitForm = (data) => {
        onSave?.(data);
    };

    return (
        <Modal
            show={isShow}
            onHide={onCloseModal}
            animation
            backdrop="static"
            size="sm"
        >
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Modal.Header closeButton className="bg-primary-800">
                    <Modal.Title>Kết nối đơn vị</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <Field
                                            name="o_securitycode"
                                            className="form-control"
                                            label="Mã bảo mật"
                                            component={RenderTextField}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-group">
                        <ButtonModalAction
                            buttonType={BUTTON_ACTION_TYPE.SAVE}
                            buttonLabel="Lưu"
                            type="submit"
                            disabled={submitting || invalid}
                            isLeftIcon
                        />
                        <ButtonModalAction
                            buttonType={BUTTON_ACTION_TYPE.CLOSE}
                            buttonLabel="Thoát"
                            onClick={onCloseModal}
                        />
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
    // }
};

const withReduxForm = reduxForm({
    form: 'ORGANIZATION_FORM',
    validate,
    enableReinitialize: true,
});

export default withReduxForm(OrganizationModal);
