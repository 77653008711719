import { API_ORGANIZATION_ENDPOINT } from '@app/constants/api';
import axiosService from '@app/services/axiosService';

const BASE_URL = `${API_ORGANIZATION_ENDPOINT}/v1/CustomerOrganization`;

const LIST_URL = {
    GET_LIST_AGENCY: `${BASE_URL}/GetListAgencyService`,
    GET_LIST_SCH_OF_AGENCY: `${BASE_URL}/GetSchoolService`,
};

export const apiGetListAgency = (data) =>
    axiosService.post(LIST_URL.GET_LIST_AGENCY, data);

export const apiGetListSchOfAgency = (data) =>
    axiosService.post(LIST_URL.GET_LIST_SCH_OF_AGENCY, data);
