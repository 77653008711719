const MODULE_NAME = 'foodkidReducer';

const FoodkidSelector = {
    kidGroupList: (state) => state[MODULE_NAME].kidGroupList,
    kidGroupSelect: (state) => state[MODULE_NAME].kidGroupSelect,
    rowSelected: (state) => state[MODULE_NAME].rowSelected,
    menuSelect: (state) => state[MODULE_NAME].menuSelect,
    isCheckAll: (state) => state[MODULE_NAME].isCheckAll,
    itemMenuCopy: (state) => state[MODULE_NAME].itemMenuCopy,
    listMenuCopy: (state) => state[MODULE_NAME].listMenuCopy,

    menuSchoolFoodList: (state) => state[MODULE_NAME].menuSchoolFoodList,
    menuServiceList: (state) => state[MODULE_NAME].menuServiceList,
    menuList: (state) => state[MODULE_NAME].menuList,

    listServiceSchool: (state) => state[MODULE_NAME].listServiceSchool,

    isShowModel: (state) => state[MODULE_NAME].isShowModel,
    isShowModalCopy: (state) => state[MODULE_NAME].isShowModalCopy,
    isShowModalQuantity: (state) => state[MODULE_NAME].isShowModalQuantity,
    isShowModalService: (state) => state[MODULE_NAME].isShowModalService,

    reload: (state) => state[MODULE_NAME].reload,
};

export default FoodkidSelector;
