import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import globalUtils from '@app/utils/global.utils';

const propType = {
    item: PropTypes.shape({
        stt: PropTypes.number,
        name: PropTypes.string,
        category: PropTypes.number,
        isCheck: PropTypes.bool,
    }),
    onCheckItem: PropTypes.func,
};

const IncomeItem = ({ item, onCheckItem }) => (
    <tr>
        <td className="text-center">{item.stt}</td>
        <td className="text-center">
            <Checkbox checked={item.isCheck} onChange={(s) => onCheckItem(s)} />
        </td>
        <td data-label="Tên nhóm trẻ">{item.name}</td>
        <td data-label="Tên nhóm trẻ">Thu chi</td>
        <td className="text-center">
            {globalUtils.getProcessName(item.status, item.message)}
        </td>
    </tr>
);

IncomeItem.propTypes = propType;

export default IncomeItem;
