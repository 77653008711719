import warehouseIcon from '@app/assets/images/icons/fk/warehouse.svg';
import Warehouse from '@app/components/Inventory';
import React from 'react';
import { ADMIN_FUNCTIONS, SC_MODULES } from '@app/constants/sc.modules';

/**
 * Tổ chức
 */
const warehouseRoutes = [
    {
        name: 'NHẬP XUẤT KHO',
        icon: warehouseIcon,
        isMenu: true,
        isRoute: true,
        exact: false,
        moduleId: SC_MODULES.SERVICE,
        link: '/warehouse',
        path: '/warehouse',
        main: () => <Warehouse />,
        functionId: ADMIN_FUNCTIONS.WAREHOUSE_CITY,
        // children: [
        //   {
        //     name: "menu.warehouse",
        //     link: "/warehouse",
        //     path: "/warehouse",
        //     isMenu: true,
        //     isRoute: true,
        //     exact: false,
        //     functionId: ADMIN_FUNCTIONS.FOODKID_MAIN,
        //     main: () => <DashBoard />,
        //   },
        // ],
    },
];

export default warehouseRoutes;
