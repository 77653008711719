import axiosService from '@app/services/axiosService';
import {
    API_INCOME_ENDPOINT,
    API_SC_GATEWAY_ENDPOINT,
} from '@app/constants/api';
import { ApiGatewayDataPost } from '@app/models/common/apiGatewayDataPost.model';

const BASE_URL = `${API_INCOME_ENDPOINT}/v1/ScIncome/Service/StatisticalReport`;

const LIST_URL = {
    SO_THU_THANH_TOAN_TONG_HOP: `${BASE_URL}/GetListByPaymentStatus`,
    TONG_HOP_THU_CHI_TIEN_AN: `${BASE_URL}/GetReceiptExpenseMoneyForMeal`,
    SO_QUY_TIEN_AN: `${BASE_URL}/GetMoneyForMealCashBook`,
    TRANSIT_STATION: `${API_SC_GATEWAY_ENDPOINT}/v1/ScGateway/TransitStation`,
};

// /**
//  * Lấy danh sách sổ thu thanh toán tổng hợp
//  * @param {object} dataPost : dữ liệu gửi lên
//  * @param {*} dataPost.oId : Id tổ chức
//  * @param {*} dataPost.yearId: Id năm học
//  * @param {*} dataPost.month: Tháng chọn
//  * @param {*} dataPost.classId: Id lớp học
//  * @param {*} dataPost.paymentStatus : Tình trạng thanh toán
//  * @returns
//  */
// export const apiLaySoThuThanhToanTongHop = (dataPost) =>
//     axiosService.post(LIST_URL.SO_THU_THANH_TOAN_TONG_HOP, dataPost);

// /**
//  * Lấy danh sách sổ thu thanh toán tổng hợp
//  * @param {object} dataPost : dữ liệu gửi lên
//  * @param {*} dataPost.oId : Id tổ chức
//  * @param {*} dataPost.sy_d: Id năm học
//  * @param {*} dataPost.year: năm được chọn
//  * @param {*} dataPost.month: Tháng chọn
//  * @param {*} dataPost.cl_id: Id khoản thu
//  * @returns
//  */
// export const apiLayTongHopThuChiTienAn = (dataPost) =>
//     axiosService.post(LIST_URL.TONG_HOP_THU_CHI_TIEN_AN, dataPost);

// /**
//  * Lấy danh sách sổ thu thanh toán tổng hợp
//  * @param {object} dataPost : dữ liệu gửi lên
//  * @param {*} dataPost.oId : Id tổ chức
//  * @param {*} dataPost.sy_d: Id năm học
//  * @param {*} dataPost.year: năm được chọn
//  * @param {*} dataPost.month: Tháng chọn
//  * @param {*} dataPost.cl_id: Id khoản thu
//  * @returns
//  */
// export const apiLaySoQuyTienAn = (dataPost) =>
//     axiosService.post(LIST_URL.SO_QUY_TIEN_AN, dataPost);

const _icStatisticalApis = {
    /**
     * Lấy danh sách sổ thu thanh toán tổng hợp
     * @param {object} dataPost : dữ liệu gửi lên
     * @param {*} dataPost.oId : Id tổ chức
     * @param {*} dataPost.yearId: Id năm học
     * @param {*} dataPost.month: Tháng chọn
     * @param {*} dataPost.classId: Id lớp học
     * @param {*} dataPost.paymentStatus : Tình trạng thanh toán
     * @returns
     */
    laySoThuThanhToanTongHop(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'ToolScStatistical',
            action: 'GetListByPaymentStatus',
            dataPost: {
                ...dataPost,
                orgId: dataPost.oId,
            },
        });
        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
        //return axiosService.post(LIST_URL.SO_THU_THANH_TOAN_TONG_HOP, dataPost);
    },

    layTongHopThuChiTienAn(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'ToolScStatistical',
            action: 'GetReceiptExpenseMoneyForMeal',
            dataPost: {
                ...dataPost,
                orgId: dataPost.oId,
            },
        });
        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
        //return axiosService.post(LIST_URL.TONG_HOP_THU_CHI_TIEN_AN, dataPost);
    },
    laySoQuyTienAn(dataPost) {
        const _dataPost = new ApiGatewayDataPost({
            service: 'Income',
            controller: 'ToolScStatistical',
            action: 'GetMoneyForMealCashBook',
            dataPost: {
                ...dataPost,
                orgId: dataPost.oId,
            },
        });
        return axiosService.post(LIST_URL.TRANSIT_STATION, _dataPost);
        //return axiosService.post(LIST_URL.SO_QUY_TIEN_AN, dataPost);
    },
};

export default _icStatisticalApis;
