/* eslint-disable no-unreachable */
import { deleteDB } from 'idb/with-async-ittr';
import { DB_CACHE_CONST } from '@app/constants/dbCacheConst';

// getVersionWithData,
export const initDataCache = async () => {
    try {
        return true;
    } catch (err) {
        // eslint-disable-next-line no-console
        return false;
        // console.trace(err);
    }
};

export const deleteDbCache = async () => {
    await deleteDB(DB_CACHE_CONST.DB_NAME);
};
