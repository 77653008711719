import { checkValueInList } from './_checkValueInList';
import { updateItemIntoList } from './_updateItemIntoList';
import { updateListIntoList } from './_updateListIntoList';
import { downloadPDF } from './_downloadPDF';
import { getProcessName } from './_getProcessName';
import { sortByVietnamName } from './_sortByVietnamName';
import { takeDecimalNumber } from './_takeDecimalNumber';
import { roundNumber } from './_roundNumber';
import { padLeft } from './_padLeft';
import { capitalize } from './_capitalize';
import { getYearId } from './_getYearId';
import { calcPagesCount } from './_calcPageCount';
import { v4 as uuidv4 } from 'uuid';

const _globalUtils = {
    checkValueInList,
    updateItemIntoList,
    updateListIntoList,
    downloadPDF,
    getProcessName,
    sortByVietnamName,
    takeDecimalNumber,
    padLeft,
    capitalize,
    getYearId,
    roundNumber,
    calcPagesCount,
    randomId() {
        return uuidv4();
    },
};

export default _globalUtils;
