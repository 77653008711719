import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const DatePickerField = ({
    input,
    label,
    className,
    maxDate,
    minDate,
    showTime,
    meta: { error },
    ...custome
}) => {
    const [dateValue, setDateValue] = useState();
    const dateFormat = showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';

    useEffect(() => {
        let _value = moment(new Date());
        if (input.value) {
            _value = moment(input.value);
        }
        setDateValue(_value);
        input.onChange(_value.startOf('D'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e) => {
        input.onChange(e.startOf('D'));
        setDateValue(e);
    };

    const disabledDate = (current) => {
        if (maxDate && minDate) {
            return minDate > current && current < maxDate;
        }
        if (minDate) {
            return minDate > current;
        }
        if (maxDate) {
            return current > maxDate;
        }
    };

    return (
        <div>
            {label ? (
                <label>
                    <h6 className="panel-title">{label}</h6>
                </label>
            ) : (
                ''
            )}
            <div>
                <DatePicker
                    {...custome}
                    onChange={(s) => handleChange(s)}
                    placeholder="Chọn ngày"
                    format={dateFormat}
                    allowClear={false}
                    showToday={false}
                    disabledDate={(current) => disabledDate(current)}
                    showTime={showTime}
                    value={dateValue}
                />
                {error && <small>{error}</small>}
            </div>
        </div>
    );
};

export default DatePickerField;
