import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PaymentVoucherTypeListConst } from '@app/constants/receiptCost.CONST';
import { Select } from 'antd';

const { Option } = Select;

const propTypes = {
    onChange: PropTypes.func,
    hasAllOption: PropTypes.bool,
};

function PaymentVoucherTypeSelect({ onChange, hasAllOption, label }) {
    const [selectId, setSelectId] = useState(PaymentVoucherTypeListConst[0].id);
    const [list, setList] = useState(PaymentVoucherTypeListConst);

    useEffect(() => {
        let _listTemp = list;
        if (hasAllOption) {
            _listTemp = [
                {
                    id: -1,
                    name: 'Tất cả',
                },
                ..._listTemp,
            ];
        }
        setList(_listTemp);
        setSelectId(_listTemp[0].id);
        if (onChange) {
            onChange(_listTemp[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearch = (input, option) => {
        if (option) {
            return (
                nonAccentVietnamese(option.children.replace(/\s/g, ''))
                    .toLowerCase()
                    .indexOf(
                        nonAccentVietnamese(
                            input.replace(/\s/g, '')
                        ).toLowerCase()
                    ) >= 0
            );
        }
        return false;
    };

    const handleChange = (value) => {
        setSelectId(value);
        if (onChange) {
            onChange(value);
        }
    };

    const showOptionItem = () => {
        let xhtml = null;
        if (list.length > 0) {
            xhtml = list.map((item) => (
                <Option value={item.id} key={item.id}>
                    {item.name}
                </Option>
            ));
        }
        return xhtml;
    };

    return (
        <>
            {label ? (
                <>
                    <label>
                        <h6 className="panel-title">{label}</h6>
                    </label>
                    <div className="clearfix mb20" />
                </>
            ) : null}
            <Select
                value={selectId}
                onChange={(value) => handleChange(value)}
                optionFilterProp="children"
                filterOption={(input, option) => onSearch(input, option)}
                showSearch
                // disabled={disabled}
                className="disable-modified"
            >
                {showOptionItem()}
            </Select>
        </>
    );
}

PaymentVoucherTypeSelect.propTypes = propTypes;

export default PaymentVoucherTypeSelect;
