import * as types from './foodkid.constant';

export const getKidGroup = (id) => ({
    type: types.GET_KIDGROUP,
    payload: {
        id,
    },
});

export const getKidGroupSuccess = (data) => ({
    type: types.GET_KIDGROUP_SUCCESS,
    payload: {
        data,
    },
});

export const changeKidGroup = (id) => ({
    type: types.CHANG_KIDGROUP,
    payload: {
        id,
    },
});

export const saveMenu = (data) => ({
    type: types.SAVE_MENU,
    payload: {
        data,
    },
});

export const saveMenuSuccess = (data) => ({
    type: types.SAVE_MENU_SUCCESS,
    payload: {
        data,
    },
});

export const openModal = (data) => ({
    type: types.OPEN_MODAL,
    payload: {
        data,
    },
});

export const closeModal = () => ({
    type: types.CLOSE_MODAL,
    payload: {},
});

export const changeMenuSelect = (data) => ({
    type: types.CHANGE_MENU_SELECT,
    payload: { data },
});

// export const saveBalanceToService = (year, month, id) => ({
//     type: types.SAVE_BALANCE_TO_SERVICE,
//     payload: {
//         year,
//         month,
//         id,
//     },
// });

// export const saveBalanceToServiceSuccess = (data) => ({
//     type: types.SAVE_BALANCE_TO_SERVICE_SUCCESS,
//     payload: {
//         data,
//     },
// });

// export const check = (id) => ({
//     type: types.CHECK,
//     payload: {
//         id,
//     },
// });

// export const checkAll = () => ({
//     type: types.CHECK_ALL,
// });

// export const openModalCopyMenu = (data, listMenu) => ({
//     type: types.OPEN_MODAL_COPY_MENU,
//     payload: { data, listMenu },
// });
// export const closeModalCopyMenu = () => ({
//     type: types.CLOSE_MODAL_COPY_MENU,
// });

// export const deleteMenu = (data) => ({
//     type: types.DELETE_MENU,
//     payload: {
//         data,
//     },
// });

// export const deleteMenuSuccess = (data) => ({
//     type: types.DELETE_MENU_SUCCESS,
//     payload: {
//         data,
//     },
// });

// export const doUpdateComplete = (data) => ({
//     type: types.UPDATE_MENU_COMPLETE,
//     payload: { data },
// });

// export const doUpdateCompleteSuccess = (data) => ({
//     type: types.UPDATE_MENU_COMPLETE_SUCCESS,
//     payload: { data },
// });

/** ======== LẤY TOÀN BỘ DANH SÁCH THỰC ĐƠN MẪU THEO NHÓM TUỔI & TRƯỜNG ======== */
export const doGetMenuSample = (data) => ({
    type: types.GET_MENU_SAMPLE,
    payload: { data },
});
export const doGetMenuSampleSuccess = (data) => ({
    type: types.GET_MENU_SAMPLE_SUCCESS,
    payload: { data },
});

/** ======== CẬP NHẬT THÔNG TIN THỰC ĐƠN ======== */
export const doUpdateMenuResult = (data) => ({
    type: types.UPDATE_MENU_NEW,
    payload: { data },
});

/** ======== TÍCH CHỌN THỰC ĐƠN ======== */
export const doChangeSelectedRow = (data) => ({
    type: types.CHANGE_SELECTED_ROW,
    payload: { data },
});

/** ======== POPUP CẬP NHẬT SỈ SỐ ======== */
export const doOpenModalChangeQuantity = () => ({
    type: types.OPEN_MODAL_CHANGE_QUANTITY,
});
export const doCloseModalChangeQuantity = () => ({
    type: types.CLOSE_MODAL_CHANGE_QUANTITY,
});

/** ======== POPUP CẬP NHẬT TIỀN DỊCH VỤ ======== */
export const doOpenModalUpdateService = () => ({
    type: types.OPEN_MODAL_UPDATE_SERVICE,
});
export const doCloseModalUpdateService = () => ({
    type: types.CLOSE_MODAL_UPDATE_SERVICE,
});

/** ======== LẤY TOÀN BỘ DANH SÁCH DỊCH VỤ CỦA TRƯỜNG ======== */
export const doGetListServiceSchool = (data) => ({
    type: types.GET_LIST_SERVICE_SCHOOL,
    payload: { data },
});
export const doGetListServiceSchoolSuccess = (data) => ({
    type: types.GET_LIST_SERVICE_SCHOOL_SUCCESS,
    payload: { data },
});

/** ======== CẬP NHẬT DANH SÁCH DỊCH VỤ KHI TÍCH CHỌN ======== */
export const doUpdateListServiceSchool = (data) => ({
    type: types.UPDATE_LIST_SERVICE_SCHOOL,
    payload: { data },
});

/** ======== CẬP NHẬT GIÁ THỰC PHẨM MỚI NHẤT ======== */
export const doUpdateNewPriceMenu = (data) => ({
    type: types.UPDATE_NEW_PRICE_MENU,
    payload: { data },
});
export const doUpdateNewPriceMenuSuccess = () => ({
    type: types.UPDATE_NEW_PRICE_MENU_SUCCESS,
});

/** ======== CẬP NHẬT TIỀN DỊCH VỤ CHO THỰC ĐƠN ======== */
export const doUpdatePriceServiceMenu = (data) => ({
    type: types.UPDATE_PRICE_SERVICE_MENU,
    payload: { data },
});
export const doUpdatePriceServiceMenuSuccess = () => ({
    type: types.UPDATE_PRICE_SERVICE_MENU_SUCCESS,
});

/** ======== CẬP NHẬT SỈ SỐ CHO THỰC ĐƠN ======== */
export const doUpdateQuantityMenu = (data) => ({
    type: types.UPDATE_QUANTITY_MENU,
    payload: { data },
});
export const doUpdateQuantityMenuSuccess = () => ({
    type: types.UPDATE_QUANTITY_MENU_SUCCESS,
});

/** ======== LÀM TRÒN SỐ THỰC MUA ======== */
export const doRoundingRealBuy = (data) => ({
    type: types.ROUNDING_REAL_BUY,
    payload: { data },
});
export const doRoundingRealBuySuccess = () => ({
    type: types.ROUNDING_REAL_BUY_SUCCESS,
});

/** ======== THIẾT LẬP LẠI DỮ LIỆU ======== */
export const doReset = () => ({
    type: types.RESET_AUTO_CREATE_MENU,
});
