/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { exportReportToPDF } from '@app/apis/others/exportReport.api';
import { REPORT_TYPE } from '@app/constants/';
import { GLOBAL } from '@app/constants/global.const';
import { SyntheticNutritionWeekModel } from '@app/models/foodkid/foodkid.model';
import { formatDateUnix, getDayOfWeekByDate } from '@app/utils/datetime.utils';
import {
    getCaloTotal,
    getTotalGlucid,
    getTotalLipid,
    getTotalProtid,
    valueReport,
} from '@app/utils/foodkid.data';
import _globalUtils from '@app/utils/global.utils';
import numberUtils from '@app/utils/number.utils';
import reportUtils from '@app/utils/reportUtils';
import { decimalToPrice } from '@app/utils/utils';
import _ from 'lodash';
import moment from 'moment';

const { roundNumber } = numberUtils;

class _syntheticNutritionWeekExportPDFService {
    /**
     * Merge dữ liệu thực đơn tuần
     * @param {Array} proFoods : danh sách thực phẩm
     * @param {Array} schoolFoods : danh sách thực phẩm trường
     * @param {Array} menuSchoolFoods : danh sách thực phẩm của thực đơn
     * @param {Array} menulist : danh sách thực đơn
     * @param {Array} menuDishs : danh sách món ăn
     * @param {Array} foodDishCache : danh sach dish cache
     * @param {object} kg : nhóm trẻ
     * @param {object} locationInfo : thông tin địa chỉ của trường
     * @param {object} schoolInfo : thông tin chi tiết của trường
     * @param {object} organizationInfo : thông tin của trường
     * @param {number} type : loại báo cáo
     * @param {number} datefrom : từ ngày
     * @param {number} dateto : đến ngày
     * @param {String} nameFile: tên prefix file khi xuất ra
     */

    doExport = async (
        proFoods,
        schoolFoods,
        menuSchoolFoods,
        menulist,
        menuDishs,
        foodDishCache,
        kg,
        locationInfo,
        schoolInfo,
        organizationInfo,
        type,
        datefrom,
        dateto,
        nameFile
    ) => {
        const _nameFile = nameFile || 'TongHopTuan';

        const arrDishMenu = (arr, _type) => {
            const arrDishByType = arr
                ?.filter((o) => o.md_meal === _type)
                ?.map((v) => {
                    const _check = foodDishCache.find((x) => x.d_id === v.d_id);

                    return _check.d_name;
                });

            if (!_.isEmpty(arrDishByType)) {
                switch (_type) {
                    case 1: {
                        return `  Bữa sáng: - ${arrDishByType.join(' - ')}`;
                    }
                    case 2: {
                        return `  Bữa trưa: - ${arrDishByType.join(' - ')}`;
                    }
                    case 3: {
                        return `  Bữa xế: - ${arrDishByType.join(' - ')}`;
                    }
                    case 4: {
                        return `  Bữa phụ chiều: - ${arrDishByType.join(
                            ' - '
                        )}`;
                    }
                    default:
                        break;
                }
            }
        };

        const renderDateFoundMenu = (date) =>
            `${getDayOfWeekByDate(date, true)}: ${formatDateUnix(
                date,
                'DD-MM-YYYY'
            )}`;

        const _newMenuDish = _.orderBy(menulist, ['m_datefounded'])?.reduce(
            (rsList, item) => {
                rsList.push(renderDateFoundMenu(item.m_datefounded));

                const filterDish = menuDishs.filter(
                    (o) => o.m_id === item.m_id
                );

                const arrBreakFast = arrDishMenu(filterDish, 1);
                const arrLunch = arrDishMenu(filterDish, 2);
                const arrDinner = arrDishMenu(filterDish, 3);
                const arrSnack = arrDishMenu(filterDish, 4);

                if (arrBreakFast) rsList.push(arrBreakFast);
                if (arrLunch) rsList.push(arrLunch);
                if (arrDinner) rsList.push(arrDinner);
                if (arrSnack) rsList.push(arrSnack);

                return rsList;
            },
            []
        );

        const mapProductFood = menuSchoolFoods.map((_food) => {
            const sf = schoolFoods.find((o) => o.sf_id === _food.sf_id);
            const f = proFoods.find((o) => o.f_id === sf.f_id);

            return {
                ..._food,
                ...f,
            };
        });

        const { protida, protidv, lipida, lipidv, glucid, calo } =
            this.calcTotalNutri(menuSchoolFoods, schoolFoods, proFoods);

        const totalPrice = this.calcTotalPrice(menuSchoolFoods, menulist);

        const menulength = menulist.length;

        // Tổng số suất
        const totalQuantity = _.sumBy(menulist, 'm_quantityafter');

        // Bình quân 1 suất = tổng tiền/tổng số suất
        const totalAverage = roundNumber(totalPrice / totalQuantity, 0);

        // Tiền 1 calo = Bình quân 1 suất / (trùng bình calo trong 1 ngày)
        const priceOneColo = roundNumber(totalAverage / (calo / menulength), 2);

        let quantity2 = '';
        let quantity3 = '';
        let quantity4 = '';
        let quantity5 = '';
        let quantity6 = '';
        let quantity7 = '';
        let day2 = '';
        let day3 = '';
        let day4 = '';
        let day5 = '';
        let day6 = '';
        let day7 = '';
        _.forEach(menulist, (o) => {
            const day = moment.unix(o.m_datefounded).isoWeekday();
            if (day === 1) {
                quantity2 = `${o.m_quantityafter}`;
                day2 = `${moment.unix(o.m_datefounded).format('DD/MM')}`;
            }
            if (day === 2) {
                quantity3 = `${o.m_quantityafter}`;
                day3 = `${moment.unix(o.m_datefounded).format('DD/MM')}`;
            }
            if (day === 3) {
                quantity4 = `${o.m_quantityafter}`;
                day4 = `${moment.unix(o.m_datefounded).format('DD/MM')}`;
            }
            if (day === 4) {
                quantity5 = `${o.m_quantityafter}`;
                day5 = `${moment.unix(o.m_datefounded).format('DD/MM')}`;
            }
            if (day === 5) {
                quantity6 = `${o.m_quantityafter}`;
                day6 = `${moment.unix(o.m_datefounded).format('DD/MM')}`;
            }
            if (day === 6) {
                quantity7 = `${o.m_quantityafter}`;
                day7 = `${moment.unix(o.m_datefounded).format('DD/MM')}`;
            }
        });
        const menuResult = _(menuSchoolFoods)
            .groupBy('sf_id')
            .map((items) => ({
                sf_id: items[0].sf_id,
                m_id: items[0].m_id,
                foods: items,
            }))
            .value();

        const info = {
            ...reportUtils.getLocationInfo(schoolInfo, locationInfo),
            title:
                type === 1
                    ? 'BẢNG TỔNG HỢP KHẨU PHẦN DINH DƯỠNG TRONG TUẦN'
                    : type === 2
                    ? 'BẢNG CHI TIẾT KHẨU PHẦN DINH DƯỠNG TRONG TUẦN'
                    : 'BẢNG THỐNG KÊ KHẨU PHẦN DINH DƯỠNG TRONG TUẦN',
            assistant: schoolInfo?.ho_ten_hieu_pho_ban_tru,
            assistantTitle:
                schoolInfo?.tieu_de_hieu_pho_ban_tru ||
                GLOBAL.REPORT_ASSISTANT_TITLE,
            kg_name: kg !== undefined ? kg.kg_name : '',
            // province: locationInfo.cityName,
            // district: locationInfo.districtName,
            // wards: locationInfo.wardName,
            school: schoolInfo.ten_truong,
            date: moment(new Date()).format('DD-MM-YYYY'),
            start_date: moment.unix(datefrom).format('DD/MM/YYYY'),
            end_date: moment.unix(dateto).format('DD/MM/YYYY'),
            total_price: decimalToPrice(totalPrice),
            average: decimalToPrice(totalAverage),
            calo_price: decimalToPrice(priceOneColo),

            structure_p: valueReport(kg.kg_ratioprotid),
            structure_l: valueReport(kg.kg_ratiolipid),
            structure_g: valueReport(kg.kg_ratioglucid),
            standard_protida: valueReport(kg.kg_protidamax),
            standard_protidv: valueReport(kg.kg_protidvmax),
            standard_lipida: valueReport(kg.kg_lipidamax),
            standard_lipidv: valueReport(kg.kg_lipidvmax),
            standard_glucid: valueReport(kg.kg_glucidmax),
            standard_calo: valueReport(kg.kg_calomax),
            kg_ratioprotid_real: (
                parseFloat(getTotalProtid(mapProductFood, 1)) /
                getCaloTotal(mapProductFood, 1)
            ).toFixed(2),
            kg_ratiolipid_real: (
                getTotalLipid(mapProductFood, 1) /
                getCaloTotal(mapProductFood, 1)
            ).toFixed(2),
            kg_ratioglucid_real: (
                (getTotalGlucid(mapProductFood, 1) * 4 * 100) /
                getCaloTotal(mapProductFood, 1)
            ).toFixed(2),
            ratio_protida: decimalToPrice(
                ((protida / (protida + protidv)) * 100).toFixed(2)
            ),
            ratio_protidv: ((protidv / (protida + protidv)) * 100).toFixed(2),
            ratio_lipida: ((lipida / (lipida + lipidv)) * 100).toFixed(2),
            ratio_lipidv: ((lipidv / (lipida + lipidv)) * 100).toFixed(2),
            ratio_glucid: decimalToPrice(
                (glucid / kg.kg_glucidmax / menulength) * 100
            ),
            ratio_calo: decimalToPrice(
                (calo / kg.kg_calomax / menulength) * 100
            ),
            ratio_protid_a: protida,
            ratio_protid_v: protidv,
            ratio_lipid_a: lipida,
            ratio_lipid_v: lipidv,
            rate_achieved_protid: decimalToPrice(
                ((protidv + protida) /
                    (kg.kg_protidamax + kg.kg_protidvmax) /
                    menulength) *
                    100
            ),
            rate_achieved_lipid: decimalToPrice(
                ((lipidv + lipida) /
                    (kg.kg_lipidamax + kg.kg_lipidvmax) /
                    menulength) *
                    100
            ),
            rate_achieved_glucid: decimalToPrice(
                (glucid / kg.kg_glucidmax / menulength) * 100
            ),
            rate_achieved_calo: decimalToPrice(
                (calo / kg.kg_calomax / menulength) * 100
            ),
            quantity2,
            quantity3,
            quantity4,
            quantity5,
            quantity6,
            quantity7,
            day2,
            day3,
            day4,
            day5,
            day6,
            day7,
            day_total: menulist.length,
            quantity_total: totalQuantity,
            vprotid_dv: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return prev + (f.f_protida * next.msf_quantityafter) / 100;
                }, 0)
            ),
            vprotid_av: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return prev + (f.f_protidv * next.msf_quantityafter) / 100;
                }, 0)
            ),
            vacid_dv: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return prev + (f.f_lipidv * next.msf_quantityafter) / 100;
                }, 0)
            ),
            vacid_av: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return prev + (f.f_lipida * next.msf_quantityafter) / 100;
                }, 0)
            ),
            vglucid: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return prev + (f.f_glucid * next.msf_quantityafter) / 100;
                }, 0)
            ),
            vcalo: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return prev + (f.f_calo494 * next.msf_quantityafter) / 100;
                }, 0)
            ),
            viron: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return prev + (f.f_iron * next.msf_quantityafter) / 100;
                }, 0)
            ),
            vcanxi: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return prev + (f.f_canxi * next.msf_quantityafter) / 100;
                }, 0)
            ),
            vvitamina: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return prev + (f.f_vitamina * next.msf_quantityafter) / 100;
                }, 0)
            ),
            vvitaminb1: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return (
                        prev + (f.f_vitaminb1 * next.msf_quantityafter) / 100
                    );
                }, 0)
            ),
            vvitaminb2: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return (
                        prev + (f.f_vitaminb2 * next.msf_quantityafter) / 100
                    );
                }, 0)
            ),
            vvitaminc: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return prev + (f.f_vitaminc * next.msf_quantityafter) / 100;
                }, 0)
            ),
            vvitaminpp: valueReport(
                menuSchoolFoods.reduce((prev, next) => {
                    const sf = schoolFoods.find((o) => o.sf_id === next.sf_id);
                    const f = proFoods.find((o) => o.f_id === sf.f_id);
                    return (
                        prev + (f.f_vitaminpp * next.msf_quantityafter) / 100
                    );
                }, 0)
            ),
        };
        const datas = menuResult.map((item, i) => {
            const sf = schoolFoods.find((o) => o.sf_id === item.sf_id);
            const f = proFoods.find((o) => o.f_id === sf.f_id);
            const food = new SyntheticNutritionWeekModel();
            food.stt = i + 1;
            food.f_name = sf.sf_name ? sf.sf_name : f.f_name;
            // const quantityafter = item.foods.reduce((prev, next) => prev + next.msf_quantityafter, 0);
            const vprotid_dv = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_protida * next.msf_quantityafter) / 100,
                0
            );
            const vprotid_av = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_protidv * next.msf_quantityafter) / 100,
                0
            );
            const vacid_dv = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_lipidv * next.msf_quantityafter) / 100,
                0
            );
            const vacid_av = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_lipida * next.msf_quantityafter) / 100,
                0
            );
            const vglucid = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_glucid * next.msf_quantityafter) / 100,
                0
            );
            const vcalo = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_calo494 * next.msf_quantityafter) / 100,
                0
            );
            const viron = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_iron * next.msf_quantityafter) / 100,
                0
            );
            const vcanxi = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_canxi * next.msf_quantityafter) / 100,
                0
            );
            const vvitamina = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_vitamina * next.msf_quantityafter) / 100,
                0
            );
            const vvitaminb1 = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_vitaminb1 * next.msf_quantityafter) / 100,
                0
            );
            const vvitaminb2 = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_vitaminb2 * next.msf_quantityafter) / 100,
                0
            );
            const vvitaminc = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_vitaminc * next.msf_quantityafter) / 100,
                0
            );
            const vvitaminpp = item.foods.reduce(
                (prev, next) =>
                    prev + (f.f_vitaminpp * next.msf_quantityafter) / 100,
                0
            );
            // const dish = menuDishs.find(o => o.m_id === item.m_id);

            let foodTotalQuantity = 0;
            //const listFoodMerge = _.groupBy(item.foods, '')
            item.foods.forEach((x) => {
                const mf = menulist.find((o) => o.m_id === x.m_id);
                const day = moment.unix(mf?.m_datefounded).isoWeekday();

                const soLuongGamDVT = numberUtils.roundNumber(
                    (x.msf_totalquantityafter * (100 - x.msf_disposalrate)) /
                        100
                );
                foodTotalQuantity += soLuongGamDVT;
                food[`t${day + 1}`] = (
                    +food[`t${day + 1}`] + soLuongGamDVT
                ).toFixed(2);
            });
            food.total = foodTotalQuantity.toFixed(2);
            food.average = numberUtils
                .roundNumber(
                    item.foods.reduce(
                        (prev, next) => prev + next.msf_quantityafter,
                        0
                    ) / menulength
                )
                .toFixed(2);
            food.f_lipida = valueReport(vacid_av / menulength);
            food.f_lipidv = valueReport(vacid_dv / menulength);
            food.f_protida = valueReport(vprotid_av / menulength);
            food.f_protidv = valueReport(vprotid_dv / menulength);
            food.f_glucid = valueReport(vglucid / menulength);
            food.f_calo = valueReport(vcalo / menulength);
            food.f_iron = valueReport(viron / menulength);
            food.f_canxi = valueReport(vcanxi / menulength);
            food.f_vitamina = valueReport(vvitamina / menulength);
            food.f_vitaminb1 = valueReport(vvitaminb1 / menulength);
            food.f_vitaminb2 = valueReport(vvitaminb2 / menulength);
            food.f_vitaminc = valueReport(vvitaminc / menulength);
            food.f_vitaminpp = valueReport(vvitaminpp / menulength);
            // food.m_code = dish!== undefined?;
            if (i === menuResult.length - 1) {
                const partList = _newMenuDish.slice(i);
                food.m_code =
                    _newMenuDish.length <= menuResult.length
                        ? _newMenuDish[i]
                        : partList.join('\n');
            } else {
                food.m_code = _newMenuDish[i];
            }
            return food;
        });
        const res = await exportReportToPDF({
            data: JSON.stringify({ info, datas }),
            report: REPORT_TYPE.BANG_TONG_HOP_KHAU_PHAN_DINH_DUONG_TUAN,
        });
        if (res.status === 200) {
            _globalUtils.downloadPDF(
                res.data,
                `${_nameFile}_${moment.unix(datefrom).format('DD-MM-YYYY')}_${
                    kg.kg_name
                }_${organizationInfo.o_name}_${
                    type === 1
                        ? 'tổng hợp'
                        : type === 2
                        ? 'chi tiết'
                        : 'thống kê'
                }`
            );
        }
        return true;
    };

    calcTotalPrice = (listMenuSchoolFood, listMenu) => {
        if (!listMenuSchoolFood?.length || !listMenu?.length) return 0;
        const totalPrice = listMenuSchoolFood.reduce((a, b) => {
            const mf = listMenu.find((o) => o.m_id === b.m_id);
            return a + b.msf_pricefoodafter * mf?.m_quantityafter;
        }, 0);

        return roundNumber(totalPrice, 0);
    };

    // Bình quân 1 suất
    calcTotalAverage = (listMenuSchoolFood, listMenu) => {
        if (!listMenuSchoolFood?.length || !listMenu?.length) return 0;
        const totalPrice = this.calcTotalPrice(listMenuSchoolFood, listMenu);
        const totalQuantity = _.sumBy(listMenu, 'm_quantityafter');

        // = Tổng tiền / Tổng suất ăn
        const totalAverage = roundNumber(totalPrice / totalQuantity, 0);

        return totalAverage;
    };

    calcTotalCalo = (listMenuSchoolFood, listSchoolFood, listFood) => {
        const listCalo = listMenuSchoolFood.map((o) => {
            const objFood = this.getFood(o, listSchoolFood, listFood);
            if (!objFood) return 0;
            return roundNumber(
                (objFood.f_calo494 * o.msf_quantityafter) / 100,
                2
            );
        });
        return roundNumber(_.sum(listCalo), 2);
    };

    calcTotalNutri = (listMenuSchoolFood, listSchoolFood, listFood) => {
        const arrFieldRes = [
            'calo',
            'protida',
            'protidv',
            'lipida',
            'lipidv',
            'glucid',
        ];
        const arrFieldData = [
            'f_calo494',
            'f_protida',
            'f_protidv',
            'f_lipida',
            'f_lipidv',
            'f_glucid',
        ];

        const listNutri = listMenuSchoolFood.map((o) => {
            const objFood = this.getFood(o, listSchoolFood, listFood);
            if (!objFood) {
                return {};
            }

            let objNutri = {};

            for (let i = 0; i < arrFieldData.length; i++) {
                objNutri = {
                    ...objNutri,
                    [arrFieldRes[i]]: this.calcNutri(
                        objFood,
                        o,
                        arrFieldData[i]
                    ),
                };
            }

            return objNutri;
        });

        let objRes = {};
        arrFieldRes.forEach((fieldName) => {
            objRes = {
                ...objRes,
                [fieldName]: roundNumber(_.sumBy(listNutri, fieldName), 2),
            };
        });
        return objRes;
    };

    calcNutri = (objFood, objMenuSchoolFood, fieldName) => {
        if (!fieldName || !objFood || !objMenuSchoolFood) return 0;

        return roundNumber(
            (objFood[fieldName] * objMenuSchoolFood.msf_quantityafter) / 100,
            2
        );
    };

    getFood = (objMenuSchoolFood, listSchoolFood, listFood) => {
        if (!objMenuSchoolFood) return undefined;
        const objSchooFood = listSchoolFood.find(
            (o) => o.sf_id === objMenuSchoolFood.sf_id
        );
        if (!objSchooFood) return undefined;
        const objFood = listFood.find((o) => o.f_id === objSchooFood.f_id);
        return objFood;
    };
}

export default new _syntheticNutritionWeekExportPDFService();
